import React, { useEffect, useState } from "react";
import Button from "../../../../../../common/element/Buttons";
import Table from "../../../../../../common/element/Table";
import { useDispatch } from "react-redux";
import { GetAllDocsOfProviderByDocType } from "../../../../../../actions/admin-provider/clients/GetAllDocsOfProviderByDocType";
import { useParams } from "react-router-dom";
import moment from "moment";
import { SubmitExistingDocToClient } from "../../../../../../actions/admin-provider/clients/SubmitExistingDocToClient";
const OldDocument = ({
  docTypeName,
  compliance_doc_uuid,
  closeAddDocPannel,
  indivisualData,
}) => {
  const [getAllDocsData, SetGetAllDocsData] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { Tr, Th, Td, THead, TBody } = Table;
  useEffect(() => {
    dispatch(
      GetAllDocsOfProviderByDocType({
        provider_org_uuid: id,
        document_type_uuid: docTypeName?.document_type?.document_type_uuid,
      })
    ).then((response) => {
      SetGetAllDocsData(response?.data);
    });
  }, []);
  const AddDoc = (data) => {
    let payload = {
      client_org_uuid: indivisualData.organisation_uuid,
      compliance_doc_uuid: compliance_doc_uuid,
      document_uuid: data.document_uuid,
      provider_org_uuid:id,
    };
    dispatch(SubmitExistingDocToClient(payload)).then((response) => {
      if (response.success) {
        closeAddDocPannel(true);
      }
    });
  };
  return (
    <div className="overflow-hidden border rounded-lg border-gray-200">
      <Table>
        <THead>
          <Tr>
            <Th>
              <span className="flex items-center gap-1">
                Document name
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                    stroke="#475467"
                    stroke-width="1.333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Th>

            <Th className="w-64">Document type</Th>

            <Th className="w-64">
              <span className="flex items-center gap-1">
                Expiry date
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                    stroke="#475467"
                    stroke-width="1.333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </Th>
            <Th className="w-16"></Th>
          </Tr>
        </THead>
        <TBody>
          {getAllDocsData?.map((item) => {
            return (
              <Tr>
                <Td>{item?.doc_name}</Td>
                <Td>{docTypeName?.doc_type_name}</Td>
                <Td>{moment(item?.expiry_date).format("DD-MM-YYYY")}</Td>
                <Td>
                  <Button
                    className="text-blue-700 hover:text-gray-900 focus:text-gray-600"
                    onClick={() => AddDoc(item)}
                  >
                    Select
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </TBody>
      </Table>
    </div>
  );
};

export default OldDocument;
