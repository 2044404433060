import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const Invoices = () => {
  const { SpecificProviderData } = useSelector((state) => state);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Provider",
          url: "/admin/provider/view-all-provider",
          home: "/admin/provider/dashboard",
        },
        {
          label: `${SpecificProviderData?.trading_name}`,
          url: `/admin/provider/view-all-provider/specific-provider/${id}`,
        },

        {
          label: "Invoices",
          url: `/admin/provider/view-all-provider/specific-provider/${id}`,
        },
      ])
    );
  }, []);
  return "";
};
