import React, { useEffect, useState } from "react";
import Select from "../element/Select";

export const Pagination = ({
  totalCount,
  pageNumber,
  displayPage,
  UpdatePageLimit,
  UpdatePage,
  allfilters,
}) => {

  const [countOptions, setCountOptions] = useState([
    { label: "10", value: 10 },
    { label: "25", value: 25 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ]);
    
  const [currentPage, setCurrentPage] = useState(pageNumber || 1); // Set initial pageNumber from props if provided
  const [itemsPerPage, setItemsPerPage] = useState([{ label: 10, value: 10 }]);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  // Calculate the range of items to display
  const indexOfLastItem = Math.min(
    currentPage * itemsPerPage[0].value,
    displayPage
  );

  const indexOfFirstItem = Math.min(
    (currentPage - 1) * itemsPerPage[0].value,
    displayPage
  );

  // Update current pageNumber
  useEffect(() => {
    setIsLoading(true); // Set loading to true
    if (allfilters) {
      let limit = { limit: itemsPerPage[0].value };
      let page = { page: 1 };
      const updatePromise = UpdatePageLimit({
        ...allfilters,
        ...limit,
        ...page,
      });
      if (updatePromise && updatePromise.finally) {
        updatePromise.finally(() => setIsLoading(false));
      } else {
        setIsLoading(false);
      }

    } else {
      const limitUpdatePromise = UpdatePageLimit(itemsPerPage[0].value);
      const pageUpdatePromise = UpdatePage(1);
      if (
        limitUpdatePromise &&
        limitUpdatePromise.finally &&
        pageUpdatePromise &&
        pageUpdatePromise.finally
      ) {
        Promise.all([limitUpdatePromise, pageUpdatePromise]).finally(() =>
          setIsLoading(false)
        );
      } else {
        setIsLoading(false);
      }
    }
  }, [itemsPerPage]);
  return (
    <>
      {isLoading && <div>Loading...</div>} {/* Show loading indicator */}
      {!isLoading && totalCount === 0 && (
        <div className="h-full w-full text-center mt-2">No Data Available!</div>
      )}
      {/* Show "No Data Available" when totalCount is 0 */}
      {!isLoading && totalCount > 0 && (
        <div className="flex choose-show-fields mt-4 gap-2 items-center">
          <Select
            value={itemsPerPage}
            onChange={(e) =>
              setItemsPerPage([{ label: e.label, value: e.value }])
            }
            options={countOptions}
          />
          {pageNumber > 1
            ? `Displaying ${
                itemsPerPage[0]?.value * (pageNumber - 1) + indexOfFirstItem + 1
              }-${
                itemsPerPage[0]?.value * (pageNumber - 1) + indexOfLastItem
              } of`
            : `Displaying ${indexOfFirstItem + 1}-${indexOfLastItem} of `}
          <span>{totalCount}</span> records
        </div>
      )}
    </>
  );
};
