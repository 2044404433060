import axios from "axios";
import * as actionType from "../../constant/actionType";

    export const CreateComplianceDoc = (form_data) => async (dispatch) => {
      try {
        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        const response = await axios({
          method: 'post',
          data: formdata,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
          },
          url: `${actionType.environment_url}/api/client/CreateComplianceDoc`,
        });
        return response.data
      } catch (error) {
      }
    }
  
    export const GetAllComplianceDocsOfClient = (getAllinvitedData) => async (dispatch) => {
        /*** API Call Action ***/
        try {
            const response = await axios({
            method: 'get',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
            },
            url: `${actionType.environment_url}/api/client/GetAllComplianceDocsOfClient`,
            params: getAllinvitedData
          });
          return response.data
          
        } catch (error) {
        return error.message  
        }
      };
    
      export const GetHighRiskActivityList = (getAllinvitedData) => async (dispatch) => {
        /*** API Call Action ***/
        try {
            const response = await axios({
            method: 'get',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
            },
            url: `${actionType.environment_url}/api/GetHighRiskActivityList`,
            params: getAllinvitedData
          });
          return response.data
          
        } catch (error) {
        return error.message  
        }
      };
    

      export const GetAllDocumentTypeList = (getAllinvitedData) => async (dispatch) => {
        /*** API Call Action ***/
        try {
            const response = await axios({
            method: 'get',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
            },
            url: `${actionType.environment_url}/api/client/GetAllDocumentTypeList`,
            params: getAllinvitedData
          });
          return response.data
          
        } catch (error) {
        return error.message  
        }
      };
      // GetProviderDocTypeAgainstClientList
      export const GetProviderDocTypeAgainstClientList = (ApiData) => async (dispatch) => {
        try {
            const response = await axios({
            method: 'get',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
            },
            url: `${actionType.environment_url}/api/client/GetProviderDocTypeAgainstClientList`,
            params: ApiData
          });
          return response.data
          
        } catch (error) {
        return error.message  
        }
      };
      export const GetAllOrgIndustryTypeList = (getAllinvitedData) => async (dispatch) => {
        /*** API Call Action ***/
        try {
            const response = await axios({
            method: 'get',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
            },
            url: `${actionType.environment_url}/api/GetAllOrgIndustryTypeList`,
            params: getAllinvitedData
          });
          return response.data
          
        } catch (error) {
        return error.message  
        }
      };

    //   export const GetAllComplianceDocsOfClient = (getAllinvitedData) => async (dispatch) => {
    //     /*** API Call Action ***/
    //     try {
    //         const response = await axios({
    //         method: 'get',
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
    //         },
    //         url: `${actionType.environment_url}/api/client/GetAllComplianceDocsOfClient`,
    //         params: getAllinvitedData
    //       });
    //       return response.data
          
    //     } catch (error) {
    //     return error.message  
    //     }
    //   };
    