
import { createSlice} from "@reduxjs/toolkit";
const specificClientDetails = createSlice({
  name: 'specificClientDetails',
  initialState: [],
  reducers: {
    setClientSpecificData: (state, action) => {
        return action.payload;
      },
      resetclientSpecificData(){
        return []
      }
    // fetchData: (state) => {
    //   state.loading = true;
    // },
    // fetchDataSuccess: (state, action) => {
    //   state.loading = false;
    //   state.data = action.payload;
    // },
    // fetchDataError: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
    // reSetData:()=>{
    //   return []
    // }
  },
});
export const { setClientSpecificData, resetclientSpecificData } = specificClientDetails.actions;
export default specificClientDetails.reducer;