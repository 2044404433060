import React, { useState } from "react";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../common/element/Select/Select";
import { useDispatch, useSelector } from "react-redux";

import { GetAllIndividualListForProviderContact } from "../../../../../actions/provider-portal/GetAllIndividualListForContractorContact";
import ClientInput from "../../../../../common/element/Input/clientInput";
import { AddProviderContact } from "../../../../../actions/admin-provider/AddContractorContact";
// import { notify } from "react-notify-toast";
import Validations from "../../../../../common/404/Validations";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { useParams } from "react-router-dom";

const AddContactForm = (props) => {
  const { id } = useParams();
  const allFunction = useSelector((state) => state);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [searchOptions, setsearchOptions] = useState([]);
  const [customOptions, setcustomOptions] = useState([]);
  const filteredContacts = allFunction.AllContactTypes.filter(
    (contact) => contact.contact_type === "provider contact"
  );
  const dispatch = useDispatch();
  const [readOnly, setreadOnly] = useState(false);
  const [addContactData, SetaddContactData] = useState({
    contact_type_uuid: filteredContacts[0].contact_type_uuid,
    organisation_uuid: id,
    first_name: "",
    last_name: "",
    email: "",
    job_title: "",
    phone: "",
    individual_uuid: "",
  });
  const [errorvalidation, seterrorvalidation] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const formHandle = (e) => {
    if (e.eamil == "email") {
      setcustomOptions({ value: e.e, label: e.e });
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["email"]: "",
        };
      });
      if (e.e !== "" && e.e?.length > 3) {
        dispatch(GetAllIndividualListForProviderContact(e.e)).then(
          (response) => {
            if (response.success) {
              if (response.data.length) {
                const FilterData = response?.data?.map((item) => {
                  seterrorvalidation({
                    email: "",
                    first_name: "",
                    last_name: "",
                  });
                  return {
                    label: item.email,
                    value: item,
                  };
                });
                setsearchOptions(FilterData);
                SetaddContactData((prevalue) => {
                  return {
                    ...prevalue,
                    ...response.data[0],
                  };
                });

                setreadOnly(true);
              } else {
                setreadOnly(false);
                SetaddContactData((prevalue) => {
                  return {
                    ...prevalue,
                    ["individual_uuid"]: "",
                  };
                });
                SetaddContactData((prevalue) => {
                  return {
                    ...prevalue,
                    ["email"]: e.e,
                  };
                });
                let UpdateData = { first_name: "", last_name: "" };
                SetaddContactData((prevalue) => {
                  return {
                    ...prevalue,
                    ...UpdateData,
                  };
                });
              }
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          }
        );
      }
    } else {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: "",
        };
      });
      setreadOnly(false);
      SetaddContactData((prevalue) => {
        return {
          ...prevalue,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  // OnSelect Email
  const OnSlectHandleChange = (e) => {
    SetaddContactData((prevalue) => {
      if (typeof e.value == "string") {
        return {
          ...prevalue,
          ["email"]: e.value,
        };
      } else {
        return {
          ...prevalue, // Spread previous state
          ...e.value, // Spread data from response
        };
      }
    });
  };
  const onSubmitData = () => {
    let validationCheck = true;
    const emailValidation = Validations({
      value: addContactData.email,
      Validations: { required: true, email: true },
    });
    const first_name = Validations({
      value: addContactData.first_name,
      Validations: { required: true },
    });
    const last_name = Validations({
      value: addContactData.last_name,
      Validations: { required: true },
    });

    if (!emailValidation.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["email"]: emailValidation.error_msg,
        };
      });
    }
    if (!first_name.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["first_name"]: first_name.error_msg,
        };
      });
    }
    if (!last_name.valid) {
      seterrorvalidation((prevalue) => {
        return {
          ...prevalue,
          ["last_name"]: last_name.error_msg,
        };
      });
    }
    if (emailValidation.valid && first_name.valid && last_name.valid) {
      validationCheck = true;
    } else {
      validationCheck = false;
    }
    if (addContactData && validationCheck) {
      // delete inviteData.label;
      dispatch(AddProviderContact(addContactData))
        .then((response) => {
          if (response.success) {
            props.onClick();
          }
          if (response.status == 409) {
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {
        });
    }
  };

  return (
    <React.Fragment>
      <form>
        <div className="mt-6 add-client-form px-6">
          <div className="flex justify-between flex-col">
            <div className="flex">
              <div className="flex-1 pl-1 mb-3">
                <div className="flex-1 pr-1 mb-3">
                  <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                    Email address*
                  </label>
                  <Select
                    isSearchable={true}
                    placeholder="Select email"
                    name="email"
                    options={[...searchOptions, customOptions]}
                    onChange={OnSlectHandleChange}
                    handleSearchChange={(e) =>
                      formHandle({ e, eamil: "email" })
                    }
                    className="selectType"
                  />
                  {errorvalidation.email && (
                    <div className="text-red-500 text-sm mt-1 ">
                      {errorvalidation.email}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  First name*
                </label>
                <ClientInput
                  type="text"
                  name="first_name"
                  readOnly={readOnly}
                  inputchangehandler={formHandle}
                  value={addContactData.first_name}
                  placeholder="Enter Contact's first name"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorvalidation.first_name && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorvalidation.first_name}
                  </div>
                )}
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Last name*
                </label>
                <ClientInput
                  type="text"
                  readOnly={readOnly}
                  inputchangehandler={formHandle}
                  value={addContactData.last_name}
                  name="last_name"
                  placeholder="Enter Contact's last name"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorvalidation.last_name && (
                  <div className="text-red-500 text-sm mt-1 ">
                    {errorvalidation.last_name}
                  </div>
                )}
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Job title
                </label>
                <ClientInput
                  type="text"
                  name="job_title"
                  inputchangehandler={formHandle}
                  value={addContactData.job_title}
                  placeholder="Enter Contact's job role"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Phone number
                </label>
                <ClientInput
                  type="number"
                  name="phone"
                  inputchangehandler={formHandle}
                  value={addContactData.phone}
                  placeholder="Enter Contact's phone number"
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex dailog-footer ">
          <BtnLight>Cancel</BtnLight>
          <BtnPrimary className="ml-1.5" onClick={() => onSubmitData()}>
            Add contact
          </BtnPrimary>
        </div>
      </form>
    </React.Fragment>
  );
};

export default AddContactForm;
