import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"

const LogoutUser = ({ logOut, SetAlign }) => {
    const navigate = useNavigate()
    const [RefreshUrl, SetRefreshUrl] = useState("/login")
    const logoutUser = () => {
        // localStorage.clear();
        localStorage.removeItem("conserve_authtoken")
        localStorage.setItem("checkAuth",JSON.stringify(""));
        // localStorage.removeItem("TrustDeviceDate")
        // localStorage.removeItem("remember30Days")
        navigate(RefreshUrl)
    }
    return (
        <React.Fragment>
            <div style={{ display: `${SetAlign ? "initial" : "flex"}`, gap: "12px", justifyContent: "center" }} onClick={() => logoutUser()} className={`${logOut != "admin"?"header_lougout":""}`}>
                {logOut == "admin" && (
                    <button className="logout flex flex-col items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18 8L22 12M22 12L18 16M22 12H9M15 4.20404C13.7252 3.43827 12.2452 3 10.6667 3C5.8802 3 2 7.02944 2 12C2 16.9706 5.8802 21 10.6667 21C12.2452 21 13.7252 20.5617 15 19.796" stroke="#5786CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                )}
                {logOut == "dropdownLogout" && (
                    <React.Fragment>
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5.333 14.667 8m0 0L12 10.667M14.667 8H6m4-5.197A5.589 5.589 0 0 0 7.11 2C3.92 2 1.333 4.686 1.333 8s2.587 6 5.778 6c1.052 0 2.04-.292 2.889-.803" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                        <span className="text-xs text-gray-700 font-medium">Log Out</span>
                    </React.Fragment>
                )
                }
                {logOut == "client" && (
                    <button className="logout_btn">
                        <span className="icons-wrap"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18 8L22 12M22 12L18 16M22 12H9M15 4.20404C13.7252 3.43827 12.2452 3 10.6667 3C5.8802 3 2 7.02944 2 12C2 16.9706 5.8802 21 10.6667 21C12.2452 21 13.7252 20.5617 15 19.796" stroke="#5786CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg></span>
                        <span className="text-wrap">Logout</span>
                    </button>
                )}
            </div>

        </React.Fragment>
    )
}
export default LogoutUser