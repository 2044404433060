import React, { useState, useCallback, useEffect } from "react";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../../../common/element/Select/Select";
import Upload from "../../../../../../../common/element/Upload";
// import DocumentsList from "./DocumentsList";
import Dialog from "../../../../../../../common/element/Dialog/Dialog";
import { GetAllDocType } from "../../../../../../../actions/provider-portal/worker/getAllDocumentTypeList";
// import Validations from "../../../../../../common/404/Validations";
import ClientInput from "../../../../../../../common/element/Input/clientInput";
import { PostDoctype } from "../../../../../../../actions/provider-portal/worker/postDocTypeData";
import { useDispatch, useSelector } from "react-redux";
// import { debounce } from "../../../../../../common/snippet/Debouncing";
// import { GetAllDocumentsOfProvider } from "../../../../../../actions/admin-provider/GetAllDocumentsOfSpecificContractor";
import DatePicker from "../../../../../../../common/element/DatePicker";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import OldDocument from "./OldDocument";
import Scrollbar from "../../../../../../../common/element/ScrollBar";
const AddDocument = ({
  docTypeName,
  updateData,
  closePannel,
  closeAddDocPannel,
  indivisualData,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { specificWorker } = useSelector((state) => state);
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const dateGap = 0;
  let function_assignment_uuid = function_assignment_uuid;
  let error = "This field is required!";

  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [sizeError, SetsizeError] = useState("");
  const [wordError, SetwordError] = useState("");
  const [refetch, Setrefetch] = useState(false);
  const [dialogChoose, setChoose] = useState(false);
  const [docTypeError, setDocTypeError] = useState(false);
  const [addDocForm, setaddDocForm] = useState("");
  const [checkReqiuredField, SetcheckReqiuredField] = useState(false);
  const [documentFormData, SetDocumentFormData] = useState({
    doc_name: "",
    checklist_doc_uuid: docTypeName?.checklist_doc_uuid,
    client_org_uuid: indivisualData.client_org_uuid,
    document_type_uuid: docTypeName?.document_type_uuid,
    provider_org_uuid: loginUserData?.organisation_uuid,
    function_assignment_uuid: function_assignment_uuid,
    worker_uuid: specificWorker?.worker_uuid,
    individual_uuid: specificWorker?.worker_individual?.individual_uuid,
  });

  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Worker",
          url: "/admin/worker/view-all-worker",
          home: "/admin/worker/dashboard",
        },
        {
          label: "Annette Souter",
          url: `/admin/worker/view-all-worker/specific-worker/${id}`,
        },
        {
          label: "Clients Assigned",
          url: `/admin/worker/view-all-worker/specific-worker/${id}`,
        },
      ])
    );
  }, []);

  const openDialog = () => {
    setIsOpen(true);
  };

  // Handle form
  const handleForm = (event, expireDate, custom_field) => {
    SetDocumentFormData((prevalue) => {
      if (custom_field == "doctype_fields_data") {
        return {
          ...prevalue,
          doctype_fields_data: {
            ...prevalue.doctype_fields_data,
            [expireDate]: event.target.value,
          },
        };
      } else {
        if (expireDate === "expiry_date" || expireDate === "issue_date") {
          return {
            ...prevalue,
            [expireDate]: event,
          };
        } else {
          if (event?.name == "document_type_uuid") {
            return {
              ...prevalue,
              [event.name]: event?.document_type_uuid,
            };
          } else if (event.name == "is_doctype_swms") {
            return {
              ...prevalue,
            };
          } else if (event.length || event.length === 0) {
            if (event.length === 0) {
              SetsizeError("");
              return {
                ...prevalue,
                ["doc_file"]: event,
              };
            }
            if (/\.(pdf)$/i.test(event?.[0]?.name)) {
              if (event?.[0]?.size <= 2 * 1024 * 1024) {
                SetsizeError("");
                return {
                  ...prevalue,
                  ["doc_file"]: event,
                };
              } else {
                SetsizeError("File size exceeds the maximum limit of 2MB.");
                return {
                  ...prevalue,
                  ["doc_file"]: "",
                };
              }
            } else {
              SetsizeError("Only PDF and image files will be accepted");
              return {
                ...prevalue,
                ["doc_file"]: "",
              };
            }
          } else {
            if (event.target.name === "document_desc") {
              // Get the current value of the document_desc field
              const currentValue = event.target.value;
              // Split the current value into words
              const words = currentValue.split(/\s+/);
              // Check if the word count is less than or equal to 250
              if (words.length <= 250) {
                SetwordError("");
                // If the condition is met, update the state
                return {
                  ...prevalue,
                  [event.target.name]: currentValue,
                };
              } else {
                SetwordError(
                  "Additional information must not exceed 250 words."
                );
                const limitedInput = words.slice(0, 250).join(" ");
                return {
                  ...prevalue,
                  [event.target.name]: limitedInput,
                };
              }
            } else {
              return {
                ...prevalue,
                [event.target.name]: event.target.value,
              };
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    if (dialogChoose) {
      dispatch(
        GetAllDocType({
          recipient_type: "provider",
          // document_type: e.value,
          function_assignment_uuid: function_assignment_uuid,
        })
      );
    }
  }, [dialogChoose]);

  const onChooseClose = (e) => {
    setChoose(false);
    setDocTypeError(false);
    setaddDocForm("");
    SetDocumentFormData({
      doc_name: "",
      organisation_uuid: id,
      function_assignment_uuid: function_assignment_uuid,
    });
  };

  const onDialogClose = (e) => {
    setIsOpen(false);
    onChooseClose();
    onAddDialogClose();
  };
  const openAddDialog = () => {
    SetcheckReqiuredField(true);
    let isFormDataValid = true;
    if (
      (documentFormData.hasOwnProperty("expiry_date")
        ? documentFormData.expiry_date
        : true) &&
      documentFormData.doc_name
    ) {
      isFormDataValid = true;
    } else {
      isFormDataValid = false;
    }
    if (isFormDataValid) {
      dispatch(PostDoctype(documentFormData))
        .then((response) => {
          if (response.success) {
            setAddOpen(true);
            setIsOpen(false);
            Setrefetch(!refetch);
            SetDocumentFormData([]);
            updateData({
              id: docTypeName.compliance_doc_uuid,
              name: documentFormData.doc_name,
            });
            SetcheckReqiuredField(false);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {});
    }
  };

  const onAddDialogClose = (e) => {
    setAddOpen(false);
    closePannel(true);
  };

  useEffect(() => {
    // Calculate the scroll height and set the textarea height accordingly
    const textarea = document.querySelector(".moduleDescTextarea");
    if (textarea) {
      textarea.style.minHeight = "38px";
      textarea.style.height = `${Math.max(textarea.scrollHeight, 38)}px`;
      textarea.style.maxHeight = "400px";
      if (textarea.textLength === 0) {
        textarea.style.height = "38px";
      }
    }
  }, [documentFormData]);

  // Min Date
  const minDate = dayjs(new Date())
    .subtract(dateGap, "day")
    .startOf("day")
    .toDate();

  return (
    <React.Fragment>
      <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
        <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
          <img src="/assets/icons-svg/file-check.svg" alt="" />
        </span>
        <div>
          <h5 className="mb-0 text-gray-900 text-base font-semibold">
            Add Document
          </h5>
        </div>
      </div>
      <div className="px-6">
        <div className="flex mb-5">
          <label className="font-medium text-gray-700 w-1/4">
            Select document
          </label>
          <div className="w-3/4 pl-2">
            <Select
              className="w-full h-10 select-options"
              placeholder="Select Validation Criteria"
              // options={docTypeOptions}
              value={{
                value: docTypeName?.document_type?.doc_type_name,
                label: docTypeName?.document_type?.doc_type_name,
              }}
              // onChange={handleForm}
              readOnly={true}
            ></Select>
            {/* {checkReqiuredField &&
                documentFormData?.is_doctype_swms == "" && (
                  <div className="text-red-500 text-sm mt-1 ">{error}</div>
                )} */}
          </div>
        </div>
        <div className="flex mb-5">
          <label className="font-medium text-gray-700 w-1/4">
            Document name
          </label>
          <div className="w-3/4 pl-2">
            <ClientInput
              name={"doc_name"}
              inputchangehandler={(e) => handleForm(e, "doc_name")}
              className="w-full h-10 select-options"
            />
            {checkReqiuredField && documentFormData.doc_name == "" && (
              <div className="text-red-500 text-sm mt-1 ">{error}</div>
            )}
          </div>
        </div>
        {/* Expire Date */}
        {docTypeName?.document_type?.allow_expiry && (
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Expiry date*
            </label>
            <div className="w-3/4 pl-2 flex">
              <div className="w-full relative">
                <span className="adon_icon absolute left-4 top-3">
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#0ckeddmh9a)">
                      <path
                        d="M17.5 9.291h-15m10.833-6.666v3.333M6.667 2.625v3.333M6.5 19.291h7c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.092-1.093c.273-.534.273-1.234.273-2.635v-7c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.092C15.6 4.29 14.9 4.29 13.5 4.29h-7c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092C2.5 6.191 2.5 6.891 2.5 8.291v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272z"
                        stroke="#344054"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="0ckeddmh9a">
                        <path
                          fill="#fff"
                          transform="translate(0 .958)"
                          d="M0 0h20v20H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <DatePicker
                  onChange={(e) => handleForm(e, "expiry_date")}
                  placeholder="Select expire date"
                  minDate={minDate}
                />
                {checkReqiuredField && documentFormData.expiry_date == "" && (
                  <div className="text-red-500 text-sm mt-1 ">{error}</div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Amout Insured */}
        {docTypeName?.document_type?.req_amount_insured && (
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Amount insured
            </label>
            <div className="w-3/4 pl-2 flex">
              <div className="w-full relative">
                <span className="adon_icon absolute left-2 top-10px">
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 13.789a3.333 3.333 0 0 0 3.333 3.333h3.334a3.333 3.333 0 0 0 0-6.667H8.333a3.333 3.333 0 1 1 0-6.666h3.334A3.333 3.333 0 0 1 15 7.122m-5-5V18.79"
                      stroke="#667085"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <div className="w-full">
                  <ClientInput
                    type={"number"}
                    placeholder="Enter value here"
                    name={"amount_insured"}
                    inputchangehandler={(e) => handleForm(e, "amount_insured")}
                    className="w-full h-10 select-options pl-8"
                  />
                  {checkReqiuredField &&
                    documentFormData.insured_amount == "" && (
                      <div className="text-red-500 text-sm mt-1 ">{error}</div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Policy number */}
        {docTypeName?.document_type?.req_policy_no && (
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Policy number
            </label>
            <div className="w-3/4 pl-2">
              <ClientInput
                name={"policy_no"}
                inputchangehandler={(e) => handleForm(e, "policy_no")}
                className="w-full h-10 select-options"
              />
              {/* {checkReqiuredField && documentFormData.policy_no == "" && (
                  <div className="text-red-500 text-sm mt-1 ">{error}</div>
                )} */}
            </div>
          </div>
        )}

        {/* Issuing authority */}
        {docTypeName?.document_type?.req_issuer && (
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Issuing authority
            </label>
            <div className="w-3/4 pl-2">
              <ClientInput
                name={"issuer"}
                inputchangehandler={(e) => handleForm(e, "issuer")}
                className="w-full h-10 select-options"
              />
              {checkReqiuredField &&
                documentFormData.issuing_authority == "" && (
                  <div className="text-red-500 text-sm mt-1 ">{error}</div>
                )}
            </div>
          </div>
        )}

        {/* Custom Field */}
        {docTypeName?.document_type?.custom_fields?.map((item, index) => {
          return (
            <div className="flex mb-5" key={index}>
              <label className="font-medium text-gray-700 w-1/4">{item}</label>
              <div className="w-3/4 pl-2">
                <ClientInput
                  name={item}
                  inputchangehandler={(e) =>
                    handleForm(e, item, "doctype_fields_data")
                  }
                  className="w-full h-10 select-options"
                />
                {/* {checkReqiuredField &&
                  documentFormData.issuing_authority == "" && (
                    <div className="text-red-500 text-sm mt-1 ">{error}</div>
                  )} */}
              </div>
            </div>
          );
        })}

        {/* Additional info (optional) */}
        {docTypeName?.document_type?.req_expiry_date_notes && (
          <div className="flex mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Additional info
            </label>
            <div className="w-3/4 pl-2">
              <textarea
                name={"expiry_date_notes"}
                onChange={(e) => handleForm(e, "expiry_date_notes")}
                rows={4}
                cols={40}
                placeholder="Provide a brief description to help Inductees..."
                className="moduleDescTextarea input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline h-10"
              />
              {wordError && (
                <div className="text-red-500 text-sm mt-1 ">{wordError}</div>
              )}
            </div>
          </div>
        )}

        <div className="flex mb-5">
          <label className="font-medium text-gray-700 w-1/4">Upload</label>
          <div className="w-3/4 pl-2">
            <BtnLight
              className="w-auto px-4 m-auto"
              onClick={() => openDialog()}
            >
              Select from your uploaded documents
            </BtnLight>
            <span className="text-base font-semibold text-center block my-4">
              Or
            </span>
            {/* <Upload draggable className="w-full" /> */}
            <div className="pl-2">
              <Upload
                uploadLimit={1}
                onChange={handleForm}
                accept={".jpg, .jpeg, .png, .pdf,"}
                draggable
                className="w-full"
                onFileRemove={handleForm}
              >
                <div className="text-center">
                  <div className="flex items-center justify-center flex-col">
                    <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                          stroke="#475467"
                          strokeWidth="1.667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>
                    <span className="pt-4">
                      <span className="text-blue-700 font-semibold mr-1">
                        Click to upload
                      </span>{" "}
                      <span className="text-xs text-gray-600">
                        {" "}
                        (Max 2MB. Only PDF and image files will be accepted)
                      </span>
                    </span>
                  </div>
                </div>
              </Upload>
              {sizeError && (
                <div className="text-red-500 text-sm mt-1">{sizeError}</div>
              )}
              {checkReqiuredField && documentFormData.doc_file == "" && (
                <div className="text-red-500 text-sm mt-1 ">{error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex dailog-footer ">
        <BtnLight className="mr-1.5" variant="plain" onClick={onDialogClose}>
          Cancel
        </BtnLight>
        <BtnPrimary onClick={() => openAddDialog()} className="ml-1.5">
          Add
        </BtnPrimary>
      </div>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Document added
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        width={800}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 2.91211V7.04265C14 7.6027 14 7.88273 14.109 8.09664C14.2049 8.2848 14.3578 8.43778 14.546 8.53366C14.7599 8.64265 15.0399 8.64265 15.6 8.64265H19.7305M14 17.6426H8M16 13.6426H8M20 10.6308V17.8426C20 19.5227 20 20.3628 19.673 21.0045C19.3854 21.569 18.9265 22.028 18.362 22.3156C17.7202 22.6426 16.8802 22.6426 15.2 22.6426H8.8C7.11984 22.6426 6.27976 22.6426 5.63803 22.3156C5.07354 22.028 4.6146 21.569 4.32698 21.0045C4 20.3628 4 19.5227 4 17.8426V7.44258C4 5.76242 4 4.92234 4.32698 4.28061C4.6146 3.71612 5.07354 3.25718 5.63803 2.96956C6.27976 2.64258 7.11984 2.64258 8.8 2.64258H12.0118C12.7455 2.64258 13.1124 2.64258 13.4577 2.72547C13.7638 2.79896 14.0564 2.92017 14.3249 3.08466C14.6276 3.27018 14.887 3.52961 15.4059 4.04847L18.5941 7.23669C19.113 7.75555 19.3724 8.01497 19.5579 8.31772C19.7224 8.58614 19.8436 8.87877 19.9171 9.18488C20 9.53015 20 9.89703 20 10.6308Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Select Document
            </h5>
          </div>
        </div>

        <div className="h-72">
          <Scrollbar>
            <div className="px-5 pb-3">
              <OldDocument
                docTypeName={docTypeName}
                compliance_doc_uuid={docTypeName.compliance_doc_uuid}
                closeAddDocPannel={closeAddDocPannel}
                indivisualData={indivisualData}                     
              />
            </div>
          </Scrollbar>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default AddDocument;
