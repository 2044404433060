import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetAllIndividualListForInvite = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/GetAllIndividualListForInvite`,
        params: apiData
      });
      return response.data
      
    } catch (error) {
        return error.message  
    }
  };


  export const GetAllSpecificOrgInvites = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/GetAllSpecificOrgInvites`,
        params: apiData
      });
      return response.data
      
    } catch (error) {
        return error.message  
    }
  };

  
  export const GetAllSpecificOrgUsers = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/GetAllSpecificOrgUsers`,
        params: apiData
      });
      return response.data
      
    } catch (error) {
        return error.message  
    }
  };

  export const GetOrgUserPermById = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/GetOrgUserPermById`,
        params: apiData
      });
      return response.data
      
    } catch (error) {
        return error.message  
    }
  };


    
export const InviteOrgUser = (form_data) => async (dispatch) => {
  try {
    // dispatch(fetchData());
    let formdata = new FormData();
    for (let key in form_data) {
             formdata.append(key, form_data[key]);
         }

    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/InviteOrgUser`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};



  export const ReinviteOrgUser = (data) => async (dispatch) => {
    try {
      // let formdata = new FormData();
      // for (let key in data) {
      //     formdata.append(key, data[key]);
      // }
      const response = await axios({
        method: 'put',
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/ReinviteOrgUser`,
      });
      return response.data
    } catch (error) {
      return error.message
    }
  }

  export const RemoveOrgUserInvitation = (ApiData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "delete",
        data: ApiData ,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/RemoveOrgUserInvitation`,
      });
      return response.data;
    } catch (error) {}
  };
  
  export const UpdateOrgUserPerm = (data) => async (dispatch) => {
    try {
      let formdata = new FormData();
      for (let key in data) {
          formdata.append(key, data[key]);
      }
      const response = await axios({
        method: 'put',
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/UpdateOrgUserPerm`,
      });
      return response.data
    } catch (error) {
      return error.message
    }
  }
