
import * as actionType from "../constant/actionType";

const initialState = {
    allRole: '', 
};

const AllRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_ALL_ROLE:
      return {
        ...state,
        allRole: action.payload,
      };
    default:
      return state;
  }
};

export default AllRoleReducer;