import React, { useState,useEffect } from 'react'
import Heading from '../../../../../common/element/Heading';
import { setBreadcrumbs } from '../../../../../redux/classes/breadcrumbsSlice';
import { useDispatch,useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom"
const Invoices = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const [forceRender, setForceRender] = useState(false);
    const permissionDataUpdate = useSelector((state) => state?.permissions?.permissionData);

    useEffect(() => {
        dispatch(setBreadcrumbs([{ label: "Invoices", url: "/client/invoices", home: "/client/dashboard" }]));
    }, []);

    useEffect(() => {
        if (permissionDataUpdate !== null) {
          localStorage.setItem("permission_data", JSON.stringify(permissionDataUpdate));
          setForceRender(prevForceRender => !prevForceRender);
          if (permissionDataUpdate?.invoices_view == false) {
            //   navigate('/client/dashboard');
            navigate('/client/noaccess')
          }
        }
      }, [permissionDataUpdate]);
    return (
        <React.Fragment>

            <Heading>Invoices</Heading>
            <p className="text-gray-600">Non morbi vitae turpis leo scelerisque nisl phasellus nisi. Commodo.</p>

        </React.Fragment>
    )
}
export default Invoices