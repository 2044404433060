import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Dialog from "../../../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../../../../common/element/Table";
import ThreeDotLoader from "../../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import { useSelector } from "react-redux";
import moment from "moment";
import { DeleteInducteeSiteDetails } from "../../../../../../../actions/Admin-Client/AllSiteApi";
import InducteesInfo from "./InducteesInfo";
import { useParams } from "react-router-dom";

const InducteesList = ({ handleSort, getAllinvitedData,provider_org_uuid ,setApiCalls}) => {
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const { Tr, Th, Td, THead, TBody } = Table;
  const [specificData, setSpecificData] = useState();
  const [data, SetData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [individual_uuid, setIndividual_uuid] = useState("");
  const [workerUuid, setWorkerUuid] = useState("")
  const [deleteDiolog, setdeleteDiolog] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null)
  const onDeleteDialogClose = () => {
    if(!buttonDisabled){
      setdeleteDiolog(false)
    }
  }
  const handleDelete = (item) => {
    setDeleteItem(item)
    setdeleteDiolog(true)
  }


  const onConfirmDelete = async () => {
    setButtonDisabled(true);
    try {
      
      const payload = {site_uuid:siteId , worker_uuid:deleteItem?.worker_uuid }
      // DeleteInducteeSiteDetails replace this API  and change data according to requirements (deleteItem)
      const response = await dispatch(DeleteInducteeSiteDetails(payload));
      if (response?.success) {
        setButtonDisabled(false)
        onDeleteDialogClose()
        setDeleteItem(null)
        setApiCalls(false)
        // apply 
      } else {
        console.error("Failed to delete site:", response.message);
      }
    } catch (error) {
      console.error("Error deleting site:", error);
    } finally {
      setButtonDisabled(false)
    }
  };
  const openDialog = (item) => {
    setIsOpen(true);
    setSpecificData(item);
    setIndividual_uuid(item);
    setWorkerUuid(item?.worker_uuid)

  };
  const onDialogClose = (item) => {
    setIsOpen(false);
  };

  const GettAllFunctions = useSelector((state) => state);
  useEffect(() => {
    if (GettAllFunctions?.ClientPortalInducteesData?.data?.data?.rows) {
      SetData(GettAllFunctions?.ClientPortalInducteesData?.data?.data?.rows);
    } else {
      SetData([]);
    }
  }, [GettAllFunctions]);

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead className="bg-gray-100">
            <Tr>
              <Th
                scope="col"
                className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 w-1/5"
              >
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("first_name")}
                >
                  Worker Name
                  <svg
                    className={`transform ${getAllinvitedData.sort !== "" &&
                      getAllinvitedData.sort === "first_name" &&
                      getAllinvitedData.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th
                scope="col"
                className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600  "
              >
                <span className="inline-flex items-center">Email</span>
              </Th>

              <Th
                scope="col"
                className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600"
              >
                Phone number
              </Th>
              <Th
                scope="col"
                className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 "
              >
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("worker_job_title")}
                >
                  Job Title{" "}
                  <svg
                    className={`transform ${getAllinvitedData.sort !== "" &&
                      getAllinvitedData.sort === "worker_job_title" &&
                      getAllinvitedData.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th
                scope="col"
                className="bg-gray-100 px-4 py-3  font-medium text-left text-gray-600 "
              >
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("trading_name")}
                >
                  Company name
                  <svg
                    className={`transform ${getAllinvitedData.sort !== "" &&
                      getAllinvitedData.sort === "trading_name" &&
                      getAllinvitedData.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th
                scope="col"
                className="bg-gray-100 px-4 py-3  font-medium text-left text-gray-600 "
              >
                <span className="flex items-center gap-1" onClick={() => handleSort("abn_nzbn")}>
                  ABN
                  <svg
                    className={`transform ${getAllinvitedData.sort !== "" &&
                      getAllinvitedData.sort === "abn_nzbn" &&
                      getAllinvitedData.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th
                scope="col"
                className="bg-gray-100 px-4 py-3  font-medium text-left text-gray-600 "
              >
                <span className="flex items-center gap-1">State/Country</span>
              </Th>
              <Th></Th>
            </Tr>
          </THead>
          <TBody>
            {data ? (
              data?.map((item, index) => (
                <Tr key={index}>
                  <Td className="px-4 py-1.5 font-medium text-gray-900 ">
                    <span
                      className="block cursor-pointer"
                      onClick={() => openDialog(item)}
                    >
                      {item?.first_name ? item?.first_name : "-"} {item?.last_name ? item?.last_name : ""}
                    </span>
                  </Td>
                  <Td className="px-4 py-1.5 font-medium text-gray-900">
                    <span className="text-gray-900 font-medium block">
                      {item?.email ? item?.email : "-"}
                    </span>
                  </Td>
                  <Td className="px-4 py-1.5 font-medium text-gray-900">
                    {item?.phone ? item?.phone : "-"}
                  </Td>
                  <Td className="px-4 py-1.5 font-medium text-gray-900">
                    {item?.worker_job_title ? item?.worker_job_title : "-"}
                  </Td>
                  <Td className="px-4 py-1.5 font-medium text-gray-900">
                    {item?.org_name ? item?.org_name : "-"}
                  </Td>
                  <Td className="px-4 py-1.5 font-medium text-gray-900">
                    {item?.abn_nzbn ? item?.abn_nzbn : "-"}
                  </Td>
                  <Td className="px-4 py-1.5 font-medium text-gray-900">
                    <span className="text-gray-900 font-medium block">
                      {/* {item?.state_name} */}
                    </span>
                    <span className="text-gray-600">{item?.state_name ? item?.state_name : "-"}</span>
                  </Td>
                  <Td className="px-4">
                    <button onClick={() => handleDelete(item)}>
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                          stroke="#475467"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </Td>
                </Tr>
              ))
            ) : (
              ""
            )}

          </TBody>
        </Table>
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/cons-worker.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              {specificData?.first_name} {specificData?.last_name}
            </h5>
            <p className="text-gray-600 ">Worker Details</p>
          </div>
        </div>

        <InducteesInfo DetailData={specificData} individual_uuid={individual_uuid} workerUuid={workerUuid} onDialogClose={onDialogClose}/>
      </Dialog>
      <Dialog
        isOpen={deleteDiolog}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete?
          </p>
          <p className="text-gray-600 text-center">
            This action cannot be undone
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight disabled={buttonDisabled} className="mr-1.5" onClick={onDeleteDialogClose}>
            Cancel
          </BtnLight>
  
          <BtnPrimary disabled={buttonDisabled} className="ml-1.5" onClick={onConfirmDelete}>
            {buttonDisabled ? <ThreeDotLoader /> : "Delete"}
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default InducteesList;
