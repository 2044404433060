import React, { useState } from "react";
import Scrollbar from "../../../../../common/element/ScrollBar";
import Input from "../../../../../common/element/Input";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import RichTextEditor from "../../../../../common/snippet/RichTextEditor";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
const EmailUs = () => {
  const loginUserData = JSON.parse(localStorage.getItem("LoginUserData"));
  const fullName = `${loginUserData.userName.first_name} ${loginUserData.userName.last_name}`;

  const [emailData, setEmailData] = useState({
    fullname: fullName,
    email: loginUserData.userName.email,
    subject:'',
    help:''
  })

  const handleChange = (key, value) => {
    setEmailData(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };
  
  return (
    <React.Fragment>
      <div className="flex border-b  pb-5 px-6 pt-6">
        <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
          <img src="/assets/icons-svg/email.svg" alt="" />
        </span>
        <div>
          <h5 className="mb-1 text-gray-900 text-base font-semibold">
          Email us
          </h5>
          <p className="text-gray-600 ">
          Directly engage with a Conserve team member. We aim to reply within a few hours during 9am-5pm from Mon-Fri.
          </p>
        </div>
      </div>
      <div className="chat-block p-6 h-96 pr-2">
        <Scrollbar>
          <div className="flex gap-3 pr-4">
            <div className="flex-1 mb-5">
                <label className="text-gray-700 font-medium text-xs block mb-1">Name</label>
                <Input
                  name="fullname" 
                  className="h-9 w-full" 
                  placeholder="[Auto-populate user's name]" 
                  value={emailData.fullname}
                  inputchangehandler={(e) => handleChange("fullname", e.target.value)}
                />
            </div>
            <div className="flex-1 mb-5">
                <label className="text-gray-700 font-medium text-xs block mb-1">Email</label>
                <Input 
                  name="email"
                  className="h-9 w-full" 
                  placeholder="[Auto-populate user's email]"
                  value={emailData.email}
                  onChange={(e) => handleChange("email", e.target.value)} 
                />
            </div>
          </div>
          <div className="flex mb-5 flex-col pr-4">
                <label className="text-gray-700 font-medium text-xs block mb-1">Subject</label>
                <Input 
                  name="subject"
                  className="h-9 w-full" 
                  placeholder="[Auto-populate user's email]"
                  value={emailData.subject}
                  onChange={(e) => handleChange("subject", e.target.value)}  
                />
          </div>
          <div className="flex mb-5 flex-col email-editer pr-4">
                <label className="text-gray-700 font-medium text-xs block mb-1">How can we help?</label>
                <RichTextEditor
                  value={emailData.help}
                />
          </div>
     
        </Scrollbar>
      </div>
      <div className="border-t border-gray-200 p-6 ">
        <div className="flex gap-3 justify-end">
          <BtnLight className="w-28">Cancel</BtnLight>
          <BtnPrimary className="flex items-center gap-1 w-32 justify-center">
          Send email
           
          </BtnPrimary>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailUs;
