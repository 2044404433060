import React  from 'react'
import LeftSideWrap from './leftSideWrap'
function WorkerPasswordCreation() {
return (
<div style={{ backgroundColor: `#eff8ff` }} className="flex justify-center min-h-screen">
    <div className="min-h-screen justify-center flex  py-12 px-6">
        <LeftSideWrap/>
    </div>
</div>
)
}
export default WorkerPasswordCreation