// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const updateRoute = createSlice({
  name:"updateRoute",
  initialState:false,
  reducers:{
    updateRoutefunc(state,action){
      return action.payload;
    },
  }
}) 
export default updateRoute.reducer;
export const {updateRoutefunc}=updateRoute.actions;