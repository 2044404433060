import axios from "axios";
import * as actionType from "../../../constant/actionType";
import {
  fetchData,
  fetchDataSuccess,
  fetchDataError,
} from "../../../redux/classes/admin-worker/GetWorkerSiteLogsSlice";
export const GetWorkerSiteLogs = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    dispatch(fetchData());
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/worker/GetWorkerSiteLogs`,
      params: apiData,
    });
    dispatch(fetchDataSuccess(response.data));
  } catch (error) {
    dispatch(fetchDataError(error.message));
  }
};
