import axios from "axios";
import * as actionType from "../../../constant/actionType";
// import {
//   fetchData,
//   fetchDataSuccess,
//   fetchDataError,
// } from "../../../redux/classes/client-portal/clientCsvSlice";
import {
  fetchData,
  fetchDataSuccess,
  fetchDataError,
} from "../../../redux/classes/provider-portal/GetSpecificWorkerIndSlice";

export const GetAllDocsOfWorker = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      url: `${actionType.environment_url}/api/provider/worker/GetAllDocsOfWorker`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: ApiData,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching documents:", error);
    return { success: false, message: error.message };
  }
};

export const GetWorkerrDocById = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/worker/GetWorkerrDocById`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const UpdateWorkerDoc = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === "column_names") {
        formdata.append(key, JSON.stringify(ApiData[key]));
      } else {
        // For other properties, append the value directly
        formdata.append(key, ApiData[key]);
      }
    }
    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/worker/UpdateWorkerDoc`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const GetWorkerDocHistoryById = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    // dispatch(fetchData())
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/worker/GetWorkerDocHistoryById`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    // dispatch(fetchDataError(error.message))
    return { success: false, message: error.message };
  }
};
export const AdminGetAllWorkers = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    // dispatch(fetchData())
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/worker/AdminGetAllWorkers`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    // dispatch(fetchDataError(error.message))
    return { success: false, message: error.message };
  }
};

export const GetAllDocType = (getAllinvitedData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/GetAllDocumentTypeList`,
      params: getAllinvitedData,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const PostDoctype = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === "doc_file") {
        formdata.append("doc_file", ApiData.doc_file[0]);
      } else if (key === "doctype_fields_data") {
        formdata.append(key, JSON.stringify(ApiData[key]));
      } else {
        formdata.append(key, ApiData[key]);
      }
    }
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/worker/CreateWorkerDoc`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const DeleteProviderDoc = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/worker/DeleteWorkerDoc`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};



export const GetIndWorkerByAssignedClients = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    dispatch(fetchData());
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/worker/GetIndStatusOfWorkerByAssignedClients`,
      params: apiData,
    });
    dispatch(fetchDataSuccess(response.data));
  } catch (error) {
    dispatch(fetchDataError(error.message));
  }
};
