import axios from "axios";
import * as actionType from "../../constant/actionType";
export const GetAllStatesbyCountryId = (apidata) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/GetAllStatesbyCountryId`,
        params: {country_id:apidata}
      });
      return response.data
    } catch (error) {
      return error.message
    }
  };