import React from "react";
import Notification, { notify } from 'react-notify-toast';
import Validations from "../../../common/404/Validations";
import Heading from '../../../common/element/Heading'
import BtnPrimary from '../../../common/element/Buttons/btnPrimary'
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

// Redirect Page function
export const Loginredirect = ({ redirectUrl }) => {
  const Navigate = useNavigate()
  useEffect(() => {
    Navigate(redirectUrl)
  }, [Navigate])
}

class Authentication extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      authentication_form: {
        Password: {
          type: "password",
          label: "Password",
          value: "",
          valid: true,
          error_msg: "",
          onBlur_out: false,
          placeholder: "",
          Validations: { required: true },
        },
      },
      // isLoading: true, // Set initial state for loading
      is_form_valid: false,
      passwordshow: false,
      redirect_url: "",
      isAuthenticated: false,
    };
  }

  inputChangeHandler(event, identifier) {
    const update_authentication_form = { ...this.state.authentication_form };
    const form_element = { ...update_authentication_form[identifier] };
    form_element.value = event.target.value;

    // Check Validation
    let validate_element = Validations(form_element);
    form_element.valid = validate_element.valid;
    form_element.error_msg = validate_element.error_msg;

    update_authentication_form[identifier] = form_element;
    let is_form_valid = true;
    for (let identifier in update_authentication_form) {
      is_form_valid = update_authentication_form[identifier].valid && is_form_valid;
    }

    this.setState({
      authentication_form: update_authentication_form,
      is_form_valid: is_form_valid,
    });
  }

  
  
  loginSubmitHandler = (event) => {
    event.preventDefault();

    // Check Validation
    let is_form_valid = true;
    if (!this.state.is_form_valid) {
      const update_authentication_form = this.state.authentication_form;
      for (let key in update_authentication_form) {
        let form_element = update_authentication_form[key];
        let validate_element = Validations(form_element);

        is_form_valid = validate_element.valid === false ? false : is_form_valid;
        form_element.onBlur_out = true;
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_authentication_form[key] = form_element;
      }
      this.setState({
        authentication_form: update_authentication_form,
        is_form_valid: is_form_valid,
      });
    }
    
    // Form submit handler
    if (this.state.is_form_valid) {
      const update_authentication_form = this.state.authentication_form;
      if (update_authentication_form.Password.value === "eR7b8GX#hN5G5s$z") {
        this.setState({
          isAuthenticated: true,
          redirect_url: "/login",
        });
        localStorage.setItem("Conserve_http_Authenticated", "true");
      } else {
        notify.show("Invalid password", "error");
      }
    }
  };

  render() {
    const { authentication_form } = this.state;
    // const { isLoading } = this.state;
    return (
      // <React.Fragment>
      //   {/* <Notifications /> */}
      //   {isLoading ? (
      //    <Loader/>
      //   ) : (
          <React.Fragment>
            {/* <Notifications /> */}

            {this.state.loader ? 0 : null}
            {this.state.isAuthenticated ? <Loginredirect redirectUrl={this.state.redirect_url} /> : null}
            <div className="flex flex-col bg-blue-50 min-h-screen justify-center items-center">
              <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-column flex-lg-row-fluid py-9">
                  <div className="d-flex flex-center flex-column-fluid">
                    <div className="w-lg-600px  pb-0 pt-0 mx-auto w-96">
                      <form className="form w-96" autoComplete="off" onSubmit={this.loginSubmitHandler}>
                        <div className="mb-5">
                          <Heading>Authentication</Heading>
                        </div>
                        <div className={"fv-row mb-5" + (!authentication_form.Password.valid && authentication_form.Password.onBlur_out ? " error-field" : "")}>
                          <label className="block text-gray-700  mb-2 text-xs font-medium">{authentication_form.Password.label}</label>
                          <div className="position-relativex">
                            <input
                              type={this.state.passwordshow ? "text" : "Password"}
                              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              placeholder={authentication_form.Password.placeholder}
                              value={authentication_form.Password.value ? authentication_form.Password.value : ""}
                              onChange={(event) => this.inputChangeHandler(event, "Password")}
                              onBlur={(event) => {
                                authentication_form.Password.onBlur_out = true;
                                this.setState({
                                  authentication_form: authentication_form,
                                });
                              }}
                            />
                            <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                              <i
                                className={this.state.passwordshow ? "opne" : "close"}
                                onClick={(event) => {
                                  this.setState({ passwordshow: this.state.passwordshow ? false : true });
                                }}
                              ></i>
                            </span>
                          </div>
                          {!authentication_form.Password.valid && authentication_form.Password.onBlur_out ? <div className="text-error-500 text-xs font-semibold">{authentication_form.Password.error_msg}</div> : ""}
                        </div>
                        <div className="text-center">
                          <BtnPrimary type="submit" className="btn btn-lg btn-primary rounded-50 w-100 mb-8">
                            <span className="indicator-label">Log in</span>
                          </BtnPrimary>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>

      //   )}
      // </React.Fragment>
    );
  }
}
export default Authentication;