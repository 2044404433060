import React,{useState} from "react";
import ActiveUser from "./ActiveUser";
import InvitedUser from "./InvitedUser";

const UserList = (props) => {
  return (
    <React.Fragment>

     <ActiveUser fetchAllSpecificOrgUsers={props?.fetchAllSpecificOrgUsers}  usersPermission={props?.usersPermission} allUser={props?.allUser}/>
     <InvitedUser fetchAllSpecificOrgInvites= {props?.fetchAllSpecificOrgInvites} usersPermission={props?.usersPermission} invitedUser={props?.invitedUser} onInvitedUser={props?.onInvitedUser}/>
    </React.Fragment>
  );
};
export default UserList;