import { createSlice } from '@reduxjs/toolkit';

const SpecificWorkerData = createSlice({
    name: "SpecificWorkerData",
    initialState: [],
    reducers: {
        SpecWorker(state, action) {
            return action.payload;
        },
        resetSpecWorkerData(state) {
            return []; 
        }
    }
});

export default SpecificWorkerData.reducer;
export const { SpecWorker, resetSpecWorkerData } = SpecificWorkerData.actions;
