


// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const ProviderContactData = createSlice({
  name:"ProviderContactData",
  initialState:[],
  reducers:{
    GetProviderContactData(state,action){
      return (action.payload)
    },
    ResetProviderContactData(){
      return []
    },
  }
}) 
export const {GetProviderContactData,ResetProviderContactData}=ProviderContactData.actions;
export default ProviderContactData.reducer;