import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const UpdateCompanyInductionStatus = (ApiData) => async (dispatch) => {
      try {
          

        const response = await axios({
          method: 'put',
          data: ApiData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
          },
          url: `${actionType.environment_url}/api/client/UpdateCompanyInductionStatus`,
        });
        // dispatch(fetchDataSuccess(response.data));
        return response.data
      } catch (error) {
        return error.message
        // dispatch(fetchDataError(error.message));
      }
    }