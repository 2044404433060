import Card from "../../../common/element/Card";
import Heading from "../../../common/element/Heading";
import BtnPrimary from "../../../common/element/Buttons/btnPrimary";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import ConnectClient from "./Registration/ConnectClient";
import Dialog from "../../../common/element/Dialog";
import Validations from "../../../common/404/Validations";
import { useSelector } from "react-redux";
import { GetProviderContactData } from "../../../redux/classes/provider-portal/ProviderContactData";
const ProviderPasswordCreation = () => {
  const { ProviderContactData } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [hiddenpassword, sethiddenpassword] = useState({
    Password: true,
    ConfirmPassword: true,
  });
  const [passwordError, setPasswordError] = useState("");
  const [formData, SetFormData] = useState({ firstName: "", lastName: "" });
  const [error, SetError] = useState({ firstName: "", lastName: "" });

  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const location = useLocation();
  const [repeatPassword, setRepeatPassword] = useState("");

  const togglePassword = (field) => {
    sethiddenpassword((prevalue) => {
      return {
        ...prevalue,
        [field]:
          field == "Password"
            ? !hiddenpassword.Password
            : !hiddenpassword.ConfirmPassword,
      };
    });
  };


  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleRepeatPassword = (event) => {
    setRepeatPassword(event.target.value);
  };

  const handleErrorMessage = () => {
    const isStrong = newPassword.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
    );
    setPasswordError(
      isStrong
        ? "" // No error if the password is strong
        : "Your password must be at least 8 characters long, contain at least one special character, and have a mixture of lowercase, uppercase, and numbers."
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //Validate Password
    const setFormError = (field, message) => {
      SetError((prevFormErrors) => ({
        ...prevFormErrors,
        [field]: message,
      }));
    };
    const firstName = Validations({
      value: formData.firstName,
      Validations: { required: true },
    });
    const lastName = Validations({
      value: formData.lastName,
      Validations: { required: true },
    });

    if (!firstName.valid) {
      setFormError("firstName", firstName.error_msg);
    }
    if (!lastName.valid) {
      setFormError("lastName", lastName.error_msg);
    }

    const isStrong = newPassword.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
    );

    setPasswordError(
      isStrong
        ? "" // No error if the password is strong
        : "Your password must be at least 8 characters long, contain at least one special character, and have a mixture of lowercase, uppercase, and numbers."
    );
    if (!isStrong) {
      return; // If the password is not strong, don't proceed with the submission
    }

    if (!repeatPassword) {
      setPasswordError("To continue, please enter your confirmed password");
      return;
    } else if (newPassword !== repeatPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    if (!firstName.valid || !lastName.valid) return;
    let UpdateData = { ...ProviderContactData, ...{repeatPassword:repeatPassword} };
    dispatch(GetProviderContactData(UpdateData));
    navigate(
      `/provider-invite/${
        window.location.href.split("/")[4]
      }/provider-registration`
    );
  };

  // const openDialog = () => {
  //   setIsOpen(true);
  // };

  const onDialogClose = () => {
    setIsOpen(false);
  };

  const handleForm = (value, field) => {
    SetError((prevalue) => {
      return {
        ...prevalue,
        [field]: "",
      };
    });
    SetFormData((prevalue) => {
      return {
        ...prevalue,
        [field]: value.target.value,
      };
    });
  };

  useEffect(() => {
    SetFormData({
      firstName: ProviderContactData?.invitedProvider?.first_name,
      lastName: ProviderContactData?.invitedProvider?.last_name,
    });
  }, []);

  

  return (
    <React.Fragment>
      <div
        style={{ backgroundColor: "#eff8ff" }}
        className="flex justify-center min-h-screen"
      >
        <div className="min-h-screen justify-center flex  py-12 px-6">
          <div className="flex-col items-center justify-center flex">
            <div className="flex items-center mb-6">
              <img
                className="brand-img"
                src="/assets/logo.svg"
                alt="conserve"
              />
            </div>
            <Heading className="text-center mb-3">Create your account</Heading>
            <p className="text-base text-gray-600 text-center mb-6 font-medium">
              Congratulations on being invited to join your Client on the
              <br />
              Conserve app. Create your password to get started.
            </p>
            <Card className="w-500 rounded-xl block-shadow w-100 mt-2 px-10 py-8">
              {/* <div className="w-full">
                <form>
                  <div className="mb-5">
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      htmlFor="Email"
                    >
                      First name*
                    </label>
                    <input
                      className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      type="email"
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      htmlFor="Email"
                    >
                      Last name*
                    </label>

                    <input
                      className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      type="email"
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className="mb-6 ">
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <div className="relative flex">
                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type={hiddenpassword ? "password" : "text"}
                        placeholder=""
                        value={newPassword}
                        onChange={(event) => handleNewPassword(event)}
                        onBlur={handleErrorMessage}
                      />
                      <div className="input-suffix-start right-set">
                        <span
                          className="gray-svg cursor-pointer"
                          onClick={togglePassword}
                        >
                          {hiddenpassword ? (
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="21"
                              width="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                      <button className="absolute right-4 top-3">
                        <img src="/assets/icons-svg/eye.svg" alt="#" />
                      </button>
                    </div>
                  </div>
                  <div className="mb-6">
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      htmlFor="password"
                    >
                      Confirm Password*
                    </label>
                    <div className="relative flex">
                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type="password"
                        placeholder="••••••••"
                      />
                      <button className="absolute right-4 top-3">
                        <img src="/assets/icons-svg/eye.svg" alt="#" />
                      </button>
                    </div>
                  </div>

                  <div className="flex items-center justify-between w-100">
                    <BtnPrimary onClick={openDialog}>
                      <div
                        to="email-confirmation"
                        className="flex justify-center"
                      >
                        <span className="pr-2">Continue </span>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.167 10.487h11.666m0 0L10 4.654m5.833 5.833L10 16.321"
                            stroke="#fff"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </BtnPrimary>
                  </div>
                  
                </form>
                
              </div> */}

              <div className="w-full">
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <div className="mb-5">
                      <label
                        className="block text-gray-700  mb-2 text-xs font-medium"
                        htmlFor="firstName"
                      >
                        First name*
                      </label>
                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="firstName"
                        value={formData.firstName}
                        disabled={true}
                        type="text"
                        placeholder="Enter your first name"
                        onChange={(event) => handleForm(event, "firstName")}
                      />
                      {error.firstName && (
                        <div className="flex flex-column">
                          <p className="text-xs text-red-600 mt-2">
                            {error.firstName}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="mb-5">
                      <label
                        className="block text-gray-700  mb-2 text-xs font-medium"
                        htmlFor="lastName"
                      >
                        Last name*
                      </label>

                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="lastName"
                        type="text"
                        disabled={true}
                        value={formData.lastName}
                        placeholder="Enter your last name"
                        onChange={(event) => handleForm(event, "lastName")}
                      />
                      {error.lastName && (
                        <div className="flex flex-column">
                          <p className="text-xs text-red-600 mt-2">
                            {error.lastName}
                          </p>
                        </div>
                      )}
                    </div>
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      htmlFor="Email"
                    >
                      Password*
                    </label>
                    <div className="input-wrapper">
                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type={hiddenpassword.Password ? "password" : "text"}
                        placeholder=""
                        value={newPassword}
                        onChange={(event) => handleNewPassword(event)}
                        onBlur={handleErrorMessage}
                      />
                      <div className="input-suffix-start right-set">
                        <span
                          className="gray-svg cursor-pointer"
                          onClick={() => togglePassword("Password")}
                        >
                          {hiddenpassword.Password ? (
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="21"
                              width="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label
                      className="block text-gray-700  mb-2 text-xs font-medium"
                      htmlFor="password"
                    >
                      Confirm Password*
                    </label>
                    <div className="input-wrapper">
                      <input
                        className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type={
                          hiddenpassword.ConfirmPassword ? "password" : "text"
                        }
                        placeholder=""
                        value={repeatPassword}
                        onChange={(event) => handleRepeatPassword(event)}
                      />
                      <div className="input-suffix-start right-set">
                        <span
                          className="gray-svg cursor-pointer"
                          onClick={() => togglePassword("ConfirmPassword")}
                        >
                          {hiddenpassword.ConfirmPassword ? (
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="21"
                              width="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </div>
                    {
                      <div className="flex flex-column">
                        <p className="text-xs text-red-600 mt-2">
                          {passwordError}
                        </p>
                      </div>
                    }
                  </div>

                  <div className="flex items-center justify-between w-full">
                    <BtnPrimary type="submit">
                      <span> Sign in </span>
                    </BtnPrimary>
                  </div>
                </form>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ProviderPasswordCreation;
