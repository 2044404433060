
import * as actionType from "../constant/actionType";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case actionType.AddDocumentType_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.AddDocumentType_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.AddDocumentType_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

            case actionType.AdminGetAllDocumentType_START:
                return {
                    ...state,
                    error: action.error,
                    loader: action.loader,
                };
            case actionType.AdminGetAllDocumentType_SUCCESS:
                return {
                    ...state,
                    error: null,
                    loader: false,
                    token: action.token,
                };
            case actionType.AdminGetAllDocumentType_FAIL:
                return {
                    ...state,
                    loader: action.loader,
                    error: action.error,
                };
    
                case actionType.CreateEmailTemplate_START:
                    return {
                        ...state,
                        error: action.error,
                        loader: action.loader,
                    };
                case actionType.CreateEmailTemplate_SUCCESS:
                    return {
                        ...state,
                        error: null,
                        loader: false,
                        token: action.token,
                    };
                case actionType.CreateEmailTemplate_FAIL:
                    return {
                        ...state,
                        loader: action.loader,
                        error: action.error,
                    };
        

                    case actionType.GetEmailTemplateById_START:
                        return {
                            ...state,
                            error: action.error,
                            loader: action.loader,
                        };
                    case actionType.GetEmailTemplateById_SUCCESS:
                        return {
                            ...state,
                            error: null,
                            loader: false,
                            token: action.token,
                        };
                    case actionType.GetEmailTemplateById_FAIL:
                        return {
                            ...state,
                            loader: action.loader,
                            error: action.error,
                        };
            

                        
                        case actionType.GetAllEmailTemplatesByType_START:
                            return {
                                ...state,
                                error: action.error,
                                loader: action.loader,
                            };
                        case actionType.GetAllEmailTemplatesByType_SUCCESS:
                            return {
                                ...state,
                                error: null,
                                loader: false,
                                token: action.token,
                            };
                        case actionType.GetAllEmailTemplatesByType_FAIL:
                            return {
                                ...state,
                                loader: action.loader,
                                error: action.error,
                            };
                

                            case actionType.UpdateEmailTemplate_START:
                                return {
                                    ...state,
                                    error: action.error,
                                    loader: action.loader,
                                };
                            case actionType.UpdateEmailTemplate_SUCCESS:
                                return {
                                    ...state,
                                    error: null,
                                    loader: false,
                                    token: action.token,
                                };
                            case actionType.UpdateEmailTemplate_FAIL:
                                return {
                                    ...state,
                                    loader: action.loader,
                                    error: action.error,
                                };
                    
                    
 
                                case actionType.DeleteEmailTemplate_START:
                                    return {
                                        ...state,
                                        error: action.error,
                                        loader: action.loader,
                                    };
                                case actionType.DeleteEmailTemplate_SUCCESS:
                                    return {
                                        ...state,
                                        error: null,
                                        loader: false,
                                        token: action.token,
                                    };
                                case actionType.DeleteEmailTemplate_FAIL:
                                    return {
                                        ...state,
                                        loader: action.loader,
                                        error: action.error,
                                    };
                        
                        
 
                                    case actionType.DeleteEmailTempAttachmentById_START:
                                        return {
                                            ...state,
                                            error: action.error,
                                            loader: action.loader,
                                        };
                                    case actionType.DeleteEmailTempAttachmentById_SUCCESS:
                                        return {
                                            ...state,
                                            error: null,
                                            loader: false,
                                            token: action.token,
                                        };
                                    case actionType.DeleteEmailTempAttachmentById_FAIL:
                                        return {
                                            ...state,
                                            loader: action.loader,
                                            error: action.error,
                                        };
                            
                            
 
                                        case actionType.CreateServiceType_START:
                                            return {
                                                ...state,
                                                error: action.error,
                                                loader: action.loader,
                                            };
                                        case actionType.CreateServiceType_SUCCESS:
                                            return {
                                                ...state,
                                                error: null,
                                                loader: false,
                                                token: action.token,
                                            };
                                        case actionType.CreateServiceType_FAIL:
                                            return {
                                                ...state,
                                                loader: action.loader,
                                                error: action.error,
                                            };
                                
                                
 
                                            case actionType.GetAllServiceTypes_START:
                                                return {
                                                    ...state,
                                                    error: action.error,
                                                    loader: action.loader,
                                                };
                                            case actionType.GetAllServiceTypes_SUCCESS:
                                                return {
                                                    ...state,
                                                    error: null,
                                                    loader: false,
                                                    token: action.token,
                                                };
                                            case actionType.GetAllServiceTypes_FAIL:
                                                return {
                                                    ...state,
                                                    loader: action.loader,
                                                    error: action.error,
                                                };
                                    
                                    
                                                case actionType.CreateProviderType_START:
                                                    return {
                                                        ...state,
                                                        error: action.error,
                                                        loader: action.loader,
                                                    };
                                                case actionType.CreateProviderType_SUCCESS:
                                                    return {
                                                        ...state,
                                                        error: null,
                                                        loader: false,
                                                        token: action.token,
                                                    };
                                                case actionType.CreateProviderType_FAIL:
                                                    return {
                                                        ...state,
                                                        loader: action.loader,
                                                        error: action.error,
                                                    };
                                        
                                               
                                                case actionType.GetAllProviderTypes_START:
                                                    return {
                                                        ...state,
                                                        error: action.error,
                                                        loader: action.loader,
                                                    };
                                                case actionType.GetAllProviderTypes_SUCCESS:
                                                    return {
                                                        ...state,
                                                        error: null,
                                                        loader: false,
                                                        token: action.token,
                                                    };
                                                case actionType.GetAllProviderTypes_FAIL:
                                                    return {
                                                        ...state,
                                                        loader: action.loader,
                                                        error: action.error,
                                                    };
                                            
                                            case actionType.InviteUser_START:
                                                return {
                                                    ...state,
                                                    error: action.error,
                                                    loader: action.loader,
                                                };
                                            case actionType.InviteUser_SUCCESS:
                                                return {
                                                    ...state,
                                                    error: null,
                                                    loader: false,
                                                    token: action.token,
                                                };
                                            case actionType.InviteUser_FAIL:
                                                return {
                                                    ...state,
                                                    loader: action.loader,
                                                    error: action.error,
                                                };
                                        
                                            case actionType.GetAllConserveTeamAndInvites_START:
                                                return {
                                                    ...state,
                                                    error: action.error,
                                                    loader: action.loader,
                                                };
                                            case actionType.GetAllConserveTeamAndInvites_SUCCESS:
                                                return {
                                                    ...state,
                                                    error: null,
                                                    loader: false,
                                                    token: action.token,
                                                };
                                            case actionType.GetAllConserveTeamAndInvites_FAIL:
                                                return {
                                                    ...state,
                                                    loader: action.loader,
                                                    error: action.error,
                                                };
                                    
                                    
                                        
                                                case actionType.GetInvitationById_START:
                                                    return {
                                                        ...state,
                                                        error: action.error,
                                                        loader: action.loader,
                                                    };
                                                case actionType.GetInvitationById_SUCCESS:
                                                    return {
                                                        ...state,
                                                        error: null,
                                                        loader: false,
                                                        token: action.token,
                                                    };
                                                case actionType.GetInvitationById_FAIL:
                                                    return {
                                                        ...state,
                                                        loader: action.loader,
                                                        error: action.error,
                                                    };
                                
                                                    case actionType.SubmitAdminUserInvitation_START:
                                                        return {
                                                            ...state,
                                                            error: action.error,
                                                            loader: action.loader,
                                                        };
                                                    case actionType.SubmitAdminUserInvitation_SUCCESS:
                                                        return {
                                                            ...state,
                                                            error: null,
                                                            loader: false,
                                                            token: action.token,
                                                        };
                                                    case actionType.SubmitAdminUserInvitation_FAIL:
                                                        return {
                                                            ...state,
                                                            loader: action.loader,
                                                            error: action.error,
                                                        };
                                
                                                        case actionType.GetUserDetailsById_START:
                                                            return {
                                                                ...state,
                                                                error: action.error,
                                                                loader: action.loader,
                                                            };
                                                        case actionType.GetUserDetailsById_SUCCESS:
                                                            return {
                                                                ...state,
                                                                error: null,
                                                                loader: false,
                                                                token: action.token,
                                                            };
                                                        case actionType.GetUserDetailsById_FAIL:
                                                            return {
                                                                ...state,
                                                                loader: action.loader,
                                                                error: action.error,
                                                            };
                                                            
                                
                                                            case actionType.UserResetPasswordEmail_START:
                                                                return {
                                                                    ...state,
                                                                    error: action.error,
                                                                    loader: action.loader,
                                                                };
                                                            case actionType.UserResetPasswordEmail_SUCCESS:
                                                                return {
                                                                    ...state,
                                                                    error: null,
                                                                    loader: false,
                                                                    token: action.token,
                                                                };
                                                            case actionType.UserResetPasswordEmail_FAIL:
                                                                return {
                                                                    ...state,
                                                                    loader: action.loader,
                                                                    error: action.error,
                                                                };

                                                        case actionType.UpdateUser_START:
                                                            return {
                                                                ...state,
                                                                error: action.error,
                                                                loader: action.loader,
                                                            };
                                                        case actionType.UpdateUser_SUCCESS:
                                                            return {
                                                                ...state,
                                                                error: null,
                                                                loader: false,
                                                                token: action.token,
                                                            };
                                                        case actionType.UpdateUser_FAIL:
                                                            return {
                                                                ...state,
                                                                loader: action.loader,
                                                                error: action.error,
                                                            };
                                                     case actionType.RemoveUser_START:
                                                            return {
                                                                ...state,
                                                                error: action.error,
                                                                loader: action.loader,
                                                            };
                                                        case actionType.RemoveUser_SUCCESS:
                                                            return {
                                                                ...state,
                                                                error: null,
                                                                loader: false,
                                                                token: action.token,
                                                            };
                                                        case actionType.RemoveUser_FAIL:
                                                            return {
                                                                ...state,
                                                                loader: action.loader,
                                                                error: action.error,
                                                            };

                                                            case actionType.UserResetPassword_START:
                                                                return {
                                                                    ...state,
                                                                    error: action.error,
                                                                    loader: action.loader,
                                                                };
                                                            case actionType.UserResetPassword_SUCCESS:
                                                                return {
                                                                    ...state,
                                                                    error: null,
                                                                    loader: false,
                                                                    token: action.token,
                                                                };
                                                            case actionType.UserResetPassword_FAIL:
                                                                return {
                                                                    ...state,
                                                                    loader: action.loader,
                                                                    error: action.error,
                                                                };

                                                                                            
                                            
        default:
            return state;
    }
};

export default reducer;





