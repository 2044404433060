import axios from "axios";
import * as actionType from "../../constant/actionType";
import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";

export const GetAllDocsOfWorker = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${actionType.environment_url}/api/admin/worker/GetAllDocsOfWorker`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      params: ApiData,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching documents:", error);
    return { success: false, message: error.message };
  }
};

export const GetWorkerrDocById = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/worker/GetWorkerrDocById`,
      params: apiData
    });
   return response.data;
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const UpdateWorkerDoc = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) { 
      if (key === 'column_names') {
        formdata.append(key, JSON.stringify(ApiData[key]));
      }
        else {
          // For other properties, append the value directly
            formdata.append(key, ApiData[key]);
        }
    }
    const response = await axios({
      method: 'put',
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/worker/UpdateWorkerDoc`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}

export const GetWorkerDocHistoryById = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    // dispatch(fetchData())
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/worker/GetWorkerDocHistoryById`,
      params: apiData
    });
    return response.data
  } catch (error) {
    // dispatch(fetchDataError(error.message))  
    return { success: false, message: error.message };
  }
};
export const AdminGetAllWorkers = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    // dispatch(fetchData())
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/worker/AdminGetAllWorkers`,
      params: apiData
    });
    return response.data
  } catch (error) {
    // dispatch(fetchDataError(error.message))  
    return { success: false, message: error.message };
  }
};