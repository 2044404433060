import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetAllDocType = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
      const response = await axios({
          method: 'get',
          headers: {
              Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
          },
          url: `${actionType.environment_url}/api/admin/worker/GetAllDocumentTypeList`,
          params: apiData
      });
      return response.data

  } catch (error) {
      return error.message
  }
};