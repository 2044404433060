
// import { createSlice} from "@reduxjs/toolkit";
// const ProviderRegisterData = createSlice({
//   name: 'ProviderRegister',
//       initialState:{
//         data:null,
//         loading:false,
//         error:null,
//     },
//   reducers: {
//     fetchData: (state) => {
//       state.loading = true;
//     },
//     fetchDataSuccess: (state, action) => {
//       state.loading = false;
//       state.data = action.payload;
//     },
//     fetchDataError: (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//     },
//   },
// });
// export const { fetchData, fetchDataSuccess, fetchDataError } = ProviderRegisterData.actions;
// export default ProviderRegisterData.reducer;


// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const ProviderRegisterData = createSlice({
  name:"ProviderRegisterData",
  initialState:[],
  reducers:{
    GettData(state,action){
      return (action.payload)
    },
    ResetData(){
      return []
    },
  }
}) 
export const {GettData,ResetData}=ProviderRegisterData.actions;
export default ProviderRegisterData.reducer;