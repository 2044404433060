import { createSlice } from "@reduxjs/toolkit";

const GetAllClientsOfAdminProvider = createSlice({
  name: 'AllClientsOfAdminProvider',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchData: (state) => {
      state.loading = true;
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    reSetData: () => {
      return [];
    },    
  },
});

export const {
  fetchData,
  fetchDataSuccess,
  fetchDataError,
  reSetData,
} = GetAllClientsOfAdminProvider.actions;

export default GetAllClientsOfAdminProvider.reducer;
