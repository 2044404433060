import axios from "axios";
import * as actionType from "../constant/actionType";

/*** SignIn Action ***/
export const SignInStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.SignIn_START,
  };
};
export const SignInSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.SignIn_SUCCESS,
  };
};
export const SignInFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.SignIn_FAIL,
  };
};
export const SignIn = (form_data) => {
  return (dispatch) => {
    dispatch(SignInStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      url: `${actionType.environment_url}/api/SignIn`,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.success === true) {
          if (response?.data?.data?.token) {
            localStorage.setItem(
              "conserve_authtoken",
              response?.data?.data?.token
            );
          }
        }
        dispatch(SignInSuccess("User login successfully !!!"));
        return response?.data;
      })
      .catch(function (error) {
        dispatch(SignInFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

export const SignupProviderEmailVerification = (form_data) => {
  return () => {
    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }
    return axios({
      method: "post",
      data: formdata,
      url: `${actionType.environment_url}/api/SignupProviderEmailVerification`,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        return response?.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};

/*** SignIn Action ***/
export const SignInVerifyStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.SignInVerify_START,
  };
};
export const SignInVerifySuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.SignInVerify_SUCCESS,
  };
};
export const SignInVerifyFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.SignInVerify_FAIL,
  };
};
export const SignInVerify = (form_data) => {
  return (dispatch) => {
    dispatch(SignInVerifyStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      url: `${actionType.environment_url}/api/SignInVerify`,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.success === true) {
          localStorage.setItem("conserve_authtoken", response.data.data.token);
        }
        dispatch(SignInVerifySuccess("User login successfully !!!"));
        return response.data;
      })
      .catch(function (error) {
        dispatch(SignInVerifyFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** Email Authentication Action ***/
export const emailStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.email_START,
  };
};
export const emailSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.email_SUCCESS,
  };
};
export const emailFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.email_FAIL,
  };
};
export const email = (form_data) => {
  return (dispatch) => {
    dispatch(emailStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      url: `${actionType.environment_url}/api/email`,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.success === true) {
        }
        dispatch(emailSuccess("User login successfully !!!"));
        return response.data;
      })
      .catch(function (error) {
        dispatch(emailFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** Email ForgotPassword Action ***/
export const ForgotPasswordStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.ForgotPassword_START,
  };
};
export const ForgotPasswordSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.ForgotPassword_SUCCESS,
  };
};
export const ForgotPasswordFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.ForgotPassword_FAIL,
  };
};
export const ForgotPassword = (form_data) => {
  return (dispatch) => {
    dispatch(ForgotPasswordStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      url: `${actionType.environment_url}/api/ForgotPassword`,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.success === true) {
        }
        dispatch(ForgotPasswordSuccess("User login successfully !!!"));
        return response.data;
      })
      .catch(function (error) {
        dispatch(ForgotPasswordFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

/*** Email ResendSignInOtp Action ***/
export const ResendSignInOtpStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.ResendSignInOtp_START,
  };
};
export const ResendSignInOtpSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.ResendSignInOtp_SUCCESS,
  };
};
export const ResendSignInOtpFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.ResendSignInOtp_FAIL,
  };
};
export const ResendSignInOtp = (form_data) => {
  return (dispatch) => {
    dispatch(ResendSignInOtpStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "post",
      data: formdata,
      url: `${actionType.environment_url}/api/ResendSignInOtp`,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.success === true) {
        }
        dispatch(ResendSignInOtpSuccess("User login successfully !!!"));
        return response.data;
      })
      .catch(function (error) {
        dispatch(
          ResendSignInOtpFail("Something went wrong, Please try again.")
        );
        return error;
      });
  };
};

/*** Email ResetPassword Action ***/
export const ResetPasswordStart = () => {
  return {
    error: null,
    loader: true,
    type: actionType.ResetPassword_START,
  };
};
export const ResetPasswordSuccess = (response) => {
  return {
    token: false,
    loader: false,
    type: actionType.ResetPassword_SUCCESS,
  };
};
export const ResetPasswordFail = (error) => {
  return {
    error: error,
    loader: false,
    type: actionType.ResetPassword_FAIL,
  };
};
export const ResetPassword = (form_data) => {
  return (dispatch) => {
    dispatch(ResetPasswordStart());

    let formdata = new FormData();
    for (let key in form_data) {
      formdata.append(key, form_data[key]);
    }

    return axios({
      method: "put",
      data: formdata,
      url: `${actionType.environment_url}/api/ResetPassword`,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.success === true) {
        }
        dispatch(ResetPasswordSuccess("User login successfully !!!"));
        return response.data;
      })
      .catch(function (error) {
        dispatch(ResetPasswordFail("Something went wrong, Please try again."));
        return error;
      });
  };
};

// export const isAuthorize = () => {
//     const conserve_authtoken = localStorage.getItem("conserve_authtoken");
//     if (conserve_authtoken !== null){
//         return conserve_authtoken ? true : false;
//     }
// };
export const isAuthorize = () => {
  const conserve_authtoken = localStorage.getItem("conserve_authtoken");
  return conserve_authtoken !== null;
};
