// reducers/counterReducer.js
// import { createSlice } from '@reduxjs/toolkit'


// const siteInductionDetailSlice = createSlice({
//   // name:"InductionDetailsFormData",
//   name:"siteInductionDetailData",
//   initialState:[],
//   reducers:{
//     siteInductionDetailForm(state,action){
//       return (action.payload)
//     },
//     RestSiteInductionDetailForm(){
//         return []
//     }
//   }
// }) 
// export const {siteInductionDetailForm,RestSiteInductionDetailForm}=siteInductionDetailSlice.actions;
// export default siteInductionDetailSlice.reducer;


// ///////////////////////////
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  site_uuid: '',
  site_ind_name: '',
  site_ind_desc: '',
  site_induction_uuid: '',
  site_ind_status: '',
  site_ind_doctypes: [],
  master_setting_uuid_data: [],
  // Add any other initial state properties here
};

const siteInductionDetailSlice = createSlice({
  name: 'siteInductionDetailData',
  initialState,
  reducers: {
    siteInductionDetailForm(state, action) {
      return { ...state, ...action.payload };
    },
    RestSiteInductionDetailForm() {
      return initialState;
    }
  }
});

export const {siteInductionDetailForm,RestSiteInductionDetailForm}=siteInductionDetailSlice.actions;
export default siteInductionDetailSlice.reducer;
