import React, { useState } from "react";
import BtnLight from "../../../../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../../../../../../common/element/Select/Select";
import InductionsList from "./InductionsList";
import Dialog from "../../../../../../../../../../common/element/Dialog/Dialog";
import Table from "../../../../../../../../../../common/element/Table";
import Tabs from "../../../../../../../../../../common/element/Tabs";
import Tag from "../../../../../../../../../../common/element/Tag";
import Tooltip from "../../../../../../../../../../common/element/Tooltip";
import Button from "../../../../../../../../../../common/element/Buttons";
import Scrollbar from "../../../../../../../../../../common/element/ScrollBar";
import { Link } from "react-router-dom";
const Inductions = (props) => {
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [dialogChoose, setChoose] = useState(false);
  const { TabNav, TabList, TabContent } = Tabs;
  const { Tr, Th, Td, THead, TBody } = Table;
  const searchOptions = [
    { value: "Insurance", label: "Insurance" },
    { value: "Other", label: "Other" },
  ];
  const openChoose = () => {
    setChoose(true);
  };
  const onChooseClose = (e) => {
    setChoose(false);
  };

  const openDialog = () => {
    setIsOpen(true);
    setChoose(false);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  const openAddDialog = () => {
    setAddOpen(true);
    setIsOpen(false);
  };
  const onAddDialogClose = (e) => {
    setAddOpen(false);
    // props.getOnDialogClose(onAddDialogClose);
  };
  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <div className="flex gap-2">
          <Select
            className="w-80 h-9 select-options"
            placeholder="Search"
            options={searchOptions}
          ></Select>
          <BtnLight className="w-9 h-9 flex items-center justify-center">
            <svg
              width="21"
              height="20"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.712 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0zm-6 8h12m-12 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                stroke="#101828"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnLight>
        </div>
      </div>
      <InductionsList />
      <Dialog
        isOpen={dialogChoose}
        onClose={onChooseClose}
        onRequestClose={onChooseClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/cons-client.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Viewing Client
            </h5>
          </div>
        </div>
        <div className="px-6">
          <Tabs defaultValue="tab1" variant="pill">
            <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5">
              <TabList>
                <TabNav className="w-3/6" value="tab1">
                  Client details
                </TabNav>
                <TabNav className="w-3/6" value="tab2">
                  Documents
                </TabNav>
              </TabList>
            </div>
            <div className="">
              <TabContent value="tab1">
                <div className="flex w-full">
                  <div className="border h-72 border-gray-300 rounded-lg w-56 flex items-center text-center justify-center">
                    <span className="text-xs text-gray-400 font-semibold">
                      Client logo here,
                      <br /> if available
                    </span>
                  </div>
                  <div className="flex w-4/6 pl-5">
                    <div className="w-3/6">
                      <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium">
                          Client name
                        </label>
                        <div className="text-gray-900 font-semibold">
                          [Client name here]
                        </div>
                      </div>
                      <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium">
                          Email address
                        </label>
                        <div className="text-gray-900 font-semibold">
                          Email address
                        </div>
                      </div>
                      <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium">Website</label>
                        <Link
                          to=""
                          className="text-xs text-blue-700 flex gap-1"
                        >
                          www.addresshere.com{" "}
                          <svg
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                              stroke="#5786cc"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Link>
                      </div>
                      <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium">Address</label>
                        <div className="text-gray-900 font-semibold">
                          [Address here]
                        </div>
                      </div>
                    </div>
                    <div className="w-3/6">
                      <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium">ABN/NZBN</label>
                        <div className="text-gray-900 font-semibold">
                          [ABN/NZBN number here]
                        </div>
                      </div>
                      <div className="flex flex-col mb-5">
                        <label className="text-xs font-medium">
                          Contact number
                        </label>
                        <div className="text-gray-900 font-semibold">
                          [Contact number here]
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabContent>
              <TabContent value="tab2">
               <div className="h-72">
               <Scrollbar>
                <div className="overflow-hidden border rounded-lg border-gray-200">
                  <Table>
                    <THead>
                      <Tr>
                        <Th>
                          <span className="flex items-center gap-1">
                            Document name
                            <svg
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                stroke="#475467"
                                strokeWidth="1.333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </Th>

                        <Th className="w-44">
                          <span className="flex items-center gap-1">
                            Status
                            <Tooltip
                              title="Invited: Registered: Active:"
                              placement="top-start"
                            >
                              <img
                                src="/assets/icons-svg/help-circle.svg"
                                alt="#"
                              />
                            </Tooltip>
                          </span>
                        </Th>
                        <Th className="w-12"></Th>
                      </Tr>
                    </THead>
                    <TBody>
                      <Tr>
                        <Td>Annette Souter</Td>
                        <Td>
                          <Tag className="text-error-700 bg-error-50">
                          Action required
                          </Tag>
                        </Td>
                        <Td>
                          <Button>
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Button>
                        </Td>
                      </Tr>

                      <Tr>
                        <Td>Annette Souter</Td>
                        <Td>
                          <Tag className="text-blue-800 bg-blue-50">
                          Awaiting review
                          </Tag>
                        </Td>
                        <Td>
                          <Button>
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Button>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Provider Name lorem ipsum</Td>
                        <Td>
                          <Tag className="text-success-700 bg-success-50">
                          Approved
                          </Tag>
                        </Td>
                        <Td>
                          <Button>
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                stroke="#667085"
                                strokeWidth="1.667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Button>
                        </Td>
                      </Tr>
                    </TBody>
                  </Table>
                </div>
                </Scrollbar>
                </div>
              </TabContent>
            </div>
          </Tabs>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onDialogClose}>
            Close
          </BtnLight>
          <BtnPrimary onClick={() => openDialog()} className="ml-1.5">
            View Client’s full profile
          </BtnPrimary>
        </div>
      </Dialog>
     
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <div className="flex h-full">
          <div className="left-block-wrap h-full w-1/5">
            <Tabs defaultValue="tab1" variant="pill">
              <TabList className="bg-gray-500 rounded-lg p-1">
                <TabNav value="tab1">Details</TabNav>
                <TabNav value="tab2">History</TabNav>
              </TabList>
              <div className="p-4">
                <TabContent value="tab1">
                  <div className="mb-4">
                    <div className="font-bold text-white text-xl mb-1 flex justify-between">
                      <span></span>[Document name]
                      <Button className="ml-auto">
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                            stroke="#fff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                    <label className="text-gray-100">
                      [DD Month YYYY] at [00:00]
                    </label>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      Policy number
                    </label>
                    <p className="text-white font-semibold">[00000000000000]</p>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      Amount insured
                    </label>
                    <p className="text-white font-semibold">$00000000</p>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      No. of workers
                    </label>
                    <p className="text-white font-semibold">0000000</p>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      Expiry date
                    </label>
                    <p className="text-white font-semibold">DD/MM/YYYY</p>
                  </div>
                  <hr className="border-t border-gray-400 my-5" />
                  <div className="font-bold text-white text-xl mb-4 ">
                    Validation
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium mb-2 block">
                      Message
                    </label>
                    <textarea
                      id=""
                      name=""
                      className="w-full"
                      placeholder="Enter a message..."
                    ></textarea>
                    <p className="text-gray-100 text-12">
                      Provide a message about the document, if necessary.
                    </p>
                  </div>
                  <div className="flex justify-end gap-2">
                    <Button className="text-error-700 font-semibold bg-white h-10 w-20 rounded-lg">
                      Reject
                    </Button>
                    <Button className="text-success-700 font-semibold bg-success-50 h-10 w-20 rounded-lg">
                      Approve
                    </Button>
                  </div>
                </TabContent>
                <TabContent value="tab2">
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      27 April 2023 at 13:34
                    </label>
                    <p className="font-semibold text-white mt-2">
                      Admin has edited Expiry date
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      Admin has edited Expiry date
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                    <div className=" p-4 rounded-lg bg-white font-medium mt-2">
                      Hi. The document was rejected because the insurance amount
                      does not meet Client’s requirements. Please re-upload.
                    </div>
                  </div>
                  <div className="mb-4  pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                    <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                      <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.8 14.692H4.62c-.403 0-.605 0-.698-.08a.333.333 0 0 1-.116-.28c.01-.122.152-.264.438-.55l5.668-5.669c.264-.264.396-.396.549-.445a.667.667 0 0 1 .412 0c.152.05.284.181.548.445L14 10.693v.8m-3.2 3.2c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.874c.218-.428.218-.987.218-2.108m-3.2 3.2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 13.172 2 12.613 2 11.492v-5.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v5.6M7 6.36a1.333 1.333 0 1 1-2.667 0A1.333 1.333 0 0 1 7 6.36z"
                            stroke="#5786CC"
                            strokeWidth="1.333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <div className="ml-3">
                        <span className="font-medium text-gray-700 text-xs">
                          document name.[format]
                        </span>
                        <p className="text-gray-600 text-xs">200 KB</p>
                      </div>
                      <Button className="ml-auto">
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 8.458v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 2.772 6.49C2.5 7.024 2.5 7.725 2.5 9.125v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.535.272-1.235.272-2.635v-1.833"
                            stroke="#475467"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </TabContent>
              </div>
            </Tabs>
          </div>
          <div className="right-block-wrap h-full w-4/5">
            <div className="doc-header flex items-center justify-center">
              <div className="flex justify-content-center">
                <Button className="bg-white h-10 w-11 text-xl bl-rds">-</Button>
                <span className="font-semibold text-12 text-gray-700 bg-white w-16 flex items-center justify-center text-center h-10 border-r border-l">
                  100%
                </span>
                <Button className="bg-white h-10 w-11 text-xl br-rds">+</Button>
              </div>
              <div className="flex gap-6 rightSidebtn">
                <Button className="flex text-white gap-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Download
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#zvmr0yey3a)">
                      <path
                        d="m10.835 18.333-2.5-2.5m0 0 2.5-2.5m-2.5 2.5H12.5a5.833 5.833 0 0 0 2.5-11.105m-10 10.544a5.834 5.834 0 0 1 2.5-11.105h4.167m0 0-2.5-2.5m2.5 2.5-2.5 2.5"
                        stroke="#fff"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="zvmr0yey3a">
                        <path fill="#fff" d="M0 0h20v20H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>

                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.588 15.303 9.41 16.482a4.167 4.167 0 1 1-5.893-5.893l1.179-1.178m10.606 1.178 1.179-1.178a4.167 4.167 0 1 0-5.893-5.893L9.41 4.697m-2.328 8.22 5.834-5.834"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </div>
            <div className="doc-view"></div>
          </div>
        </div>
      </Dialog>

      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Document added
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onAddDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default Inductions;
