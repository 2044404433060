
// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const SpecificProviderData = createSlice({
  name:"SpecificProviderData",
  initialState:"",
  reducers:{
    AddSpecificProviderData(state,action){
      return (action.payload)
    },
    ResetSpecificProviderData(){
      return []
    },
  }
}) 
export const {AddSpecificProviderData,ResetSpecificProviderData}=SpecificProviderData.actions;
export default SpecificProviderData.reducer;