import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ScrollBar from "../../../../../../../common/element/ScrollBar";
import Table from "../../../../../../../common/element/Table";
import Button from "../../../../../../../common/element/Buttons";
import Tooltip from "../../../../../../../common/element/Tooltip";
import SmallButton from "../../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import Dialog from "../../../../../../../common/element/Dialog/Dialog";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import { useParams } from "react-router-dom";
import moment from "moment";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import {GetAllSpecificOrgInvites, 
   GetAllSpecificOrgUsers, 
   GetOrgUserPermById,
   ReinviteOrgUser,
   RemoveOrgUserInvitation} from "../../../../../../../actions/admin-provider/AllUserApis";

const Users = () =>{
const { Tr, Th, Td, THead, TBody } = Table
const { id } = useParams();
  const dispatch = useDispatch();
const handleUnauthorizedAccess = useUnauthorizedHandler()
const [dialogDeleteOpen, setDeleteIsOpen] = useState(false)
const [dialogIsOpen, setIsOpen] = useState(false)
const [open, setOpen] = useState(0);
const [userIdSpecifice,setUserIdSpecifice]=useState()
const [userDelete, setDelete] = useState(false)
const [userEdit, setUserEdit] = useState(false)
const [addUser, setAddUser] = useState(false)

  const [invitedUser, setInvitedUser] = useState("");
  const [allUser, setAllUser] = useState("");
  const [contactTypeUuid, setContactTypeUuid] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [permData, setPermData] = useState("");
  const [inviteUuid, setInviteUuid] = useState("");
  const [deleteUserData, setDeleteUserData] = useState("");
  const { SpecificProviderData } = useSelector((state) => state);
  const AllContactType = useSelector((state) => state?.AllContactTypes);
  const function_assignment_uuid = SpecificProviderData?.provider_fa_uuid;
  let trading_name = "provider trading corpo";

  useEffect(() => {
   if (AllContactType) {
     const contactTypeUuid = AllContactType.find(
       (item) => item.contact_type === "provider contact"
     )?.contact_type_uuid;

     setContactTypeUuid(contactTypeUuid);
   }
 }, []);

const addUserOpen = () => {
setAddUser(true)
}
const addUserClose = () => {
setAddUser(false)
}
const openDialog = () => {
setIsOpen(true)
}
const onDialogClose = () => {
setIsOpen(false)
}
const openDeleteDialog = (e) => {
setDeleteIsOpen(true)
setInviteUuid(e.inviteUuid)
}
const closeInvitation = () => {
setDeleteIsOpen(false)
}
const handleOpen = (value) => {
setOpen(open === value ? 0 : value);
};
const onSwitcherToggle = (val, e) => {
}
const openUserEdit = (val) => {
setUserEdit(true)
    setNameUser(val?.name);
dispatch(GetOrgUserPermById({user_uuid: val?.userUuid, function_assignment_uuid: function_assignment_uuid})).then((response) => {
   if (response.success) {
      let userId = {
         user_uuid: val?.userUuid,
         function_assignment_uuid: function_assignment_uuid
       }
       setUserIdSpecifice(userId)
       const arrayData = response.data; 
       setPermData(arrayData);  
   } else if (response.status === 401) {
      handleUnauthorizedAccess()
   }
   });
}
const closeUserEdit = () => {
setUserEdit(false)
}
const deleteUserDialog = (e) => {
setDelete(true)
setDeleteUserData(e)
}
const closeDeleteUser = () => {
setDelete(false)
}

const updateInvitedData = (arrayData) => {
   let client_data = [];
   for (let key in arrayData) {
     const currentTime = moment();
     let invitedDate = arrayData[key]?.invite_date;
     let invited = moment.duration(currentTime.diff(invitedDate));
     client_data.push({
       inviteUuid: arrayData[key]?.invite_uuid,
       email: arrayData[key]?.invite_individual?.email,
       firstName: arrayData[key]?.invite_individual?.first_name,
       lastName: arrayData[key]?.invite_individual?.last_name,
       individualUuid:
         arrayData[key]?.invite_individual?.individual_uuid,
       invitedDate: arrayData[key]?.invite_date
         ? invited.asSeconds() < 60
           ? "Just now"
           : invited.asMinutes() < 60
           ? `${Math.floor(invited.asMinutes())} ${
               invited.asMinutes() === 1 ? "minute" : "minutes"
             } ago`
           : invited.asHours() < 24
           ? `${Math.floor(invited.asHours())} ${
               invited.asHours() === 1 ? "hour" : "hours"
             } ago`
           : invited.asDays() < 2
           ? "Yesterday"
           : `${Math.floor(invited.asDays())} ${
               invited.asDays() === 1 ? "day" : "days"
             } ago`
         : "",
     });
   }
   setInvitedUser(client_data);
}

const updateActiveData = (arrayData) => {
   let client_data = [];
   for (let key in arrayData) {
     const currentTime = moment();
     let ActiveDate =
       arrayData[key]?.org_individual?.user_data?.last_login;
     let active = moment.duration(currentTime.diff(ActiveDate));
     client_data.push({
       email: arrayData[key]?.org_individual?.email,
       name:
         arrayData[key]?.org_individual?.first_name +
         " " +
         arrayData[key]?.org_individual?.last_name,

       individualUuid: arrayData[key]?.org_individual?.individual_uuid,
       userUuid: arrayData[key]?.org_individual?.user_data?.user_uuid,
       individualOrgUuid: arrayData[key]?.individual_org_uuid,
       activeDate: arrayData[key]?.org_individual?.user_data?.last_login
         ? active.asSeconds() < 60
           ? "Just now"
           : active.asMinutes() < 60
           ? `${Math.floor(active.asMinutes())} ${
               active.asMinutes() === 1 ? "minute" : "minutes"
             } ago`
           : active.asHours() < 24
           ? `${Math.floor(active.asHours())} ${
               active.asHours() === 1 ? "hour" : "hours"
             } ago`
           : active.asDays() < 2
           ? "Yesterday"
           : `${Math.floor(active.asDays())} ${
               active.asDays() === 1 ? "day" : "days"
             } ago`
         : "",
     });
   }
   setAllUser(client_data);
}


useEffect (() => {
   dispatch(GetAllSpecificOrgInvites({function_assignment_uuid: function_assignment_uuid, invited_user_type: "provider_user"})).then((response) => {
      if (response.success) {
          const arrayData = response.data; 
          updateInvitedData(arrayData)  
      } else if (response.status === 401) {
         handleUnauthorizedAccess()
      }
      });
      dispatch(GetAllSpecificOrgUsers({organisation_uuid: id})).then((response) => {
         if (response.success) {
             const arrayData = response.data; 
             updateActiveData(arrayData)  
         } else if (response.status === 401) {
            handleUnauthorizedAccess()
         }
         });
}, [])


// to reInvite user
const ReInviteUser = (e) => {
   const payload = {
     invite_uuid: e.inviteUuid,
     trading_name: trading_name,
     first_name: e.firstName,
     last_name: e.lastName,
     email: e.email,
     contact_type_uuid: contactTypeUuid,
     invited_user_type: "provider_user"
   };

   dispatch(ReinviteOrgUser(payload))
   .then((response) => {
     if (response.success) {
      openDialog();
     } else if (response.status === 401) {
       handleUnauthorizedAccess()
     }
   })
   .catch((error) => {});
}

  // to remove Invited user
  const RemoveUserInvite = () => {
   const payload = {
     invite_uuid: inviteUuid,
     function_assignment_uuid: function_assignment_uuid
   };

   dispatch(RemoveOrgUserInvitation(payload))
   .then((response) => {
     if (response.success) {
      GetAllSpecificOrgInvites()
       closeInvitation()
       setInviteUuid('')
     } else if (response.status === 401) {
       handleUnauthorizedAccess()
     }
   })
   .catch((error) => {});
 };

return(
<React.Fragment>
   <div className="h-500 pr-1">
      <ScrollBar>
         <div className='pr-4 mt-7 pb-6'>
            <div className="flex justify-between mb-5">
               <div>
                  <h5 className="text-xl text-gray-900 font-semibold mb-1">Users</h5>
                  <p className="text-gray-600">Manage and update the User’s access.</p>
               </div>
               <Button onClick={() =>
                  addUserOpen()} className="hover-svg w-auto flex items-center px-3 text-xs text-blue-700 font-semibold hover:text-blue-900 focus:text-blue-700">Add user 
                  <svg  className="ml-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M10 4.167v11.666M4.167 10h11.666" stroke="#5786CC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
               </Button>
            </div>
            <div className="overflow-hidden border rounded-lg border-gray-200">
               <Table>
                  <THead>
                     <Tr>
                        <Th className="w-1/3">User name</Th>
                        <Th className="w-1/3">Email</Th>
                        <Th>
                           <div className="flex items-center">
                              Last active   
                              <Tooltip title="Account Billing contact is responsible for billing and payment on the account." placement="top-start">
                                 <div className="text-center cursor-pointer">
                                    <img src="/assets/icons-svg/help-circle.svg" alt="" className="ml-2"/>
                                 </div>
                              </Tooltip>
                           </div>
                        </Th>
                        <Th>
                        </Th>
                     </Tr>
                  </THead>
                  <TBody>
                  {allUser &&
                    allUser.map((item) => (
                      <Tr key={item.individualUuid}>
                        <Td>{item.name}</Td>
                        <Td>{item.email}</Td>
                        <Td>{item.activeDate}</Td>
                        <Td>
                           <span className="flex gap-4 justify-center">
                              <Button onClick={() =>
                                 deleteUserDialog(item)}>
                                 <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                 </svg>
                              </Button>
                              <Button onClick={() =>
                                 openUserEdit(item)}>
                                 <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                 </svg>
                              </Button>
                           </span>
                        </Td>
                     </Tr>))}
                  </TBody>
               </Table>
            </div>
            <div className="flex justify-between mb-5 mt-5">
               <h5 className="text-xl text-gray-900 font-semibold">Invited</h5>
            </div>
            <div className="overflow-hidden border rounded-lg border-gray-200">
               <Table>
                  <THead>
                     <Tr>
                        <Th className="w-1/3">User name</Th>
                        <Th className="w-1/3">Email</Th>
                        <Th>
                           <div className="flex items-center">
                              Sent 
                              <Tooltip title="Account Billing contact is responsible for billing and payment on the account." placement="top-start">
                                 <div className="text-center cursor-pointer">
                                    <img src="/assets/icons-svg/help-circle.svg" alt="" className="ml-2"/>
                                 </div>
                              </Tooltip>
                           </div>
                        </Th>
                        <Th>
                        </Th>
                     </Tr>
                  </THead>
                  <TBody>
                  {invitedUser &&
                    invitedUser.map((item) => (
                      <Tr key={item.individualUuid}>
                        <Td>{item.firstName + " " + item.lastName}</Td>
                        <Td>{item.email}</Td>
                        <Td>{item.invitedDate}</Td>
                        <Td>
                           <span className="flex gap-4 justify-center">
                              <Button onClick={() => openDeleteDialog(item) } >
                                 <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                 </svg>
                              </Button>
                              <Button onClick={() => ReInviteUser(item)}>
                                 <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z" stroke="#475467" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round"/>
                                 </svg>
                              </Button>
                           </span>
                        </Td>
                     </Tr>))}
                  </TBody>
               </Table>
            </div>
         </div>
      </ScrollBar>
   </div>
   {/*--------- Remove Invitaion -------- */}
   <Dialog
      isOpen={dialogDeleteOpen}
      onClose={closeInvitation}
      onRequestClose={closeInvitation}
      bodyOpenClassName="overflow-hidden"
      className="customAlert"
      >
      <div className="px-6 pt-6">
         <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
         </span>
         <h4 className="text-base text-gray-900 font-semibold text-center mt-4">Remove Invitation?</h4>
         <p className=" text-gray-600 text-center mt-1">
            Removing this invitation will cancel their access to the app.
         </p>
      </div>
      <div className='mt-8 flex justify-between pb-6 px-6'>
         <BtnLight className='mr-1.5' >Cancel</BtnLight>
         <SmallButton onClick={() => RemoveUserInvite()}
         // onClick={() => closeInvitation()}
          className='ml-1.5'>Confirm</SmallButton>
      </div>
   </Dialog>
   {/*--------- Invitaion Sent Confirmation -------- */}
   <Dialog
      isOpen={dialogIsOpen}
      onClose={onDialogClose}
      onRequestClose={onDialogClose}
      bodyOpenClassName="overflow-hidden"
      className="customAlert"
      >
      <div className="px-6 pt-6">
         <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z" stroke="#101828" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
         </span>
         <h4 className="text-base text-gray-900 font-semibold text-center mt-4">Invite sent successfully!</h4>
      </div>
      <div className='mt-8 flex justify-between pb-6 px-6'>
         <SmallButton onClick={() => onDialogClose()} >Confirm</SmallButton>
      </div>
   </Dialog>
    {/*--------- Edit User Access-------- */}
   <Dialog
      isOpen={userEdit}
      onClose={closeUserEdit}
      onRequestClose={closeUserEdit}
      bodyOpenClassName="overflow-hidden"
      className="addContact-dialog"
      >
      <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
         <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4"><img src="/assets/icons-svg/user.svg" alt=""/></span>
         <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">Edit user access for {trading_name}</h5>
            <p className="text-gray-600">Modify user’s access.</p>
         </div>
      </div>
      <EditUser userIdSpecifice={userIdSpecifice} permData={permData} closeUserEdit={closeUserEdit}/>
   </Dialog>
   {/*--------- delete User -------- */}
   <Dialog
      isOpen={userDelete}
      onClose={closeDeleteUser}
      onRequestClose={closeDeleteUser}
      bodyOpenClassName="overflow-hidden"
      className="customAlert"
      >
      <div className="px-6 pt-6">
         <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
         </span>
         <h4 className="text-base text-gray-900 font-semibold text-center mt-4">Are you sure you want to delete this user?</h4>
         <p className=" text-gray-600 text-center mt-1">
            This action cannot be undone. The user will lose all access to the app.
         </p>
      </div>
      <div className='mt-8 flex justify-between pb-6 px-6'>
         <BtnLight className='mr-1.5' >Cancel</BtnLight>
         <SmallButton onClick={() => closeDeleteUser() } className='ml-1.5'>Confirm</SmallButton>
      </div>
   </Dialog>
   {/*--------- Add User -------- */}
   <Dialog
      isOpen={addUser}
      onClose={addUserClose}
      onRequestClose={addUserClose}
      bodyOpenClassName="overflow-hidden"
      className="addContact-dialog"
      >
      <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
         <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4"><img src="/assets/icons-svg/user.svg" alt=""/></span>
         <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">Add a user</h5>
         </div>
      </div>
      <AddUser addUserClose={addUserClose} updateInvitedData={updateInvitedData}/>
   </Dialog>
</React.Fragment>
)
}
export default Users