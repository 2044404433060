import React, { useEffect, useState } from "react";
import Heading from "../../../../common/element/Heading";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";

const ContractorInvoices = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([{ label: "Invoices", url: "/provider/invoices",home: "/provider/dashboard", }])
    );
  }, []);

  return (
    <React.Fragment>
      <Heading>Invoices</Heading>
      <p className="text-gray-600 mb-5">
        Non morbi vitae turpis leo scelerisque nisl phasellus nisi. Commodo.
      </p>
    </React.Fragment>
  );
};

export default ContractorInvoices;
