import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
// import { notify } from "react-notify-toast";
import Table from "../../../../../../common/element/Table";
import Button from "../../../../../../common/element/Buttons";
import Tooltip from "../../../../../../common/element/Tooltip";
import Dialog from "../../../../../../common/element/Dialog";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Input from "../../../../../../common/element/Input/Input";
import Label from "../../../../../../common/element/Label";
import RichTextEditor from "../../../../../../common/snippet/RichTextEditor";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import * as userActions from "../../../../../../actions/index";
import { Link } from "react-router-dom";
import EditTemplate from "./EditTemplate";
import ReactPaginate from "react-paginate";
import moment from "moment";
import "react-quill/dist/quill.snow.css";
import ReactSelect from "react-select";
import searchIcon from "../../../../../../assets/icons-svg/search-lg.svg";
import emailIcon from "../../../../../../assets/icons-svg/email.svg";
import Validations from "../../../../../../common/404/Validations";
import Upload from "../../../../../../common/element/Upload";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import Select from "../../../../../../common/element/Select";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../common/pagination";

const EmailTemplateList = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_uuid;
  if (loginData) {
    role_assignment_uuid = loginData?.data?.role_assignment_uuid
  }
  let permission_info;
  if (loginData.data != null) {
    permission_info = JSON.parse(localStorage.getItem("permissionData"));
  }
  const { Tr, Th, Td, THead, TBody } = Table;
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [filterorder, setFilterOrder] = useState("desc");
  const [filtersort, setFiltersort] = useState("created_date");
  const [countData, setCountData] = useState(0);
  const [searchTerm, setsearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [documentError, setdocumentError] = useState("");
  const [archived, setArchived] = useState(props?.archived)
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogDeleteIsOpen, setDeleteIsOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupId, setPopupId] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");
  const [content, setContent] = useState("");
  const [template_type, setTemplate_type] = useState([
    "admin_client",
    "admin_provider",
    "admin_worker",
  ]);
  const [templateSec, setTemplateSec] = useState("");
  const [attachment, setAttachment] = useState({
    files: [], // Initialize 'files' as an empty array
  });

  const [sectionOption, setSectionOption] = useState([
    { label: "Client Section", value: "admin_client" },
    { label: "Provider Section", value: "admin_provider" },
    { label: "Worker Section", value: "admin_worker" },
  ]);

  const [emaiData, setEmailData] = useState(null);

  const [nameError, setNameError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [tempSecError, setTempSecError] = useState("");

  const [emailUuid, setEmailUuid] = useState("");

  useEffect(() => {
    setArchived(props?.archived)
  }, [props?.archived])
  // To handle page change
  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    GetAllEmailTemplatesByType(selected + 1, filterlimit);
  };

  // to handle page sorting
  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };
  // Function to open the delete confirmation dialog
  const openDeleteDialog = (email_uuid) => {
    GetEmailTemplateById(email_uuid);
    setDeleteIsOpen(true);
  };
  // Function to close the delete confirmation dialog
  const onDeleteDialogClose = () => {
    setDeleteIsOpen(false);
  };
  // Function to confirm the email deletion
  const onDeleteDialogOk = () => {
    setDeleteIsOpen(false);
  };
  // Function to open the create email template dialog
  const openDialog = () => {
    setIsOpen(true);
  };
  // Function to close the create email template dialog
  const handleChange = (value) => {
    setContent(value);
    setMessageError("")
  }
  const dismissError = () => {
    setTempSecError("")
    setMessageError("")
    setSubjectError("")
    setNameError("")
  }
  const onDialogClose = () => {
    if (!isSaving) {
      dismissError()
      setIsOpen(false);
      setTemplateName("");
      setTemplateSubject("");
      setContent("");
      setTemplateSec("");
      setAttachment({ files: [] });
    }
  };
  const onDialogOk = () => {
    setIsOpen(false);
  };
  // Function to delete an email
  const deleteEmail = () => {
    const payload = {
      email_template_uuid: emailUuid,
    };
    setIsSaving(true)
    props?.onDeleteEmailTemplate(payload).then((response) => {
      if (response?.success === true) {
        onDeleteDialogOk();
        GetAllEmailTemplatesByType();
        setTimeout(() => {
          setIsSaving(false)
        }, 100);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
        setIsSaving(false)
      } else {
        setIsSaving(false)
      }
    }).catch((error) => {
      setIsSaving(false)
    });

  };

  const inputchangehandler = (e, identifier) => {
    if (identifier === "templateName") {
      setTemplateName(e);
    }
    if (identifier === "templateSubject") {
      setTemplateSubject(e);
    }
  };

  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value);
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const GetAllEmailTemplatesByType = useCallback(async () => {
    dispatch(
      userActions.GetAllEmailTemplatesByType(
        template_type,
        searchTerm,
        filterpage,
        filterorder,
        filtersort,
        filterlimit,
        archived == true ? "archived" : ""
      )
    ).then((response) => {

      if (response?.success === true) {
        const arrayData = response?.data?.rows;
        setCountData(response?.data?.count)
        const maildata = [];
        for (let key in arrayData) {
          const created = moment(arrayData[key]?.created_date).format(
            "DD/MM/YYYY"
          );
          const edited = moment(arrayData[key]?.updated_date).format(
            "DD/MM/YYYY"
          );
          maildata.push({
            email_uuid: arrayData[key]?.email_template_uuid,
            createdByUser: arrayData[key]?.createdByUser,
            template_name: arrayData[key]?.template_name,
            template_subject: arrayData[key]?.template_subject,
            deletedByUser: arrayData[key]?.deletedByUser,
            deleted_date: arrayData[key]?.deleted_date ? moment(arrayData[key]?.deleted_date).format("DD/MM/YYYY") : arrayData[key]?.deleted_date,
            template_type:
              arrayData[key]?.template_type === "admin_provider"
                ? "Admin Provider"
                : arrayData[key]?.template_type === "admin_client"
                  ? "Admin Client"
                  : arrayData[key]?.template_type === "admin_worker"
                    ? "Admin Worker"
                    : "",
            updated_date: edited,
            created_date: created,
          });
        }

        setEmailData(maildata);
        setTotalPages(Math.ceil(response?.data?.count / filterlimit));
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      } else {
      }
    });
    // eslint-disable-next-line
  }, [
    dispatch,
    template_type,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
    archived
  ]);

  // Function to get data for specific email template
  const GetEmailTemplateById = useCallback(
    async (email_uuid) => {
      dispatch(userActions.GetEmailTemplateById(email_uuid)).then(
        (response) => {
          if (response?.success === true) {
            const emaiUUID = response?.data?.email_template_uuid;
            setEmailUuid(emaiUUID);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          } else {
          }
        }
      );
      // eslint-disable-next-line
    },
    [dispatch]
  );

  useEffect(() => {
    GetAllEmailTemplatesByType();
  }, [
    GetAllEmailTemplatesByType,
    template_type,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
    archived,
  ]);


  //call permission api 
  const GetSpecificUserRole = useCallback(async () => {
    if (role_assignment_uuid) {
      dispatch(userActions.GetSpecificUserRole(role_assignment_uuid)).then(
        (response) => {
          if (response?.success === true) {
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    GetSpecificUserRole()
  }, [])



  // Handle file change in the attachment
  // const handleFileChange = (files) => {
  //   setAttachment({ files });
  // };

  const handleFileChange = (files) => {
    let totalSize = 0;
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    // Filter and validate files
    let fileTypeError = false;
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          setdocumentError(
            "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
          );
          fileTypeError = true
          return false;
        }

        // Check individual file size (2MB limit)
        if (fileSize > 25 * 1024 * 1024) {
          setdocumentError("File size exceeds the 25MB limit.");
          totalSize += fileSize;
          return false;
        }
        else {
          totalSize += fileSize;
          return true;
        }

      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
      return;
    }

    // Update state if everything is valid
    if (validatedFiles?.length > 0) {
      setAttachment({ files: validatedFiles });
      // setFilesUploaded(true)
      if (fileTypeError) {
        setdocumentError(
          "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
        );
        return false;
      }
      else {
        if (totalSize > 25 * 1024 * 1024) {
          setdocumentError("Total file size exceeds the maximum limit of 25MB.");
          return;
        }
        else {
          setdocumentError(""); // Clear any existing errors
        }
      }
    } else {
      setAttachment({ files: [] }); // Clear files if no valid files
    }
  };

  // const handleFileRemove = (files) => {
  //   // Handle the file remove logic here
  //   setAttachment({ files });
  // };
  const handleFileRemove = (files) => {
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    let totalSize = 0;
    let fileTypeError = false;

    // Revalidate remaining files after one is removed
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          fileTypeError = true;
          return false;
        }

        totalSize += fileSize;
        return true;
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
    } else if (fileTypeError) {
      setdocumentError(
        "Invalid file type detected. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
      );
    } else {
      setdocumentError(""); // Clear any existing errors if valid
    }

    // Update state with the validated files
    setAttachment({ files: validatedFiles });

    // Handle edge case when all files are removed
    if (validatedFiles?.length === 0) {
      // setFilesUploaded(false);
    }


    if (files.length === 0) {
      // setFilesUploaded(false)
    }
  };

  const handleReactSelectChange = (selectedOption) => {
    setTemplateSec(selectedOption.value); // Save the selected option to state
    setTempSecError("")
  };

  // Function to calculate the total size of attachments in bytes
  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment.files.forEach((file) => {
      totalSize += file.size;
    });
    return totalSize;
  };

  const submithandler = (e) => {
    e.preventDefault();

    //Validate Email & other
    const tempNameValidation = Validations({
      value: templateName,
      Validations: { required: true },
    });
    const tempSubValidation = Validations({
      value: templateSubject,
      Validations: { required: true },
    });
    const tempContentValidation = Validations({
      value: content == "<p><br></p>" || "" ? "" : content,
      Validations: { required: true },
    });
    const tempSecValidation = Validations({
      value: templateSec,
      Validations: { required: true },
    });
    if (!tempNameValidation.valid) {
      setNameError(tempNameValidation.error_msg);
    }
    if (!tempSubValidation.valid) {
      setSubjectError(tempSubValidation.error_msg);
    }
    if (!tempContentValidation.valid) {
      setMessageError(tempContentValidation.error_msg);
    }
    if (!tempSecValidation.valid) {
      setTempSecError(tempSecValidation.error_msg);
    }
    const totalAttachmentSize = calculateTotalAttachmentSize();
    if (totalAttachmentSize > 25 * 1024 * 1024) {
      return;
    }
    const files = attachment;

    if (
      tempNameValidation.valid === true &&
      tempSubValidation.valid === true &&
      tempContentValidation.valid === true &&
      tempSecValidation.valid === true &&
      documentError == ""
    ) {
      const payload = {
        template_name: templateName,
        template_subject: templateSubject,
        template_content: content,
        template_type: templateSec,
        email_temp_doc: files, // Array to hold file objects
      };

      setIsSaving(true);
      props?.onCreateEmailTemplate(payload)
        .then((response) => {
          if (response?.success === true) {
            onDialogOk();
            setTemplateName("");
            setTemplateSubject("");
            setContent("");
            setTemplateSec("");
            setAttachment({ files: [] });
            GetAllEmailTemplatesByType();
            setIsSaving(false);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          } else {
            setIsSaving(false);
          }
        })
        .catch((error) => {
          setIsSaving(false)
        });
    }
  };

  return (
    <React.Fragment>
      {archived == false && (
        <div className="flex justify-between mb-5">
          <div className="w-80 h-9">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img src={searchIcon} alt="" className="h-5" />
              </div>
              <input
                type="text"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                onChange={(e) => inputchangeSearch(e)}
              />
            </div>
          </div>

          {permission_info && permission_info.role_name !== "support team" &&
            <BtnPrimary
              onClick={() => openDialog()}
              className="flex items-center gap-2 justify-center w-auto px-3 text-12 h-9"
            >
              Create template
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.712 4.167v11.666M4.878 10h11.667"
                  stroke="#fff"
                  strokeWidth="1.667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </BtnPrimary>
          }

        </div>
      )}
      <div className=" border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-60">
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("template_name")}
                >
                  Template name
                  <svg
                    className={`transform ${filtersort === "template_name" && filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-60">
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("template_subject")}
                >
                  Subject
                  <svg
                    className={`transform ${filtersort === "template_subject" && filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>

              <Th className="w-96">Created by</Th>
              {/* <Th className="w-48">Section</Th> */}
              <Th className="w-40">
                {" "}
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("created_date")}
                >
                  Date created
                  <svg
                    className={`transform ${filtersort === "created_date" && filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              {archived == true ? (<>
                <Th className="w-40">
                  {" "}
                  <span
                    className="flex items-center gap-1"
                    onClick={() => handleSort("deleted_date")}
                  >
                    Archived
                    <svg
                      className={`transform ${filtersort === "deleted_date" && filterorder === "asc"
                        ? "rotate-180"
                        : ""
                        }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Th>
                <Th className="w-48">Archived by</Th>
              </>) : (<>

                <Th className="w-40">
                  {" "}
                  <span
                    className="flex items-center gap-1"
                    onClick={() => handleSort("updated_date")}
                  >
                    Last modified
                    <svg
                      className={`transform ${filtersort === "updated_date" && filterorder === "asc"
                        ? "rotate-180"
                        : ""
                        }`}
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                        stroke="#475467"
                        strokeWidth="1.333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Th>
                <Th className="w-36"></Th>
              </>)}
            </Tr>
          </THead>
          <TBody>
            {emaiData
              ? emaiData.map((item) => (
                <Tr>
                  <Td>{item?.template_name ? item?.template_name : "-"}</Td>
                  <Td>{item?.template_subject ? item?.template_subject : "-"}</Td>
                  <Td>{item?.createdByUser ? item?.createdByUser : "-"}</Td>
                  <Td>{item?.created_date ? item?.created_date : "-"}</Td>
                  {/* <Td>{item?.template_type.split(" ")[1] + " " + "Section"}</Td> */}

                  {archived == true ? (<>
                    <Td>{item?.deleted_date ? item?.deleted_date : "-"}</Td>
                    <Td>{item?.deletedByUser ? item?.deletedByUser : "-"}</Td>
                  </>) : (<>
                    <Td>{item?.updated_date ? item?.updated_date : "-"}</Td>
                    <Td>
                      {permission_info ? permission_info.role_name !== "support team" ?
                        <div className="flex justify-end gap-4">
                          <Tooltip title="Delete" placement="top">
                            <Button
                              onClick={() => openDeleteDialog(item?.email_uuid)}
                            >
                              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.83268 6.66383C3.69601 6.6603 3.59676 6.65239 3.50753 6.63464C2.84638 6.50313 2.32955 5.9863 2.19804 5.32515C2.16602 5.16415 2.16602 4.97055 2.16602 4.58333C2.16602 4.19612 2.16602 4.00251 2.19804 3.84152C2.32955 3.18037 2.84638 2.66354 3.50753 2.53202C3.66853 2.5 3.86214 2.5 4.24935 2.5H16.7493C17.1366 2.5 17.3302 2.5 17.4912 2.53202C18.1523 2.66354 18.6691 3.18037 18.8007 3.84152C18.8327 4.00251 18.8327 4.19612 18.8327 4.58333C18.8327 4.97055 18.8327 5.16415 18.8007 5.32515C18.6691 5.9863 18.1523 6.50313 17.4912 6.63464C17.4019 6.65239 17.3027 6.6603 17.166 6.66383M8.83268 10.8333H12.166M3.83268 6.66667H17.166V13.5C17.166 14.9001 17.166 15.6002 16.8935 16.135C16.6538 16.6054 16.2714 16.9878 15.801 17.2275C15.2662 17.5 14.5661 17.5 13.166 17.5H7.83268C6.43255 17.5 5.73249 17.5 5.19771 17.2275C4.7273 16.9878 4.34485 16.6054 4.10517 16.135C3.83268 15.6002 3.83268 14.9001 3.83268 13.5V6.66667Z" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>

                            </Button>
                          </Tooltip>
                          <Tooltip title="Edit" placement="top">
                            <button
                              onClick={() => {
                                setPopupId(item?.email_uuid);
                                setOpenPopup(!openPopup);
                              }}
                            >
                              <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            {/* </Button> */}
                          </Tooltip>
                        </div>
                        : null :
                        <div className="flex justify-end gap-4">
                          <Tooltip title="Delete" placement="top">
                            <Button
                              onClick={() => openDeleteDialog(item?.email_uuid)}
                            >
                              <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Button>
                          </Tooltip>
                          <Tooltip title="Edit" placement="top">
                            <button
                              onClick={() => {
                                setPopupId(item?.email_uuid);
                                setOpenPopup(!openPopup);
                              }}
                            >
                              <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                                  stroke="#475467"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            {/* </Button> */}
                          </Tooltip>
                        </div>}
                    </Td>
                  </>)}
                </Tr>
              ))
              : null}
          </TBody>
        </Table>
      </div>
      <div className="flex justify-between mt-3">
        <Pagination totalCount={countData} pageNumber={filterpage} displayPage={emaiData?.length} UpdatePageLimit={setfilterlimit} UpdatePage={setfilterpage} />
        {countData > 0 && <ReactPaginate
          className="flex justify-content-end p-2 align-items-center pagination-wrap"
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLinkClassName={"prev-link"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"prev-link"}
          previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
          nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
          pageLinkClassName={"px-4 rounded-md"}
          activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
          disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          renderOnZeroPageCount={null}
          forcePage={filterpage - 1}
        />}
      </div>
      <Dialog
        isOpen={dialogDeleteIsOpen}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to remove this Email template?
          </p>
          <p className="text-gray-600 text-center">
            This action cannot be undone.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6  gap-3">
          <BtnLight
            className="customAlert"
            variant="plain"
            onClick={onDeleteDialogClose}
            disabled={isSaving}
          >
            Cancel
          </BtnLight>
          <BtnPrimary disabled={isSaving} variant="solid" onClick={deleteEmail}>
            Confirm
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
      >
        <div className="flex border-b pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={emailIcon} alt=""></img>
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Create an email template
            </h5>
            <p className="text-gray-600">
              Compose and send an email directly to user
            </p>
          </div>
        </div>
        <form>
          <div className="p-6">
            <div className="flex gap-3">
              <div className="mb-4 flex flex-col flex-1">
                <Label className="font-medium text-xs mb-2">Template name*</Label>
                <Input
                  className="h-9"
                  type="text"
                  placeholder=""
                  inputchangehandler={(e) => { inputchangehandler(e, "templateName"); setNameError("") }
                  }
                />
                {nameError && (
                  <div className="text-red-500 text-sm mt-1">{nameError}</div>
                )}
              </div>
              <div className="mb-4 flex flex-col flex-1">
                <Label className="font-medium text-xs mb-2">
                  Which section of admin portal?*
                </Label>
                <Select
                  className="h-9 hide-seacrh-icon h-full-wrap"
                  type="text"
                  value={sectionOption.filter(function (option) {
                    return option.value === templateSec;
                  })}
                  options={sectionOption}
                  onChange={handleReactSelectChange}
                  placeholder=""
                />
                {tempSecError && (
                  <div className="text-red-500 text-sm mt-1">{tempSecError}</div>
                )}
              </div>
            </div>
            <div className="mb-4 flex flex-col">
              <Label className="font-medium text-xs mb-1.5">Subject*</Label>
              <Input
                className="h-9"
                type="text"
                placeholder=""
                inputchangehandler={(e) => {
                  inputchangehandler(e, "templateSubject"); setSubjectError("")
                }}
              />
              {subjectError && (
                <div className="text-red-500 text-sm mt-1">{subjectError}</div>
              )}
            </div>

            <div className="flex flex-col">
              <div>
                <Label className="font-medium text-xs mb-1.5">Message*</Label>
                <RichTextEditor
                  value={content}
                  onChange={(e) => handleChange(e)}
                />
                {messageError && (
                  <div className="text-red-500 text-sm mt-1">
                    {messageError}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-5">
              <Label className="text-xs font-medium block mb-1 mt-3">
                Attachment
              </Label>
              <Upload
                draggable
                className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                onChange={handleFileChange}
                onFileRemove={handleFileRemove}
                multiple={true} // Allow multiple file uploads
                maxFileSize={25 * 1024 * 1024} // Max file size in bytes (25MB)
              >
                <span className="text-blue-700 font-semibold">
                  Click to upload
                </span>{" "}
                <span className="font-medium text-gray-600">
                  or drag and drop
                </span>
                <p className="text-gray-600">
                  PDF, JPG, JPEG, PNG, GIF (MAX. 25MB)
                </p>              </Upload>
              {documentError && <p className='text-xs text-red-600 mt-1'>{documentError}</p>}

            </div>
          </div>
        </form>
        <div className="flex dailog-footer mt-0 justify-end gap-4">
          <BtnLight
            className="w-auto px-3"
            variant="plain"
            onClick={onDialogClose}
            width={900}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            className="w-auto px-3"
            variant="solid"
            onClick={submithandler}
            type="submit"
            disabled={isSaving} // Disable the button when saving is in progress
          >
            {isSaving ? "Saving..." : "Save template"}
          </BtnPrimary>
        </div>
      </Dialog>
      {/* Open Edit Emailt Popup */}
      {openPopup && (
        <EditTemplate GetAllEmailTemplatesByType={GetAllEmailTemplatesByType} email_uuid={popupId} setOpenPopup={setOpenPopup} />
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    loader: userActions.CreateEmailTemplate.loader,
  };
}
const mapDispatchToProps = {
  onCreateEmailTemplate: userActions.CreateEmailTemplate,
  onDeleteEmailTemplate: userActions.DeleteEmailTemplate,
  GetEmailTemplateById: userActions.GetEmailTemplateById,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateList);
