import axios from "axios";
import * as actionType from "../../constant/actionType";

export const UpdateProviderOrgDetails = (data) => async (dispatch) => {
    // Final CSV Data
    try {
      let formdata = new FormData();
      for (let key in data) {
          formdata.append(key, data[key]);
      }
      const response = await axios({
        method: "put",
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/provider/UpdateProviderOrgDetails`,
      });
      return response.data;
    } catch (error) {}
  };