// import axios from "axios";
import axios from "axios";
import * as actionType from "../../constant/actionType";

export const DeleteCompanyInduction = (indId, orgId, trading_name, login_user, induction_name) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: { company_induction_uuid: indId, organisation_uuid: orgId, trading_name: trading_name, login_user: login_user, induction_name: induction_name },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/client/DeleteCompanyInduction`,
    });
    return response.data;
  } catch (error) { }
};