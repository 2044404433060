import React from "react";
import Menu from "../../../../../common/element/Menu";
import { NavLink } from "react-router-dom";
const AdminMenu = () => {
  const handleToggle = (expanded, e) => {
  };

  return (
    <div className="" style={{ maxWidth: 176 }}>
      <Menu>
        <Menu.MenuItem eventKey="item-1"><NavLink to="dashboard">Dashboard</NavLink></Menu.MenuItem>
        {/* All User */}
        <Menu.MenuCollapse
         eventKey="item-2"
         label="Users"
         onToggle={handleToggle}>
           <Menu.MenuItem eventKey="item-2-1"><NavLink to="users">All Users</NavLink> </Menu.MenuItem>
           <Menu.MenuItem eventKey="item-2-2"><NavLink to="notifications">Notifications</NavLink> </Menu.MenuItem>


        </Menu.MenuCollapse>
        <Menu.MenuCollapse
          eventKey="item-3"
          label="System Config"
          onToggle={handleToggle}
        >
          <Menu.MenuItem eventKey="item-3-1"><NavLink to="doc-type">Doc Types</NavLink> </Menu.MenuItem>
          <Menu.MenuItem eventKey="item-3-2"><NavLink to="services-types">Service Types</NavLink> </Menu.MenuItem>
          <Menu.MenuItem eventKey="item-3-4"><NavLink to="provider-types">Provider Types</NavLink> </Menu.MenuItem>
          <Menu.MenuItem eventKey="item-3-3"><NavLink to="email-templates">Email Templates</NavLink> </Menu.MenuItem>
          <Menu.MenuItem eventKey="item-3-"><NavLink to="item-two">Payment Tiers</NavLink> </Menu.MenuItem>
        </Menu.MenuCollapse>
        <Menu.MenuCollapse
          eventKey="item-4"
          label="System Logs"
          onToggle={handleToggle}
        >
          <Menu.MenuItem eventKey="item-3-1"><NavLink to="audit-log">Audit Log</NavLink> </Menu.MenuItem>
          <Menu.MenuItem eventKey="item-3-2"><NavLink to="email-log">Email Log</NavLink> </Menu.MenuItem>
        </Menu.MenuCollapse>
      </Menu>
    </div>
  );
};

export default AdminMenu;
