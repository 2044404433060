const BtnPrimary =(props) =>{
    const classes = "btn border border-blue-700 block w-full rounded-lg h-10 bg-blue-700 text-white text-sm font-semibold hover:bg-blue-800 focus:bg-blue-700 duration-100 primary-btn " + props.className;
    return(
        <button className={classes} disabled={props.disabled} type={props.type || "button"}
        onClick={props.onClick}>
            {props.children}
        </button>
    )
}

export default BtnPrimary