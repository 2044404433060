import React from "react"
import { Route, Routes } from "react-router-dom"
import Dashboard from "../pages/Dashboard"
import WorkerDocument from "../pages/Documents"
// import WorkerInductions from "../pages/Inductions"
import Inductions from "../pages/Inductions"
import WorkerSiteLog from "../pages/SiteLog"
import WorkerContractors from "../pages/Providers"
import ClientsAssigned from "../pages/ClientsAssigned" 
import WorkerSupport from "../pages/Support"
import WorkerSettings from "../pages/Settings"
export const WorkerPortalRoutes =()=>{
    return(
        <React.Fragment>
            <Routes>
                <Route path="dashboard" element={<Dashboard />}/>
                <Route path="documents" element={<WorkerDocument />}/>
                <Route path="inductions" element={<Inductions/>}/>
                <Route path="siteLog" element={<WorkerSiteLog/>}/>
                <Route path="contractors" element={<WorkerContractors/>}/>
                <Route path="clientsAssigned" element={<ClientsAssigned/>}/>
                <Route path="support" element={<WorkerSupport/>}/>
                <Route path="settings" element={<WorkerSettings/>}/>
            </Routes>
        </React.Fragment>
    )
}