
import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Steps from "../../../../../common/element/Steps";
import Segment from "../../../../../common/element/Segment";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import * as userActions from "../../../../../actions/index";
import Validations from "../../../../../common/404/Validations";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import imagePlus from "../../../../../assets/icons-svg/image-plus.svg";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";
import imageArrow from "../../../../../assets/icons-svg/arrow-right.svg";
import AvatarEditor from 'react-avatar-image-cropper';
import { notify } from "react-notify-toast";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";

const AddClientForm = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const MAX_WIDTH = 800;
  const MAX_HEIGHT = 400;
  // Step state to manage the form navigation
  const [step, setStep] = useState(0);
  const [docAdd, setDocAdd] = useState(false);
  const [firstStep, secondStep] = useState(false);
  // state for managing business type
  const [businessType, setBusinessType] = useState("");
  const [logo, setLogo] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [formData, setFormData] = useState({
    trading_name: "",
    abn_nzbn: "",
    acn: "",
    website_url: "",
    businessAddres1: "",
    businessAddres2: "",
    businessAddresCity: "",
    businessAddresPostCode: "",
    businessAddresState: "",
    businessAddresCountry: "",
    mailingAddres1: "",
    mailingAddres2: "",
    mailingAddresCity: "",
    mailingAddresPostCode: "",
    mailingAddresState: "",
    mailingAddresCountry: "",
  });
  const [formErrors, setFormErrors] = useState({
    abn: "",
    acn: "",
    trading_name: "",
    businessAddres1: "",
    businessAddresCity: "",
    businessAddresPostCode: "",
    businessAddresState: "",
    businessAddresCountry: "",
    mailingAddres1: "",
    mailingAddresCity: "",
    mailingAddresPostCode: "",
    mailingAddresState: "",
    mailingAddresCountry: "",
    org_type: "",
    existClient: "",
  });
  const [clientAddedDialog, setClientAddedDialog] = useState(false)
  const [hasMounted, setHasMounted] = useState(false);
  const [businessStateName, setBusinessStateName] = useState("");
  const [businessCountryName, setBusinessCountryName] = useState("");
  const [mailingStateName, setMailingStateName] = useState("");
  const [MailingCountryName, setMailingCountryName] = useState("");
  const [isParent, setIsParent] = useState("no");
  const [functionUuid, setfunctionUuid] = useState("");
  const [parentData, setParentData] = useState("");
  const [parentUuid, setParentUuid] = useState("");
  const [organizationUuid, setOrganizationUuid] = useState("");
  const [parentLevel, setParentLevel] = useState("");
  const [countries, setCountries] = useState();
  const [statesOne, setStatesOne] = useState();
  const [statesTwo, setStatesTwo] = useState();
  const [sameAddress, setSameAddress] = useState();
  const [clientExist, setClientExist] = useState(true);
  // const [OpenImageModal, setOpenImageModal] = useState(false);
  const allFunctions = useSelector((state) => state.allFunctions.allFunction);
  // const [cropImage, setCropImage] = useState(false);
  // const maxsize= 800 * 400 * 2

  const clearFormErrors = () => {
    setClientExist(true)
    setFormErrors({
      abn: "",
      acn: "",
      trading_name: "",
      businessAddres1: "",
      businessAddresCity: "",
      businessAddresPostCode: "",
      businessAddresState: "",
      businessAddresCountry: "",
      mailingAddres1: "",
      mailingAddresCity: "",
      mailingAddresPostCode: "",
      mailingAddresState: "",
      mailingAddresCountry: "",
      org_type: "",
    });
  };

  const clearFormData = () => {
    setFormData({
      trading_name: "",
      abn_nzbn: "",
      acn: "",
      website_url: "",
      businessAddres1: "",
      businessAddres2: "",
      businessAddresCity: "",
      businessAddresPostCode: "",
      businessAddresState: "",
      businessAddresCountry: "",
      mailingAddres1: "",
      mailingAddres2: "",
      mailingAddresCity: "",
      mailingAddresPostCode: "",
      mailingAddresState: "",
      mailingAddresCountry: "",
    });
    setIsParent("");
    setBusinessType("");
    // setLogo("");
    setParentLevel("");
  };

  useEffect(() => {
    let functionData = allFunctions.filter(
      (item) => item.function_name === "client"
    );
    let functionid = functionData[0]?.function_uuid;
    setfunctionUuid(functionid);
    GetAllParentClientList(functionid);
  }, []);

  const GetAllParentClientList = useCallback(
    async (functionid) => {
      if (functionid) {
        dispatch(userActions.GetAllParentClientList(functionid)).then(
          (response) => {
            if (response.success === true) {
              const arrayData = response.data;
              let parent_data = [];
              for (let key in arrayData) {
                parent_data.push({
                  parent_level: arrayData[key].f_a_parent_child_level,
                  parent_Uuid: arrayData[key].function_assignment_uuid,
                  parent_name: arrayData[key].trading_name,
                  organization: arrayData[key].organisation_uuid,
                });
              }
              setParentData(parent_data);
            } else if (response.status === 401) {
              handleUnauthorizedAccess()
            } else {
            }
          }
        );
      }
      // eslint-disable-next-line
    },
    [dispatch]
  );

  const onAddDialogClose = () => {
    setClientAddedDialog(false)
    props?.onDialogClose();
  }
  useEffect(() => {
    // Skip the effect on the first render
    if (!hasMounted) {
      setHasMounted(true);
      return;
    }
    else {
      if (formErrors?.org_type == "" && formErrors?.abn_nzbn == "" && formErrors?.trading_name == "" && formErrors?.existClient == "" && clientExist == false) {
        onChange(step + 1);
      }
    }
  }, [clientExist == false]);

  const GetAlreadyExistingOrgCheck = useCallback(
    async (trading_name, abn_nzbn) => {
      try {
        const response = await dispatch(userActions.GetAlreadyExistingOrgCheck(trading_name, abn_nzbn));

        if (response.success === true) {
          const arrayData = response.data;
          if (arrayData?.abn_nzbn === abn_nzbn && arrayData?.trading_name === trading_name) {
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              existClient: 'The Name and ABN/NZBN you have provided are already registered on our system. In order to register as a new organization, please choose a different name or ABN/NZBN',
            }));
          } else {
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              existClient: '',
            }));
            setClientExist(false);
          }
        } else if (response.status === 401) {
          handleUnauthorizedAccess();
        }
      } catch (error) {
        console.error("Error fetching organization check:", error);
      }
    },
    [dispatch]
  );

  const GetAllStatesbyCountryId = useCallback(async () => {
    if (formData?.businessAddresCountry) {
      dispatch(
        userActions.GetAllStatesbyCountryId(formData?.businessAddresCountry)
      ).then((response) => {
        if (response.success === true) {
          const arrayData = response.data;
          let state_data = [];
          for (let key in arrayData) {
            state_data.push({
              label: arrayData[key].state_name,
              value: arrayData[key].state_id,
            });
          }
          setStatesOne(state_data);
        } else if (response.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      });
    }
    if (formData?.mailingAddresCountry) {
      dispatch(
        userActions.GetAllStatesbyCountryId(formData?.mailingAddresCountry)
      ).then((response) => {
        if (response.success === true) {
          const arrayData = response.data;
          let state_data = [];
          for (let key in arrayData) {
            state_data.push({
              label: arrayData[key].state_name,
              value: arrayData[key].state_id,
            });
          }
          setStatesTwo(state_data);
        } else if (response.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      });
    }
    // eslint-disable-next-line
  }, [dispatch, formData?.businessAddresCountry, formData?.mailingAddresCountry]);

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response.success === true) {
        const arrayData = response.data;
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
            // country_id: arrayData[key].country_id,
          });
        }
        setCountries(country_data);
      } else if (response.status === 401) {
        handleUnauthorizedAccess()
      } else {
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetAllStatesbyCountryId();
    GetAllCountries();
  }, [formData?.businessAddresCountry, formData?.mailingAddresCountry]);

  const ParentSelect = (e) => {
    const parent = parentData.filter((parent) => parent.parent_Uuid === e);
    setParentLevel(parent[0].parent_level);
    setParentUuid(parent[0].parent_Uuid);
    setOrganizationUuid(parent[0].organization);
  };

  const onChange = (nextStep) => {
    if (nextStep < 0) {
      setStep(0);
    } else {
      setStep(nextStep);
    }
  };

  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const onNext = async () => {
    const abnValidation = Validations({
      value: formData?.abn_nzbn,
      Validations: { required: true, ValidateABN: true },
    });
    const typeValidation = Validations({
      value: businessType,
      Validations: { required: true },
    });
    const nameValidation = Validations({
      value: formData?.trading_name,
      Validations: { required: true },
    });

    if (!typeValidation.valid) {
      setFormError("org_type", typeValidation.error_msg);
    }
    if (!nameValidation.valid) {
      setFormError("trading_name", nameValidation.error_msg);
    }
    if (!abnValidation.valid) {
      setFormError("abn_nzbn", abnValidation.error_msg);
    }
    if (typeValidation.valid && nameValidation.valid && abnValidation.valid) {
      await GetAlreadyExistingOrgCheck(formData?.trading_name?.trim().replace(/\s+/g, ' '), formData?.abn_nzbn);
    }
  };

  // Event handler to navigate to the previous step
  const onPrevious = () => {
    setClientExist(true)
    onChange(step - 1);
  };

  // Event handler to create a client
  const onClickCreateClient = () => {
    if (step === 1) {
      // Only call submitClient() when the user is on the second step
      submitClient(); // Call the function to submit the client data
    } else {
      onNext(); // If the user is on the first step, proceed to the next step
    }
  };

  // Function to handle form input changes
  const inputchangehandler = (e, field) => {
    let value = e.target.value;

    if (field === 'website_url') {
      value = value.replace(/\s+/g, '');
    }
    if (field === 'mailingAddresCountry') {
      let countryName = countries.filter((item) => value == item.value)
      setMailingCountryName(countryName[0].label)
    }
    if (field === 'businessAddresCountry') {
      let countryName = countries.filter((item) => value == item.value)
      setBusinessCountryName(countryName[0].label)
    }
    if (field === 'businessAddresState') {
      let stateName = statesOne.filter((item) => value == item.value)
      setBusinessStateName(stateName[0].label)
    }
    if (field === 'mailingAddresState') {
      let stateName = statesTwo.filter((item) => value == item.value)
      setMailingStateName(stateName[0].label)
    }
    if (field === "abn_nzbn" || field === "trading_name") {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        existClient: '',
      }));
    }
    if (field === "trading_name") {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        trading_name: '',
      }))

    }
    if (field === "abn_nzbn") {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        abn_nzbn: '',
      }))
    }
    // if (field === "abn_nzbn") {
    //   // Remove non-numeric characters
    //   value = value.replace(/\D/g, "");
    // }
    // if (field === "website_url") {
    //   if (value.includes("www.") && !value.startsWith("www.")) {
    //     value = value.replace("www.", "");
    //   }

    setFormData({ ...formData, [field]: value });

  };

  const handleFocusWebsite = () => {
    // Check if the current value doesn't already start with "www"
    if (!formData?.website_url.startsWith('www')) {
      // Prepend "www" to the input value
      // setWebsite('www.' + website);

      setFormData({
        ...formData,
        website_url: `www.${formData?.website_url}`
      });
    }
  };

  // function to auto space in abn number
  const handleABN = () => {
    let newValue = formData?.abn_nzbn.replace(/\D/g, ""); // Remove non-digit characters
    const length = newValue.length;
    if (length <= 11) {
      // newValue = `${newValue.slice(0, 2)} ${newValue.slice(2)}`;
      newValue = `${newValue.slice(0, 2)} ${newValue.slice(
        2,
        5
      )} ${newValue.slice(5, 8)} ${newValue.slice(8, 11)}`;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      abn_nzbn: newValue,
    }));
  };

  // function to handle isparent state and show & hide find parent client
  const onParentRelation = useCallback(
    (val) => {
      setIsParent(val);
      if (val[0] === "yes") {
        setDocAdd(true);
      } else {
        setDocAdd(false);
      }
    },
    [setIsParent]
  );

  // function to handle business Type
  const onBusinessType = useCallback(
    (val) => {
      setBusinessType(val);
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        org_type: '',
      }));
    },
    [setBusinessType]
  );

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      inputChangeHandlerFile(file);
    }
  };


  const inputChangeHandlerFile = (file) => {
    // Check the file extension
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/svg+xml" ||
        fileType.startsWith("image/png") ||
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/gif")
      ) {
        // Check the file size (max. 800x400px)
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.width <= 800 && img.height <= 400) {
            setLogo(file);
            setUrlImage(URL.createObjectURL(file));
          } else {
            // Handle size validation error
            notify.show(
              "Image dimensions must be within 800x400 pixels.",
              "error"
            );
          }
        };
      } else {
        // Handle file type validation error
        notify.show(
          "Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed.",
          "error"
        );
      }
    }

  };


  const submitClient = (e) => {
    // e.preventDefault()
    if (!sameAddress) {
      // clearFormErrors();
      const add1BusinessValidation = Validations({
        value: formData?.businessAddres1,
        Validations: { required: true },
      });
      const cityBusinessValidation = Validations({
        value: formData?.businessAddresCity,
        Validations: { required: true },
      });
      const postCodeBusinessValidation = Validations({
        value: formData?.businessAddresPostCode,
        Validations: { required: true },
      });
      const countryBusinessValidation = Validations({
        value: formData?.businessAddresCountry,
        Validations: { required: true },
      });
      const stateBusinessValidation = Validations({
        value: formData?.businessAddresState,
        Validations: { required: true },
      });

      const add1MailingValidation = Validations({
        value: formData?.mailingAddres1,
        Validations: { required: true },
      });
      const cityMailingValidation = Validations({
        value: formData?.mailingAddresCity,
        Validations: { required: true },
      });
      const postCodeMailingValidation = Validations({
        value: formData?.mailingAddresPostCode,
        Validations: { required: true },
      });
      const countryMailingValidation = Validations({
        value: formData?.mailingAddresCountry,
        Validations: { required: true },
      });
      const stateMailingValidation = Validations({
        value: formData?.mailingAddresState,
        Validations: { required: true },
      });

      if (!add1BusinessValidation.valid) {
        setFormError("businessAddres1", add1BusinessValidation.error_msg);
      }
      if (!cityBusinessValidation.valid) {
        setFormError("businessAddresCity", cityBusinessValidation.error_msg);
      }
      if (!postCodeBusinessValidation.valid) {
        setFormError(
          "businessAddresPostCode",
          postCodeBusinessValidation.error_msg
        );
      }
      if (!countryBusinessValidation.valid) {
        setFormError(
          "businessAddresState",
          countryBusinessValidation.error_msg
        );
      }
      if (!stateBusinessValidation.valid) {
        setFormError(
          "businessAddresCountry",
          stateBusinessValidation.error_msg
        );
      }

      if (!add1MailingValidation.valid) {
        setFormError("mailingAddres1", add1MailingValidation.error_msg);
      }
      if (!cityMailingValidation.valid) {
        setFormError("mailingAddresCity", cityMailingValidation.error_msg);
      }
      if (!postCodeMailingValidation.valid) {
        setFormError(
          "mailingAddresPostCode",
          postCodeMailingValidation.error_msg
        );
      }
      if (!countryMailingValidation.valid) {
        setFormError("mailingAddresState", countryMailingValidation.error_msg);
      }
      if (!stateMailingValidation.valid) {
        setFormError("mailingAddresCountry", stateMailingValidation.error_msg);
      }

      if (
        add1BusinessValidation.valid === true &&
        cityBusinessValidation.valid === true &&
        postCodeBusinessValidation.valid === true &&
        countryBusinessValidation.valid === true &&
        stateBusinessValidation.valid === true &&
        add1MailingValidation.valid === true &&
        cityMailingValidation.valid === true &&
        postCodeMailingValidation.valid === true &&
        countryMailingValidation.valid === true &&
        stateMailingValidation.valid === true
      ) {
        props?.setButtonDisabled(true)
        const payload = {
          // organisation_uuid: organizationUuid,
          organisation_uuid: '',
          function_assignment_uuid: parentUuid,
          f_a_relation_type: parentUuid ? "client_sub_client" : "",
          function_uuid: functionUuid,
          f_a_parent_child_level:
            parentLevel === 0 ? 1 : parentLevel === 1 ? 2 : 0,
          org_type:
            businessType === "pty"
              ? "pty_traders"
              : businessType === "sole"
                ? "sole_traders"
                : "other", //Assuming businessType is one of "pty", "sole", or "other"
          trading_name: formData?.trading_name,
          abn_nzbn: formData?.abn_nzbn,
          acn: formData?.acn,
          website_url: formData?.website_url,
          logo: logo,
          client_address_data: JSON.stringify([
            {
              address_type: "business",
              address_one: formData?.businessAddres1,
              address_two: formData?.businessAddres2,
              suburb: formData?.businessAddresCity,
              zipcode: formData?.businessAddresPostCode,
              state_id: formData?.businessAddresState,
              country_id: formData?.businessAddresCountry,
              state_name: businessStateName,
              country_name: businessCountryName
            },
            {
              address_type: "mailing",
              address_one: formData?.mailingAddres1,
              address_two: formData?.mailingAddres2,
              suburb: formData?.mailingAddresCity,
              zipcode: formData?.mailingAddresPostCode,
              state_id: formData?.mailingAddresState,
              country_id: formData?.mailingAddresCountry,
              state_name: mailingStateName,
              country_name: MailingCountryName
            },
          ]),
        };


        props.onAddClient(payload).then((response) => {
          if (response.success === true) {

            clearFormErrors("");
            setClientAddedDialog(true);
            props?.setButtonDisabled(false)
            clearFormData("");
          } else if (response.status === 401) {
            handleUnauthorizedAccess()
          } else {
          }
        }).catch((error) => {
          console.log("Error: " + error);
        }).finally(() => {
          props?.setButtonDisabled(false)
        })
      }
    } else if (sameAddress) {
      // clearFormErrors();
      const add1BusinessValidation = Validations({
        value: formData?.businessAddres1,
        Validations: { required: true },
      });
      const cityBusinessValidation = Validations({
        value: formData?.businessAddresCity,
        Validations: { required: true },
      });
      const postCodeBusinessValidation = Validations({
        value: formData?.businessAddresPostCode,
        Validations: { required: true },
      });
      const countryBusinessValidation = Validations({
        value: formData?.businessAddresCountry,
        Validations: { required: true },
      });
      const stateBusinessValidation = Validations({
        value: formData?.businessAddresState,
        Validations: { required: true },
      });

      if (!add1BusinessValidation.valid) {
        setFormError("businessAddres1", add1BusinessValidation.error_msg);
      }
      if (!cityBusinessValidation.valid) {
        setFormError("businessAddresCity", cityBusinessValidation.error_msg);
      }
      if (!postCodeBusinessValidation.valid) {
        setFormError(
          "businessAddresPostCode",
          postCodeBusinessValidation.error_msg
        );
      }
      if (!countryBusinessValidation.valid) {
        setFormError(
          "businessAddresState",
          countryBusinessValidation.error_msg
        );
      }
      if (!stateBusinessValidation.valid) {
        setFormError(
          "businessAddresCountry",
          stateBusinessValidation.error_msg
        );
      }

      if (
        add1BusinessValidation.valid === true &&
        cityBusinessValidation.valid === true &&
        postCodeBusinessValidation.valid === true &&
        countryBusinessValidation.valid === true &&
        stateBusinessValidation.valid === true
      ) {
        props?.setButtonDisabled(true)
        const payload = {
          organisation_uuid: organizationUuid,
          function_assignment_uuid: parentUuid,
          f_a_relation_type: parentUuid ? "client_sub_client" : "",
          function_uuid: functionUuid,
          f_a_parent_child_level:
            parentLevel === 0 ? 1 : parentLevel === 1 ? 2 : 0,
          org_type:
            businessType === "pty"
              ? "pty_traders"
              : businessType === "sole"
                ? "sole_traders"
                : "other", //businessType is one of "pty", "sole", or "other"
          trading_name: formData?.trading_name,
          abn_nzbn: formData?.abn_nzbn,
          acn: formData?.acn,
          website_url: formData?.website_url,
          logo: logo,
          client_address_data: JSON.stringify([
            {
              address_type: "business",
              address_one: formData?.businessAddres1,
              address_two: formData?.businessAddres2,
              suburb: formData?.businessAddresCity,
              zipcode: formData?.businessAddresPostCode,
              state_id: formData?.businessAddresState,
              country_id: formData?.businessAddresCountry,
              state_name: businessStateName,
              country_name: businessCountryName
            },
            {
              address_type: "mailing",
              address_one: formData?.businessAddres1,
              address_two: formData?.businessAddres2,
              suburb: formData?.businessAddresCity,
              zipcode: formData?.businessAddresPostCode,
              state_id: formData?.businessAddresState,
              country_id: formData?.businessAddresCountry,
              state_name: businessStateName,
              country_name: businessCountryName
            },
          ]),
        };


        props?.onAddClient(payload).then((response) => {
          if (response.success === true) {
            // props.onDialogClose();
            clearFormErrors("");
            setClientAddedDialog(true);
            props?.setButtonDisabled(false)
            clearFormData("");
          } else if (response.status === 401) {
            handleUnauthorizedAccess()
          } else {
          }
        }).catch((error) => {
          console.log("Error: " + error);
        }).finally(() => {
          props?.setButtonDisabled(false)
        })
      }
    }
  };

  return (
    <React.Fragment>
      <div className="px-6">
        <Steps current={step}>
          <Steps.Item title="Client business Information" />
          <Steps.Item title="Client Addresses" />
        </Steps>
      </div>
      {/* <form> */}
      <div className="mt-6 add-client-form px-6">
        {step === 0 ? (
          // Step 1: Client Business Information
          <div id={firstStep}>
            <div className="flex justify-between mb-5">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">
                  Does this client belong to a Parent Client?
                </label>
              </div>
              <div className="columns-input mb-5">
                <Segment
                  className="docType-btn"
                  value={isParent}
                  onChange={(val) => onParentRelation(val)}
                >
                  <Segment.Item value="yes">Yes</Segment.Item>
                  <Segment.Item value="no">No</Segment.Item>
                </Segment>
              </div>
            </div>
            {docAdd && (
              <div className="flex justify-between">
                <div className="lavel-wd">
                  <label className="text-gray-700 font-medium">
                    Find Parent Client
                  </label>
                </div>
                <div className="columns-input mb-5">
                  <select
                    value={parentUuid}
                    onChange={(e) => ParentSelect(e.target.value)}
                    className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option>Find a Parent Client</option>
                    {parentData &&
                      parentData.map((parent) => (
                        <option
                          key={parent.parent_Uuid}
                          value={parent.parent_Uuid}
                        >
                          {parent.parent_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}
            <hr className="border-gray-200 mb-5" />
            <div className="flex justify-between mb-5">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">
                  Business Type*
                </label>
              </div>
              <div className="columns-input mb-5">
                <Segment
                  className="docType-btn"
                  value={businessType}
                  onChange={(val) => onBusinessType(val)}
                >
                  <Segment.Item value="pty">
                    <span>Pty Ltd</span>
                  </Segment.Item>
                  <Segment.Item value="sole">
                    <span>Sole Trader</span>
                  </Segment.Item>
                  <Segment.Item value="other">
                    <span>Other</span>
                  </Segment.Item>
                </Segment>
                {formErrors?.org_type && (
                  <p className="text-xs text-red-600 mt-1">
                    {formErrors?.org_type}
                  </p>
                )}
              </div>
            </div>
            {/* {cropImage &&
                        <div className=" rounded"
                        style={{position: 'absolute', width: '800px', backgroundColor: 'white',
                        height: '400px', borderRadius: '50%', margin: 'auto'}}
                        >
                            <AvatarEditor
                                rootStyle={{ borderRadius: "50%" }}
                                // width={200} // Set the width of the editor as per your requirement
                                // height={200} // Set the height of the editor as per your requirement
                                width={maxsize}
                                height={maxsize / 2}
                                apply={inputChangeHandlerFile}
                                sliderConStyle={{ backgroundColor: "none"}}
                                applyBtnStyle={{}}
                                isBack={true} 
                                maxsize={maxsize}
                                className={"image_crop_style"}
                                />
                        </div>
                } */}
            <div className="flex justify-between">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">
                  Business Name*
                </label>
              </div>
              <div className="columns-input mb-5">
                <input
                  type="text"
                  placeholder="Enter business name here"
                  className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData?.trading_name}
                  onChange={(e) => inputchangehandler(e, "trading_name")}
                />
                {formErrors?.trading_name && (
                  <p className="text-xs text-red-600 mt-1">
                    {formErrors?.trading_name}
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">ABN/NZBN*</label>
              </div>
              <div className="columns-input mb-5">
                <input
                  type='number'
                  placeholder="Enter ABN/NZBN here"
                  class=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData?.abn_nzbn}
                  onChange={(e) => inputchangehandler(e, "abn_nzbn")}
                  // onBlur={handleABN}
                  maxLength="14"
                  minLength="11"
                />
                {formErrors?.abn_nzbn && (
                  <p className="text-xs text-red-600 mt-1">
                    {formErrors?.abn_nzbn}
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">ACN</label>
              </div>

              <div className="columns-input mb-5">
                <input
                  type="text"
                  placeholder="Enter ACN here"
                  className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData?.acn}
                  onChange={(e) => inputchangehandler(e, "acn")}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">Website</label>
              </div>
              <div className="columns-input mb-5">
                <input
                  type="text"
                  placeholder="Enter website here"
                  className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData?.website_url}
                  onChange={(e) => inputchangehandler(e, "website_url")}
                  onFocus={handleFocusWebsite}
                />
              </div>
            </div>

            <div className="flex justify-between">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">
                  Company logo
                </label>
              </div>


              <div className="columns-input">
                <div className="flex flex-row">
                  <span className="h-16 w-16 bg-gray-100 rounded-full flex justify-center items-center mr-5 avtar_img_round">
                    {urlImage ? (
                      <img className="w-16 h-16 rounded-full" src={urlImage} alt="" />
                    ) : (
                      <img src={imagePlus} alt="" />
                    )}
                  </span>
                  <div className="flex items-center justify-center  flex-1 upload-img ">
                    <div className="flex items-center justify-center  flex-1 upload-img ">
                      <label
                        htmlFor="dropzone-file"
                        className="flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white"
                      >
                        <div
                          className="flex items-center justify-center  flex-1 upload-img ml-8"
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={onDropHandler}
                        >
                          <div className="flex flex-col items-center justify-center">
                            <p className="mb-2 text-xs text-gray-600 darks:text-gray-400">
                              <span className="font-semibold text-blue-700">
                                Click to upload
                              </span>
                              <span className="text-xs"> or drag and drop</span>
                            </p>
                            <p className="text-xs text-gray-600 darks:text-gray-400 font-inter">
                              SVG, PNG, JPG or GIF (max. 800x400px)
                            </p>
                          </div>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          className="hidden"
                          onChange={(e) => inputChangeHandlerFile(e.target.files[0])}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {formErrors?.existClient && (
              <p className="text-xs text-red-600 mt-1 pt-8">
                {formErrors?.existClient}
              </p>
            )}
          </div>

        ) : (
          // Step 1: Client Address Information
          <div id={secondStep}>
            <div className="flex justify-between">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">
                  Business Address
                </label>
              </div>

              <div className="columns-input">
                <div className="mb-5">
                  <input
                    type="text"
                    placeholder="Street Address*"
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    value={formData?.businessAddres1}
                    onChange={(e) => inputchangehandler(e, "businessAddres1")}
                  />
                  {formErrors?.businessAddres1 && (
                    <p className="text-xs text-red-600 mt-1">
                      {formErrors?.businessAddres1}
                    </p>

                  )}
                </div>
                <div className="flex">
                  <div className="flex-1 pr-1  mb-5">
                    <input
                      type="text"
                      placeholder="Apartment, suite, etc "
                      className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      value={formData?.businessAddres2}
                      onChange={(e) =>
                        inputchangehandler(e, "businessAddres2")
                      }
                    />
                  </div>

                  <div className="flex-1 pl-1  mb-5">
                    <input
                      type="text"
                      placeholder="Suburb*"
                      className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      value={formData?.businessAddresCity}
                      onChange={(e) =>
                        inputchangehandler(e, "businessAddresCity")
                      }
                    />
                    {formErrors?.businessAddresCity && (
                      <p className="text-xs text-red-600 mt-1">
                        {formErrors?.businessAddresCity}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 pr-1  mb-5">
                    <select
                      value={formData?.businessAddresCountry}
                      onChange={(e) =>
                        inputchangehandler(e, "businessAddresCountry")
                      }
                      className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option>Country</option>
                      {countries &&
                        countries.map((country) => (
                          <option key={country.value} value={country.value}>
                            {country.label}
                          </option>
                        ))}
                    </select>
                    {formErrors?.businessAddresCountry && (
                      <p className="text-xs text-red-600 mt-1">
                        {formErrors?.businessAddresCountry}
                      </p>
                    )}
                  </div>

                  <div className="flex-1 pl-1  mb-5">
                    <input
                      type="number"
                      placeholder="Postcode*"
                      className=" input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      value={formData?.businessAddresPostCode}
                      onChange={(e) => {
                        if (e?.target?.value?.length <= 10) {
                          inputchangehandler(e, "businessAddresPostCode");
                        }
                      }}
                    // maxLength is not needed for number input
                    />

                    {formErrors?.businessAddresPostCode && (
                      <p className="text-xs text-red-600 mt-1">
                        {formErrors?.businessAddresPostCode}
                      </p>
                    )}
                  </div>
                </div>
                <div className=" mb-5">
                  <select
                    value={formData?.businessAddresState}
                    onChange={(e) =>
                      inputchangehandler(e, "businessAddresState")
                    }
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="">State*</option>
                    {statesOne &&
                      statesOne.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                  </select>
                  {formErrors?.businessAddresState && (
                    <p className="text-xs text-red-600 mt-1">
                      {formErrors?.businessAddresState}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <hr className="mt-4 mb-4" />
            <div className="mb-5">
              <input
                className="checkbox"
                style={{
                  appearance: "auto",
                  color: "blue",
                  borderRadius: "30%",
                }}
                type="checkbox"
                value={sameAddress ? sameAddress : false}
                checked={sameAddress ? sameAddress : false}
                onChange={() => setSameAddress(!sameAddress)}
              />
              <label className="text-gray-700 font-medium">
                Same as Business Address
              </label>
            </div>

            <div className="flex justify-between">
              <div className="lavel-wd">
                <label className="text-gray-700 font-medium">
                  Mailing Address
                </label>
              </div>

              <div className="columns-input">
                <div className="mb-5">
                  <input
                    type="text"
                    placeholder="Street Address*"
                    className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    value={!sameAddress ? formData?.mailingAddres1 : formData?.businessAddres1}
                    onChange={(e) => inputchangehandler(e, "mailingAddres1")}
                    readOnly={sameAddress ? true : false}
                  />
                  {formErrors?.mailingAddres1 && (

                    <p className="text-xs text-red-600 mt-1">
                      {formErrors?.mailingAddres1}
                    </p>
                  )}
                </div>
                <div className="flex">
                  <div className="flex-1 pr-1  mb-5">
                    <input
                      type="text"
                      placeholder="Apartment, suite, etc "
                      className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      value={!sameAddress ? formData?.mailingAddres2 : formData?.businessAddres2}
                      onChange={(e) =>
                        inputchangehandler(e, "mailingAddres2")
                      }
                      readOnly={sameAddress ? true : false}
                    />
                  </div>


                  <div className="flex-1 pl-1  mb-5">
                    <input
                      type="text"
                      placeholder="Suburb*"
                      className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      value={!sameAddress ? formData?.mailingAddresCity : formData?.businessAddresCity}
                      onChange={(e) =>
                        inputchangehandler(e, "mailingAddresCity")
                      }
                      readOnly={sameAddress ? true : false}
                    />
                    {formErrors?.mailingAddresCity && (
                      <p className="text-xs text-red-600 mt-1">
                        {formErrors?.mailingAddresCity}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex">

                  <div className="flex-1 pr-1  mb-5">
                    {sameAddress ?
                      <input
                        type="text"
                        className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                        value={businessCountryName} />
                      :
                      <select
                        // readOnly={sameAddress ? true : false}
                        value={formData?.mailingAddresCountry}
                        onChange={(e) =>
                          inputchangehandler(e, "mailingAddresCountry")
                        }
                        className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      >
                        <option>Country</option>
                        {!sameAddress &&
                          countries &&
                          countries.map((country) => (
                            <option key={country?.value} value={country?.value}>
                              {country?.label}
                            </option>
                          ))}
                      </select>
                    }


                    {formErrors?.mailingAddresCountry && (
                      <p className="text-xs text-red-600 mt-1">
                        {formErrors?.mailingAddresCountry}
                      </p>
                    )}
                  </div>

                  <div className="flex-1 pl-1  mb-5">
                    <input
                      type="number"
                      placeholder="Postcode*"
                      className=" input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                      value={!sameAddress ? formData?.mailingAddresPostCode : formData?.businessAddresPostCode}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          inputchangehandler(e, "mailingAddresPostCode");
                        }
                      }}
                      readOnly={sameAddress ? true : false}
                    />

                    {formErrors?.mailingAddresPostCode && (
                      <p className="text-xs text-red-600 mt-1">
                        {formErrors?.mailingAddresPostCode}
                      </p>
                    )}
                  </div>
                </div>
                {sameAddress ?
                  <input
                    type="text"
                    className=" input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    value={businessStateName}
                    readOnly />
                  :
                  <select
                    // readOnly={sameAddress ? true : false}
                    value={formData?.mailingAddresState}
                    onChange={(e) =>
                      inputchangehandler(e, "mailingAddresState")
                    }
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option >State*</option>
                    {!sameAddress &&
                      statesTwo &&
                      statesTwo.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                  </select>}
                {formErrors?.mailingAddresState && (
                  <p className="text-xs text-red-600 mt-1">
                    {formErrors?.mailingAddresState}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex dailog-footer ">
        {step !== 0 ? (
          <BtnLight
            className="ml-1.5"
            onClick={onPrevious}
            variant="solid"
            disabled={step === 0 || props?.buttonDisabled}
          >
            <span> Previous </span>
          </BtnLight>
        ) : (
          <BtnLight
            className="ml-1.5"
            onClick={props.onDialogClose}
            variant="solid"
            disabled={step === 1}
          >
            Cancel
          </BtnLight>
        )}

        {step === 0 ? (
          <BtnPrimary className="ml-1.5" onClick={onNext} variant="solid">
            <span className="flex justify-center">
              Next <img src={imageArrow} alt="" className="ml-2" />
            </span>
          </BtnPrimary>
        ) : (
          <BtnPrimary
            disabled={props?.buttonDisabled}
            className="ml-1.5"
            onClick={onClickCreateClient}
            variant="solid"
          >
            {props?.buttonDisabled ? <ThreeDotLoader /> : "Create client"}
          </BtnPrimary>
        )}
      </div>

      <Dialog
        isOpen={clientAddedDialog}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.894531" width="48" height="48" rx="24" fill="#D1FADF" />
              <path d="M24.8945 27.5H20.3945C18.999 27.5 18.3012 27.5 17.7334 27.6722C16.455 28.06 15.4546 29.0605 15.0668 30.3389C14.8945 30.9067 14.8945 31.6044 14.8945 33M31.8945 33V27M28.8945 30H34.8945M27.3945 19.5C27.3945 21.9853 25.3798 24 22.8945 24C20.4092 24 18.3945 21.9853 18.3945 19.5C18.3945 17.0147 20.4092 15 22.8945 15C25.3798 15 27.3945 17.0147 27.3945 19.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Client created successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onAddDialogClose}>Close</BtnLight>
        </div>
      </Dialog>
      {/* </form> */}
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    loader: userActions.AddClient.loader,
  };
}
const mapDispatchToProps = {
  onAddClient: userActions.AddClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClientForm);