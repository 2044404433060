// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const specificWorker = createSlice({
  name:"specificWorker",
  initialState:"",
  reducers:{
    AddspecificWorkerData(state,action){
      return (action.payload)
    },
    ResetSpecificWorker(){
      return ""
    },
  }
}) 
export const {AddspecificWorkerData,ResetSpecificWorker}=specificWorker.actions;
export default specificWorker.reducer;