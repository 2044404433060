import React, { useState } from "react";
    import Card from "../../../../../../common/element/Card";
    import BtnLight from "../../../../../../common/element/Buttons/btnLight";
    import { Link } from "react-router-dom";

    const ClientInformation = (props) => {
        const [isEditMode, setEditMode] = useState(false);
    let address =
        props?.clientData?.org_address_data &&
        props?.clientData?.org_address_data[0]?.address_one +
        " " +
        props?.clientData?.org_address_data[0]?.address_two +
        " " +
        props?.clientData?.org_address_data[0]?.suburb +
        " " +
        props?.clientData?.org_address_data[0]?.zipcode;
    let state =
        props?.clientData?.org_address_data &&
        props?.clientData?.org_address_data[0]?.state_name;
    let country =
        props?.clientData?.org_address_data &&
        props?.clientData?.org_address_data[0]?.country_name;

        const handleEditClick = () => {
            setEditMode(true);
          };

    return (
        <Card className="p-5 border border-gray-200 rounded-xl dark-shadow">
        <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-5">
            <span className="text-gray-900 text-base font-semibold flex items-center">
            Client Information
            </span>                         
            <BtnLight className="h-9 w-20 flex items-center justify-center gap-2" onClick={props?.openAddDialog}>
            {/* <BtnLight className="h-9 w-20 flex items-center justify-center gap-2" > */}
            Edit
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                d="M14.9999 8.33326L11.6666 4.99993M2.08325 17.9166L4.90356 17.6032C5.24813 17.5649 5.42042 17.5458 5.58146 17.4937C5.72433 17.4474 5.86029 17.3821 5.98566 17.2994C6.12696 17.2062 6.24954 17.0836 6.49469 16.8385L17.4999 5.83326C18.4204 4.91279 18.4204 3.4204 17.4999 2.49993C16.5795 1.57945 15.0871 1.57945 14.1666 2.49992L3.16136 13.5052C2.91621 13.7503 2.79363 13.8729 2.70045 14.0142C2.61778 14.1396 2.55243 14.2755 2.50618 14.4184C2.45405 14.5794 2.43491 14.7517 2.39662 15.0963L2.08325 17.9166Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                />
            </svg>
            </BtnLight>
        </div>
        <div className="flex mb-4">
            <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
                Business name
            </span>
            <span className="text-gray-900 font-medium block text-sm">
                {props?.clientData?.trading_name}
            </span>
            </div>
        </div>
        <div className="flex mb-4">
            <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
                ABN/AZBN
            </span>
            <span className="text-gray-900 font-medium block text-sm">
                {props?.clientData?.abn_nzbn}
            </span>
            </div>
            <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
                ACN
            </span>
            <span className="text-gray-900 font-medium block text-sm">
                {props?.clientData?.acn}
            </span>
            </div>
        </div>
        <div className="flex mb-4">
            <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
                Website
            </span>
            <span className="text-gray-900 font-medium flex gap-1 text-sm">
                {props?.clientData?.website_url}{" "}
                <svg
                width="16"
                height="15"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                </svg>
            </span>
            </div>
        </div>
        <div className="flex mb-4">
            <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
                Business address
            </span>
            <span className="text-gray-900 font-medium flex gap-1 text-sm">
                {address}
            </span>
            </div>
        </div>
        <div className="flex mb-0">
            <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
                Country
            </span>
            <span className="text-gray-900 font-medium block text-sm">
                {country}
            </span>
            </div>
            <div className="flex-1">
            <span className="text-gray-400 font-semibold block text-sm mb-1">
                State
            </span>
            <span className="text-gray-900 font-medium block text-sm">
                {state}
            </span>
            </div>
        </div>
        </Card>
    );
    };
    export default ClientInformation;
