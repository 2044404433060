import React, { useEffect, useState } from "react";
import RichTextEditor from "../../../../../../../../common/snippet/RichTextEditor";
import { UpdateSiteInductionModuleData } from "../../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import { useDispatch, useSelector } from "react-redux";
import { UpdateInductionStatusSite } from "../../../../../../../../redux/classes/Admin-Client/updatInductionStatusSite";
const AddedText = (props) => {
  const { SiteInductionModuleData } = useSelector((state) => state);
  const [Pdfdata, Setpdfdata] = useState(SiteInductionModuleData[props.indexData]);
  const dispatch = useDispatch();
  const handleFileChange = (e) => {
    dispatch(UpdateInductionStatusSite(true))
    if (Pdfdata.content_info !== e) {
      Setpdfdata({ ...Pdfdata, content_info_type: "text", content_info: e });
    }
  };
  useEffect(() => {
    dispatch(
      UpdateSiteInductionModuleData({
        indexData: props.indexData,
        moduleData: Pdfdata,
      })
    );
  }, [Pdfdata]);
  return (
    <React.Fragment>
      <RichTextEditor
        onChange={handleFileChange}
        value={Pdfdata.content_info}
        placeholder="Enter text for module..."
      />
    </React.Fragment>
  );
};
export default AddedText;