// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const updateAvtar = createSlice({
  name:"UpdateAvtar",
  initialState:false,
  reducers:{
    updateAvtarfunc(state,action){
      return action.payload;
    },
  }
}) 
export default updateAvtar.reducer;
export const {updateAvtarfunc}=updateAvtar.actions;