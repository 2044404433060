import React, { useState, useCallback, useEffect, useRef } from "react";
import Heading from "../../../../../common/element/Heading";
import Segment from "../../../../../common/element/Segment";
import Table from "../../../../../common/element/Table";
import Badge from "../../../../../common/element/Badge";
import Tag from "../../../../../common/element/Tag";
import Scrollbar from "../../../../../common/element/ScrollBar";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import ConnectClient from "../../../../providerPortal/pages/Registration/ConnectClient";
import Dialog from "../../../../../common/element/Dialog";
import Progress from "../../../../../common/element/Progress/Progress";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { UpdateCheckExistUser } from "../../../../../redux/classes/provider-portal/checkExistUser";
import moment from "moment";
import { useParams, Link } from "react-router-dom";
import SearchImg from "../../../../../assets/icons-svg/search-lg.svg";
import ClientInput from "../../../../../common/element/Input/clientInput";
import useCallbackRef from "../../../../../common/element/hooks/useCallbackRef";
import Tooltip from "../../../../../common/element/Tooltip";
import { debounce } from "../../../../../common/snippet/Debouncing";
import { GetAllDeshboardTasks, MarkTaskAsRead, UpdateTaskArchiveStatus } from "../../../../../actions/Admin";

const Tasks = () => {
    const { id } = useParams();
    const dispatch = useDispatch()
    const handleUnauthorizedAccess = useUnauthorizedHandler()

    const { Tr, Th, Td, THead, TBody } = Table;
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
    const userId = loginData?.userName?.user_uuid || loginData?.data?.user_uuid;
    const userRole = "worker";
    const individual_uuid = loginData?.userName?.individual_uuid
    const [totalTastCount, setTotalTaskCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const scrollContainerRef = useRef(null);
    const [scrollThreshold, setScrollThreshold] = useState(23)
    const [limit, setLimit] = useState(10)
    const [searchTerm, setSearchTerm] = useState("")
    const [notificationList, setNotificationList] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [singleSegmentValue, setSingleSegmentValue] = useState(["Inbox"]);

    const onSingleSelectionSegmentChange = useCallback(
        (val) => {
            setSingleSegmentValue(val)
        },
        [setSingleSegmentValue])

    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'day').startOf('day');
    const startOfWeek = moment().startOf('week');
    const startOfMonth = moment().startOf('month');
    const startOfYear = moment().startOf('year');
    const groupedNotifications = notificationList?.reduce(
        (acc, notification) => {
            const createdDate = moment(notification?.created_date);

            if (createdDate.isSame(today, 'd')) {
                acc.today.push(notification);
            } else if (createdDate.isSame(yesterday, 'd')) {
                acc.yesterday.push(notification);
            } else if (createdDate.isSameOrAfter(startOfWeek)) {
                acc.thisWeek.push(notification);
            } else if (createdDate.isSameOrAfter(startOfMonth)) {
                acc.thisMonth.push(notification);
            } else if (createdDate.isSameOrAfter(startOfYear)) {
                acc.thisYear.push(notification);
            } else {
                acc.earlier.push(notification);
            }
            return acc;
        },
        { today: [], yesterday: [], thisWeek: [], thisMonth: [], thisYear: [], earlier: [] }
    );

    useEffect(() => {
        const handleScroll = () => {
            const scrollContainer = scrollContainerRef.current;
            if (
                scrollContainer &&
                scrollContainer.scrollTop >= scrollThreshold &&
                !isFetching &&
                notificationList?.length < totalCount
            ) {
                setLimit((prevLimit) => prevLimit + 10);
                setScrollThreshold((prevThreshold) => prevThreshold + 150);
            }
        };
        const scrollContainer = scrollContainerRef.current;
        scrollContainer?.addEventListener('scroll', handleScroll);

        return () => {
            scrollContainer?.removeEventListener('scroll', handleScroll);
        };
    }, [isFetching, scrollThreshold, totalCount, notificationList]);
    const GetTasksNotifications = useCallback(() => {
        // setIsFetching(true);
        const payload = { limit: limit, role_name: userRole, userUuid: userId, is_archived: singleSegmentValue[0] == "Inbox" ? false : true, individual_uuid: individual_uuid, search: searchTerm };
        dispatch(GetAllDeshboardTasks(payload)).then((res) => {
            // setIsFetching(false);
            if (res?.success === true) {
                const arrayData = res?.data?.rows
                setNotificationList(arrayData);
                setTotalTaskCount(res?.data?.unreadCount)
                setTotalCount(res?.data?.count)

            }
        });

    }, [dispatch, singleSegmentValue, searchTerm, limit]);
    useEffect(() => {
        GetTasksNotifications()
    }, [singleSegmentValue, searchTerm, limit])

    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    };
    const inputchangehandler = useCallbackRef(debounce(handleSearch), []);

    const archiveTask = (notify) => {
        const payload = { role_name: userRole, notification_uuid: notify.notification_uuid, is_archived: true, userUuid: userId }
        dispatch(UpdateTaskArchiveStatus(payload)).then((res) => {
            if (res?.success === true) {
                GetTasksNotifications();
            } else if (res?.status === 401) {
                handleUnauthorizedAccess();
            } else {
                // Handle other errors
            }
        });
    }

    const onMarkAsRead = (notifyId) => {
        const payload = { role_name: userRole, notification_uuid: notifyId, userUuid: userId, task_read: true };
        dispatch(MarkTaskAsRead(payload)).then((res) => {
            if (res?.success === true) {
                setTotalTaskCount(totalTastCount - 1); // Fixed typo: 'totalTastCount' -> 'totalTaskCount'
                setNotificationList((prevList) => {
                    return prevList.map((item) => {
                        if (item?.notification_uuid === notifyId) {
                            return { ...item, task_read: true };
                        }
                        return item;
                    });
                });
            } else if (res?.status === 401) {
                handleUnauthorizedAccess();
            } else {
                // Handle other errors
            }
        });
    };

    const { checkExistUser } = useSelector((state) => state);
    // checkExistUser.checkAcceptUser

    useEffect(() => {
        if (checkExistUser.checkAcceptUser) {
            dispatch(
                UpdateCheckExistUser({
                    checkUser: false,
                    path: "",
                    login: false,
                    checkAcceptUser: false,
                })
            );
            setDialogIsOpen(true);
        }
    }, [checkExistUser]);

    const onDialogClose = () => {
        setDialogIsOpen(false);
    };
    return (
        <React.Fragment>
            <div className="bg-white py-6 rounded-xl dark-shadow">
                <div className="card-header flex justify-between border-b border-gray-200 pb-5 px-6 mb-3">
                    <span className="text-gray-900 text-base font-semibold flex items-center">
                        <span className="mr-4">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M6 15L8 17L12.5 12.5M8 8V5.2C8 4.0799 8 3.51984 8.21799 3.09202C8.40973 2.71569 8.71569 2.40973 9.09202 2.21799C9.51984 2 10.0799 2 11.2 2H18.8C19.9201 2 20.4802 2 20.908 2.21799C21.2843 2.40973 21.5903 2.71569 21.782 3.09202C22 3.51984 22 4.0799 22 5.2V12.8C22 13.9201 22 14.4802 21.782 14.908C21.5903 15.2843 21.2843 15.5903 20.908 15.782C20.4802 16 19.9201 16 18.8 16H16M5.2 22H12.8C13.9201 22 14.4802 22 14.908 21.782C15.2843 21.5903 15.5903 21.2843 15.782 20.908C16 20.4802 16 19.9201 16 18.8V11.2C16 10.0799 16 9.51984 15.782 9.09202C15.5903 8.71569 15.2843 8.40973 14.908 8.21799C14.4802 8 13.9201 8 12.8 8H5.2C4.0799 8 3.51984 8 3.09202 8.21799C2.71569 8.40973 2.40973 8.71569 2.21799 9.09202C2 9.51984 2 10.0799 2 11.2V18.8C2 19.9201 2 20.4802 2.21799 20.908C2.40973 21.2843 2.71569 21.5903 3.09202 21.782C3.51984 22 4.07989 22 5.2 22Z"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </span>
                        Task <Tag className="text-blue-800 bg-blue-100 ml-2">{totalTastCount}</Tag>
                    </span>
                    {/* <BtnLight className="w-20 h-9">View All</BtnLight> */}
                </div>
                <div className="flex justify-between mb-3 px-4">
                    <Segment
                        className="docType-btn"
                        value={singleSegmentValue[0] == "Inbox" ? "Inbox" : "Archive"}
                        onChange={(val) => onSingleSelectionSegmentChange(val)}
                    >
                        <Segment.Item value="Inbox">Inbox</Segment.Item>
                        <Segment.Item value="Archive">Archive</Segment.Item>
                    </Segment>
                    <div className="w-80 h-10">
                        <div className="input-wrapper">
                            <div className="input-suffix-start left-3.5">
                                <img src={SearchImg} alt="" className="h-5" />
                            </div>
                            <ClientInput
                                type="text"
                                className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Search"
                                inputchangehandler={inputchangehandler}
                                name={"search"}
                            />
                        </div>
                    </div>
                </div>
                <div ref={scrollContainerRef} className="notification-list-container overflow-y-auto h-[400px]">
                    <Table className="min-w-full">
                        <THead>
                            <Tr>
                                <Th className="pl-6 w-1/2	">Notification</Th>
                                <Th>Timestamp</Th>
                                <Th className="w-84">Company</Th>
                                {singleSegmentValue[0] == "Inbox" &&
                                    <Th className="">Action</Th>
                                }

                            </Tr>
                        </THead>
                        <tbody className={`overflow-y-auto notification-list `}>
                            {loading ? (
                                <tr>
                                    <td colSpan="4" className="text-center p-4">Loading...</td>
                                </tr>
                            ) : notificationList.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className="text-center p-4">No Data Available!</td>
                                </tr>
                            ) : (
                                <>
                                    {groupedNotifications.today.length > 0 && (
                                        <>
                                            <tr>
                                                <td colSpan="4" className="text-blue-700 font-bold px-6 py-2 mt-2">Today</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">
                                                    <ul className="border-b pb-2">
                                                        {groupedNotifications.today.map((notif) => (
                                                            <li key={notif.notification_uuid} className="flex justify-between py-1.5 px-6 hover:bg-blue-50">
                                                                <Link
                                                                    className={`flex gap-2 ${notif?.task_read ? '' : 'font-bold'}`}
                                                                    to="#"
                                                                    onClick={() => !notif?.task_read && onMarkAsRead(notif.notification_uuid)}
                                                                >
                                                                    {notif?.task_read === true ? (
                                                                        <div className="" width="10" height="10">

                                                                        </div>
                                                                    ) : (
                                                                        <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                                                        </svg>
                                                                    )}


                                                                    <Tooltip title={notif.message}>
                                                                        <span className="text-gray-700 w-72 noti-newwrap"> {notif.message}</span>
                                                                    </Tooltip>
                                                                </Link>
                                                                <span className=" pl-2">
                                                                    {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                                                </span>
                                                                <Tooltip title={notif.trading_name}>
                                                                    <span className="w-32 noti-newwrap cursor-pointer">
                                                                        {notif?.trading_name ? notif.trading_name : "-"}
                                                                    </span>
                                                                </Tooltip>
                                                                {singleSegmentValue[0] == "Inbox" &&
                                                                    <span className={`text-blue-700 font-semibold cursor-pointer`} onClick={() => { archiveTask(notif) }}>
                                                                        {notif?.action ? notif.action : "Archive"}
                                                                    </span>
                                                                }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {groupedNotifications.yesterday.length > 0 && (
                                        <>
                                            <tr>
                                                <td colSpan="4" className="text-blue-700 font-bold px-6 py-2 mt-2">Yesterday</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">
                                                    <ul className="border-b pb-2">
                                                        {groupedNotifications.yesterday.map((notif) => (
                                                            <li key={notif.notification_uuid} className="flex justify-between py-1.5 px-6 hover:bg-blue-50">
                                                                <Link
                                                                    className={`flex gap-2 ${notif?.task_read ? '' : 'font-bold'}`}
                                                                    to="#"
                                                                    onClick={() => !notif?.task_read && onMarkAsRead(notif.notification_uuid)}
                                                                >
                                                                    {notif?.task_read === true ? (
                                                                        <div className="" width="10" height="10">

                                                                        </div>
                                                                    ) : (
                                                                        <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                                                        </svg>
                                                                    )}
                                                                    <Tooltip title={notif.message}>
                                                                        <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                                                    </Tooltip>
                                                                </Link>
                                                                <span className=" pl-2">
                                                                    {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                                                </span>
                                                                <Tooltip title={notif.trading_name}>
                                                                    <span className="w-32 noti-newwrap cursor-pointer">
                                                                        {notif?.trading_name ? notif.trading_name : "-"}
                                                                    </span>
                                                                </Tooltip>
                                                                {singleSegmentValue[0] == "Inbox" &&
                                                                    <span className={`text-blue-700 font-semibold cursor-pointer`} onClick={() => { archiveTask(notif) }}>
                                                                        {notif?.action ? notif.action : "Archive"}
                                                                    </span>
                                                                }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {groupedNotifications.thisWeek.length > 0 && (
                                        <>
                                            <tr>
                                                <td colSpan="4" className="text-blue-700 font-bold px-6 py-2 mt-2">This week</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">
                                                    <ul className="border-b pb-2">
                                                        {groupedNotifications.thisWeek.map((notif) => (
                                                            <li key={notif.notification_uuid} className="flex justify-between py-1.5 px-6 hover:bg-blue-50">
                                                                <Link
                                                                    className={`flex gap-2 ${notif?.task_read ? '' : 'font-bold'}`}
                                                                    to="#"
                                                                    onClick={() => !notif?.task_read && onMarkAsRead(notif.notification_uuid)}
                                                                >
                                                                    {notif?.task_read === true ? (
                                                                        <div className="" width="10" height="10">

                                                                        </div>
                                                                    ) : (
                                                                        <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                                                        </svg>
                                                                    )}
                                                                    <Tooltip title={notif.message}>
                                                                        <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                                                    </Tooltip>
                                                                </Link>
                                                                <span className=" pl-2">
                                                                    {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                                                </span>
                                                                <Tooltip title={notif.trading_name}>
                                                                    <span className="w-32 noti-newwrap cursor-pointer">
                                                                        {notif?.trading_name ? notif.trading_name : "-"}
                                                                    </span>
                                                                </Tooltip>
                                                                {singleSegmentValue[0] == "Inbox" &&
                                                                    <span className={`text-blue-700 font-semibold cursor-pointer`} onClick={() => { archiveTask(notif) }}>
                                                                        {notif?.action ? notif.action : "Archive"}
                                                                    </span>
                                                                }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {groupedNotifications.thisMonth.length > 0 && (
                                        <>
                                            <tr>
                                                <td colSpan="4" className="text-blue-700 font-bold px-6 py-2 mt-2">This month</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">
                                                    <ul className="border-b pb-2">
                                                        {groupedNotifications.thisMonth.map((notif) => (
                                                            <li key={notif.notification_uuid} className="flex justify-between py-1.5 px-6 hover:bg-blue-50">
                                                                <Link
                                                                    className={`flex gap-2 ${notif?.task_read ? '' : 'font-bold'}`}
                                                                    to="#"
                                                                    onClick={() => !notif?.task_read && onMarkAsRead(notif.notification_uuid)}
                                                                >
                                                                    {notif?.task_read === true ? (
                                                                        <div className="" width="10" height="10">

                                                                        </div>
                                                                    ) : (
                                                                        <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                                                        </svg>
                                                                    )}
                                                                    <Tooltip title={notif.message}>
                                                                        <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                                                    </Tooltip>
                                                                </Link>
                                                                <span className=" pl-2">
                                                                    {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                                                </span>
                                                                <Tooltip title={notif.trading_name}>
                                                                    <span className="w-32 noti-newwrap cursor-pointer">
                                                                        {notif?.trading_name ? notif.trading_name : "-"}
                                                                    </span>
                                                                </Tooltip>
                                                                {singleSegmentValue[0] == "Inbox" &&
                                                                    <span className={`text-blue-700 font-semibold cursor-pointer`} onClick={() => { archiveTask(notif) }}>
                                                                        {notif?.action ? notif.action : "Archive"}
                                                                    </span>
                                                                }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {groupedNotifications.thisYear.length > 0 && (
                                        <>
                                            <tr>
                                                <td colSpan="4" className="text-blue-700 font-bold px-6 py-2 mt-2">This year</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4">
                                                    <ul className="border-b pb-2">
                                                        {groupedNotifications.thisYear.map((notif) => (
                                                            <li key={notif.notification_uuid} className="flex justify-between py-1.5 px-6 hover:bg-blue-50">
                                                                <Link
                                                                    className={`flex gap-2 ${notif?.task_read ? '' : 'font-bold'}`}
                                                                    to="#"
                                                                    onClick={() => !notif?.task_read && onMarkAsRead(notif.notification_uuid)}
                                                                >
                                                                    {notif?.task_read === true ? (
                                                                        <div className="" width="10" height="10">

                                                                        </div>
                                                                    ) : (
                                                                        <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                                                        </svg>
                                                                    )}
                                                                    <Tooltip title={notif.message}>
                                                                        <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                                                    </Tooltip>
                                                                </Link>
                                                                <span className=" pl-2">
                                                                    {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                                                </span>
                                                                <Tooltip title={notif.trading_name}>
                                                                    <span className="w-32 noti-newwrap cursor-pointer">
                                                                        {notif?.trading_name ? notif.trading_name : "-"}
                                                                    </span>
                                                                </Tooltip>
                                                                {singleSegmentValue[0] == "Inbox" &&
                                                                    <span className={`text-blue-700 font-semibold cursor-pointer`} onClick={() => { archiveTask(notif) }}>
                                                                        {notif?.action ? notif.action : "Archive"}
                                                                    </span>
                                                                }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </>
                            )}
                        </tbody>
                        {isFetching && (
                            <div className="flex justify-center py-4">
                                <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-blue-600"></div>
                            </div>
                        )}
                    </Table>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Tasks