import React from 'react';

const BtnPrimaryLight = (props) => {
    const { disabled, className, type, onClick, children } = props;
    const classes = "btn border border-blue-100 block w-full rounded-lg h-10 bg-blue-50 text-blue-700 text-sm font-semibold hover:bg-blue-100 hover:border-blue-100 focus:bg-blue-100  duration-100 primary-btn " + className;
    return (
        <button 
            className={classes} 
            type={type || "button"} 
            onClick={onClick}
            disabled={disabled} // Add disabled attribute conditionally
        >
            {children}
        </button>
    );
}

export default BtnPrimaryLight;
