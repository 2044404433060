import React from "react";
import Heading from "../../../../../common/element/Heading";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
const SiteName = (props) => {
  const clientData = JSON.parse(localStorage.getItem('LoginUserData'))
  let logo;
  let trading_name;
  if (clientData) {
    logo = clientData?.userName?.avatar;
    trading_name = clientData?.trading_name;
  }
  // avatar
  return (
    <React.Fragment>
      <div className="flex gap-4 flex-row justify-between">

        <div className="flex items-center mb-5">
          <div className="h-16 w-16 rounded-full overflow-hidden mr-5 avtar_img_round">
            <img
              src={logo ? logo : "/assets/img/avatars/thumb-8.jpg"}
              alt="" />
          </div>
          <div className="flex flex-col">
            <Heading className="mb-1">{props?.siteName}</Heading>
            <div className="block">
              <span className="text-gray-600 text-base  font-medium">{trading_name ? trading_name : "-"}</span></div>
          </div>
        </div>
        {/* <div className="flex justify-between mb-5 px-2">
                    <BtnLight className=" w-24 text-xs text-gray-700 font-semibold flex  items-center justify-center" type="button"><img src="/assets/icons-svg/edit.svg" alt="" className="mr-2"/>Notes</BtnLight>
                    </div> */}

      </div>
    </React.Fragment>
  )
}
export default SiteName