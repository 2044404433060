import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import ContractorDocuments from "../pages/Documents";
import ContractorClients from "../pages/Clients";
import ContractorWorkers from "../pages/Workers";
import ContractorInvoices from "../pages/Invoices";
import ContractorContacts from "../pages/Contacts";
import ContractorSupport from "../pages/Support";
import ProviderPortalUsers from "../pages/Users";
import ProviderProtalReporting from "../pages/Reporting";
import ContractorPortalSettings from "../pages/Settings";
import Messages from "../pages/Messages";
import Notes from "../pages/Notes/Notes";
export const ProviderPortalRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="messages" element={<Messages/>}/>
        <Route path="users" element={<ProviderPortalUsers/>} />
        <Route
          path="notes"
          element={<Notes />}
        />
        <Route path="documents" element={<ContractorDocuments />} />
        <Route path="clients" element={<ContractorClients />} />
        <Route path="reporting" element={<ProviderProtalReporting />} />
        <Route path="workers" element={<ContractorWorkers />} />
        <Route path="specific-workers" element={<ContractorWorkers />} />
        <Route path="invoices" element={<ContractorInvoices />} />
        <Route path="contacts" element={<ContractorContacts />} />
        <Route path="support" element={<ContractorSupport />} />
        <Route path="settings" element={<ContractorPortalSettings/>}/>
      </Routes>
    </React.Fragment>
  );
};
