import React from "react";
import Heading from "../../../../../common/element/Heading";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import BtnPrimaryLight from "../../../../../common/element/Buttons/primaryLightBtn";
import Filter from "./Filter";
import InvoiceList from "./InvoiceList"
import { Link } from "react-router-dom";
const Invoice = () => {
  return (
    <React.Fragment>
      <div className="flex items-center justify-between mb-7 gap-3 flex-wrap">
        <Heading>Invoice</Heading>
        <div className="flex flex-wrap gap-3">
          <BtnPrimaryLight className="w-36 flex items-center justify-center text-xs">
            Download all
            <span className="ml-3">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.8936 11.4814V12.4814C15.8936 13.8816 15.8936 14.5816 15.6211 15.1164C15.3814 15.5868 14.9989 15.9693 14.5285 16.209C13.9938 16.4814 13.2937 16.4814 11.8936 16.4814H4.89355C3.49342 16.4814 2.79336 16.4814 2.25858 16.209C1.78817 15.9693 1.40572 15.5868 1.16604 15.1164C0.893555 14.5816 0.893555 13.8816 0.893555 12.4814V11.4814M12.5602 7.31478L8.39355 11.4814M8.39355 11.4814L4.22689 7.31478M8.39355 11.4814V1.48145" stroke="#5786CC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
          </BtnPrimaryLight>
          <BtnPrimary className="w-36 flex items-center justify-center text-xs">
            <Link to="new-invoice">
            Raise an invoice
            <span className="ml-3">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_7446_45784)">
                  <path d="M12.0599 2.87268V6.3148C12.0599 6.78151 12.0599 7.01486 12.1507 7.19312C12.2306 7.34992 12.3581 7.47741 12.5149 7.5573C12.6932 7.64813 12.9265 7.64813 13.3932 7.64813H16.8353M10.3932 15.9814V10.9814M7.89323 13.4814H12.8932M12.0599 2.64807H7.72656C6.32643 2.64807 5.62637 2.64807 5.09159 2.92056C4.62118 3.16024 4.23873 3.54269 3.99905 4.0131C3.72656 4.54787 3.72656 5.24794 3.72656 6.64807V15.3147C3.72656 16.7149 3.72656 17.4149 3.99905 17.9497C4.23873 18.4201 4.62118 18.8026 5.09159 19.0423C5.62637 19.3147 6.32643 19.3147 7.72656 19.3147H13.0599C14.46 19.3147 15.1601 19.3147 15.6949 19.0423C16.1653 18.8026 16.5477 18.4201 16.7874 17.9497C17.0599 17.4149 17.0599 16.7149 17.0599 15.3147V7.64807L12.0599 2.64807Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_7446_45784">
                  <rect width="20" height="20" fill="white" transform="translate(0.393555 0.981445)"/>
                  </clipPath>
                  </defs>
                  </svg>
            </span>
            </Link>
          </BtnPrimary>
        </div>
      </div>
    <Filter/>
    <InvoiceList/>
    </React.Fragment>
  );
};

export default Invoice;
