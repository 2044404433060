import React,{useState} from "react";
import ActiveUser from "./ActiveUser";
import InvitedUser from "./InvitedUser";

const UserList = (props) => {
  return (
    <React.Fragment>

     <ActiveUser allUser={props.allUser} permission_data={props.permission_data}/>
     <InvitedUser invitedUser={props.invitedUser} permission_data={props.permission_data} onInvitedUser={props.onInvitedUser}/>
    </React.Fragment>
  );
};

export default UserList;
