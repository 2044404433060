import axios from "axios";
import * as actionType from "../../constant/actionType";
    export const SubmitWorkerInvitation = (apiData) => async (dispatch) => {
    /*** Add CSV Action ***/
      try {
        let formdata = new FormData();
        for (let key in apiData) { 
          if (key === 'avatar') {
            if(apiData.avatar==""){
              formdata.append('avatar', "");
            }else{
              formdata.append('avatar', apiData.avatar[0]);
            }
          }
            else {
              // For other properties, append the value directly
                formdata.append(key, apiData[key]);
            }
        }
        const response = await axios({
          method: 'post',
          data: formdata,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
          },
          url: `${actionType.environment_url}/api/SubmitWorkerInvitation`,
        });
          return response.data
      } catch (error) {
        return error.message
      }
    }


    