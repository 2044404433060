// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const userSlice = createSlice({
  name:"user",
  initialState:[],
  reducers:{
    AllContactTypes(state,action){
      return (action.payload)
    }
  }
}) 
export default userSlice.reducer;
export const {AllContactTypes}=userSlice.actions;