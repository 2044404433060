import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const UpdateProviderDoc = (ApiData) => async (dispatch) => {
      try {
        let formdata = new FormData();
        for (let key in ApiData) { 
          if (key === 'column_names') {
            formdata.append(key, JSON.stringify(ApiData[key]));
          }
            else {
              // For other properties, append the value directly
                formdata.append(key, ApiData[key]);
            }
        }
        const response = await axios({
          method: 'put',
          data: formdata,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
          },
          url: `${actionType.environment_url}/api/provider/UpdateProviderDoc`,
        });
        return response.data
      } catch (error) {
        return error.message
      }
    }