import React from "react";
import Tabs from '../../../../../../../../common/element/Tabs';
import ClientDetails from "./ClientDetails";
import Users from "./User";
import Accreditations from "./Accreditation";
const ClientSettings = () =>{
    const { TabNav, TabList, TabContent } = Tabs
    return(
        <React.Fragment>
               <Tabs defaultValue="tab1" variant="pill">
               <div className='p-3'>
                    <div className = "bg-blue-25 border border-gray-100 p-1 rounded-lg">
                        <TabList className="flex">
                            <TabNav className="flex-1" value="tab1">Client Details</TabNav>
                            {/* <TabNav className="flex-1" value="tab2">Users</TabNav>
                            <TabNav className="flex-1" value="tab3">Accreditation</TabNav> */}
                        </TabList>
                    </div>
                    </div>
                    <div className="pl-6">
                    <TabContent value="tab1"> <ClientDetails/></TabContent>
                    {/* <TabContent value="tab2"><Users/> </TabContent>
                    <TabContent value="tab3"><Accreditations/></TabContent> */}
                    </div>
               </Tabs>
        </React.Fragment>
    )
}
export default ClientSettings