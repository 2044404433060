import React,{useState,useEffect} from "react";
import Heading from "../../../../../../common/element/Heading";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import AuditLogList from "./AuditLogList";
import searchIcon from "../../../../../../assets/icons-svg/search-lg.svg";
import { connect, useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
const AuditLog = () =>{
   const dispatch = useDispatch();
   useEffect(() => {
      dispatch(
        setBreadcrumbs([
          {
            label: "Audit Log",
            url: "/admin/audit-log",
            home: "/admin/dashboard",
          },
        ])
      );
    }, []);
    return(
        <React.Fragment>
                <div className="flex justify-between mb-5  flex-wrap gap-y-2.5">
         <Heading>Audit Log</Heading>
         <div className="flex gap-3 flex-wrap gap-y-2.5">
            <div className="w-80 h-10">
               <div className="input-wrapper">
                  <div className="input-suffix-start left-3.5">
                  <img src={searchIcon} alt="" className="h-5" />
                  </div>
                  <input type="text" name="hs-table-search" className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Search"/>
               </div>
            </div>
            
         
         </div>
      </div>
                <AuditLogList/>
               
        </React.Fragment>
    )
}
export default AuditLog