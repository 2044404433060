import React from 'react'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc';
import ImageFile from '../../../assets/icons-svg/image-01.svg';
import PdfFile from '../../../assets/icons-svg/upload-pdf.svg';
import filmIcon from '../../../assets/icons-svg/film.svg';


const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const FileItem = (props) => {
    const { file, children } = props
    const { type, name, size } = file

    const renderThumbnail = () => {
        const isImageFile = type.split('/')[0] === 'image'

        if (isImageFile) {
            return (
                <span className='w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3'> <img src={ImageFile}/></span>
               
            )
        }

        if (type === 'application/zip') {
            return (
                <FileIcon>
                    <span className='w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3'> <img src={PdfFile}/></span>
                </FileIcon>
            )
        }

        if (type === 'application/pdf') {
            return (
                <FileIcon>
                  <span className='w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3'> <img src={PdfFile}/></span>
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                 <span className='w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3'> <img src={filmIcon}/></span>
            </FileIcon>
        )
    }

    return (
        <div className="upload-file">
            <div className="flex">
                <div className="upload-file-thumbnail">{renderThumbnail()}</div>
                <div className="upload-file-info">
                    <h6 className="upload-file-name font-medium text-xs text-gray-700">{name}</h6>
                    <span className="upload-file-size text-xs text-gray-600">{getKB(size)} KB- 100% uploaded</span>
                </div>
            </div>
            {children}
        </div>
    )
}

export default FileItem
