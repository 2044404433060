import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Heading from "../../../../../common/element/Heading";
import Segment from "../../../../../common/element/Segment";
import Table from "../../../../../common/element/Table";
import SearchImg from "../../../../../assets/icons-svg/search-lg.svg";
import Badge from "../../../../../common/element/Badge";
import ClientInput from "../../../../../common/element/Input/clientInput";
import moment from "moment";
import useCallbackRef from "../../../../../common/element/hooks/useCallbackRef";
import Tooltip from "../../../../../common/element/Tooltip";
import { debounce } from "../../../../../common/snippet/Debouncing";
import { GetAllDeshboardTasks, UpdateTaskArchiveStatus, MarkTaskAsRead, GetNotificationTasksForAdminPortal } from "../../../../../actions/Admin";
import { useParams, Link } from "react-router-dom";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import Tag from "../../../../../common/element/Tag";
import Scrollbar from "../../../../../common/element/ScrollBar";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
const ContractorSectionDashboard = () => {
  const { Tr, Th, Td, THead, TBody } = Table
  const { id } = useParams();
  const dispatch = useDispatch()
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  const userId = loginData?.userName?.user_uuid || loginData?.data?.user_uuid;
  const userRole = loginData?.data?.role?.role_name;
  const [totalTastCount, setTotalTaskCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const scrollContainerRef = useRef(null);
  const [scrollThreshold, setScrollThreshold] = useState(23)
  const [limit, setLimit] = useState(10)
  const [searchTerm, setSearchTerm] = useState("")
  const [notificationList, setNotificationList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [singleSegmentValue, setSingleSegmentValue] = useState(["Inbox"]);
  useEffect(() => {
    dispatch(setBreadcrumbs([{ label: 'Dashboard', url: '/admin/provider/dashboard', home: "/admin/provider/dashboard" }]));
  }, []);

  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val)
    },
    [setSingleSegmentValue])

  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');
  const startOfWeek = moment().startOf('week');
  const startOfMonth = moment().startOf('month');
  const startOfYear = moment().startOf('year');
  const groupedNotifications = notificationList?.reduce(
    (acc, notification) => {
      const createdDate = moment(notification?.created_date);

      if (createdDate.isSame(today, 'd')) {
        acc.today.push(notification);
      } else if (createdDate.isSame(yesterday, 'd')) {
        acc.yesterday.push(notification);
      } else if (createdDate.isSameOrAfter(startOfWeek)) {
        acc.thisWeek.push(notification);
      } else if (createdDate.isSameOrAfter(startOfMonth)) {
        acc.thisMonth.push(notification);
      } else if (createdDate.isSameOrAfter(startOfYear)) {
        acc.thisYear.push(notification);
      } else {
        acc.earlier.push(notification);
      }
      return acc;
    },
    { today: [], yesterday: [], thisWeek: [], thisMonth: [], thisYear: [], earlier: [] }
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;


      // console.log(scrollContainer.clientHeight, scrollContainer.scrollTop, scrollThreshold);
      if (
        scrollContainer &&
        scrollContainer.scrollTop >= scrollThreshold &&
        !isFetching &&
        notificationList?.length < totalCount
      ) {
        setLimit((prevLimit) => prevLimit + 10);
        setScrollThreshold((prevThreshold) => prevThreshold + 150);
      }
    };
    const scrollContainer = scrollContainerRef.current;
    scrollContainer?.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer?.removeEventListener('scroll', handleScroll);
    };
  }, [isFetching, scrollThreshold, totalCount, notificationList]);
  const OnGetNotificationTasksForAdminPortal = useCallback(() => {
    // setIsFetching(true);
    const payload = { dashboardType: "adminProvider", limit: limit, role_name: userRole, userUuid: userId, is_archived: singleSegmentValue[0] == "Inbox" ? false : true, organisation_uuid: id, search: searchTerm };
    dispatch(GetNotificationTasksForAdminPortal(payload)).then((res) => {
      // setIsFetching(false);
      if (res?.success === true) {
        const arrayData = res?.data.rows
        setNotificationList(arrayData);
        setTotalTaskCount(res?.data?.unreadCount)
        setTotalCount(res?.data?.count)

      }
    });

  }, [dispatch, singleSegmentValue, searchTerm, limit]);
  useEffect(() => {
    OnGetNotificationTasksForAdminPortal()
  }, [singleSegmentValue, searchTerm, limit])

  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
  };
  const inputchangehandler = useCallbackRef(debounce(handleSearch), []);

  const archiveTask = (notify) => {
    const payload = {role_name: userRole, dashboardType: "adminProvider", notification_uuid: notify.notification_uuid, is_archived: true, userUuid: userId }
    dispatch(UpdateTaskArchiveStatus(payload)).then((res) => {
      if (res?.success === true) {
        OnGetNotificationTasksForAdminPortal();
      } else if (res?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other errors
      }
    });
  }

  const onMarkAsRead = (notifyId) => {
    const payload = {role_name: userRole, dashboardType: "adminProvider", notification_uuid: notifyId, userUuid: userId, task_read: true };
    dispatch(MarkTaskAsRead(payload)).then((res) => {
      if (res?.success === true) {
        setTotalTaskCount(totalTastCount - 1); // Fixed typo: 'totalTastCount' -> 'totalTaskCount'
        setNotificationList((prevList) => {
          return prevList.map((item) => {
            if (item?.notification_uuid === notifyId) {
              return { ...item, task_read: true };
            }
            return item;
          });
        });
      } else if (res?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other errors
      }
    });
  };

  return (
    <React.Fragment>
      <Heading className="mb-4">Dashboard</Heading>
      <div className="grid grid-container gap-8">
        <div className="flex gap-8 flex-col">
          <div className="bg-white py-5 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5 px-5 mb-3">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                <span className="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M6 15L8 17L12.5 12.5M8 8V5.2C8 4.0799 8 3.51984 8.21799 3.09202C8.40973 2.71569 8.71569 2.40973 9.09202 2.21799C9.51984 2 10.0799 2 11.2 2H18.8C19.9201 2 20.4802 2 20.908 2.21799C21.2843 2.40973 21.5903 2.71569 21.782 3.09202C22 3.51984 22 4.0799 22 5.2V12.8C22 13.9201 22 14.4802 21.782 14.908C21.5903 15.2843 21.2843 15.5903 20.908 15.782C20.4802 16 19.9201 16 18.8 16H16M5.2 22H12.8C13.9201 22 14.4802 22 14.908 21.782C15.2843 21.5903 15.5903 21.2843 15.782 20.908C16 20.4802 16 19.9201 16 18.8V11.2C16 10.0799 16 9.51984 15.782 9.09202C15.5903 8.71569 15.2843 8.40973 14.908 8.21799C14.4802 8 13.9201 8 12.8 8H5.2C4.0799 8 3.51984 8 3.09202 8.21799C2.71569 8.40973 2.40973 8.71569 2.21799 9.09202C2 9.51984 2 10.0799 2 11.2V18.8C2 19.9201 2 20.4802 2.21799 20.908C2.40973 21.2843 2.71569 21.5903 3.09202 21.782C3.51984 22 4.07989 22 5.2 22Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg></span>
                Task <Tag className="text-blue-800 bg-blue-100 ml-2">{totalTastCount}</Tag>
              </span>
              {/* <BtnLight className="w-20">View All</BtnLight> */}
            </div>
            <div className="flex justify-between mb-3 px-4">
              <Segment className="docType-btn"
                value={singleSegmentValue[0] == "Inbox" ? "Inbox" : "Archive"}
                onChange={(val) =>
                  onSingleSelectionSegmentChange(val)}
              >
                <Segment.Item value="Inbox">Inbox</Segment.Item>
                <Segment.Item value="Archive">Archive</Segment.Item>

              </Segment>
              <div className="w-80 h-10">
                <div className="input-wrapper">
                  <div className="input-suffix-start left-3.5">
                    <img src={SearchImg} alt="" className="h-5" />
                  </div>
                  <ClientInput
                    type="text"
                    className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Search"
                    inputchangehandler={inputchangehandler}
                    name={"search"}
                  />
                </div>
              </div>
            </div>

            <div ref={scrollContainerRef} className="notification-list-container overflow-y-auto h-[400px]">
              <Table className="min-w-full">
                <THead>
                  <Tr>
                    <Th className="pl-6 w-1/2	">Notification</Th>
                    <Th>Timestamp</Th>
                    <Th className="w-84">Company</Th>
                    {singleSegmentValue[0] == "Inbox" &&
                      <Th className="">Action</Th>
                    }
                  </Tr>
                </THead>
                <tbody className={`overflow-y-auto notification-list `}>
                  {loading ? (
                    <tr>
                      <td colSpan="4" className="text-center p-4">Loading...</td>
                    </tr>
                  ) : notificationList.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="text-center p-4">No Data Available!</td>
                    </tr>
                  ) : (
                    <>
                      {groupedNotifications.today.length > 0 && (
                        <>
                          <tr>
                            <td colSpan="4" className="text-blue-700 font-bold px-6 py-2 mt-2">Today</td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.today.map((notif) => (
                                  <li key={notif.notification_uuid} className="flex justify-between py-1.5 px-6 hover:bg-blue-50">
                                    <Link
                                      className={`flex gap-2 ${notif?.task_read ? '' : 'font-bold'}`}
                                      to="#"
                                      onClick={() => !notif?.task_read && onMarkAsRead(notif.notification_uuid)}
                                    >
                                      {notif?.task_read === true ? (
                                        <div className="" width="10" height="10">

                                        </div>
                                      ) : (
                                        <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                        </svg>
                                      )}


                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap"> {notif.message}</span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name ? notif.trading_name : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" &&
                                      <span className={`text-blue-700 font-semibold cursor-pointer`} onClick={() => { archiveTask(notif) }}>
                                        {notif?.action ? notif.action : "Archive"}
                                      </span>
                                    }
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.yesterday.length > 0 && (
                        <>
                          <tr>
                            <td colSpan="4" className="text-blue-700 font-bold px-6 py-2 mt-2">Yesterday</td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.yesterday.map((notif) => (
                                  <li key={notif.notification_uuid} className="flex justify-between py-1.5 px-6 hover:bg-blue-50">
                                    <Link
                                      className={`flex gap-2 ${notif?.task_read ? '' : 'font-bold'}`}
                                      to="#"
                                      onClick={() => !notif?.task_read && onMarkAsRead(notif.notification_uuid)}
                                    >
                                      {notif?.task_read === true ? (
                                        <div className="" width="10" height="10">

                                        </div>
                                      ) : (
                                        <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name ? notif.trading_name : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" &&
                                      <span className={`text-blue-700 font-semibold cursor-pointer`} onClick={() => { archiveTask(notif) }}>
                                        {notif?.action ? notif.action : "Archive"}
                                      </span>
                                    }
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisWeek.length > 0 && (
                        <>
                          <tr>
                            <td colSpan="4" className="text-blue-700 font-bold px-6 py-2 mt-2">This week</td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisWeek.map((notif) => (
                                  <li key={notif.notification_uuid} className="flex justify-between py-1.5 px-6 hover:bg-blue-50">
                                    <Link
                                      className={`flex gap-2 ${notif?.task_read ? '' : 'font-bold'}`}
                                      to="#"
                                      onClick={() => !notif?.task_read && onMarkAsRead(notif.notification_uuid)}
                                    >
                                      {notif?.task_read === true ? (
                                        <div className="" width="10" height="10">

                                        </div>
                                      ) : (
                                        <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name ? notif.trading_name : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" &&
                                      <span className={`text-blue-700 font-semibold cursor-pointer`} onClick={() => { archiveTask(notif) }}>
                                        {notif?.action ? notif.action : "Archive"}
                                      </span>
                                    }
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisMonth.length > 0 && (
                        <>
                          <tr>
                            <td colSpan="4" className="text-blue-700 font-bold px-6 py-2 mt-2">This month</td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisMonth.map((notif) => (
                                  <li key={notif.notification_uuid} className="flex justify-between py-1.5 px-6 hover:bg-blue-50">
                                    <Link
                                      className={`flex gap-2 ${notif?.task_read ? '' : 'font-bold'}`}
                                      to="#"
                                      onClick={() => !notif?.task_read && onMarkAsRead(notif.notification_uuid)}
                                    >
                                      {notif?.task_read === true ? (
                                        <div className="" width="10" height="10">

                                        </div>
                                      ) : (
                                        <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name ? notif.trading_name : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" &&
                                      <span className={`text-blue-700 font-semibold cursor-pointer`} onClick={() => { archiveTask(notif) }}>
                                        {notif?.action ? notif.action : "Archive"}
                                      </span>
                                    }
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                      {groupedNotifications.thisYear.length > 0 && (
                        <>
                          <tr>
                            <td colSpan="4" className="text-blue-700 font-bold px-6 py-2 mt-2">This year</td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <ul className="border-b pb-2">
                                {groupedNotifications.thisYear.map((notif) => (
                                  <li key={notif.notification_uuid} className="flex justify-between py-1.5 px-6 hover:bg-blue-50">
                                    <Link
                                      className={`flex gap-2 ${notif?.task_read ? '' : 'font-bold'}`}
                                      to="#"
                                      onClick={() => !notif?.task_read && onMarkAsRead(notif.notification_uuid)}
                                    >
                                      {notif?.task_read === true ? (
                                        <div className="" width="10" height="10">

                                        </div>
                                      ) : (
                                        <svg className="self-center" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="5" cy="5" r="5" fill="#5786CC" />
                                        </svg>
                                      )}
                                      <Tooltip title={notif.message}>
                                        <span className="text-gray-700 w-72 noti-newwrap">{notif.message}</span>
                                      </Tooltip>
                                    </Link>
                                    <span className=" pl-2">
                                      {moment(notif.created_date).format("HH:mm DD/MM/YY")}
                                    </span>
                                    <Tooltip title={notif.trading_name}>
                                      <span className="w-32 noti-newwrap cursor-pointer">
                                        {notif?.trading_name ? notif.trading_name : "-"}
                                      </span>
                                    </Tooltip>
                                    {singleSegmentValue[0] == "Inbox" &&
                                      <span className={`text-blue-700 font-semibold cursor-pointer`} onClick={() => { archiveTask(notif) }}>
                                        {notif?.action ? notif.action : "Archive"}
                                      </span>
                                    }
                                  </li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
                {isFetching && (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-4 border-blue-600"></div>
                  </div>
                )}
              </Table>
            </div>
          </div>

        </div>
        <div className="flex gap-8 flex-col">
          <div className="bg-white p-5 border border-gray-200 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                <span className="mr-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M17.9332 21.4389H7.31473C6.17619 21.4389 5.25 20.5128 5.25 19.3742V4.62625C5.25 3.48772 6.17614 2.56152 7.31473 2.56152H17.9334C19.0719 2.56152 19.9981 3.48773 19.9981 4.62625V19.3742C19.9981 20.5127 19.0718 21.4389 17.9332 21.4389ZM18.2281 4.62592C18.2281 4.46308 18.0954 4.33094 17.9332 4.33094H7.31473C7.15248 4.33094 7.01975 4.46309 7.01975 4.62592C7.01975 20.0499 6.85515 19.6689 7.31468 19.6689C18.2524 19.6689 18.2282 19.8564 18.2282 19.3739L18.2281 4.62592Z" fill="#1D2939" />
                    <path d="M14.0985 6.98594H11.1489C10.6605 6.98594 10.264 6.59008 10.264 6.10106C10.264 5.61203 10.6605 5.21619 11.1489 5.21619H14.0985C14.5869 5.21619 14.9834 5.61205 14.9834 6.10106C14.9834 6.59009 14.5869 6.98594 14.0985 6.98594Z" fill="#1D2939" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6287 9.56846C11.7726 9.56846 11.0786 10.2624 11.0786 11.1185C11.0786 11.9745 11.7726 12.6685 12.6287 12.6685C13.4847 12.6685 14.1787 11.9745 14.1787 11.1185C14.1787 10.2624 13.4847 9.56846 12.6287 9.56846ZM9.67864 11.1185C9.67864 9.48922 10.9994 8.16846 12.6287 8.16846C14.2579 8.16846 15.5787 9.48922 15.5787 11.1185C15.5787 12.7477 14.2579 14.0685 12.6287 14.0685C10.9994 14.0685 9.67864 12.7477 9.67864 11.1185ZM9.35309 17.1685H15.9042C15.1298 16.189 13.9486 15.5685 12.6287 15.5685C11.3087 15.5685 10.1275 16.189 9.35309 17.1685ZM8.17814 16.3994C9.20026 15.0467 10.8119 14.1685 12.6287 14.1685C14.4454 14.1685 16.057 15.0467 17.0792 16.3994C17.0843 16.4063 17.0896 16.4133 17.095 16.4203C17.1774 16.5293 17.2763 16.66 17.3483 16.7885C17.4393 16.9509 17.5115 17.1483 17.5071 17.3904C17.5035 17.5861 17.4448 17.764 17.3767 17.9008C17.3087 18.0375 17.2021 18.1917 17.0481 18.3125C16.842 18.4743 16.6204 18.5284 16.4392 18.5501C16.2854 18.5686 16.1065 18.5685 15.9426 18.5685C15.9334 18.5685 15.9242 18.5685 15.915 18.5685H9.34226C9.33312 18.5685 9.32392 18.5685 9.31468 18.5685C9.15077 18.5685 8.97192 18.5686 8.81814 18.5501C8.63694 18.5284 8.41533 18.4743 8.20918 18.3125C8.05522 18.1917 7.94864 18.0375 7.88058 17.9008C7.81252 17.764 7.75377 17.5861 7.75019 17.3904C7.74577 17.1483 7.81805 16.9509 7.90903 16.7885C7.98102 16.66 8.0799 16.5293 8.16234 16.4203C8.16768 16.4133 8.17295 16.4063 8.17814 16.3994Z" fill="#1D2939" />
                  </svg>
                </span>
                Provider breakdown
              </span>
              <BtnLight className="w-20">View All</BtnLight>
            </div>
            <div className="card-body pt-5">
              <div className="flex gap-3">
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-success-50 text-success-700">Compliant</Tag>
                  <span className="text-gray-600 text-sm font-semibold">72</span>
                </div>
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-error-50 text-error-700">Non-compliant</Tag>
                  <span className="text-gray-600 text-sm font-semibold">20</span>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-5 border border-gray-200 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                <span className="mr-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M14.625 2.26953V6.40007C14.625 6.96012 14.625 7.24015 14.734 7.45406C14.8299 7.64222 14.9828 7.7952 15.171 7.89108C15.3849 8.00007 15.6649 8.00007 16.225 8.00007H20.3555M9.625 16L11.625 18L16.125 13.5M14.625 2H9.425C7.74484 2 6.90476 2 6.26303 2.32698C5.69854 2.6146 5.2396 3.07354 4.95198 3.63803C4.625 4.27976 4.625 5.11984 4.625 6.8V17.2C4.625 18.8802 4.625 19.7202 4.95198 20.362C5.2396 20.9265 5.69854 21.3854 6.26303 21.673C6.90476 22 7.74484 22 9.425 22H15.825C17.5052 22 18.3452 22 18.987 21.673C19.5515 21.3854 20.0104 20.9265 20.298 20.362C20.625 19.7202 20.625 18.8802 20.625 17.2V8L14.625 2Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                Provider documents
              </span>
              <BtnLight className="w-20">View All</BtnLight>
            </div>
            <div className="card-body pt-5">
              <div className="flex gap-3">
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-blue-100 text-blue-800">Awaiting review</Tag>
                  <span className="text-gray-600 text-sm font-semibold">8</span>
                </div>
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-error-50 text-error-700">Action required</Tag>
                  <span className="text-gray-600 text-sm font-semibold">72</span>
                </div>
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-success-50 text-success-700">Approved</Tag>
                  <span className="text-gray-600 text-sm font-semibold">72</span>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white py-5 border border-gray-200 rounded-xl dark-shadow">
            <div className="px-5">
              <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-3">
                <div className="flex items-center">
                  <span className="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M9.35156 14.6667C9.35156 15.9553 10.3962 17 11.6849 17H13.8516C15.2323 17 16.3516 15.8807 16.3516 14.5C16.3516 13.1193 15.2323 12 13.8516 12H11.8516C10.4709 12 9.35156 10.8807 9.35156 9.5C9.35156 8.11929 10.4709 7 11.8516 7H14.0182C15.3069 7 16.3516 8.04467 16.3516 9.33333M12.8516 5.5V7M12.8516 17V18.5M22.8516 12C22.8516 17.5228 18.3744 22 12.8516 22C7.32871 22 2.85156 17.5228 2.85156 12C2.85156 6.47715 7.32871 2 12.8516 2C18.3744 2 22.8516 6.47715 22.8516 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></span>
                  <p></p>
                  <div className="flex flex-col"><span className="text-gray-900 text-base font-semibold">Billing</span><p className="text-gray-600">Due in the next 30 days </p></div>
                </div>
                <BtnLight className="w-20">View All</BtnLight>
              </div>
            </div>

            <div className="h-64">
              <Scrollbar>
                <div className="px-5">
                  <div className="overflow-hidden border rounded-lg border-gray-200">
                    <Table>
                      <TBody>
                        <Tr>
                          <Td>[Name of Recipient]</Td>
                          <Td><Tag className="bg-error-100 text-error-700"><span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                              <g clip-path="url(#clip0_5886_87224)">
                                <path d="M6 4V6M6 8H6.005M1 4.26137V7.73863C1 7.86092 1 7.92207 1.01382 7.97962C1.02606 8.03063 1.04627 8.07941 1.07368 8.12414C1.1046 8.1746 1.14784 8.21784 1.23431 8.30431L3.69569 10.7657C3.78216 10.8522 3.8254 10.8954 3.87586 10.9263C3.92059 10.9537 3.96937 10.9739 4.02038 10.9862C4.07793 11 4.13908 11 4.26137 11H7.73863C7.86092 11 7.92207 11 7.97962 10.9862C8.03063 10.9739 8.07941 10.9537 8.12414 10.9263C8.1746 10.8954 8.21784 10.8522 8.30431 10.7657L10.7657 8.30431C10.8522 8.21784 10.8954 8.1746 10.9263 8.12414C10.9537 8.07941 10.9739 8.03063 10.9862 7.97962C11 7.92207 11 7.86092 11 7.73863V4.26137C11 4.13908 11 4.07793 10.9862 4.02038C10.9739 3.96937 10.9537 3.92059 10.9263 3.87586C10.8954 3.8254 10.8522 3.78216 10.7657 3.69569L8.30431 1.23431C8.21784 1.14784 8.1746 1.1046 8.12414 1.07368C8.07941 1.04627 8.03063 1.02606 7.97962 1.01382C7.92207 1 7.86092 1 7.73863 1H4.26137C4.13908 1 4.07793 1 4.02038 1.01382C3.96937 1.02606 3.92059 1.04627 3.87586 1.07368C3.8254 1.1046 3.78216 1.14784 3.69569 1.23431L1.23431 3.69569C1.14784 3.78216 1.1046 3.8254 1.07368 3.87586C1.04627 3.92059 1.02606 3.96937 1.01382 4.02038C1 4.07793 1 4.13908 1 4.26137Z" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                              <defs>
                                <clipPath id="clip0_5886_87224">
                                  <rect width="12" height="12" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>Overdue</Tag>
                          </Td>
                          <Td>DD/MM/YYYY</Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>
                          <Td><Tag className="bg-error-100 text-error-700"><span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                              <g clip-path="url(#clip0_5886_87224)">
                                <path d="M6 4V6M6 8H6.005M1 4.26137V7.73863C1 7.86092 1 7.92207 1.01382 7.97962C1.02606 8.03063 1.04627 8.07941 1.07368 8.12414C1.1046 8.1746 1.14784 8.21784 1.23431 8.30431L3.69569 10.7657C3.78216 10.8522 3.8254 10.8954 3.87586 10.9263C3.92059 10.9537 3.96937 10.9739 4.02038 10.9862C4.07793 11 4.13908 11 4.26137 11H7.73863C7.86092 11 7.92207 11 7.97962 10.9862C8.03063 10.9739 8.07941 10.9537 8.12414 10.9263C8.1746 10.8954 8.21784 10.8522 8.30431 10.7657L10.7657 8.30431C10.8522 8.21784 10.8954 8.1746 10.9263 8.12414C10.9537 8.07941 10.9739 8.03063 10.9862 7.97962C11 7.92207 11 7.86092 11 7.73863V4.26137C11 4.13908 11 4.07793 10.9862 4.02038C10.9739 3.96937 10.9537 3.92059 10.9263 3.87586C10.8954 3.8254 10.8522 3.78216 10.7657 3.69569L8.30431 1.23431C8.21784 1.14784 8.1746 1.1046 8.12414 1.07368C8.07941 1.04627 8.03063 1.02606 7.97962 1.01382C7.92207 1 7.86092 1 7.73863 1H4.26137C4.13908 1 4.07793 1 4.02038 1.01382C3.96937 1.02606 3.92059 1.04627 3.87586 1.07368C3.8254 1.1046 3.78216 1.14784 3.69569 1.23431L1.23431 3.69569C1.14784 3.78216 1.1046 3.8254 1.07368 3.87586C1.04627 3.92059 1.02606 3.96937 1.01382 4.02038C1 4.07793 1 4.13908 1 4.26137Z" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                              <defs>
                                <clipPath id="clip0_5886_87224">
                                  <rect width="12" height="12" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>Overdue</Tag>
                          </Td>
                          <Td>DD/MM/YYYY</Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>
                          <Td><Tag className="bg-warning-100 text-warning-700"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                            <g clip-path="url(#clip0_5799_40401)">
                              <path d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7" stroke="#F79009" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_5799_40401">
                                <rect width="12" height="12" fill="white" transform="translate(0.851562)" />
                              </clipPath>
                            </defs>
                          </svg></span>Upcoming</Tag>
                          </Td>
                          <Td>DD/MM/YYYY</Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>
                          <Td><Tag className="bg-warning-100 text-warning-700"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                            <g clip-path="url(#clip0_5799_40401)">
                              <path d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7" stroke="#F79009" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_5799_40401">
                                <rect width="12" height="12" fill="white" transform="translate(0.851562)" />
                              </clipPath>
                            </defs>
                          </svg></span>Upcoming</Tag>
                          </Td>
                          <Td>DD/MM/YYYY</Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>
                          <Td><Tag className="bg-warning-100 text-warning-700"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                            <g clip-path="url(#clip0_5799_40401)">
                              <path d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7" stroke="#F79009" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_5799_40401">
                                <rect width="12" height="12" fill="white" transform="translate(0.851562)" />
                              </clipPath>
                            </defs>
                          </svg></span>Upcoming</Tag>
                          </Td>
                          <Td>DD/MM/YYYY</Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>
                          <Td><Tag className="bg-warning-100 text-warning-700"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                            <g clip-path="url(#clip0_5799_40401)">
                              <path d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7" stroke="#F79009" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_5799_40401">
                                <rect width="12" height="12" fill="white" transform="translate(0.851562)" />
                              </clipPath>
                            </defs>
                          </svg></span>Upcoming</Tag>
                          </Td>
                          <Td>DD/MM/YYYY</Td>
                        </Tr>
                        <Tr>
                          <Td>[Name of Recipient]</Td>
                          <Td><Tag className="bg-warning-100 text-warning-700"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                            <g clip-path="url(#clip0_5799_40401)">
                              <path d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7" stroke="#F79009" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                              <clipPath id="clip0_5799_40401">
                                <rect width="12" height="12" fill="white" transform="translate(0.851562)" />
                              </clipPath>
                            </defs>
                          </svg></span>Upcoming</Tag>
                          </Td>
                          <Td>DD/MM/YYYY</Td>
                        </Tr>
                      </TBody>
                    </Table>
                  </div>
                </div>
              </Scrollbar>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContractorSectionDashboard;
