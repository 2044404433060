import React,{useState} from "react"
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import Tooltip from "../../../../../../common/element/Tooltip";
const Invited = () =>{
    const [dialogDeleteOpen, setDeleteIsOpen] = useState(false)
    const [dialogIsOpen, setIsOpen] = useState(false)

    const openDialog = () => {
        setIsOpen(true)
    }
    const onDialogClose = (e) => {
        setIsOpen(false)
    }
    const openDeleteDialog = () => {
        setDeleteIsOpen(true)
    }
    const onDeleteDialogClose = (e) => {
        setDeleteIsOpen(false)
    }
    return(
        <React.Fragment>
        <div className="overflow-x-auto mb-10">
        <div className="w-full inline-block align-middle">
            <div className="overflow-hidden border rounded-lg border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            
                            <th
                                scope="col"
                                className="bg-gray-100 px-4 py-3 text-xs font-medium text-left text-gray-600 w-2/5"
                            >
                                <span className='flex items-center'>User name
                              </span>
                            
                            </th>
                            <th
                                scope="col"
                                className="bg-gray-100 px-4 py-3 text-xs font-medium text-left text-gray-600  w-1/4"
                            >
                                <span className="inline-flex items-center">
                                Email
                                 
                                </span>
                            </th>
                      
                            
                            <th
                                scope="col"
                                className="bg-gray-100 px-4 py-3 text-xs font-medium text-left text-gray-600 "
                            >
                                <span className='flex items-center'>Sent <span className="ml-1 ">
                                <Tooltip title="Account Billing contact is responsible for billing and payment on the account." placement="top-start">
                <div className="text-center cursor-pointer">
                <img src="/assets/icons-svg/help-circle.svg" alt=""/>
                </div>
            </Tooltip>
                                </span>
                               </span>
                            
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                                     <tr>
                                        <td className="px-4 py-3.5 text-xs font-medium text-gray-900 flex items-center">First name Last name</td>
                                        <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">name@emailaddress.com</td>
                                        <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">00 hrs ago</td>
                                        <td align="center">
                                            <button onClick={() => openDeleteDialog() } className="mr-4"><svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</button>
                                            <button onClick={() => openDialog()}><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z" stroke="#101828" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

</button>
                                        </td>
                                     </tr>  
                                     <tr>
                                        <td className="px-4 py-3.5 text-xs font-medium text-gray-900 flex items-center">First name Last name</td>
                                        <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">name@emailaddress.com</td>
                                        <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">00 hrs ago</td>
                                        <td align="center">
                                            <button onClick={() => openDeleteDialog() } className="mr-4"><svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</button>
                                            <button onClick={() => openDialog()}><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z" stroke="#101828" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

</button>
                                        </td>
                                     </tr>  
                                     <tr>
                                        <td className="px-4 py-3.5 text-xs font-medium text-gray-900 flex items-center">First name Last name</td>
                                        <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">name@emailaddress.com</td>
                                        <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">00 hrs ago</td>
                                        <td align="center">
                                            <button onClick={() => openDeleteDialog() } className="mr-4"><svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</button>
                                            <button onClick={() => openDialog()}><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z" stroke="#101828" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

</button>
                                        </td>
                                     </tr>  
                                     <tr>
                                        <td className="px-4 py-3.5 text-xs font-medium text-gray-900 flex items-center">First name Last name</td>
                                        <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">name@emailaddress.com</td>
                                        <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">00 hrs ago</td>
                                        <td align="center">
                                            <button onClick={() => openDeleteDialog() } className="mr-4"><svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</button>
                                            <button onClick={() => openDialog()}><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z" stroke="#101828" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

</button>
                                        </td>
                                     </tr>            
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <Dialog
        isOpen={dialogDeleteOpen}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
    >
       <div className="px-6 pt-6">
        <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

         </span>
             <h4 className="text-base text-gray-900 font-semibold text-center mt-4">Remove Invitation?</h4>
            <p className=" text-gray-600 text-center mt-1">
            Removing this invitation will cancel their access to the app.
            </p>
        </div>
      
        <div className='mt-8 flex justify-between pb-6 px-6'>
            <BtnLight className='mr-1.5' >Cancel</BtnLight>
            <SmallButton className='ml-1.5'>Confirm</SmallButton>
       
        </div>

    </Dialog>
    
    <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
                bodyOpenClassName="overflow-hidden"
                className="customAlert"
            >
              <div className="px-6 pt-6">
        <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z" stroke="#101828" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

         </span>
             <h4 className="text-base text-gray-900 font-semibold text-center mt-4">Invite sent successfully!</h4>
    
        </div>
   
        <div className='mt-8 flex justify-between pb-6 px-6'>
            <SmallButton >Confirm</SmallButton>
       
        </div>
            </Dialog>
    </React.Fragment>
    )
}
export default Invited