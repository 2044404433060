import React from "react";
import Heading from "../../../../../common/element/Heading";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../../../../actions/index";
const SiteName = (props) => {
  const dispatch = useDispatch();
  const { ClientSpecificProfileData } = useSelector((state) => state)
  
  const clientData = JSON.parse(localStorage.getItem('clientData'))
  return (
    <React.Fragment>
      <div className="flex gap-4 flex-row justify-between">
        <div className="flex items-center mb-5">
          <div className="h-16 w-16 rounded-full overflow-hidden mr-5 avtar_img_round">
            <img
              src={ClientSpecificProfileData?.logo ? ClientSpecificProfileData?.logo : "/assets/img/avatars/thumb-8.jpg"}
              alt="Client Logo"
            />                          </div>
          <div className="flex flex-col">
            <Heading className="mb-1">{props?.siteName ? props?.siteName : "-"}</Heading>
            <div className="block">
              <span className="text-gray-600 text-base  font-medium">{ClientSpecificProfileData?.trading_name?ClientSpecificProfileData?.trading_name:""}</span></div>
          </div>
        </div>
        {/* <div className="flex justify-between mb-5 px-2">
                    <BtnLight className=" w-24 text-xs text-gray-700 font-semibold flex  items-center justify-center" type="button"><img src="/assets/icons-svg/edit.svg" alt="" className="mr-2"/>Notes</BtnLight>
                    </div> */}

      </div>
    </React.Fragment>
  )
}
export default SiteName