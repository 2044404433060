import axios from "axios";
import * as actionType from "../../../constant/actionType";
import { fetchData,fetchDataSuccess, fetchDataError } from "../../../redux/classes/AdminContractor/GetAdminproviderClientSlice";
export const GetAllClientsOfProvider = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
        dispatch(fetchData())
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/GetAllClientsOfProvider`,
        params: apiData
      });
      dispatch(fetchDataSuccess(response.data))
      
    } catch (error) {
        dispatch(fetchDataError(error.message))  
    }
  };