import React from "react";
import Dropdown from "../../../../../../../../common/element/Dropdown/Dropdown";
import BtnPrimary from "../../../../../../../../common/element/Buttons/btnPrimary";
const ChooseModule = (props) => {
   const Toggle = (
     <BtnPrimary className="px-4 text-xs h-9 flex justify-center items-center gap-2">
       <svg
         width="21"
         height="20"
         viewBox="0 0 21 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
       >
         <path
           d="M10.712 4.167v11.666M4.878 10h11.667"
           stroke="#fff"
           strokeWidth="1.667"
           strokeLinecap="round"
           strokeLinejoin="round"
         />
       </svg>
       Add content
     </BtnPrimary>
   );
 
   //  Render pdf
 
   return (
     <React.Fragment>
       <div className="dropdown-wrap">
         <Dropdown renderTitle={Toggle}>
           <Dropdown.Item
             onClick={props.handleAddContent}
             eventKey="a"
             className="gap-2 justify-between"
             id="pdf"
           >
             <span className="w-full flex gap-1 text-gray-900 font-medium" id="pdf">
               <svg
                 width="21"
                 height="20"
                 viewBox="0 0 21 20"
                 id="pdf"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M10.5 11.667V7.084a1.25 1.25 0 1 1 2.5 0v4.583a2.5 2.5 0 0 1-5 0V8.334m-.167 10h5.333c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.093-1.092c.272-.535.272-1.235.272-2.635V5.667c0-1.4 0-2.1-.272-2.635A2.5 2.5 0 0 0 15.8 1.939c-.534-.272-1.235-.272-2.635-.272H7.833c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093c-.272.535-.272 1.235-.272 2.635v8.667c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092c.535.273 1.235.273 2.635.273z"
                   stroke="#667085"
                   id="pdf"
                   strokeWidth="1.667"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
               </svg>
               PDF
             </span>
             <span className="checkIcon" id="pdf">
               <svg
                 width="21"
                 id="pdf"
                 height="20"
                 viewBox="0 0 21 20"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M17.166 5 8 14.167 3.833 10"
                   stroke="#5AB8ED"
                   strokeWidth="1.667"
                   strokeLinecap="round"
                   id="pdf"
                   strokeLinejoin="round"
                 />
               </svg>
             </span>
           </Dropdown.Item>
           <Dropdown.Item
             eventKey="b"
             onClick={props.handleAddContent}
             className="gap-2 justify-between"
             id="image"
           >
             <span className="w-full flex gap-1 text-gray-900 font-medium" id="image">
               <svg
                 width="21"
                 height="20"
                 id="image"
                 viewBox="0 0 21 20"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M14 17.5H6.276c-.505 0-.757 0-.874-.1a.417.417 0 0 1-.145-.35c.012-.153.19-.331.548-.688l7.085-7.086c.33-.33.495-.495.686-.557a.833.833 0 0 1 .515 0c.19.062.355.227.685.557L18 12.5v1m-4 4c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.092-1.092C18 15.6 18 14.9 18 13.5m-4 4H7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C3 15.6 3 14.9 3 13.5v-7c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093C4.9 2.5 5.6 2.5 7 2.5h7c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.092 1.093C18 4.4 18 5.1 18 6.5v7M9.25 7.083a1.667 1.667 0 1 1-3.333 0 1.667 1.667 0 0 1 3.333 0z"
                   stroke="#667085"
                   strokeWidth="1.667"
                   strokeLinecap="round"
                   id="image"
                   strokeLinejoin="round"
                 />
               </svg>
               Image
               <span className="font-normal text-gray-600" id="image">jpg, png, gif</span>
             </span>
 
             <span className="checkIcon" id="image">
               <svg
                 width="21"
                 id="image"
                 height="20"
                 viewBox="0 0 21 20"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M17.166 5 8 14.167 3.833 10"
                   stroke="#5AB8ED"
                   strokeWidth="1.667"
                   id="image"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
               </svg>
             </span>
           </Dropdown.Item>
           <Dropdown.Item
             eventKey="c"
             onClick={props.handleAddContent}
             className="gap-2 justify-between"
             id="video"
           >
             <div className="w-full flex gap-1 text-gray-900 font-medium" id="video">
               <svg
                 width="21"
                 id="video"
                 height="20"
                 viewBox="0 0 21 20"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M18.834 7.443c0-.505 0-.757-.1-.874a.417.417 0 0 0-.35-.145c-.153.012-.331.19-.688.548L14.667 10l3.029 3.029c.357.357.535.535.688.547a.417.417 0 0 0 .35-.144c.1-.117.1-.37.1-.875V7.443zM2.167 8.167c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.535-.272 1.235-.272 2.635-.272h4.5c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.535.272 1.235.272 2.635v3.667c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.092c-.535.273-1.235.273-2.635.273h-4.5c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092c-.272-.535-.272-1.235-.272-2.635V8.167z"
                   stroke="#667085"
                   strokeWidth="1.667"
                   id="video"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
               </svg>
               Video
               <span className="font-normal text-gray-600" id="video">
                 Youtube, Vimeo, upload
               </span>
             </div>
 
             <span className="checkIcon" id="video">
               <svg
                 width="21"
                 id="video"
                 height="20"
                 viewBox="0 0 21 20"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M17.166 5 8 14.167 3.833 10"
                   stroke="#5AB8ED"
                   id="video"
                   strokeWidth="1.667"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
               </svg>
             </span>
           </Dropdown.Item>
           <Dropdown.Item
             eventKey="d"
             onClick={props.handleAddContent}
             className="gap-2 justify-between"
             id="text"
           >
             <span className="w-full flex gap-1 text-gray-900 font-medium" id="text">
               <svg
                 width="21"
                 height="20"
                 id="text"
                 viewBox="0 0 21 20"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M4.666 5.955h2.5M3.833 7.62l1.78-3.916c.096-.212.144-.318.211-.35a.208.208 0 0 1 .184 0c.067.032.115.138.212.35L8 7.621"
                   stroke="#667085"
                   strokeWidth="1.667"
                   id="text"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
                 <path
                   d="M18 7.708h-7.5M18 3.333h-7.5m7.5 8.958H3m15 4.375H3"
                   stroke="#000"
                   id="text"
                   strokeWidth="2"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
               </svg>
               Text
             </span>
             <span className="checkIcon" id="text">
               <svg
                 width="21"
                 id="text"
                 height="20"
                 viewBox="0 0 21 20"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M17.166 5 8 14.167 3.833 10"
                   stroke="#5AB8ED"
                   id="text"
                   strokeWidth="1.667"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
               </svg>
             </span>
           </Dropdown.Item>
           <Dropdown.Item
             eventKey="e"
             onClick={props.handleAddContent}
             className="gap-2 justify-between"
             id="urlLink"
           >
             <span className="w-full flex gap-1 text-gray-900 font-medium" id="urlLink">
               <svg
                 width="21"
                 height="20"
                 id="urlLink"
                 viewBox="0 0 21 20"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M8.834 10.833a4.168 4.168 0 0 0 6.283.45l2.5-2.5a4.167 4.167 0 0 0-5.892-5.892l-1.433 1.425m1.875 4.85a4.165 4.165 0 0 0-6.284-.45l-2.5 2.5a4.167 4.167 0 0 0 5.892 5.892l1.425-1.425"
                   stroke="#667085"
                   strokeWidth="1.667"
                   id="urlLink"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
               </svg>
               URL Link
             </span>
             <span className="checkIcon" id="urlLink">
               <svg
                 width="21"
                 id="urlLink"
                 height="20"
                 viewBox="0 0 21 20"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
               >
                 <path
                   d="M17.166 5 8 14.167 3.833 10"
                   stroke="#5AB8ED"
                   id="urlLink"
                   strokeWidth="1.667"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                 />
               </svg>
             </span>
           </Dropdown.Item>
         </Dropdown>
       </div>
     </React.Fragment>
   );
 };
export default ChooseModule