import React, { useState, useEffect } from "react";
import Table from "../../../../../../common/element/Table";


const ServicesTypesList = (props) => {
   const { Tr, Th, Td, THead, TBody } = Table
   const [serviceData, setServiceData] = useState(props?.serviceData)
   //  const [serviceType, setServiceType] = useState(props?.serviceType )


   useEffect(() => {
      setServiceData(props?.serviceData)
   }, [props?.serviceData])
   return (
      <React.Fragment>
         <div className="border rounded-lg border-gray-200">
            <Table>
               <THead>
                  <Tr>

                     <Th className="w-48">Type  </Th>
                     <Th>
                        <span className='flex items-center gap-1' onClick={() => props?.handleSort('meta_value_one')}>
                           Service Name
                           <svg className={`transform ${props?.filtersort === 'meta_value_one' && props?.filterorder === 'asc' ? 'rotate-180' : ''}`}
                              width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round" />
                           </svg>
                        </span>
                     </Th>
                     <Th className="w-72">Created by  </Th>
                     <Th className="w-44">
                        <span className='flex items-center gap-1' onClick={() => props?.handleSort('created_date')}>
                           Date created
                           <svg className={`transform ${props?.filtersort === 'created_date' && props?.filterorder === 'asc' ? 'rotate-180' : ''}`}
                              width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round" />
                           </svg>
                        </span>
                     </Th>
                  </Tr>
               </THead>
               <TBody>
                  {serviceData && serviceData?.map((item) =>
                     <Tr key={item?.service_id}>

                        <Td>{item?.meta_key?item?.meta_key:"-"}</Td>
                        <Td>{item?.meta_value_one?item?.meta_value_one:"-"}</Td>
                        <Td>{`${item?.first_name?item?.first_name:"-"} ${item?.last_name?item?.last_name:""}`}</Td>
                        <Td>{item?.created_date?item?.created_date:"-"}</Td>
                     </Tr>
                  )}

               </TBody>
            </Table>
         </div>
      </React.Fragment>
   )
}
export default ServicesTypesList