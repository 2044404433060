import { createSlice } from '@reduxjs/toolkit';

const SpecificClientProfileData = createSlice({
    name: "SpecificClientProfileData",
    initialState: [],
    reducers: {
        specificClientData(state, action) {
            return action.payload;
        },
        resetData(state) {
            return []; 
        }
    }
});

export default SpecificClientProfileData.reducer;
export const { specificClientData, resetData } = SpecificClientProfileData.actions;
