import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ImageUploader from "../../../../../../../common/element/ImageUploder";
import { GetClientOverviewProfileById } from "../../../../../../../actions/provider-portal/worker/GetClientOverviewProfileById";
const ClientDetails = (props) => {
  const defaultImageUrl =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVYhkJiYFb8n7qEEchgHlIoZzgalsuUrfkXQ&s";
  const [getCLientData, SetGetCLientData] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const fetchData = useCallback(async () => {
    console.log("props?.clientOrgId", props?.clientOrgId);
    if (props?.clientOrgId) {
      const response = await dispatch(
        GetClientOverviewProfileById({
          organisation_uuid: props?.clientOrgId || "",
        })
      );
      if (response?.success) {
        SetGetCLientData(response?.data);
      }
    }
  }, [dispatch, props?.clientOrgId]);
  useEffect(() => {
    fetchData();
  }, [props?.clientOrgId]);

  return (
    <div className="flex w-full">
      <div className="border h-72 border-gray-300 rounded-lg w-56 flex items-center text-center justify-center">
        {getCLientData?.logo == undefined && defaultImageUrl && (
          <ImageUploader
            uploadImage={false} // Set to false to disable image upload
            defaultImage={defaultImageUrl}
          />
        )}
        {getCLientData?.logo && defaultImageUrl && (
          <ImageUploader
            uploadImage={false} // Set to false to disable image upload
            defaultImage={getCLientData?.logo}
          />
        )}
      </div>
      <div className="flex w-4/6 pl-5">
        <div className="w-3/6">
          <div className="flex flex-col mb-5">
            <label className="text-xs font-medium">Client name</label>
            <div className="text-gray-900 font-semibold">
              {getCLientData?.trading_name ? getCLientData?.trading_name : "-"}
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <label className="text-xs font-medium">Email address</label>
            <div className="text-gray-900 font-semibold">
              {getCLientData?.email ? getCLientData?.email : "-"}
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <label className="text-xs font-medium">Website</label>
            <Link
              to={getCLientData?.website_url}
              className="text-xs text-blue-700 flex gap-1"
            >
              {getCLientData?.website_url}
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7"
                  stroke="#5786cc"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="flex flex-col mb-5">
            <div class="flex mb-0">
              <div class="flex-1">
                <label className="text-xs font-medium">Address</label>
                <span className="text-gray-900 font-semibold block">
                  <span className="text-gray-900 font-semibold block">
                    {[
                      getCLientData?.org_address_data?.[0]?.address_one,
                      getCLientData?.org_address_data?.[0]?.address_two,
                      getCLientData?.org_address_data?.[0]?.state_name,
                      getCLientData?.org_address_data?.[0]?.country_name,
                    ].every((part) => !part)
                      ? "-"
                      : [
                          getCLientData?.org_address_data?.[0]?.address_one,
                          getCLientData?.org_address_data?.[0]?.address_two,
                          getCLientData?.org_address_data?.[0]?.state_name,
                          getCLientData?.org_address_data?.[0]?.country_name,
                        ].join(", ")}
                  </span>
                </span>
              </div>
              {/* <div class="flex-1">
          
                <label className="text-xs font-medium">Address2</label>
                <span class="text-gray-900 font-semibold block">
                  {getCLientData?.org_address_data?.[0]?.address_two}
                </span>
              </div> */}
            </div>
            {/* <div class="flex mb-0">
              <div class="flex-1">
      
                <label className="text-xs font-medium">Country</label>
                <span class="text-gray-900 font-semibold block">
                  {getCLientData?.org_address_data?.[0]?.country_name}
                </span>
              </div>
              <div class="flex-1">
              
                <label className="text-xs font-medium">State</label>
                <span class="text-gray-900 font-semibold block">
                  {getCLientData?.org_address_data?.[0]?.state_name}
                </span>
              </div>
            </div> */}
          </div>
        </div>
        <div className="w-3/6">
          <div className="flex flex-col mb-5">
            <label className="text-xs font-medium">ABN/NZBN</label>
            <div className="text-gray-900 font-semibold">
              {getCLientData?.abn_nzbn ? getCLientData?.abn_nzbn : "-"}
            </div>
          </div>
          <div className="flex flex-col mb-5">
            <label className="text-xs font-medium">Contact number</label>
            <div className="text-gray-900 font-semibold">
              {getCLientData?.org_phone ? getCLientData?.org_phone : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDetails;
