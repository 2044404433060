import React, { useEffect } from "react";
import ClientInformation from "./ClientInformation";
import Billing from "./Billing";
import Inductions from "./Indunctions";
import TotalClient from "./TotalClient";
import TotalWorker from "./TotalWorker";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import { useSelector } from "react-redux";
const Overview = () => {
  const {SpecificProviderData} = useSelector((state)=>state)
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {             
          label: "View All Provider",
          url: "/admin/provider/view-all-provider",
          home: "/admin/provider/dashboard",
        },
        {
          label: `${SpecificProviderData?.trading_name}`,
          url: `/admin/provider/view-all-provider/specific-provider/${id}`,
        },

        {
          label: "Overview",
          url: `/admin/provider/view-all-provider/specific-provider/${id}`,
        },
      ])
    );
  }, []);
  return (
    <div className="grid grid-container gap-8">
      <div className="flex gap-8 flex-col">
        <ClientInformation />
        <Billing />
      </div>

      <div className="flex gap-8 flex-col">
        <TotalClient />
        <TotalWorker />
        <Inductions />
      </div>
    </div>
  );
};

export default Overview;
