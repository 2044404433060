import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const CreateCompanyInductionModuleApi = (ApiData) => async (dispatch) => {
      try {
        // dispatch(fetchData());
        let formdata = new FormData();
        for (let key in ApiData) { 
          if (key === 'company_ind_file') {
            formdata.append('company_ind_file', ApiData.company_ind_file);
          } else {
            if (key === 'module_ques_data') {
              // Assuming you want to stringify the array as JSON
                formdata.append(key, JSON.stringify(ApiData[key]));
            }
            else if(key==="pdf_page_range"){
              if(typeof(ApiData[key])=="object"){
                formdata.append(key, JSON.stringify(ApiData[key]));
              }else{
                formdata.append(key, (ApiData[key]));
              }
              // formdata.append(key, ApiData[key]);
            }
            else {
              // For other properties, append the value directly
              if(key === "content_info_type"){
                if(ApiData.content_info_type!==""&&ApiData.content_info_type!==null){
                  formdata.append(key, ApiData[key]);
                }
              }else{
                formdata.append(key, ApiData[key]);
              } 
            }
          }
        }

        const response = await axios({
          method: 'post',
          data: formdata,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
          },
          url: `${actionType.environment_url}/api/client/CreateCompanyInductionModule`,
        });
        // dispatch(fetchDataSuccess(response.data));
        return response.data
      } catch (error) {
        return error.message
        // dispatch(fetchDataError(error.message));
      }
    }