import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "react-notify-toast";
import { useParams } from "react-router-dom";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import Scrollbars from "react-custom-scrollbars";
import RichTextEditor from "../../../../../common/snippet/RichTextEditor";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import EmailIcon from "../../../../../assets/icons-svg/email.svg";
import * as userActions from "../../../../../actions/index";
import Select from "../../../../../common/element/Select";
import ThreeDotLoader from "../../../../../common/element/ButtonLoader/ThreeDotLoader";
import Upload from "../../../../../common/element/Upload";
import Tooltip from "../../../../../common/element/Tooltip";
import trashIcon from "../../../../../assets/icons-svg/trash.svg";
import PdfFile from "../../../../../assets/icons-svg/upload-pdf.svg";
import Validations from "../../../../../common/404/Validations";
import { debounce } from "../../../../../common/snippet/Debouncing";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import {
  GetAllIndividualForEmail,
  GetAllSpecificEmailTemplates,
  EmailToIndividual,
} from "../../../../../actions/client-portal/AllEmailApi";

const Email = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  let templateType = { template_type: "client" };
  const [templateData, setTemplateData] = useState("");
  const [templateSub, setTemplateSub] = useState("");
  const [dialogAddOpen, setDialogAddOpen] = useState(false);
  const [templateMessage, setTemplateMessage] = useState("");
  const [search, setSearch] = useState("");
  const [selectedOptionRecipient, setSelectedOptionRecipient] = useState(null);
  const [selectedOptionCC, setSelectedOptionCC] = useState(null);
  const [selectedOptionBCC, setSelectedOptionBCC] = useState(null);
  const [searchOptions, setSearchOptions] = useState([]);
  const [recipient, setRecipient] = useState([]);
  const [cc, setCC] = useState([]);
  const [bcc, setBCC] = useState([]);
  const [attachment, setAttachment] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState({ files: [] });
  const [templateUuid, setTemplateUuid] = useState("");
  const [emailToError, setEmailError] = useState({
    recipientError: "",
    ccError: "",
    bccError: "", 
    docUploadError: "",
  }); const [subjectError, setSubjectError] = useState("");
  // Retrieve function assignment UUID
  let function_assignment_uuid;
  let organisation_uuid;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    function_assignment_uuid = storedData.function_assignment_uuid;
    organisation_uuid = storedData.organisation_uuid;
  }
  // // Check if client data is available
  // if (props?.clientData?.trading_name) {
  //   let items = [
  //     { label: "View All Clients" },
  //     { label: props?.clientData?.trading_name },
  //   ];
  //   localStorage.setItem("Breadcrums", JSON.stringify(items));
  // }
  // Handle CC button click
  const onCcClick = () => {
    setShowCC(!showCC);
  };
  // Handle BCC button click
  const onBccClick = () => {
    setShowBcc(!showBcc);
  };
  // Open the dialog for Email
  const openDialog = () => {
    setIsOpen(true);
  };

  // Handle dialog close for email
  const onDialogClose = (e) => {
    if (props?.buttonDisabled) {
      setEmailError({
        recipientError: "",
        ccError: "",
        bccError: "",
        docUploadError: ""
      });
      setSubjectError("")
      setIsOpen(false);
      setAttachment("");
      setUploadedFiles({ files: [] });
      setBCC([]);
      setCC([]);
      setRecipient([]);
      setTemplateSub("");
      setTemplateMessage("");
      setSelectedOptionRecipient("");
      setSelectedOptionCC("");
      setSelectedOptionBCC("");
      setShowBcc(false);
      setShowCC(false);
    }
  };

  const fetchEmailTemplates = useCallback(() => {
    dispatch(GetAllSpecificEmailTemplates(templateType))
      .then((response) => {
        if (response?.success) {
          const arrayData = response?.data;
          const maildata = arrayData.map((item) => ({
            template_content: item?.template_content,
            attachment: item?.email_temp_attach,
            template_name: item?.template_name,
            template_subject: item?.template_subject,
            templateUuid: item?.email_template_uuid,
          }));

          setTemplateData(maildata);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        console.error("Error fetching templates:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchEmailTemplates();
  }, [])


  const fetchIndividualsForEmail = useCallback(() => {
    dispatch(GetAllIndividualForEmail(search))
      .then((response) => {
        if (response?.success) {
          const arrayData = response?.data;
          const maildata = arrayData.map((item) => ({
            individual_uuid: item?.individual_uuid,
            label: item?.email,
            value: item?.email,
          }));

          setSearchOptions(maildata);
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        console.error("Error fetching individuals for email:", error);
      });
  }, [dispatch, search]);


  // Handle template selection
  const handleTemplate = async (e, tempData) => {
    setTemplateSub(tempData?.template_subject);
    if (tempData?.template_subject) {
      setSubjectError("")
    }
    setTemplateMessage(tempData?.template_content);
    setTemplateUuid(tempData?.templateUuid);

    // Fetch the file from the URL
    const fileData = [];
    tempData?.attachment.forEach((data) => {
      const file = data?.email_temp_doc;
      const attachment_uuid = data?.email_temp_attachment_uuid;
      const segments = file.split("/");

      // Get the last segment, which should be the file name
      const fileNameWithQueryParams = segments[segments.length - 1];

      // Decode the percent-encoded characters
      const decodedFileName = decodeURIComponent(fileNameWithQueryParams);

      fileData.push({
        email_temp_attachment_uuid: attachment_uuid,
        file: file,
        fileName: decodedFileName,
      });
    });

    setUploadedFiles((prevAttachment) => ({
      ...prevAttachment,
      files: fileData,
    }));
  };

  // Handle file change
  const handleFileChange = (files) => {
    setAttachment({ files });
  };
  // Handle file removal
  const handleFileRemove = (files) => {
    if (files.email_temp_attachment_uuid) {
      let removeFile = uploadedFiles.files.filter(
        (item) =>
          item?.email_temp_attachment_uuid !== files.email_temp_attachment_uuid
      );
      setUploadedFiles((prevAttachment) => ({
        ...prevAttachment,
        files: removeFile,
      }));
    } else {
      setEmailError((prevError) => ({ ...prevError, docUploadError: "" }));
      setAttachment({ files });
    }
  };
  //  To check Correct Email or not 
  function isEmail(value) {
    if (value !== undefined && value !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    }
    return false;
  }
  // Handle search input change
  const handleSearchChange = debounce((newInputValue, identifier) => {
    setSearch(newInputValue);
    if (newInputValue?.length >= 3) {
      if (identifier === "recipient") {
        setEmailError((prevError) => ({ ...prevError, recipientError: "" }));
      }
      if (identifier === "cc") {
        setEmailError((prevError) => ({ ...prevError, ccError: "" }));
      }
      if (identifier === "bcc") {
        setEmailError((prevError) => ({ ...prevError, bccError: "" }));
      }

      fetchIndividualsForEmail()
    }
  }, 500);
  // Handle select change
  const handleSelectChange = (selectedOption, identifier) => {
    let selectedEmail = selectedOption
      .map((item) => item?.label)
      .filter((label) => label.trim() !== "");   // Remove leading/trailing spaces

    const hasInvalidEmail = selectedEmail?.some((email) => !isEmail(email));  // Check if any email is invalid
    selectedEmail = selectedEmail?.filter((email) => isEmail(email));   // Filter out invalid emails

    let formattedSelectedRec = []
    if (selectedEmail?.length > 0) {
      formattedSelectedRec = selectedEmail?.map(email => ({ label: email, value: email })); // set as selected email in select options
    }

    if (identifier === "recipient") {
      setRecipient(selectedEmail);
      setSelectedOptionRecipient(formattedSelectedRec);
      if (hasInvalidEmail) {
        setEmailError((prevError) => ({ ...prevError, recipientError: "Please insert a valid email address!" }));
        setTimeout(() => {
          setEmailError((prevError) => ({ ...prevError, recipientError: "" }));
        }, 4000);
      }
      else {
        setEmailError((prevError) => ({ ...prevError, recipientError: "" }));
      }
    }
    if (identifier === "cc") {
      setCC(selectedEmail);
      setSelectedOptionCC(formattedSelectedRec);
      if (hasInvalidEmail) {
        setEmailError((prevError) => ({ ...prevError, ccError: "Please insert a valid email address!" }));
        setTimeout(() => {
          setEmailError((prevError) => ({ ...prevError, ccError: "" }));
        }, 4000);
      }
      else {
        setEmailError((prevError) => ({ ...prevError, ccError: "" }));
      }
    }
    if (identifier === "bcc") {
      setBCC(selectedEmail);
      setSelectedOptionBCC(formattedSelectedRec);
      if (hasInvalidEmail) {
        setEmailError((prevError) => ({ ...prevError, bccError: "Please insert a valid email address!" }));
        setTimeout(() => {
          setEmailError((prevError) => ({ ...prevError, bccError: "" }))
        }, 4000);
      }
      else {
        setEmailError((prevError) => ({ ...prevError, bccError: "" }));
      }
    }
  };
  // Custom search option
  const customOption = { value: search, label: search };
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : [...searchOptions, customOption];

  // Calculate total attachment size
  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;

    if (attachment && attachment.files) {
      attachment.files.forEach((item) => {
        if (item?.size) {
          totalSize += item?.size;
        }
      });
    }
    if (uploadedFiles && uploadedFiles.files) {
      uploadedFiles.files.forEach((item) => {
        if (item?.size) {
          totalSize += item?.size;
        }
      });
    }
    return totalSize;
  };
  const submitEmail = (e) => {
    e.preventDefault();
    const emailToValidation = Validations({ value: recipient?.length > 0 && recipient?.[0], Validations: { email: true, required: true } });
    if (!emailToValidation.valid) { setEmailError((prevError) => ({ ...prevError, recipientError: recipient?.length === 0 ? "This field is required!" : emailToValidation.error_msg })) }

    const ccToValidation = Validations({ value: cc?.length > 0 ? cc?.[0] : true, Validations: { required: true } });
    if (!ccToValidation.valid) { setEmailError((prevError) => ({ ...prevError, ccError: ccToValidation.error_msg })) }

    const bccToValidation = Validations({ value: bcc?.length > 0 ? bcc?.[0] : true, Validations: { required: true } });
    if (!bccToValidation.valid) { setEmailError((prevError) => ({ ...prevError, bccError: bccToValidation.error_msg })) }

    const SubjectValidation = Validations({ value: templateSub, Validations: { required: true } });
    if (!SubjectValidation.valid) { setSubjectError(SubjectValidation.error_msg) }

    let attachemntDoc = [];
    const totalSize = calculateTotalAttachmentSize();

    if (totalSize > 25 * 1024 * 1024) {
      setEmailError((prevError) => ({ ...prevError, docUploadError: "The file size exceeds the 25 MB limit!" }))
      return;
    } else {
      setEmailError((prevError) => ({ ...prevError, docUploadError: "" }));
    }

    if (uploadedFiles) {
      uploadedFiles.files.map((item) => {
        attachemntDoc.push(item?.file);
      });
    }
    if (emailToValidation?.valid === true && SubjectValidation?.valid === true &&
      ccToValidation?.valid === true && bccToValidation?.valid == true && emailToError?.docUploadError == "") {
      props?.setButtonDisabled(true);
      const payload = {
        // function_assignment_uuid: function_assignment_uuid
        //   ? function_assignment_uuid
        //   : "",
        organisation_uuid: organisation_uuid ? organisation_uuid : "",
        email_to: recipient ? JSON.stringify(recipient) : [],
        email_cc: cc ? JSON.stringify(cc) : [],
        email_bcc: bcc ? JSON.stringify(bcc) : [],
        email_subject: templateSub,
        email_content: templateMessage,
        email_template_uuid: templateUuid ? templateUuid : "",
        email_attach_docs: attachemntDoc,
        email_doc: attachment,
      };
      dispatch(EmailToIndividual(payload))

        .then((response) => {
          if (response?.success) {
            // onDialogClose();
            setDialogAddOpen(true);
            props?.setButtonDisabled(false)
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          }
        })
        .catch((error) => {
        }).finally(() => {
          props?.setButtonDisabled(false)
        })

    }
  };
  const onAddDialogClose = () => {
    props?.onEmailClose();
    onDialogClose()
    setDialogAddOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Dialog
        toOpen={openDialog}
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
      > */}
      {/* <div className="flex border-b  pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={EmailIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Send an email{" "}
            </h5>
            <p className="text-gray-600 ">
              Compose and send an email directly to user
            </p>
          </div>
        </div> */}
      <div className="flex">
        <div className=" bg-gray-100 rounded-bl-xl">
          <div className="overflow-y-auto w-48   h-595">
            <Scrollbars className="border-r border-gray-200">
              <p className="text-gray-900 font-semibold px-6 pt-6 mb-3">
                Templates
              </p>
              <ul className="templates-list pb-2">
                {templateData &&
                  templateData.map((item) => (
                    <li key={item?.email_uuid} className="border-gray-200 border-b">
                      <button onClick={(e) => handleTemplate(e, item)} className="threeDotLine w-full">
                        <Tooltip title={item?.template_name} placement="top">
                          {item?.template_name}
                        </Tooltip>
                      </button>
                    </li>
                  ))}
              </ul>
            </Scrollbars>
          </div>
        </div>
        <div className="pt-6 w-full">
          <div className="overflow-y-auto h-500 px-2">
            <Scrollbars>
              <div className="flex justify-center flex-col w-full mb-4 px-5">
                <label className="text-xs text-medium text-gray-700 mb-1.5">
                  Recipient*{" "}
                </label>
                <div className="flex">
                  <Select
                    type="text"
                    placeholder=""
                    className="w-full h-auto select-options"
                    isClearable={true}
                    isSearchable={true}
                    options={
                      updatedOptions ? updatedOptions : selectedOptionRecipient
                    }
                    value={selectedOptionRecipient}
                    onChange={(e) => handleSelectChange(e, "recipient")}
                    handleSearchChange={(e) =>
                      handleSearchChange(e, "recipient")
                    }
                    isMulti={true}
                  />
                  <div className="flex">
                    <button
                      className={
                        showCC
                          ? "!hidden"
                          : " text-xs text-gray-600 font-semibold ml-3"
                      }
                      onClick={onCcClick}
                    >
                      CC
                    </button>
                    <button
                      className={
                        showBcc
                          ? "!hidden"
                          : "text-xs text-gray-600 font-semibold ml-3"
                      }
                      onClick={onBccClick}
                    >
                      BCC
                    </button>
                  </div>
                </div>
                {emailToError?.recipientError && (
                  <div className="text-red-500 text-sm mt-1">
                    {emailToError?.recipientError}
                  </div>
                )}
              </div>
              <div
                className={
                  !showCC
                    ? "!hidden"
                    : "flex justify-center flex-col w-full mb-4 px-5"
                }
              >
                <label className="text-xs text-medium text-gray-700 mb-1.5">
                  CC{" "}
                </label>
                <div className="flex">
                  <Select
                    type="text"
                    placeholder=""
                    className="w-full h-auto select-options"
                    isClearable={true}
                    isSearchable={true}
                    options={updatedOptions ? updatedOptions : selectedOptionCC}
                    value={selectedOptionCC}
                    onChange={(e) => handleSelectChange(e, "cc")}
                    handleSearchChange={(e) => handleSearchChange(e, "cc")}
                    isMulti={true}
                  />
                  <svg onClick={onCcClick} className="cursor-pointer my-1 py-1" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="m18 6.5-12 12m0-12 12 12" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    </path></svg>
                </div>
                {emailToError?.ccError && (
                  <div className="text-red-500 text-sm mt-1">
                    {emailToError?.ccError}
                  </div>
                )}
              </div>
              <div
                className={
                  !showBcc
                    ? "!hidden"
                    : "flex justify-center flex-col w-full mb-4 px-5"
                }
              >
                <label className="text-xs text-medium text-gray-700 mb-1.5">
                  BCC{" "}
                </label>
                <div className="flex">
                  <Select
                    type="text"
                    placeholder=""
                    className="w-full h-auto select-options"
                    isClearable={true}
                    isSearchable={true}
                    options={updatedOptions ? updatedOptions : selectedOptionBCC}
                    value={selectedOptionBCC}
                    onChange={(e) => handleSelectChange(e, "bcc")}
                    handleSearchChange={(e) => handleSearchChange(e, "bcc")}
                    isMulti
                  />
                  <svg onClick={onBccClick} className="cursor-pointer my-1 py-1" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="m18 6.5-12 12m0-12 12 12" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    </path></svg>
                </div>
                {emailToError?.bccError && (
                  <div className="text-red-500 text-sm mt-1">
                    {emailToError?.bccError}
                  </div>
                )}
              </div>
              <div className="flex justify-center flex-col w-full mb-4 px-5">
                <label className="text-xs text-medium text-gray-700 mb-1.5">
                  Subject*{" "}
                </label>
                <input
                  type="text"
                  placeholder=""
                  value={templateSub}
                  className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={(e) => { setTemplateSub(e.target.value); setSubjectError("") }}
                />
                {subjectError && (
                  <div className="text-red-500 text-sm mt-1">
                    {subjectError}
                  </div>
                )}
              </div>
              <div className="flex justify-center flex-col w-full mb-4 px-5">
                <label className="text-xs text-medium text-gray-700 mb-1.5">
                  Message*{" "}
                </label>
                <RichTextEditor
                  value={templateMessage}
                  onChange={(value) => setTemplateMessage(value)}
                />
              </div>
              <div className="mb-5 px-5">
                <label className="text-xs font-medium block mb-1 mt-3">
                  Attachment
                </label>
                <Upload
                  draggable
                  className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                  onChange={handleFileChange}
                  onFileRemove={handleFileRemove}
                  multiple={true}
                  maxFileSize={25 * 1024 * 1024}
                >
                  <span className="text-blue-700 font-semibold">
                    Click to upload
                  </span>{" "}
                  <span className="font-medium text-gray-600">
                    or drag and drop
                  </span>
                  <p className="text-gray-600">(max.25mb)</p>
                </Upload>
                {emailToError?.docUploadError && (
                  <div className="text-red-500 text-sm mt-1">
                    {emailToError?.docUploadError}
                  </div>
                )}
                {uploadedFiles.files &&
                  uploadedFiles.files.map((item, index) =>
                    item?.email_temp_attachment_uuid ? (
                      <div
                        key={index}
                        className="upload-file flex items-center justify-between mt-2"
                      >
                        <div className="upload-file-left flex items-center">
                          <div className="upload-file-thumbnail">
                            <span className="w-10 h-10 bg-blue-100 border-4 border-blue-50 flex items-center rounded-full justify-center mr-3">
                              <img src={PdfFile} alt="" />
                            </span>
                          </div>
                          <a
                            href={item?.file}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="upload-file-name font-medium text-xs text-gray-700">
                              {item?.fileName}
                            </span>
                          </a>
                        </div>
                        <span
                          className="upload-file-remove w-5"
                          onClick={() => handleFileRemove(item)}
                        >
                          <span className="">
                            <img src={trashIcon} alt="" />
                          </span>
                        </span>
                      </div>
                    ) : (
                      ""
                    )
                  )}
              </div>
            </Scrollbars>
          </div>
          <div className="flex p-6 border-t justify-end border-gray-200">

            <BtnLight
              className="mr-1.5"
              disabled={props?.buttonDisabled}
              onClick={props?.onEmailClose}
            >
              Cancel
            </BtnLight>


            <BtnPrimary disabled={props?.buttonDisabled} className="w-full px-5 mr-3" onClick={submitEmail}>
              {props?.buttonDisabled ? <ThreeDotLoader /> : "Send email"}
            </BtnPrimary>
          </div>
        </div>
      </div>
      {/* </Dialog> */}
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Email sent successfully
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onAddDialogClose()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default Email;
