import React, { useEffect, useState } from "react";
import RichTextEditor from "../../../../../../../common/snippet/RichTextEditor";
import { UpdateInductionModuleData } from "../../../../../../../redux/classes/client-portal/inductionModuleDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { UpdateInductionStatus } from "../../../../../../../redux/classes/client-portal/updateInductionSlice";
const AddedText = (props) => {
  const { InductionModuleData } = useSelector((state) => state);
  const [Pdfdata, Setpdfdata] = useState(InductionModuleData[props.indexData]);
  const dispatch = useDispatch();
  const handleFileChange = (e) => {
    dispatch(UpdateInductionStatus(true))
    if (Pdfdata.content_info !== e) {
      Setpdfdata({ ...Pdfdata, content_info_type: "text", content_info: e });
    }
  };

  useEffect(() => {
    dispatch(
      UpdateInductionModuleData({
        indexData: props.indexData,
        moduleData: Pdfdata,
      })
    );
  }, [Pdfdata]);
  return (
    <React.Fragment>
      <RichTextEditor
        onChange={handleFileChange}
        value={Pdfdata.content_info}
        placeholder="Enter text for module..."
      />
    </React.Fragment>
  );
};
export default AddedText;
