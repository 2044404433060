import React, { useState } from "react"
import Segment from "../../../../../../common/element/Segment";
import { Link } from "react-router-dom";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
const ClientDetails = () => {
   const [dialogIsOpen, setIsOpen] = useState(false)
   const [unSaveDialogIsOpen, unSaveSetIsOpen] = useState(false)
   const openDialog = () => {
      setIsOpen(true)
   }
   const onDialogClose = (e) => {
      setIsOpen(false)
   }
   const openDialogUnSave = () => {
      unSaveSetIsOpen(true)
   }
   const onDialogCloseUnSave = (e) => {
      unSaveSetIsOpen(false)
   }
   return (
      <React.Fragment>
         <div className="flex items-center border-b border-gray-200 pb-5 mb-5">
            <label className="text-gray-700 font-semibold w-2/12">Status</label>
            <div className="flex items-center">
               <span className="bg-success-50 text-success-700 flex items-center px-2 py-0.5 rounded-full font-medium"><span className="w-2 h-2 bg-success-500 block rounded-full mr-0.5"></span>Active</span>
               <span className="text-gray-600 text-xs font-semibold ml-2">Make inactive</span>
            </div>
         </div>
         <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
            <label className="text-gray-700 font-semibold w-2/12">Company logo</label>
            <div className="flex items-center">
               <span className="w-28 h-28 rounded-full bg-gray-100"></span>
               <div className="flex items-center justify-center  w-96 upload-img ml-8">
                  <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white">
                     <div className="flex flex-col items-center justify-center">
                        <div className="rounded-full border-12 circle m-auto flex align-center justify-center bg-gray-200 border-gray-50 mb-3 border-6 w-10 h-10"><img src="/assets/icons-svg/upload.svg" alt="" className="w-4" /></div>
                        <p className="mb-2  text-gray-600 darks:text-gray-400"><span className="font-semibold text-blue-700">Click to upload</span><span className="text-xs"> or drag and drop</span></p>
                        <p className=" text-gray-600 darks:text-gray-400 font-inter">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                     </div>
                     <input id="dropzone-file" type="file" className="hidden" />
                  </label>
               </div>
            </div>
         </div>
         <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
            <label className="text-gray-700 font-semibold w-2/12">Business type*</label>
            <div className="flex items-center docType-btn">
               <Segment>
                  <Segment.Item value="Pty"><span>Pty Ltd</span></Segment.Item>
                  <Segment.Item value="Sole"><span>Sole Trader</span></Segment.Item>
                  <Segment.Item value="Other"><span>Other</span></Segment.Item>
               </Segment>
            </div>
         </div>
         <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
            <label className="text-gray-700 font-semibold w-2/12">Business name*</label>
            <div className="flex items-center w-9/12">
               <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="Business name here" />
            </div>
         </div>
         <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
            <label className="text-gray-700 font-semibold w-2/12">ABN/NZBN*</label>
            <div className="flex items-center w-9/12">
               <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="00-000-000-000" />
            </div>
         </div>
         <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
            <label className="text-gray-700 font-semibold w-2/12">Business Address*</label>
            <div className="flex items-center w-9/12 flex-wrap">
               <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5" id="email" type="text" placeholder="Street Address*" />
               <div className="flex w-full">
                  <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-1 mb-5" id="email" type="text" placeholder="Apartment, suite, etc (optional)" />
                  <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-1 mb-5" id="email" type="text" placeholder="Suburb*" />
               </div>
               <div className="flex w-full">
                  <select className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline">
                     <option>Country</option>
                  </select>
                  <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-1 mb-5" id="email" type="text" placeholder="Postcode*" />
               </div>
               <select className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline mr-1 ">
                  <option>State*</option>
               </select>
            </div>
         </div>
         <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
            <label className="text-gray-700 font-semibold w-2/12">Mailing Address</label>
            <div className="flex items-center w-9/12 flex-wrap">
               <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-5" id="email" type="text" placeholder="Street Address*" />
               <div className="flex w-full">
                  <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-1 mb-5" id="email" type="text" placeholder="Apartment, suite, etc (optional)" />
                  <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-1 mb-5" id="email" type="text" placeholder="Suburb*" />
               </div>
               <div className="flex w-full">
                  <select className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline">
                     <option>Country</option>
                  </select>
                  <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-1 mb-5" id="email" type="text" placeholder="Postcode*" />
               </div>
               <select className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-2/4 py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline mr-1 ">
                  <option>State*</option>
               </select>
            </div>
         </div>
         <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
            <label className="text-gray-700 font-semibold w-2/12">Website</label>
            <div className="flex items-center w-9/12">
               <input className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-r-0 rounded-tr-none rounded-br-none" id="email" type="text" value="www.sitename.com" />
               <Link to="#" className="text-gray-700 font-semibold border border-gray-100 bg-white w-24  flex h-10 justify-center items-center rounded-lg gap-1 rounded-tl-none rounded-bl-none hover:text-gray-900 focus:text-gray-600">
                  <svg width="16" height="15" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7" stroke="#000" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  Open</Link>
            </div>
         </div>
         <div className="flex justify-end items-center pb-10">
            <BtnLight onClick={() => openDialogUnSave()} className="w-auto px-4">Cancel</BtnLight>
            <SmallButton onClick={() => openDialog()} className="w-auto px-4 ml-3">Save</SmallButton>
         </div>
         <Dialog
            isOpen={dialogIsOpen}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
            bodyOpenClassName="overflow-hidden"
            className="customAlert"
         >
            <div className="px-6 pt-6">
               <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </span>
               <h4 className="text-base text-gray-900 font-semibold text-center mt-4">Are you sure you want to <br />make this Client inactive?</h4>
               <p className=" text-gray-600 text-center mt-1">
                  This client will no longer have access to the app.
               </p>
            </div>
            <div className='mt-8 flex justify-between pb-6 px-6'>
               <BtnLight onClick={() => onDialogClose()} className='mr-1.5' >Cancel</BtnLight>
               <SmallButton className='ml-1.5'>Confirm</SmallButton>
            </div>
         </Dialog>
         <Dialog
            isOpen={unSaveDialogIsOpen}
            onClose={onDialogCloseUnSave}
            onRequestClose={onDialogCloseUnSave}
            bodyOpenClassName="overflow-hidden"
            className="customAlert"
         >
            <div className="px-6 pt-6">
               <span className="w-12 h-12 rounded-full m-auto bg-warning-100 flex items-center justify-center">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M7.5 3.5v3.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C8.26 8.5 8.54 8.5 9.1 8.5h6.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437c.109-.214.109-.494.109-1.054V4.5m0 17v-6.4c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437c-.214-.109-.494-.109-1.054-.109H9.1c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C7.5 14.26 7.5 14.54 7.5 15.1v6.4m14-11.675V16.7c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327H8.3c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3.5 19.22 3.5 18.38 3.5 16.7V8.3c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C5.78 3.5 6.62 3.5 8.3 3.5h6.874c.49 0 .734 0 .965.055.204.05.399.13.578.24.201.123.374.296.72.642l3.126 3.126c.346.346.519.519.642.72.11.18.19.374.24.579.055.23.055.474.055.963z" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </span>
               <h4 className="text-base text-gray-900 font-semibold text-center mt-4">You have unsaved changes</h4>
               <p className=" text-gray-600 text-center mt-1">
                  Save your information before leaving?
               </p>
            </div>
            <div className='mt-8 flex justify-between pb-6 px-6'>
               <BtnLight onClick={() => onDialogCloseUnSave()} className='mr-1.5' >Exit</BtnLight>
               <SmallButton className='ml-1.5'>Save</SmallButton>
            </div>
         </Dialog>
      </React.Fragment>
   )
}
export default ClientDetails