// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const refetchAllInductions = createSlice({
  name:"refetchAllInductionsData",
  initialState:false,
  reducers:{
    refetchAllInductionsData(state,action){
      return action.payload;
    },
  }
}) 
export default refetchAllInductions.reducer;
export const {refetchAllInductionsData}=refetchAllInductions.actions;