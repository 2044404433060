import React, { useState, useEffect, useCallback } from "react";
import ClientInformation from "./ClientInformation";
import Billing from "./Billing";
import Inductions from "./Indunctions";
import { connect, useDispatch, useSelector } from "react-redux";
import ComplianceChecklist from "./ComplianceChecklist";
import TotalContractors from "./TotalContractors";
import Dialog from "../../../../../../common/element/Dialog";
import * as userActions from "../../../../../../actions/index";
import { useParams } from "react-router-dom";
import ClientDetails from "../../Dialog/Clients/ClientSettings/ClientDetails";
import ValidationCriteria from "../../SpecificClientTabs/ValidationCriteria";
import SettingIcon from "../../../../../../assets/icons-svg/setting.svg";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { DashboardClientCompanyInductions } from "../../../../../../actions/Admin-Client/AllSiteApi";
const Overview = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [userSetting, showUserSetting] = useState(false);
  const [clientDataName, setClientDataName] = useState([])
  const [editBtnHide, setEditBtnHide] = useState(false)

  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_uuid;
  if (loginData) {
    role_assignment_uuid = loginData?.data?.role_assignment_uuid;
  }
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "View All Clients", url: "/admin/client/view-all-clients", home: "/admin/client/dashboard" },
        {
          label: props?.clientData?.trading_name,
          url: `/admin/client/view-all-clients/specific-client/${id}`,
        },
        {
          label: "Overview",
          url: `/admin/client/view-all-clients/specific-client/${id}`,
        },
      ])
    )
  }, [props?.clientData]);

  const openAddDialog = () => {
    setAddOpen(true);
  };
  const onAddDialogClose = (e) => {
    setAddOpen(false);
  };
  // Hide the user settings dialog
  const userSettingHide = () => {
    setTimeout(() => {
      // showUserSetting(false);
      setAddOpen(false);
    }, 0);
    setAddOpen(false);
  };

  const GetSpecificUserRole = useCallback(async () => {
    if (role_assignment_uuid) {
      dispatch(userActions.GetSpecificUserRole(role_assignment_uuid)).then(
        (response) => {
          if (response?.success === true) {
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    GetSpecificUserRole();
  }, []);

  const fetchClientCompanyInductions = useCallback(async (function_assignment_uuid) => {
    try {
      const response = await dispatch(DashboardClientCompanyInductions(function_assignment_uuid));
      if (response.success) {
        const arrayData = response?.data;
        setClientDataName(arrayData);
        setEditBtnHide(true)
      } else if (response?.status === 401) {
        handleUnauthorizedAccess();
      } else {
        // Handle other errors
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (props?.clientData?.function_assignment_uuid) {
      fetchClientCompanyInductions(props?.clientData.function_assignment_uuid);
    }
  }, [props?.clientData?.function_assignment_uuid]);
  return (
    <div className="grid grid-container gap-8">
      <div className="flex gap-8 flex-col">
        <ClientInformation
          clientData={props?.clientData}
          openAddDialog={openAddDialog}
        />
        <Billing setDefaultTab={props?.setDefaultTab} />
      </div>

      <div className="flex gap-8 flex-col">
        <TotalContractors clientData={props?.clientData} setDefaultTab={props?.setDefaultTab} />
        <ComplianceChecklist changeTabVal={props.changeTabVal} setDefaultTab={props?.setDefaultTab} />
        <Inductions clientDataName={clientDataName} clientData={props?.clientData} setDefaultTab={props?.setDefaultTab} editBtnHide={editBtnHide} />
      </div>

      <Dialog
        isOpen={dialogAddOpen}
        onClose={userSettingHide}
        onRequestClose={userSettingHide}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b  pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={SettingIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Settings
            </h5>
          </div>
        </div>
        <div className="pt-2 px-6">
          <ClientDetails GetClientOverviewProfileById={props?.GetClientOverviewProfileById} userSettingHide={userSettingHide} />
        </div>
      </Dialog>
      {/* <Dialog
        isOpen={dialogAddOpen}
        onClose={userSettingHide}
        onRequestClose={userSettingHide}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b  pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={SettingIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Settings
            </h5>
          </div>
        </div>
        <div className="pt-2 px-6">
          <ValidationCriteria userSettingHide={userSettingHide} />
        </div>
      </Dialog> */}
    </div>
  );
};
export default Overview;
