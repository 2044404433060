import React, { useState, useEffect } from 'react';
import Heading from '../../../../../common/element/Heading';
import { setBreadcrumbs } from '../../../../../redux/classes/breadcrumbsSlice';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const AccessNot = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [forceRender, setForceRender] = useState(false);
    const permissionDataUpdate = useSelector((state) => state?.permissions?.permissionData);

    useEffect(() => {
        dispatch(setBreadcrumbs([{ label: "No Access", url: "/client/access" }]));
    }, [dispatch]);

    return (
        <React.Fragment>
            <Heading>No Access</Heading>
            <p className="text-gray-600">
                You do not have the required permissions. If you suspect this is a mistake, please contact your administrator for assistance.
            </p>
        </React.Fragment>
    );
};

export default AccessNot;
