import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Table from "../../../../../../../common/element/Table";
const ContactsList = (props) => {
  const { Tr, Th, Td, THead, TBody } = Table;
  return (
    <React.Fragment>
      <div className="overflow-x-auto">
        <div className="w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg border-gray-200">
            <Table className="min-w-full divide-y divide-gray-200">
              <THead className="bg-gray-100">
                <Tr>
                  <Th
                    scope="col"
                    className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 w-1/5"
                  >
                    <span className='flex items-center gap-1' onClick={() => props?.handleSort("first_name")}>Contact name
                      <svg
                        className={`transform ${props?.filtersort === "first_name" && props?.filterorder === "asc"
                          ? "rotate-180"
                          : ""
                          }`}
                        width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round" />
                      </svg></span>
                  </Th>
                  <Th
                    scope="col"
                    className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 w-1/5"
                  >
                    <span className='flex items-center gap-1' onClick={() => props?.handleSort("job_title")}>Job title
                      <svg
                        className={`transform ${props?.filtersort === "job_title" && props?.filterorder === "asc"
                          ? "rotate-180"
                          : ""
                          }`}
                        width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round" />
                      </svg></span>
                  </Th>
                  <Th
                    scope="col"
                    className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600">Email address
                  </Th>
                  <Th
                    scope="col"
                    className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 "
                  >
                    <span className='flex items-center gap-1'>Phone number</span>
                  </Th>
                </Tr>
              </THead>
              <TBody className="divide-y divide-gray-200">
                {props?.allListData && props?.allListData.map((item, index) => (
                  <Tr key={index}>
                    <Td className="px-4 py-2 font-medium text-gray-900 ">
                      <span>{`${item?.first_name ? item?.first_name : "-"} ${item?.last_name ? item?.last_name : ""}`}</span>
                    </Td>
                    <Td className="px-4 py-2 font-medium text-gray-900">
                      <span className="text-gray-900 font-medium block">{item?.job_title ? item?.job_title : "-"}</span>
                    </Td>
                    <Td className="px-4 py-2 font-medium text-gray-900">{item?.email ? item?.email : "-"}</Td>
                    <Td className="px-4 py-2 font-medium text-gray-900">{item?.phone ? item?.phone : "-"}</Td>
                  </Tr>
                ))}

              </TBody>
            </Table>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}
export default ContactsList;
