// permissionsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  permissionData: null,
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissionData(state, action) {
      state.permissionData = action.payload;
    },
  },
});

export const { setPermissionData } = permissionsSlice.actions;

export default permissionsSlice.reducer;
