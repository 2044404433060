import axios from "axios";
import * as actionType from "../../constant/actionType";
export const VerifyEmail = (data) => async (dispatch) => {
    try {
      let formdata = new FormData();
      for (let key in data) {
          formdata.append(key, data[key]);
      }
      const response = await axios({
        method: "patch",
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/VerifyEmail`,
      });
      return response.data;
    } catch (error) {}
  };