import React, { useState, useEffect } from "react";
import Table from "../../../../../common/element/Table";
import Button from "../../../../../common/element/Buttons";
import Tag from "../../../../../common/element/Tag";
import Dropdown from "../../../../../common/element/Dropdown";
import Checkbox from "../../../../../common/element/Checkbox";
const { Tr, Th, Td, THead, TBody } = Table
const InvoiceList = () => {
    const [mainCheck, setMainCheck] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [filtersort, setFiltersort] = useState("invoice_no");
    const [filterorder, setFilterOrder] = useState("desc");

    const getData = [
        { client_uuid: 1, invoiceNumber: "000000001", recipient: "Recipient A", amount: "$100.00", billingData: "01/01/2022", paymentData: "05/01/2022", status: "Paid" },
        { client_uuid: 2, invoiceNumber: "000000002", recipient: "Recipient B", amount: "$200.00", billingData: "02/01/2022", paymentData: "-", status: "Overdue" },
        { client_uuid: 3, invoiceNumber: "000000003", recipient: "Recipient C", amount: "$300.00", billingData: "03/01/2022", paymentData: "-", status: "Upcoming" },
    ];

    const onCheckboxChange = (e, item) => {
        if (item === "mainCheck") {
            const isChecked = e;
            setMainCheck(isChecked);

            if (isChecked) {
                setCheckedItems(getData);
            } else {
                setCheckedItems([]);
            }
        } else {
            const isChecked = e;
            const existingIndex = checkedItems.findIndex(ele => ele.client_uuid === item.client_uuid);

            if (isChecked && existingIndex === -1) {
                setCheckedItems(prevItems => [...prevItems, item]);
            } else if (!isChecked && existingIndex !== -1) {
                setCheckedItems(prevItems => {
                    const updatedItems = [...prevItems];
                    updatedItems.splice(existingIndex, 1);
                    return updatedItems;
                });
            }
        }
    };
    const handleSort = (column) => {
        // Handle sorting of the service list
        if (column === filtersort) {
          setFilterOrder(filterorder === "asc" ? "desc" : "asc");
        } else {
          setFiltersort(column);
          setFilterOrder(filterorder === "asc" ? "desc" : "asc");
        }
      };
    return (
        <React.Fragment>
            <div className="border rounded-lg border-gray-200">
                <Table>
                    <THead>
                        <Tr>
                            <Th>
                                <Checkbox className="cursor-pointer"
                                    checked={checkedItems.length === getData.length ? mainCheck : false}
                                    onChange={(e) => onCheckboxChange(e, "mainCheck")}
                                />
                            </Th>
                            <Th>
                                <div className="flex gap-1">
                                    <span className="flex items-center gap-1"
                                        onClick={() => handleSort("invoice_no")}>
                                        Invoice Number
                                        <svg 
                                         className={`transform ${filtersort === "invoice_no" && filterorder === "asc"
                                         ? "rotate-180"
                                         : ""
                                         }`}
                                        width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </span>
                                </div>
                            </Th>
                            <Th>Recipient</Th>
                            <Th>Billing date</Th>
                            <Th>Amount</Th>
                            <Th>Status</Th>
                            <Th>Payment data</Th>
                            <Th></Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {getData.map((item) => (
                            <Tr key={item?.client_uuid}>
                                <Td>
                                    <Checkbox className="cursor-pointer"
                                        checked={checkedItems?.some(checkedItem => checkedItem?.client_uuid === item?.client_uuid)}
                                        onChange={(e) => onCheckboxChange(e, item)}
                                    />
                                </Td>
                                <Td>{item?.invoiceNumber}</Td>
                                <Td>{item?.recipient}</Td>
                                <Td>{item?.billingData}</Td>
                                <Td>{item?.amount}</Td>
                                <Td>
                                    {item.status === "Paid" && <Tag className="bg-success-50 text-success-700"><span>
                                        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.39355 0.981445L3.89355 6.48145L1.39355 3.98145" stroke="#3DB77E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span> Paid</Tag>}
                                    {item.status === "Overdue" && <Tag className="bg-error-50 text-error-700"><span>
                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_7446_51239)">
                                                <path d="M6.39355 4.98145V6.98145M6.39355 8.98145H6.39855M1.39355 5.24282V8.72007C1.39355 8.84237 1.39355 8.90352 1.40737 8.96106C1.41962 9.01208 1.43982 9.06085 1.46723 9.10559C1.49816 9.15605 1.54139 9.19928 1.62787 9.28576L4.08924 11.7471C4.17572 11.8336 4.21895 11.8768 4.26941 11.9078C4.31415 11.9352 4.36292 11.9554 4.41394 11.9676C4.47148 11.9814 4.53263 11.9814 4.65493 11.9814H8.13218C8.25448 11.9814 8.31563 11.9814 8.37317 11.9676C8.42419 11.9554 8.47296 11.9352 8.5177 11.9078C8.56816 11.8768 8.61139 11.8336 8.69787 11.7471L11.1592 9.28576C11.2457 9.19928 11.289 9.15605 11.3199 9.10559C11.3473 9.06085 11.3675 9.01208 11.3797 8.96106C11.3936 8.90352 11.3936 8.84237 11.3936 8.72007V5.24282C11.3936 5.12052 11.3936 5.05937 11.3797 5.00183C11.3675 4.95081 11.3473 4.90204 11.3199 4.8573C11.289 4.80684 11.2457 4.76361 11.1592 4.67713L8.69787 2.21576C8.61139 2.12928 8.56816 2.08605 8.5177 2.05513C8.47296 2.02771 8.42419 2.00751 8.37317 1.99526C8.31563 1.98145 8.25448 1.98145 8.13218 1.98145H4.65493C4.53263 1.98145 4.47148 1.98145 4.41394 1.99526C4.36292 2.00751 4.31415 2.02771 4.26941 2.05513C4.21895 2.08605 4.17572 2.12928 4.08924 2.21576L1.62787 4.67713C1.54139 4.76361 1.49816 4.80684 1.46723 4.8573C1.43982 4.90204 1.41962 4.95081 1.40737 5.00183C1.39355 5.05937 1.39355 5.12052 1.39355 5.24282Z" stroke="#F04438" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_7446_51239">
                                                    <rect width="12" height="12" fill="white" transform="translate(0.393555 0.981445)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span> Overdue</Tag>}
                                    {item.status === "Upcoming" && <Tag className="bg-warning-50 text-warning-700"><span>
                                        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.39355 7.98145V6.98145M5.39355 3.98145H5.39855M9.39355 6.98145C9.39355 9.19066 7.60276 10.9814 5.39355 10.9814C3.18434 10.9814 1.39355 9.19066 1.39355 6.98145C1.39355 4.77224 3.18434 2.98145 5.39355 2.98145C7.60276 2.98145 9.39355 4.77224 9.39355 6.98145Z" stroke="#DC6803" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span> Upcoming</Tag>}
                                </Td>
                                <Td>{item?.paymentData}</Td>
                                <Td>
                                    <Button className="px-0 py-0" variant="text">
                                        <span>
                                            <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.39355 8.98145C2.81977 8.98145 3.14355 8.65766 3.14355 8.23145C3.14355 7.80524 2.81977 7.48145 2.39355 7.48145C1.96734 7.48145 1.64355 7.80524 1.64355 8.23145C1.64355 8.65766 1.96734 8.98145 2.39355 8.98145Z" fill="#98A2B3" />
                                                <path d="M2.39355 4.98145C2.81977 4.98145 3.14355 4.65766 3.14355 4.23145C3.14355 3.80524 2.81977 3.48145 2.39355 3.48145C1.96734 3.48145 1.64355 3.80524 1.64355 4.23145C1.64355 4.65766 1.96734 4.98145 2.39355 4.98145Z" fill="#98A2B3" />
                                                <path d="M2.39355 12.9814C2.81977 12.9814 3.14355 12.6577 3.14355 12.2314C3.14355 11.8052 2.81977 11.4814 2.39355 11.4814C1.96734 11.4814 1.64355 11.8052 1.64355 12.2314C1.64355 12.6577 1.96734 12.9814 2.39355 12.9814Z" fill="#98A2B3" />
                                            </svg>
                                        </span>
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </TBody>
                </Table>
            </div>

            {checkedItems?.length > 0 && (
                <>
                    <div className="font-semibold my-5">
                        <ul
                            className="flex items-center justify-center w-[425px] h-[56px] rounded-[110px] p-2 mx-auto my-auto"
                            style={{ boxShadow: "0px 0px 24px 0px rgb(214, 221, 237)" }}
                        >
                            <li
                                className="p-2"
                                style={{ color: "#5786CC", position: "relative" }}
                            >
                                <Button>{`${checkedItems?.length} selected`}</Button>
                                <div
                                    className="absolute right-0 top-1/2 transform -translate-y-1/2"
                                    style={{
                                        width: "2px",
                                        height: "36px",
                                        backgroundColor: "#F2F4F7",
                                    }}
                                />
                            </li>
                            <li className="p-2 relative flex gap-1 cursor-pointer">

                                <div
                                    className="absolute right-0 top-1/2 transform -translate-y-1/2"
                                    style={{
                                        width: "2px",
                                        height: "36px",
                                        backgroundColor: "#F2F4F7",
                                    }}
                                />
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5 8.42969L17.5 3.42969M17.5 3.42969H12.5M17.5 3.42969L10.8333 10.0964M8.33333 5.09635H6.5C5.09987 5.09635 4.3998 5.09635 3.86502 5.36884C3.39462 5.60852 3.01217 5.99097 2.77248 6.46138C2.5 6.99616 2.5 7.69622 2.5 9.09635V14.4297C2.5 15.8298 2.5 16.5299 2.77248 17.0647C3.01217 17.5351 3.39462 17.9175 3.86502 18.1572C4.3998 18.4297 5.09987 18.4297 6.5 18.4297H11.8333C13.2335 18.4297 13.9335 18.4297 14.4683 18.1572C14.9387 17.9175 15.3212 17.5351 15.5608 17.0647C15.8333 16.5299 15.8333 15.8298 15.8333 14.4297V12.5964" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <span>Open</span>


                            </li>
                            <li className="p-2 flex gap-1 cursor-pointer">
                                <div
                                    className="absolute right-0 top-1/2 transform -translate-y-1/2"
                                    style={{
                                        width: "2px",
                                        height: "36px",
                                        backgroundColor: "#F2F4F7",
                                    }}
                                />
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5 11.4297V12.4297C16.5 13.8298 16.5 14.5299 16.2275 15.0647C15.9878 15.5351 15.6054 15.9175 15.135 16.1572C14.6002 16.4297 13.9001 16.4297 12.5 16.4297H5.5C4.09987 16.4297 3.3998 16.4297 2.86502 16.1572C2.39462 15.9175 2.01217 15.5351 1.77248 15.0647C1.5 14.5299 1.5 13.8298 1.5 12.4297V11.4297M13.1667 7.26302L9 11.4297M9 11.4297L4.83333 7.26302M9 11.4297V1.42969" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <span>Download</span>


                            </li>
                        </ul>
                    </div>
                </>
            )}

        </React.Fragment>
    )
}

export default InvoiceList