import React, { useCallback, useEffect, useState } from "react";
import Table from "../../../../../../common/element/Table";
import Tag from "../../../../../../common/element/Tag";
import { useSelector } from "react-redux";
import Tooltip from "../../../../../../common/element/Tooltip";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import Tabs from "../../../../../../common/element/Tabs";
import Documents from "./Documents";
import ClientDetails from "./ClientDetails";
import ConnectedWorkers from "./ConnectedWorkers";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Button from "../../../../../../common/element/Buttons";
import { reject } from "lodash";
import moment from "moment";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import { GetAllAssignedChecklistOfProvider } from "../../../../../../actions/client-portal/AllSiteApi";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Checkbox from "../../../../../../common/element/Checkbox";
const { TabNav, TabList, TabContent } = Tabs;
const ClientsList = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [clientData, setClientData] = useState(null);
  const [indivisualData, setIndivisualData] = useState(null);
  const { Tr, Th, Td, THead, TBody } = Table;

  let { AllClientsOfAdminProvider } = useSelector((state) => state);
  useEffect(() => {
    setClientData(AllClientsOfAdminProvider?.data?.data?.rows);
  }, [AllClientsOfAdminProvider]);
  const [checklistIds, setChecklistIds] = useState([]);
  const [removeChecklistIds, setRemoveChecklistIds] = useState([]);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [providerCompCheckList, setProviderCompCheckList] = useState([]);
  const openDialog = (item) => {
    setIndivisualData(item);
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  const onDialogOk = (e) => {
    setIsOpen(false);
  };

  // CheckList code
  const OnGetAllAssignedChecklistOfProvider = useCallback(async () => {
    if (true) {
      try {
        const payload = {
          provider_org_uuid: id,
          client_org_uuid: indivisualData?.organisation_uuid,
        };
        const response = await dispatch(
          GetAllAssignedChecklistOfProvider(payload)
        );
        const { success, data, status } = response;
        if (success) {
          const arrayData = data?.data;
          // setAlreadyAssigned(rows); // Assuming `rows` is what you need to set
          setProviderCompCheckList(arrayData);
          const alreadyAssignedChecklist = arrayData.filter(
            (ele) =>
              ele?.compAssigns && Object.keys(ele?.compAssigns)?.length > 0
          );
          setCheckedItems(alreadyAssignedChecklist);
        } else if (status === 401) {
          // handleUnauthorizedAccess(); // Uncomment and define this if needed
        } else {
          console.error("Failed to fetch compliance checklist:", response);
          // Handle other types of errors or unsuccessful responses
        }
      } catch (error) {
        console.error("Failed to fetch compliance checklist:", error);
        // Handle unexpected errors
      }
    }
  }, [dispatch, id, indivisualData?.organisation_uuid]);

  useEffect(() => {
    // OnGetAllAssignedChecklistOfProvider();
  }, [id, indivisualData?.organisation_uuid]);

  const onCheckboxChange = (isChecked, item) => {
    const existingIndex = checkedItems.findIndex(
      (ele) => ele.checklist_uuid === item.checklist_uuid
    );

    if (isChecked && existingIndex === -1) {
      // Item is checked (added)
      setCheckedItems((prevItems) => [...prevItems, item]);
      setChecklistIds((prevIds) => [...prevIds, item.checklist_uuid]);
      setRemoveChecklistIds((prevIds) =>
        prevIds.filter((id) => id !== item.checklist_uuid)
      );
    } else if (!isChecked && existingIndex !== -1) {
      // Item is unchecked (removed)
      setCheckedItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(existingIndex, 1);
        return updatedItems;
      });
      setRemoveChecklistIds((prevIds) => [...prevIds, item.checklist_uuid]);
      setChecklistIds((prevIds) =>
        prevIds.filter((id) => id !== item.checklist_uuid)
      );
    }
  };

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() =>
                    props.handleSort(
                      props.getAllinvitedData.type == "clientContact"
                        ? "contact_first_name"
                        : "first_name"
                    )
                  }
                >
                  Status
                  <Tooltip
                    title="Invited: Registered: Active:"
                    placement="top-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_7297_80902)">
                        <path
                          d="M6.05992 5.99992C6.21665 5.55436 6.52602 5.17866 6.93322 4.93934C7.34042 4.70002 7.81918 4.61254 8.2847 4.69239C8.75022 4.77224 9.17246 5.01427 9.47664 5.3756C9.78081 5.73694 9.94729 6.19427 9.94659 6.66659C9.94659 7.99992 7.94659 8.66659 7.94659 8.66659M7.99992 11.3333H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                          stroke="#98A2B3"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7297_80902">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Tooltip>
                </span>
              </Th>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => props.handleSort("trading_name")}
                >
                  Client name
                  <svg
                    className={`transform ${
                      props.getAllinvitedData.order != "asc" ? "rotate-180" : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-96">
                <span className="flex items-center gap-1">Email</span>
              </Th>
              <Th className="w-44">
                <span className="flex items-center gap-1">Phone number</span>
              </Th>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() =>
                    props.handleSort(
                      props.getAllinvitedData.type == "clientContact"
                        ? "contact_first_name"
                        : "first_name"
                    )
                  }
                >
                  Documents
                  <Tooltip
                    title="Invited: Registered: Active:"
                    placement="top-start"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_7297_80902)">
                        <path
                          d="M6.05992 5.99992C6.21665 5.55436 6.52602 5.17866 6.93322 4.93934C7.34042 4.70002 7.81918 4.61254 8.2847 4.69239C8.75022 4.77224 9.17246 5.01427 9.47664 5.3756C9.78081 5.73694 9.94729 6.19427 9.94659 6.66659C9.94659 7.99992 7.94659 8.66659 7.94659 8.66659M7.99992 11.3333H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                          stroke="#98A2B3"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_7297_80902">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Tooltip>
                </span>
              </Th>
            </Tr>
          </THead>
          <TBody>
            {clientData?.map((item, index) => {
              let status;
              // let assigned;
              const approvalStatusArray = item?.orgChklists?.flatMap((item) =>
                item?.compDocs
                  ?.filter((doc) => doc)
                  ?.map((doc) =>
                    doc.DocAppr !== null
                      ? doc.DocAppr.approval_status
                      : "pending"
                  )
              );

              const assignedStatusArray = item?.orgChklists?.flatMap((item) =>
                item?.compDocs
                  ?.filter((doc) => doc)
                  ?.map((doc) =>
                    doc.DocAppr !== null ? doc.DocAppr.approval_status : "null"
                  )
              );

              const reviewed_date = item?.orgChklists?.flatMap((item) =>
                item?.compDocs
                  ?.filter((doc) =>
                    doc?.DocAppr?.reviewed_date
                      ? doc.DocAppr?.reviewed_date !== "null" &&
                        doc?.DocAppr?.reviewed_date !== null
                      : ""
                  )
                  ?.map(
                    (doc) =>
                      doc.DocAppr !== null &&
                      doc.DocAppr !== "null" &&
                      doc.DocAppr.reviewed_date
                  )
              );

              // Convert date strings to Date objects
              // const latestDate = new Date(
              //   Math.max(...reviewed_date?.map((dateString) => new Date(dateString)))
              // );
              // if (
              //   assignedStatusArray.every((status) => status === "admin_reject")
              // ) {
              //   assigned = "Rejected";
              // } else if (
              //   assignedStatusArray.every((status) => status === "approved")
              // ) {
              //   assigned = moment(latestDate).format("DD/MM/YYYY")
              // }
              //  else if (assignedStatusArray.includes("null") || assignedStatusArray.includes("client_approval_req")) {
              //   assigned = "Unassigned";
              // }

              if (approvalStatusArray?.includes("client_approval_req")) {
                status = "Action required";
              } else if (
                approvalStatusArray?.length == 2 &&
                approvalStatusArray?.includes("approved") &&
                approvalStatusArray?.includes("client_approved_action")
              ) {
                status = "Approved";
              } else if (
                approvalStatusArray?.every(
                  (status) => status === "admin_approved"
                )
              ) {
                status = "Approved";
              } else if (
                approvalStatusArray?.every(
                  (status) => status === "client_approved"
                )
              ) {
                status = "Approved";
              } else if (
                approvalStatusArray?.every((status) => status === "approved")
              ) {
                status = "Approved";
              } else if (
                approvalStatusArray?.every(
                  (status) => status === "client_approved_action"
                )
              ) {
                status = "Approved";
              } else if (approvalStatusArray?.includes("admin_reject")) {
                status = "Action required";
              } else if (approvalStatusArray?.includes("client_reject")) {
                status = "Action required";
              } else if (
                approvalStatusArray?.every((status) => status === "pending")
              ) {
                status = "Awaiting review";
              } else if (
                approvalStatusArray?.every((status) => status === null)
              ) {
                status = "Awaiting review";
              } else {
                status = "Action required";
              }

              return (
                <Tr key={index}>
                  <Td>
                    <Tag
                      className={`${
                        item?.docs_status == "Compliant"
                          ? "tag text-success-700 bg-success-50"
                          : "text-error-700 bg-error-50"
                      }`}
                    >
                      {item?.docs_status}
                    </Tag>
                  </Td>
                  <Td>{item.trading_name}</Td>
                  <Td>{item?.provClientCont?.contact_email}</Td>
                  <Td>
                    <span className="block w-full text-gray-900">
                      {" "}
                      {item?.provClientCont?.contact_phone
                        ? item?.provClientCont?.contact_phone
                        : "-"}
                    </span>
                    <span className="block w-full text-gray-600">
                      {item?.provClientCont?.contact_first_name
                        ? item?.provClientCont?.contact_first_name +
                          " " +
                          item?.provClientCont?.contact_last_name
                        : "-"}
                    </span>
                  </Td>
                  {/* <Td>{assigned}</Td> */}
                  <Td>
                    <Button onClick={() => openDialog(item)}>
                      <Tag
                        className={`${
                          status == "Action required"
                            ? "badge-wrapper bg-error-100 text-error-700"
                            : status ==
                              "badge-wrapper bg-blue-100 text-blue-700"
                            ? "badge-wrapper bg-blue-100 text-blue-700"
                            : "text-success-700 bg-success-50"
                        }`}
                      >
                        {status}
                      </Tag>
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.818 7.48905L20.818 7.48906C21.2151 7.55537 21.5063 7.89888 21.5063 8.30132V21.3249C21.5063 21.7796 21.1378 22.1484 20.6828 22.1484H14.8945C14.4399 22.1484 14.0711 21.7793 14.0711 21.3249C14.0711 20.8704 14.4399 20.5013 14.8945 20.5013H19.8595L19.8594 8.99906L12.8239 7.82655V21.3249C12.8239 21.7793 12.4552 22.1484 12.0004 22.1484C11.5458 22.1484 11.1771 21.7793 11.1771 21.3249V6.85434C11.1771 6.61219 11.2835 6.38252 11.4682 6.22611C11.6528 6.06957 11.8972 6.00228 12.1357 6.04206C12.1357 6.04206 12.1357 6.04206 12.1357 6.04206L20.818 7.48905Z"
                fill="#344054"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M14.0706 17.7071C14.0706 17.2521 14.4391 16.8836 14.894 16.8836H17.7882C18.2432 16.8836 18.6117 17.2521 18.6117 17.7071V21.3247C18.6117 21.7796 18.2432 22.1483 17.7882 22.1483H14.894C14.4391 22.1483 14.0706 21.7796 14.0706 21.3247V17.7071ZM15.7176 20.5012H16.9647V18.5306H15.7176V20.5012Z"
                fill="#344054"
                stroke="#344054"
                stroke-width="0.2"
              />
              <path
                d="M5.39091 17.7071C5.39091 17.2521 5.75953 16.8836 6.21446 16.8836H9.1086C9.56355 16.8836 9.93203 17.2521 9.93203 17.7071V21.3247C9.93203 21.7796 9.56357 22.1483 9.1086 22.1483H6.21446C5.75951 22.1483 5.39091 21.7797 5.39091 21.3247V17.7071ZM7.03805 20.5012H8.28504V18.5306H7.03805V20.5012Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M3.45215 3.14766L3.45226 3.14768L12.1345 4.59492C12.5315 4.6611 12.8227 5.00451 12.8227 5.40719V21.3247C12.8227 21.7795 12.4543 22.1481 11.9992 22.1481H6.21095C5.75633 22.1481 5.38764 21.779 5.38764 21.3247C5.38764 20.8704 5.75633 20.5013 6.21095 20.5013H11.1759L11.1759 6.10492L4.14038 4.93217V21.3248C4.14038 21.7791 3.7717 22.1482 3.31707 22.1482C2.86233 22.1482 2.49364 21.7791 2.49364 21.3248V3.95996C2.49364 3.71782 2.6002 3.48843 2.78471 3.33197C2.9694 3.17512 3.21377 3.10819 3.45215 3.14766Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M16.9647 13.7278C16.9647 13.4728 17.1715 13.266 17.4264 13.266H18.1499C18.4049 13.266 18.6117 13.4728 18.6117 13.7278V14.4513C18.6117 14.7061 18.4049 14.9131 18.1499 14.9131H17.4264C17.1715 14.9131 16.9647 14.7061 16.9647 14.4513V13.7278Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M14.0702 13.7278C14.0702 13.4728 14.277 13.266 14.532 13.266H15.2554C15.5104 13.266 15.7172 13.4728 15.7172 13.7278V14.4513C15.7172 14.7061 15.5104 14.9131 15.2554 14.9131H14.532C14.277 14.9131 14.0702 14.7062 14.0702 14.4513V13.7278Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M14.0702 10.8335C14.0702 10.5787 14.277 10.3717 14.532 10.3717H15.2554C15.5104 10.3717 15.7172 10.5786 15.7172 10.8335V11.557C15.7172 11.8119 15.5104 12.0188 15.2554 12.0188H14.532C14.277 12.0188 14.0702 11.8119 14.0702 11.557V10.8335Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M16.9647 10.8335C16.9647 10.5787 17.1715 10.3717 17.4264 10.3717H18.1499C18.4049 10.3717 18.6117 10.5786 18.6117 10.8335V11.557C18.6117 11.8119 18.4049 12.0188 18.1499 12.0188H17.4264C17.1715 12.0188 16.9647 11.8118 16.9647 11.557V10.8335Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M8.28501 13.7278C8.28501 13.4728 8.49182 13.266 8.7468 13.266H9.47036C9.72523 13.266 9.93203 13.4728 9.93203 13.7278V14.4513C9.93203 14.7061 9.72526 14.9131 9.47036 14.9131H8.7468C8.49182 14.9131 8.28501 14.7062 8.28501 14.4513V13.7278Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M5.39036 13.7278C5.39036 13.4728 5.59717 13.266 5.85215 13.266H6.57571C6.83053 13.266 7.0375 13.4728 7.0375 13.7278V14.4513C7.0375 14.7062 6.83057 14.9131 6.57571 14.9131H5.85215C5.59716 14.9131 5.39036 14.7062 5.39036 14.4513V13.7278Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M5.39036 10.8335C5.39036 10.5787 5.59715 10.3717 5.85215 10.3717H6.57571C6.83055 10.3717 7.0375 10.5786 7.0375 10.8335V11.557C7.0375 11.8119 6.83057 12.0188 6.57571 12.0188H5.85215C5.59716 12.0188 5.39036 11.8119 5.39036 11.557V10.8335Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M8.28501 10.8335C8.28501 10.5787 8.4918 10.3717 8.7468 10.3717H9.47036C9.72525 10.3717 9.93203 10.5787 9.93203 10.8335V11.557C9.93203 11.8118 9.72526 12.0188 9.47036 12.0188H8.7468C8.49182 12.0188 8.28501 11.8119 8.28501 11.557V10.8335Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M8.28501 7.93943C8.28501 7.68461 8.4918 7.47764 8.7468 7.47764H9.47036C9.72525 7.47764 9.93203 7.68461 9.93203 7.93943V8.66299C9.93203 8.91793 9.72524 9.12478 9.47036 9.12478H8.7468C8.49184 9.12478 8.28501 8.91797 8.28501 8.66299V7.93943Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
              <path
                d="M5.39036 7.93943C5.39036 7.68461 5.59715 7.47764 5.85215 7.47764H6.57571C6.83055 7.47764 7.0375 7.68457 7.0375 7.93943V8.66299C7.0375 8.91797 6.83055 9.12478 6.57571 9.12478H5.85215C5.59718 9.12478 5.39036 8.91797 5.39036 8.66299V7.93943Z"
                fill="#0E4E9A"
                stroke="#0E4E9A"
                stroke-width="0.2"
              />
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Viewing Client
            </h5>
          </div>
        </div>
        <div className="px-4 pb-4">
          <Tabs defaultValue="tab1" variant="pill">
            <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5">
              <TabList>
                <TabNav value="tab1" className="flex-1">
                  Documents
                </TabNav>
                <TabNav value="tab2" className="flex-1">
                  Client Details
                </TabNav>
                <TabNav value="tab3" className="flex-1">
                  Connected workers
                </TabNav>
                <TabNav value="tab4" className="flex-1">
                  Assigned checklist
                </TabNav>
              </TabList>
            </div>
            <TabContent value="tab1" className="px-4">
              <Documents
                indivisualData={indivisualData}
                getAllinvitedData={props.getAllinvitedData}
                handleSort={props.handleSort}
              />
            </TabContent>
            <TabContent value="tab2">
              <ClientDetails />
            </TabContent>
            <TabContent value="tab3">
              <ConnectedWorkers indivisualData={indivisualData} />
            </TabContent>
            <TabContent value="tab4">
              <div>
                <div className="border rounded-lg border-gray-200">
                  <div className="h-64 overflow-auto">
                    <Scrollbar>
                      <Table>
                        <THead>
                          <Tr>
                            <Th className="flex justify-between gap-1">
                              <span className="flex items-center gap-1">
                                Checklist
                                <svg
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                    stroke="#475467"
                                    strokeWidth="1.333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </span>
                            </Th>
                            <Th>
                              <span className="flex items-center gap-1 justify-end">
                                Expiry date
                                <svg
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                    stroke="#475467"
                                    strokeWidth="1.333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </span>
                            </Th>
                          </Tr>
                        </THead>
                        <TBody>
                          {providerCompCheckList?.length &&
                            providerCompCheckList.map((item) => (
                              <Tr key={item.checklist_uuid}>
                                <Td className="flex gap-2">
                                  <Checkbox
                                    className="cursor-pointer"
                                    checked={checkedItems.some(
                                      (checkedItem) =>
                                        checkedItem.checklist_uuid ===
                                        item.checklist_uuid
                                    )}
                                    onChange={(e) => onCheckboxChange(e, item)}
                                  />
                                  {item?.checklist_name
                                    ? item?.checklist_name
                                    : "-"}
                                </Td>
                                <Td className="text-right">
                                  {item.checklist_renewal_date
                                    ? moment(
                                        item?.checklist_renewal_date
                                      ).format("DD/MM/YYYY")
                                    : "-"}
                                </Td>
                              </Tr>
                            ))}
                        </TBody>
                      </Table>
                    </Scrollbar>
                  </div>
                </div>
              </div>
              <div className="flex dailog-footer ">
                <BtnLight
                  className="mr-1.5"
                  variant="plain"
                  // onClick={onCloseAssignChecklist}
                >
                  Cancel
                </BtnLight>
                <BtnPrimary
                  type="submit"
                  onClick={() => {
                    setOpenConfirmDialog(true);
                  }}
                  className="ml-1.5"
                  // disabled={isSaving}
                >
                  Save updates
                  {/* {isSaving ? "Saving..." : "Save updates"} */}
                </BtnPrimary>
              </div>
            </TabContent>
          </Tabs>
        </div>
        {/* <div className="flex dailog-footer justify-end gap-2">
          <BtnLight
            className="ltr:mr-2 rtl:ml-2"
            variant="plain"
            onClick={onDialogClose}
          >
            Cancel
          </BtnLight>
          <BtnPrimary variant="solid" onClick={onDialogOk}>
            View Client’s full profile
          </BtnPrimary>
        </div> */}
      </Dialog>
    </React.Fragment>
  );
};
export default ClientsList;
