import React, { useRef, useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import FileItem from "./FileItem";
import CloseButton from "../CloseButton";
import Notification from "../Notification";
import toast from "../toast";
import trashIcon from "../../../assets/icons-svg/trash.svg";

const filesToArray = (files) => Object.keys(files).map((key) => files[key]);

const Upload = React.forwardRef((props, ref) => {
  const {
    accept,
    beforeUpload,
    disabled,
    draggable,
    fileList,
    multiple,
    onChange,
    onFileRemove,
    showList,
    value,
    tip,
    uploadLimit,
    children,
    className,
    field,
    form,
    ...rest
  } = props;

  const fileInputField = useRef(null);
  const [files, setFiles] = useState(fileList);
  const [dragOver, setDragOver] = useState(false);

  const themeColor = "#ddd";
  const primaryColorLevel = "#000";

  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  const triggerMessage = (msg) => {
    toast.push(
      <Notification type="danger" duration={2000}>
        {msg || "Upload Failed!"}
      </Notification>,
      {
        placement: "top-center",
      }
    );
  };

  const pushFile = (newFiles, file) => {
    for (let f of newFiles) {
      file.push(f);
    }
    return file;
  };

  const addNewFiles = (newFiles) => {
    let file = cloneDeep(files);
    if (typeof uploadLimit === "number" && uploadLimit !== 0) {
      if (Object.keys(file).length >= uploadLimit) {
        if (uploadLimit === 1) {
          file.shift();
          file = pushFile(newFiles, file);
        }

        return filesToArray({ ...file });
      }
    }
    file = pushFile(newFiles, file);
    return filesToArray({ ...file });
  };

  const onNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    let result = true;

    if (beforeUpload) {
      result = beforeUpload(newFiles, files);

      if (result === false) {
        triggerMessage();
        return;
      }

      if (typeof result === "string" && result.length > 0) {
        triggerMessage(result);
        return;
      }
    }

    if (result) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      onChange?.(updatedFiles, files);
    }
  };

  const removeFile = (fileIndex) => {
    const deletedFileList = files.filter((_, index) => index !== fileIndex);
    setFiles(deletedFileList);
    onFileRemove?.(deletedFileList);
  };

  const triggerUpload = (e) => {
    if (!disabled) {
      fileInputField.current?.click();
    }
    e.stopPropagation();
  };

  const renderChildren = () => {
    if (!draggable && !children) {
      return (
        <button disabled={disabled} onClick={(e) => e.preventDefault()}>
          Upload
        </button>
      );
    }
    
    if (draggable && !children) {
      return (
        <div className="flex items-center justify-center flex-col">
          {" "}
          <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                stroke="#475467"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span className="pt-4">
            <span className="text-blue-700 font-semibold mr-1">
              Click to upload
            </span>{" "}
            <span className="text-xs text-gray-600"> or drag and drop</span>
          </span>
          <span className="text-xs text-gray-600 mt-1">
            Example SVG, PNG, JPG or GIF (max. 800x400px)
          </span>
        </div>
      );
    }

    return children;
  };

  const handleDragLeave = useCallback(() => {
    if (draggable) {
      setDragOver(false);
    }
  }, [draggable]);

  const handleDragOver = useCallback(() => {
    if (draggable && !disabled) {
      setDragOver(true);
    }
  }, [draggable, disabled]);

  const handleDrop = useCallback(() => {
    if (draggable) {
      setDragOver(false);
    }
  }, [draggable]);

  const draggableProp = {
    onDragLeave: handleDragLeave,
    onDragOver: handleDragOver,
    onDrop: handleDrop,
  };

  const draggableEventFeedbackClass = `border-${themeColor}-${primaryColorLevel}`;

  const uploadClass = classNames(
    "upload",
    draggable && `upload-draggable`,
    draggable && !disabled && `hover:${draggableEventFeedbackClass}`,
    draggable && disabled && "disabled",
    dragOver && draggableEventFeedbackClass,
    className
  );

  const uploadInputClass = classNames("upload-input", draggable && `draggable`);

  return (
    <>
      <div
        ref={ref}
        className={uploadClass}
        {...(draggable ? draggableProp : { onClick: triggerUpload })}
        {...rest}
      >
        <input
          className={uploadInputClass}
          type="file"
          ref={fileInputField}
          onChange={onNewFileUpload}
          disabled={disabled}
          multiple={multiple}
          accept={accept}
          title=""
          value={value}
          {...field}
          {...rest}
        ></input>
        {renderChildren()}
      </div>
      {tip}
      {showList && (
        <div className="upload-file-list">
          {files.map((file, index) => ( 
            <FileItem file={file} key={file.name + index}>
              <CloseButton
                onClick={() => removeFile(index)}
                className="upload-file-remove"
                style={{ backgroundImage: `url(${trashIcon})` }}
              />
            </FileItem>
          ))}
        </div>
      )}
    </>
  );
});

Upload.propTypes = {
  uploadLimit: PropTypes.number,
  draggable: PropTypes.bool,
  disabled: PropTypes.bool,
  showList: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Upload.defaultProps = {
  draggable: false,
  showList: true,
  disabled: false,
  fileList: [],
};

export default Upload;
