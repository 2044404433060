
import axios from "axios";
import * as actionType from "../constant/actionType";

export const setAllRole = (allRole) => {
    return {
      type: actionType.SET_ALL_ROLE,
      payload: allRole,
    };
};



/*** GetAllContactTypes Action ***/
export const GetAllContactTypesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllContactTypes_START,
    };
};
export const GetAllContactTypesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllContactTypes_SUCCESS,
    };
};
export const GetAllContactTypesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllContactTypes_FAIL,
    };
};

export const GetAllContactTypes = () => { 
    return (dispatch) => {
        dispatch(GetAllContactTypesStart());

        const config = {
            method: 'get',
            url: `${actionType.environment_url}/api/GetAllContactTypes`,
            headers: { 
                // Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            params: {}
        };

        return axios(config)
            .then(function (response) {
                dispatch(GetAllContactTypesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllContactTypesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllFunctions Action ***/
export const GetAllFunctionsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllFunctions_START,
    };
};
export const GetAllFunctionsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllFunctions_SUCCESS,
    };
};
export const GetAllFunctionsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllFunctions_FAIL,
    };
};

export const GetAllFunctions = () => { 
    return (dispatch) => {
        dispatch(GetAllFunctionsStart());
        const config = {
            method: 'get',
            url: `${actionType.environment_url}/api/GetAllFunctions`,
            headers: { 
                // Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            params: {}
        };

        return axios(config)
            .then(function (response) {
                dispatch(GetAllFunctionsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllFunctionsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllIndividualTypes Action ***/
export const GetAllRoleByFunctionIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllRoleByFunctionId_START,
    };
};

export const GetAllRoleByFunctionIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllRoleByFunctionId_SUCCESS,
    };
};
export const GetAllRoleByFunctionIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllRoleByFunctionId_FAIL,
    };
};

export const GetAllRoleByFunctionId = (role_uuid) => { 
    return (dispatch) => {
        dispatch(GetAllRoleByFunctionIdStart());

        const config = {
            method: 'get',
            url: `${actionType.environment_url}/api/GetAllRoleByFunctionId`,
            headers: { 
                // Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            params: {
                function_uuid: role_uuid,
            }
        };

        return axios(config)
            .then(function (response) {
                dispatch(GetAllRoleByFunctionIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllRoleByFunctionIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllCountries Action ***/
export const GetAllCountriesStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllCountries_START,
    };
};
export const GetAllCountriesSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllCountries_SUCCESS,
    };
};
export const GetAllCountriesFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllCountries_FAIL,
    };
};

export const GetAllCountries = () => { 
    return (dispatch) => {
        dispatch(GetAllCountriesStart());

        const config = {
            method: 'get',
            url: `${actionType.environment_url}/api/GetAllCountries`,
            headers: { 
                // Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            params: {  }
        };

        return axios(config)
            .then(function (response) {
                dispatch(GetAllCountriesSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllCountriesFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetAllCountries Action ***/
export const GetAllStatesbyCountryIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStatesbyCountryId_START,
    };
};
export const GetAllStatesbyCountryIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStatesbyCountryId_SUCCESS,
    };
};
export const GetAllStatesbyCountryIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStatesbyCountryId_FAIL,
    };
};

export const GetAllStatesbyCountryId = (country_id) => { 
    return (dispatch) => {
        dispatch(GetAllStatesbyCountryIdStart());

        const config = {
            method: 'get',
            url: `${actionType.environment_url}/api/GetAllStatesbyCountryId`,
            headers: { 
                Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            params: {
                country_id: country_id,
            }
        };

        return axios(config)
            .then(function (response) {
                dispatch(GetAllStatesbyCountryIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllStatesbyCountryIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetHighRiskActivityList Action ***/
export const GetHighRiskActivityListStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetHighRiskActivityList_START,
    };
};
export const GetHighRiskActivityListSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetHighRiskActivityList_SUCCESS,
    };
};
export const GetHighRiskActivityListFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetHighRiskActivityList_FAIL,
    };
};

export const GetHighRiskActivityList = () => { 
    return (dispatch) => {
        dispatch(GetHighRiskActivityListStart());

        const config = {
            method: 'get',
            url: `${actionType.environment_url}/api/GetHighRiskActivityList`,
            headers: { 
                // Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            params: {}
        };

        return axios(config)
            .then(function (response) {
                dispatch(GetHighRiskActivityListSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetHighRiskActivityListFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllOrgIndustryTypeList Action ***/
export const GetAllOrgIndustryTypeListStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllOrgIndustryTypeList_START,
    };
};
export const GetAllOrgIndustryTypeListSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllOrgIndustryTypeList_SUCCESS,
    };
};
export const GetAllOrgIndustryTypeListFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllOrgIndustryTypeList_FAIL,
    };
};

export const GetAllOrgIndustryTypeList = () => { 
    return (dispatch) => {
        dispatch(GetAllOrgIndustryTypeListStart());

        const config = {
            method: 'get',
            url: `${actionType.environment_url}/api/GetAllOrgIndustryTypeList`,
            headers: { 
                // Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            params: {}
        };

        return axios(config)
            .then(function (response) {
                dispatch(GetAllOrgIndustryTypeListSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllOrgIndustryTypeListFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetIndividualByEmail Action ***/
export const GetIndividualByEmailStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetIndividualByEmail_START,
    };
};
export const GetIndividualByEmailSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetIndividualByEmail_SUCCESS,
    };
};
export const GetIndividualByEmailFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetIndividualByEmail_FAIL,
    };
};

export const GetIndividualByEmail = (email) => { 
    return (dispatch) => {
        dispatch(GetIndividualByEmailStart());

        const config = {
            method: 'get',
            url: `${actionType.environment_url}/api/GetIndividualByEmail`,
            headers: { 
                // Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            params: {
                email: email
            }
        };

        return axios(config)
            .then(function (response) {
                dispatch(GetIndividualByEmailSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetIndividualByEmailFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetOrgUserInvitationById Action ***/
export const GetOrgUserInvitationByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetOrgUserInvitationById_START,
    };
};
export const GetOrgUserInvitationByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetOrgUserInvitationById_SUCCESS,
    };
};
export const GetOrgUserInvitationByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetOrgUserInvitationById_FAIL,
    };
};

export const GetOrgUserInvitationById = (invite_uuid) => { 
    return (dispatch) => {
        dispatch(GetOrgUserInvitationByIdStart());

        const config = {
            method: 'get',
            url: `${actionType.environment_url}/api/GetOrgUserInvitationById`,
            headers: { 
                // Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            params: {
                invite_uuid: invite_uuid,
            }
        };

        return axios(config)
            .then(function (response) {
                dispatch(GetOrgUserInvitationByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetOrgUserInvitationByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** SubmitUserInvitation Action ***/
export const SubmitUserInvitationStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.SubmitUserInvitation_START,
    };
};
export const SubmitUserInvitationSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.SubmitUserInvitation_SUCCESS,
    };
};
export const SubmitUserInvitationFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.SubmitUserInvitation_FAIL,
    };
};
export const SubmitUserInvitation = (form_data) => { 
    return (dispatch) => {
        dispatch(SubmitUserInvitationStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                // Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/SubmitUserInvitation`,
        })
            .then(function (response) {
                dispatch(SubmitUserInvitationSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(SubmitUserInvitationFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
