import { createSlice } from '@reduxjs/toolkit';

const ProviderWorkerSpecificData = createSlice({
    name: "ProviderWorkerSpecificData",
    initialState: [],
    reducers: {
        ProviderWorkerProfileData(state, action) {
            return action.payload;
        },
        resetProviderWorkerSpecificData(state) {
            return []; 
        }
    }
});

export default ProviderWorkerSpecificData.reducer;
export const { ProviderWorkerProfileData, resetProviderWorkerSpecificData } = ProviderWorkerSpecificData.actions;
