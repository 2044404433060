import React ,{useState} from "react";
import Heading from "../../../../../../../../common/element/Heading";
const WorkerName = () =>{
return(
<React.Fragment>
   <div className="flex gap-4 flex-row justify-between">
      <div className="flex items-center mb-5">
         <div className="h-16 w-16 rounded-full overflow-hidden mr-5 avtar_img_round"><img src="/assets/img/avatars/thumb-8.jpg" alt=""/></div>
         <div className="flex flex-col">
            <Heading className="mb-2">Worker name</Heading>
            <div className="flex gap-6 items-center"> <span className="badge bg-success-50 text-success-700 py-0.5 px-2 rounded-full font-medium"><span className="badge-dot badge-inner mr-1 bg-green-700"></span>Active</span>
               <span className="text-gray-900  font-medium"><span className="text-gray-400 mr-1 font-semibold">ABN:</span>00-000-000-000</span>
            </div>
         </div>
      </div>
   </div>
</React.Fragment>
)
}
export default WorkerName