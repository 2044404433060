import React from "react";
import Tabs from "../../../../../../../../common/element/Tabs";
import WorkerName from "./WorkerName";
import Documents from "./WorkerTabs/Documents";
import SiteLog from "./WorkerTabs/SiteLog";
import Inductions from "./WorkerTabs/Inductions";
const { TabNav, TabList, TabContent } = Tabs;
const ContractorSpecificWorker = () => {
  return (
    <React.Fragment>
      <WorkerName />
      <Tabs defaultValue="tab1" variant="pill" className="contractorTabsList">
        <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5">
          <TabList>
            <TabNav value="tab1">Overview</TabNav>
            <TabNav value="tab2">Documents</TabNav>
            <TabNav value="tab3">Inductions</TabNav>
            <TabNav value="tab4">Site Log</TabNav>

          </TabList>
        </div>
        <div className="">
          <TabContent value="tab1"></TabContent>
          <TabContent value="tab2">
            <Documents/>
          </TabContent>
          <TabContent value="tab3">
            <Inductions/>
          </TabContent>
          <TabContent value="tab4">
            <SiteLog/>
          </TabContent>

        </div>
      </Tabs>
    </React.Fragment>
      
  );
};

export default ContractorSpecificWorker;
