import React, { useState, useCallback, useEffect } from "react";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
// import Select from "../../../../../../common/element/Select/Select";
import Upload from "../../../../../../common/element/Upload";
// import DocumentsList from "./DocumentsList";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import { GetAllDocType } from "../../../../../../actions/admin-provider/getAllDocumentTypeList";
// import Validations from "../../../../../../common/404/Validations";
// import ClientInput from "../../../../../../common/element/Input/clientInput";
// import { PostDoctype } from "../../../../../../actions/admin-provider/postDocTypeData";
import { useDispatch, useSelector } from "react-redux";
// import { debounce } from "../../../../../../common/snippet/Debouncing";
// import { GetAllDocumentsOfProvider } from "../../../../../../actions/admin-provider/GetAllDocumentsOfSpecificContractor";
// import DatePicker from "../../../../../../common/element/DatePicker";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import OldDocument from "./OldDocument";
// import Scrollbar from "../../../../../../common/element/ScrollBar";
import { CreateAndSubmitWorkerOtherDoc } from "../../../../../../actions/admin-provider/worker/CreateAndSubmitWorkerOtherDoc";
import { Viewer, Worker } from "@react-pdf-viewer/core";
const AddOtherDocument = ({
  docTypeName,
  updateData,
  closePannel,
  closeAddDocPannel,
  indivisualData,
  individual_uuid,
  clientUuid
}) => {
  const { id } = useParams();
  const { SpecificProviderData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const dateGap = 0;
  let function_assignment_uuid = SpecificProviderData?.provider_fa_uuid;
  let error = "This field is required!";

  const [dialogAddOpen, setAddOpen] = useState(false);
  const [specificData, SetspecificData] = useState([]);
  const [sizeError, SetsizeError] = useState("");
  const [wordError, SetwordError] = useState("");
  const [refetch, Setrefetch] = useState(false);
  const [dialogChoose, setChoose] = useState(false);
  const [docTypeError, setDocTypeError] = useState(false);
  const [UrlPdf, setUrlPdf] = useState(docTypeName?.other_doc_url);
  const [pdfFile, setPdfFile] = useState("");
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState(null);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [addDocForm, setaddDocForm] = useState("");
  const [checkReqiuredField, SetcheckReqiuredField] = useState(false);
  const [documentFormData, SetDocumentFormData] = useState({
    doc_name: docTypeName?.other_doc_name,
    checklist_doc_uuid: docTypeName?.checklist_doc_uuid,
    client_org_uuid: clientUuid?.organisation_uuid
        ? clientUuid?.organisation_uuid
        : individual_uuid?.client_assigns?.[0]?.organisation_uuid,
    individual_uuid:individual_uuid?.worker_individual?.individual_uuid,
    document_type_uuid: docTypeName?.document_type?.document_type_uuid,
    worker_uuid:individual_uuid?.worker_uuid,
    provider_org_uuid:id
  });
  
  // docTypeName,
  // updateData,
  // closePannel,
  // closeAddDocPannel,
  // indivisualData,
  // individual_uuid, client_org_uuid, checklist_doc_uuid, doc_file:file data
  const onDialogOtherClose = (e) => {
    setIsOpen(false);
  };

  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Provider",
          url: "/admin/provider/view-all-provider",
          home: "/admin/provider/dashboard",
        },
        {
          label: "Annette Souter",
          url: `/admin/provider/view-all-provider/specific-provider/${id}`,
        },
        {
          label: "Documents",
          url: `/admin/provider/view-all-provider/specific-provider/${id}`,
        },
      ])
    );
  }, []);

  // Handle form
  const handleForm = (event, expireDate, custom_field) => {
    SetDocumentFormData((prevalue) => {
      if (custom_field == "doctype_fields_data") {
        return {
          ...prevalue,
          doctype_fields_data: {
            ...prevalue.doctype_fields_data,
            [expireDate]: event.target.value,
          },
        };
      } else {
        if (expireDate === "expiry_date" || expireDate === "issue_date") {
          return {
            ...prevalue,
            [expireDate]: event,
          };
        } else {
          if (event?.name == "document_type_uuid") {
            return {
              ...prevalue,
              [event.name]: event?.document_type_uuid,
            };
          } else if (event.name == "is_doctype_swms") {
            return {
              ...prevalue,
            };
          } else if (event.length || event.length === 0) {
            if (event.length === 0) {
              SetsizeError("");
              return {
                ...prevalue,
                ["doc_file"]: "",
              };
            }
            
            if (/\.(pdf|heic|png|webp|jpg)$/i.test(event?.[0]?.name)) {
              if (event?.[0]?.size <= 2 * 1024 * 1024) {
                SetsizeError("");
                return {
                  ...prevalue,
                  ["doc_file"]: event,
                };
              } else {
                SetsizeError("File size exceeds the maximum limit of 2MB.");
                return {
                  ...prevalue,
                  ["doc_file"]: "",
                };
              }
            } else {
              SetsizeError("Only PDF and image files will be accepted");
              return {
                ...prevalue,
                ["doc_file"]: "",
              };
            }
          } else {
            if (event.target.name === "document_desc") {
              // Get the current value of the document_desc field
              const currentValue = event.target.value;
              // Split the current value into words
              const words = currentValue.split(/\s+/);
              // Check if the word count is less than or equal to 250
              if (words.length <= 250) {
                SetwordError("");
                // If the condition is met, update the state
                return {
                  ...prevalue,
                  [event.target.name]: currentValue,
                };
              } else {
                SetwordError(
                  "Additional information must not exceed 250 words."
                );
                const limitedInput = words.slice(0, 250).join(" ");
                return {
                  ...prevalue,
                  [event.target.name]: limitedInput,
                };
              }
            } else {
              return {
                ...prevalue,
                [event.target.name]: event.target.value,
              };
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    if (dialogChoose) {
      dispatch(
        GetAllDocType({
          recipient_type: "provider",
          // document_type: e.value,
          function_assignment_uuid: function_assignment_uuid,
        })
      );
    }
  }, [dialogChoose]);

  const onChooseClose = (e) => {
    setChoose(false);
    setDocTypeError(false);
    setaddDocForm("");
    SetDocumentFormData({
      doc_name: "",
      organisation_uuid: id,
      function_assignment_uuid: function_assignment_uuid,
    });
  };

  const onDialogClose = (e) => {
    onChooseClose();
    onAddDialogClose();
  };

  const openAddDialog = () => {
    SetcheckReqiuredField(true);
    let isFormDataValid = true;
    if (documentFormData.doc_file) {
      isFormDataValid = true;
    } else {
      isFormDataValid = false;
    }
    
    if (isFormDataValid) {
      dispatch(CreateAndSubmitWorkerOtherDoc(documentFormData))
        .then((response) => {
          if (response.success) {
            setAddOpen(true);
            Setrefetch(!refetch);
            SetDocumentFormData([]);
            updateData({
              id: docTypeName.compliance_doc_uuid,
              name: documentFormData.doc_name,
            });
            SetcheckReqiuredField(false);
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => {});
    }
  };

  const onAddDialogClose = (e) => {
    setAddOpen(false);
    closePannel(true);
  };

  useEffect(() => {
    // Calculate the scroll height and set the textarea height accordingly
    const textarea = document.querySelector(".moduleDescTextarea");
    if (textarea) {
      textarea.style.minHeight = "38px";
      textarea.style.height = `${Math.max(textarea.scrollHeight, 38)}px`;
      textarea.style.maxHeight = "400px";
      if (textarea.textLength === 0) {
        textarea.style.height = "38px";
      }
    }
  }, [documentFormData]);

  // Min Date
  const minDate = dayjs(new Date())
    .subtract(dateGap, "day")
    .startOf("day")
    .toDate();
  let documetnName;
  documetnName = docTypeName?.other_doc_url?.split?.("-");
  const partAfterThirdHyphen = documetnName?.slice(4)?.join?.("-");
  // Decode the URL component to get the actual data
  documetnName = decodeURIComponent(partAfterThirdHyphen);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = UrlPdf;
    link.download = "image.jpg";
    link.click();
  };
  const handlePrint = () => {
    const printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.open();
    printWindow.document.write(`<img src="${UrlPdf}" alt="Image" />`);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };
  const [zoom, setZoom] = useState(100);

  const handleZoomOut = () => {
    setZoom(zoom + 10);
  };

  const handleZoomIn = () => {
    if (zoom > 1) {
      setZoom(zoom - 10);
    }
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
    setStartPosition(null);
  };
  const handleMouseMove = (e) => {
    if (dragging && startPosition) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition({
        x: position.x + deltaX,
        y: position.y + deltaY,
      });
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
  });
  return (
    <React.Fragment>
      <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
        <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
          <img src="/assets/icons-svg/file-check.svg" alt="" />
        </span>
        <div>
          <h5 className="mb-0 text-gray-900 text-base font-semibold">
            Add Document
          </h5>
        </div>
      </div>
      <div className="px-6">
        <div className="flex mb-5">
          <label className="font-medium text-gray-700 w-1/4">
            Instructions
          </label>
          <div className="w-3/4 pl-2">
            {docTypeName?.other_doc_instruction}
            {/* {checkReqiuredField &&
                documentFormData?.is_doctype_swms == "" && (
                  <div className="text-red-500 text-sm mt-1 ">{error}</div>
                )} */}
          </div>
        </div>
        <div className="flex mb-5">
          <label className="font-medium text-gray-700 w-1/4">
            Document name
          </label>
          <div
            className="p-4 rounded-xl border border-gray-300 flex items-center justify-between w-2/4"
            onClick={() => setIsOpen(true)}
          >
            <div className="flex  gap-4">
              <span className="w-9 h-9 rounded-full border-4 border-blue-50 bg-blue-200 flex justify-center items-center">
                <img src="/assets/icons-svg/upload-pdf.svg" alt="" />
              </span>
              <div className="flex flex-col justify-center">
                <span className="text-gray-700 text-xs font-medium">
                  {/* {checkPdf?.name ? checkPdf?.name : props.size.name} */}
                  {documetnName}
                </span>
                {/* <span className="text-gray-600 text-xs">
                    {checkPdf?.size
                      ? `${(checkPdf?.size / 1024 / 1024).toFixed(2)} MB`
                      : `${(props.size.size / 1024).toFixed(2)} MB`}
                  </span> */}
              </div>
            </div>
          </div>
        </div>
                                          
        <div className="flex mb-5">
          <label className="font-medium text-gray-700 w-1/4">Upload </label>
          <div className="w-3/4 pl-2">
            {/* <Upload draggable className="w-full" /> */}
            <div className="pl-2">
              <Upload
                uploadLimit={1}
                onChange={handleForm}
                accept={".jpg, .jpeg, .png, .pdf,"}
                draggable
                className="w-full"
                onFileRemove={handleForm}
              >
                <div className="text-center">
                  <div className="flex items-center justify-center flex-col">
                    <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                          stroke="#475467"
                          strokeWidth="1.667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>
                    <span className="pt-4">
                      <span className="text-blue-700 font-semibold mr-1">
                        Click to upload
                      </span>{" "}
                      <span className="text-xs text-gray-600">
                        {" "}
                        (Max 2MB. Only PDF and image files will be accepted)
                      </span>
                    </span>
                  </div>
                </div>
              </Upload>
              {sizeError && (
                <div className="text-red-500 text-sm mt-1">{sizeError}</div>
              )}
              {checkReqiuredField && documentFormData.doc_file == "" && (
                <div className="text-red-500 text-sm mt-1 ">{error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex dailog-footer ">
        <BtnLight className="mr-1.5" variant="plain" onClick={onDialogClose}>
          Cancel
        </BtnLight>
        <BtnPrimary onClick={() => openAddDialog()} className="ml-1.5">
          Add
        </BtnPrimary>
      </div>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Document added
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={onDialogClose}>Close</BtnLight>
        </div>
      </Dialog>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogOtherClose}
        onRequestClose={onDialogOtherClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <div className="flex h-full justify-center	">
          <div className="right-block-wrap h-full w-4/5">
            <div className="doc-view pdf_view_scroll">
              {pdfFile?.split(".").pop().toLowerCase() == "pdf" ? (
                specificData &&
                specificData?.doc_file &&
                pdfFile && (
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                      // fileUrl={pdfFile}
                      fileUrl={UrlPdf}
                      plugins={[defaultLayoutPluginInstance]}
                      theme="dark"
                    />
                  </Worker>
                )
              ) : (
                <div>
                  <div className="iFrame-Header ">
                    <div className="text-white text-xs">
                      {/* {moduleData?.[indexData]?.company_ind_file?.name} */}
                    </div>
                    <div className="btn-group">
                      <button
                        onClick={() => handleZoomIn()}
                        className="bg-white"
                      >
                        -
                      </button>
                      <button
                        onClick={() => handleZoomOut()}
                        className="bg-white"
                      >
                        +
                      </button>
                    </div>
                    <div className="flex gap-3">
                      <button onClick={() => handleDownload()}>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                            stroke="#fff"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button onClick={() => handlePrint()}>
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                            stroke="#fff"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div
                    className="image-container"
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                  >
                    <img
                      style={{
                        scale: `${zoom}% `,
                        transform: `translate(${position.x}px, ${position.y}px)`,
                      }}
                      src={UrlPdf}
                      alt="Image"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default AddOtherDocument;
