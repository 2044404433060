import React, { useState, useEffect, useCallback } from "react";
import { notify } from "react-notify-toast";
import { connect, useDispatch, useSelector } from "react-redux";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../common/element/Select";
import * as userActions from "../../../../../actions/index";
import Validations from "../../../../../common/404/Validations";
import { useParams } from "react-router-dom";
import { UpdateClientContact, GetAllClientContacts } from "../../../../../actions/client-portal/AllContactsApi"
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import Switcher from "../../../../../common/element/Switcher";
const EditContactForm = (props) => {
  const dispatch = useDispatch()
  const { id } = useParams();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  //   const [selectedOption, setSelectedOption] = useState(null);
  const [individualUuid, setIndividualUuid] = useState("");
  //   const [searchOptions, setSearchOptions] = useState([]);
  //   const [search, setSearch] = useState();
  //   const [jobFromOption, setJobFromOption] = useState(false);
  //   const [phoneFromOption, setPhoneFromOption] = useState(false);
  const [siteContact, setSiteContact] = useState(props?.contactData?.is_client_site_contact);

  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    job_title: '',
    phone: '',
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    first_name: "",
    last_name: "",
    job_title: "",
    phone: "",
  });
  let organisation_uuid;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    organisation_uuid = storedData.organisation_uuid;
  }
  const AllContactType = useSelector((state) => state?.AllContactTypes);

  useEffect(() => {
    setFormData({
      email: props?.contactData?.email,
      first_name: props?.contactData?.first_name,
      last_name: props?.contactData?.last_name,
      job_title: props?.contactData?.job_title,
      phone: props?.contactData?.phone,
    })
    setSiteContact(props?.contactData?.is_client_site_contact)
    setIndividualUuid(props?.contactData?.individual_uuid)
  }, [props?.contactData])

  // Function to clear form errors
  const clearFormErrors = () => {
    setFormErrors({
      email: "",
      first_name: "",
      last_name: "",
      job_title: "",
      phone: "",
    });
  };
  // Update site contact
  const onSwitcherToggle = (e) => {
    setSiteContact(!siteContact);
  };

  // Handle input changes
  const inputChangeHandler = (e, field) => {
    let value = e.target.value;
    if (field === "phone") {
      // Remove non-numeric characters
      value = value.replace(/\D/g, "");
      // Convert the value to a number
      value = value === "" ? "" : parseInt(value, 10);
    }
    setFormData({ ...formData, [field]: value });
  };

  // Set a form error
  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const submitContact = (e) => {
    e.preventDefault();
    let organisation_Id = { organisation_uuid: organisation_uuid }
    let contactType = AllContactType.filter(
      (item) => item?.contact_type === "client contact"
    );
    const contactTypeUuid = contactType[0]?.contact_type_uuid;
    if (formErrors.email === "") {
      clearFormErrors();
      //Validate
      const firstNameValidation = Validations({
        value: formData.first_name,
        Validations: { required: true },
      });
      const lastNameValidation = Validations({
        value: formData.last_name,
        Validations: { required: true },
      });
      const jobValidation = Validations({
        value: formData.job_title,
        Validations: { required: true },
      });
      const phoneValidation = Validations({
        value: formData.phone,
        Validations: { required: true },
      });
      if (!firstNameValidation.valid) {
        setFormError("first_name", firstNameValidation.error_msg);
      }
      if (!lastNameValidation.valid) {
        setFormError("last_name", lastNameValidation.error_msg);
      }
      if (!jobValidation.valid) {
        setFormError("job_title", jobValidation.error_msg);
      }
      if (!phoneValidation.valid) {
        setFormError("phone", phoneValidation.error_msg);
      }

      if (
        firstNameValidation.valid === true &&
        lastNameValidation.valid === true &&
        jobValidation.valid === true &&
        phoneValidation.valid === true
      ) {
        const payload = {
          organisation_uuid: organisation_uuid,
          job_title: formData.job_title,
          individual_data: {},
          function_assignment_uuid: props?.function_assignment_uuid,
          is_client_site_contact: siteContact,
          // ...formData,
          individual_uuid: individualUuid || "",
        };
        props?.setButtonDisabled(true)
        dispatch(UpdateClientContact(payload))
          .then((response) => {
            if (response?.success) {
              props.onDialogClose();
              clearFormErrors();
              props?.fetchClientContacts()
            } else if (response?.status === 401) {
              handleUnauthorizedAccess()
            }
          })
          .catch((error) => {

          })
          .finally(() => {
            props?.setButtonDisabled(false)
          });
      }
    }
    else {
      props?.setButtonDisabled(false)
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={submitContact}>
        <div className="mt-6 add-client-form px-6">
          <div className="flex justify-between flex-col">
            <div className="mb-3">
              <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                Email address*
              </label>
              <input
                type="text"
                placeholder="Enter Contact's first name"
                class="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={formData.email}
              />

            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  First name*
                </label>
                <input
                  type="text"
                  placeholder="Enter Contact's first name"
                  class="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.first_name}
                  //   readOnly={individualUuid ? true : false}
                  readOnly
                  onChange={(e) => inputChangeHandler(e, "first_name")}
                />
                {formErrors.first_name &&
                  (!formData.first_name ? (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.first_name}
                    </p>
                  ) : (
                    ""
                  ))}
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Last name*
                </label>
                <input
                  type="text"
                  placeholder="Enter Contact's last name"
                  class="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.last_name}
                  readOnly
                  //   readOnly={individualUuid ? true : false}
                  onChange={(e) => inputChangeHandler(e, "last_name")}
                />
                {formErrors.last_name &&
                  (!formData.last_name ? (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.last_name}
                    </p>
                  ) : (
                    ""
                  ))}
              </div>
            </div>
            <div className="flex">
              <div className="flex-1 pr-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Job title
                </label>
                <input
                  type="text"
                  placeholder="Enter Contact's job role"
                  class="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.job_title}
                  //   readOnly={jobFromOption ? true : false}
                  onChange={(e) => inputChangeHandler(e, "job_title")}
                />
                {formErrors.job_title &&
                  (!formData.job_title ? (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.job_title}
                    </p>
                  ) : (
                    ""
                  ))}
              </div>
              <div className="flex-1 pl-1 mb-3">
                <label className="text-gray-700 font-medium text-xs mb-1.5 block">
                  Phone number
                </label>
                <input
                  type="number"
                  placeholder="Enter Contact's phone number"
                  class="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.phone}
                  readOnly
                  //   readOnly={phoneFromOption ? true : false}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    if (value?.length <= 15) {
                      inputChangeHandler(e, "phone");
                    }
                  }}
                  min="1000000"  // Min value for 7 digits
                  max="999999999999999"  // Max value for 15 digits

                />
                {formErrors.phone &&
                  (!formData.phone ? (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {formErrors.phone}
                    </p>
                  ) : (
                    ""
                  ))}
              </div>

            </div>
            <div className="mb-3">
              <Accordion open={1}>
                <AccordionBody >
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e)}
                    checked={siteContact}
                  />
                  <span className="text-gray-600 ml-4 font-semibold">
                    Set as a site contact?
                  </span>
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </div>

        <div className="flex dailog-footer ">
          <BtnLight disabled={props?.buttonDisabled} onClick={props.onDialogClose}>Cancel</BtnLight>
          <BtnPrimary disabled={props?.buttonDisabled} type="submit" className="ml-1.5">
            Update contact
          </BtnPrimary>
        </div>
      </form>
    </React.Fragment>
  );
};


export default EditContactForm;
