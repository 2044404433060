import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../../../../common/element/Card";
import Table from "../../../../../../../common/element/Table";
const ProviderProviderOverviewInfo = (props) => {
    const dispatch = useDispatch()
    const { Tr, Td, TBody } = Table;
    return (
        <div className="flex w-full gap-3 items-start">
            <Card className="w-2/5 p-5 border border-gray-200 rounded-xl dark-shadow">
                <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-2">
                    <span className="gap-2 text-gray-900 text-base font-semibold flex items-center">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.1658 21.8037C19.9709 21.8037 20.6214 21.1517 20.6214 20.3483V16.4759C20.6214 15.6726 19.9709 15.0206 19.1658 15.0206H13.3557C12.5506 15.0206 11.9 15.6726 11.9 16.4759V20.3483C11.9 21.1517 12.5506 21.8037 13.3557 21.8037H19.1658ZM19.1658 20.442H13.3557C13.304 20.442 13.262 20.3999 13.262 20.3483V16.4759C13.262 16.4243 13.304 16.3823 13.3557 16.3823H19.1658C19.2174 16.3823 19.2594 16.4243 19.2594 16.4759V20.3483C19.2594 20.3999 19.2174 20.442 19.1658 20.442Z" fill="#012963" stroke="#012963" stroke-width="0.2" />
                            <path d="M19.2593 16.475C19.2593 16.9543 18.8703 17.3433 18.391 17.3433H14.1303C13.651 17.3433 13.262 16.9543 13.262 16.475C13.262 16.0991 12.9569 15.794 12.581 15.794C12.2051 15.794 11.9 16.0991 11.9 16.475C11.9 17.7061 12.8992 18.7053 14.1303 18.7053H18.391C19.6221 18.7053 20.6213 17.7061 20.6213 16.475C20.6213 16.0991 20.3162 15.794 19.9403 15.794C19.5644 15.794 19.2593 16.0991 19.2593 16.475Z" fill="#012963" stroke="#012963" stroke-width="0.2" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.81 15.8182C18.1307 15.8182 18.391 15.5579 18.391 15.2372V14.4626C18.391 13.7143 17.7852 13.107 17.0354 13.107H15.486C14.7362 13.107 14.1304 13.7143 14.1304 14.4626V15.2372C14.1304 15.5579 14.3907 15.8182 14.7114 15.8182H17.81ZM17.229 14.802H15.2924V14.4626C15.2924 14.3557 15.3792 14.2689 15.486 14.2689H17.0354C17.1422 14.2689 17.229 14.3557 17.229 14.4626V14.802Z" fill="#012963" stroke="#012963" stroke-width="0.2" />
                            <path d="M10.78 14.9955C8.90316 15.0271 5.33487 15.6637 3.56036 19.4864C3.34841 19.9433 3.38479 20.6661 4.73027 20.7051C6.07575 20.7441 8.606 20.7213 10.166 20.7051" stroke="#012963" stroke-width="1.95" stroke-linecap="round" stroke-linejoin="round" />
                            <circle cx="11.5698" cy="8.83594" r="3.75986" stroke="#012963" stroke-width="1.95" />
                        </svg>
                        Provider Information
                    </span>
                </div>

                <Table className="min-w-full bg-white remove-border">
                    <TBody>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Provider type
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.provider_org_detail?.providerType?.provider_type_name ? props?.providerData?.provider_org_detail?.providerType?.provider_type_name : "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Type of business
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.org_type ? props?.providerData?.org_type : "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Business Name
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.trading_name ? props?.providerData?.trading_name : "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Trading Name
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.trading_name ? props?.providerData?.trading_name : "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Business address
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {

                                }
                                {props?.providerData?.org_address_data?.[1]?.address_type == "business" ?
                                    `${props?.providerData?.org_address_data[1]?.address_one}, ${props?.providerData?.org_address_data[1]?.state_name}, ${props?.providerData?.org_address_data[1]?.country_name}` :

                                    props?.providerData?.org_address_data?.[0]?.address_type == "business" ?
                                        `${props?.providerData?.org_address_data[0]?.address_one}, ${props?.providerData?.org_address_data[0]?.state_name}, ${props?.providerData?.org_address_data[0]?.country_name}` :

                                        "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                ABN/NZBN
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.abn_nzbn ? props?.providerData?.abn_nzbn : "00-000-000-000"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Industry
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.provider_org_detail?.master_setting?.meta_value_one ?
                                    props?.providerData?.provider_org_detail?.master_setting?.meta_value_one : "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Service
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                [Service label here]
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                No. of employees
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.provider_org_detail?.no_of_emp ? props?.providerData?.provider_org_detail?.no_of_emp : "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Mailing address
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.org_address_data?.[0]?.address_type == "mailing" ?
                                    `${props?.providerData?.org_address_data[0]?.address_one}, ${props?.providerData?.org_address_data[0]?.state_name}, ${props?.providerData?.org_address_data[0]?.country_name ? props?.providerData?.org_address_data[0]?.country_name : ""}` :

                                    props?.providerData?.org_address_data?.[1]?.address_type == "mailing" ?
                                        `${props?.providerData?.org_address_data[1]?.address_one}, ${props?.providerData?.org_address_data[1]?.state_name}, ${props?.providerData?.org_address_data[1]?.country_name ? props?.providerData?.org_address_data[1]?.country_name : ""}` :

                                        "Not available"}

                            </Td>
                        </Tr>
                    </TBody>
                </Table>
            </Card>

            <Card className=" w-2/5 p-5 border border-gray-200 rounded-xl dark-shadow">
                <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-2">
                    <span className="gap-2 text-gray-900 text-base font-semibold flex items-center">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.71377 9.78243C9.40977 11.232 10.3586 12.5907 11.5601 13.7923C12.7617 14.9938 14.1204 15.9426 15.57 16.6386C15.6947 16.6985 15.757 16.7284 15.8359 16.7514C16.1163 16.8332 16.4605 16.7745 16.6979 16.6044C16.7648 16.5566 16.8219 16.4994 16.9362 16.3851C17.2858 16.0355 17.4606 15.8607 17.6364 15.7464C18.2993 15.3154 19.1539 15.3154 19.8168 15.7464C19.9926 15.8607 20.1674 16.0355 20.517 16.3851L20.7118 16.58C21.2433 17.1114 21.509 17.3772 21.6533 17.6625C21.9404 18.2301 21.9404 18.9004 21.6533 19.4679C21.509 19.7533 21.2433 20.019 20.7118 20.5505L20.5542 20.7081C20.0246 21.2377 19.7598 21.5025 19.3997 21.7048C19.0002 21.9292 18.3797 22.0906 17.9215 22.0892C17.5086 22.088 17.2263 22.0079 16.6619 21.8477C13.6285 20.9867 10.7661 19.3622 8.37816 16.9742C5.99018 14.5863 4.3657 11.7239 3.50473 8.69051C3.34452 8.12607 3.26442 7.84384 3.26319 7.4309C3.26183 6.97267 3.42319 6.35218 3.64761 5.95268C3.84986 5.59264 4.11467 5.32783 4.64429 4.79821L4.80193 4.64058C5.33337 4.10913 5.59909 3.84341 5.88447 3.69907C6.45203 3.412 7.1223 3.412 7.68986 3.69907C7.97524 3.84341 8.24096 4.10913 8.7724 4.64058L8.96727 4.83544C9.31688 5.18505 9.49168 5.35985 9.60597 5.53563C10.037 6.19852 10.037 7.0531 9.60597 7.71599C9.49168 7.89177 9.31688 8.06657 8.96727 8.41618C8.85296 8.53049 8.79581 8.58765 8.74796 8.65446C8.57795 8.89189 8.51925 9.23615 8.60098 9.5165C8.62397 9.59539 8.65391 9.65774 8.71377 9.78243Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Contact Information
                    </span>
                </div>

                <Table className="min-w-full bg-white provider-info-table">
                    <TBody>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Primary contact
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">

                                {props?.providerData?.individual_data?.[0] ?
                                    `${props?.providerData?.individual_data?.[0]?.first_name ? props?.providerData?.individual_data?.[0]?.first_name : ""}
                                     ${props?.providerData?.individual_data?.[0]?.last_name ? props?.providerData?.individual_data?.[0]?.last_name : ""}`
                                    : "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Email address
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.individual_data?.[0] ?
                                    `${props?.providerData?.individual_data?.[0]?.email ? props?.providerData?.individual_data?.[0]?.email : ""}`
                                    : "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Phone number
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.individual_data?.[0] ?
                                    props?.providerData?.individual_data?.[0]?.phone == "" ? "Not available" :
                                        `${props?.providerData?.individual_data?.[0]?.phone ? props?.providerData?.individual_data?.[0]?.phone : ""}`
                                    : "Not available"}
                            </Td>
                        </Tr>
                        {props?.providerData?.individual_data?.[1] &&
                            (<>   <Tr >
                                <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                    Secondary contact
                                </Td>
                                <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                    {props?.providerData?.individual_data?.[1] ?
                                        `${props?.providerData?.individual_data?.[1]?.first_name ? props?.providerData?.individual_data?.[1]?.first_name : ""}
                                     ${props?.providerData?.individual_data?.[1]?.last_name ? props?.providerData?.individual_data?.[1]?.last_name : ""}`
                                        : "Not available"}
                                </Td>
                            </Tr>
                                <Tr >
                                    <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                        Email address
                                    </Td>
                                    <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                        {props?.providerData?.individual_data?.[1] ?
                                            `${props?.providerData?.individual_data?.[1]?.email ? props?.providerData?.individual_data?.[1]?.email : ""}`
                                            : "Not available"}
                                    </Td>
                                </Tr>
                                <Tr >
                                    <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                        Phone number
                                    </Td>
                                    <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                        {props?.providerData?.individual_data?.[1] ?
                                            `${props?.providerData?.individual_data?.[1]?.phone ? props?.providerData?.individual_data?.[1]?.phone : ""}`
                                            : "Not available"}
                                    </Td>
                                </Tr>
                            </>)
                        }

                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                WHS contact
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.provider_org_detail?.whs_contact_info ?
                                    `${props?.providerData?.provider_org_detail?.whs_contact_info?.first_name} ${props?.providerData?.provider_org_detail?.whs_contact_info?.last_name} `
                                    : "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Email address
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.provider_org_detail?.whs_contact_info?.email ? props?.providerData?.provider_org_detail?.whs_contact_info?.email : "Not available"}
                            </Td>
                        </Tr>
                        <Tr >
                            <Td className="py-4 px-6 text-gray-400 font-semibold text-sm">
                                Phone number
                            </Td>
                            <Td className="py-4 px-6 text-gray-900 font-medium text-sm">
                                {props?.providerData?.provider_org_detail?.whs_contact_info?.phone ? props?.providerData?.provider_org_detail?.whs_contact_info?.phone : "Not available"}
                            </Td>
                        </Tr>
                    </TBody>
                </Table>
            </Card>
        </div>

    );
};
export default ProviderProviderOverviewInfo;
