
import { createSlice } from '@reduxjs/toolkit'
const refetchAllInductionsSite = createSlice({
  name:"refetchAllInductionsDataSite",
  initialState:false,
  reducers:{
    refetchAllInductionsDataSite(state,action){
      return action.payload;
    },
  }
}) 
export default refetchAllInductionsSite.reducer;
export const {refetchAllInductionsDataSite}=refetchAllInductionsSite.actions;