import React from 'react';
import Dialog from '../../element/Dialog/Dialog';
import BtnPrimary from '../../element/Buttons/btnPrimary';
const DialogBoxSuccess = ({ isOpen, onClose, message, icon }) => {
  return (
    <Dialog
      isOpen={isOpen}
      bodyOpenClassName="overflow-hidden"
      onClose={onClose}
      onRequestClose={onClose}
      className="customAlert"
    >
      <div className="px-6 pt-6">
        <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
          {icon}
        </span>
        <p className="text-base text-gray-900 font-semibold text-center mt-4">
          {message}
        </p>
      </div>
      <div className='mt-8 flex justify-between pb-6 px-6 '>
        <BtnPrimary onClick={onClose}>Close</BtnPrimary>
      </div>
    </Dialog>
  );
}

export default DialogBoxSuccess;
