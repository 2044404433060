


// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const CheckExistUser = createSlice({
  name:"CheckExistUser",
  initialState:{checkUser:false,path:"",login:false,checkAcceptUser:false},
  reducers:{
    UpdateCheckExistUser(state,action){
      return (action.payload)
    },
    ResetCheckExistUser(){
      return ""
    },
  }
}) 
export const {UpdateCheckExistUser,ResetCheckExistUser}=CheckExistUser.actions;
export default CheckExistUser.reducer;