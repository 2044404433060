import { useState, useEffect } from "react";
import BtnPrimary from "../../../common/element/Buttons/btnPrimary";
import Card from "../../../common/element/Card";
import Heading from "../../../common/element/Heading";
import Circle from "../../../common/element/Iconcircle";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import { connect } from "react-redux";
import ThreeDotLoader from "../../../common/element/ButtonLoader/ThreeDotLoader";
import * as userActions from "../../../actions/index";
import { useNavigate, useLocation } from "react-router-dom";
import KeyImg from "../../../assets/icons-svg/key.svg";
import arrowLeftImg from "../../../assets/icons-svg/arrow-left.svg";

function ResetPassword(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("login"))
      ? JSON.parse(localStorage.getItem("login"))
      : ""
  );
  const [hiddenpassword, sethiddenpassword] = useState(true);

  const togglePassword = () => {
    sethiddenpassword(!hiddenpassword);
  };

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleRepeatPassword = (event) => {
    setRepeatPassword(event.target.value);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const email = urlSearchParams.get("email");
    setEmail(email);
  }, []);

  const handleErrorMessage = () => {
    const isStrong = newPassword.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
    );
    setPasswordError(
      isStrong
        ? "" // No error if the password is strong
        : "Your password must be at least 8 characters long, contain at least one special character, and have a mixture of lowercase, uppercase, and numbers."
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    //Validate Password
    const isStrong = newPassword.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
    );

    setPasswordError(
      isStrong
        ? "" // No error if the password is strong
        : "Your password must be at least 8 characters long, contain at least one special character, and have a mixture of lowercase, uppercase, and numbers."
    );
    if (!isStrong) {
      return; // If the password is not strong, don't proceed with the submission
    }

    if (!repeatPassword) {
      setPasswordError("To continue, please enter your confirmed password");
      return;
    } else if (newPassword !== repeatPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    const payload = {
      email: email,
      password: newPassword,
    };
    setButtonDisabled(true);
    props.onResetPassword(payload).then((response) => {
      if (response.success === true) {
        notify.show(response.data, "success");
        setTimeout(() => {
          // Redirects to dashboard page,
          setButtonDisabled(false);
          navigate("/password-reset");
        }, 500);
      } else {
        notify.show(response.response.data.message, "error");
      }
    }).catch((error) => {console.log("error",error);})
    .finally((()=>{
      setTimeout(() => {
        setButtonDisabled(false);
      }, 500);
    }))
  };

  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-center min-h-screen"
    >
      <div className="min-h-screen justify-center flex  py-12 px-6">
        <div className="flex-col items-center justify-center flex">
          <Card className="w-500 rounded-xl   block-shadow w-100 mt-2 px-10 py-8">
            <div className="w-full">
              <Circle className="bg-blue-100 border-blue-50 mb-6">
                <img src={KeyImg} alt="conserve" />
              </Circle>
              <Heading className="text-center mb-3">Set new password</Heading>
              <p className="text-sm text-gray-600 text-center mb-6">
                Your new password must be different to previously <br />
                used passwords.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <label
                    className="block text-gray-700  mb-2 text-xs font-medium"
                    htmlFor="password"
                  >
                    New password
                  </label>
                  <div className="relative flex">
                    <input
                      className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="New-password"
                      type={hiddenpassword ? "password" : "text"}
                      value={newPassword}
                      placeholder="••••••••"
                      onChange={(event) => handleNewPassword(event)}
                      onBlur={handleErrorMessage}
                    />
                    <span
                      className="absolute right-4 top-3"
                      onClick={togglePassword}
                    >
                      {hiddenpassword ? (
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          height="21"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                  <p className="text-xs text-red-600 mt-2">{passwordError}</p>
                </div>

                <div className="mb-6">
                  <label
                    className="block text-gray-700  mb-2 text-xs font-medium"
                    htmlFor="password"
                  >
                    Repeat new password
                  </label>
                  <div className="relative flex">
                    <input
                      className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      type={hiddenpassword ? "password" : "text"}
                      value={repeatPassword}
                      placeholder="••••••••"
                      onChange={(event) => handleRepeatPassword(event)}
                    />
                    <span
                      className="absolute right-4 top-3"
                      onClick={togglePassword}
                    >
                      {hiddenpassword ? (
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          height="21"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                </div>

                <div className="flex items-center justify-between w-100">
                  <BtnPrimary disabled={buttonDisabled} type="submit" >
                    {buttonDisabled ? <ThreeDotLoader /> : "Reset password"}
                  </BtnPrimary>
                </div>
              </form>
            </div>
          </Card>
          <div className=" mt-8 px-10 w-full text-center">
            <Link
              to="/login"
              className="text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600 flex justify-center items-center"
            >
              {" "}
              <img src={arrowLeftImg} className="w-5 mr-2" alt="" /> Back to log
              in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = {
  onResetPassword: userActions.ResetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
