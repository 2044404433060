import { createSlice } from "@reduxjs/toolkit";

const GetAllContactsOfContractorPortal = createSlice({
  name: 'AllContactsOfContractorPortalData',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },            
  reducers: {
    fetchData: (state) => {
      state.loading = true;
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    reSetData: () => {
      return [];
    },
    deleteContact: (state, action) => {
      const contactIndexToDelete = action.payload;
      // Ensure the index is valid
      if (contactIndexToDelete >= 0 && contactIndexToDelete < state.data.data.rows.length) {
        state.data.data.rows.splice(contactIndexToDelete, 1);
      }
    },
    
    
  },
});

export const {
  fetchData,
  fetchDataSuccess,
  fetchDataError,
  reSetData,
  updateContact,
  deleteContact,
} = GetAllContactsOfContractorPortal.actions;

export default GetAllContactsOfContractorPortal.reducer;
