import { useState } from "react";
import Card from "../../../common/element/Card";
import Heading from "../../../common/element/Heading";
import Circle from "../../../common/element/Iconcircle";
import { Link } from "react-router-dom";
import { notify } from "react-notify-toast";
import { connect } from "react-redux";
import * as userActions from "../../../actions/index";

function ResetLink(props) {
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [email] = useState(
    JSON.parse(localStorage.getItem("login"))
      ? JSON.parse(localStorage.getItem("login"))
      : ""
  );

  const handleResendClick = (e) => {
    e.preventDefault();

    const payload = {
      email: email,
    };
    setButtonDisabled(true)
    props.onForgotPassword(payload).then((response) => {
      if (response.success === true) {
        notify.show(response.data, "success");
        setTimeout(() => {
          setButtonDisabled(false)
        }, 800);
      } else {
        notify.show(response.response.data.message, "error");
        setTimeout(() => {
          setButtonDisabled(false)
        }, 800);
      }
    }).catch((error) => {
      console.log("Error", error);
      setButtonDisabled(false)
    })
  };

  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-center min-h-screen"
    >
      <div className="min-h-screen justify-center flex  py-12 px-6">
        <div className="flex-col items-center justify-center flex">
          <Card className="w-500 rounded-xl   block-shadow w-100 mt-2 px-10 py-8">
            <Circle className="bg-blue-100 border-blue-50 mb-6">
              <img src="/assets/icons-svg/mail.svg" alt="conserve" />
            </Circle>
            <Heading className="text-center mb-3">Check your email</Heading>
            <p className="text-sm text-gray-600 text-center mb-8">
              We sent a password reset link to <br />{" "}
              <span className="font-semibold">{email}</span>
            </p>
            <p className="text-xs text-center">
              Didn’t receive the email?
              {buttonDisabled == false ? (
                <Link
                  to="/set-new-password"
                  className="text-blue-700 font-semibold hover:text-blue-900 focus:text-blue-700"
                  onClick={handleResendClick}
                >
                  Click to resend
                </Link>
              ) : (
                <Link

                  className="cursor-not-allowed opacity-50 text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600"
                >
                  Click to resend
                </Link>
              )

              }

            </p>
          </Card>
          <div className=" mt-8 px-10 w-full text-center">
            <Link
              to="/login"
              className="text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600 flex justify-center items-center"
            >
              {" "}
              <img
                src="/assets/icons-svg/arrow-left.svg"
                className="w-5 mr-2"
                alt=""
              />{" "}
              Back to log in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = {
  onForgotPassword: userActions.ForgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetLink);
