import React, { useState, useEffect, useCallback, useRef } from "react"
import Table from "../../../../common/element/Table";
import Tag from "../../../../common/element/Tag";
// import ViewInduction from "../../../adminPortal/pages/Worker/Dialog/SpecificWorker/Inductions/ViewInduction";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Dialog from "../../../../common/element/Dialog/Dialog";
import InductionView from "./Dialog/InductionView";
import Badge from "../../../../common/element/Badge";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Tooltip from "../../../../common/element/Tooltip";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import { EditInduction } from "../../../../redux/classes/client-portal/EeditInduction";
import { ResetEditInduction } from "../../../../redux/classes/client-portal/EeditInduction";
import { ResetInductionModuleData } from "../../../../redux/classes/client-portal/inductionModuleDataSlice";
import { GetCompanyInductionAndModulesbyId } from "../../../../actions/client-portal/GetCompanyInductionAndModulesbyIdApi";
import { InductuionDetailForm } from "../../../../redux/classes/client-portal/inductionDetailsForm";
import { AddInductionModuleData } from "../../../../redux/classes/client-portal/inductionModuleDataSlice";
const InductionsList = (props) => {
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const dispatch = useDispatch();
  let index = 0;
  const [dialogIsOpen, setIsOpen] = useState(false)
  const [checkEditdata, SetcheckEditdata] = useState(true);
  const [waitDialogIsOpen, setWaitIsOpen] = useState(false)
  const [expandedRows, setExpandedRows] = useState({});
  const [editBtnHide,setEditBtnHide]= useState(false)
  const [companyInductionUuid, setCompanyInductionUuid] = useState("");
  const [inductionName, setInductionName] = useState("");
  const Allfunction = useSelector((state) => state);
  const { Tr, Th, Td, THead, TBody } = Table;

  const openDialog = (company_induction_uuid) => {
    SetcheckEditdata(true);
    dispatch(ResetEditInduction());
    dispatch(ResetInductionModuleData());
    dispatch(GetCompanyInductionAndModulesbyId(company_induction_uuid)).then(
      (response) => {

        if (response?.success) {
          dispatch(
            InductuionDetailForm({
              function_assignment_uuid: response?.data?.function_assignment_uuid,
              company_ind_name: response?.data?.company_ind_name,
              company_ind_desc: response?.data?.company_ind_desc,
              company_induction_uuid: response?.data?.company_induction_uuid,
              company_ind_status: response?.data?.company_ind_status,
              company_ind_valid_days: response?.data?.company_ind_valid_days,
              company_ind_publish_date: response?.data?.company_ind_publish_date,
            })
          );
          response?.data?.company_ind_modules.map((item) => {
            dispatch(AddInductionModuleData(item));
          });
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        }
      }
    );
    setIsOpen(true);
  };

  const allClientSiteInductions = [
    {
      clientName: "Dikonia",
      totalModules: 6,
      dateCompleted: "-",
      progressStatusPercentage: 66.7,
      inductions: [
        {
          inductionName: "Green Balance",
          siteName: "Test Site",
          modules: 3,
          inductionCompletedDate: "22/02/2023",
          inductionStatus: "Completed",
        },
        {
          inductionName: "Blue Energy",
          siteName: "Main Office",
          modules: 1,
          inductionCompletedDate: "15/03/2023",
          inductionStatus: "In Progress",
        },
        {
          inductionName: "Yellow Safety",
          siteName: "Warehouse",
          modules: 2,
          inductionCompletedDate: "10/01/2023",
          inductionStatus: "Completed",
        },
      ],
    },
    {
      clientName: "EcoWorks",
      totalModules: 8,
      dateCompleted: "-",
      progressStatusPercentage: 50,
      inductions: [
        {
          inductionName: "Solar Shield",
          siteName: "Solar Farm",
          modules: 5,
          inductionCompletedDate: "18/04/2023",
          inductionStatus: "Completed",
        },
        {
          inductionName: "Wind Power",
          siteName: "Head Office",
          modules: 3,
          inductionCompletedDate: "23/05/2023",
          inductionStatus: "In Progress",
        },
      ],
    },
    {
      clientName: "Clean Future",
      totalModules: 10,
      dateCompleted: "15/07/2023",
      progressStatusPercentage: 100,
      inductions: [
        {
          inductionName: "Waste Management",
          siteName: "Landfill Site",
          modules: 7,
          inductionCompletedDate: "12/06/2023",
          inductionStatus: "Completed",
        },
        {
          inductionName: "Recycling Initiatives",
          siteName: "Recycling Plant",
          modules: 3,
          inductionCompletedDate: "15/07/2023",
          inductionStatus: "Completed",
        },
      ],
    },
    {
      clientName: "BioTech Innovations",
      totalModules: 8,
      dateCompleted: "-",
      progressStatusPercentage: 75,
      inductions: [
        {
          inductionName: "Genetic Research",
          siteName: "Research Lab",
          modules: 2,
          inductionCompletedDate: "10/08/2023",
          inductionStatus: "Completed",
        },
        {
          inductionName: "BioSafety",
          siteName: "Main Office",
          modules: 2,
          inductionCompletedDate: "20/08/2023",
          inductionStatus: "Completed",
        },
        {
          inductionName: "Clinical Trials",
          siteName: "Test Facility",
          modules: 1,
          inductionCompletedDate: "25/08/2023",
          inductionStatus: "In Progress",
        },
        {
          inductionName: "Recycling Initiatives",
          siteName: "Recycling Plant",
          modules: 3,
          inductionCompletedDate: "15/07/2023",
          inductionStatus: "Completed",
        },
      ],
    },
    {
      clientName: "GreenTech Solutions",
      totalModules: 5,
      dateCompleted: "30/09/2023",
      progressStatusPercentage: 100,
      inductions: [
        {
          inductionName: "Eco-Friendly Construction",
          siteName: "Construction Site",
          modules: 5,
          inductionCompletedDate: "30/09/2023",
          inductionStatus: "Completed",
        },
      ],
    },
  ];

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle visibility for specific row
    }));
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
    dispatch(ResetInductionModuleData());
    dispatch(ResetEditInduction());
    SetcheckEditdata(false)
  };
  const closeViewInduction = () => {
    dispatch(ResetEditInduction());
    SetcheckEditdata(false)
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-2/12">
                <span className="flex items-center gap-1 ">
                  Client
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-2/12">
                <span className="flex items-center gap-1 ">
                  Induction(s)
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-2/12">Site(s)</Th>

              <Th className="w-60">
                <span className="flex items-center gap-1 ">
                  Module(s)
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-60">
                <span className="flex items-center gap-1 ">
                  Date completed
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-60">
                <span className="flex items-center gap-1">Status</span>
              </Th>
            </Tr>
          </THead>
          <TBody>
            {allClientSiteInductions && allClientSiteInductions?.map((item, index) => (
              <React.Fragment key={index}>
                {/* Main row */}
                <Tr className={`${expandedRows[index] === true && "bg-blue-50"}`}>
                  <Td className="flex gap-2 items-center">
                    {/* Toggle icon */}
                    {expandedRows[index] === false || expandedRows[index] === undefined ? (
                      <svg
                        className="cursor-pointer"
                        onClick={() => toggleRow(index)}
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="24.5"
                          width="24"
                          height="24"
                          rx="12"
                          transform="rotate(-90 0 24.5)"
                          fill="#F9FAFB"
                        />
                        <path
                          d="M9 18.5L15 12.5L9 6.5"
                          stroke="#5786CC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="cursor-pointer"
                        onClick={() => toggleRow(index)}
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect y="0.5" width="24" height="24" rx="12" fill="#D2EBFF" />
                        <path
                          d="M6 9.5L12 15.5L18 9.5"
                          stroke="#5786CC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                    <span>{item.clientName}</span>
                  </Td>
                  <Td>{item.inductions.length} Inductions</Td>
                  <Td>{item.inductions.length} Sites</Td>
                  <Td>{item.totalModules}</Td>
                  <Td>{item.dateCompleted}</Td>
                  <Td>
                    <div className="w-4/5 flex gap-2 items-center">
                      <div className="w-2/3 bg-gray-200 rounded-full h-2">
                        {/* Dynamic progress bar with reduced height */}
                        <div
                          className="bg-blue-700 h-2 rounded-full"
                          style={{ width: `${item.progressStatusPercentage}%` }} // Dynamic width
                        ></div>
                      </div>

                      {/* Percentage tag with margin-top for spacing */}
                      <span className="text-right text-gray-700 font-medium block mt-1">
                        <Tag className=" bg-blue-50">
                          {item.progressStatusPercentage}%
                        </Tag>
                      </span>
                    </div>
                  </Td>


                </Tr>

                {/* Additional rows when expanded */}
                {expandedRows[index] && (
                  <>
                    {item.inductions.map((induction, idx) => (
                      <Tr key={idx}>
                        <Td></Td>
                        <Td>{induction.inductionName}</Td>
                        <Td>{induction.siteName}</Td>
                        <Td>{induction.modules}</Td>
                        <Td>{induction.inductionCompletedDate}</Td>
                        <Td >
                          <span onClick={()=>openDialog("dc5cbd33-0d25-48b0-96b9-06fed71f4510")} className={`cursor-pointer h-7 w-32 flex gap-2 items-center justify-center rounded-2xl font-medium	 ${induction?.inductionStatus === "Completed"
                            ? "bg-success-100 text-success-600"
                            : "bg-blue-50 text-blue-800"
                            }`}>
                            {induction?.inductionStatus === "In Progress" && (
                              <>
                                <svg
                                  width="16" // Reduced width
                                  height="16" // Reduced height
                                  viewBox="0 0 12 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 1.625V2.875M6 9.5V11.5M2.875 6.5H1.125M10.625 6.5H9.875M9.22855 9.72855L8.875 9.375M9.33211 3.20789L8.625 3.915M2.46079 10.0392L3.875 8.625M2.56434 3.10434L3.625 4.165"
                                    stroke="#0E4E9A"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <span>Pending</span>
                              </>
                            )}

                            {induction?.inductionStatus === "Completed" && (
                              <>
                                <svg
                                  width="16" // Reduced width
                                  height="16" // Reduced height
                                  viewBox="0 0 12 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10 3.5L4.5 9L2 6.5"
                                    stroke="#3DB77E"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <span >Completed</span>
                              </>
                            )}
                          </span>
                        </Td>
                      </Tr>
                    ))}
                  </>
                )}
              </React.Fragment>
            ))}
          </TBody>
         
        </Table>
      </div>







      {/* This is for view specific induction */}
      {checkEditdata && (
        <Dialog
          isOpen={dialogIsOpen}
          onClose={onDialogClose}
          onRequestClose={onDialogClose}
          bodyOpenClassName="overflow-hidden"
          className="lg-dialog"
        >
          <div className="flex border-b pb-5 px-6 pt-6 items-center">
            <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
              <img src="/assets/icons-svg/file-check.svg" alt="" />
            </span>
            <div>
              <h5 className="mb-0 text-gray-900 text-base font-semibold">
                {Allfunction?.inductionDetailData?.company_ind_name}
              </h5>
            </div>
          </div>
          <InductionView inductionPermission={props?.inductionPermission} closeViewInductionModule={closeViewInduction} 
          editBtnHide={editBtnHide}
          // handleEditinductionModule={handleEditinduction}
           />
        </Dialog>
      )}

    </React.Fragment>
  );
};
export default InductionsList;
