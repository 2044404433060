import "./Loader.css"
// For boncing loader
const ThreeDotLoader = () => (
  <div className="flex justify-center items-center space-x-1 ">
    <div className="w-2 h-2 bg-white rounded-full animate-dot-blink delay-0"></div>
    <div className="w-2 h-2 bg-white rounded-full animate-dot-blink delay-200"></div>
    <div className="w-2 h-2 bg-white rounded-full animate-dot-blink delay-400"></div>
  </div>
);

export default ThreeDotLoader;