import React, { useState, useCallback, useEffect, useRef } from "react";
import { GetSubmissionDocsOrChecklist } from "../../../../../../../../actions/client-portal/AllSiteApi";
import DocumentListShow from "./DocumentListShow";
import { debounce } from "../../../../../../../../common/snippet/Debouncing";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
const Documents = (props) => {
  const dispatch = useDispatch();
  const [workerD, setworkerD] = useState([])
  const [searchTerm, setsearchTerm] = useState("");
  const [allChecklistData, setAllChecklistData] = useState([])
  const [allDocData, setAllDocData] = useState([])
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [filterorder, setFilterOrder] = useState("desc");
  const [countData, setCountData] = useState(0);
  const [filtersort, setFiltersort] = useState("doc_name");
  const cancelTokenSourceRef = useRef(null);
  let organisation_uuid;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    // user_uuid = storedData?.userName?.user_uuid;
    // function_assignment_uuid = storedData.function_assignment_uuid;
    // function_uuid = storedData.functionUuid
    // trading_name = storedData.trading_name;
    organisation_uuid = storedData?.organisation_uuid

  }
  const OnGetSubmissionDocsOrChecklist = useCallback(async () => {
    // if (organisation_uuid && props?.organisation_uuid) {
    const payload = {
      client_org_uuid: organisation_uuid,
      worker_uuid: props?.worker_uuid ? props?.worker_uuid : ""
    }

    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('Operation canceled by the user.');
    }
    const source = axios.CancelToken.source();
    cancelTokenSourceRef.current = source;
    try {
      const result = await dispatch(GetSubmissionDocsOrChecklist(payload, source.token));
      if (result?.success) {
        // const arrayData = result?.data?.rows;
        const arrayData = result?.data
        const checklistData = arrayData?.data?.checklistData;
        const workerData = arrayData?.data?.workerDocs
        const filterData = checklistData?.map((item) => ({
          value: item?.checklist_uuid,
          label: item?.checklist_name,
        }));
        setAllChecklistData(filterData)
        // setCountData(result?.data?.data?.count);
        // setTotalPages(Math.ceil(result?.data?.data?.count / filterlimit));
        setAllDocData(workerData)
        // setworkerD(result?.data?.data?.rows)

      } else {
        console.error('Error:', result);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error('Dispatch error:', error);
      }
    }
    // }
  }, [dispatch]);


  useEffect(() => {
    OnGetSubmissionDocsOrChecklist()
    return () => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Component unmounted');
      }
    };
  }, [])
  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    OnGetSubmissionDocsOrChecklist()
  };
  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value);
    setfilterpage(1);
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const handleSort = (column) => {
    // Handle sorting of the service list
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };
  return (
    <React.Fragment>
      <DocumentListShow
        OnGetSubmissionDocsOrChecklist={OnGetSubmissionDocsOrChecklist}
        allDocData={allDocData}
        handleSort={handleSort}
        filtersort={filtersort}
        filterorder={filterorder}
        provider_org_uuid={props?.provider_org_uuid ? props?.provider_org_uuid : ""}
        client_org_uuid={organisation_uuid}
        worker_uuid={props?.worker_uuid ? props?.worker_uuid : ""}
        trading_name={props?.trading_name}
        allChecklistData={allChecklistData} />
      {/* <div className="flex justify-between mt-3">
        <Pagination totalCount={countData} pageNumber={filterpage} displayPage={workerD?.length} UpdatePageLimit={setfilterlimit} UpdatePage={setfilterpage} />

        {countData > 0 && <ReactPaginate
          className="flex justify-content-end p-2 align-items-center pagination-wrap"
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLinkClassName={"prev-link"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"prev-link"}
          previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
          nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
          pageLinkClassName={"px-4 rounded-md"}
          activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
          disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          renderOnZeroPageCount={null}
          forcePage={filterpage - 1}
        />}
      </div> */}
    </React.Fragment>
  );
};

export default Documents;
