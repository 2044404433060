import axios from "axios";
import * as actionType from "../../constant/actionType";
import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/admin-worker/GetAllContractorsOfSpecificWorkerslice";
export const GetAllProvidersOfWorker = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/worker/GetAllProvidersOfWorker`,
        params: apiData
      });
    return response.data
      
    } catch (error) {
     console.log(error.message); 
    }
  };