import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Segment from "../../../../../../common/element/Segment";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import * as userActions from "../../../../../../actions/index";
import imagePlus from "../../../../../../assets/icons-svg/image-plus.svg";
import imageArrow from "../../../../../../assets/icons-svg/arrow-right.svg";
import Validations from "../../../../../../common/404/Validations";
import Select from "../../../../../../common/element/Select/Select";
import { notify } from "react-notify-toast";
// import { GetClientUserPermByIdForClient } from "../../../../../../actions/client-portal/AllUsersApi";
import {
  GetSpecificClientDetailsById,
  GetAlreadyExistingOrgCheck,
  UpdateClientDetails,
} from "../../../../../../actions/client-portal/AllSettingApis";
import Dialog from "../../../../../../common/element/Dialog";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";

const CompanyProfile = (props) => {
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [docAdd, setDocAdd] = useState(false);
  const [logo, setLogo] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [dialogAddOpen, setdialogAddOpen] = useState(false);
  const [businessStateName, setBusinessStateName] = useState("");
  const [businessCountryName, setBusinessCountryName] = useState("");
  const [formData, setFormData] = useState({
    trading_name: "",
    abn_nzbn: "",
    acn: "",
    website_url: "",
    businessAddres1: "",
    businessAddres2: "",
    businessAddresCity: "",
    businessAddresPostCode: "",
    businessAddresState: "",
    businessAddresCountry: "",
    businessUuid: "",
    mailingAddres1: "",
    mailingAddres2: "",
    mailingAddresCity: "",
    mailingAddresPostCode: "",
    mailingAddresState: "",
    mailingAddresCountry: "",
    mailingUuid: "",
  });
  const [formErrors, setFormErrors] = useState({
    abn_nzbn: "",
    acn: "",
    trading_name: "",
    businessAddres1: "",
    businessAddresCity: "",
    businessAddresPostCode: "",
    businessAddresState: "",
    businessAddresCountry: "",
    mailingAddres1: "",
    mailingAddresCity: "",
    mailingAddresPostCode: "",
    mailingAddresState: "",
    mailingAddresCountry: "",
    org_type: "",
    existClient: "",
  });

  const [organizationUuid, setOrganizationUuid] = useState("");
  const [parentLevel, setParentLevel] = useState("");
  const [countries, setCountries] = useState();
  const [statesOne, setStatesOne] = useState();
  const [statesTwo, setStatesTwo] = useState();
  const [sameAddress, setSameAddress] = useState();
  const [businessAddresCountry, setBusinessAddresCountry] = useState("");
  const [documentError, setdocumentError] = useState("");
  const [businessAddresState, setBusinessAddresState] = useState("");
  const [mailingAddresCountry, setMailingAddresCountry] = useState("");
  const [mailingAddresState, setMailingAddresState] = useState("");
  const [businessUuid, setBusinessUuid] = useState("");
  const [mailingUuid, setMailingUuid] = useState("");
  // state to set abn without having space
  const [abn, setAbn] = useState();
  const [callApi, setcallApi] = useState(false);

  let getApiData;

  let function_assignment_uuid;
  let user_uuid;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

  if (storedData) {
    getApiData = {
      organisation_uuid: storedData.organisation_uuid,
    };
    user_uuid = storedData?.userName?.user_uuid;
    function_assignment_uuid = storedData.function_assignment_uuid;
  }

  const DissmissError = () => {
    setFormErrors({
      abn_nzbn: "",
      acn: "",
      trading_name: "",
      businessAddres1: "",
      businessAddresCity: "",
      businessAddresPostCode: "",
      businessAddresState: "",
      businessAddresCountry: "",
      mailingAddres1: "",
      mailingAddresCity: "",
      mailingAddresPostCode: "",
      mailingAddresState: "",
      mailingAddresCountry: "",
      org_type: "",
      existClient: "",
    })
  }
  // api call for permissions

  const clearFormErrors = () => {
    setFormErrors({
      abn_nzbn: "",
      acn: "",
      trading_name: "",
      businessAddres1: "",
      businessAddresCity: "",
      businessAddresPostCode: "",
      businessAddresState: "",
      businessAddresCountry: "",
      mailingAddres1: "",
      mailingAddresCity: "",
      mailingAddresPostCode: "",
      mailingAddresState: "",
      mailingAddresCountry: "",
      org_type: "",
    });
  };

  const clearFormData = () => {
    setFormData({
      trading_name: "",
      abn_nzbn: "",
      acn: "",
      website_url: "",
      businessAddres1: "",
      businessAddres2: "",
      businessAddresCity: "",
      businessAddresPostCode: "",
      businessAddresState: "",
      businessAddresCountry: "",
      mailingAddres1: "",
      mailingAddres2: "",
      mailingAddresCity: "",
      mailingAddresPostCode: "",
      mailingAddresState: "",
      mailingAddresCountry: "",
    });

    setBusinessType("");
  };

  useEffect(() => {
    if (sameAddress && formData) {
      // Set business country and state from formData, only if they are defined
      if (formData?.businessAddresCountry !== undefined) {
        if (countries) {
          const selectedCountry = countries?.find(
            (option) => option.value === formData?.businessAddresCountry
          );
          if (selectedCountry) {
            setBusinessCountryName(selectedCountry.label); // Set the label as the name
          }
        }
      }

      if (formData?.businessAddresState !== undefined) {
        if (statesOne) {
          const selectedState = statesOne?.find(
            (option) => option.value === formData?.businessAddresState
          );
          if (selectedState) {
            setBusinessStateName(selectedState.label); // Set the label as the name
          }
        }
      }
    }
  }, [sameAddress]);


  const GetAllStatesbyCountryId = useCallback(
    async (formData) => {

      if (formData?.businessAddresCountry) {
        dispatch(
          userActions.GetAllStatesbyCountryId(formData?.businessAddresCountry)
        ).then((response) => {
          if (response?.success === true) {
            const arrayData = response?.data;
            let state_data = [];
            for (let key in arrayData) {
              state_data.push({
                label: arrayData[key]?.state_name,
                value: arrayData[key]?.state_id,
              });
            }
            setStatesOne(state_data);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          } else {
          }
        });
      }

      if (formData?.mailingAddresCountry) {
        dispatch(
          userActions.GetAllStatesbyCountryId(formData?.mailingAddresCountry)
        ).then((response) => {
          if (response?.success === true) {
            const arrayData = response?.data;
            let state_data = [];
            for (let key in arrayData) {
              state_data.push({
                label: arrayData[key]?.state_name,
                value: arrayData[key]?.state_id,
              });
            }
            setStatesTwo(state_data);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          } else {
          }
        }).catch((error) => {
          console.log("error", error);
        })
      }
      // eslint-disable-next-line
    },
    [dispatch, formData?.businessAddresCountry, formData?.mailingAddresCountry]
  );

  const GetAllCountries = useCallback(async () => {
    dispatch(userActions.GetAllCountries()).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        let country_data = [];
        for (let key in arrayData) {
          country_data.push({
            label: arrayData[key].name,
            value: arrayData[key].country_id,
            country_id: arrayData[key].country_id,
          });
        }
        setCountries(country_data);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      } else {
      }
    }).catch((error) => {
      console.log("error", error);
    })

    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {

    GetAllStatesbyCountryId(formData);
  }, [formData?.businessAddresCountry, formData?.mailingAddresCountry]);

  useEffect(() => {
    if (callApi) {
      GetAllCountries();
    }
  }, [callApi]);

  const updateClientDatafromAPi = (arrayData) => {
    // Check if arrayData exists to avoid null errors
    if (!arrayData) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      trading_name: arrayData?.trading_name || "",
      abn_nzbn: arrayData?.abn_nzbn || "",
      acn: arrayData?.acn || "",
      website_url: arrayData?.website_url || "",
      businessAddres1: "",
      businessAddres2: "",
      businessAddresCity: "",
      businessAddresPostCode: "",
      mailingAddres1: "",
      mailingAddres2: "",
      mailingAddresCity: "",
      mailingAddresPostCode: "",
    }));

    setUrlImage(arrayData?.logo || "");
    setLogo(arrayData?.logo || "");
    setBusinessType(arrayData?.org_type || "");
    setOrganizationUuid(arrayData?.organisation_uuid || "");

    // Iterate over org_address_data using forEach
    arrayData?.org_address_data?.forEach((item) => {
      if (item?.address_type === "business") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          businessAddres1: item?.address_one || "",
          businessAddres2: item?.address_two || "",
          businessAddresCity: item?.suburb || "",
          businessAddresPostCode: item?.zipcode || "",
          businessUuid: item?.address_uuid || "",
          businessAddresCountry: item?.country_id || "",
          businessAddresState: item?.state_id || "",
        }));

        setBusinessUuid(item?.address_uuid || "");
        setBusinessAddresCountry({ label: item?.country_name || "" });
        setBusinessAddresState({ label: item?.state_name || "" });
      }
    });

    arrayData?.org_address_data?.forEach((item) => {
      if (item?.address_type === "mailing") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          mailingAddres1: item?.address_one || "",
          mailingAddres2: item?.address_two || "",
          mailingAddresCity: item?.suburb || "",
          mailingAddresPostCode: item?.zipcode || "",
          mailingUuid: item?.address_uuid || "",
          mailingAddresCountry: item?.country_id || "",
          mailingAddresState: item?.state_id || "",
        }));
        setMailingUuid(item?.address_uuid || "");
        setMailingAddresCountry({ label: item?.country_name || "" });
        setMailingAddresState({ label: item?.state_name || "" });
      }
    });

    // Set callApi flag
    setcallApi(true);
  };


  useEffect(() => {
    dispatch(GetSpecificClientDetailsById(getApiData)).then((response) => {
      if (response?.success) {
        const arrayData = response?.data;
        updateClientDatafromAPi(arrayData);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      }
    }).catch((error) => { console.log("error", error); })
  }, []);

  const inputchangehandler = (e, field) => {
    let value = e?.target?.value;
    if (field === 'website_url') {
      value = value.replace(/\s+/g, '');
    }
    // if (field === "abn_nzbn") {
    //   // Remove non-numeric characters
    //   value = value.replace(/\D/g, "");
    // }
    // if (field === "website_url") {
    //   // if (value.includes("www.") && !value.startsWith("www.")) {
    //   //   value = value.replace("www.", "");
    //   // }

    //   // if (!value.startsWith("www") && !value.startsWith("www.")) {
    //   //   value = `www.${value}`;
    //   // }
    //   if (value.includes('www.') && !value.startsWith('www.') && !value.startsWith('http')) {
    //     value = value.replace('www.', '');
    // }

    // if (!value.startsWith('www') && !value.startsWith('www.') && !value.startsWith('http')) {
    //   value = `www.${value}`;
    // }
    // }
    setFormData({ ...formData, [field]: value });
  };

  const handleFocusWebsite = () => {
    // Check if the current value doesn't already start with "www"
    if (!formData?.website_url.startsWith('www')) {
      // Prepend "www" to the input value
      // setWebsite('www.' + website);
      setFormData({
        ...formData,
        website_url: `www.${formData?.website_url}`
      });
    }
  };


  // function to auto space in abn number
  const handleABN = () => {
    // let newValue = formData?.abn_nzbn.replace(/\D/g, ""); // Remove non-digit characters
    // const length = newValue.length;
    // if (length <= 11) {
    //   // newValue = `${newValue.slice(0, 2)} ${newValue.slice(2)}`;
    //   newValue = `${newValue.slice(0, 2)} ${newValue.slice(
    //     2,
    //     5
    //   )} ${newValue.slice(5, 8)} ${newValue.slice(8, 11)}`;
    // }
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   abn_nzbn: newValue,
    // }));
    // setAbn(newValue);
    // let clientData = {
    // trading_name: formData?.trading_name,
    // abn_nzbn: formData?.abn_nzbn,
    // organisation_uuid: organizationUuid,
    // };

    dispatch(GetAlreadyExistingOrgCheck({
      trading_name: formData?.trading_name,
      abn_nzbn: formData?.abn_nzbn,
      organisation_uuid: organizationUuid
    })).then((response) => {
      if (response?.success) {
        const arrayData = response?.data;
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          existClient: "",
        }));

        if (
          arrayData &&
          arrayData?.abn_nzbn == formData?.abn_nzbn &&
          arrayData?.trading_name == formData?.trading_name
        ) {
          // setClientExist(true)
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            existClient:
              "The Name and ABN/NZBN you have provided are already registered on our system. In order to register as a new organization, please choose a different name or ABN/NZBN",
          }));
        } else {
          // setClientExist(false)
        }
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      }
    }).catch((error) => { console.log("error", error); })
  };

  // function to handle business Type
  const onBusinessType = useCallback(
    (val) => {
      setBusinessType(val);
    },
    [setBusinessType]
  );

  const handleCountryChange = (selectedOption, identifier) => {
    if (identifier === "businessAddress") {
      //  setbusinessCountryID(selectedOption.value)
      setFormData((prevFormData) => ({
        ...prevFormData,
        businessAddresCountry: selectedOption.value,
        businessAddresState: "",
      }));
      setBusinessAddresCountry(selectedOption);
      setBusinessCountryName(selectedOption?.label)
      debugger

    }
    if (identifier === "mailingAddress") {
      // setmailingCountryID(selectedOption.value)
      setFormData((prevFormData) => ({
        ...prevFormData,
        mailingAddresCountry: selectedOption.value,
        mailingAddresState: "",
      }));
      setMailingAddresCountry(selectedOption);
    }
    // setHasChanges(true);
  };

  const handleStateChange = (selectedOption, identifier) => {
    if (identifier === "businessAddress") {
      //  setBusinessStateID(selectedOption.value)
      setFormData((prevFormData) => ({
        ...prevFormData,
        businessAddresState: selectedOption?.value,
      }));
      setBusinessAddresState(selectedOption);
      setBusinessStateName(selectedOption?.label)
      debugger
    }
    if (identifier === "mailingAddress") {
      //  setMailingStateID(selectedOption.value)
      setFormData((prevFormData) => ({
        ...prevFormData,
        mailingAddresState: selectedOption.value,
      }));
      setMailingAddresState(selectedOption);
    }
    // setHasChanges(true);
  };

  const onDropHandler = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      inputChangeHandlerFile(file);
    }
  };

  const inputChangeHandlerFile = (file) => {
    // Check the file extension
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/svg+xml" ||
        fileType.startsWith("image/png") ||
        fileType.startsWith("image/jpeg") ||
        fileType.startsWith("image/gif")
      ) {
        // Check the file size (max. 800x400px)
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          if (img.width <= 800 && img.height <= 400) {
            setLogo(file);
            setUrlImage(URL.createObjectURL(file));
            setdocumentError("");
          } else {
            // Handle size validation error
            setdocumentError("Image dimensions must be within 800x400 pixels.");
          }
        };
      } else {
        // Handle file type validation error
        setdocumentError(
          "Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed."
        );
      }
    }
  };

  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const submitClient = (e) => {
    if (!formErrors.existClient) {
      if (!sameAddress) {
        // clearFormErrors();
        const abnValidation = Validations({
          value: formData?.abn_nzbn,
          Validations: { required: true, ValidateABN: true },
        });
        const typeValidation = Validations({
          value: businessType,
          Validations: { required: true },
        });
        const nameValidation = Validations({
          value: formData?.trading_name,
          Validations: { required: true },
        });

        if (!typeValidation.valid) {
          setFormError("org_type", typeValidation.error_msg);
        }
        if (!nameValidation.valid) {
          setFormError("trading_name", nameValidation.error_msg);
        }
        if (!abnValidation.valid) {
          setFormError("abn_nzbn", abnValidation.error_msg);
        }

        const add1BusinessValidation = Validations({
          value: formData?.businessAddres1,
          Validations: { required: true },
        });
        const cityBusinessValidation = Validations({
          value: formData?.businessAddresCity,
          Validations: { required: true },
        });
        const postCodeBusinessValidation = Validations({
          value: formData?.businessAddresPostCode,
          Validations: { required: true },
        });
        const countryBusinessValidation = Validations({
          value: formData?.businessAddresCountry,
          Validations: { required: true },
        });
        const stateBusinessValidation = Validations({
          value: formData?.businessAddresState,
          Validations: { required: true },
        });

        const add1MailingValidation = Validations({
          value: formData?.mailingAddres1,
          Validations: { required: true },
        });
        const cityMailingValidation = Validations({
          value: formData?.mailingAddresCity,
          Validations: { required: true },
        });
        const postCodeMailingValidation = Validations({
          value: formData?.mailingAddresPostCode,
          Validations: { required: true },
        });
        const countryMailingValidation = Validations({
          value: formData?.mailingAddresCountry,
          Validations: { required: true },
        });
        const stateMailingValidation = Validations({
          value: formData?.mailingAddresState,
          Validations: { required: true },
        });

        if (!add1BusinessValidation.valid) {
          setFormError("businessAddres1", add1BusinessValidation.error_msg);
        }
        if (!cityBusinessValidation.valid) {
          setFormError("businessAddresCity", cityBusinessValidation.error_msg);
        }
        if (!postCodeBusinessValidation.valid) {
          setFormError(
            "businessAddresPostCode",
            postCodeBusinessValidation.error_msg
          );
        }
        if (!countryBusinessValidation.valid) {
          setFormError(
            "businessAddresState",
            countryBusinessValidation.error_msg
          );
        }
        if (!stateBusinessValidation.valid) {
          setFormError(
            "businessAddresCountry",
            stateBusinessValidation.error_msg
          );
        }

        if (!add1MailingValidation.valid) {
          setFormError("mailingAddres1", add1MailingValidation.error_msg);
        }
        if (!cityMailingValidation.valid) {
          setFormError("mailingAddresCity", cityMailingValidation.error_msg);
        }
        if (!postCodeMailingValidation.valid) {
          setFormError(
            "mailingAddresPostCode",
            postCodeMailingValidation.error_msg
          );
        }
        if (!countryMailingValidation.valid) {
          setFormError(
            "mailingAddresState",
            countryMailingValidation.error_msg
          );
        }
        if (!stateMailingValidation.valid) {
          setFormError(
            "mailingAddresCountry",
            stateMailingValidation.error_msg
          );
        }
        if (
          typeValidation.valid === true &&
          nameValidation.valid === true &&
          abnValidation.valid === true &&
          add1BusinessValidation.valid === true &&
          cityBusinessValidation.valid === true &&
          postCodeBusinessValidation.valid === true &&
          countryBusinessValidation.valid === true &&
          stateBusinessValidation.valid === true &&
          add1MailingValidation.valid === true &&
          cityMailingValidation.valid === true &&
          postCodeMailingValidation.valid === true &&
          countryMailingValidation.valid === true &&
          stateMailingValidation.valid === true
        ) {
          debugger
          const payload = {
            organisation_uuid: organizationUuid,
            org_type:
              businessType === "pty"
                ? "pty_traders"
                : businessType === "sole"
                  ? "sole_traders"
                  : "other", //Assuming businessType is one of "pty", "sole", or "other"
            trading_name: formData?.trading_name,
            abn_nzbn: formData?.abn_nzbn,
            // abn_nzbn: abn,
            acn: formData?.acn,
            website_url: formData?.website_url,
            logo: logo,
            client_address_data: JSON.stringify([
              {
                address_type: "business",
                address_one: formData?.businessAddres1,
                address_two: formData?.businessAddres2,
                suburb: formData?.businessAddresCity,
                zipcode: formData?.businessAddresPostCode,
                state_id: formData?.businessAddresState,
                country_id: formData?.businessAddresCountry,
                state_name: businessAddresState.label,
                country_name: businessAddresCountry.label,
                address_uuid: businessUuid ? businessUuid : "",
              },
              {
                address_type: "mailing",
                address_one: formData?.mailingAddres1,
                address_two: formData?.mailingAddres2,
                suburb: formData?.mailingAddresCity,
                zipcode: formData?.mailingAddresPostCode,
                state_id: formData?.mailingAddresState,
                country_id: formData?.mailingAddresCountry,
                state_name: mailingAddresState.label,
                country_name: mailingAddresCountry.label,
                address_uuid: mailingUuid ? mailingUuid : "",
              },
            ]),
          };

          setButtonDisabled(true)
          dispatch(UpdateClientDetails(payload))
            .then((response) => {
              if (response?.success) {
                DissmissError()
                setdialogAddOpen(true);
                setButtonDisabled(false)
                props?.onDialogClose();
                clearFormErrors("");
                clearFormData("");
              } else if (response?.status === 401) {
                handleUnauthorizedAccess()
              }
            })
            .catch((error) => {
              console.error('Error:', error);
            })
            .finally(() => {
              setButtonDisabled(false);
            });
        }
      } else if (sameAddress) {
        // clearFormErrors();
        const abnValidation = Validations({
          value: formData?.abn_nzbn,
          Validations: { required: true, ValidateABN: true },
        });
        const typeValidation = Validations({
          value: businessType,
          Validations: { required: true },
        });
        const nameValidation = Validations({
          value: formData?.trading_name,
          Validations: { required: true },
        });

        if (!typeValidation.valid) {
          setFormError("org_type", typeValidation.error_msg);
        }
        if (!nameValidation.valid) {
          setFormError("trading_name", nameValidation.error_msg);
        }
        if (!abnValidation.valid) {
          setFormError("abn_nzbn", abnValidation.error_msg);
        }

        const add1BusinessValidation = Validations({
          value: formData?.businessAddres1,
          Validations: { required: true },
        });
        const cityBusinessValidation = Validations({
          value: formData?.businessAddresCity,
          Validations: { required: true },
        });
        const postCodeBusinessValidation = Validations({
          value: formData?.businessAddresPostCode,
          Validations: { required: true },
        });
        const countryBusinessValidation = Validations({
          value: formData?.businessAddresCountry,
          Validations: { required: true },
        });
        const stateBusinessValidation = Validations({
          value: formData?.businessAddresState,
          Validations: { required: true },
        });

        if (!add1BusinessValidation.valid) {
          setFormError("businessAddres1", add1BusinessValidation.error_msg);
        }
        if (!cityBusinessValidation.valid) {
          setFormError("businessAddresCity", cityBusinessValidation.error_msg);
        }
        if (!postCodeBusinessValidation.valid) {
          setFormError(
            "businessAddresPostCode",
            postCodeBusinessValidation.error_msg
          );
        }
        if (!countryBusinessValidation.valid) {
          setFormError(
            "businessAddresState",
            countryBusinessValidation.error_msg
          );
        }
        if (!stateBusinessValidation.valid) {
          setFormError(
            "businessAddresCountry",
            stateBusinessValidation.error_msg
          );
        }

        if (
          typeValidation.valid === true &&
          nameValidation.valid === true &&
          abnValidation.valid === true &&
          add1BusinessValidation.valid === true &&
          cityBusinessValidation.valid === true &&
          postCodeBusinessValidation.valid === true &&
          countryBusinessValidation.valid === true &&
          stateBusinessValidation.valid === true
        ) {
          debugger
          const payload = {
            organisation_uuid: organizationUuid,
            org_type:
              businessType === "pty"
                ? "pty_traders"
                : businessType === "sole"
                  ? "sole_traders"
                  : "other",
            trading_name: formData?.trading_name,
            abn_nzbn: formData?.abn_nzbn,
            acn: formData?.acn,
            website_url: formData?.website_url,
            logo: logo,
            client_address_data: JSON.stringify([
              {
                address_type: "business",
                address_one: formData?.businessAddres1,
                address_two: formData?.businessAddres2,
                suburb: formData?.businessAddresCity,
                zipcode: formData?.businessAddresPostCode,
                state_id: formData?.businessAddresState,
                country_id: formData?.businessAddresCountry,
                state_name: businessAddresState?.label,
                country_name: businessAddresCountry?.label,
                address_uuid: businessUuid ? businessUuid : "",
              },
              {
                address_type: "mailing",
                address_one: formData?.businessAddres1,
                address_two: formData?.businessAddres2,
                suburb: formData?.businessAddresCity,
                zipcode: formData?.businessAddresPostCode,
                state_id: formData?.businessAddresState,
                country_id: formData?.businessAddresCountry,
                state_name: businessAddresState.label,
                country_name: businessAddresCountry.label,
                address_uuid: mailingUuid ? mailingUuid : "",
              },
            ]),
          };

          setButtonDisabled(true)
          dispatch(UpdateClientDetails(payload))
            .then((response) => {
              if (response?.success) {
                DissmissError()
                setButtonDisabled(false)
                setdialogAddOpen(true);
                props?.onDialogClose();
                clearFormErrors("");
                clearFormData("");
              } else if (response?.status === 401) {
                handleUnauthorizedAccess()
              }
            })
            .catch((error) => {
              console.error('Error:', error);
            })
            .finally(() => {
              setButtonDisabled(false);
            });
        }
      }
    }
  };

  const onAddDialogClose = () => {
    if (!buttonDisabled) {
      setdialogAddOpen(false);
    }
  };

  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
        <div>
          <h5 className="text-base text-gray-900 font-semibold mb-1">
            Personal info
          </h5>
          <p className="text-gray-600">
            Update your photo and personal details here.
          </p>
        </div>

        <div className="flex justify-end items-center">
          <BtnLight disabled={props?.settingsPermission || buttonDisabled} className="w-auto px-4">Cancel</BtnLight>
          <SmallButton
            className="w-auto px-4 ml-3"
            onClick={() => submitClient()}
            disabled={props?.settingsPermission || buttonDisabled}
          >
            Save
          </SmallButton>
        </div>

      </div>
      {formErrors.existClient && (
        <p className="text-xs text-red-600 mb-4 mt-1 pt-8">
          {formErrors.existClient}
        </p>
      )}
      <div className="flex items-start border-b border-gray-200 pb-5 mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Company logo
        </label>

        <div className="columns-input">
          <div className="flex flex-row">
            <span className="h-16 w-16 bg-gray-100 rounded-full flex justify-center items-center mr-5 avtar_img_round">
              {urlImage ? (
                <img className="w-16 h-16 rounded-full" src={urlImage} alt="" />
              ) : (
                <img src={imagePlus} alt="" />
              )}
            </span>
            <div className="flex items-center justify-center  flex-1 upload-img ">
              <div className="flex items-center justify-center  flex-1 upload-img ">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full border py-4 px-6 rounded-xl  border-gray-200  hover:bg-gray-25 bg-white"
                >
                  <div
                    className="flex items-center justify-center  flex-1 upload-img ml-8"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={onDropHandler}
                  >
                    <div className="flex flex-col items-center justify-center">
                      <p className="mb-2 text-xs text-gray-600 darks:text-gray-400">
                        <span className="font-semibold text-blue-700">
                          Click to upload
                        </span>
                        <span className="text-xs"> or drag and drop</span>
                      </p>
                      <p className="text-xs text-gray-600 darks:text-gray-400 font-inter">
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </p>
                      {documentError && (
                        <div className="text-red-500 text-sm mt-1">
                          {documentError}
                        </div>
                      )}
                    </div>
                  </div>
                  <input
                    disabled={props?.settingsPermission}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={(e) => inputChangeHandlerFile(e.target.files[0])}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start  mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Business type*
        </label>
        <div className="flex items-center">
          <Segment
            className="docType-btn"
            value={businessType}
            onChange={(val) => onBusinessType(val)}
          >
            <Segment.Item value="pty">
              <span>Pty Ltd</span>
            </Segment.Item>
            <Segment.Item value="sole">
              <span>Sole Trader</span>
            </Segment.Item>
            <Segment.Item value="other">
              <span>Other</span>
            </Segment.Item>
          </Segment>
        </div>
      </div>
      <div className="flex items-start  mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Business name*
        </label>
        <div className=" items-center w-8/12">
          <input
            disabled={props?.settingsPermission}
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="Enter business name here"
            value={formData?.trading_name}
            readOnly
          />
          {formErrors.trading_name && (
            <p className="text-xs text-red-600 mb-4 mt-1">
              {formErrors.trading_name}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">ABN/NZBN*</label>
        <div className=" items-center w-8/12">
          <input
            disabled={props?.settingsPermission}
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            // id="abn_nzbn"
            type="number"
            placeholder="Enter ABN/NZBN here"
            value={formData?.abn_nzbn}
            onChange={(e) => inputchangehandler(e, "abn_nzbn")}
            onBlur={handleABN}
            maxLength="14"
            minLength="11"
          />
          {formErrors.abn_nzbn && (
            <p className="text-xs text-red-600 mb-4 mt-1">
              {formErrors.abn_nzbn}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">ACN</label>
        <div className="flex items-center w-8/12">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            disabled={props?.settingsPermission}
            type="text"
            placeholder="Enter ACN here"
            value={formData?.acn}
            onChange={(e) => inputchangehandler(e, "acn")}
          />
        </div>
      </div>

      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">Website</label>
        <div className="flex items-center w-8/12">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-r-0 rounded-tr-none rounded-br-none"
            id="email"
            type="text"
            disabled={props?.settingsPermission}
            value={formData?.website_url}
            onChange={(e) => inputchangehandler(e, "website_url")}
            onFocus={handleFocusWebsite}
          />
        </div>
      </div>
      <div className="flex items-start mb-5">
        <label className="text-gray-700 font-semibold w-2/12">
          Business Address*
        </label>
        <div className="flex items-center w-8/12 flex-wrap">
          <input
            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1"
            id="email"
            disabled={props?.settingsPermission}
            type="text"
            placeholder="Street Address*"
            value={formData?.businessAddres1}
            onChange={(e) => inputchangehandler(e, "businessAddres1")}
          />
          {formErrors.businessAddres1 && (
            <p className="text-xs text-red-600 mb-1 mt-1">
              {formErrors.businessAddres1}
            </p>
          )}

          <div className="flex w-full">
            <div className="w-1/3 mx-1">
              <input
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1"
                id="businessAddres2"
                type="text"
                disabled={props?.settingsPermission}
                placeholder="Apartment, suite, etc"
                value={formData?.businessAddres2}
                onChange={(e) => inputchangehandler(e, "businessAddres2")}
              />
            </div>

            <div className="w-1/3 mx-1">
              <input
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1"
                id="businessAddresCity"
                type="text"
                disabled={props?.settingsPermission}
                placeholder="Suburb*"
                value={formData?.businessAddresCity}
                onChange={(e) => inputchangehandler(e, "businessAddresCity")}
              />
              {formErrors.businessAddresCity && (
                <p className="text-xs text-red-600 mt-1">{formErrors.businessAddresCity}</p>
              )}
            </div>

            <div className="w-1/3 mx-1">
              <input
                type="number"
                disabled={props?.settingsPermission}
                placeholder="Postcode*"
                className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1"
                value={formData?.businessAddresPostCode}
                onChange={(e) => {
                  if (e?.target?.value?.length <= 10) {
                    inputchangehandler(e, "businessAddresPostCode");
                  }
                }}
              />
              {formErrors.businessAddresPostCode && (
                <p className="text-xs text-red-600 mt-1">{formErrors.businessAddresPostCode}</p>
              )}
            </div>
          </div>

          <div className="flex w-full">
            <div className="w-full flex-1 mx-1">
              <Select
                isSearchable={false}
                placeholder="Country"
                options={countries}
                readOnly={props?.settingsPermission}
                className="select-option w-full h-10"
                value={countries?.find(option => option.value === formData?.businessAddresCountry)} // Use .find instead of .filter for single selection
                onChange={(e) => handleCountryChange(e, "businessAddress")}
              />
              {formErrors.businessAddresCountry && (
                <p className="text-xs text-red-600 mt-1">{formErrors.businessAddresCountry}</p>
              )}
            </div>

            <div className="w-full flex-1 mx-1">
              <Select
                isSearchable={false}
                placeholder="State"
                readOnly={props?.settingsPermission}
                options={statesOne}
                className="select-option w-full h-10"
                value={statesOne?.find(option => option.value === formData?.businessAddresState)} // Use .find instead of .filter for single selection
                onChange={(e) => handleStateChange(e, "businessAddress")}
              />
              {formErrors.businessAddresState && (
                <p className="text-xs text-red-600 mt-1">{formErrors.businessAddresState}</p>
              )}
            </div>
          </div>

        </div>
      </div>

      <div>
        <div className="my-4">
          <input
            disabled={props?.settingsPermission}
            className="checkbox"
            style={{
              appearance: "auto",
              color: "blue",
              borderRadius: "30%",
            }}
            type="checkbox"
            value={sameAddress ? sameAddress : false}
            checked={sameAddress ? sameAddress : false}
            onChange={() => setSameAddress(!sameAddress)}
          />
          <label className="text-gray-700 font-medium p-3">
            Same as Business Address
          </label>{" "}
        </div>
        <div className="flex items-start mb-5">
          <label className="text-gray-700 font-semibold w-2/12">
            Mailing Address*
          </label>
          <div className="flex items-center w-8/12 flex-wrap">
            <input
              disabled={props?.settingsPermission || sameAddress}
              className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1"
              id="email"
              type="text"
              placeholder="Street Address*"
              // value={formData?.mailingAddres1}
              value={!sameAddress ? formData?.mailingAddres1 : formData?.businessAddres1}
              onChange={(e) => inputchangehandler(e, "mailingAddres1")}
            />
            {formErrors.mailingAddres1 && (
              <p className="text-xs text-red-600 mb-1 mt-1">
                {formErrors.mailingAddres1}
              </p>
            )}

            <div className="flex w-full">
              <div className="w-1/3 mx-1">
                <input
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1"
                  id="apartment"
                  disabled={props?.settingsPermission || sameAddress}
                  type="text"
                  placeholder="Apartment, suite, etc"
                  value={!sameAddress ? formData?.mailingAddres2 : formData?.businessAddres2}
                  onChange={(e) => inputchangehandler(e, "mailingAddres2")}
                />
              </div>

              <div className="w-1/3 mx-1">
                <input
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1"
                  id="suburb"
                  disabled={props?.settingsPermission || sameAddress}
                  type="text"
                  placeholder="Suburb*"
                  value={!sameAddress ? formData?.mailingAddresCity : formData?.businessAddresCity}
                  onChange={(e) => inputchangehandler(e, "mailingAddresCity")}
                />
                {formErrors.mailingAddresCity && (
                  <p className="text-xs text-red-600 mt-1">{formErrors.mailingAddresCity}</p>
                )}
              </div>

              <div className="w-1/3 mx-1">
                <input
                  className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1"
                  disabled={props?.settingsPermission || sameAddress}
                  type="number"
                  placeholder="Postcode*"
                  value={!sameAddress ? formData?.mailingAddresPostCode : formData?.businessAddresPostCode}
                  onChange={(e) => {
                    if (e?.target?.value?.length <= 10) {
                      inputchangehandler(e, "mailingAddresPostCode");
                    }
                  }}
                />
                {formErrors.mailingAddresPostCode && (
                  <p className="text-xs text-red-600 mt-1">{formErrors.mailingAddresPostCode}</p>
                )}
              </div>
            </div>

            <div className="flex w-full">
              {sameAddress ? (
                <>
                  <input
                    disabled={props?.settingsPermission || sameAddress}
                    type="text"
                    className="flex-1 mx-1 input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    value={businessCountryName}
                  />
                  <input
                    disabled={props?.settingsPermission || sameAddress}
                    type="text"
                    className="flex-1 mx-1 input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                    value={businessStateName}
                  />
                </>
              ) : (
                <>
                  <Select
                    isSearchable={false}
                    placeholder="Country"
                    readOnly={props?.settingsPermission || sameAddress}
                    options={countries}
                    className="select-option w-full h-10 flex-1 mx-1"
                    value={countries?.filter(function (option) {
                      return option.value === formData?.mailingAddresCountry;
                    })}
                    onChange={(e) => handleCountryChange(e, "mailingAddress")}
                  />
                  <Select
                    readOnly={props?.settingsPermission || sameAddress}
                    isSearchable={false}
                    placeholder="State"
                    options={statesTwo}
                    className="select-option w-full h-10 flex-1 mx-1"
                    value={statesTwo?.filter(function (option) {
                      return option.value === formData?.mailingAddresState;
                    })}
                    onChange={(e) => handleStateChange(e, "mailingAddress")}
                  />
                </>
              )}
            </div>

            <div className="flex w-full">
              {/* Error message for Country */}
              {sameAddress ? (
                formErrors.businessAddresCountry && (
                  <p className="text-xs text-red-600 mb-4 mt-1 w-full">
                    {formErrors.businessAddresCountry}
                  </p>
                )
              ) : (
                formErrors.mailingAddresCountry && (
                  <p className="text-xs text-red-600 mb-4 mt-1 w-full">
                    {formErrors.mailingAddresCountry}
                  </p>
                )
              )}

              {/* Error message for State */}
              {sameAddress ? (
                formErrors.businessAddresState && (
                  <p className="text-xs text-red-600 mb-4 mt-1 w-full">
                    {formErrors.businessAddresState}
                  </p>
                )
              ) : (
                formErrors.mailingAddresState && (
                  <p className="text-xs text-red-600 mb-4 mt-1 w-full">
                    {formErrors.mailingAddresState}
                  </p>
                )
              )}
            </div>

          </div>
        </div>
      </div>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                fill="#D1FADF"
              />
              <rect
                x="0.583008"
                y="1.37207"
                width="47"
                height="47"
                rx="23.5"
                stroke="#ECFDF3"
              />
              <path
                d="M32.083 18.8721L21.083 29.8721L16.083 24.8721"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Company profile has been saved successfully
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => onAddDialogClose()}>Close</BtnLight>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default CompanyProfile;
