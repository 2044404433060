import React, { useState } from "react";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import EditUser from "../../Dialog/Clients/ClientSettings/EditUser";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import Tooltip from "../../../../../../common/element/Tooltip";
const UserList = () => {
  const [open, setOpen] = useState(0);
  const [dialogDeleteOpen, setDeleteIsOpen] = useState(false);
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const onSwitcherToggle = (val, e) => {};
  const [dialogIsOpen, setIsOpen] = useState(false);
  const openDialog = () => {
    setIsOpen(true);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  const openDeleteDialog = () => {
    setDeleteIsOpen(true);
  };
  const onDeleteDialogClose = (e) => {
    setDeleteIsOpen(false);
  };
  return (
    <React.Fragment>
      <div className="overflow-x-auto">
        <div className="w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="bg-gray-100 px-4 py-3 text-xs font-medium text-left text-gray-600 w-2/5"
                  >
                    <span className="flex items-center">User name</span>
                  </th>
                  <th
                    scope="col"
                    className="bg-gray-100 px-4 py-3 text-xs font-medium text-left text-gray-600 w-1/4"
                  >
                    <span className="inline-flex items-center">Email</span>
                  </th>

                  <th
                    scope="col"
                    className="bg-gray-100 px-4 py-3 text-xs font-medium text-left text-gray-600 "
                  >
                    <span className="flex items-center">
                      Last active{" "}
                      <span className="ml-1 ">
                        <Tooltip
                          title="Account Billing contact is responsible for billing and payment on the account."
                          placement="top-start"
                        >
                          <div className="text-center cursor-pointer">
                            <img
                              src="/assets/icons-svg/help-circle.svg"
                              alt=""
                            />
                          </div>
                        </Tooltip>
                      </span>
                    </span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr>
                  <td className="px-4 py-3.5 text-xs font-medium text-gray-900 flex items-center">
                    First name Last name
                  </td>
                  <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">
                    name@emailaddress.com
                  </td>
                  <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">
                    00 hrs ago
                  </td>
                  <td align="center">
                    <button onClick={() => openDeleteDialog()} className="mr-4">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                          stroke="#101828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <button onClick={() => openDialog()}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                          stroke="#101828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-3.5 text-xs font-medium text-gray-900 flex items-center">
                    First name Last name
                  </td>
                  <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">
                    name@emailaddress.com
                  </td>
                  <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">
                    00 hrs ago
                  </td>
                  <td align="center">
                    <button onClick={() => openDeleteDialog()} className="mr-4">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                          stroke="#101828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <button onClick={() => openDialog()} c>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                          stroke="#101828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-3.5 text-xs font-medium text-gray-900 flex items-center">
                    First name Last name
                  </td>
                  <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">
                    name@emailaddress.com
                  </td>
                  <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">
                    00 hrs ago
                  </td>
                  <td align="center">
                    <button onClick={() => openDeleteDialog()} className="mr-4">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                          stroke="#101828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <button onClick={() => openDialog()} c>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                          stroke="#101828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-3.5 text-xs font-medium text-gray-900 flex items-center">
                    First name Last name
                  </td>
                  <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">
                    name@emailaddress.com
                  </td>
                  <td className=" px-4 py-3.5 text-xs font-medium text-gray-900">
                    00 hrs ago
                  </td>
                  <td align="center">
                    <button onClick={() => openDeleteDialog()} className="mr-4">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                          stroke="#101828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <button onClick={() => openDialog()}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                          stroke="#101828"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/user.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Edit user access for [Name]
            </h5>
            <p className="text-gray-600">Modify user’s access.</p>
          </div>
        </div>
        <EditUser />
      </Dialog>
      <Dialog
        isOpen={dialogDeleteOpen}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to delete this user?
          </h4>
          <p className=" text-gray-600 text-center mt-1">
            This action cannot be undone. The user will lose all access to the
            app.
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight className="mr-1.5">Cancel</BtnLight>
          <SmallButton className="ml-1.5">Confirm</SmallButton>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default UserList;
