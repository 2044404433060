import Card from "../../../../../../common/element/Card"
import BtnLight from '../../../../../../common/element/Buttons/btnLight'
import { Link } from "react-router-dom"
const ClientInformation = () =>{
    return(
        <Card className='p-6 border border-gray-200 rounded-xl dark-shadow'>
                                    <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-5">
                                        <span className="text-gray-900 text-base font-semibold flex items-center">Provider Information</span>
                                        <BtnLight className='h-9 w-20 flex items-center justify-center gap-2'>Edit <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M14.9987 8.33326L11.6654 4.99993M2.08203 17.9166L4.90234 17.6032C5.24691 17.5649 5.4192 17.5458 5.58024 17.4937C5.72311 17.4474 5.85907 17.3821 5.98444 17.2994C6.12574 17.2062 6.24832 17.0836 6.49347 16.8385L17.4987 5.83326C18.4192 4.91279 18.4192 3.4204 17.4987 2.49993C16.5782 1.57945 15.0859 1.57945 14.1654 2.49992L3.16014 13.5052C2.91499 13.7503 2.79241 13.8729 2.69923 14.0142C2.61656 14.1396 2.55121 14.2755 2.50496 14.4184C2.45283 14.5794 2.43368 14.7517 2.3954 15.0963L2.08203 17.9166Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg></BtnLight>
                                    </div>
                                    <div className="flex mb-3">
                                        <div className="flex-1">
                                            <span className="text-gray-400 font-semibold block text-sm mb-1">Business name</span>
                                            <span className="text-gray-900 font-medium block text-sm">Business name here</span>
                                        </div>
                                    </div>
                                    <div className="flex mb-3">
                                        <div className="flex-1">
                                            <span className="text-gray-400 font-semibold block text-sm mb-1">ABN/AZBN</span>
                                            <span className="text-gray-900 font-medium block text-sm">[ABN/NZBN here]</span>
                                        </div>
                                        <div className="flex-1">
                                            <span className="text-gray-400 font-semibold block text-sm mb-1">ACN</span>
                                            <span className="text-gray-900 font-medium block text-sm">[ACN here]</span>
                                        </div>
                                    </div>
                                    <div className="flex mb-3">
                                        <div className="flex-1">
                                            <span className="text-gray-400 font-semibold block text-sm mb-1">Website</span>
                                            <span className="text-gray-900 font-medium flex gap-1 text-sm">[Website url here] <svg width="16" height="15" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7" stroke="#000" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                                        </div>
                                    </div>
                                    <div className="flex mb-3">
                                        <div className="flex-1">
                                            <span className="text-gray-400 font-semibold block text-sm mb-1">Business address</span>
                                            <span className="text-gray-900 font-medium flex gap-1 text-sm">[Address here]</span>
                                        </div>
                                    </div>
                                    <div className="flex mb-0">
                                        <div className="flex-1">
                                            <span className="text-gray-400 font-semibold block text-sm mb-1">Country</span>
                                            <span className="text-gray-900 font-medium block text-sm">[Country here]</span>
                                        </div>
                                        <div className="flex-1">
                                            <span className="text-gray-400 font-semibold block text-sm mb-1">State</span>
                                            <span className="text-gray-900 font-medium block text-sm">[State here]</span>
                                        </div>
                                    </div>
                                </Card>
    )
}
export default ClientInformation 