import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useTimeout from '../hooks/useTimeout'


import { motion } from 'framer-motion'
import CloseButton from '../CloseButton'
import StatusIcon from '../StatusIcon/StatusIcon'

const DEFAULT_TYPE = 'warning'

const TYPE_MAP = {
    success: {
        backgroundColor: 'bg-success-50',
        titleColor: 'text-success-700',
        textColor: 'text-success-700',
        iconColor: 'text-success-400',
        border: 'border',
        borderColor: 'border-success-300'
    },
    info: {
        backgroundColor: 'bg-blue-50 darks:bg-blue-500',
        titleColor: 'text-blue-700 darks:text-blue-100',
        textColor: 'text-blue-500 darks:text-blue-100',
        iconColor: 'text-blue-400 darks:text-blue-100',
        border: 'border',
        borderColor: 'border-blue-200'
 
    },
    warning: {
        backgroundColor: 'bg-warning-50 darks:bg-warning-500',
        titleColor: 'text-warning-700 darks:text-warning-50',
        textColor: 'text-warning-500 darks:text-warning-50',
        iconColor: 'text-warning-400 darks:text-warning-50',
        border: 'border',
        borderColor: 'border-warning-300'
   
    },
    danger: {
        backgroundColor: 'bg-red-50 darks:bg-red-500',
        titleColor: 'text-red-700 darks:text-red-100',
        textColor: 'text-red-500 darks:text-red-100',
        iconColor: 'text-red-400 darks:text-red-100',
        border: 'border',
        borderColor: 'border-error-300'
     
    },
    secondary: {
        backgroundColor: 'bg-gray-50 darks:bg-gray-50',
        titleColor: 'text-gray-700 darks:text-gray-700',
        textColor: 'text-gray-600 darks:text-gray-600',
        iconColor: 'text-gray-700 darks:text-gray-700',
        border: 'border',
        borderColor: 'border-gray-300'
    },
}


const TYPE_ARRAY = ['success', 'danger', 'info', 'warning', 'secondary']

const Alert = React.forwardRef((props, ref) => {
    const {
        className,
        children,
        title,
        showIcon,
        customIcon,
        closable,
        customClose,
        onClose,
        duration,
        rounded,
        triggerByToast,
        ...rest
    } = props

    const getType = () => {
        const { type } = props
        if (TYPE_ARRAY.includes(type)) {
            return type
        }
        return DEFAULT_TYPE
    }

    const type = getType()
    const typeMap = TYPE_MAP[type]

    const [display, setDisplay] = useState('show')

    const { clear } = useTimeout(onClose, duration, duration > 0)

    const handleClose = (e) => {
        setDisplay('hiding')
        onClose?.(e)
        clear()
        if (!triggerByToast) {
            setTimeout(() => {
                setDisplay('hide')
            }, 400)
        }
    }

    const renderClose = () => {
        return (
            <div className="cursor-pointer" onClick={(e) => handleClose(e)}>
                {customClose || <CloseButton defaultStyle={false} />}
            </div>
        )
    }

    const alertDefaultClass = 'p-4 relative flex'

    const alertClass = classNames(
        'alert',
        alertDefaultClass,
        typeMap.backgroundColor,
        typeMap.textColor,
        typeMap.border,
        typeMap.borderColor,
        !title ? 'font-semibold' : '',
        closable ? 'justify-between' : '',
        closable && !title ? 'items-center' : '',
        'rounded-[12px]',
        className
    )

    if (display === 'hide') {
        return null
    }

    return (
        <motion.div
            ref={ref}
            className={alertClass}
            initial={{ opacity: 1 }}
            animate={display === 'hiding' ? 'exit' : 'animate'}
            transition={{ duration: 0.25, type: 'tween' }}
            variants={{
                animate: {
                    opacity: 1,
                },
                exit: {
                    opacity: 0,
                },
            }}
            {...rest}
        >
            <div className={`flex ${title ? '' : 'items-center'}`}>
                {showIcon && (
                    <StatusIcon
                        iconColor={typeMap.iconColor}
                        custom={customIcon}
                        type={type}
                    />
                )}
                <div className={showIcon ? 'ml-2' : null}>
                    {title ? (
                        <div
                            className={`font-semibold mb-1 ${typeMap.titleColor}`}
                        >
                            {title}
                        </div>
                    ) : null}
                    {children}
                </div>
            </div>
            {closable ? renderClose() : null}
        </motion.div>
    )
})

Alert.defaultProps = {
    type: DEFAULT_TYPE,
    showIcon: false,
    triggerByToast: false,
    closable: false,
    duration: 3000,
    title: null,
    rounded: true,
}

Alert.propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'danger','secondary']),
    showIcon: PropTypes.bool,
    triggerByToast: PropTypes.bool,
    closable: PropTypes.bool,
    duration: PropTypes.number,
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    rounded: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClose: PropTypes.func,
}

export default Alert
