
import { createSlice } from '@reduxjs/toolkit'
const updateInductionStatusSite = createSlice({
  name:"updateInductionStatusSite",
  initialState:false,
  reducers:{
    UpdateInductionStatusSite(state,action){
      return action.payload;
    },
    ResetInductionStatusSite(){
      return null
    }
  }
}) 
export default updateInductionStatusSite.reducer;
export const {UpdateInductionStatusSite,ResetInductionStatusSite}=updateInductionStatusSite.actions;
                                            