import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetDocumentHistoryByIdSlice";


// export const GetAllClientArticles = () => async (dispatch) => {
//   try {
//     // Step 1: Fetch articles by collection
//     const response = await axios({
//       method: 'get',
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
//       },
//       url: `${actionType.helpScout_url}/collections/${actionType.client_collectionId}/articles`,
//       auth: {
//         username: `${actionType.helpScout_api_key}`,
//         password: `${actionType.helpScout_password}`
//       }
//     });

//     const articles = response.data.articles.items;

//     // Step 2: Fetch full details for each article
//     const articleDetailsPromises = articles.map(article =>
//       axios({
//         method: 'get',
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
//         },
//         url: `${actionType.helpScout_url}/articles/${article.id}`,
//         auth: {
//           username: `${actionType.helpScout_api_key}`,
//           password: `${actionType.helpScout_password}`
//         }
//       })
//     );

//     const articlesDetailsResponses = await Promise.all(articleDetailsPromises);
//     const fullArticles = articlesDetailsResponses.map(response => response.data.article);

//     return fullArticles;
//   } catch (error) {
//     console.error("Error fetching client articles:", error);
//   }
// };



export const GetAllClientArticles = () => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.helpScout_url}/collections/${actionType.client_collectionId}/articles`,
      auth: {
        username: `${actionType.helpScout_api_key}`,
        password: `${actionType.helpScout_password}`
      }
    });
    return response.data
  } catch (error) {
    console.error("Error fetching client articles:", error);
  }
};

export const GetSearchClientArticles = (searchQuery) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.helpScout_url}/search/articles?query=${encodeURIComponent(searchQuery)}&collectionId=${actionType.client_collectionId}`,
      auth: {
        username: `${actionType.helpScout_api_key}`,
        password: `${actionType.helpScout_password}`
      }
    });
    return response.data
  } catch (error) {
    console.error("Error fetching client articles:", error);
  }

}