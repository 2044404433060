import React, { useEffect, useState } from "react";
import WorkerInformation from "./WorkerInformation";
import Documents from "./Documents";
import Inductions from "./Indunctions";
// import { GetWorkerOverviewDetailsById } from "../../../../../../actions/admin-worker/GetWorkerOverviewDetailsById";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Dialog from "../../../../../../../common/element/Dialog";
import SettingIcon from "../../../../../../../assets/icons-svg/setting.svg";
import { setBreadcrumbs } from "../../../../../../../redux/classes/breadcrumbsSlice";
import ClientDetails from "../../Dialog/Clients/ClientSettings/ClientDetails";
const Overview = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [userSetting, showUserSetting] = useState(false);
  const { workerRedirectSpecificData } = useSelector((state) => state);
  const { specificWorker } = useSelector((state) => state);
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All",
          url: "/provider/workers/",
          home: "/provider/dashboard",
        },
        {
          label: `${specificWorker?.worker_individual?.first_name || ""} ${
            specificWorker?.worker_individual?.last_name || ""
          }`,
          url: `/provider/worker/view-all-worker/specific-worker/${id}`,
        },
        {
          label: "Overview",
          url: `/admin/worker/view-all-worker/specific-worker/${id}`,
        },
      ])
    );
  }, []);
  const openAddDialog = () => {
    setAddOpen(true);
  };
  // Hide the user settings dialog
  const userSettingHide = () => {
    setTimeout(() => {
      // showUserSetting(false);
      setAddOpen(false);
    }, 0);
    setAddOpen(false);
  };
  return (
    <React.Fragment>
      <div className="grid grid-container gap-8">
        <div className="flex gap-8 flex-col">
          <WorkerInformation
            openAddDialog={openAddDialog}
            workerRedirectSpecificData={workerRedirectSpecificData}
          />
        </div>
        <div className="flex gap-8 flex-col">
          <Inductions />
          <Documents />
        </div>
      </div>
      {/* <Dialog
        isOpen={dialogAddOpen}
        onClose={userSettingHide}
        onRequestClose={userSettingHide}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b  pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={SettingIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Settings sdas
            </h5>
          </div>
        </div>
        <div className="pt-2 px-6">
          <ClientDetails userSettingHide={userSettingHide} />
        </div>
      </Dialog> */}
    </React.Fragment>
  );
};
export default Overview;
