
import { createSlice } from '@reduxjs/toolkit'
const deletedocumentSite = createSlice({
  name:"deletedocumentSite",
  initialState:false,
  reducers:{
    DeletedocumentDataSite(state,action){
      return action.payload;
    },
    ResetdocumentDataSite(){
      return null
    }
  }
}) 
export default deletedocumentSite.reducer;
export const {DeletedocumentDataSite,ResetdocumentDataSite}=deletedocumentSite.actions;