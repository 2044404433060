import React, { useState } from "react"
import Dialog from "../../../../../../../common/element/Dialog/Dialog"
import VisitorLog from "./VisitorDetails"
import Table from "../../../../../../../common/element/Table"
const VisitorList = (props) => {

    const { Tr, Th, Td, THead, TBody } = Table;
    const [detailsView,setDetailsView]=useState(null)
    const [dialogIsOpen, setIsOpen] = useState(false)
    const openDialog = (item) => {
        setIsOpen(true)
        setDetailsView(item)
    }

    const onDialogClose = () => {
        setIsOpen(false)

    }
    return (
        <React.Fragment>
            <div className="overflow-x-auto">
                <div className="w-full inline-block align-middle">
                    <div className="overflow-hidden border rounded-lg border-gray-200">
                        <Table className="min-w-full divide-y divide-gray-200">
                            <THead className="bg-gray-100">
                                <Tr>
                                    <Th
                                        scope="col"
                                        className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 w-1/5"
                                    >
                                        <span className='flex items-center gap-1' onClick={() => props?.handleSort("first_name")}>Visitor log
                                            <svg
                                             className={`transform ${props?.filtersort === "first_name" && props?.filterorder === "asc"
                                             ? "rotate-180"
                                             : ""
                                             }`}
                                            width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></span>
                                    </Th>
                                    <Th
                                        scope="col"
                                        className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600  "
                                    >
                                        <span className="inline-flex items-center">
                                            Email

                                        </span>
                                    </Th>

                                    <Th
                                        scope="col"
                                        className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600">Phone number
                                    </Th>
                                    <Th
                                        scope="col"
                                        className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 "
                                    >
                                        <span className='flex items-center gap-1'>Job Title</span>

                                    </Th>
                                    <Th
                                        scope="col"
                                        className="bg-gray-100 px-4 py-3  font-medium text-left text-gray-600 ">
                                        <span className='flex items-center gap-1'>Company name</span>
                                    </Th>
                                    <Th
                                        scope="col"
                                        className="bg-gray-100 px-4 py-3  font-medium text-left text-gray-600 ">
                                        <span className='flex items-center gap-1'>Sign-In</span>
                                    </Th>
                                    <Th
                                        scope="col"
                                        className="bg-gray-100 px-4 py-3  font-medium text-left text-gray-600 ">
                                        <span className='flex items-center gap-1'>Signed-Out</span>
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody className="divide-y divide-gray-200">


                                {props?.allListData && props?.allListData.map((item, index) => (
                                    <Tr key={index}>
                                        <Td className="px-4 py-2 font-medium text-gray-900 ">
                                            <span className="cursor-pointer">{`${item?.first_name?item?.first_name:"-"} ${item?.last_name?item?.last_name:""}`}</span>
                                        </Td>
                                        <Td className="px-4 py-2 font-medium text-gray-900">
                                            <span className="text-gray-900 font-medium block">{item.email?item.email:"-"}</span>
                                        </Td>
                                        <Td className="px-4 py-2 font-medium text-gray-900">{item?.phone?item?.phone:"-"}</Td>
                                        <Td className="px-4 py-2 font-medium text-gray-900">{item?.jobTitle?item?.jobTitle:"-"}</Td>
                                        <Td className="px-4 py-2 font-medium text-gray-900">{item?.companyName?item?.companyName:"-"}</Td>
                                        <Td className="px-4 py-2 font-medium text-gray-900">
                                            <span className="text-gray-900 font-medium block">{item?.date?item?.date:"-"}</span>
                                            <span className="text-gray-600">{item?.time?item?.time:"-"}</span>
                                        </Td>
                                        <Td className="px-4 cursor-pointer">
                                            <span className="text-success-500 cursor-pointer" onClick={()=>openDialog(item)}>{item?.status?item?.status:"On site"}</span>
                                        </Td>
                                    </Tr>
                                ))}

                            </TBody>
                        </Table>
                    </div>
                </div>
            </div>
            <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
                bodyOpenClassName="overflow-hidden"
                className="addContact-dialog"
            >
                <div className="flex border-b mb-5 pb-5 px-6 pt-6  items-center">
                    <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4"><img src="/assets/icons-svg/image-user-check.svg" alt="" /></span>
                    <div>
                        <h5 className="mb-0 text-gray-900 text-base font-semibold">Visitor Details</h5>

                    </div>
                </div>
                <VisitorLog detailsView={detailsView} onDialogClose={onDialogClose} siteName={props?.siteName} />

            </Dialog>
        </React.Fragment>
    )
}
export default VisitorList