import React, { useState, useCallback, useEffect } from "react";
import Heading from "../../../../common/element/Heading";
import Segment from "../../../../common/element/Segment";
import Table from "../../../../common/element/Table";
import Badge from "../../../../common/element/Badge";
import Tag from "../../../../common/element/Tag";
import Scrollbar from "../../../../common/element/ScrollBar";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import Tasks from "./Tasks";
const Dashboard = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(setBreadcrumbs([{ label: "Dashboard", url: "/worker/dashboard" }]));
  }, []);
  const [singleSegmentValue, setSingleSegmentValue] = useState(["Index"]);

  const { Tr, Th, Td, THead, TBody } = Table;
  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val);
    },
    [setSingleSegmentValue]
  );
  return (
    <React.Fragment>
      <Heading className="mb-4">Dashboard</Heading>
      <div className="grid grid-container gap-8">
        <div className="flex gap-8 flex-col">
          <Tasks />
        </div>
        <div className="flex gap-8 flex-col">
          <div className="bg-white p-6 border border-gray-200 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                <span className="mr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M17.9332 21.4389H7.31473C6.17619 21.4389 5.25 20.5128 5.25 19.3742V4.62625C5.25 3.48772 6.17614 2.56152 7.31473 2.56152H17.9334C19.0719 2.56152 19.9981 3.48773 19.9981 4.62625V19.3742C19.9981 20.5127 19.0718 21.4389 17.9332 21.4389ZM18.2281 4.62592C18.2281 4.46308 18.0954 4.33094 17.9332 4.33094H7.31473C7.15248 4.33094 7.01975 4.46309 7.01975 4.62592C7.01975 20.0499 6.85515 19.6689 7.31468 19.6689C18.2524 19.6689 18.2282 19.8564 18.2282 19.3739L18.2281 4.62592Z"
                      fill="#1D2939"
                    />
                    <path
                      d="M14.0985 6.98594H11.1489C10.6605 6.98594 10.264 6.59008 10.264 6.10106C10.264 5.61203 10.6605 5.21619 11.1489 5.21619H14.0985C14.5869 5.21619 14.9834 5.61205 14.9834 6.10106C14.9834 6.59009 14.5869 6.98594 14.0985 6.98594Z"
                      fill="#1D2939"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.6287 9.56846C11.7726 9.56846 11.0786 10.2624 11.0786 11.1185C11.0786 11.9745 11.7726 12.6685 12.6287 12.6685C13.4847 12.6685 14.1787 11.9745 14.1787 11.1185C14.1787 10.2624 13.4847 9.56846 12.6287 9.56846ZM9.67864 11.1185C9.67864 9.48922 10.9994 8.16846 12.6287 8.16846C14.2579 8.16846 15.5787 9.48922 15.5787 11.1185C15.5787 12.7477 14.2579 14.0685 12.6287 14.0685C10.9994 14.0685 9.67864 12.7477 9.67864 11.1185ZM9.35309 17.1685H15.9042C15.1298 16.189 13.9486 15.5685 12.6287 15.5685C11.3087 15.5685 10.1275 16.189 9.35309 17.1685ZM8.17814 16.3994C9.20026 15.0467 10.8119 14.1685 12.6287 14.1685C14.4454 14.1685 16.057 15.0467 17.0792 16.3994C17.0843 16.4063 17.0896 16.4133 17.095 16.4203C17.1774 16.5293 17.2763 16.66 17.3483 16.7885C17.4393 16.9509 17.5115 17.1483 17.5071 17.3904C17.5035 17.5861 17.4448 17.764 17.3767 17.9008C17.3087 18.0375 17.2021 18.1917 17.0481 18.3125C16.842 18.4743 16.6204 18.5284 16.4392 18.5501C16.2854 18.5686 16.1065 18.5685 15.9426 18.5685C15.9334 18.5685 15.9242 18.5685 15.915 18.5685H9.34226C9.33312 18.5685 9.32392 18.5685 9.31468 18.5685C9.15077 18.5685 8.97192 18.5686 8.81814 18.5501C8.63694 18.5284 8.41533 18.4743 8.20918 18.3125C8.05522 18.1917 7.94864 18.0375 7.88058 17.9008C7.81252 17.764 7.75377 17.5861 7.75019 17.3904C7.74577 17.1483 7.81805 16.9509 7.90903 16.7885C7.98102 16.66 8.0799 16.5293 8.16234 16.4203C8.16768 16.4133 8.17295 16.4063 8.17814 16.3994Z"
                      fill="#1D2939"
                    />
                  </svg>
                </span>
                Worker breakdown
              </span>
              <BtnLight className="w-20 h-9">View All</BtnLight>
            </div>
            <div className="card-body pt-5">
              <div className="flex gap-3">
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-success-50 text-success-700">
                    Compliant
                  </Tag>
                  <span className="text-gray-600 text-sm font-semibold">
                    72
                  </span>
                </div>
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-error-50 text-error-700">
                    Non-compliant
                  </Tag>
                  <span className="text-gray-600 text-sm font-semibold">
                    20
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 border border-gray-200 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5">
              <span className="text-gray-900 text-base font-semibold flex items-center">
                <span className="mr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M14.625 2.26953V6.40007C14.625 6.96012 14.625 7.24015 14.734 7.45406C14.8299 7.64222 14.9828 7.7952 15.171 7.89108C15.3849 8.00007 15.6649 8.00007 16.225 8.00007H20.3555M9.625 16L11.625 18L16.125 13.5M14.625 2H9.425C7.74484 2 6.90476 2 6.26303 2.32698C5.69854 2.6146 5.2396 3.07354 4.95198 3.63803C4.625 4.27976 4.625 5.11984 4.625 6.8V17.2C4.625 18.8802 4.625 19.7202 4.95198 20.362C5.2396 20.9265 5.69854 21.3854 6.26303 21.673C6.90476 22 7.74484 22 9.425 22H15.825C17.5052 22 18.3452 22 18.987 21.673C19.5515 21.3854 20.0104 20.9265 20.298 20.362C20.625 19.7202 20.625 18.8802 20.625 17.2V8L14.625 2Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                Worker documents
              </span>
              <BtnLight className="w-20 h-9">View All</BtnLight>
            </div>
            <div className="card-body pt-5">
              <div className="flex gap-3">
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-blue-100 text-blue-800">
                    Awaiting review
                  </Tag>
                  <span className="text-gray-600 text-sm font-semibold">8</span>
                </div>
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-error-50 text-error-700">
                    Action required
                  </Tag>
                  <span className="text-gray-600 text-sm font-semibold">
                    72
                  </span>
                </div>
                <div className="h-20 flex flex-1 flex-col gap-2 items-center justify-center border border-gray-200 rounded-3xl">
                  <Tag className="bg-success-50 text-success-700">Approved</Tag>
                  <span className="text-gray-600 text-sm font-semibold">
                    72
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
