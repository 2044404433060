import axios from "axios";
import * as actionType from "../../constant/actionType";
export const DeleteProviderDoc = (ApiData) => async (dispatch) => {
      try {
        const response = await axios({
          method: 'delete',
          data: ApiData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
          },
          url: `${actionType.environment_url}/api/provider/DeleteProviderDoc`,
        });
        return response.data
      } catch (error) {
        return error.message
      }
    }