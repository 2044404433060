import axios from "axios";
import * as actionType from "../../constant/actionType";
import { fetchData, fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetDocumentHistoryByIdSlice";
export const GetWorkerDocHistoryById = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    dispatch(fetchData());
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetDocumentHistoryById`,
      params: apiData
    });
    dispatch(fetchDataSuccess(response.data));
  } catch (error) {
    dispatch(fetchDataError(error));
  }

};
export const UpdateProviderDocStatus = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'put',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateProviderDocStatus`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}
export const GetIndStatusOfWorkerByClient = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetIndStatusOfWorkerByClient`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};
export const GetWorkerSiteLogsByClient = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetWorkerSiteLogsByClient`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};

export const UpdateProviderDoc = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === 'column_names') {
        formdata.append(key, JSON.stringify(ApiData[key]));
      }
      else {
        // For other properties, append the value directly
        formdata.append(key, ApiData[key]);
      }
    }
    const response = await axios({
      method: 'put',
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateProviderDoc`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}
export const DeleteProviderDoc = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'delete',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteProviderDoc`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}

export const AssignCompChecklistToWorker = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'post',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/AssignCompChecklistToWorker`,
    });
    return response.data
  } catch (error) {
  }
}



export const GetSubmissionDocsOrChecklist = (ApiData, cancelToken) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetSubmissionDocsOrChecklist`,
      params: ApiData,
      cancelToken: cancelToken,
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error fetching provider docs:', error);
    return { success: false, error: error.message || 'Unknown error' };
  }
};

export const GetWorkerTrainingInduction = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetWorkerTrainingInduction`,
      params: getAllData
    });
    return response.data

  } catch (error) {
  }
};