import axios from "axios";
import * as actionType from "../../../constant/actionType";
export const CreateAndSubmitWorkerOtherDoc = (ApiData) => async (dispatch) => {
      try {
        let formdata = new FormData();
        for (let key in ApiData) {
          if (key === 'doc_file') {
            formdata.append('doc_file', ApiData.doc_file[0]);
          }
          else {
            formdata.append(key, ApiData[key]);
          }
        }
        const response = await axios({
          method: 'post',
          data: formdata,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
          },
          url: `${actionType.environment_url}/api/provider/CreateAndSubmitWorkerOtherDoc`,
        });
        return response.data
      } catch (error) {
        return  error.message
      }
    }