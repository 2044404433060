import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import theme from "tw.macro";
import tw from "tw.macro";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useForm } from "../Form/context";
import { useInputGroup } from "../InputGroup/context";
import { HiCheck, HiChevronDown, HiX } from "react-icons/hi";
import Spinner from "../Spinner";
import { SIZES } from "../utils/constant";
import { CONTROL_SIZES } from "../utils/constant";
import downImg from "../../../assets/icons-svg/chevron-down-gray.svg";
import cancelImg from "../../../assets/icons-svg/x-close.svg";

const DefaultOption = ({
  innerProps,
  label,
  selectProps,
  isSelected,
  isDisabled,
}) => {
  const { themeColor } = selectProps;
  return (
    <div
      className={`select-option ${isSelected && "selected"} ${
        isDisabled && "disabled"
      }`}
      {...innerProps}
    >
      <span className="ml-2">{label}</span>
      {isSelected && (
        <HiCheck className={`text-${themeColor} darks:text-white text-xl`} />
      )}
    </div>
  );
};

const DefaultDropdownIndicator = () => {
  return (
    <div className="select-dropdown-indicator mr-2">
      <img src={downImg} />
    </div>
  );
};

const DefaultClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <div className="select-clear-indicator">
        <img src={cancelImg} />
      </div>
    </div>
  );
};

const DefaultLoadingIndicator = ({ selectProps }) => {
  const { themeColor } = selectProps;
  return <Spinner className={`select-loading-indicatior text-${themeColor}`} />;
};

const Select = React.forwardRef((props, ref) => {
  const {
    size,
    style,
    className,
    form,
    field,
    components,
    componentAs: Component,
    readOnly, // New prop for readOnly functionality
    ...rest
  } = props;
  const disabled = readOnly ? true : props.isDisabled;
  const [searchValue, setSearchValue] = useState();

  const themeColor = "";
  const controlSize = "";
  const primaryColorLevel = "";
  const mode = "";
  const formControlSize = "useForm()?.size";
  const inputGroupSize = useInputGroup()?.size;

  const selectSize = size || inputGroupSize || formControlSize || controlSize;

  const twColor = theme`colors`;
  const twHeight = theme`height`;

  let isInvalid = false;

  if (!isEmpty(form)) {
    const { touched, errors } = form;

    const touchedField = get(touched, field.name);
    const errorField = get(errors, field.name);

    isInvalid = touchedField && errorField;
  }

  const getBoxShadow = (state) => {
    const shadaowBase = "0 0 0 1px ";

    if (isInvalid) {
      return shadaowBase + twColor.red["500"];
    }

    if (state.isFocused) {
      return;
    }

    return "none";
  };

  // const styles = {
  //   control: (provided, state) => {
  //     return {
  //       ...provided,
  //       height: twHeight[CONTROL_SIZES[selectSize]],
  //       minHeight: twHeight[CONTROL_SIZES[selectSize]],
  //       "&:hover": {
  //         boxShadow: getBoxShadow(state),
  //         cursor: "pointer",
  //       },
  //       boxShadow: getBoxShadow(state),
  //       borderRadius: tw`rounded-md`.borderRadius,
  //       ...(isInvalid ? { borderColor: twColor.red["500"] } : {}),
  //     };
  //   },
  //   input: (css) => {
  //     return {
  //       ...css,
  //       input: {
  //         outline: "none",
  //         outlineOffset: 0,
  //         boxShadow: "none !important",
  //       },
  //     };
  //   },
  //   menu: (provided) => ({ ...provided, zIndex: 50 }),
  //   ...style,
  // };

  // const selectClass = classNames("select", `select-${selectSize}`, className);

  const styles = {
    control: (provided, state) => {
      return {
        ...provided,
        height: twHeight[CONTROL_SIZES[selectSize]],
        minHeight: twHeight[CONTROL_SIZES[selectSize]],
        "&:hover": {
          boxShadow: getBoxShadow(state),
          cursor: "pointer",
        },
        boxShadow: getBoxShadow(state),
        borderRadius: tw`rounded-md`.borderRadius,
        ...(isInvalid ? { borderColor: twColor.red["500"] } : {}),
      };
    },
    input: (css) => {
      return {
        ...css,
        input: {
          outline: "none",
          outlineOffset: 0,
          boxShadow: "none !important",
        },
      };
    },
    menu: (provided) => ({
      ...provided,
      zIndex: 50,
    }),
    ...style,
  };

  const selectClass = classNames("select", `select-${selectSize}`, className);

  return (
    <Component
      className={selectClass}
      classNamePrefix={"select"}
      ref={ref}
      styles={styles}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
        },
      })}
      themeColor={`${themeColor}-${primaryColorLevel}`}
      components={{
        IndicatorSeparator: () => null,
        Option: DefaultOption,
        LoadingIndicator: DefaultLoadingIndicator,
        DropdownIndicator: DefaultDropdownIndicator,
        ClearIndicator: DefaultClearIndicator,
        ...components,
      }}
      options={props.options}
      value={props.value}
      onChange={props.onChange}
      isDisabled={disabled} // Pass disabled to isDisabled prop
      menuPlacement="auto" // Automatically place the menu above or below
      // menuPosition="fixed" // Position the menu relative to the viewport
      {...field}
      {...rest}
      onInputChange={props.handleSearchChange}
    />
  );
});

Select.propTypes = {
  size: PropTypes.oneOf([SIZES.LG, SIZES.MD, SIZES.SM]),
  componentAs: PropTypes.elementType,
  readOnly: PropTypes.bool, // PropType for readOnly
};

Select.defaultProps = {
  componentAs: ReactSelect,
};

export default Select;

// import React, { useState } from "react";
// import classNames from "classnames";
// import PropTypes from "prop-types";
// import ReactSelect from "react-select";
// import theme from "tw.macro";
// import tw from "tw.macro";
// import isEmpty from "lodash/isEmpty";
// import get from "lodash/get";
// import { useForm } from "../Form/context";
// import { useInputGroup } from "../InputGroup/context";
// import { HiCheck, HiChevronDown, HiX } from "react-icons/hi";
// import Spinner from "../Spinner";
// import { SIZES } from "../utils/constant";
// import { CONTROL_SIZES } from "../utils/constant";
// import downImg from "../../../assets/icons-svg/chevron-down-gray.svg";
// import cancelImg from "../../../assets/icons-svg/x-close.svg";

// const DefaultOption = ({
//   innerProps,
//   label,
//   selectProps,
//   isSelected,
//   isDisabled,
// }) => {
//   const { themeColor } = selectProps;
//   return (
//     <div
//       className={`select-option ${isSelected && "selected"} ${
//         isDisabled && "disabled"
//       }`}
//       {...innerProps}
//     >
//       <span className="ml-2">{label}</span>
//       {isSelected && (
//         <HiCheck className={`text-${themeColor} darks:text-white text-xl`} />
//       )}
//     </div>
//   );
// };

// const DefaultDropdownIndicator = () => {
//   return (
//     <div className="select-dropdown-indicator mr-2">
//       <img src={downImg} />
//     </div>
//   );
// };

// const DefaultClearIndicator = (props) => {
//   const {
//     innerProps: { ref, ...restInnerProps },
//   } = props;
//   return (
//     <div {...restInnerProps} ref={ref}>
//       <div className="select-clear-indicator">
//         <img src={cancelImg} />
//       </div>
//     </div>
//   );
// };

// const DefaultLoadingIndicator = ({ selectProps }) => {
//   const { themeColor } = selectProps;
//   return <Spinner className={`select-loading-indicatior text-${themeColor}`} />;
// };

// const Select = React.forwardRef((props, ref) => {
//   const {
//     size,
//     style,
//     className,
//     form,
//     field,
//     components,
//     componentAs: Component,
//     readOnly, // New prop for readOnly functionality
//     ...rest
//   } = props;
//   const disabled = readOnly ? true : props.isDisabled;
//   const [searchValue, setSearchValue] = useState();

//   const themeColor = "";
//   const controlSize = "";
//   const primaryColorLevel = "";
//   const mode = "";
//   const formControlSize = "useForm()?.size";
//   const inputGroupSize = useInputGroup()?.size;

//   const selectSize = size || inputGroupSize || formControlSize || controlSize;

//   const twColor = theme`colors`;
//   const twHeight = theme`height`;

//   let isInvalid = false;

//   if (!isEmpty(form)) {
//     const { touched, errors } = form;

//     const touchedField = get(touched, field.name);
//     const errorField = get(errors, field.name);

//     isInvalid = touchedField && errorField;
//   }

//   const getBoxShadow = (state) => {
//     const shadaowBase = "0 0 0 1px ";

//     if (isInvalid) {
//       return shadaowBase + twColor.red["500"];
//     }

//     if (state.isFocused) {
//       return;
//     }

//     return "none";
//   };

//   const styles = {
//     control: (provided, state) => {
//       return {
//         ...provided,
//         height: twHeight[CONTROL_SIZES[selectSize]],
//         minHeight: twHeight[CONTROL_SIZES[selectSize]],
//         "&:hover": {
//           boxShadow: getBoxShadow(state),
//           cursor: "pointer",
//         },
//         boxShadow: getBoxShadow(state),
//         borderRadius: tw`rounded-md`.borderRadius,
//         ...(isInvalid ? { borderColor: twColor.red["500"] } : {}),
//       };
//     },
//     input: (css) => {
//       return {
//         ...css,
//         input: {
//           outline: "none",
//           outlineOffset: 0,
//           boxShadow: "none !important",
//         },
//       };
//     },
//     menu: (provided) => ({
//       ...provided,
//       zIndex: 50,
//     }),
//     ...style,
//   };

//   const selectClass = classNames("select", `select-${selectSize}`, className);

//   return (
//     <Component
//       className={selectClass}
//       classNamePrefix={"select"}
//       ref={ref}
//       styles={styles}
//       theme={(theme) => ({
//         ...theme,
//         colors: {
//           ...theme.colors,
//         },
//       })}
//       themeColor={`${themeColor}-${primaryColorLevel}`}
//       components={{
//         IndicatorSeparator: () => null,
//         Option: DefaultOption,
//         LoadingIndicator: DefaultLoadingIndicator,
//         DropdownIndicator: DefaultDropdownIndicator,
//         ClearIndicator: DefaultClearIndicator,
//         ...components,
//       }}
//       options={props.options}
//       value={props.value}
//       onChange={props.onChange}
//       isDisabled={disabled} // Pass disabled to isDisabled prop
//       menuPlacement="auto" // Automatically place the menu above or below
//       menuPosition="fixed" // Position the menu relative to the viewport
//       {...field}
//       {...rest}
//       onInputChange={props.handleSearchChange}
//     />
//   );
// });

// Select.propTypes = {
//   size: PropTypes.oneOf([SIZES.LG, SIZES.MD, SIZES.SM]),
//   componentAs: PropTypes.elementType,
//   readOnly: PropTypes.bool, // PropType for readOnly
// };

// Select.defaultProps = {
//   componentAs: ReactSelect,
// };

// export default Select;
