// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'


const SiteInductionModuleDataSlice = createSlice({
  name: "SiteInductionModuleData",
  initialState: [], // Always initialize with an empty array
  reducers: {
    AddSiteInductionModuleData(state, action) {
      state.push(action.payload);
    },

    UpdateSiteInductionModuleData: (state, action) => {
      const { indexData, moduleData } = action.payload;
      state[indexData] = moduleData;
    },
    DeleteSiteInductionModuleDataByIndex: (state, action) => {
      const { indexData } = action.payload;
      if (indexData >= 0 && indexData < state.length) {
        state.splice(indexData, 1);
      }
    },
    ResetSiteInductionModuleData() {
      return []; // Always return an array when resetting
    }
  }
})


export const { AddSiteInductionModuleData, ResetSiteInductionModuleData, UpdateSiteInductionModuleData, DeleteSiteInductionModuleDataByIndex } = SiteInductionModuleDataSlice.actions;
export default SiteInductionModuleDataSlice.reducer;






// // reducers/counterReducer.js
// import { createSlice } from '@reduxjs/toolkit'
// const SiteInductionModuleDataSlice = createSlice({
//   name:"SiteInductionModuleData",
//   initialState:[],
//   reducers:{
//     AddSiteInductionModuleData(state,action){
//         state.push(action.payload);
//     },
//     fetchData: (state) => {
//       state.loading = true;
//     },
//     fetchDataSuccess: (state, action) => {
//       state.loading = false;
//       state.data = action.payload;
//     },
//     fetchDataError: (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//     },

//     UpdateSiteInductionModuleData: (state, action) => {
//       const { indexData, moduleData } = action.payload;
//       state[indexData] = moduleData;
//     },
//     ResetSiteInductionModuleData(){
//         return []
//     }
//   }
// }) 
// export const {fetchData,fetchDataSuccess,fetchDataError,AddSiteInductionModuleData,ResetSiteInductionModuleData,UpdateSiteInductionModuleData}=SiteInductionModuleDataSlice.actions;
// export default SiteInductionModuleDataSlice.reducer;