import axios from "axios";
import * as actionType from "../../constant/actionType";
import { fetchData, fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetDocumentHistoryByIdSlice";
export const GetWorkerDocHistoryById = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    dispatch(fetchData());
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/GetDocumentHistoryById`,
      params: apiData
    });
    dispatch(fetchDataSuccess(response.data));
    return { success: true, data: response.data };
  } catch (error) {
    dispatch(fetchDataError(error));
    return { success: false, error };
  }

};
export const UpdateProviderDocStatus = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'put',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/client/UpdateProviderDocStatus`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}