import React, { useState } from "react";
import Heading from "../../../../../common/element/Heading";
import Tabs from "../../../../../common/element/Tabs";
import Notifaction from "./Notification";
import Security from "./Security";
import YourProfile from "./YourProfile";
const { TabNav, TabList, TabContent } = Tabs;
const AdminSettings = () => {
  return (
    <React.Fragment>
        <Heading>Settings</Heading>
        <Tabs defaultValue="tab1" variant="pill">
          <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5 mt-6 client-portal-tab">
            <TabList>
              <TabNav value="tab1">Your profile</TabNav>
              <TabNav value="tab3">Security</TabNav>
              <TabNav value="tab4">Notifications</TabNav>
            </TabList>
          </div>

          <TabContent value="tab1">
            <YourProfile/>
          </TabContent>

          <TabContent value="tab3">
          <Security/>
          </TabContent>
          <TabContent value="tab4">
                <Notifaction/>
          </TabContent>
        </Tabs>
    </React.Fragment>
  );
};

export default AdminSettings;
