
import React, { forwardRef, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
const RichTextEditor = forwardRef((props, ref) => {


return (
<div className="rich-text-editor rounded-lg border-gray-300 input-shadow focus:outline-none focus:shadow-outline">
      <ReactQuill
        ref={ref} {...props} value={props.value}
        modules={modules}  
        formats={formats}
      />
      <EditorToolbar />
    </div>
  );
});

export default RichTextEditor;
