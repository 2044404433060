// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const editSiteInductionSlice = createSlice({
  name:"EditSiteInduction",
  initialState:false,
  reducers:{
    EditSiteInduction(state,action){
      return (action.payload)
    },
    ResetEditSiteInduction(){
      return null
    }
  }
}) 
export default editSiteInductionSlice.reducer;
export const {EditSiteInduction,ResetEditSiteInduction}= editSiteInductionSlice.actions;