import React from "react"
import Heading from "../../../common/element/Heading"
// import BtnPrimary from '../../components/element/Buttons/btnPrimary'
// import BtnLight from "../../components/element/Buttons/btnLight"
   const InvitationFeedback =
 () =>{
    return(
        <div style={{ backgroundColor: `#eff8ff` }} className="flex justify-center min-h-screen">
        <div className="min-h-screen justify-center flex  py-12 px-6">
           <div className="flex-col items-center justify-center flex">
             <div className='flex items-center mb-6'>
                 <img className="brand-img" src="/assets/logo.svg" alt="conserve" />
              </div>
              <Heading className="text-center mb-3">Thank you for your feedback</Heading>
              </div>
        </div>
        </div>
    )
 }
 export default InvitationFeedback