import React, { useRef, useState } from "react";
import logomark from "./Logomark.png";
import headBg from "./head-bg.png";
import sign from "./sign.png";
import Button from "../../../../../common/element/Buttons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Dialog from "../../../../../common/element/Dialog/Dialog";
import ClientInput from "../../../../../common/element/Input/clientInput";
import Tabs from "../../../../../common/element/Tabs";
import { useReactToPrint } from 'react-to-print';
const { TabNav, TabList, TabContent, Scrollbar, Tag } = Tabs;
const PdfData = () => {
  const componentRef = useRef();
  const [startPosition, setStartPosition] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(100);
  const [dragging, setDragging] = useState(false);
  const [openPdf, SetOpenPdf] = useState(false);

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "JPEG", 0, 0);
        pdf.save("download.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF: ", error);
      });
  };

  const handleZoomOut = () => {
    setZoom(zoom + 10);
  };

  const handleZoomIn = () => {
    if (zoom > 1) {
      setZoom(zoom - 10);
    }
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
    setStartPosition(null);
  };

  const handleMouseMove = (e) => {
    if (dragging && startPosition) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition({
        x: position.x + deltaX,
        y: position.y + deltaY,
      });
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };
  
  const onDialogAddDocClose = () => {
    SetOpenPdf(false);
  };
  // const handleDownload = () => {
  //   const link = document.createElement("a");
  //   link.href = UrlPdf;
  //   link.download = "image.jpg";
  //   link.click();
  // };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // const handlePrint = () => {
  //   window.print();
    
  //   // const printWindow = window.open("", "", "width=600,height=600");
  //   // printWindow.document.open();
  //   // printWindow.document.write(`<img src="${UrlPdf}" alt="Image" />`);
  //   // printWindow.document.close();
  //   // printWindow.print();
  //   // printWindow.close();
    
  // };
  return (
    <React.Fragment>
      {!openPdf && (
        <div className="border inline-flex border-gray-300 rounded-lg p-8 relative content">
          <div className="pdf-shadow" id="divToPrint">
            <div
              border="0"
              calsan=""
              style={{
                background: "#f5faff",
                margin: "0",
                padding: "0",
                borderCollapse: "collapse",
                border: "none",
              }}
            >
              <table
                style={{
                  width: "100%",
                  backgroundColor: "#f5faff",
                  border: 0,
                  borderSpacing: 0,
                  backgroundImage: `url(${logomark})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "left center",
                  backgroundSize: "contain",
                }}
              >
                <thead
                  style={{
                    backgroundImage: `url(${headBg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "bottom",
                  }}
                >
                  <tr>
                    <th style={{ height: "60px", lineHeight: "60px" }}></th>
                  </tr>
                  <tr>
                    <th style={{ padding: "0 0 40px 0" }}></th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        fontSize: "48px",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      Certificate of Compliance
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        fontSize: "24px",
                        color: "#fff",
                        textAlign: "center",
                        padding: "25px 0",
                        fontWeight: 400,
                      }}
                    >
                      This is to certify that
                    </th>
                  </tr>
                  <tr>
                    <th style={{ height: "60px", lineHeight: "60px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        color: "#0e4e9a",
                        fontSize: "28px",
                        textAlign: "center",
                        padding: "30px 0",
                      }}
                    >
                      <strong>{"sdasdasd"}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <span
                        style={{
                          paddingRight: "20px",
                          fontSize: "24px",
                          color: "#0e4e9a",
                        }}
                      >
                        <strong>ABN: </strong>
                      </span>
                      <span
                        style={{
                          paddingLeft: "20px",
                          fontSize: "24px",
                          color: "#0e4e9a",
                        }}
                      >
                        <strong>ACN: </strong>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        padding: "30px 60px",
                        color: "#1d2939",
                        fontSize: "24px",
                      }}
                    >
                      is an approved Conserve contractor following the following
                      client(s)
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center", color: "#1d2939" }}>
                      <table style={{ maxWidth: "700px", margin: "auto" }}>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#1d2939",
                                lineHeight: "30px",
                                fontSize: "20px",
                              }}
                            >
                              {/* {clientNames.map((clientName, index) => (
                      <span key={index}>{clientName} </span>
                    ))} */}
                              <span>"One" </span>
                              <span>"Two" </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: "0 60px" }}>
                      <table style={{ width: "100%", marginTop: "60px" }}>
                        <tr>
                          <td style={{ color: "#1d2939" }}>
                            <table>
                              <tr>
                                <td>
                                  <strong>Contractor ID:</strong> "sdasda"
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Certificate No:</strong> "sdadada"
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Expiry Date:</strong> "ASdasdadad"
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td style={{ color: "#1d2939" }}>
                            <table style={{ width: "100%" }}>
                              <tr>
                                <td style={{ textAlign: "right" }}>
                                  <img src={sign} alt="Signature" />
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "right" }}>
                                  <strong>Brian Govindasamy</strong>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ textAlign: "right" }}>
                                  Managing Director
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex border-t border-gray-100 pdf-btn-block">
            <Button
              className="border-r h-10 flex gap-1 flex-1 text-sm font-semibold text-gray-700 items-center justify-center"
              onClick={() => SetOpenPdf(true)}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.375 7.64258L18.375 2.64258M18.375 2.64258H13.375M18.375 2.64258L11.7083 9.30924M9.20833 4.30924H7.375C5.97487 4.30924 5.2748 4.30924 4.74002 4.58173C4.26962 4.82141 3.88717 5.20386 3.64748 5.67427C3.375 6.20905 3.375 6.90911 3.375 8.30924V13.6426C3.375 15.0427 3.375 15.7428 3.64748 16.2776C3.88717 16.748 4.26962 17.1304 4.74002 17.3701C5.2748 17.6426 5.97487 17.6426 7.375 17.6426H12.7083C14.1085 17.6426 14.8085 17.6426 15.3433 17.3701C15.8137 17.1304 16.1962 16.748 16.4358 16.2776C16.7083 15.7428 16.7083 15.0427 16.7083 13.6426V11.8092"
                  stroke="#344054"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Open
            </Button>
            <Button
              className="border-l h-10 flex gap-1 flex-1 text-sm font-semibold text-gray-700 items-center justify-center"
              onClick={printDocument}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.875 12.6426V13.6426C17.875 15.0427 17.875 15.7428 17.6025 16.2776C17.3628 16.748 16.9804 17.1304 16.51 17.3701C15.9752 17.6426 15.2751 17.6426 13.875 17.6426H6.875C5.47487 17.6426 4.7748 17.6426 4.24002 17.3701C3.76962 17.1304 3.38717 16.748 3.14748 16.2776C2.875 15.7428 2.875 15.0427 2.875 13.6426V12.6426M14.5417 8.47591L10.375 12.6426M10.375 12.6426L6.20833 8.47591M10.375 12.6426V2.64258"
                  stroke="#344054"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Download
            </Button>
          </div>
        </div>
      )}
      {openPdf && (
        <Dialog
          isOpen={true}
          bodyOpenClassName="overflow-hidden"
          className="documentDialog"
          onClose={onDialogAddDocClose}
          onRequestClose={onDialogAddDocClose}
        >
          <React.Fragment>
            <div className="iFrame-Header image_viewer pdf_btn_center	">
              <div className="text-white text-xs">
                {/* {moduleData?.[indexData]?.company_ind_file?.name} */}
              </div>
              <div className="btn-group">
                <button onClick={() => handleZoomIn()} className="bg-white">
                  -
                </button>
                <button onClick={() => handleZoomOut()} className="bg-white">
                  +
                </button>
              </div>
              <div className="flex gap-3">
                <button onClick={() => printDocument()}>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button onClick={() => handlePrint()}>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div
              className="image-container"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
              id="divToPrint"
              ref={componentRef}
            >
              <div
                className="pdf-shadow"
                style={{
                  scale: `${zoom}% `,
                  transform: `translate(${position.x}px, ${position.y}px)`,
                }}
              >
                <div
                  border="0"
                  calsan=""
                  style={{
                    background: "#f5faff",
                    margin: "0",
                    padding: "0",
                    borderCollapse: "collapse",
                    border: "none",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      backgroundColor: "#f5faff",
                      border: 0,
                      borderSpacing: 0,
                      backgroundImage: `url(${logomark})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "left center",
                      backgroundSize: "contain",
                    }}
                  >
                    <thead
                      style={{
                        backgroundImage: `url(${headBg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "bottom",
                      }}
                    >
                      <tr>
                        <th style={{ height: "60px", lineHeight: "60px" }}></th>
                      </tr>
                      <tr>
                        <th style={{ padding: "0 0 40px 0" }}></th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            fontSize: "48px",
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Certificate of Compliance
                        </th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            fontSize: "24px",
                            color: "#fff",
                            textAlign: "center",
                            padding: "25px 0",
                            fontWeight: 400,
                          }}
                        >
                          This is to certify that
                        </th>
                      </tr>
                      <tr>
                        <th style={{ height: "60px", lineHeight: "60px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#0e4e9a",
                            fontSize: "28px",
                            textAlign: "center",
                            padding: "30px 0",
                          }}
                        >
                          <strong>{"sdasdasd"}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <span
                            style={{
                              paddingRight: "20px",
                              fontSize: "24px",
                              color: "#0e4e9a",
                            }}
                          >
                            <strong>ABN: </strong>
                          </span>
                          <span
                            style={{
                              paddingLeft: "20px",
                              fontSize: "24px",
                              color: "#0e4e9a",
                            }}
                          >
                            <strong>ACN: </strong>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                            padding: "30px 60px",
                            color: "#1d2939",
                            fontSize: "24px",
                          }}
                        >
                          is an approved Conserve contractor following the
                          following client(s)
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "center", color: "#1d2939" }}>
                          <table style={{ maxWidth: "700px", margin: "auto" }}>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "center",
                                    color: "#1d2939",
                                    lineHeight: "30px",
                                    fontSize: "20px",
                                  }}
                                >
                                  {/* {clientNames.map((clientName, index) => (
            <span key={index}>{clientName} </span>
          ))} */}
                                  <span>"One" </span>
                                  <span>"Two" </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ padding: "0 60px" }}>
                          <table style={{ width: "100%", marginTop: "60px" }}>
                            <tr>
                              <td style={{ color: "#1d2939" }}>
                                <table>
                                  <tr>
                                    <td>
                                      <strong>Contractor ID:</strong> "sdasda"
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Certificate No:</strong> "sdadada"
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Expiry Date:</strong> "ASdasdadad"
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td style={{ color: "#1d2939" }}>
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    <td style={{ textAlign: "right" }}>
                                      <img src={sign} alt="Signature" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "right" }}>
                                      <strong>Brian Govindasamy</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ textAlign: "right" }}>
                                      Managing Director
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default PdfData;
