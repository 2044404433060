import React from "react";
import Header from "./components/template/Header";
import UserDropdown from "./components/template/UserDropdown";
import Breadcrumbs from "../../common/element/Breadcrumbs";
import Notification from "./components/template/Notification";
import WorkerPortalSideNav from "./components/template/WorkerPortalSideNav";
import { Outlet } from "react-router-dom";
const HeaderActionsStart = () => {
  return (
    <>
      <Breadcrumbs />
    </>
  );
};
const HeaderActionsEnd = () => {
  return (
    <>
      <Notification />
      <UserDropdown hoverable={false} />
    </>
  );
};
export default function WorkerLayout(props) {
  return (
    <div className="app-layout-modern flex flex-auto flex-col">
      <div className="flex flex-auto min-w-0">
        <WorkerPortalSideNav />
        <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-white darks:bg-gray-800 border-l border-gray-200 darks:border-gray-700">
          <Header
            className="border-b border-gray-200 darks:border-gray-700"
            headerEnd={<HeaderActionsEnd />}
            headerStart={<HeaderActionsStart />}
          />
          <div className="h-full flex flex-auto flex-col justify-between">
            <main className="h-full">
              <div className="page-container relative h-full flex flex-auto flex-col px-6 py-5">
                {" "}
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
