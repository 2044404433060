import axios from "axios"

import {
  LOGIN,
  USER,
} from "../constants"

const { REACT_APP_AXIOS_RETRY, REACT_APP_API_PREFIX } = process.env

axios.defaults.headers.common = { 'Authorization': `Bearer ${localStorage.getItem('token')}` }

const AXIOS_RETRY = REACT_APP_AXIOS_RETRY
const USER_API = REACT_APP_API_PREFIX

const retry = {
  [AXIOS_RETRY]: { retries: 2 },
  errorHandling: { global: true },
}

export const login = data => axios.post(`${USER_API}${LOGIN}`, data, retry);

// export const fetchStacks = () => axios.get(`${USER_API}${STACK}`, retry);
// export const fetchStackById = id => axios.get(`${USER_API}${STACK}/${id}`, retry);
// export const createStack = data => axios.post(`${USER_API}${STACK}`, data, retry);
// export const updateStack = (data, id) => axios.put(`${USER_API}${STACK}/${id}`, data, retry);
// export const deleteStack = id => axios.delete(`${USER_API}${STACK}/${id}`, retry);

// export const fetchUsers = (page, limit) => axios.get(`${USER_API}${USER}?page=${page}&limit=${limit}`, retry);
// export const fetchUserById = id => axios.get(`${USER_API}${USER}/${id}`, retry);
// export const updateUser = (data, id) => axios.put(`${USER_API}${USER}/${id}`, data, retry);


// export const fileUpload = body => axios.post(`${USER_API}files`, body, retry);