import axios from "axios";
import * as actionType from "../../constant/actionType";

export const UpdateModuleSortingOrder = (data) => async (dispatch) => {
    const dataa = {moduleOrderContents : data}
    try {
      let formdata = new FormData();
      for (let key in dataa) {
          formdata.append(key, JSON.stringify(dataa[key]));
      }
      const response = await axios({
        method: "patch",
        data: formdata,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/client/UpdateModuleSortingOrder`,
      });
      return response.data;
    } catch (error) {}
  };