import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetAllSpecificEmailTemplates = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
        const response = await axios({
            method: 'get',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
            },
            url: `${actionType.environment_url}/api/admin/worker/GetAllSpecificEmailTemplates`,
            params: apiData
        });
        return response.data

    } catch (error) {
        return error.message
    }
};

export const GetAllIndividualForEmail = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
        const response = await axios({
            method: 'get',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
            },
            url: `${actionType.environment_url}/api/admin/worker/GetAllIndividualForEmail`,
            params: apiData
        });
        return response.data

    } catch (error) {
        return error.message
    }
};

export const EmailToIndividual = (form_data) => async (dispatch) => {
    try {
        // dispatch(fetchData());
        let formdata = new FormData();
        for (let key in form_data) {
            if (key === "email_doc") {
                if (form_data[key]) {
                    form_data[key].files.forEach((file, i) => {
                        formdata.append(`email_doc`, file)
                    })
                }

            } else if (key === "email_attach_docs") {
                if (form_data[key]) {
                    if (form_data[key].length === 1) {
                        formdata.append(`email_attach_docs[]`, [form_data[key][0]]);
                    } else {
                        if (form_data[key].length != 0) {
                            form_data[key].forEach((file, i) => {
                                formdata.append(`email_attach_docs`, file)
                            })
                        }
                        else {
                            formdata.append(`email_attach_docs`, [[][0]])
                        }
                    }
                }
            } else {
                formdata.append(key, form_data[key]);
            }
        }

        const response = await axios({
            method: "post",
            data: formdata,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/admin/worker/EmailToIndividual`,
        });
        return response.data;
    } catch (error) {
        return error.message;
    }
};
