import React from 'react';
import logoImage from "../../assets/logo.svg";
const Loader = () => {
    return (
        <React.Fragment>
                <div className="min-h-screen justify-center flex py-12 px-6" style={{ backgroundColor: '#E5F3FE' }}>
                    <div className="flex-col items-center justify-center flex">
                        <div className="flex items-center mb-6">
                            <img className="brand-img" src={logoImage} alt="conserve" />
                        </div>
                        <div className="spinner">
                            <svg className="spin" width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M62.1484 33.5303C62.1484 37.3386 61.3983 41.1097 59.9409 44.6281C58.4836 48.1465 56.3474 51.3435 53.6545 54.0364C50.9616 56.7293 47.7647 58.8654 44.2463 60.3228C40.7278 61.7802 36.9568 62.5303 33.1484 62.5303C29.3401 62.5303 25.5691 61.7802 22.0506 60.3228C18.5322 58.8654 15.3352 56.7293 12.6423 54.0364C9.94944 51.3435 7.81331 48.1465 6.35593 44.6281C4.89854 41.1096 4.14844 37.3386 4.14844 33.5303C4.14844 29.7219 4.89855 25.9509 6.35594 22.4324C7.81332 18.914 9.94945 15.7171 12.6423 13.0242C15.3352 10.3313 18.5322 8.19515 22.0506 6.73776C25.5691 5.28038 29.3401 4.53027 33.1485 4.53027C36.9568 4.53028 40.7278 5.28038 44.2463 6.73777C47.7647 8.19516 50.9616 10.3313 53.6545 13.0242C56.3474 15.7171 58.4836 18.914 59.941 22.4325C61.3983 25.9509 62.1484 29.722 62.1484 33.5303L62.1484 33.5303Z" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M33.1484 4.53027C36.9568 4.53027 40.7278 5.28038 44.2463 6.73777C47.7647 8.19515 50.9616 10.3313 53.6545 13.0242C56.3474 15.7171 58.4836 18.914 59.9409 22.4325C61.3983 25.9509 62.1484 29.7219 62.1484 33.5303" stroke="#5786CC" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
      
        </React.Fragment>
    );
}

export default Loader;
