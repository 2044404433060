import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollBar from "../../../../../../../common/element/ScrollBar/ScrollBar";
import Tabs from "../../../../../../../common/element/Tabs";
import Select from "../../../../../../../common/element/Select/Select";
import PdfFile from "./ChooseOption/ChoosePDF";
import URLink from "./ChooseOption/URLink";
import ChooseModule from "./ChooseOption/ChooseModule";
import AddedText from "./ChooseOption/AddedText";
import ImgFile from "./ChooseOption/ImgFile";
import VideoUpload from "./ChooseOption/VideoUpload";
import MultiQus from "./ChooseOption/MultiQus";
import ChooseQus from "./ChooseQus";
import { notify } from "react-notify-toast";
import Validation from "../../../../../../../common/404/Validations";
import ClientInput from "../../../../../../../common/element/Input/clientInput";
import Tooltip from "../../../../../../../common/element/Tooltip";

import { closeDocumentfunc } from "../../../../../../../redux/classes/client-portal/closeDocument";
import { InductuionDetailForm } from "../../../../../../../redux/classes/client-portal/inductionDetailsForm";
import {
  AddInductionModuleData,
  ResetInductionModuleData,
} from "../../../../../../../redux/classes/client-portal/inductionModuleDataSlice";
import { UpdateInductionModuleData } from "../../../../../../../redux/classes/client-portal/inductionModuleDataSlice";
import { ResetEditInduction } from "../../../../../../../redux/classes/client-portal/EeditInduction";
import { DeletedocumentData } from "../../../../../../../redux/classes/client-portal/deletedocument";
import { UpdateInductionStatus } from "../../../../../../../redux/classes/client-portal/updateInductionSlice";
// import {InductuionDetailFormAdminClient} from "../../../../../../../redux/classes/Admin-Client/inductionDetailsForm";
import {
  CreateCompanyInductionModuleApiForAdmin,
  UpdateCompanyInductionModuleForAdmin,
  GetCompanyInductionModuleByIdForAdmin,
  GetCompanyInductionAndModulesbyIdForAdmin,
  UpdateCompanyInductionForAdmin,
  DeleteCompanyIndModuleFileById,
  UpdateModuleSortingOrder,
} from "../../../../../../../actions/Admin-Client/AllCompanyInduction";
import { TailSpin } from "react-loader-spinner";
// import { UpdateCompanyInductionModuleForAdmin } from "../../../../../../../actions/Admin-Client/UpdateCompanyInductionModuleForAdmin";
// import { GetCompanyInductionModuleByIdForAdmin } from "../../../../../../../actions/Admin-Client/GetIndividualCompanyInductionMoudle";
// import { UpdateCompanyInductionForAdmin } from "../../../../../../../actions/Admin-Client/UpdateCompanyInductionForAdmin"
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

let updateCheck = false;
const CompanyInductionDialog = (props) => {
  let moduleDelete=true
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { InductionModuleData } = useSelector((state) => state);
  const getAllfunctions = useSelector((state) => state);
  const moduleGetData = useSelector((state) => state.InductionModuleData);

  const [fileSize, SetfileSize] = useState({ size: "", name: "" });
  const [moduleDics, SetModuleDics] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("https://example.com/path/to/your.pdf");

  const function_assignment_uuid = useSelector(
    (state) => state.functionAssignmentUuid.functionAssignmentUuid
  );
  const [choosePDF, uploadPDF] = useState(false);
  const [checkEdit, SetcheckEdit] = useState(true);
  const [formSatus, SetformSatus] = useState("inductionDetails");
  const [hasRun, setHasRun] = useState(false);
  const [chooseURLink, setURLink] = useState(false);
  const [isFetchData, setisFetchData] = useState(true);
  const [editText, setEditText] = useState(false);
  const [viewModuleCheck, setviewModuleCheck] = useState(false);
  const [checkValue, setcheckValue] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [inductionNameError, SetInductionNameError] = useState("");
  const [moduleError, SetmoduleError] = useState("");
  const [chooseImg, UploadImg] = useState(false);
  const [checkContent, SetcheckContent] = useState(false);
  const [indexData, SetindexData] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(null);
  const [moduleUuid, SetmoduleUuid] = useState([]);
  const [chooseVideo, UploadVideo] = useState(false);
  const [checkLoader, SetCheckLoader] = useState(false);
  const [defaultTabChekc, SetdefaultTabChekc] = useState(true);
  const [defaultTab, SetdefaultTab] = useState("tab1");
  const [inductionData, SetinductionData] = useState({
    function_assignment_uuid: function_assignment_uuid,
    company_ind_name: "",
    company_ind_desc: "",
    company_ind_valid_days: "",
    // was removed due to some issue earlier by mitika
    ...props?.inductionDetailData,
  });
  const { updateInductionStatus } = useSelector((state) => state);
  const [dialogDeleteOpen, setDeleteOpen] = useState(false);

  const openDeleteDialog = () => {
    setDeleteOpen(true);
  };

  const onDeleteDialogClose = () => {
    setDeleteOpen(false);
  };

  const ViewModule = (e) => {
    const InductionName = Validation({
      value: inductionData?.company_ind_name,
      Validations: { required: true },
    });
    const ModuleName = Validation({
      value: moduleGetData[indexData]?.module_name,
      Validations: { required: true },
    });
    if (!ModuleName.valid) {
      SetmoduleError(ModuleName.error_msg);
    }
    if (!InductionName.valid) {
      SetInductionNameError(InductionName.error_msg);
    }

    if (ModuleName.valid && InductionName.valid) {
      SetCheckLoader(true);
      let checktabisinduction = false;
      // After Edit Induction
      if (getAllfunctions.editInduction && defaultTab == "tab1") {
        const updatedInductionData = {
          ...inductionData,
          company_induction_uuid:
            props.companyInductionUuid == null
              ? getAllfunctions.inductionDetailData.company_induction_uuid
              : props.companyInductionUuid,
        };
        dispatch(UpdateCompanyInductionForAdmin(updatedInductionData))
          .then((response) => {
            if (response.success) {
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          .catch((error) => { });
      } else {
         
        viewModuleCheck == false && moduleDelete &&
          dispatch(
            CreateCompanyInductionModuleApiForAdmin(
              moduleGetData[moduleGetData.length - 1]
            )
          )
            .then((response) => {
              if (response.success) {
                let indexData = moduleGetData.length - 1;
                let moduleDataUuid = { module_uuid: response.data.module_uuid };
                // Merge response.data into moduleGetData[indexData]
                const moduleData = {
                  ...moduleGetData[moduleGetData.length - 1],
                  ...moduleDataUuid,
                };
                // updating redux state
                dispatch(UpdateInductionModuleData({ indexData, moduleData }));
                moduleUuid.push({
                  module_uuid: response.data.module_uuid,
                  index: indexData,
                });
              } else if (response.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => { });
            moduleDelete=true
      }

      SetcheckEdit(false);
      updateCheck = true;
      let indexData = e.index;
      let oldIndex = indexData;
      let moduleData;
      let updateIndex = { module_uuid: moduleUuid[e.index] };
      setPreviousIndex(oldIndex);
      if (getAllfunctions.editInduction) {
        updateIndex = {
          module_uuid: { module_uuid: moduleGetData[e.index].module_uuid },
        };
        // updateIndex = { module_uuid: moduleGetData[e.index].module_uuid };
      }
      updateIndex.module_uuid &&
        dispatch(
          GetCompanyInductionModuleByIdForAdmin(updateIndex.module_uuid)
        ).then((response) => {
          if (response.success) {
            moduleData = response.data;
            SetModuleData(moduleData);
            dispatch(UpdateInductionModuleData({ indexData, moduleData }));
            UpdateData();
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        });

      setviewModuleCheck(true);

      uploadPDF(false);
      UploadImg(false);
      UploadVideo(false);
      setURLink(false);
      setEditText(false);

      SetModuleData((prevalue) => {
        const newData = moduleGetData[e.index];
        return {
          ...prevalue,
          ...newData,
        };
      });
      SetdefaultTab(e.module_name);
      SetindexData(e.index);

      if (InductionModuleData[e.index].content_info_type == "file") {
        // const fileName = InductionModuleData?.[e.index]?.company_ind_file?.name;
        let fileName;
        if (
          InductionModuleData?.[e.index]?.company_ind_file?.name !== undefined
        ) {
          fileName = InductionModuleData[e.index].company_ind_file.name;
        } else {
          fileName = InductionModuleData[e.index].content_info;
        }
        // Get the file extension
        let fileExtension;
        if (fileName) {
          fileExtension = fileName.split(".").pop().toLowerCase();
        }
        // Check if it's a PDF
        if (fileExtension === "pdf") {
          uploadPDF(true);
          //   fetch(moduleGetData[indexData].content_info)
          //     .then((response) => response.blob())
          //     .then((blob) => {
          //       const sizeKB = Math.round(blob.size / 1024);
          //       const parts = moduleGetData[indexData].content_info.split("-");

          //       const partAfterThirdHyphen = parts.slice(4).join("-");
          //       // Decode the URL component to get the actual data
          //       const decodedData = decodeURIComponent(partAfterThirdHyphen);
          //       let FileName;
          //       if (parts.length >= 3) {
          //         FileName = parts[parts.length - 1];
          //         FileName = decodedData;
          //       }
          //       SetfileSize({
          //         size: sizeKB,
          //         name: FileName,
          //       });
          //     });
        }
        // Check if it's an image (you can add more image extensions if needed)
        if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
          UploadImg(true);
          const parts = moduleGetData[indexData].content_info.split("-");
          let FileName;
          if (parts.length >= 3) {
            FileName = parts[parts.length - 1];
            FileName = FileName.replace(/%/g, " ");
          }
          SetfileSize({
            size: "",
            name: FileName,
          });
        }
      }
      const contentInfoType = InductionModuleData[e.index].content_info_type;
      switch (contentInfoType) {
        case "text":
          setEditText(true);
          break;
        case "file_video":
          UploadVideo(true);

          const parts = moduleGetData[indexData].content_info.split("-");
          let FileName;
          if (parts.length >= 3) {
            FileName = parts[parts.length - 1];
            FileName = FileName.replace(/%/g, " ");
          }
          SetfileSize({
            size: "",
            name: FileName,
          });

          break;
        case "video_url":
          UploadVideo(true);
          break;
        case "url":
          setURLink(true);
          break;
        case "":
          uploadPDF(false);
          UploadImg(false);
          UploadVideo(false);
          setURLink(false);
          setEditText(false);
          break;
      }
    }
  };
  useEffect(() => {
    if (getAllfunctions.editInduction) {
      SetinductionData(props?.inductionDetailData);
    }
  }, [props?.inductionDetailData]);

  const [moduleData, SetModuleData] = useState({
    company_induction_uuid: getAllfunctions.editInduction
      ? getAllfunctions.inductionDetailData.company_induction_uuid
      : props.companyInductionUuid,
    module_name: "Module",
    content_info: "",
    module_desc: "",
    content_link_title: "",
    content_info_type: "",
    module_type: "company induction",
    pdf_page_range: {},
    module_ques_data: [
      {
        question: "",
        ques_ans_data: [
          {
            answer: "",
            is_correct_answer: false,
          },
        ],
      },
    ],
    company_ind_file: [],
  });

  useEffect(() => {
    setisFetchData(true);
  }, [InductionModuleData[indexData]]);

  const fetchDataCallback = useCallback(() => {
    if (InductionModuleData.length && isFetchData) {
      setisFetchData(false);
      SetModuleData(InductionModuleData[indexData]);
    }
  }, [
    InductionModuleData,
    indexData,
    isFetchData,
    SetModuleData,
    setisFetchData,
  ]);

  useEffect(() => {
    fetchDataCallback();
  }, [fetchDataCallback]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ""; // This is required for some browsers
      dispatch(ResetEditInduction());
      return "Are you sure you want to leave this page?"; // This message will be displayed in the confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [moduleGetData]);

  useEffect(() => {
    let checkValidation = true;
    if (props.validateFrom) {
      const InductionName = Validation({
        value: inductionData.company_ind_name,
        Validations: { required: true },
      });
      if (!InductionName.valid) {
        SetInductionNameError(InductionName.error_msg);
      }
    }
    if (getAllfunctions.editInduction) {
      const InductionName = Validation({
        value: inductionData.company_ind_name,
        Validations: { required: true },
      });
      if (!InductionName.valid) {
        SetInductionNameError(InductionName.error_msg);
      }
      checkValidation = InductionName.valid;
    }
    const InductionName = Validation({
      value: moduleGetData[indexData]?.module_name,
      Validations: { required: true },
    });
    if (moduleGetData.length == 0) {
      InductionName.valid = true;
    }
    if (!InductionName.valid) {
      SetmoduleError(InductionName.error_msg);
    }
    if (checkValidation && InductionName.valid) {
      SetformSatus(props.formSatus);
      SetdefaultTab(props.formSatus != "inductionDetails" ? "Module0" : "tab1");
      let isCorrectAnswerPresent = false;
      // Check if the target question is found and has the required is_correct_answer
      // moduleGetData?.[indexData]?.module_ques_data?.map((item) => {
      // if (item.question == "") {
      // isCorrectAnswerPresent = true;
      // } else {
      //   isCorrectAnswerPresent = item.ques_ans_data.some(
      //     (ansData) => ansData.is_correct_answer
      //   );
      // }
      // });
      if (
        moduleGetData.length &&
        moduleGetData?.[indexData]?.content_info_type
      ) {
        if (
          moduleGetData?.[indexData]?.module_ques_data?.every(
            (item) =>
              item?.ques_ans_data.every((ans) => ans.answer !== "") &&
              item?.ques_ans_data.some((ans) => ans.is_correct_answer)
          )
        ) {
          isCorrectAnswerPresent = true;
        } else {
          isCorrectAnswerPresent = false;
        }
      } else {
        isCorrectAnswerPresent = true;
      }
      if (props.saveData) {
        if (
          ((updateCheck ||
            props.exitCheck ||
            getAllfunctions.editInduction ||
            defaultTab == "tab1") &&
            moduleGetData?.[indexData]?.module_uuid) ||
          moduleGetData.length == 0
        ) {
          if (
            checkLoader ||
            props.exitCheck ||
            getAllfunctions.editInduction ||
            defaultTab == "tab1"
          ) {
            const moduleDesc = moduleGetData?.[indexData]?.module_desc;
            const words = moduleDesc?.split(/\s+/);
            if (isCorrectAnswerPresent || moduleGetData.length == 0) {
              setIsSaving(true);
              if (words?.length <= 255 || moduleGetData.length == 0) {
                if (updateInductionStatus || props.exitCheck) {
                  if (getAllfunctions.editInduction && defaultTab == "tab1") {
                    dispatch(UpdateCompanyInductionForAdmin(inductionData))
                      .then((response) => {
                        if (response.success) {
                          dispatch(InductuionDetailForm(inductionData));
                          dispatch(UpdateInductionStatus(false));
                          props.closePannel();
                        } else if (response.status === 401) {
                          handleUnauthorizedAccess();
                        } else {
                          props.closePannelDisable();
                        }
                      })
                      .catch((error) => { });
                  } else {
                    dispatch(
                      UpdateCompanyInductionModuleForAdmin(
                        moduleGetData[indexData]
                      )
                    )
                      .then((response) => {
                        if (response.success) {
                          updateCheck = false;
                          setIsSaving(false);
                          dispatch(UpdateInductionStatus(false));
                          props.closePannel();
                        } else if (response.status === 401) {
                          handleUnauthorizedAccess();
                        } else {
                          props.closePannelDisable();
                        }
                      })
                      .catch((error) => { });
                  }
                }
              } else {
                props.closePannelDisable();
                setIsSaving(false);
                notify.show(
                  "value too long for type character varying(255)",
                  "error"
                );
              }
            } else {
              props.closePannelDisable();
              notify.show("Each question one answer is required", "error");
            }
          } else {
            props.closePannel();
          }
        } else {
          const moduleDesc = moduleGetData?.[indexData]?.module_desc;
          const words = moduleDesc?.split(/\s+/);
          // Check if the length of module_desc is greater than or equal to 250 characters
          if (isCorrectAnswerPresent) {
            setIsSaving(true);
            if (words?.length <= 255) {
              // Your code here if the condition is true
              // if(moduleGetData?.[indexData]?.module_uuid){
              //   delete moduleGetData[indexData].module_uuid;
              // }
              dispatch(
                CreateCompanyInductionModuleApiForAdmin(
                  moduleGetData?.[indexData]
                )
              )
                .then((response) => {
                  if (response.success) {
                    let moduleDataUuid = {
                      module_uuid: response.data.module_uuid,
                    };
                    // Merge response.data into moduleGetData[indexData]
                    const moduleData = {
                      ...moduleGetData[indexData],
                      ...moduleDataUuid,
                    };
                    dispatch(
                      UpdateInductionModuleData({ indexData, moduleData })
                    );
                    props.closePannel();
                    setIsSaving(false);
                  } else if (response.status === 401) {
                    handleUnauthorizedAccess();
                  }
                })
                .catch((error) => { });
            } else {
              // Your code here if the condition is false
              props.closePannelDisable();
              setIsSaving(false);
              notify.show(
                "value too long for type character varying(255)",
                "error"
              );
            }
          } else {
            props.closePannelDisable();
            notify.show("Each question one answer is required", "error");
          }
        }
      }
    } else {
      props.closePannel("disableBTN");
    }
  }, [props.formSatus, props.validateFrom, props.saveData, props.upDateState]);

  const { TabNav, TabList, TabContent } = Tabs;

  const handleAddContent = (e) => {
    dispatch(DeletedocumentData(true));
    SetfileSize({ size: "", name: "" });
    dispatch(UpdateInductionStatus(true));
    e.target.id == "pdf" && uploadPDF(true);
    e.target.id == "image" && UploadImg(true);
    e.target.id == "video" && UploadVideo(true);
    e.target.id == "urlLink" && setURLink(true);
    e.target.id == "text" && setEditText(true);
  };

  const handleClose = () => {
    // dispatch(DeletedocumentData(true))
    // uploadPDF(false);
    // UploadImg(false);
    // UploadVideo(false);
    // setURLink(false);
    // setEditText(false);
  };
  useEffect(() => {
    if (getAllfunctions.closeDocument) {
      uploadPDF(false);
      UploadImg(false);
      UploadVideo(false);
      setURLink(false);
      setEditText(false);
      dispatch(closeDocumentfunc(false));
    }
  }, [getAllfunctions.closeDocument]);
  // Details Induction Form Handle
  const inputchangehandler = (e) => {
    SetcheckContent(true);
    if (e.target.name == "company_ind_name") {
      SetInductionNameError("");
    }
    SetinductionData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    if (!getAllfunctions?.editInduction) {
      dispatch(InductuionDetailForm(inductionData));
    }
  }, [inductionData]);

  // Module Code
  const inputModulechangehandler = (event) => {
    dispatch(UpdateInductionStatus(true));
    SetcheckContent(true);
    if (event.target.name === "module_name") {
      SetdefaultTab(event.target.value + indexData);
      SetmoduleError("");
    }
    const moduleDesc = event.target.value;
    const words = moduleDesc.split(/\s+/);
    if (words.length <= 256) {
      SetModuleDics(false);
      SetModuleData((prevalue) => {
        return {
          ...prevalue,
          [event.target.name]: event.target.value,
        };
      });
    } else {
      SetModuleDics(true);
    }
  };

  useEffect(() => {
    if (moduleGetData.length !== 0) {
      if (!getAllfunctions.editInduction || checkContent) {
        dispatch(UpdateInductionModuleData({ indexData, moduleData }));
      }
      // dispatch(UpdateInductionModuleData({ indexData, moduleData }));
    }
  }, [moduleData]);

  if (props.formSatus === "module") {
    if (moduleGetData.length == 0) {
      if (props.companyInductionUuid && !getAllfunctions.editInduction) {
        moduleData.company_induction_uuid = props.companyInductionUuid;
        dispatch(AddInductionModuleData(moduleData));
      }
      if (getAllfunctions.editInduction) {
        moduleData.company_induction_uuid =
          getAllfunctions.inductionDetailData.company_induction_uuid;
        // dispatch(AddInductionModuleData(moduleData));
      }
    }
  }

  // Add New Module
  const handleAddNewSection = () => {
    let isCorrectAnswerPresent = true;
    // Check if the target question is found and has the required is_correct_answer
    // moduleGetData?.[indexData]?.module_ques_data.map((item) => {
    //   if (item.question == "") {
    //     isCorrectAnswerPresent = true;
    //   } else {
    //     isCorrectAnswerPresent = item.ques_ans_data.some(
    //       (ansData) => ansData.is_correct_answer
    //     );
    //   }
    // });
    if (moduleGetData.length && moduleGetData?.[indexData]?.content_info_type) {
      if (
        moduleGetData?.[indexData]?.module_ques_data?.every(
          (item) =>
            item?.ques_ans_data.every((ans) => ans.answer !== "") &&
            item?.ques_ans_data.some((ans) => ans.is_correct_answer)
        )
      ) {
        isCorrectAnswerPresent = true;
      } else {
        isCorrectAnswerPresent = false;
      }
    } else {
      isCorrectAnswerPresent = true;
    }

    if (isCorrectAnswerPresent) {
      SetModuleDics(false);
      SetCheckLoader(true);
      SetdefaultTabChekc(false);
      if (getAllfunctions.editInduction && defaultTab == "tab1") {
        dispatch(UpdateCompanyInductionForAdmin(inductionData))
          .then((response) => {
            if (response.success) {
              dispatch(InductuionDetailForm(inductionData));
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          .catch((error) => { });
      }
      if (updateCheck) {
        UpdateData();
        updateCheck = false;
      }

      const InductionName = Validation({
        value: moduleGetData[indexData]?.module_name,
        Validations: { required: true },
      });
      if (moduleGetData.length == 0) {
        InductionName.valid = true;
      }
      if (!InductionName.valid) {
        SetmoduleError(InductionName.error_msg);
      } else {
        SetModuleData({
          company_induction_uuid: getAllfunctions.editInduction
            ? getAllfunctions.inductionDetailData.company_induction_uuid
            : props.companyInductionUuid,
          module_name: "Module",
          content_info: "",
          module_desc: "",
          content_link_title: "",
          content_info_type: "",
          module_type: "company induction",
          pdf_page_range: {},
          module_ques_data: [
            {
              question: "",
              ques_ans_data: [
                {
                  answer: "",
                  is_correct_answer: false,
                },
              ],
            },
          ],
          company_ind_file: [],
        });
        dispatch(
          AddInductionModuleData({
            company_induction_uuid: getAllfunctions?.editInduction
              ? getAllfunctions?.inductionDetailData?.company_induction_uuid
              : props?.companyInductionUuid,
            module_name: "Module",
            content_info: "",
            module_desc: "",
            content_link_title: "",
            content_info_type: "",
            module_type: "company induction",
            module_ques_data: [
              {
                question: "",
                ques_ans_data: [
                  {
                    answer: "",
                    is_correct_answer: false,
                  },
                ],
              },
            ],
            company_ind_file: [],
            pdf_page_range: {},
          })
        );
        uploadPDF(false);
        UploadImg(false);
        UploadVideo(false);
        setURLink(false);
        setEditText(false);
        SetdefaultTab("Module" + moduleGetData.length);
        !viewModuleCheck &&
          defaultTab !== "tab1" &&
          dispatch(
            CreateCompanyInductionModuleApiForAdmin(moduleGetData[indexData])
          )
            .then((response) => {
              if (response.success) {
                let moduleDataUuid = { module_uuid: response.data.module_uuid };
                // Merge response.data into moduleGetData[indexData]
                const moduleData = {
                  ...moduleGetData[indexData],
                  ...moduleDataUuid,
                };
                dispatch(UpdateInductionModuleData({ indexData, moduleData }));
                moduleUuid.push({
                  module_uuid: response.data.module_uuid,
                  index: indexData,
                });
              } else if (response.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => { });
        SetindexData(moduleGetData.length);
      }
      setviewModuleCheck(false);
    } else {
      notify.show("Each question one answer is required", "error");
    }
  };

  useEffect(() => {
    if (
      getAllfunctions?.editInduction == true &&
      moduleGetData.length > 0 &&
      checkEdit
    ) {
      uploadPDF(false);
      UploadImg(false);
      UploadVideo(false);
      setURLink(false);
      setEditText(false);
      if (moduleGetData?.[indexData]?.content_info_type == "file") {
        // const fileName = InductionModuleData?.[e.index]?.company_ind_file?.name;
        let fileName;
        if (moduleGetData?.[indexData]?.company_ind_file?.name !== undefined) {
          fileName = moduleGetData[indexData].company_ind_file.name;
        } else {
          fileName = moduleGetData[indexData].content_info;
        }
        // Get the file extension
        let fileExtension;
        if (fileName) {
          fileExtension = fileName?.split(".").pop().toLowerCase();
        }
        // Check if it's a PDF
        if (fileExtension === "pdf") {
          uploadPDF(true);
        }
        // Check if it's an image (you can add more image extensions if needed)
        if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
          UploadImg(true);
          const parts = moduleGetData[indexData].content_info.split("-");
          let FileName;
          if (parts.length >= 3) {
            FileName = parts[parts.length - 1];
          }
          SetfileSize({
            size: "",
            name: FileName,
          });
          // });
        }
      }
      if (moduleGetData?.[indexData]?.content_info_type == "text") {
        setEditText(true);
      }
      if (moduleGetData?.[indexData]?.content_info_type == "file_video") {
        UploadVideo(true);
      }
      if (moduleGetData?.[indexData]?.content_info_type == "video_url") {
        UploadVideo(true);
      }
      if (moduleGetData?.[indexData]?.content_info_type == "url") {
        setURLink(true);
      }
    }
  }, [moduleGetData]);

  useEffect(() => {
    if (InductionModuleData.length > 0) {
      if (InductionModuleData[indexData].content_info_type == "file") {
        let fileName;
        if (
          InductionModuleData?.[indexData]?.company_ind_file?.name !== undefined
        ) {
          fileName = InductionModuleData[indexData].company_ind_file.name;
        } else {
          fileName = InductionModuleData?.[indexData]?.content_info;
        }
        // Get the file extension
        let fileExtension;
        if (fileName) {
          fileExtension = fileName?.split(".").pop().toLowerCase();
        }
        // Check if it's a PDF
        if (fileExtension === "pdf") {
          uploadPDF(true);
          try {
            // const url = new URL(pdfUrl);
            // const newBaseUrl = `${url.origin}${url.pathname}`;
          } catch (error) {
            console.error("Invalid PDF URL:", error);
          }
        }
        // Check if it's an image (you can add more image extensions if needed)
        if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
          UploadImg(true);
        }
        // if (fileExtension === "video") {
        //   UploadVideo(true);
        // }
      }
      if (InductionModuleData[indexData].content_info_type == "text") {
        setEditText(true);
      }
      if (moduleGetData[indexData].content_info_type == "file_video") {
        UploadVideo(true);
      }
      if (InductionModuleData[indexData].content_info_type == "video_url") {
        UploadVideo(true);
      }
      if (InductionModuleData[indexData].content_info_type == "url") {
        setURLink(true);
      }
      if (InductionModuleData[indexData].content_info_type == "") {
        uploadPDF(false);
        UploadImg(false);
        UploadVideo(false);
        setURLink(false);
        setEditText(false);
      }
    }
  }, []);

  function UpdateData() {
    if (updateCheck && previousIndex != null) {
      if (updateInductionStatus) {
        let isCorrectAnswerPresent = false;
        // Check if the target question is found and has the required is_correct_answer
        if (
          moduleGetData.length &&
          (moduleGetData?.[previousIndex]?.content_info !== "" ||
            moduleGetData?.[previousIndex]?.content_info_type)
        ) {
          if (
            moduleGetData?.[previousIndex]?.module_ques_data?.every(
              (item) =>
                item?.ques_ans_data.every((ans) => ans.answer !== "") &&
                item?.ques_ans_data.some((ans) => ans.is_correct_answer)
            )
          ) {
            isCorrectAnswerPresent = true;
          } else {
            isCorrectAnswerPresent = false;
          }
        } else {
          isCorrectAnswerPresent = true;
        }
        if (isCorrectAnswerPresent) {
          dispatch(
            UpdateCompanyInductionModuleForAdmin(moduleGetData[previousIndex])
          )
            .then((response) => {
              if (response.success) {
                dispatch(UpdateInductionStatus(false));
              } else if (response.status === 401) {
                handleUnauthorizedAccess();
              }
            })
            .catch((error) => {
              notify.show(error, "error");
            });
        } else {
          notify.show("Each question one answer is required", "error");
          dispatch(UpdateInductionStatus(false));
        }
      }
    }
  }

  let closeBtn;
  useEffect(() => {
    if (
      choosePDF ||
      chooseImg ||
      chooseVideo ||
      editText ||
      chooseURLink == true
    ) {
      setcheckValue(true);
    } else {
      setcheckValue(false);
      // closeBtn = "";
    }
  }, [choosePDF, chooseImg, chooseVideo, editText, chooseURLink]);

  // Adjust Module Discription Height
  useEffect(() => {
    // Calculate the scroll height and set the textarea height accordingly
    const textarea = document.querySelector(".moduleDescTextarea");
    if (textarea) {
      textarea.style.minHeight = "88px";
      textarea.style.height = `${Math.max(textarea.scrollHeight, 88)}px`;
      textarea.style.maxHeight = "400px";
      if (textarea.textLength === 0) {
        textarea.style.height = "88px";
      }
    }
  }, [moduleData, inductionData]);

  // Onclick Edit Button open Default module
  if (moduleGetData.length > 0 && !hasRun && defaultTabChekc) {
    setHasRun(true);
  }
  useEffect(() => {
    if (getAllfunctions.editInduction && moduleGetData.length > 0 && hasRun) {
      SetdefaultTab("tab1");
      // SetdefaultTab(moduleGetData?.[0]?.module_name + 0);
      setHasRun(true);
    }
    if (getAllfunctions.editInduction && moduleGetData.length == 0) {
      SetdefaultTab("tab1");
    }
  }, [hasRun]);
  // Handle Edit And Update Module
  let checkTab = getAllfunctions.editInduction ? true : defaultTab !== "tab1";
  useEffect(() => {
    const moduleDesc = moduleGetData[indexData]?.module_desc;
    const words = moduleDesc?.split(/\s+/);
    if (words !== undefined) {
      if (words.length <= 255) {
        SetModuleDics(false);
      } else {
        SetModuleDics(true);
      }
    }
  });

  // Delete Module

  const deleteModule = (item, index, module_name) => {
    let ModuleData = {
      module_uuid: item.module_uuid,
      content_info: item.content_info,
      content_info_type: item.content_info_type,
      moduleQuesIds: item?.module_ques_data?.map(
        (item) => item.module_question_uuid
      ),
    };
    let indexData = index;
    SetindexData(0);
    props.openDeletePopup(ModuleData, indexData);
    moduleDelete=false
    ViewModule({
      index: 0,
      module_name: module_name
    })
    
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    // Ensure the dragged item is moving to a different position
    if (source.index !== destination.index) {
      // Calculate the new sort order based on the destination index
      // const newSortOrder = destination.index;
      const newSortOrder =
        destination.index > source.index
          ? destination.index
          : destination.index;
      //  + 1;

      // Update the state or dispatch an action to update the order
      // const reorderedModules = Array.from(moduleGetData);
      // const [removed] = reorderedModules.splice(source.index, 1);
      // reorderedModules.splice(destination.index, 0, removed);
      const reorderedModules = Array.from(moduleGetData);
      const movedModule = reorderedModules.splice(source.index, 1)[0];
      // reorderedModules.splice(destination.index, 0, movedModule);
      reorderedModules.splice(newSortOrder, 0, movedModule);

      // Update the sort_order property in the reorderedModules array
      const updatedModules = reorderedModules.map((item, index) => ({
        ...item,
        sort_order: index,
      }));

      let moduleArray = updatedModules?.map((items, index) => {
        return {
          module_uuid: items.module_uuid,
          sort_order: items.sort_order,
        };
      });

      dispatch(UpdateModuleSortingOrder(moduleArray))
        .then((response) => {
          if (response.success) {
            dispatch(
              GetCompanyInductionAndModulesbyIdForAdmin(
                inductionData?.company_induction_uuid
              )
            ).then((response) => {
              if (response.success) {
                dispatch(ResetInductionModuleData());
                response?.data?.company_ind_modules?.map((item) => {
                  dispatch(AddInductionModuleData(item));
                });
              } else if (response.status === 401) {
                handleUnauthorizedAccess();
              }
            });
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => { });

      // }
    }
  };

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Tabs value={defaultTab} variant="pill">
          <div className="flex flex-auto w-full">
            <div className="flex flex-col  bg-blue-50 border-r border-gray-300 w-1/4">
              <div className="overflow-y-auto h-595">
                <ScrollBar>
                  {!isSaving && (
                    <div className=" py-6 px-3">
                      <TabList className="flex-col left-side-block inductionList">
                        <TabNav
                          disabled={formSatus !== "inductionDetails"}
                          value={"tab1"}
                          onClick={() => {
                            if (getAllfunctions.editInduction) {
                              SetdefaultTab("tab1");
                            }
                          }}
                        >
                          Induction Details
                        </TabNav>
                        <hr className="border-t border-gray-300 mt-4 mb-3 w-full" />

                        {checkTab && (
                          <React.Fragment>
                            <Droppable
                              droppableId="tabNavList"
                            // direction="horizontal"
                            >
                              {(provided) => (
                                <TabList
                                  className="flex-col left-side-block inductionList w-full"
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {moduleGetData?.map((items, index) => {
                                    let module_name = moduleGetData[0].module_name + 0
                                    return (
                                      <React.Fragment>
                                        <Draggable
                                          className="w-full"
                                          key={items.module_uuid}
                                          draggableId={items.module_uuid}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <div
                                              className="w-full"
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <TabNav
                                                value={
                                                  items.module_name + index
                                                }
                                                key={index}
                                              >
                                                <span
                                                  className="items-center w-full text-left break-all whitespace-normal pr-1 "
                                                  onClick={() =>
                                                    ViewModule({
                                                      index: index,
                                                      module_name:
                                                        items.module_name +
                                                        index,
                                                    })
                                                  }
                                                >
                                                  {index + 1}.
                                                  {items.module_name}
                                                </span>
                                                <div className="flex items-center gap-2">
                                                  <span
                                                    onClick={() =>
                                                      ViewModule({
                                                        index: index,
                                                        module_name:
                                                          items.module_name +
                                                          index,
                                                      })
                                                    }
                                                    className="flex justify-center items-center "
                                                  >
                                                    <svg
                                                      width="20"
                                                      height="21"
                                                      viewBox="0 0 20 21"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M10 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM10 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM10 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM15.834 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM15.834 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM15.834 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM4.167 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM4.167 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM4.167 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667z"
                                                        stroke="#D0D5DD"
                                                        strokeWidth="1.667"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  </span>
                                                  {moduleGetData.length > 1 && (
                                                    <button
                                                      onClick={() =>
                                                        deleteModule(
                                                          items,
                                                          index,
                                                          module_name
                                                        )
                                                      }
                                                    >
                                                      <svg
                                                        width="20"
                                                        height="21"
                                                        viewBox="0 0 20 21"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                                                          stroke="#667085"
                                                          strokeWidth="1.667"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                    </button>
                                                  )}
                                                </div>
                                              </TabNav>
                                            </div>
                                          )}
                                        </Draggable>
                                      </React.Fragment>
                                    );
                                  })}
                                  <button
                                    className="text-blue-700 text-xs font-semibold flex items-center gap-2 mt-5"
                                    onClick={handleAddNewSection}
                                  >
                                    <span>
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10 4.167v11.666M4.167 10h11.666"
                                          stroke="#5786CC"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    Add another module
                                  </button>
                                </TabList>
                              )}
                            </Droppable>
                          </React.Fragment>
                        )}
                      </TabList>
                    </div>
                  )}
                  {isSaving && (
                    <div>
                      <div className="blur">
                        <div className="flex">
                          <label className="font-medium text-gray-700 w-1/5">
                            Site Address*
                          </label>
                          <div className="w-4/5 pl-2">
                            <input
                              type="text"
                              placeholder="Street Address*"
                              className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            <input
                              type="text"
                              placeholder="Street Address*"
                              className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center mb-5 mt-5 pt-5">
                        {/* <TailSpin color="blue" radius={"8px"} /> */}
                      </div>
                      <div className="blur">
                        <div className="flex mb-5 border-t mt-5 pt-5">
                          <label className="font-medium text-gray-700 w-1/5">
                            Evacuation Diagram
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </ScrollBar>
              </div>
            </div>
            <div className="w-3/4">
              <TabContent value="tab1" className="pr-1">
                <div className="overflow-y-auto h-595">
                  <ScrollBar>
                    <div className="p-6">
                      <div className="flex mb-5 ">
                        <label className="text-gray-700 font-Medium w-1/4">
                          Induction name*
                        </label>
                        <div className="w-3/4">
                          <ClientInput
                            type="text"
                            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Induction name"
                            inputchangehandler={inputchangehandler}
                            name={"company_ind_name"}
                            value={inductionData.company_ind_name}
                          />
                          {inductionNameError && (
                            <div className="text-red-500 text-sm mt-1">
                              {inductionNameError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex mb-5">
                        <label className="text-gray-700 font-Medium w-1/4">
                          Induction description
                        </label>
                        <div className="w-3/4">
                          <textarea
                            name="company_ind_desc"
                            // maxLength={250}
                            onChange={inputchangehandler}
                            rows={4}
                            value={inductionData.company_ind_desc}
                            cols={40}
                            placeholder="Provide a brief description to help Inductees..."
                            className="moduleDescTextarea input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                      <hr className="mb-5 border-gray-200" />
                      <div className="flex mb-5">
                        <label className="text-gray-700 font-Medium w-1/4">
                          Induction valid days
                        </label>
                        <div className="w-3/4">
                          <ClientInput
                            type="number"
                            name="company_ind_valid_days"
                            value={inductionData.company_ind_valid_days}
                            inputchangehandler={inputchangehandler}
                            placeholder="Enter days"
                            className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                    </div>
                  </ScrollBar>
                </div>
              </TabContent>
              <TabContent
                value={defaultTab == "tab1" ? "tab2" : defaultTab}
                className="pr-1"
              >
                <div className="overflow-y-auto h-595">
                  <ScrollBar>
                    <div className="p-6">
                      {!isSaving && (
                        <React.Fragment>
                          <div className="flex mb-5 ">
                            <label className="text-gray-700 font-Medium w-1/4">
                              Module name*
                            </label>
                            <div className="w-3/4">
                              <ClientInput
                                inputchangehandler={inputModulechangehandler}
                                type="text"
                                value={moduleGetData[indexData]?.module_name}
                                name={"module_name"}
                                placeholder="Module name"
                                className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                              />
                              {moduleError && (
                                <div className="text-red-500 text-sm mt-1">
                                  {moduleError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex mb-5">
                            <label className="text-gray-700 font-Medium w-1/4">
                              Module description
                            </label>
                            <div className="w-3/4">
                              <textarea
                                // maxLength={250}
                                name="module_desc"
                                onChange={inputModulechangehandler}
                                rows={4}
                                cols={40}
                                placeholder=""
                                value={moduleGetData[indexData]?.module_desc}
                                className="moduleDescTextarea input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                              />
                              {moduleDics && (
                                <div className="text-red-500 text-sm mt-1">
                                  value too long for type character varying(255)
                                </div>
                              )}
                            </div>
                          </div>
                          <hr className="mb-5 border-gray-200" />
                        </React.Fragment>
                      )}

                      {isSaving && (
                        <div>
                          <div className="blur">
                            <div className="flex">
                              <label className="font-medium text-gray-700 w-1/5">
                                Site Address*
                              </label>
                              <div className="w-4/5 pl-2">
                                <input
                                  type="text"
                                  placeholder="Street Address*"
                                  className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                                />
                                <input
                                  type="text"
                                  placeholder="Street Address*"
                                  className="mb-5 input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center mb-5 mt-5 pt-5">
                            <TailSpin color="blue" radius={"8px"} />
                          </div>
                          <div className="blur">
                            <div className="flex mb-5 border-t mt-5 pt-5">
                              <label className="font-medium text-gray-700 w-1/5">
                                Evacuation Diagram
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {!isSaving && (
                        <React.Fragment>
                          <div className="flex mb-5 flex-col gap-3">
                            <label className="text-gray-900 font-semibold text-xl">
                              Module Content
                            </label>

                            {closeBtn}
                            {!checkValue && (
                              <div className="text-center w-full py-12">
                                <span className="text-base text-gray-600 font-semibold block mb-6">
                                  This Module has no content yet. Start by
                                  uploading content to get started.
                                </span>
                                <ChooseModule
                                  handleAddContent={handleAddContent}
                                />
                              </div>
                            )}
                            {choosePDF && (
                              <PdfFile
                                indexData={indexData}
                                moduleData={moduleData}
                                size={fileSize}
                              />
                            )}
                            {chooseImg && (
                              <ImgFile
                                indexData={indexData}
                                moduleData={moduleData}
                                size={fileSize}
                              />
                            )}
                            {chooseVideo && (
                              <VideoUpload
                                indexData={indexData}
                                moduleData={moduleData}
                                size={fileSize}
                              />
                            )}
                            {editText && (
                              <AddedText
                                indexData={indexData}
                                moduleData={moduleData}
                              />
                            )}
                            {chooseURLink && (
                              <URLink
                                indexData={indexData}
                                moduleData={moduleData}
                              />
                            )}
                          </div>
                          <div className="flex flex-col gap-3">
                            <label className="text-gray-900 font-semibold text-xl">
                              Assessment
                            </label>
                            {!checkValue && (
                              <div className="text-center w-full py-12">
                                <span className="text-base text-gray-600 font-semibold block">
                                  Content is needed to start filling out the
                                  assessment section. Please upload content to
                                  this module.
                                </span>
                              </div>
                            )}
                            {checkValue && (
                              <ChooseQus
                                indexData={indexData}
                                moduleData={moduleData}
                              />
                            )}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </ScrollBar>
                </div>
              </TabContent>
              {/* <TabContent value="tab7" className="pr-1">
               <div className ="overflow-y-auto h-595">
                  <ScrollBar>
                     <div className="p-6">
                        <MultiQus/>
                        
                     </div>
                  </ScrollBar>
               </div>
            </TabContent> */}
            </div>
          </div>
        </Tabs>
      </DragDropContext>
    </React.Fragment>
  );
};
export default CompanyInductionDialog;
