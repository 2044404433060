import React from "react";
import Badge from "../../../../../../common/element/Badge";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import Input from "../../../../../../common/element/Input";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import HelpScoutClientBeacon from './HelpScoutClientBeacon'
const LiveChat = ({isOpen}) => {
  return (
    <React.Fragment>
      <div className="flex border-b  pb-5 px-6 pt-6">
        <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
          <img src="/assets/icons-svg/email.svg" alt="" />
        </span>
        <div>
          <h5 className="mb-1 text-gray-900 text-base font-semibold">
            Chat with our team
          </h5>
          <p className="text-gray-600 ">
            We’re online right now, talk with our team real-time.
          </p>
        </div>
      </div>
      <div className="chat-block p-6 h-96 pr-2 relative">
        <HelpScoutClientBeacon isOpen={isOpen}/>
      </div>
    </React.Fragment>
  );
};

export default LiveChat;
