import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Tabs from "../../../../../../common/element/Tabs";
// import { notify } from "react-notify-toast";
import * as userActions from "../../../../../../actions/index";
import ContractorsList from "./ContractorsList";
import Button from "../../../../../../common/element/Buttons";
import Dropdown from "../../../../../../common/element/Dropdown";
import RichTextEditor from "../../../../../../common/snippet/RichTextEditor";
import Upload from "../../../../../../common/element/Upload";
import Td from "../../../../../../common/element/Table/Td";
import TBody from "../../../../../../common/element/Table/TBody";
import THead from "../../../../../../common/element/Table/THead";
import Th from "../../../../../../common/element/Table/Th";
import Tr from "../../../../../../common/element/Table/Tr";
import Table from "../../../../../../common/element/Table";
import Label from "../../../../../../common/element/Label";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import Input from "../../../../../../common/element/Input";
import Select from "../../../../../../common/element/Select";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import Validations from "../../../../../../common/404/Validations";
import InviteContrators from "./InviteContractors";
import { CSVLink } from "react-csv";
import { reSetData } from "../../../../../../redux/classes/AdminContractor/inviteContractorWorkerCsvViewSlice";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import SearchIcon from "../../../../../../assets/icons-svg/search-lg.svg";

import ClientInput from "../../../../../../common/element/Input/clientInput";

import SettingIcon from "../../../../../../assets/icons-svg/settings02.svg";
import ColumnIcon from "../../../../../../assets/icons-svg/columns-03.svg";
import PlusIcon from "../../../../../../assets/icons-svg/plus.svg";
import ContractorIcon from "../../../../../../assets/icons-svg/cons-provider.svg";
import moment from "moment";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import { useParams } from "react-router-dom";
import Checkbox from "../../../../../../common/element/Checkbox";
import ScrollBar from "../../../../../../common/element/ScrollBar/ScrollBar";
import { setDefaults } from "react-i18next";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
// import DialogBoxSuccess from "../../../../../../common/reusableDialogBox/DialogBoxSuccess";
import {
  GetAllProviderTypesList,
  GetAllComplianceOfClientList,
} from "../../../../../../actions/Admin-Client/Admin-Client";
import { inviteProviderCSVView } from "../../../../../../actions/Admin-Client/inviteProviderCSVView";
import { ViewClientCsv } from "../../../../../../actions/Admin-Client/clientCsvView";
const Providers = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_uuid;
  let current_user;
  if (loginData) {
    role_assignment_uuid = loginData?.data?.role_assignment_uuid;
    current_user = `${loginData?.userName?.first_name || ''} ${loginData?.userName?.last_name || ''}`;
  }
  const { ClientSpecificProfileData } = useSelector((state) => state)
  const { TabNav, TabList, TabContent } = Tabs;
  const [documentError, setdocumentError] = useState("");
  const [newSearchValue, SetNewSearchValue] = useState("");
  const [previousFirstAbn, setPreviousFirstAbn] = useState("");
  const [selectProviderDetails, setSelectProviderDetails] = useState([]);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [dialogChoose, setChoose] = useState(false);
  const [providerOrgId, setProviderOrgId] = useState(false);
  const [dialogNextCsv, setDialogNextCsv] = useState(false);
  const [CsvFileData, SetCsvFileData] = useState({
    provider_type_uuid: "",
    invite_attach: [],
    invite_message: "",
  });
  const [providerUserUuid, setProviderUserUuid] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [dialogAlreadyOpen, setCloseAlreadyExist] = useState(false);
  const [searchOptions, setSelectOptions] = useState([]);
  const [filtersort, setFiltersort] = useState("created_date");
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [filterorder, setFilterOrder] = useState("desc");
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [totalPagesInvite, setTotalPagesInvite] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [workerCheckListError, setWorkerChecklistError] = useState("");
  const [workerSelCompChecklist, setWorkerSelCompChecklist] = useState([]);
  const [selCompChecklist, setSelCompChecklist] = useState([]);
  // states for csv
  const [finalCsvData, setfinalCsvData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dialogOpenCSV, setCSV] = useState(false);
  const [updateCsvData, setUpdateCsvData] = useState(false);
  const [formError, setFormErrors] = useState({
    selectDoc: "",
    conserveEmailReq: "",
    correctFormat: "",
    comCheckListId: "",
  });
  const [abn, setAbn] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState({ files: [] });

  const [functionUuid, setfunctionUuid] = useState("");
  const [contactTypeUuid, setContactTypeUuid] = useState("");
  const [fullName, setFullName] = useState({ first_name: "", last_name: "" });

  const [fullNameError, setFullNameError] = useState({
    first_name: "",
    last_name: "",
  });

  const [emailError, setEmailError] = useState("");
  const [abnError, setAbnError] = useState("");
  const [abnEditOrNot, setAbnEditOrNot] = useState(false);
  const [individualUuid, setIndividualUuid] = useState("");
  const [contractorFAUuid, setContractorFAUuid] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [searchTerm, setsearchTerm] = useState("");
  const [searchViewAll, setsearchViewAll] = useState("");
  const [checkListError, setChecklistError] = useState("");
  const [countData, setCountData] = useState(0);
  const [defaultTab, SetdefaultTab] = useState("tab1");
  const [dialogInviteSent, setDialogInvite] = useState(false);
  const [allContractors, setAllContractors] = useState("");
  const [inputEmail, setInputEmail] = useState(false);
  const [pageOne, setPageOne] = useState(false);
  const [allCheckListData, setAllCheckListData] = useState([]);
  const [workerAllCheckListData, setWorkerAllCheckListData] = useState([]);
  const [searchProviderOptions, setSearchOptions] = useState([]);

  const [refetchData, setRefetchData] = useState(false);

  //functionAssignmentUuid from redux
  const functionAssignmentUuid = useSelector(
    (state) => state.functionAssignmentUuid.functionAssignmentUuid
  );
  const Allfunction = useSelector((state) => state?.allFunctions?.allFunction);
  const AllContactType = useSelector((state) => state?.AllContactTypes);

  const [contractorDataInvited, setContractorDataInvited] = useState();

  //breadcrumbs
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          label: "View All Clients",
          url: "/admin/client/view-all-clients",
          home: "/admin/client/dashboard",
        },
        {
          label: props?.clientData?.trading_name,
          url: `/admin/client/view-all-clients/specific-client/${id}`,
        },
        {
          label: "Provider",
          url: `/admin/client/view-all-clients/specific-client/${id}`,
        },
      ])
    );
  }, [props?.clientData]);
  const GetFunction = useSelector((state) => state);

  // Function to handle selection of document type
  const selectDocType = (selectedOption) => {
    setSelectedDocumentType(selectedOption);
  };
  const selectCompChecklist = (selectedOption) => {
    setSelCompChecklist(selectedOption);
    setChecklistError("");
  };
  const selectWorkerCompChecklist = (selectedOption) => {
    setWorkerSelCompChecklist(selectedOption);
    setWorkerChecklistError("");
  };

  //function to opne add provider dialog
  const openChoose = () => {
    setChoose(true);
  };

  //function to close add provider dialog
  const onChooseClose = (e) => {
    setChoose(false);
    setEmail("");
    setSelectOptions([]);
    setSelectedOption("")
    setFormErrors((prevError) => ({
      ...prevError,
      selectDoc: "",
      correctFormat: "",
      conserveEmailReq: "",
      comCheckListId: "",
    }));
    setEmailError("");
    setSelectProviderDetails([])
    setAbn("");
    setPreviousFirstAbn("")
    setAbnError("");
    setSelectedOption("");
    setSelectedDocumentType("");
  };
  const isSearchClear = () => {
    setEmail("");
    setEmailError("");
    setIndividualUuid("");
    setFullName((prevState) => ({
      ...prevState,
      first_name: "",
      last_name: "",
    }));
  };
  const onCloseNextCsv = () => {
    setSelCompChecklist([]);
    setDialogNextCsv(false);
    setWorkerChecklistError("");
    setWorkerSelCompChecklist([]);
    setfinalCsvData([]);
    setAttachment({ files: [] })
    SetCsvFileData({
      provider_type_uuid: "",
      invite_attach: [],
      invite_message: "",
    });
  };
  const OnCloseinviteProvider = () => {
    setRefetchData(!refetchData)
    setDialogInvite(false);
    setfinalCsvData([]);
    setAttachment({ files: [] })
    SetCsvFileData({
      provider_type_uuid: "",
      invite_attach: [],
      invite_message: "",
    });
  };
  const onDialogAlreadyClose = () => {
    setCloseAlreadyExist(false);
    onDialogClose();
  };
  // useEffect(() => {
  //   // dialogIsOpen is function to open 2nd dialog for add provider

  //   if (dialogIsOpen === true && email && !contractorFAUuid) {
  //     GetIndividualByEmail();
  //   }
  // }, [dialogIsOpen]);

  // function to save 1st dialog to add provider and open 2nd
  const openDialog = () => {
    let emailValidation;
    let abnValidation;
    let correctFormatVal;
    const inputValue = selectedOption?.value;

    if (emailError == "") {
      if (isEmail(selectedOption?.label)) {
        emailValidation = Validations({
          value: inputValue,
          Validations: { email: true, required: true },
        });
        if (!emailValidation.valid) {
          setEmailError(emailValidation.error_msg);
        }
        // set ABN error empty
      } else if (isElevenDigitNumber(selectedOption?.label)) {
        abnValidation = Validations({
          value: inputValue,
          Validations: { required: true, ValidateABN: true },
        });
        if (!abnValidation.valid) {
          setAbnError(abnValidation.error_msg);
        }
        // set email error Empty
      } else {
        // formError.correctFormat
        correctFormatVal = Validations({
          value: inputValue,
          Validations: { email: true, required: true },
        });
        if (!correctFormatVal.valid) {
          setFormErrors((prevError) => ({
            ...prevError,
            correctFormat:
              inputValue == "" || inputValue == undefined
                ? "This field is required!"
                : "Please enter the value in the correct format",
          }));
        }
      }
      // Check email, or number
      const DocSelectValidation = Validations({
        value: selectedDocumentType,
        Validations: { required: true },
      });
      setFormError("selectDoc", DocSelectValidation.error_msg);

      // Reset error messages
      if (
        (emailValidation?.valid === true || abnValidation?.valid === true) &&
        formError?.correctFormat === "" &&
        DocSelectValidation?.valid &&
        emailError == "" &&
        formError?.conserveEmailReq == ""
      ) {
        setIsOpen(true);
        setChoose(false);
        if (abn !== "") {
          setAbnEditOrNot(true);
        }
      }
      if (emailValidation?.valid) {
        setEmail(inputValue);
        setAbnError("");
        setInputEmail(true);
        setPageOne(true);
      } else if (abnValidation?.valid) {
        setEmailError("");
        setAbn(inputValue ? inputValue : "")
        setPreviousFirstAbn(inputValue ? inputValue : "")
        setSelectedOption("");
        // setSelectedDocumentType("");
        setPageOne(false);
      }
    }
  };

  // function to close all dialog for add provider
  const onDialogClose = (e) => {
    if (buttonDisabled == false) {
      setAbnEditOrNot(false);
      setSelCompChecklist([]);
      setWorkerSelCompChecklist([])
      setIsOpen(false);
      setFullNameError((prevError) => ({
        ...prevError,
        first_name: "",
        last_name: "",
      }));
      setEmail("");
      setEmailError("");
      setSelectOptions([])
      setSelectedOption("")
      setAbn("");
      setSelectProviderDetails([])
      setPreviousFirstAbn("")
      setEmailError("");
      setAbnError("");
      setSelectedOption("");
      setSelectedDocumentType("");
      setFullName((prevState) => ({
        ...prevState,
        first_name: "",
        last_name: "",
      }));
      setIndividualUuid("");
    }
  };

  // close invitation sent alert
  const onAddDialogClose = (e) => {
    setAddOpen(false);
    setRefetchData(!refetchData)
  };

  const handleSeachChange = (e, checkTab) => {
    SetNewSearchValue((e.target.value).trim())
    if (checkTab == "viewAll") {
      setsearchViewAll((e.target.value).trim())
    } else if (checkTab == "invitedList") {
      setsearchTerm((e.target.value).trim());
      setfilterpage(1);
    }
  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

  const handlePageChangeInvite = ({ selected }) => {
    setfilterpage(selected + 1);
    GetAllProvidersPrimaryList(selected + 1, filterlimit);
  };
  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    GetAllProvidersPrimaryList(selected + 1, filterlimit);
  };

  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };
  // to handle input change
  const inputchangehandler = (e, identifier) => {
    if (identifier === "first_name" || identifier === "last_name") {
      setFullName((prevalue) => {
        return {
          ...prevalue,
          [identifier]: e,
        };
      });
      setFullNameError((prevError) => ({
        ...prevError,
        [identifier]: "",
      }));
    }
    if (identifier === "abn") {
      setAbnError("");
      setAbn(e);
    }

    // check if this condition calls ever
    if (identifier === "email") {
      setEmail(e);
      if (email?.length >= 3) {
        // GetIndividualByEmail(e)
        setSearch(e);
        GetAllProvidersPrimaryList(e);
      }
    }
  };

  useEffect(() => {
    if (GetFunction.inviteProviderCsvData?.data?.data?.success) {
      if (updateCsvData) {
        dialogCloseCSV();
        setAddOpen(true);
        // dispatch(reSetData());
        setCsvData([]);
      }
    } else {
      if (
        !GetFunction?.inviteProviderCsvData?.loading &&
        GetFunction?.inviteProviderCsvData.data?.data !== undefined
      ) {
        setCsvData(GetFunction.inviteProviderCsvData.data?.data);
        let filterdata = GetFunction?.inviteProviderCsvData?.data?.data?.filter(
          (items) => items?.is_validated == true
        );
        // Now, create a new filtered array that excludes the 'action' and 'invite_uuid' properties
        if (filterdata) {
          const filteredData = filterdata.map((item) => {
            // Create a new object with only the desired properties
            const { action, is_validated, invite_uuid, ...filteredItem } = item;
            return filteredItem;
          });

          setfinalCsvData(filteredData);
        } else {
          setfinalCsvData([]);
        }
      }
    }
  }, [GetFunction]);

  const openCSV = () => {
    setCSV(true);
  };

  const dialogCloseCSV = (e) => {
    setCSV(false);
    // dispatch(reSetData());
    setCsvData([]);
  };

  const submitInviteCsv = () => {
    const DocSelectValidation = Validations({
      value: CsvFileData.provider_type_uuid,
      Validations: { required: true },
    });

    const comCheckListId = Validations({
      value: selCompChecklist?.length == 0 ? "" : true,
      Validations: { required: true },
    });
    const WorkerCheckListValidation = Validations({
      value: workerSelCompChecklist?.length == 0 ? "" : workerSelCompChecklist,
      Validations: { required: true },
    });
    if (!WorkerCheckListValidation?.valid) {
      setWorkerChecklistError(WorkerCheckListValidation?.error_msg);
    }
    setFormError("selectDoc", DocSelectValidation.error_msg);
    setFormError("comCheckListId", comCheckListId.error_msg);
    const workerChecllistIds = workerSelCompChecklist?.map((item) => item?.value);

    if (DocSelectValidation.valid && documentError == "" && comCheckListId.valid && WorkerCheckListValidation.valid) {
      const updatedCsvFileData = {
        ...CsvFileData,
        invite_attach: attachment,
      };
      dispatch(
        ViewClientCsv({
          // trading_name: props?.clientData?.trading_name,
          provider_invite_data: finalCsvData,
          ...updatedCsvFileData,
          checklistIds: JSON.stringify(selCompChecklist?.map((item) => item?.value)),
          client_org_uuid: ClientSpecificProfileData?.organisation_uuid,
          current_user: current_user,
          trading_name: ClientSpecificProfileData?.trading_name,
          workerChecklistIds: JSON.stringify(workerChecllistIds.length ? workerChecllistIds : []),

        })
      )
        .then((response) => {
          if (response?.success) {
            setCSV(false);
            // dispatch(reSetData());
            setCsvData([]);
            setAddOpen(true);
            onCloseNextCsv();
            setWorkerChecklistError("");
            setWorkerSelCompChecklist([]);
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          }
        })
        .catch((error) => { });
      setUpdateCsvData(true);
    }

  };
  // Upload CSV File
  const handleFileChangeCSV = (e) => {
    // dispatch(reSetData());
    setCsvData([]);
    const file = e.target.files[0];
    let payload = {
      provider_invite_csv: file,
      client_fa_uuid: functionAssignmentUuid,
      contact_type_uuid: contactTypeUuid,
      function_uuid: functionUuid,
    };
    dispatch(inviteProviderCSVView(payload));
    openCSV();
    setUpdateCsvData(false);
  };

  // CSV Default Data Array
  const CSVData = [
    ["first_name", "last_name", "email", "provider_abn_nzbn"],
    ["brandon", "Hodges", "brandon.hodges33@conserv.com", "00-000-000-000"],
    ["victor", "Carr", "victor.carr33@conserv.com", "00-000-000-000"],
    ["Lillian", "Hodges", "lillian.pullman@conserv.com", "00-000-000-000"],
    ["Edward", "Hodges", "edward.mills@conserv.com", "00-000-000-000"],
    ["Megan", "Hodges", "megan.mcdonald4555@conserv.com", "00-000-000-000"],
  ];
  const handlecheckbox = (event, index) => {
    const isChecked = event;
    const item = csvData[index];
    if (isChecked) {
      // Add the checked item to the array
      setfinalCsvData((prevCheckedItems) => [...prevCheckedItems, item]);
    } else {
      // Remove the unchecked item from the array
      setfinalCsvData((prevCheckedItems) =>
        prevCheckedItems.filter(
          (checkedItem) => checkedItem.email !== item?.email
        )
      );
    }
  };
  // Import CSV Function
  const Toggle = (
    <Button className="flex gap-1 justify-center items-center text-xs w-auto px-3 h-9 bg-blue-50 border-gray-300 rounded-lg border font-semibold hover:bg-gray-50 hover:border-gray-300 focus:bg-white focus:border-gray-300">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.67 10.417v-4.75c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.093-1.093c-.534-.272-1.235-.272-2.635-.272H7.336c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 0 0-1.093 1.093c-.272.535-.272 1.235-.272 2.635v8.666c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h3.083m1.25-9.166h-5M8.336 12.5H6.669m6.667-6.667H6.669m5.834 10 2.5 2.5m0 0 2.5-2.5m-2.5 2.5v-5"
          stroke="#344054"
          strokeWidth="1.667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Import CSV
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m6.394 9 6 6 6-6"
          stroke="#000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );

  // Submit CSV Data
  const submitCsvDataNext = () => {
    // open new pannel
    dialogCloseCSV();
    setDialogNextCsv(true);
  };
  // handleBlurForEmail function calls GetIndividualByEmail api when users user click outside the input/select tag for email after entering email
  const handleBlurForEmail = () => {
    GetIndividualByEmail();
  };

  function isEmail(value) {
    // Regular expression for checking email format
    if (value !== undefined && value !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    }
  }

  function isElevenDigitNumber(value) {
    // Remove spaces and check if it's 11 digits
    if (value !== undefined && value !== "") {
      const strippedValue = value.replace(/\s/g, "");
      const numberRegex = /^\d{11}$/;
      return numberRegex.test(strippedValue);
    }
  }

  // function for Select tag for email/abn for 1st page & email for 2nd page
  let prevSearchValue = "";
  const handleSearchChange = debounce((newInputValue) => {
    // Clear the emailError if the search input has changed
    if (
      prevSearchValue !== newInputValue &&
      emailError !== "" &&
      selectedOption != ""
    ) {
      setEmailError("");
      setSelectedOption("");
    }
    setFormErrors((prevError) => ({
      ...prevError,
      correctFormat: "",
    }));
    // Update the previous search value
    prevSearchValue = newInputValue;
    setSearch(newInputValue);
  }, 500)
  // Handle the select option change for email/abn for 1st page & email for 2nd page
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    // check email or number
    if (isEmail(selectedOption?.label)) {
      setEmail(selectedOption?.label);

      //Here we set selected provider details

      // setAbn(selectProviderDetails ? selectProviderDetails?.abn_nzbn : "")
      if (abn && abnEditOrNot) {
        if (selectProviderDetails?.abn_nzbn) {
          setAbn(selectProviderDetails ? selectProviderDetails?.abn_nzbn : "")
        }
      }
      else {
        setAbn(selectProviderDetails ? selectProviderDetails?.abn_nzbn : "")
      }
      if (previousFirstAbn && !selectProviderDetails?.abn_nzbn) {
        setAbn(previousFirstAbn)
      }

      if (
        selectProviderDetails?.first_name ||
        selectProviderDetails?.last_name
      ) {
        setFullNameError((prevError) => ({
          ...prevError,
          first_name: selectProviderDetails?.first_name
            ? ""
            : prevError.first_name,
          last_name: selectProviderDetails?.last_name
            ? ""
            : prevError.last_name,
        }));
      }


    } else if (isElevenDigitNumber(selectedOption?.label)) {
      setAbn(selectedOption?.label);
    } else {
      // show message
      //Please Enter the Value in correct format
    }

    // if (prevSelectedOptionLabel !== selectedOption?.label) {
    setAbnError("");
    // setEmailError("")
    // setEmailError("");
    setFormErrors((prevError) => ({
      ...prevError,
      conserveEmailReq: "",
    }));

    GetIndividualByEmail(selectedOption?.label);
  };

  // Custom option for the input value for email/abn for 1st page & email for 2nd page
  const customOption = { value: search, label: search };
  // Check if the inputValue exists in options, if not, add it
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : [...searchOptions, customOption];

  // handleABN function add space required for abn automatically
  const handleABN = () => {
    let newValue = abn?.replace(/\D/g, ""); // Remove non-digit characters
    const length = newValue?.length;
    if (length <= 11) {
      // newValue = `${newValue.slice(0, 2)} ${newValue.slice(2)}`;
      newValue = `${newValue.slice(0, 2)} ${newValue.slice(
        2,
        5
      )} ${newValue.slice(5, 8)} ${newValue.slice(8, 11)}`;
    }
    setAbn(newValue);
  };

  //Get all contractors invited under the specific client
  const GetAllInvitedProvidersOfClient = useCallback(async () => {
    if (functionAssignmentUuid) {
      dispatch(
        userActions.GetAllInvitedProvidersOfClient(
          functionAssignmentUuid,
          searchTerm,
          searchTerm ? 1 : filterpage,
          filterorder,
          filtersort,
          filterlimit
        )
      ).then((response) => {
        if (response?.success === true) {
          const arrayData = response?.data?.rows;
          setCountData(response?.data?.count);
          let contactorData = [];
          for (let key in arrayData) {
            let invitedDate = moment(arrayData[key]?.invite_date).format(
              "DD/MM/YYYY"
            );
            contactorData.push({
              providerName:
                arrayData[key]?.first_name + " " + arrayData[key]?.last_name,
              ProviderType: arrayData[key]?.provider_type_name,
              abnNzbn: arrayData[key]?.provider_abn_nzbn,
              inviteDate: invitedDate,
              inviteStatus: arrayData[key]?.invite_status,
              inviteContractorUuid: arrayData[key]?.invite_provider_uuid,
              email: arrayData[key]?.email,
            });
          }
          setContractorDataInvited(contactorData);
          setTotalPagesInvite(Math.ceil(response?.data?.count / filterlimit));
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        } else {
        }
      });
    }
    // eslint-disable-next-line
  }, [dispatch, searchTerm, filterpage, filterorder, filtersort, filterlimit]);

  useEffect(() => {
    GetAllInvitedProvidersOfClient();
  }, [
    functionAssignmentUuid,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
    refetchData
  ]);

  const GetAllProvidersPrimaryList = async () => {
    if (functionUuid && contactTypeUuid && search) {
      props?.onGetAllContractorPrimaryList(
        functionUuid,
        contactTypeUuid,
        functionAssignmentUuid,
        search
      )
        .then((response) => {
          if (response?.success === true) {
            const arrayData = response?.data;
            let contactorData = [];
            for (let key in arrayData) {
              contactorData.push({
                ContractorOrgName: arrayData[key].trading_name,
                ContractorFAUuid: arrayData[key].function_assignment_uuid,
                abn: arrayData[key].abn_nzbn,
                functionUUid: arrayData[key].function_uuid,
                individualUuid: arrayData[key].individual_uuid,
                email: arrayData[key].email,
                relationUuid: arrayData[key].f_a_relation_uuid,
                ContractorFirstName: arrayData[key].first_name,
                ContractorLastName: arrayData[key].last_name,
                userUuid: arrayData[key].user_uuid
              });
            }
            setSelectProviderDetails(arrayData?.[0])
            setProviderOrgId(arrayData?.[0] ? arrayData?.[0]?.organisation_uuid : "")
            setContractorFAUuid(arrayData?.[0] ? arrayData?.[0]?.function_assignment_uuid : "");
            setProviderUserUuid(arrayData?.[0] ? arrayData?.[0]?.user_uuid : "");



            // setAbn(arrayData?.[0] ? arrayData?.[0]?.abn_nzbn : "")
            // if (abn && abnEditOrNot) {
            //   if (arrayData?.[0]?.abn_nzbn) {
            //     setAbn(arrayData?.[0] ? arrayData?.[0]?.abn_nzbn : "")
            //   }
            // }
            // else {
            //   setAbn(arrayData?.[0] ? arrayData?.[0]?.abn_nzbn : "")
            // }
            // if(previousFirstAbn &&  !arrayData?.[0]?.abn_nzbn ){
            //   setAbn(previousFirstAbn)
            // }

            // if (
            //   contactorData?.[0]?.ContractorFirstName ||
            //   contactorData?.[0]?.ContractorLastName
            // ) {
            //   setFullNameError((prevError) => ({
            //     ...prevError,
            //     first_name: contactorData?.[0]?.ContractorFirstName
            //       ? ""
            //       : prevError.first_name,
            //     last_name: contactorData?.[0]?.ContractorLastName
            //       ? ""
            //       : prevError.last_name,
            //   }));
            // }

            // to hide the provider from the list if relationUuid exist
            // below list is used to check if the provider exist globally or not
            // if provider exist globally then if exist under the same client or not
            const listData = [];
            if (contactorData) {
              contactorData.map((item) => {
                if (!item?.relationUuid) {
                  listData.push(item);
                }
              });
            }
            setSelectOptions(listData);
            // if relationUuid exist then user cannot select that email/abn as that provider exist under the same client
            const checkByEmail = contactorData.find(
              (item) => item?.email === search
              // (item) => item?.email === email
            );
            if (checkByEmail?.relationUuid) {
              setEmailError(
                "This email cannot be selected because it alredy exist as provider"
              );
              setTimeout(() => {
                setEmail("");
                // setSelectedOption("")
              }, 3000);

              setIndividualUuid("");
              setFullName((prevState) => ({
                ...prevState,
                first_name: "",
                last_name: "",
              }));
            }
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          } else {
          }
        });
    }
  };

  const GetIndividualByEmail = async (email) => {
    if (email) {
      try {
        const response = await props?.onGetIndividualByEmail(email);
        if (response?.success === true) {
          const arrayData = response?.data;

          if (arrayData && arrayData?.is_conserve_team === false) {
            if (
              emailError !==
              "This email cannot be selected because it alredy exist as provider"
            ) {
              setIndividualUuid(arrayData?.individual_uuid);
              setFullName({
                first_name: arrayData?.first_name,
                last_name: arrayData?.last_name,
              });
              if (arrayData?.first_name || arrayData?.last_name) {
                setFullNameError((prevError) => ({
                  ...prevError,
                  first_name: arrayData?.first_name ? "" : prevError.first_name,
                  last_name: arrayData?.last_name ? "" : prevError.last_name,
                }));
              }
              setEmail(arrayData?.email);
              // if (arrayData?.abn && abn === "") {
              //   setAbn(arrayData?.abn ? arrayData?.abn : "");
              // }
            }

          } else if (arrayData && arrayData?.is_conserve_team === true) {
            // setEmailError("This email cannot be selected");
            setFormErrors((prevError) => ({
              ...prevError,
              conserveEmailReq: "This email cannot be selected",
              // Clear the error message initially
            }));
            setIndividualUuid("");
            setFullName((prevState) => ({
              ...prevState,
              first_name: "",
              last_name: "",
            }));
            // setSelectedOption("");
          }
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      } catch (error) {
        console.error(
          "Error occurred while fetching individual by email:",
          error
        );
        // Handle error if necessary
      }
    }
  };


  const OnGetAllComplianceOfClientList = useCallback(async () => {
    if (id) {
      try {
        const response = await dispatch(GetAllComplianceOfClientList({ client_org_uuid: id }));
        if (response?.success) {
          const arrayData = response?.data;

          // Transform the data to map over required fields
          const transformedData = arrayData.map((item) => {
            return {
              label: item?.checklist_name,
              value: item?.checklist_uuid,
              recipient_type: item?.recipient_type,
            };
          });

          // Filter the data by recipient_type
          const providerData = transformedData.filter(item => item.recipient_type === 'provider');
          const workerData = transformedData.filter(item => item.recipient_type === 'worker');

          // Set the filtered data to their respective states
          setAllCheckListData(providerData); // Provider data
          setWorkerAllCheckListData(workerData); // Worker data
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        } else {
          console.error("Failed to fetch data:", response);
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    }
  }, [id, dispatch]);
  
  useEffect(() => {
    OnGetAllComplianceOfClientList();
  }, [id, OnGetAllComplianceOfClientList]);



  useEffect(() => {
    if (AllContactType && Allfunction) {
      // contactTypeUuid if contact is provider primary
      const contactTypeUuid = AllContactType.find(
        (item) => item?.contact_type === "provider primary"
      )?.contact_type_uuid;

      // functionUUID if function name is provider
      const functionUUID = Allfunction.find(
        (item) => item?.function_name === "provider"
      )?.function_uuid;
      setfunctionUuid(functionUUID);
      setContactTypeUuid(contactTypeUuid);
      GetAllProvidersPrimaryList();
    }
  }, [Allfunction, AllContactType, search]);

  const handleFileChange = (files) => {
    let totalSize = 0;
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    // Filter and validate files
    let fileTypeError = false;
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          setdocumentError(
            "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
          );
          fileTypeError = true
          return false;
        }

        // Check individual file size (2MB limit)
        if (fileSize > 25 * 1024 * 1024) {
          setdocumentError("File size exceeds the 25MB limit.");
          totalSize += fileSize;
          return false;
        }
        else {
          totalSize += fileSize;
          return true;
        }

      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
      return;
    }

    // Update state if everything is valid
    if (validatedFiles?.length > 0) {
      setAttachment({ files: validatedFiles });
      // setFilesUploaded(true)
      if (fileTypeError) {
        setdocumentError(
          "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
        );
        return false;
      }
      else {
        if (totalSize > 25 * 1024 * 1024) {
          setdocumentError("Total file size exceeds the maximum limit of 25MB.");
          return;
        }
        else {
          setdocumentError(""); // Clear any existing errors
        }
      }
    } else {
      setAttachment({ files: [] }); // Clear files if no valid files
    }
  };
  const handleFileRemove = (files) => {
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    let totalSize = 0;
    let fileTypeError = false;

    // Revalidate remaining files after one is removed
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          fileTypeError = true;
          return false;
        }

        totalSize += fileSize;
        return true;
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
    } else if (fileTypeError) {
      setdocumentError(
        "Invalid file type detected. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
      );
    } else {
      setdocumentError(""); // Clear any existing errors if valid
    }

    // Update state with the validated files
    setAttachment({ files: validatedFiles });

    // Handle edge case when all files are removed
    if (validatedFiles?.length === 0) {
      // setFilesUploaded(false);
    }


    if (files.length === 0) {
      // setFilesUploaded(false)
    }
  };
  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment.files.forEach((item) => {
      if (item?.size) {
        totalSize += item?.size;
      }
    });
    return totalSize;
  };

  const InviteContractor = (e) => {
    const inputValue = selectedOption?.label;
    let abnValidation;
    let correctFormatVal;
    let emailValidation;

    const CheckListValidation = Validations({
      value: selCompChecklist?.length == 0 ? "" : selCompChecklist,
      Validations: { required: true },
    });
    if (!CheckListValidation?.valid) {
      setChecklistError(CheckListValidation?.error_msg);
    }
    const WorkerCheckListValidation = Validations({
      value: workerSelCompChecklist?.length == 0 ? "" : workerSelCompChecklist,
      Validations: { required: true },
    });
    if (!WorkerCheckListValidation?.valid) {
      setWorkerChecklistError(WorkerCheckListValidation?.error_msg);
    }
    if (email?.trim() === "" || !selectedOption?.label) {
      emailValidation = Validations({
        value: email,
        Validations: { email: true, required: true },
      });
      setEmailError(
        email?.trim() === ""
          ? "This field is required!"
          : "Please enter the value in the correct format"
      );
    }
    if (isEmail(selectedOption?.label)) {
      emailValidation = Validations({
        value: email,
        Validations: { email: true, required: true },
      });
      if (!emailValidation.valid) {
        setEmailError(emailValidation.error_msg);
      }
    }

    if (emailError === "") {
      if (abn.trim() !== "" || isElevenDigitNumber(inputValue)) {
        abnValidation = Validations({
          value: abn,
          Validations: { required: true, ValidateABN: true },
        });
        if (!abnValidation.valid) {
          setAbnError(abnValidation.error_msg);
        }
      } else {
        // formError.correctFormat
        correctFormatVal = Validations({
          value: inputValue,
          Validations: { email: true, required: true },
        });
        if (!correctFormatVal.valid) {
          setFormErrors((prevError) => ({
            ...prevError,
            correctFormat:
              inputValue == "" || inputValue == undefined
                ? "This field is required!"
                : "Please enter the value in the correct format",
          }));
        }
      }
      const workerChecllistIds = workerSelCompChecklist?.map((item) => item?.value);
      const checllistIds = selCompChecklist?.map((item) => item?.value);
      const first_name = Validations({
        value: fullName?.first_name,
        Validations: { required: true },
      });
      const last_name = Validations({
        value: fullName?.last_name,
        Validations: { required: true },
      });
      if (!first_name?.valid) {
        setFullNameError((prevalue) => {
          return { ...prevalue, ["first_name"]: first_name?.error_msg };
        });
      }
      if (!last_name?.valid) {
        setFullNameError((prevalue) => {
          return { ...prevalue, ["last_name"]: last_name?.error_msg };
        });
      }

      const totalSize = calculateTotalAttachmentSize();
      if (totalSize > 25 * 1024 * 1024) {
        return;
      }

      if (
        formError?.correctFormat === "" &&
        first_name?.valid === true &&
        formError?.conserveEmailReq == "" &&
        last_name?.valid === true &&
        emailValidation?.valid === true &&
        abnError == "" &&
        documentError == "" &&
        CheckListValidation.valid == true &&
        WorkerCheckListValidation.valid == true
      ) {
        const payload = {
          individual_uuid: individualUuid ? individualUuid : "",
          provider_fa_uuid: contractorFAUuid ? contractorFAUuid : "",
          first_name: fullName.first_name,
          last_name: fullName.last_name,
          provider_abn_nzbn: abn,
          email: email,
          invite_message: message,
          client_fa_uuid: functionAssignmentUuid,
          provider_type_uuid: selectedDocumentType.value,
          checklistIds: JSON.stringify(checllistIds.length ? checllistIds : []),
          invite_attach: attachment,
          workerChecklistIds: JSON.stringify(workerChecllistIds.length ? workerChecllistIds : []),
          client_org_uuid: ClientSpecificProfileData?.organisation_uuid,
          current_user: current_user,
          trading_name: ClientSpecificProfileData?.trading_name,
          provider_user_uuid: providerUserUuid,
          provider_org_uuid: providerOrgId ? providerOrgId : ""
        };
        setButtonDisabled(true)
        props?.onInviteSpecificClientContractor(payload)
          .then((response) => {
            if (response?.success === true) {
              GetAllInvitedProvidersOfClient();
              setAddOpen(true);
              setProviderUserUuid("")
              setProviderOrgId("")
              setIsOpen(false);
              setButtonDisabled(false)
              onDialogClose();
            } else if (response?.status == 409) {
              setCloseAlreadyExist(true);
              setButtonDisabled(false)
              setProviderUserUuid("")
              setProviderOrgId("")
            } else if (response?.status === 401) {
              handleUnauthorizedAccess();
              setButtonDisabled(false)

            } else {
              setButtonDisabled(false)
              setProviderUserUuid("")
              setProviderOrgId("")

            }
          })
          .catch((error) => {
            setButtonDisabled(false)
            setProviderUserUuid("")
          });
      }
    }
  };

  //call permission api
  // const GetSpecificUserRole = useCallback(async () => {
  //   if (role_assignment_uuid) {
  //     dispatch(userActions.GetSpecificUserRole(role_assignment_uuid)).then(
  //       (response) => {
  //         if (response?.success === true) {
  //         } else if (response?.status === 401) {
  //           handleUnauthorizedAccess();
  //         }
  //       }
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [dispatch]);
  // useEffect(() => {
  //   GetSpecificUserRole();
  // }, []);

  // // Get ProviderList
  const fetchProviderList = useCallback(() => {
    dispatch(GetAllProviderTypesList()).then((response) => {
      const filterData = response?.data?.map((item) => ({
        value: item?.provider_type_uuid,
        label: item?.provider_type_name,
      }));
      setSearchOptions(filterData);
    });
  }, [dispatch, setSearchOptions]);

  useEffect(() => {
    if (dialogChoose || dialogNextCsv) {
      fetchProviderList();
    }
  }, [dialogChoose, dialogNextCsv]);

  // Set a form error
  const setFormError = (field, message) => {
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };

  const HandleAtachData = (event, name) => {
    SetCsvFileData((prevalue) => {
      return {
        ...prevalue,
        [name]: event,
      };
    });
  };


  return (
    <React.Fragment>
      <Tabs defaultValue="tab1" variant="pill">
        <div className="flex justify-between  px-2 flex-wrap gap-2">
          <div className="flex gap-2 flex-wrap">
            <div className="mb-5 client-provider-tab worker_provider_tabs">
              <TabList>
                <TabNav value="tab1">
                  <button onClick={() => { SetdefaultTab("tab1"); setsearchViewAll(newSearchValue) }}>
                    View All
                  </button>
                </TabNav>

                <TabNav value="tab3">
                  <button onClick={() => SetdefaultTab("tab3")}>
                    Compliant
                  </button>
                </TabNav>
                <TabNav value="tab4">
                  <button onClick={() => SetdefaultTab("tab4")}>
                    Non-compliant
                  </button>
                </TabNav>
                <TabNav value="tab2">
                  <button onClick={() => {
                    // setRefetchData(!refetchData);
                    setsearchTerm(newSearchValue);
                    SetdefaultTab("tab2")
                  }}>
                    Invited Providers
                  </button>
                </TabNav>
              </TabList>
            </div>
            <div className="w-80 h-10">
              <div className="input-wrapper">
                <div className="input-suffix-start left-3.5 ">
                  <img src={SearchIcon} alt="" className="h-5" />
                </div>
                {defaultTab === "tab1" ? (
                  <input
                    type="text"
                    name="hs-table-search"
                    className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight mr-2 focus:outline-none focus:shadow-outline"
                    placeholder="Search"
                    onChange={(e) => inputchangeSearch(e, "viewAll")}
                  />
                ) : (
                  <input
                    type="text"
                    name="hs-table-search"
                    className="input  pl-11 w-full  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight mr-2 focus:outline-none focus:shadow-outline"
                    placeholder="Search"
                    onChange={(e) => inputchangeSearch(e, "invitedList")}
                  />
                )}
                {/* <div className="absolute inset-y-0 right-3.5 flex items-center pl-4 pointer-events-none">
                  <img src={SettingIcon} alt="" />
                </div> */}
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-2 reset-dropdown px-2">
            <Dropdown renderTitle={Toggle} isOpen={true}>
              <Dropdown.Item eventKey="a">
                <CSVLink
                  className="client_portal_csv"
                  data={CSVData.map((row) =>
                    row.filter((column) => column !== "action")
                  )}
                  filename={"Sample file for worker invite.csv"}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 10V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V10M11.3333 6.66667L8 10M8 10L4.66667 6.66667M8 10V2"
                      stroke="#344054"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Download template</span>
                </CSVLink>
              </Dropdown.Item>
              <Dropdown.Item eventKey="b">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.33317 1.51318V4.26688C9.33317 4.64025 9.33317 4.82693 9.40583 4.96954C9.46975 5.09498 9.57173 5.19697 9.69718 5.26088C9.83978 5.33354 10.0265 5.33354 10.3998 5.33354H13.1535M5.99984 10.0002L7.99984 12.0002M7.99984 12.0002L9.99984 10.0002M7.99984 12.0002L7.99984 8.00016M9.33317 1.3335H5.8665C4.7464 1.3335 4.18635 1.3335 3.75852 1.55148C3.3822 1.74323 3.07624 2.04919 2.88449 2.42552C2.6665 2.85334 2.6665 3.41339 2.6665 4.5335V11.4668C2.6665 12.5869 2.6665 13.147 2.88449 13.5748C3.07624 13.9511 3.3822 14.2571 3.75852 14.4488C4.18635 14.6668 4.7464 14.6668 5.8665 14.6668H10.1332C11.2533 14.6668 11.8133 14.6668 12.2412 14.4488C12.6175 14.2571 12.9234 13.9511 13.1152 13.5748C13.3332 13.147 13.3332 12.5869 13.3332 11.4668V5.3335L9.33317 1.3335Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {/* Import file */}
                <span>Import file</span>
                <input
                  className="upload_csv"
                  type="file"
                  accept=".csv"
                  onChange={handleFileChangeCSV}
                />
              </Dropdown.Item>
            </Dropdown>
            <div className="flex">
              <BtnPrimary
                variant="solid"
                className="w-auto h-9 flex items-center justify-center text-xs px-3.5"
                type="button"
                onClick={() => openChoose()}
              >
                Add provider
                <span class="ml-3">
                  <img src={PlusIcon} alt="" class="h-5" />
                </span>
              </BtnPrimary>
            </div>
          </div>
        </div>
        {/* client_fa_uuid:"",contact_type_uuid:"" */}
        <TabContent value="tab1">
          <ContractorsList
            handleSort={handleSort}
            client_fa_uuid={functionAssignmentUuid}
            contact_type_uuid={contactTypeUuid}
            filtersort={filtersort}
            searchViewAll={searchViewAll}
            filterorder={filterorder}
            contractorData={contractorDataInvited}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </TabContent>
        <TabContent value="tab2">
          <InviteContrators
            handleSort={handleSort}
            filtersort={filtersort}
            countData={countData}
            filterorder={filterorder}
            contractorData={contractorDataInvited}
            totalPages={totalPagesInvite}
            handlePageChange={handlePageChangeInvite}
            tradingName={props?.tradingName}
            filterpage={filterpage}
            setfilterpage={setfilterpage}
            setfilterlimit={setfilterlimit}
            refetchData={refetchData}
            setRefetchData={setRefetchData}
          />
        </TabContent>
      </Tabs>
      <Dialog
        isOpen={dialogChoose}
        onClose={onChooseClose}
        onRequestClose={onChooseClose}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={ContractorIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add a Provider
            </h5>
            <p>Find an existing Provider in the system or invite a new one.</p>
          </div>
        </div>

        <div className="px-6">
          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4 mb-1 block">
              Provider type*
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select document"
                options={searchProviderOptions}
                value={selectedDocumentType}
                onChange={(selectedOption) => selectDocType(selectedOption)}
              ></Select>
              {!selectedDocumentType && formError.selectDoc && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {formError.selectDoc}
                </p>
              )}
            </div>
          </div>

          <div className="mb-5">
            <label className="font-medium text-gray-700 mb-1 block">
              Email address or ABN/NZBN*
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-10 select-options"
                placeholder="Search for a Provider"
                isClearable={true}
                isSearchable={true}
                options={updatedOptions ? updatedOptions : selectedOption}
                value={selectedOption || customOption}
                onChange={handleSelectChange}
                handleSearchChange={handleSearchChange}
              ></Select>

              {formError.conserveEmailReq && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {formError.conserveEmailReq}
                </p>
              )}

              {emailError ? (
                <p className="text-xs text-red-600 mb-0 mt-1">{emailError}</p>
              ) : (
                abnError && (
                  <p className="text-xs text-red-600 mb-0 mt-1">{abnError}</p>
                )
              )}
              {formError.correctFormat && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {formError.correctFormat}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onChooseClose}>
            Cancel
          </BtnLight>
          <BtnPrimary onClick={() => openDialog()} className="ml-1.5">
            Add Provider
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={ContractorIcon} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add a Provider
            </h5>
            <p>Find an existing Provider in the system or invite a new one. </p>
          </div>
        </div>
        <div className="pl-4">
          <div className="h-500">
            <ScrollBar>
              <div className="pr-6 pl-2">
                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Provider type*
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select document"
                      options={searchProviderOptions}
                      value={selectedDocumentType}
                      readOnly
                    // onChange={(selectedOption) =>
                    //   selectDocType(selectedOption)
                    // }
                    ></Select>
                    {!selectedDocumentType && formError.selectDoc && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.selectDoc}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-1 gap-4">
                  <div className="mb-5 w-3/6">
                    <Label className="text-xs font-medium block mb-1">
                      Email address*
                    </Label>

                    {/* email can be applied to the below condition instead of contractorFAUuid */}
                    {/* {contractorFAUuid || pageOne ? ( */}
                    {(abnEditOrNot == false && pageOne) || email ? (

                      <Input
                        className="h-9 w-full"
                        placeholder=""
                        value={email}
                        // inputchangehandler={(e) => inputchangehandler(e, "email")}
                        readOnly={email ? true : false}
                      // onBlur={handleBlurForEmail}
                      />
                    ) : (
                      <Select
                        className="w-full h-10 select-options"
                        placeholder="Search for a Provider"
                        isClearable={true}
                        isSearchable={true}
                        options={
                          updatedOptions ? updatedOptions : selectedOption
                        }
                        value={selectedOption || customOption}
                        onChange={(selectedValue) => {
                          if (!selectedValue) {
                            isSearchClear();
                          }
                          handleSelectChange(selectedValue);
                        }}
                        handleSearchChange={handleSearchChange}
                      // onBlur={handleBlurForEmail}
                      />
                    )}
                    {formError.correctFormat && (
                      <p className="text-xs text-red-600 mb-0 mt-1">
                        {formError.correctFormat}
                      </p>
                    )}
                    {!formError.correctFormat && emailError && (
                      <p className="text-xs text-red-600 mb-0 mt-1">
                        {emailError}
                      </p>
                    )}
                    {formError.conserveEmailReq && (
                      <p className="text-xs text-red-600 mb-0 mt-1">
                        {formError.conserveEmailReq}
                      </p>
                    )}
                  </div>
                  <div className="w-3/6">
                    <Label className="text-xs font-medium block mb-1">
                      ABN/NZBN
                    </Label>
                    <Input
                      // type="number"
                      className="h-9 w-full"
                      placeholder=""
                      value={abn}
                      inputchangehandler={(e) => inputchangehandler(e, "abn")}
                      onBlur={handleABN}
                      readOnly={abnEditOrNot ? true : false}
                      maxLength="14"
                      minLength="11"
                    />
                    {abnError && (
                      <p className="text-xs text-red-600 mb-0 mt-1">
                        {abnError}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-1 gap-4">
                  <div className="mb-5 w-3/6">
                    <Label className="text-xs font-medium block mb-1">
                      First name*
                    </Label>

                    <Input
                      type="text"
                      className="h-9 w-full"
                      placeholder="Enter Provider’s first name"
                      value={fullName.first_name}
                      inputchangehandler={(e) =>
                        inputchangehandler(e, "first_name")
                      }
                      readOnly={individualUuid ? true : false}
                    />
                    {fullNameError.first_name && (
                      <p className="text-xs text-red-600 mb-0 mt-1">
                        {fullNameError.first_name}
                      </p>
                    )}
                  </div>
                  <div className="w-3/6">
                    <Label className="text-xs font-medium block mb-1">
                      Last name*
                    </Label>
                    <Input
                      type="text"
                      className="h-9 w-full"
                      placeholder="Enter Provider’s last name"
                      value={fullName.last_name}
                      inputchangehandler={(e) =>
                        inputchangehandler(e, "last_name")
                      }
                      readOnly={individualUuid ? true : false}
                    />
                    {fullNameError.last_name && (
                      <p className="text-xs text-red-600 mb-0 mt-1">
                        {fullNameError.last_name}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Assign Provider Compliance Checklist(s)
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select Compliance Checklist"
                      isMulti={true}
                      options={allCheckListData}
                      value={selCompChecklist}
                      onChange={(selectedOption) =>
                        selectCompChecklist(selectedOption)
                      }
                    ></Select>
                    {selCompChecklist?.length === 0 && checkListError && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {checkListError}
                      </p>
                    )}

                    {/* <Select
                      value={selectedOption}
                      onChange={(e) => HandleDocData(e, "document_type_uuid")}
                      options={Array.isArray(selectDocumentData) ? selectDocumentData : []}
                    /> */}
                  </div>
                </div>

                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Assign Worker Compliance Checklist(s)
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select Compliance Checklist"
                      isMulti={true}
                      options={workerAllCheckListData}
                      value={workerSelCompChecklist}
                      onChange={(selectedOption) =>
                        selectWorkerCompChecklist(selectedOption)
                      }
                    ></Select>
                    {workerSelCompChecklist?.length === 0 && workerCheckListError && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {workerCheckListError}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-5">
                  <Label className="text-xs font-medium block mb-1">
                    Attachment (optional)
                  </Label>
                  <Upload
                    draggable
                    className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                    onChange={handleFileChange}
                    onFileRemove={handleFileRemove}
                    multiple={true}
                    maxFileSize={25 * 1024 * 1024}
                  >
                    <span className="text-blue-700 font-semibold">
                      Click to upload
                    </span>{" "}
                    <span className="font-medium text-gray-600">
                      or drag and drop
                    </span>
                    <p className="text-gray-600">(Max 25mb. Only pdf, heic, png, and jpg files will be accepted)</p>
                  </Upload>
                  {documentError && <p className='text-xs text-red-600 mt-1'>{documentError}</p>}

                </div>
                <div className="mb-5 message-box-height">
                  <Label className="text-xs font-medium block mb-1">
                    Message (optional)
                  </Label>
                  <RichTextEditor
                    value={message}
                    onChange={(value) => setMessage(value)}
                  />
                </div>
                {/* <h3 className="text-base font-semibold text-gray-900 mb-4">
                  Payment options
                </h3>
                <div className="flex mb-4">
                  <Checkbox />
                  <div>
                    <span className="text-sm font-medium text-gray-700">
                      You pay for the Provider
                    </span>
                    <p className="text-xs text-gray-600 mt-1">
                      By selecting this option, you're agreeing to cover the
                      Conserve subscription fees for your Provider after they
                      have accepted the invite.
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <Checkbox />
                  <div>
                    <span className="text-sm font-medium text-gray-700">
                      Provider pays themselves
                    </span>
                    <p className="text-xs text-gray-600 mt-1">
                      By selecting this option, the Provider will pay for their
                      own Conserve subscription. They’ll be prompted to make
                      payment during their onboarding process.
                    </p>
                  </div>
                </div> */}
              </div>
            </ScrollBar>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight disabled={buttonDisabled} className="mr-1.5" variant="plain" onClick={onDialogClose}>
            Cancel
          </BtnLight>
          <BtnPrimary disabled={buttonDisabled} onClick={() => InviteContractor()} className="ml-1.5">
            {buttonDisabled == true ? "Adding..." : "Add Provider"}
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="22"
              height="23"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#000"
                stroke-width="1.667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onAddDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>

      {/* CSV Dilog Box */}
      <Dialog
        isOpen={dialogOpenCSV}
        onClose={dialogCloseCSV}
        onRequestClose={dialogCloseCSV}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_12450_78578)">
                <path
                  d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.81 14.889C18.1307 14.889 18.391 14.6287 18.391 14.308V13.5333C18.391 12.785 17.7852 12.1777 17.0354 12.1777H15.486C14.7362 12.1777 14.1304 12.785 14.1304 13.5333V14.308C14.1304 14.6287 14.3907 14.889 14.7114 14.889H17.81ZM17.229 13.8727H15.2924V13.5333C15.2924 13.4265 15.3792 13.3397 15.486 13.3397H17.0354C17.1422 13.3397 17.229 13.4265 17.229 13.5333V13.8727Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M10.78 14.0664C8.90316 14.098 5.33487 14.7346 3.56036 18.5573C3.34841 19.0142 3.38479 19.737 4.73027 19.776C6.07575 19.815 8.606 19.7923 10.166 19.776"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="11.5698"
                  cy="7.90674"
                  r="3.75986"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_12450_78578">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Providers
            </h5>
            <p>
              Please verify that the uploaded CSV information of Providers is
              accurate.
            </p>
          </div>
        </div>
        <div className="h-96 pr-2">
          <Scrollbar>
            <div className="px-4">
              <div className="overflow-hidden border rounded-lg border-gray-200">
                <Table>
                  <THead>
                    <Tr>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          First Name
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Last Name
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                              stroke="#475467"
                              strokeWidth="1.333"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          Email address
                        </span>
                      </Th>
                      <Th className="w-1/3">
                        <span className="flex items-center gap-1">
                          ABN/NZBN
                        </span>
                      </Th>
                      <Th className="w-1/3"></Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {csvData?.map((item, index) => {
                      return (
                        <React.Fragment>
                          <Tr key={index}>
                            <Td>{item?.first_name}</Td>
                            <Td>{item?.last_name}</Td>
                            <Td>{item?.email}</Td>
                            <Td>{item?.provider_abn_nzbn}</Td>
                            <Td>
                              {item?.is_validated ? (
                                <Checkbox
                                  defaultChecked={true}
                                  onChange={(event) => {
                                    handlecheckbox(event, index);
                                  }}
                                />
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="m18 6.5-12 12m0-12 12 12"
                                    stroke="red"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })}
                  </TBody>
                </Table>
              </div>
            </div>
          </Scrollbar>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={() => dialogCloseCSV()} className="mr-1.5">
            Cancel
          </BtnLight>
          <BtnPrimary
            disabled={!finalCsvData.length}
            onClick={() => submitCsvDataNext()}
          >
            Next
          </BtnPrimary>
        </div>
      </Dialog>
      {/* Succesfull Dilog Box */}
      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="22"
              height="23"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#000"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onAddDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>

      {/* Invite Provider import csv */}
      <Dialog
        isOpen={dialogNextCsv}
        onClose={onCloseNextCsv}
        onRequestClose={onCloseNextCsv}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_12450_78578)">
                <path
                  d="M19.1658 20.8745C19.9709 20.8745 20.6214 20.2225 20.6214 19.4191V15.5467C20.6214 14.7434 19.9709 14.0914 19.1658 14.0914H13.3557C12.5506 14.0914 11.9 14.7434 11.9 15.5467V19.4191C11.9 20.2225 12.5506 20.8745 13.3557 20.8745H19.1658ZM19.1658 19.5128H13.3557C13.304 19.5128 13.262 19.4707 13.262 19.4191V15.5467C13.262 15.4951 13.304 15.4531 13.3557 15.4531H19.1658C19.2174 15.4531 19.2594 15.4951 19.2594 15.5467V19.4191C19.2594 19.4707 19.2174 19.5128 19.1658 19.5128Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M19.2593 15.5458C19.2593 16.0251 18.8703 16.4141 18.391 16.4141H14.1303C13.651 16.4141 13.262 16.0251 13.262 15.5458C13.262 15.1699 12.9569 14.8648 12.581 14.8648C12.2051 14.8648 11.9 15.1699 11.9 15.5458C11.9 16.7769 12.8992 17.7761 14.1303 17.7761H18.391C19.6221 17.7761 20.6213 16.7769 20.6213 15.5458C20.6213 15.1699 20.3162 14.8648 19.9403 14.8648C19.5644 14.8648 19.2593 15.1699 19.2593 15.5458Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.81 14.889C18.1307 14.889 18.391 14.6287 18.391 14.308V13.5333C18.391 12.785 17.7852 12.1777 17.0354 12.1777H15.486C14.7362 12.1777 14.1304 12.785 14.1304 13.5333V14.308C14.1304 14.6287 14.3907 14.889 14.7114 14.889H17.81ZM17.229 13.8727H15.2924V13.5333C15.2924 13.4265 15.3792 13.3397 15.486 13.3397H17.0354C17.1422 13.3397 17.229 13.4265 17.229 13.5333V13.8727Z"
                  fill="#0E4E9A"
                  stroke="#0E4E9A"
                  stroke-width="0.2"
                />
                <path
                  d="M10.78 14.0664C8.90316 14.098 5.33487 14.7346 3.56036 18.5573C3.34841 19.0142 3.38479 19.737 4.73027 19.776C6.07575 19.815 8.606 19.7923 10.166 19.776"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="11.5698"
                  cy="7.90674"
                  r="3.75986"
                  stroke="#0E4E9A"
                  stroke-width="1.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_12450_78578">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add Providers
            </h5>
            <p>
              Ensure Providers are categorised correctly and send an optional
              invitation message.
            </p>
          </div>
        </div>
        <div className="pl-4">
          <div className="h-500">
            <ScrollBar>
              <div className="pr-6 pl-2">
                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Provider type*
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select document"
                      options={searchProviderOptions}
                      // value={selectedDocumentType}
                      onChange={(e) =>
                        HandleAtachData(e.value, "provider_type_uuid")
                      }
                    ></Select>
                    {!CsvFileData.provider_type_uuid && formError.selectDoc && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.selectDoc}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Assign Compliance Checklist*
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select document"
                      isMulti={true}
                      options={allCheckListData}
                      value={selCompChecklist}
                      onChange={(selectedOption) => {
                        selectCompChecklist(selectedOption);
                        setFormErrors((prevError) => ({
                          ...prevError,
                          comCheckListId: "",
                        }));
                      }}
                    ></Select>
                    {!CsvFileData.provider_type_uuid && formError.selectDoc && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {formError.comCheckListId}
                      </p>
                    )}

                  </div>
                </div>

                <div className="mb-5">
                  <label className="font-medium text-gray-700 w-1/4">
                    Assign Worker Compliance Checklist(s)
                  </label>
                  <div className="w-full hide-select-elt">
                    <Select
                      className="w-full h-9 select-options"
                      placeholder="Select Compliance Checklist"
                      isMulti={true}
                      options={workerAllCheckListData}
                      value={workerSelCompChecklist}
                      onChange={(selectedOption) =>
                        selectWorkerCompChecklist(selectedOption)
                      }
                    ></Select>
                    {workerSelCompChecklist?.length === 0 && workerCheckListError && (
                      <p className="text-xs text-red-600 mb-2 mt-0">
                        {workerCheckListError}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mb-5">
                  <Label className="text-xs font-medium block mb-1">
                    Attachment (optional)
                  </Label>
                  <Upload
                    draggable
                    className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                    // onChange={(e) => HandleAtachData(e, "invite_attach")}
                    onChange={handleFileChange}
                    onFileRemove={handleFileRemove}
                    multiple={true}
                    maxFileSize={25 * 1024 * 1024}
                  >
                    <span className="text-blue-700 font-semibold">
                      Click to upload
                    </span>{" "}
                    <span className="font-medium text-gray-600">
                      or drag and drop
                    </span>
                    <p className="text-gray-600">(Max 25mb. Only pdf, heic, png, and jpg files will be accepted)</p>
                  </Upload>
                  {documentError && <p className='text-xs text-red-600 mt-1'>{documentError}</p>}

                </div>
                <div className="mb-5 message-box-height">
                  <Label className="text-xs font-medium block mb-1">
                    Message (optional)
                  </Label>
                  <RichTextEditor
                    value={CsvFileData.invite_message}
                    // onChange={(value) => setMessage(value)}
                    onChange={(e) => HandleAtachData(e, "invite_message")}
                  />
                </div>
              </div>
            </ScrollBar>
          </div>
        </div>
        <div className="flex dailog-footer ">
          <BtnLight className="mr-1.5" variant="plain" onClick={onCloseNextCsv}>
            Cancel
          </BtnLight>
          <BtnPrimary onClick={() => submitInviteCsv()} className="ml-1.5">
            Invite Provider
          </BtnPrimary>
        </div>
      </Dialog>

      <Dialog
        isOpen={dialogInviteSent}
        bodyOpenClassName="overflow-hidden"
        onClose={OnCloseinviteProvider}
        onRequestClose={OnCloseinviteProvider}
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="48"
              height="49"
              viewBox="0 0 48 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="48" height="48" rx="24" fill="#D1FADF" />
              <path
                d="M22.4995 26.0002L32.9995 15.5002M22.6271 26.3282L25.2552 33.0862C25.4867 33.6816 25.6025 33.9793 25.7693 34.0662C25.9139 34.1415 26.0862 34.1416 26.2308 34.0664C26.3977 33.9797 26.5139 33.6822 26.7461 33.0871L33.3364 16.1994C33.5461 15.6622 33.6509 15.3936 33.5935 15.222C33.5437 15.0729 33.4268 14.956 33.2777 14.9062C33.1061 14.8488 32.8375 14.9536 32.3003 15.1633L15.4126 21.7536C14.8175 21.9858 14.52 22.102 14.4333 22.2689C14.3581 22.4135 14.3582 22.5858 14.4335 22.7304C14.5204 22.8972 14.8181 23.013 15.4135 23.2445L22.1715 25.8726C22.2923 25.9196 22.3527 25.9431 22.4036 25.9794C22.4487 26.0115 22.4881 26.051 22.5203 26.0961C22.5566 26.147 22.5801 26.2074 22.6271 26.3282Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invites sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 ">
          <BtnPrimary onClick={OnCloseinviteProvider}>Close</BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAlreadyOpen}
        onClose={onDialogAlreadyClose}
        onRequestClose={onDialogAlreadyClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="48" height="48" rx="24" fill="#FEE4E2" />
              <path
                d="M21.5 21L27.5 27M27.5 21L21.5 27M20.3 33H28.7C30.3802 33 31.2202 33 31.862 32.673C32.4265 32.3854 32.8854 31.9265 33.173 31.362C33.5 30.7202 33.5 29.8802 33.5 28.2V19.8C33.5 18.1198 33.5 17.2798 33.173 16.638C32.8854 16.0735 32.4265 15.6146 31.862 15.327C31.2202 15 30.3802 15 28.7 15H20.3C18.6198 15 17.7798 15 17.138 15.327C16.5735 15.6146 16.1146 16.0735 15.827 16.638C15.5 17.2798 15.5 18.1198 15.5 19.8V28.2C15.5 29.8802 15.5 30.7202 15.827 31.362C16.1146 31.9265 16.5735 32.3854 17.138 32.673C17.7798 33 18.6198 33 20.3 33Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            This Provider is already invited!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onDialogAlreadyClose}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  onInviteSpecificClientContractor: userActions.InviteSpecificProvider,
  onGetIndividualByEmail: userActions.GetIndividualByEmail,
  onGetAllContractorPrimaryList: userActions.GetAllProvidersPrimaryList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
