import React, { useState, useCallback, useEffect } from "react";
import { Doughnut } from 'react-chartjs-2';
import * as userActions from "../../../../../actions/index";
import Heading from "../../../../../common/element/Heading";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import Table from "../../../../../common/element/Table";
import Segment from "../../../../../common/element/Segment";
import Tag from "../../../../../common/element/Tag";
import DatePicker from "../../../../../common/element/DatePicker";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
import { Link } from "react-router-dom";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import { DashboardGetAllClientsStatusChart } from "../../../../../actions/Admin-Client/AllSiteApi";
import { updateRoutefunc } from "../../../../../redux/classes/common/updateRoute";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useDispatch, useSelector } from "react-redux";
ChartJS.register(ArcElement, Tooltip, Legend);

const TotalClients = () => {
    const dispatch = useDispatch();
    const handleUnauthorizedAccess = useUnauthorizedHandler()
    const [functionUuid, setfunctionUuid] = useState("");
    const [clientData, setClientData] = useState(null);
    const AllFunctions = useSelector((state) => state.allFunctions);
    useEffect(() => {
        if (AllFunctions?.allFunction) {
            let functionType = AllFunctions?.allFunction.filter(
                (item) => item?.function_name === "client"
            );
            setfunctionUuid(functionType[0]?.function_uuid);
        }
    }, []);
    let organisation_uuid;
    let permission_data;
    const permissionData = JSON.parse(localStorage?.getItem("permission_data"));
    if (permissionData !== null) {
        permission_data = permissionData;
        organisation_uuid = permissionData?.data?.organisation_uuid;

    }
    const data = {
        labels: [
            `Active ${clientData?.activeCount || 0}`,
            `Deactivated ${clientData?.inactiveCount || 0}`,
        ],
        datasets: [{
            label: 'Client Status',
            data: [
                Number(clientData?.activeCount || 0),
                Number(clientData?.inactiveCount || 0)
            ],
            backgroundColor: [
                '#86E5B2',
                '#FDA29B',
            ],
            hoverOffset: 4
        }]
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'right',

                labels: {
                    boxWidth: 30,
                    padding: 20,
                    usePointStyle: true, // Add this line to make legend circles
                },

            }
        }
    };


    // Custom plugin to draw text in the center
    const centerTextPlugin = {
        id: 'centerText',
        beforeDraw: (chart) => {
            const { ctx, data, chartArea: { width, height } } = chart;
            ctx.save();
            // Convert data to numbers and calculate the total
            const total = data.datasets[0].data.reduce((acc, value) => acc + Number(value), 0);

            // Text settings for the total number
            ctx.font = 'bold 20px Arial';
            ctx.fillStyle = '#1D2939';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            // Draw the total number
            ctx.fillText(`${total}`, width / 2, height / 2 - 10);

            // Text settings for "Clients"
            ctx.font = '13px Arial'; // Regular font with 10px size
            ctx.fillText('Clients', width / 2, height / 2 + 10);

            ctx.restore();
        }
    };



    const fetchDashboardAllClientsDoughnut = useCallback(() => {
        if (functionUuid) {
            dispatch(DashboardGetAllClientsStatusChart({ function_uuid: functionUuid }))
                .then((response) => {
                    if (response.success) {
                        setClientData(response?.data);
                    } else if (response.status === 401) {
                        handleUnauthorizedAccess();
                    }
                })
                .catch((error) => {
                    // Handle error here
                    console.error("Error fetching client data: ", error);
                });
        }
    }, [dispatch, functionUuid]);

    useEffect(() => {
        fetchDashboardAllClientsDoughnut();
    }, [fetchDashboardAllClientsDoughnut]);

    return (<React.Fragment>

        <div className="bg-white p-5 border border-gray-200 rounded-xl dark-shadow">
            <div className="card-header flex justify-between border-b border-gray-200 pb-5">
                <span className="text-gray-900 text-base font-semibold flex items-center">
                    <span className="mr-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M18.6695 4.84647L18.6696 4.84648C19.0667 4.91279 19.3578 5.2563 19.3578 5.65874V18.6823C19.3578 19.1371 18.9894 19.5058 18.5344 19.5058H12.7461C12.2915 19.5058 11.9227 19.1367 11.9227 18.6823C11.9227 18.2278 12.2915 17.8587 12.7461 17.8587H17.711L17.711 6.35648L10.6754 5.18397V18.6823C10.6754 19.1367 10.3067 19.5058 9.85198 19.5058C9.39734 19.5058 9.02867 19.1367 9.02867 18.6823V4.21176C9.02867 3.96961 9.13503 3.73994 9.31975 3.58353C9.50435 3.42699 9.74874 3.3597 9.98728 3.39948C9.98729 3.39948 9.98729 3.39948 9.98729 3.39948L18.6695 4.84647Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M11.9222 15.0647C11.9222 14.6098 12.2906 14.2413 12.7456 14.2413H15.6397C16.0948 14.2413 16.4633 14.6098 16.4633 15.0647V18.6824C16.4633 19.1373 16.0948 19.5059 15.6397 19.5059H12.7456C12.2906 19.5059 11.9222 19.1373 11.9222 18.6824V15.0647ZM13.5692 17.8589H14.8163V15.8883H13.5692V17.8589Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M3.24247 15.0647C3.24247 14.6098 3.6111 14.2413 4.06602 14.2413H6.96016C7.41511 14.2413 7.78359 14.6098 7.78359 15.0647V18.6824C7.78359 19.1373 7.41513 19.5059 6.96016 19.5059H4.06602C3.61107 19.5059 3.24247 19.1373 3.24247 18.6824V15.0647ZM4.88961 17.8589H6.1366V15.8883H4.88961V17.8589Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M1.30371 0.505331L1.30382 0.505348L9.98607 1.95258C10.3831 2.01877 10.6742 2.36218 10.6742 2.76485V18.6824C10.6742 19.1372 10.3059 19.5058 9.85079 19.5058H4.06252C3.60789 19.5058 3.2392 19.1367 3.2392 18.6824C3.2392 18.2281 3.60789 17.8589 4.06252 17.8589H9.02744L9.02751 3.46259L1.99195 2.28983V18.6824C1.99195 19.1367 1.62326 19.5059 1.16863 19.5059C0.713896 19.5059 0.345201 19.1367 0.345201 18.6824V1.31762C0.345201 1.07549 0.451765 0.846099 0.636275 0.68964C0.820964 0.532789 1.06534 0.465851 1.30371 0.505331Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M14.8163 11.0854C14.8163 10.8305 15.023 10.6236 15.2779 10.6236H16.0015C16.2565 10.6236 16.4633 10.8304 16.4633 11.0854V11.809C16.4633 12.0638 16.2565 12.2708 16.0015 12.2708H15.2779C15.023 12.2708 14.8163 12.0638 14.8163 11.809V11.0854Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M11.9217 11.0854C11.9217 10.8305 12.1285 10.6236 12.3835 10.6236H13.107C13.3619 10.6236 13.5688 10.8304 13.5688 11.0854V11.809C13.5688 12.0638 13.362 12.2708 13.107 12.2708H12.3835C12.1285 12.2708 11.9217 12.0638 11.9217 11.809V11.0854Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M11.9217 8.19138C11.9217 7.93656 12.1285 7.72959 12.3835 7.72959H13.107C13.3619 7.72959 13.5688 7.93654 13.5688 8.19138V8.91494C13.5688 9.16977 13.362 9.37673 13.107 9.37673H12.3835C12.1285 9.37673 11.9217 9.16978 11.9217 8.91494V8.19138Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M14.8163 8.19138C14.8163 7.93658 15.023 7.72959 15.2779 7.72959H16.0015C16.2565 7.72959 16.4633 7.93654 16.4633 8.19138V8.91494C16.4633 9.16977 16.2565 9.37673 16.0015 9.37673H15.2779C15.023 9.37673 14.8163 9.16976 14.8163 8.91494V8.19138Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M6.13657 11.0854C6.13657 10.8305 6.34338 10.6236 6.59837 10.6236H7.32192C7.57679 10.6236 7.78359 10.8305 7.78359 11.0854V11.809C7.78359 12.0638 7.57683 12.2708 7.32192 12.2708H6.59837C6.34338 12.2708 6.13657 12.0638 6.13657 11.809V11.0854Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M3.24192 11.0854C3.24192 10.8305 3.44873 10.6236 3.70371 10.6236H4.42727C4.68209 10.6236 4.88906 10.8304 4.88906 11.0854V11.809C4.88906 12.0638 4.68213 12.2708 4.42727 12.2708H3.70371C3.44872 12.2708 3.24192 12.0638 3.24192 11.809V11.0854Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M3.24192 8.19138C3.24192 7.93656 3.44871 7.72959 3.70371 7.72959H4.42727C4.68211 7.72959 4.88906 7.93652 4.88906 8.19138V8.91494C4.88906 9.16979 4.68213 9.37673 4.42727 9.37673H3.70371C3.44872 9.37673 3.24192 9.16978 3.24192 8.91494V8.19138Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M6.13657 8.19138C6.13657 7.93656 6.34336 7.72959 6.59837 7.72959H7.32192C7.57681 7.72959 7.78359 7.93657 7.78359 8.19138V8.91494C7.78359 9.16974 7.57683 9.37673 7.32192 9.37673H6.59837C6.34338 9.37673 6.13657 9.16978 6.13657 8.91494V8.19138Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M6.13657 5.29734C6.13657 5.04252 6.34336 4.83555 6.59837 4.83555H7.32192C7.57681 4.83555 7.78359 5.04252 7.78359 5.29734V6.0209C7.78359 6.27584 7.5768 6.48269 7.32192 6.48269H6.59837C6.3434 6.48269 6.13657 6.27588 6.13657 6.0209V5.29734Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                            <path
                                d="M3.24192 5.29734C3.24192 5.04252 3.44871 4.83555 3.70371 4.83555H4.42727C4.68211 4.83555 4.88906 5.04248 4.88906 5.29734V6.0209C4.88906 6.27588 4.68211 6.48269 4.42727 6.48269H3.70371C3.44875 6.48269 3.24192 6.27588 3.24192 6.0209V5.29734Z"
                                fill="#101828"
                                stroke="#101828"
                                stroke-width="0.2"
                            />
                        </svg>
                    </span>
                    Total clients
                </span>
                <BtnLight className="w-20 h-9">
                    <Link to={"/admin/client/view-all-clients"}>View All</Link>{" "}
                </BtnLight>
            </div>
            <div className="card-body mt-3.5 flex justify-center">
                <Doughnut data={data} options={options} plugins={[centerTextPlugin]} />
            </div>
        </div>

    </React.Fragment>)


}
export default TotalClients;