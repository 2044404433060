import React, { useState, useEffect, useCallback } from "react";
import Heading from "../../../../../../common/element/Heading";
import Tabs from "../../../../../../common/element/Tabs";
import Workers from "./Workers";
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import Contacts from "./Contacts";
import DocumentList from "./Documents";
import { GetProviderOverviewForClient } from "../../../../../../actions/client-portal/AllSiteApi";
import ProviderProviderOverviewInfo from "./Overview/ProviderInfo";
const SpecificContractorName = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { organisationUuid } = useParams()
    const dispatch = useDispatch()
    const { TabNav, TabList, TabContent } = Tabs;
    const [providerData, setProviderData] = useState(null)
    const [contactTypeId, setContactTypeId] = useState("");
    const permissionDataUpdate = useSelector((state) => state?.permissions?.permissionData);
    const [forceRender, setForceRender] = useState(false);
    const AllContactType = useSelector((state) => state?.AllContactTypes);
    let contactTypeUuid;

    useEffect(() => {
        if (permissionDataUpdate !== null) {
            localStorage.setItem(
                "permission_data",
                JSON.stringify(permissionDataUpdate)
            );
            setForceRender((prevForceRender) => !prevForceRender);
            if (permissionDataUpdate?.provider_view == false) {
                if (location.pathname.startsWith('/client/provider/specific-provider/') && organisationUuid) {
                    navigate('/client/dashboard');
                }
            }
        }
    }, [permissionDataUpdate]);

    useEffect(() => {
        if (AllContactType) {
            contactTypeUuid = AllContactType.find(
                (item) => item.contact_type === "provider primary"
            )?.contact_type_uuid;
            setContactTypeId(contactTypeUuid);
        }
    }, [AllContactType]);

    const ProviderOverviewForClient = useCallback(async () => {
        if (organisationUuid && (contactTypeId || contactTypeUuid)) {
            try {
                const response = await dispatch(GetProviderOverviewForClient({ organisation_uuid: organisationUuid, contact_type_uuid: (contactTypeId || contactTypeUuid) }))
                if (response.success) {
                    const arrayData = response.data;
                    setProviderData(arrayData);

                } else if (response.status === 401) {
                    // handleUnauthorizedAccess();
                } else {
                    // Handle other errors
                }
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }

        }
    }, [dispatch])

    useEffect(() => {
        ProviderOverviewForClient()
    }, [])

    return (
        <React.Fragment>
            <div className="flex items-center mb-5">
                <div className="h-16 w-16 rounded-full overflow-hidden mr-5 avtar_img_round"><img src="/assets/img/avatars/thumb-8.jpg" alt="" /></div>
                <div className="flex flex-col">
                    <Heading className="mb-2">{providerData?.trading_name ? providerData?.trading_name : "[Provider name]"}</Heading>
                    <div className="flex gap-6 items-center"> <span className="badge bg-success-50 text-success-700 py-0.5 px-2 rounded-full font-medium"><span className="badge-dot badge-inner mr-1 bg-green-700"></span>Compliant</span>
                        <span className="text-gray-900  font-medium"><span className="text-gray-400 mr-1 font-semibold">ABN:</span>{providerData?.abn_nzbn ? providerData?.abn_nzbn : "00-000-000-000"}</span>
                    </div>
                </div>
            </div>
            <Tabs defaultValue="tab1" variant="pill">
                <div className=" contractorTabsList bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5">
                    <TabList>
                        <TabNav value="tab1">Overview</TabNav>
                        <TabNav value="tab2">Documents</TabNav>
                        <TabNav value="tab3">Workers</TabNav>
                        <TabNav value="tab4">Contacts</TabNav>
                    </TabList>
                </div>

                <TabContent value="tab1">
                    <ProviderProviderOverviewInfo providerData={providerData} />

                </TabContent>
                <TabContent value="tab2">
                    <DocumentList organisation_uuid={providerData?.organisation_uuid} trading_name={providerData?.trading_name} />
                </TabContent>
                <TabContent value="tab3">
                    <Workers organisation_uuid={providerData?.organisation_uuid} trading_name={providerData?.trading_name} />
                </TabContent>
                <TabContent value="tab4">
                    <Contacts />
                </TabContent>

            </Tabs>
        </React.Fragment>
    )
}
export default SpecificContractorName