import axios from "axios";
import * as actionType from "../../constant/actionType";
export const GetAllIndividualListForInviteForClient =
  (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/provider/GetAllIndividualListForInvite`,
        params: apiData,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const UserInviteForProvider = (data) => async (dispatch) => {
  // Final CSV Data
  try {
    const response = await axios({
      method: "post",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/InviteOrgUser`,
    });
    return response.data;
  } catch (error) {}
};

export const UpdateClientUserPermForProvider = (data) => async (dispatch) => {
  // Final CSV Data
  try {
    const response = await axios({
      method: "put",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/UpdateOrgUserPerm`,
    });
    return response.data;
  } catch (error) {}
};

export const GetClientUserPermByIdForProvider = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/GetOrgUserPermById`,
      params: apiData,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const GetAllSpecificOrgUsers =
  (apiData,searchTerm) => async (dispatch) => {
    const organisation_uuid = { organisation_uuid: apiData,search:searchTerm}
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/provider/GetAllSpecificOrgUsers`,
        params: organisation_uuid,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const GetAllSpecificOrgInvites =
  (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/provider/GetAllSpecificOrgInvites`,
        params: apiData,
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const ReinviteOrgUser = (data) => async (dispatch) => {
  // Final CSV Data
  try {
    const response = await axios({
      method: "put",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/ReinviteOrgUser`,
    });
    return response.data;
  } catch (error) {}
};


export const RemoveOrgUserInvitation = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: ApiData ,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/RemoveOrgUserInvitation`,
    });
    return response.data;
  } catch (error) {}
};


export const RemoveClientUser = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/RemoveClientUser`,
    });
    return response.data;
  } catch (error) {}
};

export const UpdateSiteActiveStatus = (data) => async (dispatch) => {
  // Final CSV Data
  try {
    const response = await axios({
      method: "put",
      data: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/provider/UpdateSiteActiveStatus`,
    });
    return response.data;
  } catch (error) {}
};
