import { createSlice } from '@reduxjs/toolkit'
const closeDocumentSite = createSlice({
    name:"closeDocumentSite",
    initialState:false,
    reducers:{
      closeDocumentfuncSite(state,action){
        return action.payload;
      },
    }
  }) 
  export default closeDocumentSite.reducer;
  export const {closeDocumentfuncSite}=closeDocumentSite.actions;