import React, { useState, useEffect } from "react";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import ScrollBar from "../../../../common/element/ScrollBar";
import Input from "../../../../common/element/Input";
import Label from "../../../../common/element/Label";
import Segment from "../../../../common/element/Segment";
import Select from "../../../../common/element/Select/Select";
import Tooltip from "../../../../common/element/Tooltip";
import {
  GetHighRiskActivityList,
  GetProviderDetailsById,
  UpdateProviderOrgOtherDetails,
} from "../../../../actions/admin-provider/AllNotesEmailSettingApi";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Upload from "../../../../common/element/Upload";
import PdfFile from "../../../../assets/icons-svg/upload-pdf.svg";
import { useSelector } from "react-redux";

const WorkHealth = ({ UpdateData }) => {
  const { ProviderRegisterData } = useSelector((state) => state);
  const { RegistrationValidation } = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const [highRiskShow, setHighRiskShow] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeOptional, setCountryCodeOptional] = useState("");

  const [phoneCode, SetPhoneCode] = useState([
    { label: "+93", value: "+93" },
    { label: "+355", value: "+355" },
    { label: "+213", value: "+213" },
    { label: "+376", value: "+376" },
    { label: "+244", value: "+244" },
    { label: "+54", value: "+54" },
    { label: "+374", value: "+374" },
    { label: "+61", value: "+61" },
    { label: "+43", value: "+43" },
    { label: "+994", value: "+994" },
    { label: "+1-242", value: "+1-242" },
    { label: "+973", value: "+973" },
    { label: "+880", value: "+880" },
    { label: "+1-246", value: "+1-246" },
    { label: "+375", value: "+375" },
    { label: "+32", value: "+32" },
    { label: "+501", value: "+501" },
    { label: "+229", value: "+229" },
    { label: "+975", value: "+975" },
    { label: "+591", value: "+591" },
    { label: "+387", value: "+387" },
    { label: "+267", value: "+267" },
    { label: "+55", value: "+55" },
    { label: "+673", value: "+673" },
    { label: "+359", value: "+359" },
    { label: "+226", value: "+226" },
    { label: "+257", value: "+257" },
    { label: "+855", value: "+855" },
    { label: "+237", value: "+237" },
    { label: "+1", value: "+1" },
    { label: "+238", value: "+238" },
    { label: "+236", value: "+236" },
    { label: "+235", value: "+235" },
    { label: "+56", value: "+56" },
    { label: "+86", value: "+86" },
    { label: "+57", value: "+57" },
    { label: "+269", value: "+269" },
    { label: "+242", value: "+242" },
    { label: "+243", value: "+243" },
    { label: "+506", value: "+506" },
    { label: "+385", value: "+385" },
    { label: "+53", value: "+53" },
    { label: "+357", value: "+357" },
    { label: "+420", value: "+420" },
    { label: "+45", value: "+45" },
    { label: "+253", value: "+253" },
    { label: "+1-767", value: "+1-767" },
    { label: "+1-809", value: "+1-809" },
    { label: "+593", value: "+593" },
    { label: "+20", value: "+20" },
    { label: "+503", value: "+503" },
    { label: "+240", value: "+240" },
    { label: "+291", value: "+291" },
    { label: "+372", value: "+372" },
    { label: "+268", value: "+268" },
    { label: "+251", value: "+251" },
    { label: "+679", value: "+679" },
    { label: "+358", value: "+358" },
    { label: "+33", value: "+33" },
    { label: "+241", value: "+241" },
    { label: "+220", value: "+220" },
    { label: "+995", value: "+995" },
    { label: "+49", value: "+49" },
    { label: "+233", value: "+233" },
    { label: "+30", value: "+30" },
    { label: "+1-473", value: "+1-473" },
    { label: "+502", value: "+502" },
    { label: "+224", value: "+224" },
    { label: "+245", value: "+245" },
    { label: "+592", value: "+592" },
    { label: "+509", value: "+509" },
    { label: "+504", value: "+504" },
    { label: "+36", value: "+36" },
    { label: "+354", value: "+354" },
    { label: "+91", value: "+91" },
    { label: "+62", value: "+62" },
    { label: "+98", value: "+98" },
    { label: "+964", value: "+964" },
    { label: "+353", value: "+353" },
    { label: "+972", value: "+972" },
    { label: "+39", value: "+39" },
    { label: "+1-876", value: "+1-876" },
    { label: "+81", value: "+81" },
    { label: "+962", value: "+962" },
    { label: "+7", value: "+7" },
    { label: "+254", value: "+254" },
    { label: "+686", value: "+686" },
    { label: "+965", value: "+965" },
    { label: "+996", value: "+996" },
    { label: "+856", value: "+856" },
    { label: "+371", value: "+371" },
    { label: "+961", value: "+961" },
    { label: "+266", value: "+266" },
    { label: "+231", value: "+231" },
    { label: "+218", value: "+218" },
    { label: "+423", value: "+423" },
    { label: "+370", value: "+370" },
    { label: "+352", value: "+352" },
    { label: "+261", value: "+261" },
    { label: "+265", value: "+265" },
    { label: "+60", value: "+60" },
    { label: "+960", value: "+960" },
    { label: "+223", value: "+223" },
    { label: "+356", value: "+356" },
    { label: "+692", value: "+692" },
    { label: "+222", value: "+222" },
    { label: "+230", value: "+230" },
    { label: "+52", value: "+52" },
    { label: "+691", value: "+691" },
    { label: "+373", value: "+373" },
    { label: "+377", value: "+377" },
    { label: "+976", value: "+976" },
    { label: "+382", value: "+382" },
    { label: "+212", value: "+212" },
    { label: "+258", value: "+258" },
    { label: "+95", value: "+95" },
    { label: "+264", value: "+264" },
    { label: "+674", value: "+674" },
    { label: "+977", value: "+977" },
    { label: "+31", value: "+31" },
    { label: "+64", value: "+64" },
    { label: "+505", value: "+505" },
    { label: "+227", value: "+227" },
    { label: "+234", value: "+234" },
  ]);

  const TitleOptions = [
    { value: "mr.", label: "Mr." },
    { value: "miss", label: "Miss" },
    { value: "mrs", label: "Mrs." },
  ];
  const [attachment, setAttachment] = useState();
  const [riskData, setRiskData] = useState();
  const [formData, setFormData] = useState({
    high_risk_activity: false,
    high_risk_master_setting_uuid: "",
    whs_system_available: "",
    whs_system_certified: "",
  });
  const [whs_contact_info, setWHSContact] = useState({
    title: "mr.",
    first_name: "",
    last_name: "",
    phone: "",
    phone_optional: "",
    email: "",
  });

  const highRiskShowHandle = (val) => {
    setHighRiskShow(val[0]);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        ["high_risk_activity"]: val[0],
      };
    });
    if (val[0] == "false") {
      setFormData((prevalue) => {
        return {
          ...prevalue,
          ["high_risk_master_setting_uuid"]: "",
        };
      });
    }
  };

  const whsSystemHandle = (val) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      whs_system_available: val[0],
    }));
  };

  const whsCertifiedHandle = (val) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      whs_system_certified: val[0],
    }));
  };

  useEffect(() => {
    dispatch(GetHighRiskActivityList()).then((response) => {
      if (response.success) {
        const arrayData = response?.data;
        const industryData = [];
        for (let key in arrayData) {
          industryData.push({
            label: arrayData[key]?.meta_value_one,
            value: arrayData[key]?.master_setting_uuid,
            // name:"ind_master_setting_uuids",
          });
        }
        setRiskData(industryData);
      } else if (response.status === 401) {
        handleUnauthorizedAccess();
      }
    });
  }, []);

  const inputchangehandler = (e, field) => {
    setWHSContact({ ...whs_contact_info, [field]: e });
  };

  const handleChange = (selectedOption, identifier) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      high_risk_master_setting_uuid: selectedOption.value,
    }));
  };

  const handleTitleChange = (selectedOption) => {
    setWHSContact((prevFormData) => ({
      ...prevFormData,
      title: selectedOption?.value,
    }));
  };

  // Handle file change in the attachment input
  // const handleFileChange = (files) => {
  //   setAttachment(files);
  // };

  // const handleFileRemove = (files) => {
  //   // Handle the file remove logic here
  //   setAttachment(files);
  // };

  // const submitWHS = () => {
  //   const payload = {
  //     provider_org_detail_uuid: formData.provider_org_detail_uuid,
  //     whs_contact_info: {
  //       title: whs_contact_info?.title,
  //       first_name: whs_contact_info.first_name,
  //       last_name: whs_contact_info.last_name,
  //       phone: whs_contact_info.phone,
  //       phone_optional: whs_contact_info.phone_optional,
  //       email: whs_contact_info.email,
  //       phone_code: countryCode,
  //       phone_code_optional: countryCodeOptional,
  //     },
  //     high_risk_master_setting_uuid: formData.high_risk_master_setting_uuid,
  //     high_risk_activity: highRiskShow,
  //     whs_system_available: formData.whs_system_available,
  //     whs_system_certified: formData.whs_system_certified,
  //     msa_doc: attachment,
  //   };
  // };

  useEffect(() => {
    UpdateData(whs_contact_info, formData);
  }, [whs_contact_info, formData]);

  useEffect(() => {
    if (ProviderRegisterData?.data) {
      let address_data = ProviderRegisterData?.data?.provider_regist_data;
      setWHSContact(address_data?.whs_contact_info);
      if (address_data.high_risk_activity) {
        setHighRiskShow(address_data.high_risk_activity);
      }

      setFormData({
        high_risk_activity: address_data.high_risk_activity,
        high_risk_master_setting_uuid:
          address_data?.high_risk_master_setting_uuid,
        whs_system_available: address_data?.whs_system_available,
        whs_system_certified: address_data?.whs_system_certified,
      });
      // SetContact(
      //   ProviderRegisterData?.data?.data?.provider_regist_data?.individual_data
      // );
      // setBusinessType(address_data?.org_type)
      // setFormData({
      //   trading_name: address_data?.trading_name,
      //   provider_org_detail_uuid: "",
      //   abn_nzbn: address_data?.abn_nzbn,
      //   // acn: "",
      //   no_of_emp: address_data?.no_of_emp,
      //   ind_master_setting_uuid: address_data?.industry_master_setting_uuid,
      //   businessAddres1: address_data?.address_data[0]?.address_one,
      //   businessAddres2: address_data?.address_data[0]?.address_two,
      //   businessAddresCity: address_data?.address_data[0]?.suburb,
      //   businessAddresPostCode: address_data?.address_data[0]?.zipcode,
      //   businessAddresState: address_data?.address_data[0]?.state_id,
      //   businessAddresCountry: address_data?.address_data[0]?.country_id,
      //   businessUuid: "",

      //   mailingAddres1: address_data?.address_data[1]?.address_one,
      //   mailingAddres2: address_data?.address_data[1]?.address_two,
      //   mailingAddresCity: address_data?.address_data[1]?.suburb,
      //   mailingAddresPostCode: address_data?.address_data[1]?.zipcode,
      //   mailingAddresState: address_data?.address_data[1]?.state_id,
      //   mailingAddresCountry: address_data?.address_data[1]?.country_id,
      //   mailingUuid: "",
      // });
      // if (
      //   ProviderRegisterData?.data?.data?.provider_regist_data?.individual_data
      //     .length == 2
      // ) {
      //   setVisibleHide(true);
      //   AccBillingcontact("Different");
      //   setValue("Different");
      // }
    }
  }, [ProviderRegisterData]);

  useEffect(() => {
    UpdateData(whs_contact_info, formData);
  }, [whs_contact_info, formData]);

  return (
    <React.Fragment>
      <div className="h-500 pr-1">
        <ScrollBar>
          <div className="pr-4">
            <div className="flex justify-between flex-col">
              <h5 className="text-blue-800 font-semibold text-2xl">
                Work Health and Safety{" "}
              </h5>
              <h4 className="font-bold text-gray-700 text-base mb-5 mt-7">
                Do you perform high-risk activities?
              </h4>
            </div>
            <div className="items-start  mb-5">
              <div className=" items-center validation-filter ">
                <Segment
                  value={highRiskShow}
                  onChange={(val) => highRiskShowHandle(val)}
                  // onClick={preventDefault}
                >
                  <Segment.Item value="true">
                    <span>Yes</span>
                  </Segment.Item>
                  <Segment.Item value="false">
                    <span>No</span>
                  </Segment.Item>
                </Segment>
              </div>
            </div>
            {highRiskShow === "true" && (
              <div className="items-start  mb-5">
                <h4 className="font-bold text-gray-700 text-base mb-3 mt-3">
                  High risk activity offered
                </h4>
                <div className="items-center flex-1 flex-wrap">
                  <Select
                    isSearchable={false}
                    placeholder="High Risk Activity"
                    options={riskData}
                    className="select-option w-full h-9"
                    value={
                      riskData &&
                      riskData.filter(
                        (options) =>
                          options.value ===
                          formData.high_risk_master_setting_uuid
                      )
                    }
                    onChange={(e) =>
                      handleChange(e, "high_risk_master_setting_uuid")
                    }
                  />
                </div>
                { RegistrationValidation && formData.high_risk_master_setting_uuid == "" && (
                  <p className="text-xs text-red-600 mb-4 mt-0">
                    {"This field is required!"}
                  </p>
                )}
              </div>
            )}
            <div className="items-start  mb-5">
              <h4 className="font-bold text-gray-700 text-base mb-3 mt-3">
                WHS Contact (Optional)
              </h4>
              <div className="Pflex items-center  flex-1 flex-wrap">
                <div className="flex w-full gap-2">
                  <Select
                    isSearchable={false}
                    placeholder="Title*"
                    options={TitleOptions}
                    className="select-option w-2/12 mb-5"
                    value={
                      TitleOptions &&
                      TitleOptions.filter(
                        (options) => options.value === whs_contact_info.title
                      )
                    }
                    onChange={(e) => handleTitleChange(e, "title")}
                  />
                  <Input
                    type="text"
                    name="First Name"
                    placeholder="First Name*"
                    className="w-5/12 h-9 mb-5"
                    value={whs_contact_info.first_name}
                    inputchangehandler={(e) =>
                      inputchangehandler(e, "first_name")
                    }
                  />
                  <Input
                    type="text"
                    name="Last Name"
                    placeholder="Last Name*"
                    className="w-5/12 h-9 mb-5"
                    value={whs_contact_info.last_name}
                    inputchangehandler={(e) =>
                      inputchangehandler(e, "last_name")
                    }
                  />
                </div>
                <div className="flex w-full gap-2">
                  <Select
                    isSearchable={false}
                    placeholder="Title*"
                    options={phoneCode}
                    className="select-option w-2/5 mb-5"
                    value={
                      phoneCode &&
                      phoneCode.filter(
                        (options) => options.value === countryCode
                      )
                    }
                    onChange={(e) => setCountryCode(e.value)}
                  />
                  <Input
                    type="number"
                    name="Work"
                    placeholder="Work*"
                    className="w-5/12  h-9 mb-5 mr-1"
                    value={whs_contact_info.phone}
                    inputchangehandler={(e) => inputchangehandler(e, "phone")}
                  />
                  {/* <Input
                    className="w-1/12  h-9 mb-5 ml-1"
                    value={`+${countryCode}`}
                  /> */}
                  <Select
                    isSearchable={false}
                    placeholder="Title*"
                    options={phoneCode}
                    className="select-option w-2/5 mb-5"
                    value={
                      phoneCode &&
                      phoneCode.filter(
                        (options) => options.value === countryCodeOptional
                      )
                    }
                    onChange={(e) => setCountryCodeOptional(e.value)}
                  />

                  <Input
                    type="text"
                    name="Mobile"
                    placeholder="Mobile(Optional)"
                    className="w-5/12  h-9 mb-5"
                    value={whs_contact_info.phone_optional}
                    inputchangehandler={(e) =>
                      inputchangehandler(e, "phone_optional")
                    }
                  />
                </div>
                <div className="flex w-full h-9">
                  <Input
                    type="text"
                    name="Email"
                    placeholder="Email"
                    className="w-6/12 h-9 mb-5"
                    value={whs_contact_info.email}
                    inputchangehandler={(e) => inputchangehandler(e, "email")}
                  />
                </div>
              </div>
            </div>
            <div className="items-start  mb-5">
              <h4 className="font-bold text-gray-700 text-base mb-5 mt-7">
                Do you have a WHS Management System?
              </h4>
              <div className="flex items-center  validation-filter mt-3">
                <Segment
                  value={formData.whs_system_available}
                  onChange={(val) => whsSystemHandle(val)}
                >
                  <Segment.Item value="true">
                    <span>Yes</span>
                  </Segment.Item>
                  <Segment.Item value="false">
                    <span>No</span>
                  </Segment.Item>
                </Segment>
              </div>
            </div>
            <div className="items-start  mb-4">
              <h4 className="font-bold text-gray-700 text-base mb-5 mt-7">
                Is your WHS Management System externally certified?
              </h4>
              <div className="flex items-center validation-filter mt-3">
                <Segment
                  value={formData.whs_system_certified}
                  onChange={(val) => whsCertifiedHandle(val)}
                >
                  <Segment.Item value="true">
                    <span>Yes</span>
                  </Segment.Item>
                  <Segment.Item value="false">
                    <span>No</span>
                  </Segment.Item>
                </Segment>
              </div>
            </div>
          </div>
        </ScrollBar>
      </div>
    </React.Fragment>
  );
};
export default WorkHealth;
