import React, { useEffect, useState, useCallback } from "react";
import Tabs from "../../../../../../common/element/Tabs";
import WorkerName from "./WorkerName";
import Documents from "../SpecificWorkerTabs/Documents";
import Clients from "../SpecificWorkerTabs/Inductions";
import Workers from "../SpecificWorkerTabs/SiteLog";
import { GetWorkerOverviewDetailsById } from "../../../../../../actions/admin-worker/GetWorkerOverviewDetailsById";
import ClientsAssigned from "../SpecificWorkerTabs/ClientsAssigned";
import Overview from "../SpecificWorkerTabs/Overview";
import { useDispatch ,useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import { redirectSpecWorker } from "../../../../../../redux/classes/admin-worker/RedirectWorkerSlice";
const { TabNav, TabList, TabContent } = Tabs;
const SpecificWorker = () => {
  const { specificWorker } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const {workerRedirectSpecificData} = useSelector((state) => state)
  const [workerSpecficData, setWorkerSpecficData] = useState(null);
  const [hasFetched, setHasFetched] = useState(false)
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "View All",
          url: "/provider/worker/view-all-worker",
          home: "/provider/dashboard"
        },
        
        {
          label: `${specificWorker?.worker_individual?.first_name || ""} ${
            specificWorker?.worker_individual?.last_name || ""
          }`,
          label: `${workerSpecficData?.first_name || ''} ${workerSpecficData?.last_name || ''}`,
          url: `/provider/worker/view-all-worker/specific-worker/${id}`,
        },
      ])
    );
  }, [workerSpecficData]);
  const GetSpecWorkerOverviewDetailsById = useCallback(() => {
    if (workerRedirectSpecificData?.individual_uuid && !hasFetched) {
      dispatch(
        GetWorkerOverviewDetailsById({ individual_uuid: workerRedirectSpecificData?.individual_uuid })
      ).then((response) => {
        if (response?.success) {
          setWorkerSpecficData(response?.data);
          dispatch(redirectSpecWorker(response?.data));
          setHasFetched(true); // Set flag to true after data is fetched
        }
      });
    }   
  }, [dispatch, workerRedirectSpecificData, hasFetched]);
  
  useEffect(() => {
    GetSpecWorkerOverviewDetailsById();
  }, []);
  return (
    <React.Fragment>
      <WorkerName workerSpecficData={specificWorker}/>
      <Tabs defaultValue="tab1" variant="pill" className="contractorTabsList">
        <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5">
          <TabList>
            <TabNav value="tab1">Overview</TabNav>
            <TabNav value="tab2">Documents</TabNav>
            <TabNav value="tab6">Clients assigned</TabNav>
            <TabNav value="tab3">Inductions</TabNav>
            <TabNav value="tab4">Site Log</TabNav>
          </TabList>
        </div>
        <div className="">
          <TabContent value="tab1">
            <Overview workerSpecficData={workerSpecficData}/>
          </TabContent>
          <TabContent value="tab2">
            <Documents />
          </TabContent>
          <TabContent value="tab3">
            <Clients />
          </TabContent>
          <TabContent value="tab4">
            <Workers />
          </TabContent>
          <TabContent value="tab6" >
            <ClientsAssigned />
          </TabContent>
        </div>
      </Tabs>
    </React.Fragment>
  );
};

export default SpecificWorker;
