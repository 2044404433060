
import React, { useCallback, useEffect, useState } from "react";
import ContactsList from "./ContractorsList";
import Select from "../../../../common/element/Select/Select";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import { debounce } from "../../../../common/snippet/Debouncing";
import ClientInput from "../../../../common/element/Input/clientInput";
import { Pagination } from "../../../../common/pagination";
import ReactPaginate from "react-paginate";
import { GetAllProvidersOfWorker } from "../../../../actions/admin-worker/GetAllContractorsOfSpecificWorker";
import Heading from "../../../../common/element/Heading";
const WorkerContractors = () => {
  let individual_uuid_loc;
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (loginData) {
    individual_uuid_loc = loginData?.userName?.individual_uuid
  }
  useEffect(() => {
    dispatch(setBreadcrumbs([{ label: "Providers", url: "/worker/Providers" }]));
  }, []);
  const GetFunction = useSelector((state) => state);
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(1);
  const [countData, setCountData] = useState(0);
  const [apiCalls, setApiCalls] = useState(true);
  const AllContactType = useSelector((state) => state?.AllContactTypes);
  const [providerAllData, setProviderAllData] = useState([]);
  const [getAllinvitedData, SetgetAllinvitedData] = useState({
    contact_type_uuid: AllContactType?.filter((item) => item?.contact_type == "provider primary")?.[0]?.contact_type_uuid,
    individual_uuid: individual_uuid_loc, //change this individual id this is static
    // individual_uuid: "e7c8c24a-3129-4610-85fa-94c57dfb8b4d", //change this individual id this is static
    page: 1,
    limit: 10,
    sort: "trading_name",
    order: "desc",
    search: "",
  });

  const GetAllProvidersOfWorkerData = useCallback(() => {
    dispatch(GetAllProvidersOfWorker(getAllinvitedData))
      .then((response) => {
        if (response?.success) {
          const arrayData = response?.data;
          setCountData(response?.data?.count);
          setProviderAllData(arrayData?.rows)
          setTotalPages(Math.ceil(countData / getAllinvitedData.limit));
        } else {
          // Handle unsuccessful response or any other response status
          console.log("Failed to fetch data or handle response properly.");
        }
      })
      .catch((error) => {
        console.error("Error fetching providers data:", error);
      });
  }, [dispatch, getAllinvitedData]);


  useEffect(() => {
    GetAllProvidersOfWorkerData()
  }, [getAllinvitedData])

  const handleSort = (column) => {
    if (column === getAllinvitedData.sort) {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getAllinvitedData.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetgetAllinvitedData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
    }
  };
  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false)
    }
    else {

      SetgetAllinvitedData(prevState => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
        page: 1
      }));
      setTotalPages(Math.ceil(countData / newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10));
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false)
    }
    else {

      SetgetAllinvitedData(prevState => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }

  };
  const handlePageChange = ({ selected }) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(
      Math.ceil(
        countData / getAllinvitedData.limit
      )
    );
  };

  // Search Data
  const handleSearch = (e) => {
    SetgetAllinvitedData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const inputchangehandler = useCallback(debounce(handleSearch), []);
  const getPageNumber = () => {
    setTotalPages(
      Math.ceil(
        countData / getAllinvitedData.limit
      )
    );
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  return (
    <React.Fragment>
      <Heading>Providers</Heading>
      <div className="flex justify-between mb-5 mt-7">
          <div className="w-80 h-10">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img
                  src="/assets/icons-svg/search-lg.svg"
                  alt=""
                  className="h-5"
                />
              </div>
              <ClientInput
                type="text"
                className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                inputchangehandler={inputchangehandler}
                name={"search"}
              />
            </div>
        
        </div>
      </div>
      <ContactsList
        providerAllData={providerAllData}
        handleSort={handleSort}
        getAllinvitedData={getAllinvitedData} />
      <div className="flex justify-between mt-3"
        style={{ alignItems: "Right", listStyle: "none", padding: 0 }} >
        <Pagination
          totalCount={countData}
          pageNumber={getAllinvitedData?.page}
          displayPage={providerAllData?.length}
          UpdatePageLimit={updatePageLimit}
          UpdatePage={updatePage}
        // UpdatePageLimit={SetgetAllinvitedData}
        // allfilters={getAllinvitedData}
        />
        {countData > 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
            renderOnZeroPageCount={null}
            forcePage={getAllinvitedData?.page - 1}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default WorkerContractors;

