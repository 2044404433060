import React, { useState, useEffect, useCallback, useRef } from "react";
// import Segment from "../../../../common/element/Segment";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../common/element/Select/Select";
import InductionsList from "./InductionsList";
import Dialog from "../../../../common/element/Dialog/Dialog";
import Table from "../../../../common/element/Table";
import ReactPaginate from "react-paginate";
import Tabs from "../../../../common/element/Tabs";
import Tag from "../../../../common/element/Tag";
import Heading from "../../../../common/element/Heading";
import useUnauthorizedHandler from "../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../common/pagination";
import { GetAllInductionsData } from "../../../../actions/client-portal/GetAllInductionOfSpecificCompanyApi";
import { debounce } from "../../../../common/snippet/Debouncing";
import Segment from "../../../../common/element/Segment";
import searchIcon from "../../../../assets/icons-svg/search-lg.svg";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "../../../../common/element/Tooltip";
import Button from "../../../../common/element/Buttons";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import Scrollbar from "../../../../common/element/ScrollBar";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
const Inductions = (props) => {
  const dispatch = useDispatch()
  const { id } = useParams();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { workerRedirectSpecificData } = useSelector((state) => state)
  const [singleSegmentValue, setSingleSegmentValue] = useState(["view all"]);
  const [recipientType, setRecipientType] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [veiwInduction, setveiwInduction] = useState(false);
  const [AllData, setAllData] = useState([]);
  const [apiCalls, setApiCalls] = useState(true);
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [dialogChoose, setChoose] = useState(false);
  const { TabNav, TabList, TabContent } = Tabs;
  const { Tr, Th, Td, THead, TBody } = Table;

  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(setBreadcrumbs([{ label: "Inductions", url: "/worker/inductions"}]));
    }, []);
  const searchOptions = [
    { value: "Insurance", label: "Insurance" },
    { value: "Other", label: "Other" },
  ];
  const openChoose = () => {
    setChoose(true);
  };
  const onChooseClose = (e) => {
    setChoose(false);
  };

  const openDialog = () => {
    setIsOpen(true);
    setChoose(false);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
  };

  const openAddDialog = () => {
    setAddOpen(true);
    setIsOpen(false);
  };
  const onAddDialogClose = (e) => {
    setAddOpen(false);
    // props.getOnDialogClose(onAddDialogClose);
  };

  const onSingleSelectionSegmentChange = useCallback(
    (val) => {
      setSingleSegmentValue(val);
      if (val[0] === "view all") {
        setRecipientType(val[0]);
        SetFilterData((prevalue) => {
          return {
            ...prevalue,
            ["page"]: 1,
          };
        });
        setTotalPages(Math.ceil(AllData?.count / getFilterData?.limit));
      } else {
        setRecipientType(val[0]);
        SetFilterData((prevalue) => {
          return {
            ...prevalue,
            ["page"]: 1,
          };
        });
        setTotalPages(Math.ceil(AllData?.count / getFilterData?.limit));
      }
    },
    [setSingleSegmentValue]
  );
  const handleSeachChange = (e) => {
    SetFilterData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });

  };
  const inputchangeSearch = useCallback(debounce(handleSeachChange), []);
  const [getFilterData, SetFilterData] = useState({
    function_assignment_uuid: "fd91be60-d246-413f-825e-aba18dbb3f6a",
    page: 1,
    limit: 10,
    sort: "created_date",
    order: "desc",
    search: "",
    recipientType: recipientType ? recipientType : "view all",
  });
  // All ReduxFunction
  const fetchInductionData = useCallback(() => {
    dispatch(GetAllInductionsData(getFilterData))
      .then((response) => {
        if (response?.success) {
          setAllData(response?.data);
          setTotalPages(Math.ceil(response?.data?.count / getFilterData?.limit));
          // dispatch(refetchAllInductionsData(false));
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [dispatch, getFilterData, recipientType]);

  useEffect(() => {
    fetchInductionData();
  }, [getFilterData, recipientType]);

  const handlePageChange = ({ selected }) => {
    SetFilterData((prevalue) => {
      return {
        ...prevalue,
        ["page"]: selected + 1,
      };
    });
    setTotalPages(Math.ceil(AllData?.count / getFilterData?.limit));
  };
  const getPageNumber = () => {
    setTotalPages(Math.ceil(AllData?.count / getFilterData?.limit));
  };
  useEffect(() => {
    getPageNumber();
  }, [getPageNumber]);

  const handleSort = (column) => {
    if (column === getFilterData?.sort) {
      SetFilterData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getFilterData?.order === "asc" ? "desc" : "asc",
        };
      });
    } else {
      SetFilterData((prevalue) => {
        return {
          ...prevalue,
          ["sort"]: column,
        };
      });
      SetFilterData((prevalue) => {
        return {
          ...prevalue,
          ["order"]: getFilterData?.order === "asc" ? "desc" : "asc",
        };
      });
    }
  };


  const updatePageLimit = (newLimit) => {
    if (apiCalls == true) {
      setApiCalls(false)
    }
    else {

      SetFilterData(prevState => ({
        ...prevState,
        limit: newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10,
        page: 1
      }));
      setTotalPages(Math.ceil(AllData?.count / newLimit?.limit ? newLimit?.limit : newLimit ? newLimit : 10));
    }
  };

  const updatePage = (newPage) => {
    if (apiCalls == true) {
      setApiCalls(false)
    }
    else {

      SetFilterData(prevState => ({
        ...prevState,
        page: newPage?.page ? newPage?.page : newPage ? newPage : 1,
      }));
    }

  };
  return (
    <React.Fragment>
      <Heading className="mb-1">Inductions</Heading>
      <p className="text-gray-600 font-medium mb-7">
        Lorem ipsum dolor sit amet consectetur. Sem arcu mi in gravida tincidunt
        eget sit. Dui felis aliquam metus morbi morbi.
      </p>
      <div className="flex justify-between mb-5 flex-wrap gap-2 px-2">
        <div className="flex gap-2 flex-wrap">
          <Segment
            className="docType-btn"
            value={singleSegmentValue}
            onChange={(val) => onSingleSelectionSegmentChange(val)}
          >
            <Segment.Item value="view all">View All</Segment.Item>
            <Segment.Item value="awaiting action">Awaiting action</Segment.Item>
            <Segment.Item value="pending">Pending</Segment.Item>
            <Segment.Item value="completed">Completed</Segment.Item>
          </Segment>

          <div className="w-80 h-9">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img src={searchIcon} alt="" className="h-5" />
              </div>
              <input
                type="text"
                name="hs-table-search"
                className="input  pl-11 w-full  input-shadow h-9 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                onChange={(e) => inputchangeSearch(e)}
              />
            </div>
          </div>
          <BtnLight
            // onClick={() => setOpen(!open)} 
            className="w-9 h-9 flex items-center justify-center">
            <svg width="21" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.712 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0zm-6 8h12m-12 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </BtnLight>
        </div>
      </div>
      <InductionsList
        fetchInductionData={fetchInductionData}
        veiwInduction={veiwInduction}
        data={AllData}
        handleSort={handleSort}
        getFilterData={getFilterData}
      />

      {/* <div className="flex justify-between mt-3"
        style={{ alignItems: "Right", listStyle: "none", padding: 0 }}>
        <Pagination
          totalCount={AllData?.count}
          pageNumber={getFilterData?.page}
          displayPage={AllData?.rows?.length ? AllData?.rows?.length : 0}
          UpdatePageLimit={updatePageLimit}
          UpdatePage={updatePage}
        />
        {AllData?.rows?.length > 0 && (
          <ReactPaginate
            className="flex justify-content-end p-2 align-items-center pagination-wrap"
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLinkClassName={"prev-link"}
            nextLinkClassName={"page-link"}
            breakLinkClassName={"prev-link"}
            previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
            nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
            pageLinkClassName={"px-4 rounded-md"}
            activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
            disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
            renderOnZeroPageCount={null}
            forcePage={getFilterData?.page - 1}
          />
        )}
      </div> */}
    </React.Fragment>
  );
};
export default Inductions;
