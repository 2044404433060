import axios from "axios";
import * as actionType from "../../constant/actionType";

export const CreateSiteInduction = (APIData) => async (dispatch) => {
  try {
    // dispatch(fetchData());
    const response = await axios({
      method: "post",
      data: APIData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/CreateSiteInduction`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};



export const DashboardAllClientsCompanyInd = () => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DashboardAllClientsCompanyInd`,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return { success: false, error: error.message };
  }
};
export const DashboardClientCompanyInductions = (apiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      params: { function_assignment_uuid: apiData },
      url: `${actionType.environment_url}/api/admin/client/DashboardClientCompanyInductions`,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return { success: false, error: error.message };
  }
};

export const DashboardGetAllClientsStatusChart = (apiData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DashboardGetAllClientsStatusChart`,
      params: apiData,
    });
    return response.data
    //   dispatch(fetchDataSuccess(response.data));
  } catch (error) {
    return error.message
    //   dispatch(fetchDataError(error.message));
  }
};


export const CreateSiteInductionModule = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === "site_induction_file ") {
        formdata.append("site_induction_file ", ApiData.site_induction_file);
      } else {
        if (key === "module_ques_data" || key === "pdf_page_range") {
          formdata.append(key, JSON.stringify(ApiData[key]));
        } else {
          if (key === "content_info_type") {
            if (ApiData.content_info_type !== "") {
              formdata.append(key, ApiData[key]);
            }
          } else {
            formdata.append(key, ApiData[key]);
          }
        }
      }
    }

    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/CreateSiteInductionModule`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const TwoFactorAuth = (APIData) => async (dispatch) => {
  try {
    // dispatch(fetchData());
    const response = await axios({
      method: "patch",
      data: APIData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/TwoFactorAuth`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
export const GetAllInductionsOfSpecificSite =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/GetAllInductionsOfSpecificSite`,
        params: getAllData,
      });
      return response.data;
    } catch (error) { }
  };

export const GetIndividualSiteIndData = (getData) => async (dispatch) => {
  /*** API Call Action ***/
  try {
    // dispatch(fetchData());
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetSiteInductionModuleById`,
      params: { module_uuid: getData.module_uuid },
    });
    // dispatch(fetchDataSuccess(response.data));
    return response.data;
  } catch (error) {
    return error.message;
    // dispatch(fetchDataError(error.message));
  }
};

export const GetSiteInductionAndModulesbyIdForAdmin =
  (getAllData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "get",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/GetSiteInductionAndModulesbyId`,
        params: { site_induction_uuid: getAllData },
      });
      return response.data;
    } catch (error) {
      return error.message;
    }
  };

export const GetSiteInductionModuleById = (getData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/GetSiteInductionModuleById`,
      params: { module_uuid: getData.module_uuid },
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const UpdateSiteInductionData = (ApiData) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in ApiData) {
      if (
        key == "SiteIndCompDocIds" ||
        key == "SiteIndIndustryIds" ||
        key == "doctType_uuid_data" ||
        key == "master_setting_uuid_data"
      ) {
        // For other properties, append the value directly
        formdata.append(key, JSON.stringify(ApiData[key]));
      } else {
        //   // For other properties, append the value directly
        formdata.append(key, ApiData[key]);
      }
    }
    const response = await axios({
      method: "put",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateSiteInduction`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
// export const UpdateSiteInductionModuleForAdmin =
//   (ApiData) => async (dispatch) => {
//     try {
//       // dispatch(fetchData());
//       let formdata = new FormData();
//       for (let key in ApiData) {
//         if (key === "site_induction_file") {
//           formdata.append("site_induction_file", ApiData.site_induction_file);
//         } else {
//           if (key === "module_ques_data" || key === "pdf_page_range") {
//             // Assuming you want to stringify the array as JSON
//             formdata.append(key, JSON.stringify(ApiData[key]));
//           } else {
//             // For other properties, append the value directly
//             if (key === "content_info_type") {
//               if (
//                 ApiData.content_info_type !== "" &&
//                 ApiData.content_info_type !== null
//               ) {
//                 formdata.append(key, ApiData[key]);
//               }
//             } else {
//               formdata.append(key, ApiData[key]);
//             }
//           }
//         }
//       }

//       const response = await axios({
//         method: "put",
//         data: formdata,
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
//         },
//         url: `${actionType.environment_url}/api/admin/client/UpdateSiteInductionModule`,
//       });
//       // dispatch(fetchDataSuccess(response.data));
//       return response.data;
//     } catch (error) {
//       return error.message;
//       // dispatch(fetchDataError(error.message));
//     }
//   };
export const UpdateSiteInductionModuleForAdmin = (ApiData) => async (dispatch) => {
  try {
    // dispatch(fetchData());
    let formdata = new FormData();
    for (let key in ApiData) {
      if (key === 'site_induction_file') {
        formdata.append('site_induction_file', ApiData.site_induction_file);
      } else {
        if (key === 'module_ques_data' || key === "pdf_page_range") {
          // Assuming you want to stringify the array as JSON
          formdata.append(key, JSON.stringify(ApiData[key]));
        }
        else {
          // For other properties, append the value directly
          if (key === "content_info_type") {
            if (ApiData.content_info_type !== "" && ApiData.content_info_type !== null) {
              formdata.append(key, ApiData[key]);
            }
          } else {
            formdata.append(key, ApiData[key]);
          }
        }
      }
    }

    const response = await axios({
      method: 'put',
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/UpdateSiteInductionModule`,
    });
    // dispatch(fetchDataSuccess(response.data));
    return response.data
  } catch (error) {
    return error.message
    // dispatch(fetchDataError(error.message));
  }
}
export const UpdateSiteInductionStatusForAdmin =
  (ApiData) => async (dispatch) => {
    try {
      const response = await axios({
        method: "put",
        data: ApiData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
        },
        url: `${actionType.environment_url}/api/admin/client/UpdateSiteInductionStatus`,
      });
      // dispatch(fetchDataSuccess(response.data));
      return response.data;
    } catch (error) {
      return error.message;
      // dispatch(fetchDataError(error.message));
    }
  };


// export const UpdateSiteInductionDataForClient = (ApiData) => async (dispatch) => {
//       try {
//         let formdata = new FormData();
//         for (let key in ApiData) { 
//           if (key == 'SiteIndDocTypesIds' || 
//               key == 'SiteIndIndustryIds' ||
//               key == 'document_type_uuid_data' ||
//               key == 'master_setting_uuid_data') {
//             // For other properties, append the value directly
//             formdata.append(key, JSON.stringify(ApiData[key]));
//           }
//             else {
//             //   // For other properties, append the value directly
//                 formdata.append(key, ApiData[key]);
//             }
//         }
//         const response = await axios({
//           method: 'put',
//           data: formdata,
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
//           },
//           url: `${actionType.environment_url}/api/admin/client/UpdateSiteInduction`,
//         });
//         return response.data
//       } catch (error) {
//         return error.message
//       }
//     }



// export const GetSiteInductionAndModulesbyId = (getAllData) => async (dispatch) => {
// try {
//   const response = await axios({
//     method: 'get',
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
//     },
//     url: `${actionType.environment_url}/api/admin/client/GetSiteInductionAndModulesbyId`,
//     params: {site_induction_uuid:getAllData}
//   });
//   return response.data

// } catch (error) {
//   return error.message
// }
// };

// export const ExportInductionModulePdfRange =
// (getData) => async (dispatch) => {
//   try {
//     const response = await axios({
//       method: "put",
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
//       },
//       url: `${actionType.environment_url}/api/admin/client/ExportInductionModulePdfRange`,
//       params: { module_uuid: getData.module_uuid },
//     });
//     return response.data;
//   } catch (error) {
//     return error.message;
//   }
// };

export const ExportInductionModulePdfRange = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'put',
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/admin/client/ExportInductionModulePdfRange`,
    });
    return response.data
  } catch (error) {
    return error.message
  }
}


export const DeleteSite = (siteUUid) => async (dispatch) => {

  try {
    const response = await axios({
      method: "delete",
      data: { site_uuid: siteUUid },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteSite`,
    });
    return response.data;
  } catch (error) { }
};
export const DeleteSiteInduction = (apiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: apiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteSiteInduction`,
    });
    return response.data;
  } catch (error) { }
};
export const DeleteInducteeSiteDetails = (apiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: apiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteInducteeSiteDetails`,
    });
    return response.data;
  } catch (error) { }
};
export const DeleteCompanyIndModuleFileById = (data) => async (dispatch) => {
  // Final CSV Data
  try {
    let formdata = new FormData();
    for (let key in data) {
      formdata.append(key, data[key]);
    }
    const response = await axios({
      method: "delete",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteCompanyIndModuleFileById`,
    });
    return response.data;
  } catch (error) { }
};

export const DeleteCompanyIndModuleQuesAnsByid = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: { module_question_uuid: ApiData },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteCompanyIndModuleQuesAnsByid`,
    });
    return response.data;
  } catch (error) { }
};

export const DeleteCompanyIndModuleAnsByid = (ApiData) => async (dispatch) => {
  // Final CSV Data
  try {
    const response = await axios({
      method: "delete",
      data: { module_answer_uuid: ApiData },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteCompanyIndModuleAnsByid`,
    });
    return response.data;
  } catch (error) { }
};


export const DeleteSiteInductionModuleById = (ApiData) => async (dispatch) => {
  try {
    const response = await axios({
      method: "delete",
      data: ApiData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/client/DeleteSiteInductionModuleById`,
    });
    return response.data;
  } catch (error) { }
};