import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetAllContactsOfContractorSlice";
export const GetProviderInternalContactById = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    let apiPath;
    let data;
    if(apiData.hasOwnProperty("type")){
        apiPath="GetProviderClientContactById"
        data={provider_client_contact_uuid:apiData.provider_client_contact_uuid}
    }
    else{
        apiPath="GetProviderInternalContactById"
        data={individual_uuid:apiData.individual_uuid,organisation_uuid:apiData.organisation_uuid}
    }
    try {
        // dispatch(fetchData())
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/${apiPath}`,
        params: data
      });
      return response.data
    //   dispatch(fetchDataSuccess(response.data))
      
    } catch (error) {
        // dispatch(fetchDataError(error.message))  
        return error.message
    }
  };