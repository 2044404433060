// breadcrumbSlice.js
import { createSlice } from '@reduxjs/toolkit';
const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState: [],
  reducers: {
    setBreadcrumbs: (state, action) => {
      return action.payload;
    },
    resetBreadCrumbs(){
      return []
    }
  },
});

export const { setBreadcrumbs, resetBreadCrumbs } = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
