import React, { useState } from "react";
import BtnLight from "../../../../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../../../../common/element/Buttons/btnPrimary";
import Select from "../../../../../../../../../../common/element/Select/Select";
import Dialog from "../../../../../../../../../../common/element/Dialog/Dialog";
import SiteList from "./SiteList";
import Input from "../../../../../../../../../../common/element/Input";
const SiteLog = (props) => {
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [dialogAddOpen, setAddOpen] = useState(false);
  const [dialogUnsaveOpen, setUnsaveOpen] = useState(false);
  const searchOptions = [
    {
      value: "Steph De La Vega steph.delavega@speckledigital.com",
      label: "Steph De La Vega steph.delavega@speckledigital.com",
    },
  ];
  const openDialog = () => {
    setIsOpen(true);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
    props.getOnDialogClose(onDialogClose);
  };
  const openAddDialog = () => {
    setAddOpen(true);
    setIsOpen(false);
  };
  const onAddDialogClose = (e) => {
    setAddOpen(false);
    // props.getOnDialogClose(onAddDialogClose);
  };

  const openUnsaveDialog = () => {
    setUnsaveOpen(true);
  };
  const onUnsaveDialogClose = (e) => {
    setUnsaveOpen(false);
  };
  return (
    <React.Fragment>
      <div className="flex justify-between mb-5">
        <div className="flex gap-2">
          <Select
            className="w-80 h-9 select-options"
            placeholder="Search"
            options={searchOptions}
          ></Select>

          <BtnLight className="w-9 h-9 flex items-center justify-center">
            <svg
              width="21"
              height="20"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.712 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0zm-6 8h12m-12 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                stroke="#101828"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </BtnLight>
        </div>
      </div>
      <SiteList />
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/file-check.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Invite a Worker
            </h5>
          </div>
        </div>
        <div className="px-6">
   
            <label className="font-medium text-gray-700 block mb-1">
              Email address*
            </label>
            <div className="chooseEmail">
              <Select
                className="w-full h-9 select-options"
                placeholder="Enter Worker’s email address"
                options={searchOptions}
              ></Select>
         
          </div>
          <div className="flex gap-2"> 
          <div className="w-3/6 mt-5">
            <label className="font-medium text-gray-700 block mb-1">
              First Name*
            </label>
               <Input className="h-9 w-full" placeholder="Enter Contact's first name"/>
          </div>
          <div className=" w-3/6 mt-5">
            <label className="font-medium text-gray-700 block mb-1">
              Last Name*
            </label>
               <Input className="h-9 w-full" placeholder="Enter Contact's last name"/>
          </div>
          </div>
        </div>
        <div className="flex dailog-footer">
          <BtnLight
            className="mr-1.5 px-4"
            variant="plain"
            onClick={openUnsaveDialog}
          >
            Cancel
          </BtnLight>
          <BtnPrimary
            onClick={() => openAddDialog()}
            className="ml-1.5 px-4 flex gap-2 items-center justify-center"
          >
            Invite user
          </BtnPrimary>
        </div>
      </Dialog>
     
      <Dialog
        isOpen={dialogUnsaveOpen}
        onClose={onUnsaveDialogClose}
        onRequestClose={onUnsaveDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-warning-100 flex items-center justify-center">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 3.5v3.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C8.26 8.5 8.54 8.5 9.1 8.5h6.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437c.109-.214.109-.494.109-1.054V4.5m0 17v-6.4c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437c-.214-.109-.494-.109-1.054-.109H9.1c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C7.5 14.26 7.5 14.54 7.5 15.1v6.4m14-11.675V16.7c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327H8.3c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3.5 19.22 3.5 18.38 3.5 16.7V8.3c0-1.68 0-2.52.327-3.162a3 3 0 0 1 1.311-1.311C5.78 3.5 6.62 3.5 8.3 3.5h6.874c.49 0 .734 0 .965.055.204.05.399.13.578.24.201.123.374.296.72.642l3.126 3.126c.346.346.519.519.642.72.11.18.19.374.24.579.055.23.055.474.055.963z"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-0.5">
            You have unsaved updates
          </p>
          <p className=" text-gray-600 text-center">Save before leaving?</p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-3">
          <BtnLight className="flex-1" onClick={onUnsaveDialogClose}>
            Exit
          </BtnLight>
          <BtnPrimary className="flex-1 flex items-center justify-center">
            Save
          </BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default SiteLog;
