import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import ContractorViewAll from "../pages/Provider/ViewAll";
// import WorkerViewAll from "../pages/Worker/ViewAll";

// import SpecificContractor from "../pages/Provider/SpecificContractor/SpecificContractor";
import SpecificWorker from "../pages/Workers/SpecificWorker/SpecificWorker/SpecificContractor";

import Notes from "../pages/Notes/Notes";
import ClientSettings from "../pages/Workers/SpecificWorker/Dialog/Clients/ClientSettings";
// import SpecificSite from "../pages/Client/SpecificSite/index";
// import SpecificSite

// import Dashboard from "../pages/Worker/Dashboard";

function useLocalStoragePath() {
  const location = useLocation();
  
  useEffect(() => {
    localStorage.setItem("currentPath", location.pathname);
  }, [location.pathname]);

  return location.pathname;
}

export const SpecificWorkerRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route
          path="view-all-worker/specific-worker/:id"
          element={<SpecificWorker />}
        />
        {/* <Route
          path="/notes"
          element={<Notes />}
        /> */}
        <Route
          path="view-all-worker/specific-worker/:id/settings"
          element={<ClientSettings />}
        />
         {/* <Route
          path="view-all-worker/specific-worker/:id/specific-site"
          element={<SpecificSite />}
        /> */}
      </Routes>
    </React.Fragment>
  );
};
