import React from "react";
import Heading from "../../../../../common/element/Heading";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import BtnPrimaryLight from "../../../../../common/element/Buttons/primaryLightBtn";
import Select from "../../../../../common/element/Select";
import DatePicker from "../../../../../common/element/DatePicker/DatePicker";
import Input from "../../../../../common/element/Input"
import Label from "../../../../../common/element/Label";
import Checkbox from "../../../../../common/element/Checkbox";
import Table from "../../../../../common/element/Table";
const seacrhOption = [
    { value: '', label: '' },
    { value: '', label: ''},

]
const selectOption = [
    { value: 'Tiers', label: 'Tiers' },
    { value: '', label: ''},

]
const { Tr, Th, Td, THead, TBody } = Table
const NewInvoice = () => {
  return (
    <React.Fragment>
      <div className="flex items-center justify-between mb-7 gap-3 flex-wrap">
        <Heading>New invoice </Heading>
        <div className="flex flex-wrap gap-3">
          <BtnPrimaryLight className="w-16 flex items-center justify-center text-xs">
            Save
        
          </BtnPrimaryLight>
          <BtnPrimary className="w-44 flex items-center justify-center text-xs">
          Approve and email
            <span className="ml-3">
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9165 13.4815L12.3808 8.48149M7.61889 8.48149L2.0832 13.4815M1.6665 4.31482L8.4706 9.07769C9.02158 9.46337 9.29707 9.65621 9.59672 9.73091C9.86142 9.79689 10.1383 9.79689 10.403 9.73091C10.7026 9.65621 10.9781 9.46337 11.5291 9.07769L18.3332 4.31482M5.6665 15.1482H14.3332C15.7333 15.1482 16.4334 15.1482 16.9681 14.8757C17.4386 14.636 17.821 14.2535 18.0607 13.7831C18.3332 13.2483 18.3332 12.5483 18.3332 11.1482V5.81482C18.3332 4.41469 18.3332 3.71462 18.0607 3.17984C17.821 2.70944 17.4386 2.32699 16.9681 2.0873C16.4334 1.81482 15.7333 1.81482 14.3332 1.81482H5.6665C4.26637 1.81482 3.56631 1.81482 3.03153 2.0873C2.56112 2.32699 2.17867 2.70944 1.93899 3.17984C1.6665 3.71462 1.6665 4.41469 1.6665 5.81482V11.1482C1.6665 12.5483 1.6665 13.2483 1.93899 13.7831C2.17867 14.2535 2.56112 14.636 3.03153 14.8757C3.56631 15.1482 4.26637 15.1482 5.6665 15.1482Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
          </BtnPrimary>
        </div>
      </div>

      <div className="border border-gray-300 rounded-xl p-5">
                <div className="flex gap-3">
                    <div className="flex-1">
                        <Label className="font-medium">To</Label>
                        <Select
                            placeholder="Please Select"
                            options={seacrhOption}
                        ></Select>
                    </div>
                    <div className="flex-1">
                        <Label className="font-medium">Invoice no.</Label>
                        <Input type="" className="h-34 w-full" placeholder="[Invoice no]"/>
                    </div>
                    <div className="flex-1">
                        <Label className="font-medium">Reference</Label>
                        <Input type="" className="h-34 w-full"/>
                    </div>
                    <div className="flex-1">
                        <Label className="font-medium">Issue Date</Label>
                        <DatePicker/>
                    </div>
                    <div className="flex-1">
                        <Label className="font-medium">Due date</Label>
                        <DatePicker/>
                    </div>
                </div>

                <div className="mt-5"><Checkbox/>Generate annual invoice</div>
                <div className="border rounded-lg border-gray-200 mt-5 invoice-table">
                <Table>
                <THead>
                    <Tr>
                        <Th>items</Th>
                        <Th>Description</Th>
                        <Th>Qty</Th>
                        <Th>Tax</Th>
                        <Th>Amount</Th>
                    </Tr>
                </THead>
                <TBody>
                    <Tr>
                        <Td>
                        <div className="select-option-wrap">
                        <Select
                            placeholder="Please Select"
                            options={selectOption}
                        ></Select>
                        </div>
                        </Td>
                        <Td></Td>
                        <Td>0</Td>
                        <Td></Td>
                        <Td>000</Td>
                    </Tr>
                
                </TBody>
            </Table>
            </div>
            <div className="mt-5">
                    <div className="flex items-end">
                        <div className="w-2/3">
                        <BtnPrimaryLight className="w-28 gap-2 flex items-center justify-center text-xs"><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5832 4.86125V14.2314C14.5832 16.7627 12.5311 18.8147 9.99984 18.8147C7.46853 18.8147 5.4165 16.7627 5.4165 14.2314V5.20363C5.4165 3.51609 6.78452 2.14807 8.47206 2.14807C10.1596 2.14807 11.5276 3.51609 11.5276 5.20363V14.1862C11.5276 15.03 10.8436 15.714 9.99984 15.714C9.15607 15.714 8.47206 15.03 8.47206 14.1862V6.02404" stroke="#5786CC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Attach file</BtnPrimaryLight>
                        </div>
                        <div className="w-1/3 total-invoice-wrap">

                        <Table>
                <TBody>
                    <Tr>
                        <Td><span className="font-semibold">Subtotal</span></Td>
                        <Td>00.00</Td>
                    </Tr>
                    <Tr>
                        <Td><span className="font-semibold">Total GST</span></Td>
                        <Td>00.00</Td>
                    </Tr>
                    <Tr>
                        <Td><span className="font-semibold">Total</span></Td>
                        <Td>00.00</Td>
                    </Tr>
                </TBody>
            </Table>
                        </div>
                    </div>

            </div>
      </div>
    </React.Fragment>
  );
};

export default NewInvoice;
