import React, { useEffect, useState } from "react";
import Dialog from "../../../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../../../../common/element/Table";
import { useSelector } from "react-redux";
import moment from "moment";
const SiteList = ({ handleSort, getAllinvitedData }) => {
  const [inducteesDialogIsOpen, setInducteesIsOpen] = useState(false);
  const { Tr, Th, Td, THead, TBody } = Table;
  const [data, SetData] = useState([]);
  const onInducteesDialogClose = () => {
    setInducteesIsOpen(false);
  };

  const GettAllFunctions = useSelector((state) => state);

  useEffect(() => {
    if (GettAllFunctions?.SiteAccessLogsData?.data?.data?.rows) {
      SetData(GettAllFunctions?.SiteAccessLogsData?.data?.data?.rows);
    } else {
      SetData([]);
    }
  }, [GettAllFunctions]);
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-1/4">
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("trading_name")}
                >
                  Provider name
                  <svg
                    className={`transform ${
                      getAllinvitedData.sort !== "" &&
                      getAllinvitedData.sort === "trading_name" &&
                      getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("first_name")}
                >
                  Worker name
                  <svg
                    className={`transform ${
                      getAllinvitedData.sort !== "" &&
                      getAllinvitedData.sort === "first_name" &&
                      getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-40">
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("sign_in_date")}
                >
                  Sign-In
                  <svg
                    className={`transform ${
                      getAllinvitedData.sort !== "" &&
                      getAllinvitedData.sort === "sign_in_date" &&
                      getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-40">
                <span
                  className="flex items-center gap-1"
                  onClick={() => handleSort("sign_out_date")}
                >
                  Sign-Out
                  <svg
                    className={`transform ${
                      getAllinvitedData.sort !== "" &&
                      getAllinvitedData.sort === "sign_out_date" &&
                      getAllinvitedData.order === "asc"
                        ? "rotate-180"
                        : ""
                    }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
            </Tr>
          </THead>
          <TBody>
            {data?.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td>{item?.trading_name?item?.trading_name:"-"}</Td>
                  {/* <Td>{item?.WorkerSite?.site_name}</Td> */}
                  <Td>{`${item?.first_name || '-'} ${item?.last_name || ''}`}</Td>
                  <Td>
                    <span className="text-gray-900 block w-full font-medium">
                      {item?.sign_in_date !== null
                        ? moment(item.sign_in_date).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                    <span className="text-gray-600">
                      {item?.sign_in_date !== null
                        ? moment(item.sign_in_date).format("HH:mm")
                        : "-"}
                    </span>
                  </Td>
                  <Td>
                    {item?.clock_in_out_status ? (
                      <span className="text-success-500">On Site</span>
                    ) : (
                      <>
                        <span className="text-gray-900 block w-full font-medium">
                          {item?.sign_out_date !== null
                            ? moment(item.sign_out_date).format("DD/MM/YYYY")
                            : "-"}
                        </span>
                        <span className="text-gray-600">
                          {item?.sign_in_date !== null
                            ? moment(item.sign_out_date).format("HH:mm")
                            : "-"}
                        </span>
                      </>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </div>
    </React.Fragment>
  );
};
export default SiteList;
