import React from "react";
import Heading from "../../../../common/element/Heading";
import BtnLight from "../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../common/element/Buttons/btnPrimary";
import { Link } from "react-router-dom";
const InboxVerificationExpire = () => {
  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-center min-h-screen pr-6 pl-6"
    >
      <div className="min-h-screen justify-center flex  py-12 px-4">
        <div className="flex-col items-center justify-center flex">
          <div className="flex items-center mb-6">
            <img className="brand-img" src="/assets/logo.svg" alt="conserve" />
          </div>
          <Heading className="text-center mb-3">
            Oops! The link has expired for
            <br /> security purposes.
          </Heading>
          <p className="text-base text-gray-600 text-center font-semibold max-w-lg mb-6">
            Simply click the button below and we'll send a new
            <br /> verification email to your registered email.
          </p>
          <BtnPrimary className="w-auto px-4 text-xs h-9">
            <Link to="inbox-verification">Resend confirmation email</Link>
          </BtnPrimary>
          <div className="border-t border-gray-200 w-full max-w-md mb-9 mt-7"></div>

          <p className="text-center text-gray-600 text-base font-medium">
            Having trouble accessing your account?
          </p>
          <div className="flex mt-2 mb-9 justify-between">
            <BtnLight className="h-9 text-xs px-3 flex justify-center items-center">
              Contact support
            </BtnLight>
          </div>
          <p className="text-xs text-gray-400 text-center font-inter">
            Make sure to check your spam/junk folder.
          </p>
        </div>
      </div>
    </div>
  );
};
export default InboxVerificationExpire;
