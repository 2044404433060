import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../../../../common/element/Table";
import Tooltip from "../../../../../../../common/element/Tooltip";
import Tag from "../../../../../../../common/element/Tag";
import Button from "../../../../../../../common/element/Buttons";
import Dialog from "../../../../../../../common/element/Dialog/Dialog";
import Tabs from "../../../../../../../common/element/Tabs";
import Upload from "../../../../../../../common/element/Upload";
import Input from "../../../../../../../common/element/Input";
import Badge from "../../../../../../../common/element/Badge"
import Documents from "./Documents";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import BtnPrimaryLight from "../../../../../../../common/element/Buttons/primaryLightBtn";
const { Tr, Th, Td, THead, TBody } = Table;
const { TabNav, TabList, TabContent } = Tabs;
const DocumentsList = (props) => {
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [insuraceDialogIsOpen, setIsInsuraceOpen] = useState(false);
  const [formDialogIsOpen, setIsFormOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("")
  const openDialog = (item) => {
    setSelectedItem(item)
    setIsOpen(true);
  };
  const onDialogClose = (e) => {
    setIsOpen(false);
  };


  const insuraceDialogClose = (e) => {
    setIsInsuraceOpen(false);
  };


  const formDialogClose = (e) => {
    setIsFormOpen(false);
  };
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-5/12">
                <span onClick={() => props?.handleSort("doc_name")} className="flex items-center gap-1 ">
                  Document name
                  <svg
                    className={`transform ${props?.filtersort === "doc_name" && props?.filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>Document type</Th>



              <Th>
                <span className="flex items-center gap-1">
                  Status
                  <Tooltip
                    title="Invited: Registered: Active:"
                    placement="top-start"
                  >
                    <img src="/assets/icons-svg/help-circle.svg" alt="#" />
                  </Tooltip>
                </span>
              </Th>

            </Tr>
          </THead>
          <TBody>

            {props?.allDocData?.data?.rows && props?.allDocData?.data?.rows?.length > 0 && (
              props?.allDocData?.data?.rows?.map((dataItem, index) => (
                <>
                  <Tr key={index}>
                    <Td>{dataItem?.DocAppr?.provApprDoc?.doc_name ? dataItem?.DocAppr?.provApprDoc?.doc_name : dataItem?.other_doc_name ? dataItem?.other_doc_name : "-"}</Td>

                    <Td>{dataItem?.document_type?.doc_type_name}</Td>
                    <Td>
                      {dataItem?.DocAppr?.approval_status === "approved" && (
                        <Badge onClick={() => openDialog(dataItem)}
                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                          content={"Approved"}
                          innerClass="bg-success-100 text-success-700"
                        />
                      )}
                      {dataItem?.DocAppr?.approval_status === "client_approved_action" && (
                        <div className="flex gap-3">
                          <Badge onClick={() => openDialog(dataItem)}
                            className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                            content={"Approved"}
                            innerClass="bg-success-100 text-success-700"
                          /><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.66675 14.5L2.66675 3.16667M2.66675 9.16667H7.60008C7.97345 9.16667 8.16013 9.16667 8.30274 9.094C8.42818 9.03009 8.53017 8.9281 8.59409 8.80266C8.66675 8.66005 8.66675 8.47337 8.66675 8.1V3.56667C8.66675 3.1933 8.66675 3.00661 8.59409 2.86401C8.53017 2.73856 8.42818 2.63658 8.30274 2.57266C8.16013 2.5 7.97345 2.5 7.60008 2.5H3.73341C3.36005 2.5 3.17336 2.5 3.03075 2.57266C2.90531 2.63658 2.80333 2.73856 2.73941 2.86401C2.66675 3.00661 2.66675 3.1933 2.66675 3.56667V9.16667ZM8.66675 3.83333H12.9334C13.3068 3.83333 13.4935 3.83333 13.6361 3.906C13.7615 3.96991 13.8635 4.0719 13.9274 4.19734C14.0001 4.33995 14.0001 4.52663 14.0001 4.9V9.43333C14.0001 9.8067 14.0001 9.99339 13.9274 10.136C13.8635 10.2614 13.7615 10.3634 13.6361 10.4273C13.4935 10.5 13.3068 10.5 12.9334 10.5H9.73341C9.36005 10.5 9.17336 10.5 9.03075 10.4273C8.90531 10.3634 8.80333 10.2614 8.73941 10.136C8.66675 9.99339 8.66675 9.8067 8.66675 9.43333V3.83333Z" stroke="#039855" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      )}
                      {dataItem?.DocAppr?.approval_status === "client_approval_req" && (
                        <Badge onClick={() => openDialog(dataItem)}
                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                          content={"Action required"}
                          innerClass="bg-blue-100 text-blue-700"
                        />
                      )}
                      {dataItem?.DocAppr?.approval_status === "client_reject" && (
                        <Badge onClick={() => openDialog(dataItem)}
                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                          content={"Rejected"}
                          innerClass="bg-error-100 text-error-700"
                        />
                      )}
                      {dataItem?.DocAppr?.approval_status === "admin_reject" && (
                        <Badge onClick={() => openDialog(dataItem)}
                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                          content={"Rejected"}
                          innerClass="bg-error-100 text-error-700"
                        />
                      )}
                      {dataItem?.DocAppr?.approval_status === "pending" && (
                        <Badge onClick={() => openDialog(dataItem)}
                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                          content={"Awaiting review"}
                          innerClass="bg-blue-100 text-blue-700"
                        />
                      )}
                      {dataItem?.DocAppr?.approval_status === (null || undefined) && (
                        <Badge onClick={() => openDialog(dataItem)}
                          className="py-0.5 px-2 cursor-pointer rounded-lg font-normal"
                          content={"Pending Upload"}
                          innerClass="bg-success-100 text-success-700"
                        />
                      )}
                    </Td>
                  </Tr>
                </>
              )))}
          </TBody>
        </Table>
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <Documents selectedItem={selectedItem} trading_name={props?.trading_name} OnGetProviderDocsAgainstClient={props?.OnGetProviderDocsAgainstClient} />
      </Dialog>


      <Dialog
        isOpen={insuraceDialogIsOpen}
        onClose={insuraceDialogClose}
        onRequestClose={insuraceDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <div className="flex h-full">
          <div className="left-block-wrap h-full w-1/5">
            <Tabs defaultValue="tab1" variant="pill">
              <TabList className="bg-gray-500 rounded-lg p-1">
                <TabNav value="tab1">Details</TabNav>
                <TabNav value="tab2">History</TabNav>
              </TabList>
              <div className="p-4">
                <TabContent value="tab1">
                  <div className="mb-4">
                    <div className="font-bold text-white text-xl  flex justify-between mb-4">
                      <span>Status</span>
                    </div>
                    <Tag className="text-success-700 bg-success-50">Approved</Tag>
                    <label className="text-gray-100 block mt-4 mb-1">Message</label>
                    <p className="font-semibold text-white">Duis massa leo elementum quam facilisis enim quis. Aliquam adipiscing enim non laoreet tempus purus habitasse. In commodo dignissim sodales eget habitasse urna accumsan suspendisse.</p>
                    <hr className="my-4 border-t border-gray-500" />
                    <div className="font-bold text-white text-xl mb-1 flex justify-between">
                      <span >Status</span>
                      <Button className="ml-auto">
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.063 15.096c.039-.344.058-.517.11-.678.046-.143.111-.278.194-.404.093-.141.216-.264.461-.509L14.833 2.5a2.357 2.357 0 1 1 3.334 3.333L7.16 16.84c-.245.245-.367.367-.509.46a1.667 1.667 0 0 1-.404.195c-.16.052-.333.07-.678.11l-2.82.313.313-2.82z"
                            stroke="#fff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                    <label className="text-gray-100">
                      [DD Month YYYY] at [00:00]
                    </label>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      Policy number
                    </label>
                    <p className="text-white font-semibold">[00000000000000]</p>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      Amount insured
                    </label>
                    <p className="text-white font-semibold">$00000000</p>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      No. of workers
                    </label>
                    <p className="text-white font-semibold">0000000</p>
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-100 text-12 font-medium">
                      Expiry date
                    </label>
                    <p className="text-white font-semibold">DD/MM/YYYY</p>
                  </div>
                </TabContent>

                <TabContent value="tab2">
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      27 April 2023 at 13:34
                    </label>
                    <p className="font-semibold text-white mt-2">
                      Admin has edited Expiry date
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      Admin has edited Expiry date
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                    <div className=" p-4 rounded-lg bg-white font-medium mt-2">
                      Hi. The document was rejected because the insurance amount
                      does not meet Client’s requirements. Please re-upload.
                    </div>
                  </div>
                  <div className="mb-4  pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                    <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                      <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.8 14.692H4.62c-.403 0-.605 0-.698-.08a.333.333 0 0 1-.116-.28c.01-.122.152-.264.438-.55l5.668-5.669c.264-.264.396-.396.549-.445a.667.667 0 0 1 .412 0c.152.05.284.181.548.445L14 10.693v.8m-3.2 3.2c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.874c.218-.428.218-.987.218-2.108m-3.2 3.2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 13.172 2 12.613 2 11.492v-5.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v5.6M7 6.36a1.333 1.333 0 1 1-2.667 0A1.333 1.333 0 0 1 7 6.36z"
                            stroke="#5786CC"
                            strokeWidth="1.333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <div className="ml-3">
                        <span className="font-medium text-gray-700 text-xs">
                          document name.[format]
                        </span>
                        <p className="text-gray-600 text-xs">200 KB</p>
                      </div>
                      <Button className="ml-auto">
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 8.458v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 2.772 6.49C2.5 7.024 2.5 7.725 2.5 9.125v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.535.272-1.235.272-2.635v-1.833"
                            stroke="#475467"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </TabContent>
              </div>
            </Tabs>
          </div>
          <div className="right-block-wrap h-full w-4/5">
            <div className="doc-header flex items-center justify-center">
              <div className="flex justify-content-center">
                <Button className="bg-white h-10 w-11 text-xl bl-rds">-</Button>
                <span className="font-semibold text-12 text-gray-700 bg-white w-16 flex items-center justify-center text-center h-10 border-r border-l">
                  100%
                </span>
                <Button className="bg-white h-10 w-11 text-xl br-rds">+</Button>
              </div>
              <div className="flex gap-6 rightSidebtn">
                <Button className="flex text-white gap-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Download
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#zvmr0yey3a)">
                      <path
                        d="m10.835 18.333-2.5-2.5m0 0 2.5-2.5m-2.5 2.5H12.5a5.833 5.833 0 0 0 2.5-11.105m-10 10.544a5.834 5.834 0 0 1 2.5-11.105h4.167m0 0-2.5-2.5m2.5 2.5-2.5 2.5"
                        stroke="#fff"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="zvmr0yey3a">
                        <path fill="#fff" d="M0 0h20v20H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>

                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.588 15.303 9.41 16.482a4.167 4.167 0 1 1-5.893-5.893l1.179-1.178m10.606 1.178 1.179-1.178a4.167 4.167 0 1 0-5.893-5.893L9.41 4.697m-2.328 8.22 5.834-5.834"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </div>
            <div className="doc-view"></div>
          </div>
        </div>
      </Dialog>
      <Dialog
        isOpen={formDialogIsOpen}
        onClose={formDialogClose}
        onRequestClose={formDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="documentDialog"
      >
        <div className="flex h-full">
          <div className="left-block-wrap h-full w-1/5">
            <Tabs defaultValue="tab1" variant="pill">
              <TabList className="bg-gray-500 rounded-lg p-1">
                <TabNav value="tab1">Details</TabNav>
                <TabNav value="tab2">History</TabNav>
              </TabList>
              <div className="p-4">
                <TabContent value="tab1">
                  <div className="mb-4">
                    <div className="font-bold text-white text-xl  flex justify-between mb-4">
                      <span>Status</span>
                    </div>
                    <Tag className="text-blue-700 bg-blue-50">Awaiting review</Tag>
                    <label className="text-gray-100 block mt-4 mb-1">Message</label>
                    <p className="font-semibold text-white">Duis massa leo elementum quam facilisis enim quis. Aliquam adipiscing enim non laoreet tempus purus habitasse. In commodo dignissim sodales eget habitasse urna accumsan suspendisse.</p>
                    <hr className="my-4 border-t border-gray-500" />
                    <div className="font-bold text-white text-xl mb-1 flex justify-between">
                      <span >[Document name]</span>
                    </div>
                    <Upload draggable className="bg-white my-4 text-center" />
                    <label className="text-gray-100">
                      [DD Month YYYY] at [00:00]
                    </label>
                  </div>
                  <div className="mb-4">
                    <label className="text-12 font-Medium text-gray-200 mb-2 block">Policy number</label>
                    <Input className="h-9 w-full block" />
                  </div>
                  <div className="mb-4">
                    <label className="text-12 font-Medium text-gray-200 mb-2 block">Amount insured</label>
                    <Input className="h-9 w-full block" placeholder="[00000000000000]" />
                  </div>
                  <div className="mb-4">
                    <label className="text-12 font-Medium text-gray-200 mb-2 block">No. of workers</label>
                    <select className="w-full h-9 rounded-lg">

                      <option>000000</option>
                      <option>20</option>
                    </select>

                  </div>
                  <div className="mb-4">
                    <label className="text-12 font-Medium text-gray-200 mb-2 block">Expiry date</label>
                    <Input className="h-9 w-full block" type="date" />
                  </div>
                  <div className="mb-4 flex justify-end">
                    <BtnPrimary className="w-auto px-4">Add document</BtnPrimary>
                  </div>
                </TabContent>

                <TabContent value="tab2">
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      27 April 2023 at 13:34
                    </label>
                    <p className="font-semibold text-white mt-2">
                      Admin has edited Expiry date
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      Admin has edited Expiry date
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                  </div>
                  <div className="mb-4 border-b border-gray-500 pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                    <div className=" p-4 rounded-lg bg-white font-medium mt-2">
                      Hi. The document was rejected because the insurance amount
                      does not meet Client’s requirements. Please re-upload.
                    </div>
                  </div>
                  <div className="mb-4  pb-4">
                    <label className="text-gray-100">
                      26 April 2023 at 15:23
                    </label>
                    <p className="font-semibold text-white mt-2">
                      [Worker Name] has edited Amount insured
                    </p>
                    <div className=" p-4 rounded-lg bg-white font-medium mt-2 flex">
                      <span className="w-9 h-9 rounded-full bg-blue-50 border-4 border-blue-100 flex justify-center items-center">
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.8 14.692H4.62c-.403 0-.605 0-.698-.08a.333.333 0 0 1-.116-.28c.01-.122.152-.264.438-.55l5.668-5.669c.264-.264.396-.396.549-.445a.667.667 0 0 1 .412 0c.152.05.284.181.548.445L14 10.693v.8m-3.2 3.2c1.12 0 1.68 0 2.108-.219a2 2 0 0 0 .874-.874c.218-.428.218-.987.218-2.108m-3.2 3.2H5.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C2 13.172 2 12.613 2 11.492v-5.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.428-.218.988-.218 2.108-.218h5.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v5.6M7 6.36a1.333 1.333 0 1 1-2.667 0A1.333 1.333 0 0 1 7 6.36z"
                            stroke="#5786CC"
                            strokeWidth="1.333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                      <div className="ml-3">
                        <span className="font-medium text-gray-700 text-xs">
                          document name.[format]
                        </span>
                        <p className="text-gray-600 text-xs">200 KB</p>
                      </div>
                      <Button className="ml-auto">
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 8.458v-5m0 0h-5m5 0-6.667 6.667m-2.5-5H6.5c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 2.772 6.49C2.5 7.024 2.5 7.725 2.5 9.125v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.093-1.093c.272-.535.272-1.235.272-2.635v-1.833"
                            stroke="#475467"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </TabContent>
              </div>
            </Tabs>
          </div>
          <div className="right-block-wrap h-full w-4/5">
            <div className="doc-header flex items-center justify-center">
              <div className="flex justify-content-center">
                <Button className="bg-white h-10 w-11 text-xl bl-rds">-</Button>
                <span className="font-semibold text-12 text-gray-700 bg-white w-16 flex items-center justify-center text-center h-10 border-r border-l">
                  100%
                </span>
                <Button className="bg-white h-10 w-11 text-xl br-rds">+</Button>
              </div>
              <div className="flex gap-6 rightSidebtn">
                <Button className="flex text-white gap-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 13v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 0 1-1.092 1.092C15.6 18 14.9 18 13.5 18h-7c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 0 1-1.093-1.092C2.5 16.1 2.5 15.4 2.5 14v-1m11.667-4.167L10 13m0 0L5.833 8.833M10 13V3"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Download
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 6.333v-1.5c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 3.433 5 3.899 5 4.833v1.5m0 9.166c-.775 0-1.163 0-1.48-.085a2.5 2.5 0 0 1-1.768-1.768c-.085-.318-.085-.705-.085-1.48v-1.833c0-1.4 0-2.1.272-2.635a2.5 2.5 0 0 1 1.093-1.093c.534-.272 1.234-.272 2.635-.272h8.666c1.4 0 2.1 0 2.635.272a2.5 2.5 0 0 1 1.093 1.093c.272.534.272 1.235.272 2.635v1.833c0 .775 0 1.162-.085 1.48a2.5 2.5 0 0 1-1.768 1.768c-.318.085-.705.085-1.48.085m-2.5-6.25H15m-7.333 9.584h4.666c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.824.182-1.757v-1.333c0-.934 0-1.4-.182-1.757a1.667 1.667 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H7.666c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728C5 13.433 5 13.899 5 14.833v1.333c0 .933 0 1.4.181 1.757.16.313.415.568.729.728.356.182.823.182 1.756.182z"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#zvmr0yey3a)">
                      <path
                        d="m10.835 18.333-2.5-2.5m0 0 2.5-2.5m-2.5 2.5H12.5a5.833 5.833 0 0 0 2.5-11.105m-10 10.544a5.834 5.834 0 0 1 2.5-11.105h4.167m0 0-2.5-2.5m2.5 2.5-2.5 2.5"
                        stroke="#fff"
                        strokeWidth="1.667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="zvmr0yey3a">
                        <path fill="#fff" d="M0 0h20v20H0z" />
                      </clipPath>
                    </defs>
                  </svg>
                </Button>

                <Button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.588 15.303 9.41 16.482a4.167 4.167 0 1 1-5.893-5.893l1.179-1.178m10.606 1.178 1.179-1.178a4.167 4.167 0 1 0-5.893-5.893L9.41 4.697m-2.328 8.22 5.834-5.834"
                      stroke="#fff"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </div>
            <div className="doc-view"></div>
          </div>
        </div>
      </Dialog>



    </React.Fragment>
  );
};
export default DocumentsList;
