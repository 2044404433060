import { createSlice } from '@reduxjs/toolkit';

const RedirectWorkerSpecificData = createSlice({
    name: "RedirectWorkerSpecificData",
    initialState: [],
    reducers: {
        redirectSpecWorker(state, action) {
            return action.payload;
        },
        resetData(state) {
            return []; 
        }
    }
});

export default RedirectWorkerSpecificData.reducer;
export const { redirectSpecWorker, resetData } = RedirectWorkerSpecificData.actions;
