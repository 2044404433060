import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetProviderDetailsById = (getAllData) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.environment_url}/api/provider/GetProviderDetailsById`,
      params: getAllData
    });
    return response.data
    
  } catch (error) {
  }
};