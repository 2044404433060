import React, { useEffect, useState } from "react";
import Heading from "../../../../common/element/Heading";
import Tabs from "../../../../common/element/Tabs";
import CompanyProfile from "./CompanyProfile";
import Notifaction from "./Notification";
import Security from "./Security";
import YourProfile from "./YourProfile";
import Accreditation from "./Accreditation";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import PdfData from "../Clients/pdf/Index";
import WHSMSA from "./WHSMSA";

const { TabNav, TabList, TabContent } = Tabs;
const ContractorPortalSettings = () => {
  const dispatch = useDispatch();
  const permissionProviderData = useSelector(
    (state) => state?.permissionsProvider?.permissionData
  );
  const [forceRender, setForceRender] = useState(false);
  let permission_data;
  useEffect(() => {
    if (permissionProviderData !== null) {
      localStorage?.setItem(
        "permission_data",
        JSON?.stringify(permissionProviderData)
      );
      setForceRender((prevForceRender) => !prevForceRender);
    }
  }, [permissionProviderData]);

  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

  if (permissionData !== null) {
    permission_data = permissionData;
  }
  useEffect(() => {
    // Set breadcrumbs when the component mounts
    dispatch(
      setBreadcrumbs([
        {
          label: "Settings",
          url: "/provider/settings",
          home: "/provider/dashboard",
        },
      ])
    );
  }, []);

  return (
    <React.Fragment>
      <React.Fragment>
        <Heading>Settings</Heading>
        <Tabs defaultValue="tab1" variant="pill">
          <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5 mt-6 client-portal-tab">
            <TabList>
              <TabNav value="tab1">Your profile</TabNav>
              <TabNav value="tab2">Company Profile</TabNav>
              <TabNav value="tab6">WHS & MSA</TabNav>
              {/* <TabNav value="tab3">Security</TabNav> */}
              <TabNav value="tab4">Notifications</TabNav>
              <TabNav value="tab5" className="flex-1">
                Accreditation
              </TabNav>
            </TabList>
          </div>

          <TabContent value="tab1">
            <YourProfile permission_data={permission_data} />
          </TabContent>
          <TabContent value="tab2">
            <CompanyProfile permission_data={permission_data} />
          </TabContent>
          {/* <TabContent value="tab3">
            <Security permission_data={permission_data} />
          </TabContent> */}
          <TabContent value="tab4">
            <Notifaction permission_data={permission_data} />
          </TabContent>
          <TabContent value="tab5">
            <PdfData />
          </TabContent>
          <TabContent value="tab6">
            <WHSMSA />
          </TabContent>
        </Tabs>
      </React.Fragment>
    </React.Fragment>
  );
};

export default ContractorPortalSettings;
