import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom"
// import PortalLayout from "../../../../adminDashboard/components/layout/PortalLayout";
import Heading from "../../../../../common/element/Heading";
import Tabs from "../../../../../common/element/Tabs";
import CompanyProfile from "./CompanyProfile";
import Notifaction from "./Notification";
import Security from "./Security";
import YourProfile from "./YourProfile";
import ComplianceDate from "./ComplianceDate";
import { setBreadcrumbs } from "../../../../../redux/classes/breadcrumbsSlice";
const { TabNav, TabList, TabContent } = Tabs;
const ClientPortalSettings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Settings", url: "/client/settings", home: "/client/dashboard" },
      ])
    );
  }, []);
  const permissionDataUpdate = useSelector((state) => state?.permissions?.permissionData);
  const [forceRender, setForceRender] = useState(false);

  useEffect(() => {
    if (permissionDataUpdate !== null) {
      localStorage.setItem("permission_data", JSON.stringify(permissionDataUpdate));
      setForceRender(prevForceRender => !prevForceRender);
      if (permissionDataUpdate?.settings_view == false) {
        // navigate('/client/dashboard');
        navigate('/client/noaccess')
    }
    }
  }, [permissionDataUpdate]);
  let permission_data;
  const permissionData = JSON.parse(localStorage?.getItem("permission_data"));

  if (permissionData !== null) {
    permission_data = permissionData;
  }

  const settingsPermission = permission_data?.settings_write == true ? false : true

  return (
    <React.Fragment>
      {/* <PortalLayout> */}
      <Heading>Settings</Heading>
      <Tabs defaultValue="tab1" variant="pill">
        <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5 mt-6 client-portal-tab">
          <TabList>
            <TabNav value="tab1">Your profile</TabNav>
            <TabNav value="tab2">Company Profile</TabNav>
            <TabNav value="tab3">Security</TabNav>
            <TabNav value="tab4">Notifications</TabNav>
            <TabNav value="tab5">Compliance Date</TabNav>
          </TabList>
        </div>

        <TabContent value="tab1">
          <YourProfile settingsPermission={settingsPermission} />
        </TabContent>
        <TabContent value="tab2">
          <CompanyProfile settingsPermission={settingsPermission} />
        </TabContent>
        <TabContent value="tab3">
          <Security settingsPermission={settingsPermission} />
        </TabContent>
        <TabContent value="tab4">
          <Notifaction settingsPermission={settingsPermission} />
        </TabContent>
        <TabContent value="tab5">
          <ComplianceDate settingsPermission={settingsPermission} />
        </TabContent>
      </Tabs>

      {/* </PortalLayout> */}
    </React.Fragment>
  );
};

export default ClientPortalSettings;