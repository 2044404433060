import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/clientCsvSlice";
export const InviteWorkerFromCsvDetails = (CSVData) => async (dispatch) => {
    // Final CSV Data
      try {
        // dispatch(fetchData());
        const response = await axios({
          method: 'post',
          data: CSVData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
          },
          url: `${actionType.environment_url}/api/admin/provider/InviteWorkerFromCsvDetails`,
        });
        return response.data
        // dispatch(fetchDataSuccess(response.data));
      } catch (error) {
        return error.message
        // dispatch(fetchDataError(error.message));
      }
    }