import React, { useState, useEffect } from "react";
import BtnLight from "../../../../../../../../common/element/Buttons/btnLight";
import InputGroup from "../../../../../../../../common/element/InputGroup";
import Input from "../../../../../../../../common/element/Input";
import Upload from "../../../../../../../../common/element/Upload";
import Label from "../../../../../../../../common/element/Label";
// import filmUpload from "../../../../../../../../assets/icons-svg/film.svg";
import { UpdateInductionModuleData } from "../../../../../../../../redux/classes/client-portal/inductionModuleDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { DeletedocumentData } from "../../../../../../../../redux/classes/client-portal/deletedocument";
import { UpdateInductionStatus } from "../../../../../../../../redux/classes/client-portal/updateInductionSlice";
import { DeleteCompanyIndModuleFileById} from "../../../../../../../../actions/Admin-Client/AllCompanyInduction";
import { closeDocumentfunc } from "../../../../../../../../redux/classes/client-portal/closeDocument";
import { Tooltip } from "@material-ui/core";
const VideoUpload = (props) => {
  const { InductionModuleData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [Pdfdata, Setpdfdata] = useState(InductionModuleData[props.indexData]);
  const [videoLink, setVideoLink] = useState("");
  const [iframeClose, setiframeClose] = useState(false);
  const [isValidLink, setIsValidLink] = useState(false);
  const [sizeError, SetsizeError] = useState("");
  const [checkPdf, SetcheckPdf] = useState("");
  const [video, Setvideo] = useState("");

  const handleInputChange = (e) => {
    setiframeClose(false)
    dispatch(UpdateInductionStatus(true));
    const link = e;
    setVideoLink(link);

    // Regular expressions to check if the link is a YouTube or Vimeo link
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([A-Za-z0-9_-]+)/;
    const vimeoRegex = /^(https?:\/\/)?(www\.)?vimeo\.com\/([0-9]+)/;
    if (e == "") {
      setIsValidLink(false);
      Setpdfdata({
        ...Pdfdata,
        content_info_type: "",
        content_info: link,
      });
    } else {
      if (youtubeRegex.test(link) || vimeoRegex.test(link)) {
        Setpdfdata({
          ...Pdfdata,
          content_info_type: "video_url",
          content_info: link,
        });
        setIsValidLink(false);
      } else {
        setIsValidLink(true);
      }
    }
  };

  useEffect(() => {
    let fileName;
    if (Pdfdata?.company_ind_file?.name !== undefined) {
      fileName = Pdfdata.company_ind_file.name;
      SetcheckPdf(Pdfdata.company_ind_file);
    } else {
      fileName = Pdfdata.content_info;
      SetcheckPdf(Pdfdata.content_info);
    }
  }, []);

  const handleFileChange = (e) => {
    setiframeClose(false)
    dispatch(UpdateInductionStatus(true));

    if (e[e.length - 1].size <= 25 * 1024 * 1024) {
      const file = e[0];
      SetcheckPdf(file);
      Setvideo(file);
      Setpdfdata({
        ...Pdfdata,
        content_info_type: "file_video",
        company_ind_file: file,
      });
      SetsizeError("");
    } else {
      SetsizeError("File size exceeds the maximum limit of 25MB.");
    }
  };
  useEffect(() => {
    dispatch(
      UpdateInductionModuleData({
        indexData: props.indexData,
        moduleData: Pdfdata,
      })
    );
  }, [Pdfdata]);

  useEffect(() => {
    setVideoLink(Pdfdata.content_info);
  }, []);

  const handleDeletePDF = () => {
    dispatch(closeDocumentfunc(true));
    setiframeClose(false)
    dispatch(UpdateInductionStatus(true));
    SetcheckPdf("");
    setVideoLink("");
    Setvideo("");
    Setpdfdata({
      ...Pdfdata,
      content_info_type: "",
      company_ind_file: "",
      content_info: "",
    });
    if(
      ((Pdfdata?.module_uuid)&&
      (Pdfdata?.content_info_type=="file_video") && 
      (Pdfdata?.content_info))
      ){
      dispatch(
        DeleteCompanyIndModuleFileById({
          module_uuid: Pdfdata.module_uuid,
          content_info: Pdfdata.content_info,
        })
      );
    }
  };
  // const { deletedocument } = useSelector((state) => state);


  //   useEffect(()=>{
  // if (deletedocument) {
  //   handleDeletePDF();
  //   dispatch(DeletedocumentData(false));
  // }
  // const extractVideoId = (url) => {
  //   const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
  //   const match = url.match(regExp);
  //   return match && match[2].length === 11 ? match[2] : null;
  // };
  const extractVideoId = (url) => {
    // YouTube
    const youTubeRegExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const youTubeMatch = url.match(youTubeRegExp);

    if (youTubeMatch && youTubeMatch[1]) {
      return youTubeMatch[1];
    }

    // Vimeo
    const vimeoRegExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:$|\/|\?))/;
    const vimeoMatch = url.match(vimeoRegExp);

    if (vimeoMatch && vimeoMatch[2]) {
      return vimeoMatch[2];
    }

    return null;
  };

  let uploadVideo = !videoLink || Pdfdata?.content_info_type == "file_video";
  let fileData =
    Pdfdata?.content_info_type == "" || Pdfdata?.content_info_type == null;
  let uploadedFile = Pdfdata?.content_info_type == "file_video";
  let urlFile =
    (!video && Pdfdata?.content_info_type !== "file_video") ||
    Pdfdata?.content_info_type == "";
  const videoId = extractVideoId(videoLink);
  // Assuming you have a variable named videoLink that contains the video URL
  let vimeoEmbedUrl;
  if (videoLink.includes("vimeo.com")) {
    // If the video link is from Vimeo
    const videoId = videoLink.split("/").pop();
    vimeoEmbedUrl = `https://player.vimeo.com/video/${videoId}`;
  } else if (videoLink.includes("youtube.com")) {
    // If the video link is from YouTube
    vimeoEmbedUrl = `https://www.youtube.com/embed/${videoId}`;
  }

  return (
    <React.Fragment>
      <div className="flex items-center relative">
        <Tooltip placement={"right-start"} title={"Remove module content"}>
          <button
            onClick={() => handleDeletePDF()}
            className="modue_creation_close_icon absolute right-0 bottom-full"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m18 6.5-12 12m0-12 12 12"
                stroke="#667085"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </Tooltip>
        {uploadVideo && (
          <div className="p-4 rounded-xl border border-gray-300 flex items-center justify-between w-2/4">
            {fileData && (
              <Upload
                value={""}
                accept="video/*"
                showList={false}
                onChange={handleFileChange}
                draggable
                className="w-full"
              >
                <div className="text-center">
                  <div className="flex items-center justify-center flex-col">
                    {" "}
                    <span className="flex w-9 h-9 items-center justify-center rounded-full bg-gray-200 outline outline-8 outline-gray-50 outline-offset-0">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.667 13.333 10 10m0 0 3.333 3.333M10 10v7.5m6.666-3.548a4.583 4.583 0 0 0-2.917-8.12.516.516 0 0 1-.444-.25 6.25 6.25 0 1 0-9.816 7.58"
                          stroke="#475467"
                          strokeWidth="1.667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>
                    <span className="pt-4">
                      <span className="text-blue-700 font-semibold mr-1">
                        Click to upload
                      </span>{" "}
                      <span className="text-xs text-gray-600">
                        {" "}
                        or drag and drop ( max. 25mb)
                      </span>
                    </span>
                  </div>
                </div>
                {sizeError && (
                  <div className="text-red-500 text-sm mt-1">{sizeError}</div>
                )}
              </Upload>
            )}
            {uploadedFile && (
              <React.Fragment>
                <div className="flex  gap-4">
                  <span className="w-9 h-9 rounded-full border-4 border-blue-50 bg-blue-200 flex justify-center items-center">
                    <img src="/assets/icons-svg/upload-pdf.svg" alt="" />
                  </span>
                  <div className="flex flex-col  justify-center">
                    <span className="text-gray-700 text-xs font-medium">
                      {checkPdf?.name ? checkPdf?.name : props.size.name}
                    </span>
                    {/* <span className="text-gray-600 text-xs">
                      {checkPdf?.size
                        ? `${(checkPdf?.size / 1024 / 1024).toFixed(2)} MB`
                        : `${(props.size.size / 1024).toFixed(2)} MB`}
                    </span> */}
                  </div>
                </div>
                <button onClick={handleDeletePDF}>
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                      stroke="#344054"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </React.Fragment>
            )}
          </div>
        )}
        {!video && !videoLink && (
          <span className="text-black text-center px-6">or</span>
        )}
        {urlFile && (
          <React.Fragment>
            <div className="w-6/12">
              <Label>Import from URL</Label>
              <InputGroup className="mb-1.5">
                {videoLink != "" && !isValidLink && iframeClose && (
                  <div className="video-container">
                    <button
                      className="modue_creation_close_icon close_iframeBtn"
                      style={{ position: "absolute", right: "20px" }}
                      onClick={() => setiframeClose(false)}
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="#fff"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m18 6.5-12 12m0-12 12 12"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>

                    <iframe
                      width="891"
                      className="induction_iframe"
                      height="552"
                      src={vimeoEmbedUrl}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
                <Input
                  value={videoLink}
                  placeholder="Add video URL"
                  inputchangehandler={handleInputChange}
                  className="border-r-0 rounded-none rounded-l-lg h-9"
                />
                <BtnLight
                  className="w-auto px-5 text-xs rounded-none rounded-r-lg"
                  onClick={() => setiframeClose(true)}
                >
                  View
                </BtnLight>
              </InputGroup>
              <span className="text-xs text-gray-600 ">
                Only Youtube or Vimeo links accepted
              </span>
              {isValidLink && (
                <p style={{ color: "red" }}>
                  Invalid link. Please enter a YouTube or Vimeo link.
                </p>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
export default VideoUpload;
