import React, { useState, useEffect, useRef } from "react";
import BtnPrimaryLight from "./element/Buttons/primaryLightBtn";
import Scrollbar from "./element/ScrollBar";
import Checkbox from "./element/Checkbox";

function Dropdown({onCheckboxChange,docCustomData}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const dropdownRef = useRef(null);
  
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div ref={dropdownRef} className="relative add_file_btn">
      <BtnPrimaryLight
        onClick={toggleOpen}
        className="flex items-center gap-2 w-28 justify-center"
      >
        Add field{" "}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99935 4.1665V15.8332M4.16602 9.99984H15.8327"
            stroke="#5786CC"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </BtnPrimaryLight>
      {isOpen && (
        <div className="border border-gray-200 rounded-lg px-1 py-0.5 bg-white w-96 absolute">
          <div className="h-48">
            <Scrollbar>
              <ul>
                <li className=" h-10 px-1.5 py-0.5 flex  items-center rounded-md bg-blue-50">
                  <Checkbox onChange={(e)=>onCheckboxChange("req_issuer",e)} checked={docCustomData.req_issuer}/> Issuer
                </li>
                <li className="h-10 px-1.5 py-0.5 flex items-center rounded-md">
                  <Checkbox onChange={(e)=>onCheckboxChange("req_policy_no",e)} checked={docCustomData.req_policy_no}/> Policy Number 
                </li>
                <li className="h-10 px-1.5 py-0.5 flex items-center rounded-md">
                  <Checkbox onChange={(e)=>onCheckboxChange("req_amount_insured",e)} checked={docCustomData.req_amount_insured}/> Amount Insured
                </li>
                <li className="h-10 px-1.5 py-0.5 flex  items-center rounded-md">
                  <Checkbox onChange={(e)=>onCheckboxChange("req_expiry_date_notes",e)} checked={docCustomData.req_expiry_date_notes}/> Expiry Date Notes
                </li>
                <li className="h-10 px-1.5 py-0.5 flex  items-center rounded-md add_custom_field" onClick={(e)=>onCheckboxChange("addCustom")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M10 7.41v6.667m-3.333-3.333h6.666m5 0a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0z"
                      stroke="#5786CC"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>{" "}
                  Add custom
                </li>
              </ul>
            </Scrollbar>
          </div>
        </div>
      )}
    </div>
  );
}
export default Dropdown;
