
import * as actionType from "../constant/actionType";

const initialState = {
    allFunction: '', 
};

const AllFunctionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_ALL_FUNCTION:
      return {
        ...state,
        allFunction: action.payload,
      };
    default:
      return state;
  }
};

export default AllFunctionReducer;