// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const updateDefaultTabe = createSlice({
  name:"updateDefaultTabe",
  initialState:false,
  reducers:{
    UpdateinductionTab(state,action){
      return action.payload;
    },
    ResetUpdateinductionTab(){
      return null
    }
  }
}) 
export default updateDefaultTabe.reducer;
export const {ResetUpdateinductionTab,UpdateinductionTab}=updateDefaultTabe.actions;