import React from "react";
import Table from "../../../../../common/element/Table";
import Tag from "../../../../../common/element/Tag";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { redirectSpecWorker } from "../../../../../redux/classes/admin-worker/RedirectWorkerSlice";
import { SpecWorker } from "../../../../../redux/classes/admin-worker/SpecificWorkerData";
const { Tr, Th, Td, THead, TBody } = Table;
const ViewAllList = (props) => {
  const dispatch = useDispatch();
  // const workerData = useSelector((state) => state.redirectWorkerSpecificData); 
  const handleWorkerData = (workerData) => {
    console.log("props?.allWorkersDetails::",workerData)
    dispatch(redirectSpecWorker(workerData));
    dispatch(SpecWorker(workerData));
    
  };
  
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <span className="flex items-center gap-1" onClick={() => props.handleSort("first_name")}>
                  Worker name
                  <svg
                    className={`transform ${props.getAllinvitedData?.sort === "first_name" && props.getAllinvitedData?.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th>Job Title</Th>
              <Th>Provider</Th>
              <Th className="w-60">State</Th>
              <Th className="w-60">
                <span className="flex items-center gap-1">Contact no.</span>
              </Th>
            </Tr>
          </THead>
          <TBody>
            {props?.allWorkersDetails &&
              props?.allWorkersDetails.map((items, index) => (
                <Tr key={index}>
                  <Td>
                    <Link to={`specific-worker/${items?.worker_uuid ? items?.worker_uuid : ""}`}>
                      <span onClick={() => handleWorkerData(items)}>
                        {items?.workerName ? items?.workerName : '-'}
                      </span>
                    </Link>
                  </Td>
                  <Td>{items?.worker_job_title ? items?.worker_job_title : "-"}</Td>
                  <Td>{items?.workerProvider?.trading_name ? items?.workerProvider?.trading_name : "-"}</Td>
                  <Td>{items?.state_name ? items?.state_name : "-"}</Td>
                  <Td>{items?.phone ? items?.phone : "-"}</Td>
                </Tr>
              ))}
          </TBody>
        </Table>
      </div>
    </React.Fragment>
  );
};
export default ViewAllList;
