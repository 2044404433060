import React, { useEffect } from 'react'
import Heading from "../../../../common/element/Heading";

import { setBreadcrumbs } from "../../../../redux/classes/breadcrumbsSlice";
import { useDispatch } from "react-redux";
const ProviderProtalReporting = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                { label: "Reporting", url: "/provider/reporting", home: "/provider/dashboard" },
            ])
        );
    }, []);
    return (
        <React.Fragment>
            <div className="flex justify-between items-start mb-5">
                <div className="mb-2">
                    <Heading>Reporting</Heading>
                    <p className="text-gray-600">
                        {" "}
                        Non morbi vitae turpis leo scelerisque nisl phasellus nisi. Commodo.
                    </p>
                </div>

            </div>
        </React.Fragment>
    )
}

export default ProviderProtalReporting
