import React, { useState, useEffect, useCallback } from "react";
import Badge from "../../../../../../../common/element/Badge";
import Table from "../../../../../../../common/element/Table";
import Dialog from "../../../../../../../common/element/Dialog";
import InducteesInfo from "./SiteDetailsWorker/InducteesInfo"
import { GetWorkerProfileById } from "../../../../../../../actions/client-portal/AllContractorApi";
// import { GetWorkerProfileById } from "../../../../../../../actions/admin-provider/worker/GetWorkerProfileById";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const WorkersList = (props) => {
  const { Tr, Th, Td, THead, TBody } = Table;
  const dispatch = useDispatch();
  const [inducteesDialogIsOpen, setInducteesIsOpen] = useState(false);
  const [individual_uuid, setIndividual_uuid] = useState("");
  const [workerUuid, setWorkerUuid] = useState("")
  const [DetailData, setDetailData] = useState("");
  const [documnetSection, setDocumentSectin] = useState(true)
  const inducteesOpenDialog = (item) => {

    setIndividual_uuid(item);
    setWorkerUuid(item?.worker_uuid)
    dispatch(
      GetWorkerProfileById({
        individual_uuid: item?.individual_uuid,
      })
    ).then((response) => {
      if (response.success) {
        setDetailData(response?.data);
        setInducteesIsOpen(true);
      }
    });
  };
  const onInducteesDialogClose = () => {
    setInducteesIsOpen(false);
  };
  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-1/4">
                <span className="flex items-center gap-1" onClick={() => props?.handleSort("first_name")}>
                  Worker name
                  <svg
                    className={`transform ${props?.filtersort === "first_name" && props?.filterorder === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-64">Job title</Th>

              <Th className="w-40">Contact no.</Th>

              <Th className="w-36"> Status</Th>
            </Tr>
          </THead>
          <TBody>
            {props?.workerData ? (
              props.workerData.map((item) => (
                <Tr key={item.id}>
                  <Td>
                    {/* <Link to="../provider/specific-worker"> */}
                    <span onClick={() => inducteesOpenDialog(item)} className="cursor-pointer">
                      {`${item?.first_name ? item?.first_name : "-"} ${item?.last_name ? item?.last_name : ""}`}
                    </span>
                    {/* </Link> */}
                  </Td>

                  <Td onClick={() => inducteesOpenDialog(item)}>{item?.worker_job_title ? item?.worker_job_title : "-"}</Td>
                  <Td onClick={() => inducteesOpenDialog(item)}>{item?.phone ? item?.phone : "-"}</Td>
                  <Td>

                    {item?.status == "non-compliant" && (
                      <Badge
                        className="py-0.5 px-2 rounded-lg"
                        content={"Non-compliant"}
                        innerClass="text-red-600"
                      />
                    )}
                    {item?.status == "compliant" && (
                      <Badge
                        className="py-0.5 px-2 rounded-lg"
                        content={"Compliant"}
                        innerClass="text-success-600"
                      />
                    )}
                    {item?.status == null || undefined || "" && (
                      <Badge
                        className="py-0.5 px-2 rounded-lg"
                        content={"-"}

                      />
                    )}
                  </Td>
                </Tr>
              ))
            ) : null}
          </TBody>


        </Table>
      </div>
      <Dialog
        isOpen={inducteesDialogIsOpen}
        onClose={onInducteesDialogClose}
        onRequestClose={onInducteesDialogClose}
        bodyOpenClassName="overflow-hidden"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src="/assets/icons-svg/cons-worker.svg" alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              {DetailData?.first_name + " " + DetailData?.last_name}
            </h5>
            <p className="text-gray-600 ">Worker Details</p>
          </div>
        </div>

        <InducteesInfo
          trading_name={props?.trading_name}
          DetailData={DetailData}
          individual_uuid={individual_uuid}
          documnetSection={documnetSection}
          workerUuid={workerUuid}
        />
      </Dialog>
    </React.Fragment>
  );
};
export default WorkersList;
