import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import BtnLight from "../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../common/element/Buttons/btnPrimary";
import Upload from "../../../../../common/element/Upload";
import Validations from "../../../../../common/404/Validations";
import useUnauthorizedHandler from "../../../../../common/snippet/useUnauthorizedHandler";
import {
  CreateOrgNote,
  GetSpecificOrgNotes,
} from "../../../../../actions/admin-provider/AllNotesEmailSettingApi";

const AddNote = (props) => {
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const dispatch = useDispatch();
  const [noteData, setNotData] = useState({
    note_heading: "",
    note: "",
    // note_attach: ''
  });
  const [noteError, setNoteError] = useState({
    note_heading: "",
    note: "",
    note_attach: "",
  });
  const [attachment, setAttachment] = useState({ files: [] });
  // Extracting function assignment UUID from the Redux store
  //   const function_assignment_uuid = useSelector(
  //     (state) => state?.functionAssignmentUuid?.functionAssignmentUuid
  //   );
  const { SpecificProviderData } = useSelector((state) => state);

  const function_assignment_uuid = SpecificProviderData?.provider_fa_uuid;
  let trading_name = "provider trading corpo";
  // Handle input changes
  const inputChangeHandler = (e, field) => {
    let value = e.target.value;
    setNotData({ ...noteData, [field]: value });
  };

  const clearFormErrors = () => {
    setNoteError({
      note_heading: "",
      note: "",
      note_attach: "",
    });
  };
  const clearFormData = () => {
    setNotData({
      note_heading: "",
      note: "",
    });
  };

  // Handle file change in the attachment input
  const handleFileChange = (files) => {
    setAttachment({ files });
  };

  const handleFileRemove = (files) => {
    // Handle the file remove logic here
    setAttachment({ files });
  };

  // Calculate the total size of attachments
  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment.files.forEach((item) => {
      if (item.size) {
        totalSize += item?.size;
      }
    });
    return totalSize;
  };

  // Set a form error
  const setFormError = (field, message) => {
    setNoteError((prevFormErrors) => ({
      ...prevFormErrors,
      [field]: message,
    }));
  };
  // Handle form submission
  const submithandler = (e) => {
    e.preventDefault();

    const headValidation = Validations({
      value: noteData.note_heading,
      Validations: { required: true },
    });
    const contentValidation = Validations({
      value: noteData.note,
      Validations: { required: true },
    });
    if (!headValidation.valid) {
      setFormError("note_heading", headValidation.error_msg);
    }
    if (!contentValidation.valid) {
      setFormError("note", contentValidation.error_msg);
    }
    const totalSize = calculateTotalAttachmentSize();
    if (totalSize > 25 * 1024 * 1024) {
      setFormError("note_attach", "Total attachment size cannot exceed 25MB");
      return;
    }
    if (headValidation.valid === true && contentValidation) {
      const payload = {
        ...noteData,
        note_attach: attachment,
        function_assignment_uuid: function_assignment_uuid,
      };

      dispatch(CreateOrgNote(payload))
        .then((response) => {
          if (response.success) {
            props.onDialogClose();
            clearFormErrors();
            clearFormData();
            dispatch(GetSpecificOrgNotes(props.filterData)).then((response) => {
              if (response.success) {
                const arrayData = response.data.rows;
                props.updateDataFromApi(arrayData);
              }
            });
          } else if (response.status === 401) {
            handleUnauthorizedAccess();
          } else {
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <React.Fragment>
      <form>
        <div className="mt-6 add-client-form px-6">
          <div className="flex justify-between">
            <div className="lavel-wd">
              <label className="text-gray-700 font-medium">Note heading*</label>
            </div>
            <div className="columns-input mb-5 ">
              <input
                type="text"
                placeholder=""
                className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={noteData.note_heading}
                onChange={(e) => inputChangeHandler(e, "note_heading")}
              />
              {!noteData.note_heading && noteError.note_heading && (
                <div className="text-red-500 text-sm ">
                  {noteError.note_heading}
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-between">
            <div className="lavel-wd">
              <label className="text-gray-700 font-medium">Note*</label>
            </div>
            <div className="columns-input mb-5">
              <textarea
                placeholder="Enter a note..."
                className="min-h06 resize-none input-shadow text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                value={noteData.note}
                onChange={(e) => inputChangeHandler(e, "note")}
              ></textarea>
              {!noteData.note && noteError.note && (
                <div className="text-red-500 text-sm ">{noteError.note}</div>
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="lavel-wd">
              <label className="text-gray-700 font-medium">
                Attachment (optional)
              </label>
            </div>
            <div className="columns-input">
              <div className="hideIcons wrap-doc">
                <Upload
                  draggable
                  className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
                  onChange={handleFileChange}
                  onFileRemove={handleFileRemove}
                  multiple={true} // Allow multiple file uploads
                  maxFileSize={25 * 1024 * 1024} // Max file size in bytes (25MB)
                >
                  <span className="text-blue-700 font-semibold">
                    Click to upload
                  </span>{" "}
                  <p className="text-gray-600">(max.25mb)</p>
                </Upload>
                {noteError.note_attach && (
                  <div className="text-red-500 text-sm ">
                    {noteError.note_attach}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex dailog-footer ">
          <BtnLight>Cancel</BtnLight>
          <BtnPrimary type="submit" className="ml-1.5" onClick={submithandler}>
            Create Note
          </BtnPrimary>
        </div>
      </form>
    </React.Fragment>
  );
};

export default AddNote;
