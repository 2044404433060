import { useNavigate } from "react-router-dom";

const useUnauthorizedHandler = () => {
  const navigate = useNavigate();

  const handleUnauthorizedAccess = () => {
    localStorage.clear();
    navigate('/Login');
  };

  return handleUnauthorizedAccess;
};

export default useUnauthorizedHandler;
