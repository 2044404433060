import React, { useEffect } from 'react';

const HelpScoutWorkerBeacon = ({ isOpen }) => {
  useEffect(() => {
    (function (e, t, n) {
      function a() {
        var e = t.getElementsByTagName("script")[0],
          n = t.createElement("script");
        n.type = "text/javascript";
        n.async = true;
        n.src = "https://beacon-v2.helpscout.net";
        e.parentNode.insertBefore(n, e);
      }
      if (e.Beacon = n = function (t, n, a) {
        e.Beacon.readyQueue.push({ method: t, options: n, data: a });
      }, n.readyQueue = [], "complete" === t.readyState) return a();
      e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, false);
    })(window, document, window.Beacon || function () { });

    window.Beacon('init', '196083aa-5c2c-4b9a-aa23-98b249efdade');

    return () => {
      window.Beacon('destroy');
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.Beacon('open');
    } else {
      window.Beacon('close');
    }
  }, [isOpen]);

  return null;
};

export default HelpScoutWorkerBeacon;
