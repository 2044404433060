import React, { useState } from "react"
import Dialog from "../../../../../../../common/element/Dialog/Dialog"
import Table from "../../../../../../../common/element/Table"
import moment from "moment";
const VisitorList = (props) => {
    const { Tr, Th, Td, THead, TBody } = Table;


    const data = [
        {
            actionMsg: "Sumit updated this task",
            userName: "John Taylor",
            dateTime: new Date('2024-06-15T09:24:00')
        },
        {
            actionMsg: "Alice created this task",
            userName: "Alice Johnson",
            dateTime: new Date('2024-06-10T08:15:30')
        },
        {
            actionMsg: "Bob assigned this task",
            userName: "Bob Smith",
            dateTime: new Date('2024-06-12T10:45:15')
        },
        {
            actionMsg: "Carla commented on this task",
            userName: "Carla Rivera",
            dateTime: new Date('2024-06-13T11:30:45')
        },
        {
            actionMsg: "David completed this task",
            userName: "David Lee",
            dateTime: new Date('2024-06-14T14:20:05')
        }
    ];

    return (
        <React.Fragment>
            <div className="overflow-x-auto">
                <div className="w-full inline-block align-middle">
                    <div className="overflow-hidden border rounded-lg border-gray-200">
                        <Table className="min-w-full divide-y divide-gray-200">
                            <THead className="bg-gray-100">
                                <Tr>
                                    <Th
                                        scope="col"
                                        className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 w-1/5"
                                    >
                                        <span className='flex items-center gap-1' onClick={() => props?.handleSort("first_name")}>Action
                                            <svg
                                                className={`transform ${props?.filtersort === "first_name" && props?.filterorder === "asc"
                                                    ? "rotate-180"
                                                    : ""
                                                    }`}
                                                width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></span>
                                    </Th>
                                    <Th
                                        scope="col"
                                        className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600  "
                                    >
                                        <span className="inline-flex items-center">
                                            User

                                        </span>
                                    </Th>

                                    <Th
                                        scope="col"
                                        className="bg-gray-100 px-4 py-3 font-medium text-left text-gray-600 w-1/5"
                                    >
                                        <span className='flex items-center gap-1' onClick={() => props?.handleSort("first_name")}>Timestamp
                                            <svg
                                                className={`transform ${props?.filtersort === "first_name" && props?.filterorder === "asc"
                                                    ? "rotate-180"
                                                    : ""
                                                    }`}
                                                width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8" stroke="#475467" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></span>
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody className="divide-y divide-gray-200">


                                {/* {props?.allListData && props?.allListData.map((item, index) => ( */}
                                {data && data.map((item, index) => (

                                    <Tr key={index}>
                                        <Td className="px-4 py-2 font-medium text-gray-900">
                                            <span className="text-gray-900 font-medium block">{item?.actionMsg ? item.actionMsg : "-"}</span>
                                        </Td>
                                        <Td className="px-4 py-2 font-medium text-gray-900 ">
                                        {/* <span className="cursor-pointer">{`${item?.first_name ? item?.first_name : "-"} ${item?.last_name ? item?.last_name : ""}`}</span> */}
                                        <span className="cursor-pointer">{item?.userName?item?.userName:"-"}</span>
                                        </Td>
                                        <Td className="px-4 py-2 font-medium text-gray-900">{item?.dateTime ? moment(item?.dateTime).format("HH:mm DD-MM-YY") : "-"}</Td>
                                        
                                    </Tr>
                                     ))}
                             {/* ))} */}

                            </TBody>
                        </Table>
                    </div>
                </div>
            </div>
         
        </React.Fragment>
    )
}
export default VisitorList