import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import ScrollBar from "../../../../../../../common/element/ScrollBar";
import Label from "../../../../../../../common/element/Label";
import { useParams } from "react-router-dom";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import { notify } from "react-notify-toast";
import ThreeDotLoader from "../../../../../../../common/element/ButtonLoader/ThreeDotLoader";
import * as userActions from "../../../../../../../actions/index";
import AlertPage from "../../../../../../../common/snippet/AlertPage";
import moment from "moment";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
const ComplianceChecklist = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [clientDetailUuid, setClientDetailUuid] = useState("");
  const [reviewDate, setReviewDate] = useState("");
  const [showAlert, setShowAlert] = useState("");

  const openDialogUnSave = () => {
    if(!buttonDisabled){
      props.userSettingHide();
    }
  };
const handleDate=(e)=>{
  setReviewDate(e?.target?.value)
}
  const GetClientOtherDetailsById = useCallback(async () => {
    dispatch(userActions.GetClientOtherDetailsById(id))
    .then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data;
        // const revDate = moment(arrayData?.review_comp_checklist).format(
        //   "DD/MM/YYYY"
        // );
        // setReviewDate(revDate);
        const revDate = moment(arrayData?.review_comp_checklist).format("YYYY-MM-DD");
        setReviewDate(revDate);
        setClientDetailUuid(arrayData?.client_detail_uuid);
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      } else {
      }
    }).catch((error) => {
      console.error('Error:', error);
      });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    GetClientOtherDetailsById();
  }, []);

  const submitDate = (e) => {
    const payload = {
      client_detail_uuid: clientDetailUuid,
      // review_comp_checklist_date: reviewDate,
      review_comp_checklist: reviewDate
    };
    
    setButtonDisabled(true)
    props?.onUpdateComplianceChecklistReviewDate(payload)
      .then((response) => {
        if (response?.success === true) {
          setButtonDisabled(false);
          openDialogUnSave()
          // setShowAlert({
          //   type: 'success',
          //   title: response?.data,
          //   showIcon: true,
          //   content: response?.data,
          //   closable: true
          // });
          // props.userSettingHide();
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else { }
      }).catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  return (
    <React.Fragment>
      {/* {showAlert && 
      <AlertPage 
      showIcon={showAlert.showIcon} 
      type={showAlert.type} 
      title={showAlert.title} 
      content={showAlert.content}
      triggerByToast={showAlert.triggerByToast}
      closable={showAlert.closable}
      >
      </AlertPage>} */}
      <div className="h-500 pr-1">
        <ScrollBar>
          <div className="pr-4 mt-7">
            <div className="flex flex-wrap">
              <div className="w-1/3">
                <Label>
                  How often do you want to be reminded to review your Compliance
                  Checklist?
                </Label>
              </div>
              <div className="w-2/3">
                <input
                  className="pl-4 input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="date"
                  placeholder="Select date"
                  value={reviewDate}
                  onChange={handleDate}
                />
              </div>
            </div>
          </div>
        </ScrollBar>
      </div>
      <div className="flex p-6 justify-end border-gray-200">
        <BtnLight
          onClick={() => openDialogUnSave()}
          className="w-auto px-5 mr-3"
          variant="plain"
          disabled={buttonDisabled}
        >
          Close
        </BtnLight>
        <BtnPrimary  disabled={buttonDisabled} onClick={() => submitDate()} className="w-auto px-5">
          {buttonDisabled ? <ThreeDotLoader /> : 'Save changes'}
        </BtnPrimary>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    loader: userActions.UpdateComplianceChecklistReviewDate.loader,
  };
}
const mapDispatchToProps = {
  onUpdateComplianceChecklistReviewDate:
    userActions.UpdateComplianceChecklistReviewDate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplianceChecklist);
