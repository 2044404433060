import axios from "axios";
import * as actionType from "../../constant/actionType";

export const SignupProviderForYourself = (ApiData) => async (dispatch) => {
  try {
    let Data = ApiData;
    delete ApiData.created_by;
    delete ApiData.updated_by;
    delete ApiData.deleted_by;
    delete ApiData.deleted_date;
    delete ApiData.created_date;
    delete ApiData.provider_org_detail_uuid;
    delete ApiData.updated_date;
    delete ApiData.temp_msa_doc;
    delete ApiData.temp_engagement_doc;
    let formdata = new FormData();
    // for (let key in Data) {
    //   if (key == "provider_regist_data") {
    //     // formdata.append(key,JSON.stringify(Data[key]));
    //     for (let innerData in Data[key]) {
    //       if (innerData == "address_data" || innerData == "individual_data") {
    //         formdata.append(innerData, JSON.stringify(Data[key]));
    //       } else {
    //         formdata.append(innerData, Data[key]);
    //       }
    //     }
    //   } else {
    //     if (key == "user_uuid" || key == "invite_provider_uuid") {
    //       formdata.append(key, Data[key] == null ? "" : Data[key]);
    //     } else {
    //       formdata.append(key, Data[key]);
    //     }
    //   }
    // }
    // for (let key in Data) {
    //   if (key == "provider_regist_data") {
    //     for (let innerData in Data[key]) {
    //       if (innerData == "address_data" || innerData == "individual_data") {
    //         formdata.append(innerData, JSON.stringify(Data[key][innerData]));
    //       } else {
    //         formdata.append(innerData, Data[key][innerData]);
    //       }
    //     }
    //   } else {
    //     if (key == "user_uuid" || key == "invite_provider_uuid") {
    //       formdata.append(key, Data[key] == null ? "" : Data[key]);
    //     } else {
    //       formdata.append(key, Data[key]);
    //     }
    //   }
    // }

    for (let key in Data) {
      if (key == "provider_regist_data") {
        // Create a new object to store the formatted data
        // let providerData = {};

        for (let innerData in Data[key]) {
          if (innerData == "address_data" || innerData == "individual_data") {
            // providerData[innerData] = JSON.stringify(Data[key][innerData]);
            formdata.append(
              innerData,
              Data[key] == null ? "" : JSON.stringify(Data[key][innerData])
            );
          } else {
            // providerData[innerData] = Data[key][innerData];
            if (
              innerData == "msa_info" ||
              innerData == "client_contact_data" ||
              innerData == "whs_contact_info"
            ) {
              formdata.append(innerData, JSON.stringify(Data[key][innerData]));
            } else {
              formdata.append(innerData, Data[key][innerData]);
            }
          }
        }

        // Append the entire provider_regist_data object as a JSON string
        // formdata.append(key, JSON.stringify(providerData));
      } else {
        if (key == "user_uuid" || key == "invite_provider_uuid") {
          formdata.append(key, Data[key] == null ? "" : Data[key]);
        } else {
          if (key == "tempMsaDoc") {
            formdata.append("msaDoc", Data[key]);
          } else if (key == "tempEngagementDoc") {
            formdata.append("engagementDoc", Data[key]);
          } else {
            formdata.append(key, Data[key]);
          }
        }
      }
    }

    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/SignupProviderForYourself`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
