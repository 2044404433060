import axios from "axios";
import * as actionType from "../../constant/actionType";
import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetAllContactsOfContractorSlice";
export const GetAllContactsOfProvider = (apiData,singleSegmentValue) => async (dispatch) => {
    /*** API Call Action ***/
    let apiPath=singleSegmentValue[0]=="all"?"GetAllContactsOfProvider":"GetProviderInternalOrClientContacts"
    try {
        dispatch(fetchData())
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/${apiPath}`,
        params: apiData
      });
      dispatch(fetchDataSuccess(response.data))
      
    } catch (error) {
        dispatch(fetchDataError(error.message))  
    }
  };