import BtnLight from '../../../../../../common/element/Buttons/btnLight'
import Table from "../../../../../../common/element/Table"
import Tag from "../../../../../../common/element/Tag"
import Scrollbar from "../../../../../../common/element/ScrollBar"
import Card from "../../../../../../common/element/Card"

const Billing = (props) => {
  const { Tr, Th, Td, THead, TBody } = Table
  return (
    <Card className='py-5 border border-gray-200 rounded-xl dark-shadow'>

      <div className="px-5">
        <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-3">
          <div className="flex items-center">
            <span className="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M9.35156 14.6667C9.35156 15.9553 10.3962 17 11.6849 17H13.8516C15.2323 17 16.3516 15.8807 16.3516 14.5C16.3516 13.1193 15.2323 12 13.8516 12H11.8516C10.4709 12 9.35156 10.8807 9.35156 9.5C9.35156 8.11929 10.4709 7 11.8516 7H14.0182C15.3069 7 16.3516 8.04467 16.3516 9.33333M12.8516 5.5V7M12.8516 17V18.5M22.8516 12C22.8516 17.5228 18.3744 22 12.8516 22C7.32871 22 2.85156 17.5228 2.85156 12C2.85156 6.47715 7.32871 2 12.8516 2C18.3744 2 22.8516 6.47715 22.8516 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg></span>
            <p></p>
            <div className="flex flex-col"><span className="text-gray-900 text-base font-semibold">Invoices</span><p className="text-gray-600">Due in the next 30 days </p></div>
          </div>
          <BtnLight onClick={() => props?.setDefaultTab('tab6')} className="w-20 h-9">View All</BtnLight>
        </div>
      </div>

      <div className="h-64">
        <Scrollbar>
          <div className="px-5">
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <Table>
                <TBody>
                  <Tr>
                    <Td>[Name of Recipient]</Td>
                    <Td><Tag className="bg-error-100 text-error-700"><span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <g clip-path="url(#clip0_5886_87224)">
                          <path d="M6 4V6M6 8H6.005M1 4.26137V7.73863C1 7.86092 1 7.92207 1.01382 7.97962C1.02606 8.03063 1.04627 8.07941 1.07368 8.12414C1.1046 8.1746 1.14784 8.21784 1.23431 8.30431L3.69569 10.7657C3.78216 10.8522 3.8254 10.8954 3.87586 10.9263C3.92059 10.9537 3.96937 10.9739 4.02038 10.9862C4.07793 11 4.13908 11 4.26137 11H7.73863C7.86092 11 7.92207 11 7.97962 10.9862C8.03063 10.9739 8.07941 10.9537 8.12414 10.9263C8.1746 10.8954 8.21784 10.8522 8.30431 10.7657L10.7657 8.30431C10.8522 8.21784 10.8954 8.1746 10.9263 8.12414C10.9537 8.07941 10.9739 8.03063 10.9862 7.97962C11 7.92207 11 7.86092 11 7.73863V4.26137C11 4.13908 11 4.07793 10.9862 4.02038C10.9739 3.96937 10.9537 3.92059 10.9263 3.87586C10.8954 3.8254 10.8522 3.78216 10.7657 3.69569L8.30431 1.23431C8.21784 1.14784 8.1746 1.1046 8.12414 1.07368C8.07941 1.04627 8.03063 1.02606 7.97962 1.01382C7.92207 1 7.86092 1 7.73863 1H4.26137C4.13908 1 4.07793 1 4.02038 1.01382C3.96937 1.02606 3.92059 1.04627 3.87586 1.07368C3.8254 1.1046 3.78216 1.14784 3.69569 1.23431L1.23431 3.69569C1.14784 3.78216 1.1046 3.8254 1.07368 3.87586C1.04627 3.92059 1.02606 3.96937 1.01382 4.02038C1 4.07793 1 4.13908 1 4.26137Z" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath id="clip0_5886_87224">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>Overdue</Tag>
                    </Td>
                    <Td>DD/MM/YYYY</Td>
                  </Tr>
                  <Tr>
                    <Td>[Name of Recipient]</Td>
                    <Td><Tag className="bg-error-100 text-error-700"><span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <g clip-path="url(#clip0_5886_87224)">
                          <path d="M6 4V6M6 8H6.005M1 4.26137V7.73863C1 7.86092 1 7.92207 1.01382 7.97962C1.02606 8.03063 1.04627 8.07941 1.07368 8.12414C1.1046 8.1746 1.14784 8.21784 1.23431 8.30431L3.69569 10.7657C3.78216 10.8522 3.8254 10.8954 3.87586 10.9263C3.92059 10.9537 3.96937 10.9739 4.02038 10.9862C4.07793 11 4.13908 11 4.26137 11H7.73863C7.86092 11 7.92207 11 7.97962 10.9862C8.03063 10.9739 8.07941 10.9537 8.12414 10.9263C8.1746 10.8954 8.21784 10.8522 8.30431 10.7657L10.7657 8.30431C10.8522 8.21784 10.8954 8.1746 10.9263 8.12414C10.9537 8.07941 10.9739 8.03063 10.9862 7.97962C11 7.92207 11 7.86092 11 7.73863V4.26137C11 4.13908 11 4.07793 10.9862 4.02038C10.9739 3.96937 10.9537 3.92059 10.9263 3.87586C10.8954 3.8254 10.8522 3.78216 10.7657 3.69569L8.30431 1.23431C8.21784 1.14784 8.1746 1.1046 8.12414 1.07368C8.07941 1.04627 8.03063 1.02606 7.97962 1.01382C7.92207 1 7.86092 1 7.73863 1H4.26137C4.13908 1 4.07793 1 4.02038 1.01382C3.96937 1.02606 3.92059 1.04627 3.87586 1.07368C3.8254 1.1046 3.78216 1.14784 3.69569 1.23431L1.23431 3.69569C1.14784 3.78216 1.1046 3.8254 1.07368 3.87586C1.04627 3.92059 1.02606 3.96937 1.01382 4.02038C1 4.07793 1 4.13908 1 4.26137Z" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath id="clip0_5886_87224">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>Overdue</Tag>
                    </Td>
                    <Td>DD/MM/YYYY</Td>
                  </Tr>
                  <Tr>
                    <Td>[Name of Recipient]</Td>
                    <Td><Tag className="bg-warning-100 text-warning-700"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                      <g clip-path="url(#clip0_5799_40401)">
                        <path d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7" stroke="#F79009" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5799_40401">
                          <rect width="12" height="12" fill="white" transform="translate(0.851562)" />
                        </clipPath>
                      </defs>
                    </svg></span>Upcoming</Tag>
                    </Td>
                    <Td>DD/MM/YYYY</Td>
                  </Tr>
                  <Tr>
                    <Td>[Name of Recipient]</Td>
                    <Td><Tag className="bg-warning-100 text-warning-700"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                      <g clip-path="url(#clip0_5799_40401)">
                        <path d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7" stroke="#F79009" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5799_40401">
                          <rect width="12" height="12" fill="white" transform="translate(0.851562)" />
                        </clipPath>
                      </defs>
                    </svg></span>Upcoming</Tag>
                    </Td>
                    <Td>DD/MM/YYYY</Td>
                  </Tr>
                  <Tr>
                    <Td>[Name of Recipient]</Td>
                    <Td><Tag className="bg-warning-100 text-warning-700"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                      <g clip-path="url(#clip0_5799_40401)">
                        <path d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7" stroke="#F79009" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5799_40401">
                          <rect width="12" height="12" fill="white" transform="translate(0.851562)" />
                        </clipPath>
                      </defs>
                    </svg></span>Upcoming</Tag>
                    </Td>
                    <Td>DD/MM/YYYY</Td>
                  </Tr>
                  <Tr>
                    <Td>[Name of Recipient]</Td>
                    <Td><Tag className="bg-warning-100 text-warning-700"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                      <g clip-path="url(#clip0_5799_40401)">
                        <path d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7" stroke="#F79009" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5799_40401">
                          <rect width="12" height="12" fill="white" transform="translate(0.851562)" />
                        </clipPath>
                      </defs>
                    </svg></span>Upcoming</Tag>
                    </Td>
                    <Td>DD/MM/YYYY</Td>
                  </Tr>
                  <Tr>
                    <Td>[Name of Recipient]</Td>
                    <Td><Tag className="bg-warning-100 text-warning-700"><span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                      <g clip-path="url(#clip0_5799_40401)">
                        <path d="M12.2015 5.75L11.2018 6.75L10.2015 5.75M11.3241 6.5C11.3422 6.33583 11.3516 6.169 11.3516 6C11.3516 3.51472 9.33684 1.5 6.85156 1.5C4.36628 1.5 2.35156 3.51472 2.35156 6C2.35156 8.48528 4.36628 10.5 6.85156 10.5C8.2652 10.5 9.52658 9.84817 10.3516 8.82867M6.85156 3.5V6L8.35156 7" stroke="#F79009" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5799_40401">
                          <rect width="12" height="12" fill="white" transform="translate(0.851562)" />
                        </clipPath>
                      </defs>
                    </svg></span>Upcoming</Tag>
                    </Td>
                    <Td>DD/MM/YYYY</Td>
                  </Tr>
                </TBody>
              </Table>
            </div>
          </div>
        </Scrollbar>

      </div>
    </Card>
  )
}
export default Billing 