import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { debounce } from "../../../../../../../common/snippet/Debouncing";
import Switcher from "../../../../../../../common/element/Switcher";
import ScrollBar from "../../../../../../../common/element/ScrollBar";
import SmallButton from "../../../../../../../common/element/Buttons/btnPrimary";
import BtnLight from "../../../../../../../common/element/Buttons/btnLight";
// import { notify } from "react-notify-toast";
import { useParams } from "react-router-dom";
import Validations from "../../../../../../../common/404/Validations";
import * as userActions from "../../../../../../../actions/index";
import Select from "../../../../../../../common/element/Select";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import Dialog from "../../../../../../../common/element/Dialog";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
const AddUser = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState();
  const { id } = useParams();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [showUserName, setUserName] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [individualUuid, setIndividualUuid] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [IsOpenInvite, setIsOpenInvite] = useState(false);
  const [allPermissionsTrue, setAllPermissionsTrue] = useState(false);
  const [readOnlyAll, setReadOnlyAll] = useState(false)
  const [allowAll, setAllowAll] = useState(false);
  const [enteruserEmail, setEnterUserEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();

  const [contactTypeUuid, setContactTypeUuid] = useState("");
  const [userUuid, setUserUuid] = useState('');
  const [callEmailAPI, setEmailAPI] = useState(true);
  const [permission, setPermissions] = useState({
    // client_view:false,
    // client_write:false,
    compliance_view: false,
    compliance_write: false,
    contacts_view: false,
    contacts_write: false,
    provider_view: false,
    provider_write: false,
    // documents_view:false,
    // documents_write: false
    induction_view: false,
    induction_write: false,
    invoices_view: false,
    invoices_write: false,
    reporting_view: false,
    reporting_write: false,
    settings_view: false,
    settings_write: false,
    sites_view: false,
    sites_write: false,
    users_view: false,
    users_write: false,
    messages_view: false,
    messages_write: false,
    dashboard_view: false,
    dashboard_write: false,
    notes_view: false,
    notes_write: false,
    support_view: false,
    support_write: false,
    // workers_view: false,
    // workers_write: false,
  });
  const requiredKeys = [
    'compliance_view',
    'compliance_write',
    'contacts_view',
    'contacts_write',
    'provider_view',
    'provider_write',
    'induction_view',
    'induction_write',
    'invoices_view',
    'invoices_write',
    'reporting_view',
    'reporting_write',
    'settings_view',
    'settings_write',
    'sites_view',
    'sites_write',
    'users_view',
    'users_write',
    'messages_view',
    'messages_write',
    'dashboard_view',
    'dashboard_write',
    'notes_view',
    'notes_write',
    'support_view',
    'support_write',
  ];
  const onDialogCloseInvite = () => {
    setIsOpenInvite(false);
    props?.addUserClose();
  }
  const functionAssignmentUuid = useSelector(
    (state) => state.functionAssignmentUuid.functionAssignmentUuid
  );
  const AllContactType = useSelector((state) => state?.AllContactTypes);

  // useEffect(() => {
  //   if (props?.buttonDisabled == true) {
  //     props?.setButtonDisabled(false)
  //   }
  // }, [props?.buttonDisabled==true])
  useEffect(() => {
    if (AllContactType) {
      const contactTypeUuid = AllContactType.find(
        (item) => item.contact_type === "client contact"
      )?.contact_type_uuid;

      setContactTypeUuid(contactTypeUuid);
    }
  }, []);
  // Function to handle opening/closing accordions
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const areAllPermissionsTrue = (permData) => {
    // Check if permData is an object and has keys
    if (typeof permData !== 'object' || permData === null || Object.keys(permData).length === 0) {
      return false;
    }
    // Filter and reduce to get the relevant permissions
    const filtered = Object.keys(permData)
      .filter(key => requiredKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = permData[key];
        return obj;
      }, {});

    // Check if all values in filtered are true
    return Object.values(filtered).every(value => value === true);
  };

  // Function to toggle view setting
  const onSwitcherToggle = (e, identifier) => {
    setPermissions({ ...permission, [identifier]: !e });
    if (identifier === "settings_view_write") {
      setPermissions({ ...permission, settings_view: !e, settings_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, settings_view: !e, settings_write: !e, }))
    }
    if (identifier === "compliance_view_write") {
      setPermissions({ ...permission, compliance_view: !e, compliance_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, compliance_view: !e, compliance_write: !e, }))
    }
    if (identifier === "users_view_write") {
      setPermissions({ ...permission, users_view: !e, users_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, users_view: !e, users_write: !e, }))
    }
    if (identifier === "contacts_view_write") {
      setPermissions({ ...permission, contacts_view: !e, contacts_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, contacts_view: !e, contacts_write: !e, }))
    }
    if (identifier === "invoices_view_write") {
      setPermissions({ ...permission, invoices_view: !e, invoices_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, invoices_view: !e, invoices_write: !e, }))
    }
    if (identifier === "induction_view_write") {
      setPermissions({ ...permission, induction_view: !e, induction_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, induction_view: !e, induction_write: !e, }))
    }
    if (identifier === "contractor_view_write") {
      setPermissions({ ...permission, provider_view: !e, provider_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, provider_view: !e, provider_write: !e, }))
    }
    if (identifier === "reporting_view_write") {
      setPermissions({ ...permission, reporting_view: !e, reporting_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, reporting_view: !e, reporting_write: !e, }))
    }
    if (identifier === "sites_view_write") {
      setPermissions({ ...permission, sites_view: !e, sites_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, sites_view: !e, sites_write: !e, }))
    }
    if (identifier === "messages_view_write") {
      setPermissions({ ...permission, messages_view: !e, messages_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, messages_view: !e, messages_write: !e, }))
    }
    if (identifier === "dashboard_view_write") {
      setPermissions({ ...permission, dashboard_view: !e, dashboard_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, dashboard_view: !e, dashboard_write: !e, }))
    }

    if (identifier === "notes_view_write") {
      setPermissions({ ...permission, notes_view: !e, notes_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, notes_view: !e, notes_write: !e, }))
    }
    if (identifier === "support_view_write") {
      setPermissions({ ...permission, support_view: !e, support_write: !e, })
      setAllPermissionsTrue(areAllPermissionsTrue({ ...permission, support_view: !e, support_write: !e, }))
    }
    if (identifier === "all") {
      setAllowAll((e) => !e);
      setReadOnlyAll(false);
      setPermissions({
        ...permission,
        compliance_view: !e,
        compliance_write: !e,
        contacts_view: !e,
        contacts_write: !e,
        provider_view: !e,
        provider_write: !e,
        induction_view: !e,
        induction_write: !e,
        invoices_view: !e,
        invoices_write: !e,
        reporting_view: !e,
        reporting_write: !e,
        settings_view: !e,
        settings_write: !e,
        sites_view: !e,
        sites_write: !e,
        users_view: !e,
        users_write: !e,
        messages_view: !e,
        messages_write: !e,
        dashboard_view: !e,
        dashboard_write: !e,
        notes_view: !e,
        notes_write: !e,
        support_view: !e,
        support_write: !e,

      })
      setAllPermissionsTrue(prevState => !prevState)
    }
    if (identifier === "realAll") {
      if (allowAll == true) {
        setAllPermissionsTrue(prevState => !prevState)
      }
      setReadOnlyAll(!readOnlyAll); // Toggle readOnlyAll state
      setAllowAll(false);
      setPermissions({
        ...permission,
        compliance_view: !readOnlyAll,
        compliance_write: false,
        contacts_view: !readOnlyAll,
        contacts_write: false,
        provider_view: !readOnlyAll,
        provider_write: false,
        induction_view: !readOnlyAll,
        induction_write: false,
        invoices_view: !readOnlyAll,
        invoices_write: false,
        reporting_view: !readOnlyAll,
        reporting_write: false,
        settings_view: !readOnlyAll,
        settings_write: false,
        sites_view: !readOnlyAll,
        sites_write: false,
        users_view: !readOnlyAll,
        users_write: false,
        messages_view: !readOnlyAll,
        messages_write: false,
        dashboard_view: !readOnlyAll,
        dashboard_write: false,
        notes_view: !readOnlyAll,
        notes_write: false,
        support_view: !readOnlyAll,
        support_write: false
      });
    }
  };
  function isEmail(value) {
    if (value !== undefined && value !== "") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    }
    return false;
}
  // Handle search input change for email
  // const handleSearchChange = (newInputValue) => {
  //   setSearch(newInputValue);
  //   if (newInputValue?.trim()?.length >= 3) {
  //     setUserName(true);
  //     return;
  //   }
  // };
  // // Handle search input change for email
  // const inputchangeSearch = useCallback(debounce(handleSearchChange), []);



  // Handle search input change for email
  const handleSearchChange = debounce((newInputValue) => {
    setSearch(newInputValue?.trim());
    newInputValue.length>0 &&  setEmailError("");
   
    if (newInputValue?.trim().length >= 3) {
      setUserName(true);
      return;
    }
  }, 500)
  useEffect(() => {
    if (firstName) {
      setFirstNameError("");
    }

    if (lastName) {
      setLastNameError("");
    }
  }, [firstName, lastName]);

  useEffect(() => {
    if (search && search?.trim()?.length >= 3) {
      // setUserName(true);
      GetAllIndividualListForInvite(search);
    }
  }, [search]);

  // Handle the select option change
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if(isEmail(selectedOption?.label.trim())){
      setEnterUserEmail(selectedOption?.label.trim());
      if (selectedOption?.individualUuid) {
        setIndividualUuid(selectedOption?.individualUuid);
        setFirstName(selectedOption?.firstName);
        setLastName(selectedOption?.lastName);
        setUserUuid(selectedOption?.user_uuid);
      } else {
        setIndividualUuid("");
        setFirstName("");
        setLastName("");
      }
      setEmailError("");
      props?.setButtonDisabled(false)
      setEmailAPI(true)

    }

    else{
      setSearch("")
      setSelectedOption("")
      if(selectedOption?.label !==""){
        setEmailError("Email format is incorrect.")
      }
    }
  };
  // Create a custom option for the Select component
  const customOption = { value: search, label: search };
  const updatedOptions = searchOptions.find((option) => option.value === search)
    ? searchOptions
    : [...searchOptions, customOption];
  // Handle input changes
  const inputChangeHandler = (e, identifier) => {
    if (identifier === "firstName") {
      setFirstName(e.target.value);
    }
    if (identifier === "lastName") {
      setLastName(e.target.value);
    }
  };

  // Invite & Add individual conditions
  // 1-> New individual invited
  // 2-> individual is superadmin
  // 3-> individual is already invited under same clinet and exist as user for ---------- handled at backend 
  // 4-> individual is exist as user under different client & getting invited under this client also
  // 5-> invitation was sent to individual some time ago when it was not exist as user & when it open the mail it has already become user under another organization 

  const GetAllIndividualListForInvite = useCallback(async () => {
    dispatch(userActions.GetAllIndividualListForInvite(search)).then(
      (response) => {
        if (response?.success === true) {
          const arrayData = response?.data;
          let userData = [];
          for (let key in arrayData) {
            userData.push({
              label: arrayData[key]?.email,
              value: arrayData[key]?.email,
              individualUuid: arrayData[key]?.individual_uuid,
              firstName: arrayData[key]?.first_name,
              lastName: arrayData[key]?.last_name,
              user_uuid: arrayData[key]?.user_uuid,
            });
          }
          // below condition still need to clarify
          // if user_uuid === null then it is not a user & if user_uuid !== null then it is user

          setSearchOptions(userData);
          setEmailError('');
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        } else {
        }
      }
    );
    // eslint-disable-next-line
  }, [dispatch, search]);

  const GetIndividualByEmail = async () => {
    return new Promise(async (resolve) => {

      if (enteruserEmail) {
        // props?.onGetIndividualByEmail(enteruserEmail).then((response) => {
        const response = await props?.onGetIndividualByEmail(enteruserEmail);
        if (response?.success === true) {
          const arrayData = response?.data;
          if (arrayData && arrayData?.is_conserve_team === true) {
            setEmailAPI(true)
            setEmailError("This email can not be selected");
            props?.setButtonDisabled(false)
          } else {
            setEmailAPI(false)
          }
          resolve(); // Resolve the promise once the function completes
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
          props?.setButtonDisabled(false)

        }
      } else {
        props?.setButtonDisabled(false)
        setEmailAPI(false)
        const emailValidation = Validations({
          value: enteruserEmail,
          Validations: { required: true, email: true },
        });
        if (!emailValidation.valid) {
          setEmailError(emailValidation.error_msg);
        }
        resolve();
      }
    }).catch((error) => {

      props?.setButtonDisabled(false)

    }).finally(() => {
      props?.setButtonDisabled(false)
    })
  };


  const submitUser = async (e) => {
    e.preventDefault();
    if (callEmailAPI == false) {
      setEmailAPI(true)
    }
    props?.setButtonDisabled(true);
    await GetIndividualByEmail();
  };

  const submitUserAPI = () => {
    if (emailError === "" && callEmailAPI === false) {
      //Validate
      const emailValidation = Validations({
        value: enteruserEmail,
        Validations: { required: true, email: true },
      });
      const firstNameValidation = Validations({
        value: firstName,
        Validations: { required: true },
      });
      const lastNameValidation = Validations({
        value: lastName,
        Validations: { required: true },
      });

      if (!emailValidation.valid) {
        setEmailError(emailValidation.error_msg);
      }
      if (!firstNameValidation.valid) {
        setFirstNameError(firstNameValidation.error_msg);
      }
      if (!lastNameValidation.valid) {
        setLastNameError(lastNameValidation.error_msg);
      }

      if (
        emailValidation.valid === true &&
        firstNameValidation.valid === true &&
        lastNameValidation.valid === true
      ) {
        const payload = {
          organisation_uuid: id ? id : "",
          individual_uuid: individualUuid ? individualUuid : "",
          function_assignment_uuid: functionAssignmentUuid,
          trading_name: props?.tradingName,
          email: enteruserEmail,
          first_name: firstName,
          last_name: lastName,
          contact_type_uuid: contactTypeUuid,
          user_uuid: userUuid ? userUuid : '',
          user_perm_data: JSON.stringify({
            // view_setting: true,
            compliance_view: permission.compliance_write ? true : permission.compliance_view,
            compliance_write: permission.compliance_write,
            contacts_view: permission.contacts_write ? true : permission.contacts_view,
            contacts_write: permission.contacts_write,
            provider_view: permission.contacts_write ? true : permission.provider_view,
            provider_write: permission.provider_write,
            induction_view: permission.induction_write ? true : permission.induction_view,
            induction_write: permission.induction_write,
            invoices_view: permission.invoices_write ? true : permission.induction_view,
            invoices_write: permission.invoices_write,
            reporting_view: permission.reporting_write ? true : permission.reporting_view,
            reporting_write: permission.reporting_write,
            settings_view: permission.settings_write ? true : permission.settings_view,
            settings_write: permission.settings_write,
            sites_view: permission.sites_write ? true : permission.sites_view,
            sites_write: permission.sites_write,
            users_view: permission.users_write ? true : permission.users_view,
            users_write: permission.users_write,
            messages_view: permission?.messages_write ? true : permission?.messages_view,
            messages_write: permission?.messages_write,
            dashboard_view: permission?.dashboard_write ? true : permission?.dashboard_view,
            dashboard_write: permission?.dashboard_write,

            notes_view: permission?.notes_write ? true : permission?.notes_view,
            notes_write: permission?.notes_write,

            support_view: permission?.support_write ? true : permission?.support_view,
            support_write: permission?.support_write,

          }),
          invited_user_type: "client_user"
        };
        props?.onInviteOrgUser(payload)
          .then((response) => {
            if (response?.success === true) {
              setIsOpenInvite(true)
              props?.setButtonDisabled(false)
              setEnterUserEmail("");
              setFirstName("");
              setLastName("");
              setEmailError("");
              setFirstNameError("");
              setLastNameError("");
              // setTimeout(() => {
              //   props?.addUserClose();
              // }, 100)
            } else if (response?.status === 409) {
              props?.setButtonDisabled(false)
              setEmailError(response?.message)
            } else if (response?.status === 401) {
              handleUnauthorizedAccess()
              props?.setButtonDisabled(false)
            } else {
              props?.setButtonDisabled(false)

            }
          })
          .catch((error) => {
            props?.setButtonDisabled(false)

          }).finally(() => {
            props?.setButtonDisabled(false);
          });
      }
    }
    else {
      const emailValidation = Validations({
        value: enteruserEmail,
        Validations: { required: true, email: true },
      });
      if (!emailValidation.valid) {
        setEmailError(emailValidation.error_msg);
      }
      props?.setButtonDisabled(false);
      setEmailAPI(false)
    }
  }
  useEffect(() => {
    if (callEmailAPI === false) {
      submitUserAPI();
    }
  }, [callEmailAPI]);

  return (
    <React.Fragment>
      <div className="h-500">
        <ScrollBar>
          <div className="px-6 pb-3">
            <div className="flex flex-col">
              <label className="block text-gray-700  mb-2 text-xs font-medium">
                Add a user
              </label>
              <Select
                type="text"
                placeholder="Enter Contact's email address"
                className="w-full h-10 select-options"
                isClearable={true}
                isSearchable={true}
                options={updatedOptions ? updatedOptions : selectedOption}
                value={selectedOption || customOption}
                onChange={handleSelectChange}
                handleSearchChange={handleSearchChange}
              />
              {emailError && (
                <p className="text-xs text-red-600 mb-4 mt-0">{emailError}</p>
              )}
            </div>
            {showUserName && (
              <div className="flex mt-5">
                <div className="flex-1 mr-1">
                  <label className="block text-gray-700  mb-2 text-xs font-medium">
                    First name*
                  </label>
                  <input
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="firstName"
                    type="text"
                    placeholder="Enter Contact's first name"
                    value={firstName}
                    onChange={(e) => inputChangeHandler(e, "firstName")}
                    readOnly={individualUuid ? true : false}
                  ></input>
                  {firstNameError && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {firstNameError}
                    </p>
                  )}
                </div>
                <div className="flex-1 ml-1">
                  <label className="block text-gray-700  mb-2 text-xs font-medium">
                    Last name*
                  </label>
                  <input
                    className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="lastName"
                    type="text"
                    placeholder="Enter Contact's last name"
                    value={lastName}
                    onChange={(e) => inputChangeHandler(e, "lastName")}
                    readOnly={individualUuid ? true : false}
                  ></input>
                  {lastNameError && (
                    <p className="text-xs text-red-600 mb-4 mt-0">
                      {lastNameError}
                    </p>
                  )}
                </div>
              </div>
            )}
            <hr className="my-5 border-gray-200" />
            <div className="flex flex-col">
              <span className="text-base text-gray-900 font-semibold">
                User access
              </span>
              <p className="text-gray-600 mt-1">
                Grant the user full app access or customise their access to
                specific areas.
              </p>
            </div>

            <div className="flex justify-between gap-4 mb-5 px-4 mt-5">
              <div className="flex items-center w-1/2">
                <Switcher onChange={(e) => onSwitcherToggle(e, "all")} checked={allowAll && allPermissionsTrue} />
                <span className="text-xs text-gray-700 font-medium ml-2.5">
                  {allowAll ? "Full decline to all sections" : "Full access to all sections"}
                </span>
              </div>
              <div className="flex items-center w-1/2">
                <Switcher onChange={(e) => onSwitcherToggle(e, "realAll")} checked={readOnlyAll} />
                <span className="text-xs text-gray-700 font-medium ml-2.5">
                  {readOnlyAll ? "Read-only decline to all sections" : "Read-only access to all sections"}
                </span>
              </div>
            </div>


            <Accordion open={open === 11}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(11)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "dashboard_view_write")}
                    checked={permission && permission?.dashboard_view && permission?.dashboard_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Dashboard
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "dashboard_view")}
                      checked={permission?.dashboard_view}
                      value={permission?.dashboard_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "dashboard_write")}
                      checked={permission?.dashboard_write}
                      value={permission?.dashboard_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 1}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(1)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "settings_view_write")}
                    checked={permission && permission.settings_view && permission.settings_write ? true : false}
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Company Settings
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "settings_view")}
                      checked={permission.settings_view}
                      value={permission.settings_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "settings_write")}
                      checked={permission.settings_write}
                      value={permission.settings_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 2}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(2)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "compliance_view_write")}
                    checked={permission && permission.compliance_view && permission.compliance_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Compliance Checklist
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "compliance_view")}
                      checked={permission.compliance_view}
                      value={permission.compliance_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "compliance_write")}
                      checked={permission.compliance_write}
                      value={permission.compliance_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 3}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(3)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "users_view_write")}
                    checked={permission && permission.users_view && permission.users_write ? true : false}
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Users
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "users_view")}
                      checked={permission.users_view}
                      value={permission.users_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "users_write")}
                      checked={permission.users_write}
                      value={permission.users_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 4}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(4)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "contacts_view_write")}
                    checked={permission && permission.contacts_view && permission.contacts_write ? true : false}
                  />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Contacts
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "contacts_view")}
                      checked={permission.contacts_view}
                      value={permission.contacts_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "contacts_write")}
                      checked={permission.contacts_write}
                      value={permission.compliance_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 5}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(5)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "invoices_view_write")}
                    checked={permission && permission.invoices_view && permission.invoices_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Invoices
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "invoices_view")}
                      checked={permission.invoices_view}
                      value={permission.invoices_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "invoices_write")}
                      checked={permission.invoices_write}
                      value={permission.invoices_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 6}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(6)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "induction_view_write")}
                    checked={permission && permission.induction_view && permission.induction_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Induction
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "induction_view")}
                      checked={permission.induction_view}
                      value={permission.induction_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "induction_write")}
                      checked={permission.induction_write}
                      value={permission.induction_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 7}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(7)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "contractor_view_write")}
                    checked={permission && permission.provider_view && permission.provider_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Provider
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "provider_view")}
                      checked={permission.provider_view}
                      value={permission.provider_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "provider_write")}
                      checked={permission.provider_write}
                      value={permission.provider_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 8}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(8)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "sites_view_write")}
                    checked={permission && permission.sites_view && permission.sites_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Sites
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "sites_view")}
                      checked={permission.sites_view}
                      value={permission.sites_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "sites_write")}
                      checked={permission.sites_write}
                      value={permission.sites_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 9}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(9)}
              >
                <div className="flex items-center">
                  <Switcher
                    onChange={(e) => onSwitcherToggle(e, "messages_view_write")}
                    checked={permission && permission.messages_view && permission.messages_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Messages
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "messages_view")}
                      checked={permission.messages_view}
                      value={permission.messages_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "messages_write")}
                      checked={permission.messages_write}
                      value={permission.messages_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 10}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(10)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "reporting_view_write")}
                    checked={permission && permission?.reporting_view && permission?.reporting_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Reports
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "reporting_view")}
                      checked={permission?.reporting_view}
                      value={permission?.reporting_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "reporting_write")}
                      checked={permission?.reporting_write}
                      value={permission?.reporting_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 12}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(12)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "notes_view_write")}
                    checked={permission && permission?.notes_view && permission?.notes_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Notes
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "notes_view")}
                      checked={permission?.notes_view}
                      value={permission?.notes_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "notes_write")}
                      checked={permission?.notes_write}
                      value={permission?.notes_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 13}>
              <AccordionHeader
                className="reset-accordian-btn"
                onClick={() => handleOpen(13)}
              >
                <div className="flex items-center">
                  <Switcher onChange={(e) => onSwitcherToggle(e, "support_view_write")}
                    checked={permission && permission?.support_view && permission?.support_write ? true : false} />
                  <div className="ml-4">
                    <span className="text-gray-700 font-semibold block">
                      Support
                    </span>
                    <p className="text-gray-600">
                      {/* Supporting text lorem ipsum il alamoni pilmolo */}
                    </p>
                  </div>
                </div>
              </AccordionHeader>
              <AccordionBody className="accordion-body">
                <ul>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "support_view")}
                      checked={permission?.support_view}
                      value={permission?.support_view} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read
                    </span>
                  </li>
                  <li className="flex px-4 py-4">
                    <Switcher onChange={(e) => onSwitcherToggle(e, "support_write")}
                      checked={permission?.support_write}
                      value={permission?.support_write} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Read + Write
                    </span>
                  </li>
                  {/* <li className="flex px-4 py-4">
                    <Switcher onChange={onSwitcherToggle} />{" "}
                    <span className="text-gray-600 ml-4 font-semibold">
                      Accordion header
                    </span>
                  </li> */}
                </ul>
              </AccordionBody>
            </Accordion>
          </div>
        </ScrollBar>
      </div>
      <div className="flex dailog-footer mt-0">
        <BtnLight disabled={props?.buttonDisabled} className="mr-1.5" onClick={() => props?.addUserClose()}>Cancel</BtnLight>
        <SmallButton disabled={props?.buttonDisabled} type="submit" className="ml-1.5" onClick={submitUser}>
          {props?.buttonDisabled == true ? "Inviting..." : "Invite user"}

        </SmallButton>
      </div>
      {/*--------- Invitaion Sent Confirmation -------- */}
      <Dialog
        isOpen={IsOpenInvite}
        onClose={onDialogCloseInvite}
        onRequestClose={onDialogCloseInvite}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#101828"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <h4 className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </h4>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={() => onDialogCloseInvite()}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  onInviteOrgUser: userActions.InviteOrgUser,
  onGetIndividualByEmail: userActions.GetIndividualByEmail,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
