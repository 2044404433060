import * as actionType from "../constant/actionType";

const initial_state = {
  error: null,
  token: null,
  loader: false,
};

const reducer = (state = initial_state, action) => {
  switch (action.type) {
    case actionType.AddClient_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.AddClient_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.AddClient_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetAllParentClientList_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetAllParentClientList_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetAllParentClientList_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.AdminGetAllClients_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.AdminGetAllClients_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.AdminGetAllClients_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetClientOverviewProfileById_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetClientOverviewProfileById_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetClientOverviewProfileById_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.CreateComplianceDoc_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.CreateComplianceDoc_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.CreateComplianceDoc_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetAllDocumentTypeList_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetAllDocumentTypeList_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetAllDocumentTypeList_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetAllComplianceDocsOfClient_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetAllComplianceDocsOfClient_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetAllComplianceDocsOfClient_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.CreateOrgNote_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.CreateOrgNote_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.CreateOrgNote_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetSpecificOrgNotes_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetSpecificOrgNotes_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetSpecificOrgNotes_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetAllContractorPrimaryList_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetAllContractorPrimaryList_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetAllContractorPrimaryList_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.InviteSpecificContractor_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.InviteSpecificContractor_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.InviteSpecificContractor_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetAllIndividualListForContact_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetAllIndividualListForContact_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetAllIndividualListForContact_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.AddClientContact_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.AddClientContact_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.AddClientContact_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetAllInvitedContractorsOfClient_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetAllInvitedContractorsOfClient_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetAllInvitedContractorsOfClient_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetAllClientContacts_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetAllClientContacts_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetAllClientContacts_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetAllIndividualListForInvite_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetAllIndividualListForInvite_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetAllIndividualListForInvite_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.ReinviteOrgUser_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.ReinviteOrgUser_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.ReinviteOrgUser_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetSpecificClientDetailsById_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetSpecificClientDetailsById_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetSpecificClientDetailsById_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.UpdateClientDetails_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.UpdateClientDetails_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.UpdateClientDetails_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.UpdateOrgStatus_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.UpdateOrgStatus_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.UpdateOrgStatus_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.GetClientOtherDetailsById_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetClientOtherDetailsById_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetClientOtherDetailsById_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.UpdateClientAccreditations_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.UpdateClientAccreditations_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.UpdateClientAccreditations_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    case actionType.UpdateComplianceChecklistReviewDate_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.UpdateComplianceChecklistReviewDate_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.UpdateComplianceChecklistReviewDate_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };
    case actionType.GetOrgUserPermById_START:
      return {
        ...state,
        error: action.error,
        loader: action.loader,
      };
    case actionType.GetOrgUserPermById_SUCCESS:
      return {
        ...state,
        error: null,
        loader: false,
        token: action.token,
      };
    case actionType.GetOrgUserPermById_FAIL:
      return {
        ...state,
        loader: action.loader,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
