import React from "react";
import Select from "../../../../../common/element/Select";
import { components } from 'react-select'
import { HiCheck } from 'react-icons/hi'
const { Control } = components
const countryOptions = [
    { value: 'paid', label: 'Paid', imgPath: '' },
    { value: 'upcoming', label: 'Upcoming', imgPath: '/img/countries/cn.png' },
    { value: 'overdue', label: 'Overdue', imgPath: '/img/countries/jp.png' },
]
const colourOptions = [
    { value: 'all', label: 'All' },
    { value: 'paid', label: 'Paid' },
    { value: 'upcoming', label: 'Upcoming'},
    { value: 'overdue', label: 'Overdue' },

]
const CustomSelectOption = ({ innerProps, label, data, isSelected }) => {
    return (
        <div
            className={`flex items-center justify-between p-2 ${
                isSelected
                    ? 'bg-blue-50 rounded-lg'
                    : 'hover:bg-blue-50 rounded-lg'
            }`}
            {...innerProps}
        >
            <div className="flex items-center">
                <span shape="circle" size={20} src={data.imgPath} />
                <span className="ml-2 rtl:mr-2">{label}</span>
            </div>
            {isSelected && <HiCheck className="text-blue-500 text-xl" />}
        </div>
    )
}
const CustomControl = ({ children, ...props }) => {
    const selected = props.getValue()[0]
    return (
        <Control {...props}>
            {selected && (
                <span
                    className=" rtl:mr-4"
                    shape="circle"
                    size={18}
                    src={selected.imgPath}
                />
            )}
            {children}
        </Control>
    )
}

const Filter = () =>{
    return(
        <React.Fragment>
                <div className="bg-gray-50 p-4 rounded-lg mb-5">
<div className="flex gap-3">
                <div className="flex-1 ">
                       <label className="text-gray-700 font-medium text-xs mb-2 block">Search</label>
                       <div className="input-wrapper"><div class="input-suffix-start left-3.5"><img src="/static/media/search-lg.b2976d5763145bb53f64eaa877fe41b4.svg" alt="" className="h-5"/></div><input type="text" name="hs-table-search" className="input  pl-9 w-full  input-shadow h-34 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Search"/></div>
                </div>
                <div className="flex-1 select-wrap-block">
                       <label className="text-gray-700 font-medium text-xs mb-2 block">Status</label>
                        <Select
                                options={countryOptions}
                                components={{
                                    Option: CustomSelectOption,
                                    Control: CustomControl,
                                }}
                                defaultValue={countryOptions[0]}
                                className="select-option"
                            />
                </div>
                <div className="flex-1 select-wrap-block">
                       <label className="text-gray-700 font-medium text-xs mb-2 block">Client</label>
                       <Select
                            placeholder="All"
                            options={colourOptions}
                            className="select-option"
                        ></Select>
                </div>


</div>
                </div>
        </React.Fragment>
    )
}

export default Filter