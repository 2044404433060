import axios from "axios";
import * as actionType from "../../constant/actionType";
export const GetOrgEmailLogs = (
  filtersort,
  filterorder,
  filterpage,
  filterlimit,
  searchTerm,
  organisation_uuid
) => {
  return (dispatch) => {
    // dispatch(GetallEmailLogsStart());
  
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/provider/GetOrgEmailLogs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      params: {
        search: searchTerm,
        page: filterpage,
        limit: filterlimit,
        sort: filtersort,
        order: filterorder,
        organisation_uuid: organisation_uuid,
        // created_by: created_by,
        // recipientEmail: recipientEmail,
        // email_template_uuid: email_template_uuid,
        // from_date: start_Date,
        // to_date: end_Date,
      },
    };

    return axios(config)
      .then(function (response) {
        // dispatch(GetallEmailLogsSuccess(response.data));
        return response.data;
      })
      .catch(function (error) {
        // dispatch(
        //   GetallEmailLogsFail("Something went wrong, Please try again.")
        // );
        return error;
      });
  };
};
