import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import * as userActions from "../../../../../../actions/index";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import ReactPaginate from "react-paginate";
import ContactsList from "./ContactsList";
import AddContactForm from "../../Dialog/AddContact";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import ClientInput from "../../../../../../common/element/Input/clientInput";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import plusImg from "../../../../../../assets/icons-svg/plus.svg";
import searchIcon from "../../../../../../assets/icons-svg/search-lg.svg";
import UserPlusImg from "../../../../../../assets/icons-svg/user-plus-01.svg";
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
import { useParams } from "react-router-dom";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { Pagination } from "../../../../../../common/pagination";
import useCallbackRef from "../../../../../../common/element/hooks/useCallbackRef";

const Contacts = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
  let role_assignment_uuid;
  if (loginData) {
    role_assignment_uuid = loginData?.data?.role_assignment_uuid
  }
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [countData, setCountData] = useState(0);
  const [filtersort, setFiltersort] = useState("first_name");
  const [filterorder, setFilterOrder] = useState("desc");
  const [filterpage, setfilterpage] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [filterlimit, setfilterlimit] = useState(10);
  const [searchTerm, setsearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [contactsData, setContactsData] = useState("");

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "View All Clients", url: "/admin/client/view-all-clients", home: "/admin/client/dashboard" },
        {
          label: props?.clientData?.trading_name,
          url: `/admin/client/view-all-clients/specific-client/${id}`,
        },
        {
          label: "Contacts",
          url: `/admin/client/view-all-clients/specific-client/${id}`,
        },
      ])
    );
  }, [props?.clientData]);

  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = (e) => {
    if(buttonDisabled==false){
      setIsOpen(false);
    }
  };

  const GetAllClientContacts = useCallback(async () => {

    // if (props?.organisation_uuid) {
    dispatch(
      userActions.GetAllClientContacts(
        id,
        searchTerm,
        filterpage,
        filterorder,
        filtersort,
        filterlimit
      )
    ).then((response) => {
      if (response?.success === true) {
        const arrayData = response?.data?.rows;
        setCountData(response?.data?.count)
        setContactsData(arrayData);
        setTotalPages(Math.ceil(response?.data?.count / filterlimit));
      } else if (response?.status === 401) {
        handleUnauthorizedAccess()
      } else {
      }
    });
    // }
    // eslint-disable-next-line
  }, [
    dispatch,
    // props?.organisation_uuid,
    id,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
  ]);

  const handlePageChange = ({ selected }) => {
    setfilterpage(selected + 1);
    // GetAllClientContacts(selected + 1, filterlimit);
  };
  const handleSeachChange = (e) => {
    setsearchTerm(e.target.value);
    setfilterpage(1);
  };
  const inputchangehandler = useCallbackRef(debounce(handleSeachChange), []);
  useEffect(() => {
    GetAllClientContacts();
  }, [
    // props?.organisation_uuid,
    id,
    searchTerm,
    filterpage,
    filterorder,
    filtersort,
    filterlimit,
  ]);


  const handleSort = (column) => {
    if (column === filtersort) {
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    } else {
      setFiltersort(column);
      setFilterOrder(filterorder === "asc" ? "desc" : "asc");
    }
  };

  //call permission api 
  // const GetSpecificUserRole = useCallback(async () => {
  //   if (role_assignment_uuid) {
  //     dispatch(userActions.GetSpecificUserRole(role_assignment_uuid)).then(
  //       (response) => {
  //         if (response?.success === true) { }
  //         else if (response?.status === 401) {
  //           handleUnauthorizedAccess()
  //         }
  //       }
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [dispatch]);
  // useEffect(() => {
  //   GetSpecificUserRole()
  // }, [])


  return (
    <React.Fragment>
      <div className="flex justify-between mb-5 px-2">
        <div className="flex gap-2 w-80 h-10">
          <div className="w-80 h-10">
            <div className="input-wrapper">
              <div className="input-suffix-start left-3.5">
                <img
                  src="/assets/icons-svg/search-lg.svg"
                  alt=""
                  className="h-5"
                />
              </div>
              <ClientInput
                type="text"
                className="input  pl-10 w-80  input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Search"
                inputchangehandler={inputchangehandler}
                name={"search"}
              />
            </div>
          </div>

        </div>
        <BtnPrimary
          variant="solid"
          onClick={() => openDialog()}
          className="w-auto h-9 flex items-center justify-center text-12 px-3.5"
          type="button"
        >
          Add Contacts{" "}
          <span class="ml-3">
            <img src={plusImg} alt="" class="h-5" />
          </span>
        </BtnPrimary>
      </div>
      <ContactsList
        contactsData={contactsData}
        handleSort={handleSort}
        filtersort={filtersort}
        contactFunAssId={props?.contactFunAssId}
        filterorder={filterorder}
        GetAllClientContacts={GetAllClientContacts}
      />
      <div className="flex justify-between mt-3"
        style={{
          alignItems: "Right",
          listStyle: "none",
          padding: 0,
        }}
      >
        <Pagination
          totalCount={countData}
          pageNumber={filterpage}
          displayPage={contactsData?.length}
          UpdatePageLimit={setfilterlimit}
          UpdatePage={setfilterpage}
        />
        {countData > 0 && <ReactPaginate
          className="flex justify-content-end p-2 align-items-center pagination-wrap"
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          previousLinkClassName={"prev-link"}
          nextLinkClassName={"page-link"}
          breakLinkClassName={"prev-link"}
          previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
          nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
          pageLinkClassName={"px-4 rounded-md"}
          activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
          disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
          renderOnZeroPageCount={null}
          forcePage={filterpage - 1}
        />}
      </div>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="addContact-dialog"
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={UserPlusImg} alt="" />
          </span>
          <div>
            <h5 className="mb-1 text-gray-900 text-base font-semibold">
              Add a Contact
            </h5>
            <p className="text-gray-600 ">
              Create a new Client into the Conserve system
            </p>
          </div>
        </div>
        <AddContactForm

          organisation_uuid={props?.organisation_uuid}
          allContactTypes={props?.allContactTypes}
          onDialogClose={onDialogClose}
          contactsData={contactsData}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
          GetAllClientContacts={GetAllClientContacts}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default Contacts;
