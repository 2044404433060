// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const SignUp = createSlice({
  name:"SignUp",
  initialState:[],
  reducers:{
    GetProviderSignUpData(state,action){
      return (action.payload)
    },
    ResetProviderSignUpData(){
      return []
    },
  }
}) 
export const {GetProviderSignUpData,ResetProviderSignUpData}=SignUp.actions;
export default SignUp.reducer;