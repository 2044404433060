import React, { useEffect, useState } from "react";
import InputGroup from "../../../../../../../common/element/InputGroup";
import { UpdateSiteInductionModuleData } from "../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import { UpdateInductionStatusSite } from "../../../../../../../redux/classes/Admin-Client/updatInductionStatusSite";

import Addon from "../../../../../../../common/element/InputGroup/Addon";
import Checkbox from "../../../../../../../common/element/Checkbox";
import ClientInput from "../../../../../../../common/element/Input/clientInput";
import { useDispatch, useSelector } from "react-redux";

import {
  DeleteCompanyIndModuleAnsByid,
  DeleteCompanyIndModuleQuesAnsByid,
  UpdateModuleQuesSortingOrder,
  UpdateModuleAnsSortingOrder,
} from "../../../../../../../actions/Admin-Client/AllCompanyInduction";

import { GetIndividualSiteIndData } from "../../../../../../../actions/Admin-Client/AllSiteApi";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

let CountAnswer = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const ChooseQus = (props) => {
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  const { SiteInductionModuleData } = useSelector((state) => state);
  const getAllState = useSelector((state) => state);
  let inductionData = SiteInductionModuleData[props.indexData];
  const [contentRequired, SetcontentRequired] = useState(false);
  const [qustionsRequired, SetqustionsRequired] = useState({
    index: "",
    required: false,
  });
  let inductionFile =
    SiteInductionModuleData[props.indexData]?.site_induction_file;
  const [qustionsData, SetQustionsData] = useState(
    SiteInductionModuleData[props.indexData]
  );
  const updatedModuleData =
    qustionsData && JSON.parse(JSON.stringify(qustionsData));

  const handdleChangeAdd = (event, questionIndex, answerIndex, dataName) => {
    dispatch(UpdateInductionStatusSite(true));
    const updatedModuleData =
      qustionsData && JSON.parse(JSON.stringify(qustionsData));

    updatedModuleData.site_induction_file = inductionFile;

    if (questionIndex == "AddAnswer") {
      const newAnswerKey = {
        answer: "",
        is_correct_answer: false,
      };
      updatedModuleData.module_ques_data[event].ques_ans_data.push(
        newAnswerKey
      );
    } else if (dataName == "Answer") {
      // content_info_type
      if (qustionsData.module_ques_data[questionIndex].question.trim() === "") {
        SetqustionsRequired({
          index: questionIndex,
          required: true,
        });
      } else {
        SetqustionsRequired({
          index: questionIndex,
          required: false,
        });
        updatedModuleData.module_ques_data[questionIndex].ques_ans_data[
          answerIndex
        ].answer = event.target.value;
      }
    } else if (event == "AddQuestion") {
      const newQuestion = {
        question: "",
        ques_ans_data: [
          {
            answer: "",
            is_correct_answer: false,
          },
        ],
      };
      updatedModuleData?.module_ques_data?.push(newQuestion);
    } else if (dataName == "checked") {
      updatedModuleData.module_ques_data[questionIndex].ques_ans_data =
        updatedModuleData.module_ques_data[questionIndex].ques_ans_data.map(
          (answer, index) => {
            return {
              ...answer,
              is_correct_answer: index === answerIndex,
            };
          }
        );
    } else {
      if (qustionsData.content_info_type == "") {
        SetcontentRequired(true);
      } else {
        SetcontentRequired(false);
        updatedModuleData.module_ques_data[questionIndex].question =
          event.target.value;
        if (event.target.value === "") {
          updatedModuleData.module_ques_data[
            questionIndex
          ].ques_ans_data.forEach((answerData) => {
            answerData.answer = "";
          });
        }
        SetqustionsRequired({
          index: questionIndex,
          required: false,
        });
      }
    }
    // qustionsData.site_induction_file=inductionFile
    SetQustionsData(updatedModuleData);
  };

  const dispatch = useDispatch();
  const deleteAnswer = (questionIndex, answerIndex) => {
    dispatch(UpdateInductionStatusSite(true));
    if (answerIndex == "QuestionDelete") {
      if (
        updatedModuleData?.module_ques_data?.[questionIndex]
          ?.module_question_uuid
      ) {
        dispatch(
          DeleteCompanyIndModuleQuesAnsByid(
            updatedModuleData?.module_ques_data?.[questionIndex]
              ?.module_question_uuid
          )
        );
      }
      updatedModuleData.module_ques_data =
        updatedModuleData.module_ques_data.filter(
          (question, index) => index !== questionIndex
        );
    } else {
      if (
        updatedModuleData?.module_ques_data?.[questionIndex]?.ques_ans_data?.[
          answerIndex
        ]?.module_answer_uuid
      ) {
        dispatch(
          DeleteCompanyIndModuleAnsByid(
            updatedModuleData.module_ques_data[questionIndex].ques_ans_data[
              answerIndex
            ].module_answer_uuid
          )
        );
      }
      updatedModuleData.module_ques_data[questionIndex].ques_ans_data.splice(
        answerIndex,
        1
      );
    }
    SetQustionsData(updatedModuleData);
  };

  useEffect(() => {
    dispatch(
      UpdateSiteInductionModuleData({
        indexData: props.indexData,
        moduleData: qustionsData,
      })
    );
  }, [qustionsData]);

  useEffect(() => {
    SetQustionsData(inductionData);
  }, [inductionData]);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedModuleData = { ...qustionsData };
    const { source, destination, draggableId, type } = result;

    if (type === "questions") {
      const questions = Array.from(updatedModuleData.module_ques_data);
      const [reorderedQuestion] = questions.splice(source.index, 1);
      questions.splice(destination.index, 0, reorderedQuestion);

      const updatedQuestions = questions.map((question, index) => {
        return {
          ...question,
          sort_order: index,
        };
      });

      updatedModuleData.module_ques_data = updatedQuestions;
      dispatch(
        UpdateSiteInductionModuleData({
          indexData: props.indexData,
          moduleData: updatedModuleData,
        })
      );
      if (
        updatedModuleData?.module_ques_data.every(
          (item) => item.module_question_uuid
        )
      ) {
        let moduleArray = updatedModuleData?.module_ques_data?.map(
          (items, index) => {
            return {
              module_question_uuid: items.module_question_uuid,
              sort_order: items.sort_order,
            };
          }
        );
        dispatch(UpdateModuleQuesSortingOrder(moduleArray))
          .then((response) => {
            if (response.success) {
              dispatch(
                GetIndividualSiteIndData({
                  module_uuid: inductionData.module_uuid,
                })
              ).then((response) => {
                if (response.success) {
                  dispatch(
                    UpdateSiteInductionModuleData({
                      indexData: props.indexData,
                      moduleData: response.data,
                    })
                  );
                } else if (response.status === 401) {
                  handleUnauthorizedAccess();
                }
              });
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          .catch((error) => {
            // ... (error handling)
          });
      }
    } else if (type === "answers") {
      const sourceIds = result.source.droppableId.split("-");
      const sourceIndex = parseInt(sourceIds[1]);

      // const updatedModuleDataCopy = JSON.parse(JSON.stringify(updatedModuleData));

      const answers = Array.from(
        updatedModuleData.module_ques_data[sourceIndex].ques_ans_data
      );
      // const answers = [...updatedModuleDataCopy.module_ques_data[sourceIndex].ques_ans_data];
      const [reorderedAnswer] = answers.splice(source.index, 1);
      answers.splice(destination.index, 0, reorderedAnswer);
      // // Update sort_order for answers
      const updatedAnswers = answers.map((answer, index) => ({
        ...answer,
        sort_order: index,
      }));
      // Assuming answers is the new array of objects containing updated answers
      // First, clone the array to make it mutable
      const mutableArray = [...updatedModuleData.module_ques_data];

      // Clone the object you want to update
      const updatedObject = { ...mutableArray[sourceIndex] };

      // Update the cloned object with the new answers
      updatedObject.ques_ans_data = answers;

      // Replace the original object with the updated one in the cloned array
      mutableArray[sourceIndex] = updatedObject;

      // Replace the original array with the updated one
      updatedModuleData.module_ques_data = mutableArray;

      dispatch(
        UpdateSiteInductionModuleData({
          indexData: props.indexData,
          moduleData: updatedModuleData,
        })
      );
      // // Update sort_order for answers
      if (
        updatedModuleData.module_ques_data[sourceIndex].ques_ans_data.every(
          (item) => item.module_answer_uuid
        )
      ) {
        let moduleArray = updatedAnswers?.map((items, index) => {
          return {
            module_answer_uuid: items.module_answer_uuid,
            sort_order: items.sort_order,
          };
        });
        dispatch(UpdateModuleAnsSortingOrder(moduleArray))
          .then((response) => {
            if (response.success) {
              dispatch(
                GetIndividualSiteIndData({
                  module_uuid: inductionData.module_uuid,
                })
              ).then((response) => {
                if (response.success) {
                  dispatch(
                    UpdateSiteInductionModuleData({
                      indexData: props.indexData,
                      moduleData: response.data,
                    })
                  );
                } else if (response.status === 401) {
                  handleUnauthorizedAccess();
                }
              });
            } else if (response.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          .catch((error) => {
            // ... (error handling)
          });
      }
    }
  };

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="questions" type="questions">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {qustionsData?.module_ques_data?.map((Item, index) => {
                return (
                  <Draggable
                    key={index}
                    draggableId={`question-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          className=" border border-gray-200 rounded-lg"
                          key={index}
                        >
                          <div className="px-4 py-3 bg-gray-50 rounded-t-lg">
                            <span className="text-blue-700 font-semibold block mb-3">
                              Question {index + 1} of{" "}
                              {qustionsData.module_ques_data.length}
                            </span>
                            <div className="flex gap-4 items-center">
                              <ClientInput
                                type="text"
                                placeholder=""
                                value={Item.question}
                                className="w-full input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline"
                                inputchangehandler={(event) =>
                                  handdleChangeAdd(event, index)
                                }
                              />
                              {contentRequired && (
                                <div
                                  className=" text-red-500 text-sm mt-1"
                                  style={{
                                    position: "absolute",
                                    transform: "translateY(35px)",
                                  }}
                                >
                                  Module Content is required
                                </div>
                              )}
                              {qustionsData.module_ques_data.length > 1 && (
                                <button
                                  onClick={() =>
                                    deleteAnswer(index, "QuestionDelete")
                                  }
                                >
                                  <svg
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                                      stroke="#667085"
                                      strokeWidth="1.667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              )}
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM10 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM10 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM15.834 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM15.834 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM15.834 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM4.167 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM4.167 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM4.167 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667z"
                                  stroke="#D0D5DD"
                                  strokeWidth="1.667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="px-4 py-3 bg-white flex flex-col gap-7 rounded-b-lg">
                            <Droppable
                              droppableId={`answers-${index}`}
                              type="answers"
                            >
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {Item.ques_ans_data.map(
                                    (answer, indexaswer) => {
                                      return (
                                        <Draggable
                                          key={indexaswer}
                                          draggableId={`answer-${index}-${indexaswer}`}
                                          index={indexaswer}
                                        >
                                          {(provided) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div
                                                className="flex gap-4 items-center w-full mt-4"
                                                key={indexaswer}
                                              >
                                                <span className="w-8 h-8 rounded-full border-2 border-blue-700  flex justify-center items-center text-blue-700 font-bold text-xs uppercase">
                                                  {
                                                    CountAnswer.split("")[
                                                      indexaswer
                                                    ]
                                                  }
                                                </span>
                                                <InputGroup className="mb-0 flex-1">
                                                  <Addon className="h-10">
                                                    <Checkbox
                                                      checked={
                                                        answer.is_correct_answer
                                                      }
                                                      onClick={(event) =>
                                                        handdleChangeAdd(
                                                          event,
                                                          index,
                                                          indexaswer,
                                                          "checked"
                                                        )
                                                      }
                                                    />
                                                  </Addon>
                                                  <ClientInput
                                                    className="h-10"
                                                    value={answer.answer}
                                                    key={indexaswer}
                                                    type="text"
                                                    inputchangehandler={(
                                                      event
                                                    ) =>
                                                      handdleChangeAdd(
                                                        event,
                                                        index,
                                                        indexaswer,
                                                        "Answer"
                                                      )
                                                    }
                                                  ></ClientInput>
                                                  {qustionsRequired.index ==
                                                    index &&
                                                    qustionsRequired.required && (
                                                      <div
                                                        className=" text-red-500 text-sm mt-1"
                                                        style={{
                                                          position: "absolute",
                                                          transform:
                                                            "translateY(35px)",
                                                        }}
                                                      >
                                                        Question is required
                                                      </div>
                                                    )}
                                                </InputGroup>
                                                <div className="flex gap-2">
                                                  {Item.ques_ans_data.length >
                                                    1 && (
                                                    <button
                                                      onClick={() =>
                                                        deleteAnswer(
                                                          index,
                                                          indexaswer
                                                        )
                                                      }
                                                    >
                                                      <svg
                                                        width="20"
                                                        height="21"
                                                        viewBox="0 0 20 21"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M13.333 5.693v-.667c0-.933 0-1.4-.181-1.757a1.667 1.667 0 0 0-.729-.728c-.356-.182-.823-.182-1.756-.182H9.333c-.933 0-1.4 0-1.756.182-.314.16-.569.415-.729.728-.181.357-.181.824-.181 1.757v.667m1.666 4.583v4.167m3.334-4.167v4.167M2.5 5.693h15m-1.667 0v9.333c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 0 1-1.093 1.093c-.534.272-1.235.272-2.635.272H8.167c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 0 1-1.093-1.093c-.272-.535-.272-1.235-.272-2.635V5.693"
                                                          stroke="#667085"
                                                          strokeWidth="1.667"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                    </button>
                                                  )}
                                                  <svg
                                                    width="20"
                                                    height="21"
                                                    viewBox="0 0 20 21"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M10 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM10 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM10 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM15.834 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM15.834 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM15.834 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM4.167 5.692a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667zM4.167 11.525a.833.833 0 1 0 0-1.666.833.833 0 0 0 0 1.666zM4.167 17.359a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667z"
                                                      stroke="#D0D5DD"
                                                      strokeWidth="1.667"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    />
                                                  </svg>
                                                  {(Item.ques_ans_data.length -
                                                    1 ==
                                                    indexaswer ||
                                                    Item.ques_ans_data.length ==
                                                      1) == true && (
                                                    <button
                                                      onClick={(event) =>
                                                        handdleChangeAdd(
                                                          index,
                                                          "AddAnswer"
                                                        )
                                                      }
                                                      className="text-blue-700 text-xs font-semibold flex items-center gap-2 hover:text-blue-900 focus:text-blue-700 hoverSvg"
                                                    >
                                                      <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M10 4.167v11.666M4.167 10h11.666"
                                                          stroke="#5786CC"
                                                          strokeWidth="2"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
        <div className="bg-blue-50 border border-blue-100 p-2.5 rounded-lg flex justify-center mt-6">
          <button
            onClick={() => handdleChangeAdd("AddQuestion")}
            className="text-blue-700 text-xs font-semibold flex items-center gap-2 hover:text-blue-900 focus:text-blue-700 hoverSvg"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 4.167v11.666M4.167 10h11.666"
                stroke="#5786CC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Add another question
          </button>
        </div>
      </DragDropContext>
    </React.Fragment>
  );
};
export default ChooseQus;
