import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Heading from "../../../../../../common/element/Heading";
import Tabs from "../../../../../../common/element/Tabs";
import ClientPortal from './ClientPortal';
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import EmailTemplateList from "./EmaiTemplateList";
import ContractorPortal from './ContractorPortal';
import EditClientContractorEmail from "./EditClient&ContractorTemplate"
import { setBreadcrumbs } from "../../../../../../redux/classes/breadcrumbsSlice";
const EmailTemplate = () => {
    const dispatch = useDispatch()
    const [archived, setArchived] = useState(false)
    const { TabNav, TabList, TabContent } = Tabs
    useEffect(() => {
        if (archived) {
            dispatch(setBreadcrumbs([
                { label: 'Email Templates', url: '/admin/email-templates', home: "/admin/dashboard" },
                { label: 'Archived' }
            ]));
        } else if (!archived) {
            dispatch(setBreadcrumbs([
                { label: 'Email Templates', url: '/admin/email-templates', home: "/admin/dashboard" }
            ]));
        }
    }, [archived]);

    useEffect(() => {
        return () => {
            handleBackArchive()
        }
    }, [])

    const handleArchive = () => {
        setArchived(true)
    }
    const handleBackArchive = () => {
        setArchived(false)
    }

    return (
        <React.Fragment>

            <div className="flex justify-between mb-0 flex-wrap gap-2">
                {archived == false ? (
                    <>
                        <Heading className="mb-6">Email Templates</Heading>
                        <BtnLight
                            className="w-36 h-9 flex items-center justify-center"
                            onClick={handleArchive}
                        > Archived
                        </BtnLight>
                    </>
                ) : (<>
                    <Heading className="mb-6">Archived Email Templates</Heading>
                    <BtnLight
                        className="w-36 h-9 flex items-center justify-center"
                        onClick={handleBackArchive}
                    > Archived <span>
                            <svg className="ml-4" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.5 7.49935H13.75C15.8211 7.49935 17.5 9.17828 17.5 11.2493C17.5 13.3204 15.8211 14.9993 13.75 14.9993H10M2.5 7.49935L5.83333 4.16602M2.5 7.49935L5.83333 10.8327" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                    </BtnLight>
                </>)

                }
            </div>
            <Tabs defaultValue="tab1" variant="pill">
                <div className=" bg-blue-25 border border-gray-100 p-1 rounded-lg mb-5 specificSiteTab">
                    <TabList>
                        <TabNav className="flex-1" value="tab1">Admin Portal</TabNav>
                        <TabNav className="flex-1" value="tab2">Client Portal</TabNav>
                        <TabNav className="flex-1" value="tab3">Provider Portal</TabNav>
                    </TabList>
                </div>

                <TabContent value="tab1"><EmailTemplateList
                    archived={archived}
                    handleArchive={handleArchive}
                    handleBackArchive={handleBackArchive} /></TabContent>
                <TabContent value="tab2"><ClientPortal
                    archived={archived}
                    handleArchive={handleArchive}
                    handleBackArchive={handleBackArchive} /></TabContent>
                <TabContent value="tab3"><ContractorPortal
                    archived={archived}
                    handleArchive={handleArchive}
                    handleBackArchive={handleBackArchive} /></TabContent>
            </Tabs> </React.Fragment>
    )
}
export default EmailTemplate