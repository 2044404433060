
import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import Card from "../../../../../../../common/element/Card";
import Heading from "../../../../../../../common/element/Heading";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import { useLocation, useNavigate } from "react-router-dom";
import * as userActions from "../../../../../../../actions/index";
// import { notify } from "react-notify-toast";
import logoImage from "../../../../../../../assets/logo.svg";
import useUnauthorizedHandler from "../../../../../../../common/snippet/useUnauthorizedHandler";

const CreatePassword = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const [invite_uuid, setInvite_uuid] = useState("");
  const [individualUuid, setIndividualUuid] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [organizationUuid, setOrganizationUuid] = useState("");
  const [contactTypeUuid, setContactTypeUuid] = useState("");
  const [hiddenpassword, sethiddenpassword] = useState(true);
  const [userUuid, setUserUuid] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [invitedUserType, setInvitedUserType] = useState("");

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const inviteUuid = urlSearchParams.get("invite_uuid");
    const contactTypeUuid = urlSearchParams.get("contact_type_uuid");
    setInvite_uuid(inviteUuid);
    setContactTypeUuid(contactTypeUuid);
  }, []);

  const togglePassword = () => {
    sethiddenpassword(!hiddenpassword);
  };

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleRepeatPassword = (event) => {
    setRepeatPassword(event.target.value);
  };

  const GetOrgUserInvitationById = useCallback(
    async (invite_uuid) => {
      dispatch(userActions.GetOrgUserInvitationById(invite_uuid)).then(
        (response) => {
          if (response.success === true) {
            if(response.data) {
              const arrayData = response.data;            
              const individual_id = arrayData?.individuals[0]?.individual_uuid;
              const organisation_id =
                arrayData?.invite_fun_assign?.organisation_uuid;
              setOrganizationUuid(organisation_id);
              setIndividualUuid(individual_id);
              setUserUuid(arrayData?.individuals[0]?.user_uuid)
              setUserStatus(arrayData?.invite_status)
              setInvitedUserType(arrayData?.invited_user_type)
            }
           else {
            setTimeout(() => {
            }, 2000);
             
           }
          } else if (response.status === 401) {
            handleUnauthorizedAccess()
          } else {
          }
        }
      );
      // eslint-disable-next-line
    },
    [dispatch]
  );

  useEffect(() => {
    if (invite_uuid) {
      GetOrgUserInvitationById(invite_uuid);
    }
  }, [GetOrgUserInvitationById, invite_uuid]);


  useEffect(() => {
  
    if (userStatus == "Active") {
      setTimeout(() => {
        // Redirects to login page,
        navigate("/login");
      }, 500);
    } else {
      if(userUuid) {
        const payload = {
          invite_uuid: invite_uuid,
          individual_uuid: individualUuid,
          organisation_uuid: organizationUuid,
          contact_type_uuid: contactTypeUuid,
          org_function_type: invitedUserType === "client_user" ? "client" : ""
        };
  
  
        props.onSubmitUserInvitation(payload).then((response) => {
          if (response.success === true) {
            setTimeout(() => {
              // Redirects to login page
              navigate("/login");
            }, 500);
          } else if (response.response.status === 409) {
            setTimeout(() => {
              // Redirects to login page
              navigate("/login");
            }, 500);
          } else if (response.status === 401) {
            handleUnauthorizedAccess()
          } else {
          }
          });
        }
    }
    // if user_uuid exist then call the submit api automatically & doesn't send password in payload

}, [userUuid])



const handleErrorMessage = () => {
  const isStrong = newPassword.match(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
  );
    setPasswordError(
      isStrong
        ? "" // No error if the password is strong
        : "Your password must be at least 8 characters long, contain at least one special character, and have a mixture of lowercase, uppercase, and numbers."
    );
  }



  const handleSubmit = (e) => {
    e.preventDefault();

    //Validate Password
    const isStrong = newPassword.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
    );

  setPasswordError(
    isStrong
      ? "" // No error if the password is strong
      : "Your password must be at least 8 characters long, contain at least one special character, and have a mixture of lowercase, uppercase, and numbers."
  );
  if (!isStrong) {
    return; // If the password is not strong, don't proceed with the submission
  }

  if (!repeatPassword) {
    setPasswordError("To continue, please enter your confirmed password");
    return;
  } else if (newPassword !== repeatPassword) {
    setPasswordError("Passwords do not match");
    return;
  }

    const payload = {
      invite_uuid: invite_uuid,
      individual_uuid: individualUuid,
      password: newPassword,
      organisation_uuid: organizationUuid,
      contact_type_uuid: contactTypeUuid,
    };


    props.onSubmitUserInvitation(payload).then((response) => {
      if (response.success === true) {
        setTimeout(() => {
          // Redirects to login page,
          navigate("/login");
        }, 500);
      } else if (response.response.status === 409) {
        setTimeout(() => {
          // Redirects to login page,
          navigate("/login");
        }, 500);
      } else if (response.status === 401) {
        handleUnauthorizedAccess()
      } else {
          }
    });
  };

  return (
    <div
      style={{ backgroundColor: `#eff8ff` }}
      className="flex justify-center min-h-screen pr-6 pl-6"
    >
      <div className="min-h-screen justify-center flex  py-12 px-4">
        <div className="flex-col items-center justify-center flex">
          <div className="flex items-center mb-6">
            <img className="brand-img" src={logoImage} alt="conserve" />
          </div>
          {individualUuid ? 
          <>
          <Heading className="text-center mb-3">
            Welcome to the Conserve Team!
            <br />
            Continue by creating a password
          </Heading>
          <Card className="w-500 rounded-xl   block-shadow w-100 mt-7 px-10 py-8">
            <div className="w-full">
              <form onSubmit={handleSubmit}>

                <div className="mb-4">
                  <label
                    className="block text-gray-700  mb-2 text-xs font-medium"
                    htmlFor="Email"
                  >
                    Password*
                  </label>
                  <div className="relative flex flex flex-column">
                    <input
                      className="input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border w-full py-2 px-3 pr-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      type={hiddenpassword ? "password" : "text"}
                      placeholder=""
                      value={newPassword}
                      onChange={(event) => handleNewPassword(event)}
                      onBlur={handleErrorMessage}
                    />
                    <span
                      className="absolute right-4 top-3"
                      onClick={togglePassword}
                    >
                      {hiddenpassword ? (
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          height="21"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                  <div className="flex flex-column">
                    <p className="text-xs text-red-600 mt-2">{passwordError}</p>
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700  mb-2 text-xs font-medium"
                    htmlFor="password"
                  >
                    Confirm Password*
                  </label>
                  <div className="relative flex">
                    <input
                      className="input-shadow h-10 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline"
                      id="password"
                      type={hiddenpassword ? "password" : "text"}
                      placeholder=""
                      value={repeatPassword}
                      onChange={(event) => handleRepeatPassword(event)}
                    />

                    <span
                      className="absolute right-4 top-3"
                      onClick={togglePassword}
                    >
                      {hiddenpassword ? (
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          height="21"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                            stroke="#667085"
                            strokeWidth="1.667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                </div>

                <div className="flex items-center justify-between w-full">
                  <BtnPrimary type="submit">
                    <span className="pr-2"> Submit </span>
                  </BtnPrimary>
                </div>
                

              </form>
            </div>
          </Card></>
          :
           <Heading className="text-center mb-3"> Oops!!! <br /> Invitation Link Expired </Heading> } 
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = {
  onSubmitUserInvitation: userActions.SubmitUserInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);


// same functionality is need to be implemented in client portal also
            // if GetOrgUserInvitationById api doesn't have data for specific invite_uuid then not show password page, doesn't call the submit api automatically but show a message -- "invite link has expired" 
            // invite_status === 'activate' then user is redirected at login page --- same thing is need to implement for admin user 
            // invite_status === 'Invited' then check if user_uuid exist or not 
                          // if user_uuid exist then call the submit api automatically & doesn't send password in payload
                          // if user_uuid not exist then call the submit api by allow user to input password

// if user_uuid === null then new individual which doesnot exist as user else it exist as user
// when handlesubmit() called it calls GetOrgUserInvitationById api first and check if user_uuid exist or not
// if user_uuid === null then password is sent in payload & SubmitUserInvitation api work conventionally
// if user_uuid !== null then 
