import Card from "../../../../../../common/element/Card"
import Table from "../../../../../../common/element/Table"
import Tag from "../../../../../../common/element/Tag"
import Scrollbar from "../../../../../../common/element/ScrollBar"
const Inductions = () =>{
    const { Tr, Th, Td, THead, TBody } = Table
    return(
        <Card className='py-6 border border-gray-200 rounded-xl dark-shadow'>
        <div className="px-6">
                          <div className="card-header flex border-b border-gray-200 pb-5 items-center mb-5">
                              <span className="mr-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 21L11.8999 20.8499C11.2053 19.808 10.858 19.287 10.3991 18.9098C9.99286 18.5759 9.52476 18.3254 9.02161 18.1726C8.45325 18 7.82711 18 6.57482 18H5.2C4.07989 18 3.51984 18 3.09202 17.782C2.71569 17.5903 2.40973 17.2843 2.21799 16.908C2 16.4802 2 15.9201 2 14.8V6.2C2 5.07989 2 4.51984 2.21799 4.09202C2.40973 3.71569 2.71569 3.40973 3.09202 3.21799C3.51984 3 4.07989 3 5.2 3H5.6C7.84021 3 8.96031 3 9.81596 3.43597C10.5686 3.81947 11.1805 4.43139 11.564 5.18404C12 6.03968 12 7.15979 12 9.4M12 21V9.4M12 21L12.1001 20.8499C12.7947 19.808 13.142 19.287 13.6009 18.9098C14.0071 18.5759 14.4752 18.3254 14.9784 18.1726C15.5467 18 16.1729 18 17.4252 18H18.8C19.9201 18 20.4802 18 20.908 17.782C21.2843 17.5903 21.5903 17.2843 21.782 16.908C22 16.4802 22 15.9201 22 14.8V6.2C22 5.07989 22 4.51984 21.782 4.09202C21.5903 3.71569 21.2843 3.40973 20.908 3.21799C20.4802 3 19.9201 3 18.8 3H18.4C16.1598 3 15.0397 3 14.184 3.43597C13.4314 3.81947 12.8195 4.43139 12.436 5.18404C12 6.03968 12 7.15979 12 9.4" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                              </span>
                              <div className="flex flex-col">    
                              <span className="text-gray-900 text-base font-semibold">Inductions</span>
                              <p className="text-gray-600">Pending completion</p>
                              </div>
                          </div>
                        </div>
                          <div className="h-64">             
                            <Scrollbar>
                            <div className="px-6">
                            <div className="overflow-hidden border rounded-lg border-gray-200">
                        <Table>
                  
                        <TBody>
                            <Tr>
                              
                                <Td>Induction name <span className="block text-gray-600">[Client name]</span></Td>
                                <Td className="w-28"><Tag className="bg-gray-100 text-gray-700"> <span><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<path d="M6 6.5C6.27614 6.5 6.5 6.27614 6.5 6C6.5 5.72386 6.27614 5.5 6 5.5C5.72386 5.5 5.5 5.72386 5.5 6C5.5 6.27614 5.72386 6.5 6 6.5Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 6.5C9.77614 6.5 10 6.27614 10 6C10 5.72386 9.77614 5.5 9.5 5.5C9.22386 5.5 9 5.72386 9 6C9 6.27614 9.22386 6.5 9.5 6.5Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 6.5C2.77614 6.5 3 6.27614 3 6C3 5.72386 2.77614 5.5 2.5 5.5C2.22386 5.5 2 5.72386 2 6C2 6.27614 2.22386 6.5 2.5 6.5Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span>Awaiting action</Tag></Td>
                            </Tr>
                            <Tr>
                               
                                <Td>Induction name <span className="block text-gray-600">[Client name]</span></Td>
                                <Td className="w-28"><Tag className="bg-blue-50 text-blue-800"> <span><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<g clip-path="url(#clip0_5886_93335)">
<path d="M6 1.125V2.375M6 9V11M2.875 6H1.125M10.625 6H9.875M9.22855 9.22855L8.875 8.875M9.33211 2.70789L8.625 3.415M2.46079 9.53921L3.875 8.125M2.56434 2.60434L3.625 3.665" stroke="#0E4E9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_5886_93335">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg></span>Pending</Tag></Td>
                            </Tr>
                            <Tr>
                        
                                <Td>Induction name <span className="block text-gray-600">[Client name]</span></Td>
                                <Td className="w-28"><Tag className="bg-blue-50 text-blue-800"> <span><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<g clip-path="url(#clip0_5886_93335)">
<path d="M6 1.125V2.375M6 9V11M2.875 6H1.125M10.625 6H9.875M9.22855 9.22855L8.875 8.875M9.33211 2.70789L8.625 3.415M2.46079 9.53921L3.875 8.125M2.56434 2.60434L3.625 3.665" stroke="#0E4E9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_5886_93335">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg></span>Pending</Tag></Td>
                            </Tr>
                            <Tr>
                             
                                <Td>Induction name <span className="block text-gray-600">[Client name]</span></Td>
                                <Td className="w-28"><Tag className="bg-blue-50 text-blue-800"> <span><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<g clip-path="url(#clip0_5886_93335)">
<path d="M6 1.125V2.375M6 9V11M2.875 6H1.125M10.625 6H9.875M9.22855 9.22855L8.875 8.875M9.33211 2.70789L8.625 3.415M2.46079 9.53921L3.875 8.125M2.56434 2.60434L3.625 3.665" stroke="#0E4E9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_5886_93335">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg></span>Pending</Tag></Td>
                            </Tr>
                            <Tr>
                          
                                <Td>Induction name <span className="block text-gray-600">[Client name]</span></Td>
                                <Td className="w-28"><Tag className="bg-blue-50 text-blue-800"> <span><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
<g clip-path="url(#clip0_5886_93335)">
<path d="M6 1.125V2.375M6 9V11M2.875 6H1.125M10.625 6H9.875M9.22855 9.22855L8.875 8.875M9.33211 2.70789L8.625 3.415M2.46079 9.53921L3.875 8.125M2.56434 2.60434L3.625 3.665" stroke="#0E4E9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_5886_93335">
<rect width="12" height="12" fill="white"/>
</clipPath>
</defs>
</svg></span>Pending</Tag></Td>
                            </Tr>
                        </TBody>
                        </Table>
                            </div>
                            </div>
                            </Scrollbar>
                        </div>
  </Card>
    )
}
export default Inductions 