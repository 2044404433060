import React,{useState} from "react"
import Switcher from "../../../../../../common/element/Switcher";
const Accreditations = () =>{
    const [open, setOpen] = useState();
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
      };
      const onSwitcherToggle = (val, e) => {
    }
    return(
        <React.Fragment>

                <h5 className="font-semibold text-base text-gray-900 mb-6">Provider Accreditation Certificate</h5>
                <div className="flex flex-wrap items-center mb-6">
                <Switcher onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Allow Providers to receive Accreditation Certificates</span>
                </div>
                <div className="flex flex-wrap items-center mb-6">
                <Switcher onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Send Client confirmation of Provider receiving Accreditation Certificates</span>
                </div>
                <div className="border-t border-gray-200 pt-6 mb-6">
                     <h5 className="font-semibold text-base text-gray-900 mb-1">Worker Induction Card</h5>
                     <p className="text-gray-600 font-medium  mb-6">Manage what will show on Workers Induction Cards</p>
                     <div className="flex flex-wrap items-center mb-6">
                      <Switcher onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Show Photo on Induction Card</span>
                     </div>
                     <div className="flex flex-wrap items-center mb-6">
                      <Switcher onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Show Client Logo on Induction Card</span>
                     </div>
                     <div className="flex flex-wrap items-center mb-6">
                      <Switcher onChange={onSwitcherToggle} />  <span className="text-xs text-gray-700 font-medium ml-2">Show Induction Card Back</span>
                     </div>
                </div>
        </React.Fragment>
    )
}
export default Accreditations