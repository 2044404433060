import React, { useEffect, useState } from "react";
import Table from "../../../../../../common/element/Table";
import Badge from "../../../../../../common/element/Badge";
import Tooltip from "../../../../../../common/element/Tooltip";
import Dialog from "../../../../../../common/element/Dialog";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import moment from "moment";
import InductionView from "../Dialog/InductionView";
import BtnPrimaryLight from "../../../../../../common/element/Buttons/primaryLightBtn";
import { GetCompanyInductionAndModulesbyId } from "../../../../../../actions/client-portal/GetCompanyInductionAndModulesbyIdApi";
import { useDispatch, useSelector } from "react-redux";
import { ResetInductionModuleData } from "../../../../../../redux/classes/client-portal/inductionModuleDataSlice";
import { AddInductionModuleData } from "../../../../../../redux/classes/client-portal/inductionModuleDataSlice";
import { InductuionDetailForm } from "../../../../../../redux/classes/client-portal/inductionDetailsForm";
import { EditInduction } from "../../../../../../redux/classes/client-portal/EeditInduction";
import { ResetEditInduction } from "../../../../../../redux/classes/client-portal/EeditInduction";
import { DeleteCompanyInduction } from "../../../../../../actions/client-portal/DeleteCompanyInduction";
import { refetchAllInductionsData } from "../../../../../../redux/classes/client-portal/refetchAllInductions";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";

const InductionList = (props) => {
  const handleUnauthorizedAccess = useUnauthorizedHandler()
  const dispatch = useDispatch();
  const { Tr, Th, Td, THead, TBody } = Table;
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [openDataExistDialog, setDataExistDailog] = useState(false);
  const [checkEditdata, SetcheckEditdata] = useState(true);
  const [inductionOpen, setInductionOpen] = useState(false);
  const [companyInductionUuid, setCompanyInductionUuid] = useState("");
  const [deleteDiolog, setdeleteDiolog] = useState(false);
  const [inductionName,setInductionName]=useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false)
  let organisation_uuid;
  let trading_name;
  let user_uuid;
  let login_user;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));

  if (storedData !== null) {
    user_uuid = storedData?.userName?.user_uuid;
    organisation_uuid=storedData?.organisation_uuid;
    trading_name = storedData?.trading_name;
    login_user=`${storedData?.userName?.first_name || ''} ${storedData?.userName?.last_name || ''}`;
  }
  // if (storedData !== null) {
  // }
  useEffect(() => {
    setIsOpen(props?.veiwInduction);
    if (props?.veiwInduction) {
      SetcheckEditdata(true)
    }
  }, [props?.veiwInduction]);
  const openDialog = (company_induction_uuid) => {
    SetcheckEditdata(true);
    dispatch(ResetEditInduction());
    dispatch(ResetInductionModuleData());
    dispatch(GetCompanyInductionAndModulesbyId(company_induction_uuid)).then(
      (response) => {

        if (response?.success) {
          dispatch(
            InductuionDetailForm({
              function_assignment_uuid: response?.data?.function_assignment_uuid,
              company_ind_name: response?.data?.company_ind_name,
              company_ind_desc: response?.data?.company_ind_desc,
              company_induction_uuid: response?.data?.company_induction_uuid,
              company_ind_status: response?.data?.company_ind_status,
              company_ind_valid_days: response?.data?.company_ind_valid_days,
              company_ind_publish_date: response?.data?.company_ind_publish_date,
            })
          );
          response?.data?.company_ind_modules.map((item) => {
            dispatch(AddInductionModuleData(item));
          });
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        }
      }
    );
    setIsOpen(true);
  };
  const onCloseExistDailog = (e) => {
    setDataExistDailog(false)
    setdeleteDiolog(false)
  }
  const onDialogClose = (e) => {
    setIsOpen(false);
    dispatch(ResetInductionModuleData());
    dispatch(ResetEditInduction());
    SetcheckEditdata(false)
  };

  const onDeleteDialogClose = () => {
    if (!buttonDisabled) {
      setdeleteDiolog(false)
    }
  }

  const onConfirmDelete = () => {
    setButtonDisabled(true)
    dispatch(DeleteCompanyInduction(companyInductionUuid,organisation_uuid,trading_name,login_user,inductionName))
      .then((response) => {
        if (response?.success) {
          try {
            if(response?.message !== "Deletion cannot be performed because this induction was attempted by a worker!"){            
              props?.fetchInductionData()
            }
            // dispatch(refetchAllInductionsData(true));
          } catch (error) {
            console.error("Error handling response:", error);
          } finally {
            setButtonDisabled(false);
            onDeleteDialogClose()
          }
        }
        if (response?.message == "Deletion cannot be performed because this induction was attempted by a worker!") {
          setDataExistDailog(true)
          setButtonDisabled(false)
        } else if (response?.status === 401) {
          handleUnauthorizedAccess()
        }
      }).catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setButtonDisabled(false);
      })
  }


  const inductionCptClose = (e) => {
    setInductionOpen(false);
    dispatch(ResetInductionModuleData());
  };

  const Allfunction = useSelector((state) => state);
  // Get Data
  // useEffect(()=>{
  //    SetGetData(GettAllFunctions.getAllComplianceData?.data?.data?.rows)
  // },[GettAllFunctions])
  const handleEditinduction = (company_induction_uuid, e) => {
    if (e === "EditModule") {
      SetcheckEditdata(true);
      dispatch(ResetEditInduction());
      dispatch(ResetInductionModuleData());
      dispatch(GetCompanyInductionAndModulesbyId(company_induction_uuid)).then(
        (response) => {
          if (response?.success) {
            dispatch(
              InductuionDetailForm({
                function_assignment_uuid:
                  response?.data?.function_assignment_uuid,
                company_ind_name: response?.data?.company_ind_name,
                company_ind_desc: response?.data?.company_ind_desc,
                company_induction_uuid: response?.data?.company_induction_uuid,
                company_ind_status: response?.data?.company_ind_status,
                company_ind_valid_days: response?.data?.company_ind_valid_days,
                company_ind_publish_date: response?.data?.company_ind_publish_date,
              })
            );
            response?.data?.company_ind_modules.map((item) => {
              dispatch(AddInductionModuleData(item));
            });
          } else if (response?.status === 401) {
            handleUnauthorizedAccess()
          }
        }
      );
      setIsOpen(true);
      dispatch(EditInduction(true));
      setIsOpen(false);
    } else {
      SetcheckEditdata(true);
      dispatch(ResetEditInduction());
      dispatch(EditInduction(true));
      // SetcheckEditdata(false);
      setIsOpen(false);
    }
  };
  // useEffect(()=>{
  //   if(GettAllFunctions?.editInduction===null && GettAllFunctions?.editInduction){
  //     setIsOpen(true);
  //     dispatch(EditInduction(false))
  //   }
  // },[GettAllFunctions])

  const closeViewInduction = () => {
    dispatch(ResetEditInduction());
    SetcheckEditdata(false)
    setIsOpen(false);
  }

  const handleDeleteinduction = (company_induction_uuid,induction_name) => {
    setCompanyInductionUuid(company_induction_uuid)
    setInductionName(induction_name)
    setdeleteDiolog(true)
  }

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th>
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("company_ind_name")}
                >
                  Induction name
                  <svg
                    className={`transform ${props?.getFilterData?.sort !== "" &&
                      props?.getFilterData?.sort === "company_ind_name" &&
                      props?.getFilterData?.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("company_module_count")}
                >
                  No. of Modules
                  <svg
                    className={`transform ${props?.getFilterData?.sort !== "" &&
                      props?.getFilterData?.sort === "company_module_count" &&
                      props?.getFilterData?.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("company_ind_publish_date")}
                >
                  Publish date
                  <svg
                    className={`transform ${props?.getFilterData?.sort !== "" &&
                      props?.getFilterData?.sort === "company_ind_publish_date" &&
                      props?.getFilterData?.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">
                <span
                  className="flex items-center gap-1"
                  onClick={() => props?.handleSort("company_ind_valid_days")}
                >
                  Valid till
                  <svg
                    className={`transform ${props?.getFilterData?.sort !== "" &&
                      props?.getFilterData?.sort === "company_ind_valid_days" &&
                      props?.getFilterData?.order === "asc"
                      ? "rotate-180"
                      : ""
                      }`}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-36">Status</Th>
              <Th className="w-28"></Th>
            </Tr>
          </THead>
          <TBody>
            {props?.data?.rows?.map((item, index) => {
              // Add days to the validSDays
              const validSDays = moment(item?.created_date).add(
                item?.company_ind_valid_days,
                "days"
              );
              return (
                <Tr>
                  <Td>{item?.company_ind_name}</Td>
                  <Td>{item?.company_module_count}</Td>
                  <Td>{moment(item?.created_date).format("DD/MM/YYYY")}</Td>
                  <Td>{moment(validSDays).format("DD/MM/YYYY")}</Td>
                  <Td>
                    {item?.company_ind_status === "draft" && (
                      <Badge
                        className="py-0.5 px-2 rounded-lg font-normal"
                        content={"Draft"}
                        innerClass="bg-blue-50  text-blue-800"
                      />
                    )}
                    {item?.company_ind_status === "archived" && (
                      <Badge
                        className="py-0.5 px-2 rounded-lg font-normal"
                        content={"archived"}
                        innerClass="text-gray-700 bg-gray-100"
                      />
                    )}
                    {item?.company_ind_status === "active" && (
                      <Badge
                        className="py-0.5 px-2 rounded-lg font-normal"
                        content={"Active"}
                        innerClass="bg-success-100 text-success-700"
                      />
                    )}
                  </Td>
                  <Td>
                    <div className="flex items-center justify-end gap-4">
                      <Tooltip title="View" placement="top">
                        <button
                          onClick={() =>
                            openDialog(item?.company_induction_uuid)
                          }
                        >
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.017 11.094c-.114-.18-.17-.27-.202-.408a.977.977 0 0 1 0-.372c.032-.139.088-.229.202-.408.938-1.485 3.73-5.24 7.983-5.24 4.255 0 7.046 3.755 7.984 5.24.113.18.17.27.202.408a.976.976 0 0 1 0 .372c-.032.139-.089.229-.202.408-.938 1.485-3.73 5.24-7.984 5.24s-7.045-3.755-7.983-5.24z"
                              stroke="#667085"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 13a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                              stroke="#667085"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </Tooltip>

                      <Tooltip title="Edit" placement="top">
                        <button
                          disabled={props?.inductionPermission}
                          onClick={() =>
                            handleEditinduction(
                              item?.company_induction_uuid,
                              "EditModule"
                            )
                          }
                        >
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.667 8.333 12.333 5M2.75 17.917l2.82-.314c.345-.038.517-.057.678-.11.143-.046.279-.11.404-.194.142-.093.264-.215.51-.46L18.166 5.832A2.357 2.357 0 1 0 14.833 2.5L3.828 13.505c-.245.245-.368.368-.46.51a1.667 1.667 0 0 0-.195.403c-.052.161-.071.334-.11.678l-.313 2.82z"
                              stroke="#344054"
                              strokeWidth="1.667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </Tooltip>

                      <Tooltip title="Delete" placement="top">
                        <button disabled={props?.inductionPermission} onClick={() => handleDeleteinduction(item?.company_induction_uuid,item?.company_ind_name)
                        }>
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                              stroke="#667085"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    </div>
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </div>
      {checkEditdata && (
        <Dialog
          isOpen={dialogIsOpen}
          onClose={onDialogClose}
          onRequestClose={onDialogClose}
          bodyOpenClassName="overflow-hidden"
          className="lg-dialog"
        >
          <div className="flex border-b pb-5 px-6 pt-6 items-center">
            <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
              <img src="/assets/icons-svg/file-check.svg" alt="" />
            </span>
            <div>
              <h5 className="mb-0 text-gray-900 text-base font-semibold">
                {Allfunction?.inductionDetailData?.company_ind_name}
              </h5>
            </div>
          </div>
          <InductionView inductionPermission={props?.inductionPermission} closeViewInductionModule={closeViewInduction} handleEditinductionModule={handleEditinduction} />
        </Dialog>
      )}

      <Dialog
        isOpen={inductionOpen}
        onClose={inductionCptClose}
        onRequestClose={inductionCptClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m12.895 21.586-.1-.15c-.695-1.042-1.043-1.563-1.501-1.94a4 4 0 0 0-1.378-.737c-.568-.173-1.194-.173-2.447-.173H6.095c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108v-8.6c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874c.427-.218.987-.218 2.108-.218h.4c2.24 0 3.36 0 4.215.436A4 4 0 0 1 12.46 5.77c.436.856.436 1.976.436 4.216m0 11.6v-11.6m0 11.6.1-.15c.694-1.042 1.042-1.563 1.5-1.94a4.001 4.001 0 0 1 1.378-.737c.568-.173 1.194-.173 2.447-.173h1.375c1.12 0 1.68 0 2.107-.218a2 2 0 0 0 .875-.874c.218-.428.218-.988.218-2.108v-8.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.875-.874c-.427-.218-.987-.218-2.107-.218h-.4c-2.24 0-3.36 0-4.216.436A4 4 0 0 0 13.33 5.77c-.436.856-.436 1.976-.436 4.216"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Congrats! Induction completed successfully.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight onClick={inductionCptClose}>Close</BtnLight>
        </div>
      </Dialog>

      {/* Add Dialog Delete */}
      <Dialog
        isOpen={deleteDiolog}
        onClose={onDeleteDialogClose}
        onRequestClose={onDeleteDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4 mb-1">
            Are you sure you want to delete induction?
          </p>
          <p className="text-gray-600 text-center">
            This action cannot be undone
          </p>
        </div>

        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnLight disabled={buttonDisabled} className="mr-1.5" onClick={onDeleteDialogClose}>
            Cancel
          </BtnLight>
          <BtnPrimary disabled={buttonDisabled} className="ml-1.5" onClick={onConfirmDelete}>
            Delete
          </BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={openDataExistDialog}
        onClose={onCloseExistDailog}
        onRequestClose={onCloseExistDailog}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="48" height="48" rx="24" fill="#FEE4E2" />
              <path
                d="M21.5 21L27.5 27M27.5 21L21.5 27M20.3 33H28.7C30.3802 33 31.2202 33 31.862 32.673C32.4265 32.3854 32.8854 31.9265 33.173 31.362C33.5 30.7202 33.5 29.8802 33.5 28.2V19.8C33.5 18.1198 33.5 17.2798 33.173 16.638C32.8854 16.0735 32.4265 15.6146 31.862 15.327C31.2202 15 30.3802 15 28.7 15H20.3C18.6198 15 17.7798 15 17.138 15.327C16.5735 15.6146 16.1146 16.0735 15.827 16.638C15.5 17.2798 15.5 18.1198 15.5 19.8V28.2C15.5 29.8802 15.5 30.7202 15.827 31.362C16.1146 31.9265 16.5735 32.3854 17.138 32.673C17.7798 33 18.6198 33 20.3 33Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Deletion cannot be performed because this induction was attempted by a worker!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onCloseExistDailog}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default InductionList;
