import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

function ProctedRoute() {
    const navigate = useNavigate();
    // Get tokken from local storage
    const storedData = localStorage.getItem('conserve_authtoken')
    const authenticated = localStorage.getItem('Conserve_http_Authenticated')
    useEffect(()=>{
        if (!storedData) { 
            if(authenticated){
                navigate("/login") 
            }else{
                navigate("/") 
            } 
           }
    },[])
    return (
        <Outlet />
    );
}

export default ProctedRoute;
