import axios from "axios";
import * as actionType from "../../constant/actionType";
// Provider list data
export const GetAllProviderTypesList = () => {
  return (dispatch) => {
    const config = {
      method: "get",
      url: `${actionType.environment_url}/api/admin/provider/GetAllProviderTypesList`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
    };
    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  };
};
