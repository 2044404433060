import React,{useState} from "react";
import ClientName from "../ClientName";
import Heading from "../../../../../../common/element/Heading";
import Tabs from "../../../../../../common/element/Tabs";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import UserList from "./UserList";
import Invited from "./Invited";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import AddUser from "../../Dialog/Clients/ClientSettings/AddUser";
import ClientDetails from "./ClientDetails";
import Accreditations from "./Accreditations";
const { TabNav, TabList, TabContent } = Tabs
const Settings = ()=>{
    const [dialogIsOpen, setIsOpen] = useState(false)

    const openDialog = () => {
        setIsOpen(true)
    }
    const onDialogClose = (e) => {
        setIsOpen(false)
    }

    return(
       <React.Fragment>
            <ClientName/>
            <div className="bg-gray-50 border-t border-gray-200  nmb-6 nmx-6  px-6">
            <div className="py-6 border-b border-gray-200 mb-9"> <Heading>Settings</Heading></div>
            <div className='userList'>
            <Tabs defaultValue="tab1" variant="pill">
                <TabList>
                    <TabNav value="tab1">Client Details</TabNav>
                    <TabNav value="tab2">Users</TabNav>
                    <TabNav value="tab3">Accreditations</TabNav>
                </TabList>
                <TabContent value="tab1">
                    <div className=" pl-24">
                        <div className="flex justify-between mb-6 border-b border-gray-200 pb-6">
                                <div className="flex flex-col">
                                    <h5 className="text-base text-gray-900 font-semibold mb-1">Client Details</h5>
                                    <p className="text-gray-600">Update the client’s logo and details here.</p>
                                </div>
                                <div className="flex">
                                <BtnLight className="w-auto flex items-center px-4 mr-1.5">Cancel</BtnLight>
                                <SmallButton className="w-auto flex items-center px-4 ml-1.5">Save</SmallButton>
                                </div>
                            </div>
                            <ClientDetails/>
                        </div>
                </TabContent>
                <TabContent value="tab2">
                <div className=" pl-24">
                        <div className="flex justify-between mb-6">
                            <div>
                                <h5 className="text-base text-gray-900 font-semibold mb-1">Users</h5>
                                <p className="text-gray-600">Manage and update the User’s access.</p>
                            </div>
                            <SmallButton onClick={() => openDialog()} className="w-auto flex items-center px-3 text-xs">Add user <img src="/assets/icons-svg/plus.svg" alt="" className="ml-2"/></SmallButton>
                        </div>
                        <UserList/>
                        <h4 className="text-gray-900 font-semibold text-base my-6">Invited</h4>
                        <Invited/>
                </div>
                </TabContent>
                <TabContent value="tab3" className="min-h-500">
                <div className="pl-24 min-h-full">
                    <Accreditations/>
                </div>
                </TabContent>
            </Tabs>
            </div>
            </div>
            <Dialog
                isOpen={dialogIsOpen}
                onClose={onDialogClose}
                onRequestClose={onDialogClose}
                bodyOpenClassName="overflow-hidden"
                className="addContact-dialog"
            >
                <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
                    <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4"><img src="/assets/icons-svg/user.svg" alt=""/></span>
                    <div>
                    <h5 className="mb-1 text-gray-900 text-base font-semibold">Add a user</h5>
                    </div>
                </div>
                <AddUser/>
            </Dialog>

       </React.Fragment>
    )
}
export default Settings