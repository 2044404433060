import Card from "../../../../../../common/element/Card";
import Table from "../../../../../../common/element/Table";
import Tag from "../../../../../../common/element/Tag";
import Scrollbar from "../../../../../../common/element/ScrollBar";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
const Documents = () => {
  const { Tr, Th, Td, THead, TBody } = Table;
  return (
    <Card className="py-6 border border-gray-200 rounded-xl dark-shadow">
      <div className="px-6">
        <div className="card-header flex justify-between border-b border-gray-200 pb-5 mb-3">
          <div className="flex items-center">
            <span className="mr-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M9 16L11 18L15.5 13.5M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <p></p>
            <div className="flex flex-col">
              <span className="text-gray-900 text-base font-semibold">
                Documents
              </span>
              <p className="text-gray-600">Action required </p>
            </div>
          </div>
          <BtnLight className="w-20 h-9">View All</BtnLight>
        </div>
      </div>

      <div className="h-64">
        <Scrollbar>
          <div className="px-6">
            <div className="overflow-hidden border rounded-lg border-gray-200">
              <Table>
                <TBody>
                  <Tr>
                    <Td>
                      Document name{" "}
                      <span className="block text-gray-600">[Client name]</span>
                    </Td>
                    <Td className="w-28">
                      <Tag className="bg-error-100 text-error-700">
                        Action required
                      </Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Document name{" "}
                      <span className="block text-gray-600">[Client name]</span>
                    </Td>
                    <Td className="w-28">
                      <Tag className="bg-error-100 text-error-700">
                        Action required
                      </Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Document name{" "}
                      <span className="block text-gray-600">[Client name]</span>
                    </Td>
                    <Td className="w-28">
                      <Tag className="bg-blue-100 text-blue-700">
                        Awaiting review
                      </Tag>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      Document name{" "}
                      <span className="block text-gray-600">[Client name]</span>
                    </Td>
                    <Td className="w-28">
                      <Tag className="bg-error-100 text-error-700">
                        Action required
                      </Tag>
                    </Td>
                  </Tr>
                </TBody>
              </Table>
            </div>
          </div>
        </Scrollbar>
      </div>
    </Card>
  );
};
export default Documents;
