
import { createSlice} from "@reduxjs/toolkit";
const GetAllDocumentsOfSpecificWorker = createSlice({
  name: 'GetAllDocumentsOfSpecificWorker',
      initialState:{
        data:null,
        loading:false,
        error:null,
    },
  reducers: {
    fetchData: (state) => {
      state.loading = true;
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchDataError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    reSetData:()=>{
      return []
    }
  },
});
export const { fetchData, fetchDataSuccess, fetchDataError,reSetData } = GetAllDocumentsOfSpecificWorker.actions;
export default GetAllDocumentsOfSpecificWorker.reducer;