import React, { useState } from "react";
import BtnPrimary from "../../../../../../../common/element/Buttons/btnPrimary";
import ImageUploader from "../../../../../../../common/element/ImageUploder";
const VisitorLog = (props) => {
    const defaultImageUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVYhkJiYFb8n7qEEchgHlIoZzgalsuUrfkXQ&s"
    const handleImageChange = (file) => {
        // console.log('Selected file:', file);
    }
    return (
        <React.Fragment>
            <div className="flex w-full p-5 details-modal">
                <div className="col-1 h-75">

                    <ImageUploader
                        uploadImage={false} // Set to false to disable image upload
                        defaultImage={defaultImageUrl}
                        onChange={handleImageChange}
                    />
                </div>
                <div className="pl-5 col-2">
                    <form>
                        <div className="w-full">
                            <div className="mb-5">
                                <label className="text-xs font-medium text-gray-700 mb-1.5 block">Site contact</label>
                                <input disabled value={props?.detailsView?.site_contact ? props?.detailsView?.site_contact : ""} type="text" placeholder="Site contact name" className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                            </div>
                        </div>
                        <hr className="border-gray-200 mb-5" />
                        <div className="flex w-full">
                            <div className="flex-1 pr-1.5 mb-5">
                                <label className="text-xs font-medium text-gray-700 mb-1.5 block">First name*</label>
                                <input type="text" disabled value={props?.detailsView?.first_name} placeholder="Visitor’s first name" className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                            </div>
                            <div className="flex-1 pl-1.5 mb-5">
                                <label className="text-xs font-medium text-gray-700 mb-1.5 block">Last name*</label>
                                <input type="text" disabled value={props?.detailsView?.last_name} placeholder="Visitor’s last name" className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="mb-5">
                                <label className="text-xs font-medium text-gray-700 mb-1.5 block">Email address*</label>
                                <input type="text" disabled value={props?.detailsView?.email} placeholder="Worker’s first name" className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="mb-5">
                                <label className="text-xs font-medium text-gray-700 mb-1.5 block">Phone number</label>
                                <input type="text" disabled value={props?.detailsView?.phone} placeholder="Visitor's phone number" className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="mb-5">
                                <label className="text-xs font-medium text-gray-700 mb-1.5 block">Job title</label>
                                <input type="text" disabled value={props?.detailsView?.job_title ? props?.detailsView?.job_title : ""} placeholder="Visitor’s job role" className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                            </div>
                        </div>
                        <div className="w-full">
                            <label className="text-xs font-medium text-gray-700 mb-1.5 block">Company name</label>
                            <input type="text" disabled value={props?.detailsView?.company_name ? props?.detailsView?.company_name : ""} placeholder="Visitor’s company name" className="input-shadow h-9 text-sm border-gray-300 rounded-lg  appearance-none border w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>
                    </form>
                </div>
            </div>
            <div className="flex dailog-footer mt-0">
                <BtnPrimary className="ml-1.5" onClick={() => props.onDialogClose()}>Close</BtnPrimary >
            </div>
        </React.Fragment>
    )
}
export default VisitorLog