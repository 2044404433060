import React, { useCallback, useEffect, useState } from "react";
import Tabs from "../../../../../../common/element/Tabs";
import Dialog from "../../../../../../common/element/Dialog/Dialog";
import Select from "../../../../../../common/element/Select/Select";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import ClientInput from "../../../../../../common/element/Input/clientInput";
import Label from "../../../../../../common/element/Label";
import Upload from "../../../../../../common/element/Upload";
import RichTextEditor from "../../../../../../common/snippet/RichTextEditor";
import Table from "../../../../../../common/element/Table";
import Validations from "../../../../../../common/404/Validations";
import { debounce } from "../../../../../../common/snippet/Debouncing";
import * as userActions from "../../../../../../actions/index";
import { connect, useDispatch, useSelector } from "react-redux";
import { notify } from "react-notify-toast";
import Input from "../../../../../../common/element/Input";
import { GetAllComplianceOfClientList } from "../../../../../../actions/client-portal/AllSiteApi";
import { InviteSpecificProvider } from "../../../../../../actions/client-portal/AllContractorApi";
import contractorImg from "../../../../../../assets/icons-svg/cons-provider.svg";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { UpdateSiteActiveStatus_SUCCESS } from "../../../../../../constant/actionType";

const AddContractor = (props) => {
  // const {function_assignment_uuid}=JSON.parse(localStorage.getItem("LoginUserData"))
  const dispatch = useDispatch();
  const handleUnauthorizedAccess = useUnauthorizedHandler();
  let function_assignment_uuid;
  let trading_name;
  let organisation_uuid;
  let current_user;
  const storedData = JSON.parse(localStorage.getItem("LoginUserData"));
  if (storedData !== null) {
    function_assignment_uuid = storedData.function_assignment_uuid;
    organisation_uuid = storedData?.organisation_uuid;
    trading_name = storedData.trading_name;
    current_user = `${storedData?.userName?.first_name || ''} ${storedData?.userName?.last_name || ''}`;

  }

  const GetFunction = useSelector((state) => state);
  // const [emailError, setEmailError] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  // const [nameError, setNameError] = useState('');
  const [search, setSearch] = useState();
  const [documentError, setdocumentError] = useState("");
  const [emailSearch, setEmailSearch] = useState();
  const [invite_attach, setInvite_attach] = useState([]);
  const [selectProviderDetails, setSelectProviderDetails] = useState([]);
  const [searchOptions, setSelectOptions] = useState([]);
  const [workerAllCheckListData, setWorkerAllCheckListData] = useState([]);
  const [checkListError, setChecklistError] = useState("");
  const [workerCheckListError, setWorkerChecklistError] = useState("");
  const [allCheckListData, setAllCheckListData] = useState([]);
  const [selCompChecklist, setSelCompChecklist] = useState([]);
  const [workerSelCompChecklist, setWorkerSelCompChecklist] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [providerOrgId, setProviderOrgId] = useState(false);
  const [providerUserUuid, setProviderUserUuid] = useState("")
  const [selectedDocumentType, setSelectedDocumentType] = useState(
    props?.selectedDocumentType ? props?.selectedDocumentType : ""
  );
  const [dialogAlreadyOpen, setCloseAlreadyExist] = useState(false);
  const [dialogIsOpen, setIsOpen] = useState(true);
  const [functionUuid, setfunctionUuid] = useState();
  const [dialogAddOpen, setAddOpen] = useState(false);
  // const [fullName, setFullName] = useState({ first_name: "", last_name: "" });
  const [attachment, setAttachment] = useState({ files: [] });

  const [fullNameError, setFullNameError] = useState({
    first_name: "",
    last_name: "",
  });

  const isSearchClear = () => {
    props?.SetcontractorData((prevValue) => ({
      ...prevValue,
      individual_uuid: "",
      provider_email: "",
      first_name: "",
      last_name: "",
    }));
    props?.setFormErrors((prevError) => ({
      ...prevError,
      provider_email: "",
    }));
    setFullNameError((prevError) => ({
      ...prevError,
      first_name: "",
      last_name: "",
    }));
  };

  const handleFileChange = (files) => {
    let totalSize = 0;
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    // Filter and validate files
    let fileTypeError = false;
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          setdocumentError(
            "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
          );
          fileTypeError = true
          return false;
        }

        // Check individual file size (2MB limit)
        if (fileSize > 25 * 1024 * 1024) {
          setdocumentError("File size exceeds the 25MB limit.");
          totalSize += fileSize;
          return false;
        }
        else {
          totalSize += fileSize;
          return true;
        }

      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
      return;
    }

    // Update state if everything is valid
    if (validatedFiles?.length > 0) {
      setAttachment({ files: validatedFiles });
      // setFilesUploaded(true)
      if (fileTypeError) {
        setdocumentError(
          "Invalid file type. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
        );
        return false;
      }
      else {
        if (totalSize > 25 * 1024 * 1024) {
          setdocumentError("Total file size exceeds the maximum limit of 25MB.");
          return;
        }
        else {
          setdocumentError(""); // Clear any existing errors
        }
      }
    } else {
      setAttachment({ files: [] }); // Clear files if no valid files
    }
  };
  const handleFileRemove = (files) => {
    const validFileTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
      "application/pdf"
    ];

    let totalSize = 0;
    let fileTypeError = false;

    // Revalidate remaining files after one is removed
    const validatedFiles = files.filter((file) => {
      if (file) {
        const { type: fileType, size: fileSize } = file;

        // Check for valid file type
        if (!validFileTypes.includes(fileType)) {
          fileTypeError = true;
          return false;
        }

        totalSize += fileSize;
        return true;
      }
      return false;
    });

    // Check total size limit (25MB)
    if (totalSize > 25 * 1024 * 1024) {
      setdocumentError("Total file size exceeds the maximum limit of 25MB.");
    } else if (fileTypeError) {
      setdocumentError(
        "Invalid file type detected. Only PDF, SVG, PNG, JPG, or GIF files are allowed."
      );
    } else {
      setdocumentError(""); // Clear any existing errors if valid
    }

    // Update state with the validated files
    setAttachment({ files: validatedFiles });

    // Handle edge case when all files are removed
    if (validatedFiles?.length === 0) {
      // setFilesUploaded(false);
    }


    if (files.length === 0) {
      // setFilesUploaded(false)
    }
  };

  const calculateTotalAttachmentSize = () => {
    let totalSize = 0;
    attachment.files.forEach((item) => {
      if (item?.size) {
        totalSize += item?.size;
      }
    });
    return totalSize;
  };
  const selectDocType = (selectedOption) => {
    setSelectedDocumentType(selectedOption);
    // const providerTypeUIID=(props?.searchProviderOptions).filter
    props?.setFormErrors((prevErrors) => ({
      ...prevErrors,
      selectDoc: "",
    }));
  };

  // const handleEmailSearchChange = useCallback(debounce(handleEmail), []);
  const selectCompChecklist = (selectedOption) => {
    setSelCompChecklist(selectedOption);
    setChecklistError("");
  };
  const selectWorkerCompChecklist = (selectedOption) => {
    setWorkerSelCompChecklist(selectedOption);
    setWorkerChecklistError("");
  };
  // Custom option for the input value
  const customOption = { value: emailSearch, label: emailSearch };
  const customEmailOption = { value: search, label: search };
  // Check if the inputValue exists in options, if not, add it;
  const updatedEmailOptions = searchOptions.find(
    (option) => option.value === emailSearch
  )
    ? searchOptions
    : [...searchOptions, customOption];

  const handleABN = () => {
    let newValue = props?.contractorData?.provider_abn_nzbn?.replace(/\D/g, ""); // Remove non-digit characters
    const length = newValue?.length;
    if (length <= 11) {
      // newValue = `${newValue.slice(0, 2)} ${newValue.slice(2)}`;
      newValue = `${newValue.slice(0, 2)} ${newValue.slice(
        2,
        5
      )} ${newValue.slice(5, 8)} ${newValue.slice(8, 11)}`;
    }
    props?.SetcontractorData((prevState) => ({
      ...prevState,
      provider_abn_nzbn: newValue,
    }));
  };

  // IndividualByEmail
  const GetIndividualByEmail = async (emailSearch) => {
    if (emailSearch) {
      props?.onGetIndividualByEmail(emailSearch).then((response) => {
        if (response?.success === true) {
          const arrayData = response?.data;
          // if is_conserve_team - false then email id doesn't exist as superadmin so it can be selected
          if (arrayData && arrayData?.is_conserve_team === false) {
            // Issue in this partt
            props?.SetcontractorData({
              ...props?.contractorData,
              first_name: arrayData?.first_name,
              last_name: arrayData?.last_name,
              provider_email: arrayData?.email,
              individual_uuid: arrayData?.individual_uuid,
              contractor_f_a_uuid: arrayData?.function_assignment_uuid,
            });

            if (arrayData?.first_name || arrayData?.last_name) {
              setFullNameError((prevError) => ({
                ...prevError,
                first_name: arrayData?.first_name ? "" : prevError.first_name,
                last_name: arrayData?.last_name ? "" : prevError.last_name,
              }));
            }
            // if (
            //   props?.contractorData.provider_abn_nzbn === "" &&
            //   arrayData?.abn
            // ) {
            //   props?.SetcontractorData((prevState) => ({
            //     ...prevState,
            //     provider_abn_nzbn: arrayData?.abn ? arrayData?.abn : "",
            //   }));
            // }
            // props?.setEmail(arrayData?.email);
          } else if (arrayData && arrayData?.is_conserve_team === true) {
            // if is_conserve_team - true then email id exist as superadmin so it can't be selected
            props?.setFormErrors((prevError) => ({
              ...prevError,
              conserveEmailReq: "This email cannot be selected",
              // Clear the error message initially
            })); // setEmailError("This email can not be selected");
            props?.SetcontractorData((prevState) => ({
              ...prevState,
              first_name: "",
              last_name: "",
              individual_uuid: "",
            }));
          }
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        }
      });
    }
  };

  useEffect(() => {
    if (emailSearch?.length > 3) {
      GetIndividualByEmail(emailSearch);
    }
  }, [emailSearch]);

  const onDialogAlreadyClose = () => {
    setCloseAlreadyExist(false);
    onDialogClose();
  };
  const onDialogClose = (e) => {
    if (!buttonDisabled) {
      setSelCompChecklist([]);
      setWorkerSelCompChecklist([])
      setSelectProviderDetails([])
      setSelectOptions([])
      props?.setPreviousFirstAbn("")
      setSelectedOption("")
      setIsOpen(false);
      setFullNameError((prevErrors) => ({
        ...prevErrors,
        first_name: "",
        last_name: "",
      }));
      setInvite_attach();
      props?.SetcontractorData((prevValue) => ({
        ...prevValue,
        individual_uuid: "",
        provider_fa_uuid: "",
        contractor_name: "",
        provider_abn_nzbn: "",
        provider_email: "",
        invite_message: "",
        trading_name: "",
        first_name: "",
        last_name: "",
        invite_attach: { files: [] },
      }));
      props?.setAbnEditOrNot(false);
      // props?.setFormErrors((prevErrors)=>({
      //   ...prevErrors,
      // }))
      props?.clearFormErrors();
    }
  };
  const OnGetAllComplianceOfClientList = useCallback(async () => {
    if (organisation_uuid) {
      try {
        const response = await dispatch(GetAllComplianceOfClientList({ client_org_uuid: organisation_uuid }));
        if (response?.success) {
          const arrayData = response?.data;

          // Transform the data to map over required fields
          const transformedData = arrayData.map((item) => {
            return {
              label: item?.checklist_name,
              value: item?.checklist_uuid,
              recipient_type: item?.recipient_type,
            };
          });

          // Filter the data by recipient_type
          const providerData = transformedData.filter(item => item.recipient_type === 'provider');
          const workerData = transformedData.filter(item => item.recipient_type === 'worker');

          // Set the filtered data to their respective states
          setAllCheckListData(providerData); // Provider data
          setWorkerAllCheckListData(workerData); // Worker data
        } else if (response?.status === 401) {
          handleUnauthorizedAccess();
        } else {
          console.error("Failed to fetch data:", response);
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    }
  }, [organisation_uuid, dispatch]);

  useEffect(() => {
    OnGetAllComplianceOfClientList();
  }, [organisation_uuid, OnGetAllComplianceOfClientList]);

  const onAddDialogClose = (e) => {
    setAddOpen(false);
  };
  let prevSearchValue = "";
  const handleSearchChange = debounce((newInputValue) => {
    if (
      prevSearchValue !== newInputValue &&
      props?.formErrors?.provider_email !== "" &&
      selectedOption != ""
    ) {
      props?.setFormErrors((prevError) => ({
        ...prevError,
        provider_email: "",
      }));
      setSelectedOption("");
    }
    props?.setFormErrors((prevError) => ({
      ...prevError,
      onlyCheckEmail: "",
      correctFormat: "",
    }));
    prevSearchValue = newInputValue;
    setEmailSearch(newInputValue);
  }, 500);

  // Handle the select option change
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (props?.abnEditOrNot == true && props?.contractorData?.provider_email) {
      props?.SetcontractorData((prevError) => ({
        ...prevError,
        provider_email: "",
      }));
    }
    if (isEmail(selectedOption?.label)) {
      props?.SetcontractorData({
        ...props?.contractorData,
        provider_email: selectedOption?.label,
      })
      if (props?.contractorData?.provider_abn_nzbn && props?.abnEditOrNot) {
        if (selectProviderDetails?.abn_nzbn) {
          props?.SetcontractorData((prevState) => ({
            ...prevState,
            provider_abn_nzbn: selectProviderDetails ? selectProviderDetails?.abn_nzbn : "",
          }));
        }
      }
      else {
        props?.SetcontractorData((prevState) => ({
          ...prevState,
          provider_abn_nzbn: selectProviderDetails ? selectProviderDetails?.abn_nzbn : "",
        }));
      }

      if (props?.previousFirstAbn && !selectProviderDetails?.abn_nzbn) {
        props?.SetcontractorData((prevState) => ({
          ...prevState,
          provider_abn_nzbn: props?.previousFirstAbn,
        }));
      }


    } else if (isElevenDigitNumber(selectedOption?.label)) {
      props?.SetcontractorData({
        ...props?.contractorData,
        provider_abn_nzbn: selectedOption?.label,
      });
    } else {
      // show message
      //Please Enter the Value in correct format
    }
    props?.setFormErrors((prevError) => ({
      ...prevError,
      conserveEmailReq: "",
      provider_abn_nzbn: "",
    }));

    GetIndividualByEmail(selectedOption?.label);
  };

  // Add Provider Code
  const inputchangehandler = (event) => {
    if (event.target.name === "provider_abn_nzbn") {
      props?.setFormError("provider_abn_nzbn", "");
    }
    if (
      event.target.name === "first_name" ||
      event.target.name === "last_name"
    ) {
      props?.SetcontractorData((prevalue) => {
        return {
          ...prevalue,
          [event.target.name]: event.target.value,
        };
      });
      setFullNameError((prevErrors) => ({
        ...prevErrors,
        [event.target.name]: "", // Clearing any previous error for this field
      }));
    }
    props?.SetcontractorData((prevalue) => {
      return {
        ...prevalue,
        [event.target.name]: event.target.value,
      };
    });
  };
  function isEmail(value) {
    // Regular expression for checking email format
    if (value !== undefined && value !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    }
  }

  function isElevenDigitNumber(value) {
    // Remove spaces and check if it's 11 digits
    if (value !== undefined && value !== "") {
      const strippedValue = value.replace(/\s/g, "");
      const numberRegex = /^\d{11}$/;
      return numberRegex.test(strippedValue);
    }
  }
  const GetFunctionUuuid = GetFunction.allFunctions.allFunction;

  function GetAllfunctionUuid() {
    if (GetFunctionUuuid) {
      for (let key in GetFunctionUuuid) {
        if (GetFunctionUuuid[key].function_name === "provider") {
          let uuid = GetFunctionUuuid[key].function_uuid;
          setfunctionUuid(uuid);
        }
      }
    }
  }

  useEffect(() => {
    GetAllfunctionUuid();
  }, [GetAllfunctionUuid]);
  const GetAllProvidersPrimaryList = async (contact_type_uuid) => {
    if (functionUuid && contact_type_uuid && emailSearch) {
      props
        .onGetAllContractorPrimaryList(
          functionUuid,
          contact_type_uuid,
          function_assignment_uuid,
          emailSearch
        )
        .then((response) => {
          if (response?.success === true) {
            const arrayData = response?.data;
            let contactorData = [];
            for (let key in arrayData) {
              contactorData.push({
                ContractorOrgName: arrayData[key]?.trading_name,
                ContractorFAUuid: arrayData[key]?.function_assignment_uuid,
                abn: arrayData[key]?.abn,
                functionUUid: arrayData[key]?.function_uuid,
                individualUuid: arrayData[key]?.individual_uuid,
                email: arrayData[key]?.email,
                relationUuid: arrayData[key]?.f_a_relation_uuid,
                ContractorFirstName: arrayData[key]?.first_name,
                ContractorLastName: arrayData[key]?.last_name,
                userUuid: arrayData[key]?.user_uuid
              });
            }
            setProviderOrgId(arrayData?.[0] ? arrayData?.[0]?.organisation_uuid : "")
            setProviderUserUuid(arrayData?.[0] ? arrayData?.[0]?.user_uuid : "");

            setSelectProviderDetails(arrayData?.[0])

            // if (props?.contractorData?.provider_abn_nzbn && props?.abnEditOrNot) {
            //   if (arrayData?.[0]?.abn_nzbn) {
            //     props?.SetcontractorData((prevState) => ({
            //       ...prevState,
            //       provider_abn_nzbn: arrayData?.[0] ? arrayData?.[0]?.abn_nzbn : "",
            //     }));
            //   }
            // }
            // else {
            //   props?.SetcontractorData((prevState) => ({
            //     ...prevState,
            //     provider_abn_nzbn: arrayData?.[0] ? arrayData?.[0]?.abn_nzbn : "",
            //   }));
            // }
            // if (props?.previousFirstAbn && !arrayData?.[0]?.abn_nzbn) {
            //   props?.SetcontractorData((prevState) => ({
            //     ...prevState,
            //     provider_abn_nzbn: props?.previousFirstAbn,
            //   }));
            // }




            // if (
            //   props?.contactorData?.[0]?.ContractorFirstName ||
            //   props?.contactorData?.[0]?.ContractorLastName
            // ) {
            //   props?.SetcontractorData((prevError) => ({
            //     ...prevError,
            //     first_name: props?.contactorData?.[0]?.ContractorFirstName
            //       ? ""
            //       : prevError.first_name,
            //     last_name: props?.contactorData?.[0]?.ContractorLastName
            //       ? ""
            //       : prevError.last_name,
            //   }));
            // }

            // if provider exist globally then if exist under the same client or not

            const listData = [];
            if (contactorData) {
              contactorData.map((item) => {
                if (!item?.relationUuid) {
                  listData.push(item);
                }
              });
            }

            setSelectOptions(listData);
            // props?.setContactorDataUse(contactorData)

            // if relationUuid exist then user cannot select that email/abn as that provider exist under the same client
            const checkByEmail = contactorData.find(
              (item) => item?.email === emailSearch
              // (item) => item?.email === contactorData.provider_email
            );
            if (checkByEmail?.relationUuid) {
              props?.setFormErrors((prevErrors) => ({
                ...prevErrors,
                provider_email:
                  "This email cannot be selected because it alredy exist as provider for this client",
              }));

              setTimeout(() => {
                props?.SetcontractorData((prevState) => ({
                  ...prevState,
                  provider_email: ""
                }));
              }, 3000);

              props?.SetcontractorData((prevState) => ({
                ...prevState,
                first_name: "",
                last_name: "",
                individual_uuid: "",
              }));
            }
          } else if (response?.status === 401) {
            handleUnauthorizedAccess();
          } else {
          }
        });
    }
  };

  let contactTypeUuid = GetFunction.AllContactTypes.filter(
    (item) => item?.contact_type === "provider primary"
  );

  useEffect(() => {
    if (GetFunction.AllContactTypes) {
      if (emailSearch?.length > 3) {
        GetAllProvidersPrimaryList(contactTypeUuid[0].contact_type_uuid);
      }
    }
  }, [emailSearch]);

  // handleBlurForEmail function calls GetIndividualByEmail api when users user click outside the input/select tag for email after entering email
  // const handleBlurForEmail = () => {
  //   GetIndividualByEmail();
  // };

  const onSubmitForm = () => {
    //Validate Email
    const inputValue = selectedOption?.label;
    let abnValidation;
    let correctFormatVal;
    let emailValidation;
    const CheckListValidation = Validations({
      value: selCompChecklist?.length == 0 ? "" : selCompChecklist,
      Validations: { required: true },
    });
    if (!CheckListValidation?.valid) {
      setChecklistError(CheckListValidation?.error_msg);
    }

    const WorkerCheckListValidation = Validations({
      value: workerSelCompChecklist?.length == 0 ? "" : workerSelCompChecklist,
      Validations: { required: true },
    });
    if (!WorkerCheckListValidation?.valid) {
      setWorkerChecklistError(WorkerCheckListValidation?.error_msg);
    }

    if (
      props?.contractorData?.provider_email?.trim() === "" ||
      !selectedOption?.label
    ) {
      emailValidation = Validations({
        value: props?.contractorData?.provider_email,
        Validations: { email: true, required: true },
      });
      props?.setFormErrors(
        "provider_email",
        props?.contractorData?.provider_email?.trim() === ""
          ? "This field is required!"
          : "Please enter the value in the correct format"
      );
    }
    if (isEmail(inputValue)) {
      emailValidation = Validations({
        value: props?.contractorData?.provider_email,
        Validations: { email: true, required: true },
      });
      if (!emailValidation?.valid) {
        props?.setFormErrors("provider_email", emailValidation.error_msg);
      }
    }

    if (!props?.formErrors?.provider_email) {
      if (
        (props?.contractorData?.provider_abn_nzbn).trim() !== "" ||
        isElevenDigitNumber(inputValue)
      ) {
        abnValidation = Validations({
          value: props?.contractorData?.provider_abn_nzbn,
          Validations: { required: true, ValidateABN: true },
        });

        if (!abnValidation?.valid) {
          props?.setFormErrors((prevError) => ({
            ...prevError,
            provider_abn_nzbn: abnValidation.error_msg,
          }));
        }
      }
      if (!props?.contractorData?.provider_email) {
        correctFormatVal = Validations({
          value: inputValue,
          Validations: { email: true, required: true },
        });
        if (!correctFormatVal.valid) {
          props?.setFormErrors((prevError) => ({
            ...prevError,
            correctFormat:
              inputValue == "" || inputValue == undefined
                ? "This field is required!"
                : "Please enter the value in the correct format",
          }));
        }
      }

      const checllistIds = selCompChecklist?.map((item) => item?.value);
      const workerChecllistIds = workerSelCompChecklist?.map((item) => item?.value);
      const fNameValidation = Validations({
        value: props?.contractorData?.first_name,
        Validations: { required: true },
      });
      const eNameValidation = Validations({
        value: props?.contractorData?.last_name,
        Validations: { required: true },
      });
      if (!fNameValidation?.valid) {
        setFullNameError((prevErrors) => ({
          ...prevErrors,
          first_name: fNameValidation.error_msg,
        }));
      }
      if (!eNameValidation?.valid) {
        setFullNameError((prevErrors) => ({
          ...prevErrors,
          last_name: eNameValidation.error_msg,
        }));
      }

      const totalSize = calculateTotalAttachmentSize();
      if (totalSize > 25 * 1024 * 1024) {
        return;
      }

      if (
        CheckListValidation?.valid == true &&
        WorkerCheckListValidation?.valid == true &&
        !props?.formErrors?.correctFormat &&
        eNameValidation?.valid &&
        documentError == "" &&
        emailValidation?.valid === true &&
        fNameValidation?.valid == true &&
        abnValidation?.error_msg != "Please enter valid ABN/NZBN" &&
        !props?.formErrors?.provider_abn_nzbn
      ) {
        const payload = {
          individual_uuid: props?.contractorData?.individual_uuid
            ? props?.contractorData?.individual_uuid
            : "",
          provider_fa_uuid: props?.contractorData?.contractor_f_a_uuid
            ? props?.contractorData?.contractor_f_a_uuid
            : "",
          first_name: props?.contractorData?.first_name,
          last_name: props?.contractorData?.last_name,
          provider_abn_nzbn: props?.contractorData?.provider_abn_nzbn
            ? props?.contractorData?.provider_abn_nzbn
            : "",
          email: props?.contractorData?.provider_email,
          invite_message: props?.contractorData?.invite_message,
          client_fa_uuid: function_assignment_uuid,
          provider_type_uuid: selectedDocumentType?.value
            ? selectedDocumentType?.value
            : "",
          invite_attach: attachment,
          checklistIds: JSON.stringify(checllistIds.length ? checllistIds : []),
          workerChecklistIds: JSON.stringify(workerChecllistIds.length ? workerChecllistIds : []),
          client_org_uuid: organisation_uuid,
          current_user: current_user,
          trading_name: trading_name,
          provider_user_uuid: providerUserUuid,
        };

        setButtonDisabled(true);
        dispatch(InviteSpecificProvider(payload))
          .then((response) => {
            if (response?.success) {
              // props?.SetcontractorData();
              setAddOpen(true);
              setProviderUserUuid("")
              setIsOpen(false);
              setProviderOrgId("")

              setButtonDisabled(false);
              onDialogClose();
              props?.setSelectedDocumentType("");
            } else if (response?.status === 409) {
              setCloseAlreadyExist(true);
              setProviderUserUuid("")
              setProviderOrgId("")

              setButtonDisabled(false);
            } else if (response?.status === 401) {
              handleUnauthorizedAccess();
              setButtonDisabled(false);
            } else {
              setButtonDisabled(false);
              setProviderUserUuid("")
              setProviderOrgId("")

            }
          })
          .catch((error) => {
            setButtonDisabled(false);
            setProviderUserUuid("")
          });
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        width={720}
      >
        <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
          <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
            <img src={contractorImg} alt="" />
          </span>
          <div>
            <h5 className="mb-0 text-gray-900 text-base font-semibold">
              Add a Provider
            </h5>
            <p>Find an existing Provider in the system or invite a new one.</p>
          </div>
        </div>
        <form className="px-6">
          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Provider type*
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select document"
                options={props?.searchProviderOptions}
                value={selectedDocumentType}
                readOnly
                onChange={(selectedOption) => selectDocType(selectedOption)}
              ></Select>
              {props?.formErrors.selectDoc && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {props?.formErrors.selectDoc}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-1 gap-4">
            <div className="w-3/6">
              <Label className="text-xs font-medium block mb-1">
                Email address*
              </Label>
              <div className="w-full hide-select-elt">
                {props?.abnEditOrNot == false || props?.contractorData?.provider_email ? (
                  <Input
                    className="h-9 w-full"
                    placeholder=""
                    value={props?.contractorData.provider_email}
                    readOnly={
                      props?.contractorData?.provider_email ? true : false
                    }
                  />
                ) : (
                  <Select
                    className="w-full h-10 select-options"
                    placeholder="Search for a Provider"
                    isClearable={true}
                    isSearchable={true}
                    options={
                      updatedEmailOptions ? updatedEmailOptions : selectedOption
                    }
                    value={selectedOption || customOption}
                    onChange={(selectedValue) => {
                      if (!selectedValue) {
                        isSearchClear();
                      }
                      handleSelectChange(selectedValue);
                    }}
                    handleSearchChange={handleSearchChange}
                  />
                )}

                {props?.formErrors?.conserveEmailReq ? (
                  <p className="text-xs text-red-600 mb-0 mt-1">
                    {props?.formErrors?.conserveEmailReq}
                  </p>
                ) : props?.formErrors.onlyCheckEmail ? (
                  <p className="text-xs text-red-600 mb-4 mt-0">
                    {props?.formErrors.onlyCheckEmail}
                  </p>
                ) : props?.formErrors.provider_email ? (
                  <p className="text-xs text-red-600 mb-0 mt-1">
                    {props?.formErrors?.provider_email}
                  </p>
                ) : props?.formErrors?.correctFormat ? (
                  <p className="text-xs text-red-600 mb-0 mt-1">
                    {props?.formErrors?.correctFormat}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="w-3/6">
              <Label className="text-xs font-medium block mb-1">ABN/NZBN</Label>
              <ClientInput
                className="h-9 w-full"
                placeholder=""
                inputchangehandler={inputchangehandler}
                name="provider_abn_nzbn"
                onBlur={handleABN}
                maxLength="14"
                readOnly={props?.abnEditOrNot ? true : false}
                value={props?.contractorData?.provider_abn_nzbn || ""}
              />

              {props?.formErrors?.provider_abn_nzbn && (
                <p className="text-xs text-red-600 mb-0 mt-1">
                  {props?.formErrors.provider_abn_nzbn}
                </p>
              )}
            </div>
          </div>
          <div className="mt-5">
            <div className="flex flex-1 gap-4">
              <div className="mb-5 w-3/6">
                <Label className="text-xs font-medium block mb-1">
                  First name*
                </Label>

                <ClientInput
                  type="text"
                  className="h-9 w-full"
                  placeholder="Enter Provider’s first name"
                  value={props?.contractorData?.first_name}
                  name={"first_name"}
                  inputchangehandler={inputchangehandler}
                  readOnly={
                    props?.contractorData?.individual_uuid ? true : false
                  }
                />
                {fullNameError?.first_name && (
                  <p className="text-xs text-red-600 mb-0 mt-1">
                    {fullNameError?.first_name}
                  </p>
                )}
              </div>
              <div className="w-3/6">
                <Label className="text-xs font-medium block mb-1">
                  Last name*
                </Label>
                <ClientInput
                  type="text"
                  className="h-9 w-full"
                  placeholder="Enter Provider’s last name"
                  value={props?.contractorData?.last_name}
                  name={"last_name"}
                  inputchangehandler={inputchangehandler}
                  readOnly={
                    props?.contractorData?.individual_uuid ? true : false
                  }
                />
                {fullNameError?.last_name && (
                  <p className="text-xs text-red-600 mb-0 mt-1">
                    {fullNameError?.last_name}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Assign Provider Compliance Checklist(s)
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select Compliance Checklist"
                isMulti={true}
                options={allCheckListData}
                value={selCompChecklist}
                onChange={(selectedOption) =>
                  selectCompChecklist(selectedOption)
                }
              ></Select>
              {selCompChecklist?.length === 0 && checkListError && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {checkListError}
                </p>
              )}

              {/* <Select
                      value={selectedOption}
                      onChange={(e) => HandleDocData(e, "document_type_uuid")}
                      options={Array.isArray(selectDocumentData) ? selectDocumentData : []}
                    /> */}
            </div>
          </div>
          <div className="mb-5">
            <label className="font-medium text-gray-700 w-1/4">
              Assign Worker Compliance Checklist(s)
            </label>
            <div className="w-full hide-select-elt">
              <Select
                className="w-full h-9 select-options"
                placeholder="Select Compliance Checklist"
                isMulti={true}
                options={workerAllCheckListData}
                value={workerSelCompChecklist}
                onChange={(selectedOption) =>
                  selectWorkerCompChecklist(selectedOption)
                }
              ></Select>
              {workerSelCompChecklist?.length === 0 && workerCheckListError && (
                <p className="text-xs text-red-600 mb-2 mt-0">
                  {workerCheckListError}
                </p>
              )}

              {/* <Select
                      value={selectedOption}
                      onChange={(e) => HandleDocData(e, "document_type_uuid")}
                      options={Array.isArray(selectDocumentData) ? selectDocumentData : []}
                    /> */}
            </div>
          </div>
          <div className="mb-5">
            <Label className="text-xs font-medium block mb-1">
              Attachment (optional)
            </Label>
            <Upload
              draggable
              className="border border-gray-200 rounded-xl py-4 px-6 w-full text-center"
              onChange={handleFileChange}
              onFileRemove={handleFileRemove}
              multiple={true}
              maxFileSize={25 * 1024 * 1024}
            >
              <span className="text-blue-700 font-semibold">
                Click to upload
              </span>{" "}
              <span className="font-medium text-gray-600">
                or drag and drop
              </span>
              <p className="text-gray-600">(Max 25mb. Only pdf, heic, png, and jpg files will be accepted)</p>
            </Upload>
            {documentError && <p className='text-xs text-red-600 mt-1'>{documentError}</p>}

          </div>
          <div className="mb-5 message-box-height">
            <Label className="text-xs font-medium block mb-1">
              Message (optional)
            </Label>
            <RichTextEditor
              onChange={(value) => {
                props?.SetcontractorData((prevalue) => {
                  return {
                    ...prevalue,
                    ["invite_message"]: value,
                  };
                });
              }}
              name="invite_message"
              value={props?.contractorData.invite_message}
            />
          </div>
          <div className="flex dailog-footer ">
            <BtnLight
              disabled={buttonDisabled}
              className="mr-1.5"
              variant="plain"
              onClick={onDialogClose}
            >
              Cancel
            </BtnLight>
            <BtnPrimary
              onClick={() => onSubmitForm()}
              disabled={buttonDisabled}
              className="ml-1.5"
            >
              {buttonDisabled == true ? "Adding..." : "Add Provider"}
            </BtnPrimary>
          </div>
        </form>
      </Dialog>

      <Dialog
        isOpen={dialogAddOpen}
        onClose={onAddDialogClose}
        onRequestClose={onAddDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="22"
              height="23"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.75 11.727 8.75-8.75M8.856 12l2.19 5.632c.193.496.29.744.428.816.12.063.264.063.385 0 .14-.072.236-.32.43-.816L17.78 3.56c.175-.447.262-.671.215-.814a.417.417 0 0 0-.264-.263c-.143-.048-.366.04-.814.214L2.844 8.188c-.496.193-.744.29-.816.43a.417.417 0 0 0 0 .384c.072.139.32.235.817.428l5.631 2.19c.1.04.151.06.194.09.037.026.07.06.097.097.03.042.05.092.089.193z"
                stroke="#000"
                strokeWidth="1.667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Invite sent successfully!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onAddDialogClose}>Close</BtnPrimary>
        </div>
      </Dialog>
      <Dialog
        isOpen={dialogAlreadyOpen}
        onClose={onDialogAlreadyClose}
        onRequestClose={onDialogAlreadyClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="48" height="48" rx="24" fill="#FEE4E2" />
              <path
                d="M21.5 21L27.5 27M27.5 21L21.5 27M20.3 33H28.7C30.3802 33 31.2202 33 31.862 32.673C32.4265 32.3854 32.8854 31.9265 33.173 31.362C33.5 30.7202 33.5 29.8802 33.5 28.2V19.8C33.5 18.1198 33.5 17.2798 33.173 16.638C32.8854 16.0735 32.4265 15.6146 31.862 15.327C31.2202 15 30.3802 15 28.7 15H20.3C18.6198 15 17.7798 15 17.138 15.327C16.5735 15.6146 16.1146 16.0735 15.827 16.638C15.5 17.2798 15.5 18.1198 15.5 19.8V28.2C15.5 29.8802 15.5 30.7202 15.827 31.362C16.1146 31.9265 16.5735 32.3854 17.138 32.673C17.7798 33 18.6198 33 20.3 33Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            This Provider is already invited!
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6">
          <BtnPrimary onClick={onDialogAlreadyClose}>Close</BtnPrimary>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  // onInviteSpecificClientContractor: userActions.InviteSpecificProvider,
  onGetAllContractorPrimaryList: userActions.GetAllProvidersPrimaryList,
  onGetIndividualByEmail: userActions.GetIndividualByEmail,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddContractor);
