import React, { useState, useRef } from "react";
import Tabs from '../../../../../../../common/element/Tabs';
import ClientDetails from "./ClientDetails";
import Users from "./User";
import Accreditations from "./Accreditation";
import ComplianceChecklistDate from "./ComplianceDate";

const ClientSettings = (props) =>{
  const { TabNav, TabList, TabContent } = Tabs;

  return (
    <div>
      <Tabs defaultValue="tab1" variant="pill">
        <div className='p-3'>
          <div className="bg-blue-25 border border-gray-100 p-1 rounded-lg">
            <TabList className="flex">
              <TabNav className="flex-1" value="tab1">Client Details</TabNav>
              <TabNav className="flex-1" value="tab2">Users</TabNav>
              <TabNav className="flex-1" value="tab3">Accreditation</TabNav>
              <TabNav className="flex-1" value="tab4">Compliance Checklist</TabNav>
            </TabList>
          </div>
        </div>
        <div className="pl-6">
          <TabContent value="tab1">
            <ClientDetails userSettingHide={props?.userSettingHide}/>
          </TabContent>
          <TabContent value="tab2">
            <Users tradingName={props?.tradingName} />
          </TabContent>
          <TabContent value="tab3">
            <Accreditations userSettingHide={props?.userSettingHide}/>
          </TabContent>
          <TabContent value="tab4">
            <ComplianceChecklistDate userSettingHide={props?.userSettingHide}/>
          </TabContent>
        </div>
      </Tabs>
    </div>
  );
};

export default ClientSettings;
