import axios from "axios";
import * as actionType from "../../constant/actionType";
import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/client-portal/inductionModuleDataSlice";
export const GetCompanyInductionAndModulesbyId = (getAllData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      // dispatch(fetchData());
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/client/GetCompanyInductionAndModulesbyId`,
        params: {company_induction_uuid:getAllData}
      });
      return response.data
      // dispatch(fetchDataSuccess(response.data));
      
    } catch (error) {
      return error.message
      // dispatch(fetchDataError(error.message));
    }
  };