import React, { useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import DocType from "../pages/Admin/SystemConfig/DocType"
import Users from "../pages/Admin/Users"
import ServicesTypes from '../pages/Admin/SystemConfig/ServicesTypes';
import EmailTemplate from '../pages/Admin/SystemConfig/EmailTemplates';
import ResetPassword from '../pages/Admin/Users/ResetPassword';
import AuditLog from "../pages/Admin/SystemLogs/AuditLog";
import EmailLog from "../pages/Admin/SystemLogs/EmailLog";
import AdminSettings from "../pages/Admin/Settings/index";
import Notifications from "../pages/Admin/Notifications";
import Notifaction from "../pages/Admin/Settings/Notification";
import Dashboard from "../pages/Admin/Dashboard";
import ProviderType from "../pages/Admin/SystemConfig/ProvideType/index";
function useLocalStoragePath() {
    const location = useLocation();
    useEffect(() => {
      localStorage.setItem("currentPath", location.pathname);
    }, [location.pathname]);
    return location.pathname;
  }
export const AdminPortalAdminRoutes =()=>{
    const currentPath = useLocalStoragePath();
    return(
        <React.Fragment>
            <Routes>
                <Route path="dashboard" element={<Dashboard />}/>
                <Route path="audit-log" element={<AuditLog/>} />
                <Route path="email-log" element={<EmailLog/>} />
                <Route path="users" element={<Users />} />
                <Route path="notifications" element={<Notifications/>}/>
                <Route path="doc-type" element={<DocType />}/>
                {/* <Route path="reset-password" element={<ResetPassword />} /> */}
                <Route path="services-types" element={<ServicesTypes />} />
                <Route path="email-templates" element={<EmailTemplate />} />
                <Route path="provider-types" element={<ProviderType/>}/>
                <Route path="admin-setting" element={<AdminSettings />} />
            </Routes>
        </React.Fragment>
    )
}