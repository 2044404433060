import axios from "axios";
import * as actionType from "../../constant/actionType";

export const GetAllProviderList = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        },
        url: `${actionType.environment_url}/api/admin/provider/GetAllProviderList`,
        params: apiData
      });
      return response.data

    } catch (error) {
        return error.message  
    }
  };

  export const  GetAllClientList = (apiData) => async (dispatch) => {
    /*** API Call Action ***/
    try {
      const response = await axios({
        method: 'get',
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
        // },
        url: `${actionType.environment_url}/api/GetAllClientList`,
        params: apiData
      });
      return response.data

    } catch (error) {
        return error.message  
    }
  };
      
export const InviteSpecificProvider = (form_data) => async (dispatch) => {
  try {
    let formdata = new FormData();
    for (let key in form_data) {
      if (key === "invite_attach") {
          if(form_data[key] != undefined) {
          form_data[key].files.forEach((file, i) => {
              formdata.append(`invite_attach`, file)
          })
      }
      } else {
          formdata.append(key, form_data[key]);
      }
  }
    const response = await axios({
      method: "post",
      data: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("conserve_authtoken")}`,
      },
      url: `${actionType.environment_url}/api/admin/provider/InviteSpecificProvider`,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

