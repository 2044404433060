// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const closeDocument = createSlice({
  name:"closeDocument",
  initialState:false,
  reducers:{
    closeDocumentfunc(state,action){
      return action.payload;
    },
  }
}) 
export default closeDocument.reducer;
export const {closeDocumentfunc}=closeDocument.actions;