// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const RegistrationValidation = createSlice({
  name:"UpdateAvtar",
  initialState:false,
  reducers:{
    RegistrationValidationCheck(state,action){
      return action.payload;
    },
  }
}) 
export default RegistrationValidation.reducer;
export const {RegistrationValidationCheck}=RegistrationValidation.actions;