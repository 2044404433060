const BtnLight =(props) =>{
    const classes = "whiteBtnShadow btn border border-gray-100 block w-full rounded-lg h-10 bg-white text-gray-700 text-sm font-semibold hover:bg-gray-50 hover:border-gray-300 focus:bg-white focus:border-gray-300 duration-100 secondary-btn " + props.className;
    return(
        <button className={classes} disabled={props.disabled} type={props.type || "button"}
        onClick={props.onClick}>
            {props.children}
        </button>
    )
}

export default BtnLight