// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const inductionDetailSlice = createSlice({
  name:"InductionDetailsFormData",
  initialState:[],
  reducers:{
    InductuionDetailForm(state,action){
      return (action.payload)
    },
    RestInductuionDetailForm(){
        return []
    }
  }
}) 
export const {InductuionDetailForm,RestInductuionDetailForm}=inductionDetailSlice.actions;
export default inductionDetailSlice.reducer;