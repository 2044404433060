// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const inductionModuleDataSlice = createSlice({
  name: "InductionModuleData",
  initialState: [], // Always initialize with an empty array
  reducers: {
    AddInductionModuleData(state, action) {
      state.push(action.payload);
    },
    UpdateInductionModuleData: (state, action) => {
      const { indexData, moduleData } = action.payload;
      state[indexData] = moduleData;
    },
    DeleteInductionModuleDataByIndex: (state, action) => {
      const {indexData} = action.payload;
      if (indexData >= 0 && indexData < state.length) {
        state.splice(indexData, 1);
      }
    },
    ResetInductionModuleData() {
      return []; // Always return an array when resetting
    }
  }
})

export const {AddInductionModuleData,ResetInductionModuleData,UpdateInductionModuleData,DeleteInductionModuleDataByIndex}=inductionModuleDataSlice.actions;
export default inductionModuleDataSlice.reducer;