import axios from "axios";
import * as actionType from "../../constant/actionType";
// import { fetchData,fetchDataSuccess, fetchDataError } from "../../redux/classes/AdminContractor/GetDocumentHistoryByIdSlice";
export const GetAllWorkerArticles = () => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.helpScout_url}/collections/${actionType.worker_collectionId}/articles`,
      auth: {
        username: `${actionType.helpScout_api_key}`,
        password: `${actionType.helpScout_password}`
      }
    });
    return response.data
  } catch (error) {
    console.error("Error fetching client articles:", error);
  }
};


export const GetSearchWorkerArticles = (searchQuery) => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conserve_authtoken')}`,
      },
      url: `${actionType.helpScout_url}/search/articles?query=${encodeURIComponent(searchQuery)}&collectionId=${actionType.worker_collectionId}`,
      auth: {
        username: `${actionType.helpScout_api_key}`,
        password: `${actionType.helpScout_password}`
      }
    });
    return response.data
  } catch (error) {
    console.error("Error fetching client articles:", error);
  }

}