import React, { useState, useCallback, useEffect } from 'react'
import Segment from '../../../../../../../common/element/Segment';
import BtnPrimary from '../../../../../../../common/element/Buttons/btnPrimary'
import BtnLight from '../../../../../../../common/element/Buttons/btnLight'
import Select from "../../../../../../../common/element/Select/Select";
import DocumentsList from './DocumentList';
import moment from 'moment';
import searchIcon from "../../../../../../../assets/icons-svg/search-lg.svg";
import Dialog from "../../../../../../../common/element/Dialog/Dialog";
import ReactPaginate from "react-paginate";
import Scrollbar from '../../../../../../../common/element/ScrollBar';
import { Pagination } from '../../../../../../../common/pagination';
import Checkbox from '../../../../../../../common/element/Checkbox';
import Input from "../../../../../../../common/element/Input";
import fileCheck from "../../../../../../../assets/icons-svg/file-check.svg";
import Upload from "../../../../../../../common/element/Upload";
import { debounce } from '../../../../../../../common/snippet/Debouncing';
import { useDispatch, useSelector } from "react-redux";
import Dropdown from './Dropdown';
import { useParams } from "react-router-dom";
import { GetComplianceChecklist } from '../../../../../../../actions/Admin-Client/Admin-Client';
import Table from '../../../../../../../common/element/Table';
import { GetProviderDocsAgainstClient, GetAllAssignedChecklistOfProvider, UpdateProviderChecklistAssign } from '../../../../../../../actions/client-portal/AllSiteApi';
const DocumentList = (props) => {
   const { Tr, Th, Td, THead, TBody } = Table;
   const dispatch = useDispatch();
   const { organisationUuid } = useParams();
   const [dialogIsOpen, setIsOpen] = useState(false)
   const [dialogChoose, setChoose] = useState(false)
   const [filterpage, setfilterpage] = useState(1);
   const [alreadyAssigned, setAlreadyAssigned] = useState([])
   const [filterlimit, setfilterlimit] = useState(10);
   const [totalPages, setTotalPages] = useState(0);
   const [filterorder, setFilterOrder] = useState("desc");
   const [openAssignChecklist, setOpenAssignChecklist] = useState(false)
   const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
   const [providerCompCheckList, setProviderCompCheckList] = useState([]);
   const [countData, setCountData] = useState(0);
   const [filtersort, setFiltersort] = useState("doc_name");
   const [approvalStatus, setApprovalStatus] = useState({ doc_type_name: [], approval_status: [], isPendindUpload: "false" })
   const [searchTerm, setsearchTerm] = useState("");
   const [dialogAddOpen, setAddOpen] = useState(false)
   const [singleSegmentValue, setSingleSegmentValue] = useState(['View All'])
   const [allDocData, setAllDocData] = useState([])
   const [providerD, setProviderD] = useState([])
   const [checklistIds, setChecklistIds] = useState([]);
   const [checkedItems, setCheckedItems] = useState([]);
   const [removeChecklistIds, setRemoveChecklistIds] = useState([]);
   let organisation_uuid;
   let client_uuid;
   const loginData = JSON.parse(localStorage.getItem("LoginUserData"));
   if (loginData) {
      organisation_uuid = loginData?.organisation_uuid;
      client_uuid = loginData?.userName?.user_uuid;

   }
   const onSingleSelectionSegmentChange = useCallback(
      (val) => {
         setSingleSegmentValue(val)
      },
      [setSingleSegmentValue]
   )

   const OnGetAllAssignedChecklistOfProvider = useCallback(async () => {
      if (openAssignChecklist) {
         try {
            const payload = { provider_org_uuid: organisationUuid, client_org_uuid: organisation_uuid };
            const response = await dispatch(GetAllAssignedChecklistOfProvider(payload));
            const { success, data, status } = response;
            if (success) {
               const arrayData = data?.data;
               // setAlreadyAssigned(rows); // Assuming `rows` is what you need to set
               setProviderCompCheckList(arrayData);
               const alreadyAssignedChecklist = arrayData.filter((ele) => ele?.compAssigns && Object.keys(ele?.compAssigns)?.length > 0);
               setCheckedItems(alreadyAssignedChecklist);
            } else if (status === 401) {
               // handleUnauthorizedAccess(); // Uncomment and define this if needed
            } else {
               console.error('Failed to fetch compliance checklist:', response);
               // Handle other types of errors or unsuccessful responses
            }
         } catch (error) {
            console.error('Failed to fetch compliance checklist:', error);
            // Handle unexpected errors
         }
      }
   }, [dispatch, organisationUuid, organisation_uuid, openAssignChecklist]);

   useEffect(() => {
      OnGetAllAssignedChecklistOfProvider();
   }, [organisationUuid, organisation_uuid, openAssignChecklist]);


   const onCheckboxChange = (isChecked, item) => {
      const existingIndex = checkedItems.findIndex(
         (ele) => ele.checklist_uuid === item.checklist_uuid
      );

      if (isChecked && existingIndex === -1) {
         // Item is checked (added)
         setCheckedItems((prevItems) => [...prevItems, item]);
         setChecklistIds((prevIds) => [...prevIds, item.checklist_uuid]);
         setRemoveChecklistIds((prevIds) =>
            prevIds.filter((id) => id !== item.checklist_uuid)
         );
      } else if (!isChecked && existingIndex !== -1) {
         // Item is unchecked (removed)
         setCheckedItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems.splice(existingIndex, 1);
            return updatedItems;
         });
         setRemoveChecklistIds((prevIds) => [...prevIds, item.checklist_uuid]);
         setChecklistIds((prevIds) =>
            prevIds.filter((id) => id !== item.checklist_uuid)
         );
      }
   };
   const onCloseConfirmDialog = () => {
      setOpenConfirmDialog(false)
   }
   const onConfirm = () => {
      // console.log(checklistIds, removeChecklistIds)
      const payload = {
         assignChecklistIds: JSON.stringify(checklistIds),
         removeChecklistIds: JSON.stringify(removeChecklistIds),
         provider_org_uuid: organisationUuid,
         client_org_uuid: organisation_uuid
      }
      // console.log(payload);
      dispatch(UpdateProviderChecklistAssign(payload))
         .then((res) => {
            if (res?.success === true) {
               OnGetProviderDocsAgainstClient()
               setOpenConfirmDialog(false)
               setOpenAssignChecklist(false)
               setRemoveChecklistIds([])
               setCheckedItems([])
               setChecklistIds([])
            }
         }).catch((error) => {
            console.log(error);
         })

   }
   const onCloseAssignChecklist = () => {
      setOpenAssignChecklist(false)
      setCheckedItems([])
   }
   const openChoose = () => {
      setChoose(true)
   }
   const onChooseClose = (e) => {
      setChoose(false);
   }
   const openDialog = () => {
      setIsOpen(true)
      setChoose(false);
   }
   const onDialogClose = (e) => {
      setIsOpen(false);
   }
   const openAddDialog = () => {
      setAddOpen(true)
      setIsOpen(false);
   }
   const onAddDialogClose = (e) => {
      setAddOpen(false)

   }
   const searchOptions = [
      {
         value: "Selected insurance document name here",
         label: "Selected insurance document name here",
      },
      {
         value: "Selected SWMS document name here",
         label: "Selected SWMS document name here",
      },
      {
         value: "Selected Others document name here",
         label: "Selected Others document name here",
      },
   ];

   const OnGetProviderDocsAgainstClient = useCallback(async () => {
      if (organisation_uuid && props?.organisation_uuid) {
         const payload = {
            client_org_uuid: organisation_uuid,
            provider_org_uuid: props?.organisation_uuid,
            approvalStatus: approvalStatus?.approval_status?.length == 0 ? "" : JSON.stringify(approvalStatus?.approval_status),
            docTypeIds: approvalStatus?.doc_type_name?.length == 0 ? "" : JSON.stringify(approvalStatus?.doc_type_name),
            isPendingUpload: approvalStatus?.isPendindUpload,
            sort: filtersort,
            order: filterorder,
            page: filterpage,
            limit: filterlimit,
            search: searchTerm,
         }
         try {
            const result = await dispatch(GetProviderDocsAgainstClient(payload));
            if (result?.success) {
               // const arrayData = result?.data?.rows;
               const arrayData = result?.data
               setCountData(result?.data?.data?.count);
               setTotalPages(Math.ceil(result?.data?.data?.count / filterlimit));
               setAllDocData(arrayData)
               setProviderD(result?.data?.data?.rows)

            } else {
               console.error('Error:', result);
            }
         } catch (error) {
            console.error('Dispatch error:', error);
         }
      }
   }, [dispatch, organisation_uuid, props?.organisation_uuid, approvalStatus?.doc_type_name, approvalStatus?.approval_status,
      filtersort, filterorder, filterpage, filterlimit, searchTerm]);

   useEffect(() => {
      OnGetProviderDocsAgainstClient();
   }, [OnGetProviderDocsAgainstClient, organisation_uuid, props?.organisation_uuid, approvalStatus?.approval_status,
      approvalStatus?.doc_type_name, filtersort, filterorder, filterpage, filterlimit, searchTerm]);

   const handlePageChange = ({ selected }) => {
      setfilterpage(selected + 1);
      OnGetProviderDocsAgainstClient()
   };
   const handleSeachChange = (e) => {
      setsearchTerm(e.target.value);
      setfilterpage(1);
   };
   const inputchangeSearch = useCallback(debounce(handleSeachChange), []);

   const handleSort = (column) => {
      // Handle sorting of the service list
      if (column === filtersort) {
         setFilterOrder(filterorder === "asc" ? "desc" : "asc");
      } else {
         setFiltersort(column);
         setFilterOrder(filterorder === "asc" ? "desc" : "asc");
      }
   };
   return (
      <React.Fragment>
         <div className="flex justify-between mb-5">
            <div className="flex gap-2">
               <div className="input-wrapper w-80">
                  <div className="input-suffix-start left-3.5">
                     <img src={searchIcon} alt="" className="h-5" />
                  </div>
                  <input
                     type="text"
                     name="hs-table-search"
                     className="input pl-11 w-full input-shadow h-10 text-sm border-gray-300 rounded-lg appearance-none border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                     placeholder="Search"
                     onChange={(e) => inputchangeSearch(e)}
                  />
               </div>

               <Dropdown
                  setApprovalStatus={setApprovalStatus}
                  allDocData={allDocData?.data?.rows}
                  approvalStatus={approvalStatus}
                  client_org_uuid={organisation_uuid}
                  provider_org_uuid={props?.organisation_uuid}
               />
            </div>
            <BtnLight onClick={() => { setOpenAssignChecklist(true) }} className="flex justify-between items-center gap-2 w-auto px-4">
               <span className="flex-1 text-center">Assigned checklist</span>
               <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M7.5 15L12.5 10L7.5 5" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </span>
            </BtnLight>
         </div>


         <DocumentsList allDocData={allDocData}
            handleSort={handleSort}
            filtersort={filtersort}
            filterorder={filterorder}
            trading_name={props?.trading_name}
            OnGetProviderDocsAgainstClient={OnGetProviderDocsAgainstClient}
         />

         {<Dialog
            isOpen={dialogChoose}
            onClose={onChooseClose}
            onRequestClose={onChooseClose}
            bodyOpenClassName="overflow-hidden"
            className="addContact-dialog"
         >
            <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
               <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4"><img src="/assets/icons-svg/file-check.svg" alt="" /></span>
               <div>
                  <h5 className="mb-1 text-gray-900 text-base font-semibold">Add Document</h5>
               </div>
            </div>
            <div className="px-6">
               <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">Select document</label>
                  <div className="w-3/4 pl-2">
                     <Select
                        className="w-full h-10 select-options"
                        placeholder="Search document"
                        options={searchOptions}
                     >
                     </Select>
                  </div>
               </div>
            </div>
            <div className="flex dailog-footer ">
               <BtnLight
                  className="mr-1.5"
                  variant="plain"
                  onClick={onDialogClose}
               >
                  Cancel
               </BtnLight>
               <BtnPrimary onClick={() => openDialog()} className="ml-1.5">
                  Add
               </BtnPrimary >
            </div>
         </Dialog>}
         <Dialog
            isOpen={dialogIsOpen}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
            bodyOpenClassName="overflow-hidden"
         >
            <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
               <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4"><img src="/assets/icons-svg/file-check.svg" alt="" /></span>
               <div>
                  <h5 className="mb-0 text-gray-900 text-base font-semibold">Add Document</h5>
               </div>
            </div>
            <div className="px-6">
               <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">Select document</label>
                  <div className="w-3/4 pl-2">
                     <Select
                        className="w-full h-10 select-options  search-none"
                        placeholder="Selected insurance document name here"
                        options={searchOptions}
                     >
                     </Select>
                  </div>
               </div>
               <div className="flex mb-5 border-t mt-5 pt-5">
                  <label className="font-medium text-gray-700 w-1/4">Upload</label>
                  <div className="w-3/4 pl-2">
                     <Upload draggable className='w-full' />
                  </div>
               </div>
               <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">Policy number</label>
                  <div className="w-3/4 pl-2 flex">
                     <div className="w-full relative">
                        <Input type="text" className="h-10 w-full" />
                     </div>
                  </div>
               </div>
               <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">Expiry date*</label>
                  <div className="w-3/4 pl-2 flex">
                     <div className="w-full relative">
                        <span className="adon_icon absolute left-4 top-3">
                           <svg width="16" height="17" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#0ckeddmh9a)">
                                 <path d="M17.5 9.291h-15m10.833-6.666v3.333M6.667 2.625v3.333M6.5 19.291h7c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 0 0 1.092-1.093c.273-.534.273-1.234.273-2.635v-7c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.092C15.6 4.29 14.9 4.29 13.5 4.29h-7c-1.4 0-2.1 0-2.635.273a2.5 2.5 0 0 0-1.093 1.092C2.5 6.191 2.5 6.891 2.5 8.291v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.093c.535.272 1.235.272 2.635.272z" stroke="#344054" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round" />
                              </g>
                              <defs>
                                 <clipPath id="0ckeddmh9a">
                                    <path fill="#fff" transform="translate(0 .958)" d="M0 0h20v20H0z" />
                                 </clipPath>
                              </defs>
                           </svg>

                        </span>
                        <Input type="text" placeholder="Select date" className="h-10 w-full pl-9" />
                     </div>
                  </div>
               </div>
               <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">Amount insured</label>
                  <div className="w-3/4 pl-2 flex">
                     <div className="w-full relative">
                        <span className="adon_icon absolute left-4 top-3">
                           <svg width="16" height="17" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5 13.789a3.333 3.333 0 0 0 3.333 3.333h3.334a3.333 3.333 0 0 0 0-6.667H8.333a3.333 3.333 0 1 1 0-6.666h3.334A3.333 3.333 0 0 1 15 7.122m-5-5V18.79" stroke="#667085" strokeWidth="1.667" strokeLinecap="round" strokeLinejoin="round" />
                           </svg>
                        </span>
                        <Input type="text" placeholder="Enter value here" className="h-10 w-full pl-9" />
                     </div>
                  </div>
               </div>
               <div className="flex mb-5">
                  <label className="font-medium text-gray-700 w-1/4">No. of workers</label>
                  <div className="w-3/4 pl-2 flex">
                     <div className="w-full relative">
                        <Input type="text" className="h-10 w-full" />
                     </div>
                  </div>
               </div>

            </div>
            <div className="flex dailog-footer ">
               <BtnLight
                  className="mr-1.5"
                  variant="plain"
                  onClick={onDialogClose}
               >
                  Cancel
               </BtnLight>
               <BtnPrimary onClick={() => openAddDialog()} className="ml-1.5">
                  Add
               </BtnPrimary >
            </div>
         </Dialog>

         <Dialog
            isOpen={dialogAddOpen}
            onClose={onAddDialogClose}
            onRequestClose={onAddDialogClose}
            bodyOpenClassName="overflow-hidden"
            className="customAlert"
         >
            <div className="px-6 pt-6">
               <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M14.5 2.27V6.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437c.214.11.494.11 1.054.11h4.13M9.5 16l2 2 4.5-4.5M14.5 2H9.3c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4.5 4.28 4.5 5.12 4.5 6.8v10.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.78 22 7.62 22 9.3 22h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311c.327-.642.327-1.482.327-3.162V8l-6-6z" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </span>
               <p className="text-base text-gray-900 font-semibold text-center mt-4">
                  Thanks! Document added.
               </p>
            </div>
            <div className='mt-8 flex justify-between pb-6 px-6'>
               <BtnLight onClick={onAddDialogClose}>Close</BtnLight>
            </div>
         </Dialog>

         <Dialog
            isOpen={openAssignChecklist}
            onClose={onCloseAssignChecklist}
            onRequestClose={onCloseAssignChecklist}
            bodyOpenClassName="overflow-hidden"
            width={660}
         >
            <div className="flex border-b mb-5 pb-5 px-6 pt-6 items-center">
               <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
                  <img src={fileCheck} alt="" />
               </span>
               <div>
                  <h5 className="mb-0 text-gray-900 text-base font-semibold">
                     Assigned Checklists to {props?.trading_name ? props?.trading_name : ""}
                  </h5>
                  <p className="text-gray-600 font-normal text-sm">
                     Changing assignments will initiate a review process</p>
               </div>
            </div>
            <div className="px-6">
               <div className="flex justify-between mb-5 mt-5">
                  <h5 className="text-xl text-gray-900 font-semibold">Provider Checklist(s)</h5>
               </div>
               <div className="border rounded-lg border-gray-200">
                  <div className="h-64 overflow-auto">
                     <Scrollbar>
                        <Table>
                           <THead>
                              <Tr>
                                 <Th className="flex justify-between gap-1">
                                    <span className="flex items-center gap-1">
                                       Checklist
                                       <svg
                                          width="17"
                                          height="16"
                                          viewBox="0 0 17 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                       >
                                          <path
                                             d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                             stroke="#475467"
                                             strokeWidth="1.333"
                                             strokeLinecap="round"
                                             strokeLinejoin="round"
                                          ></path>
                                       </svg>
                                    </span>


                                 </Th>
                                 <Th>
                                    <span className="flex items-center gap-1 justify-end">
                                       Expiry date
                                       <svg
                                          width="17"
                                          height="16"
                                          viewBox="0 0 17 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                       >
                                          <path
                                             d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                                             stroke="#475467"
                                             strokeWidth="1.333"
                                             strokeLinecap="round"
                                             strokeLinejoin="round"
                                          ></path>
                                       </svg>
                                    </span>
                                 </Th>
                              </Tr>
                           </THead>
                           <TBody>
                              {providerCompCheckList?.length &&
                                 providerCompCheckList.map((item) => (
                                    <Tr key={item.checklist_uuid}>
                                       <Td className="flex gap-2">
                                          <Checkbox
                                             className="cursor-pointer"
                                             checked={checkedItems.some(checkedItem => checkedItem.checklist_uuid === item.checklist_uuid)}
                                             onChange={(e) => onCheckboxChange(e, item)}
                                          />
                                          {item?.checklist_name ? item?.checklist_name : "-"}
                                       </Td>
                                       <Td className="text-right">{item.checklist_renewal_date ? moment(item?.checklist_renewal_date).format("DD/MM/YYYY") : "-"}</Td>
                                    </Tr>
                                 ))}
                           </TBody>
                        </Table>
                     </Scrollbar>
                  </div>
               </div>

            </div>
            <div className="flex dailog-footer ">
               <BtnLight className="mr-1.5" variant="plain"
                  onClick={onCloseAssignChecklist}>
                  Cancel
               </BtnLight>
               <BtnPrimary
                  type="submit"
                  onClick={() => { setOpenConfirmDialog(true) }}
                  className="ml-1.5"
               // disabled={isSaving}
               >Save updates
                  {/* {isSaving ? "Saving..." : "Save updates"} */}
               </BtnPrimary>
            </div>

         </Dialog>
         <Dialog
            isOpen={openConfirmDialog}
            onClose={onCloseConfirmDialog}
            onRequestClose={onCloseConfirmDialog}
            bodyOpenClassName="overflow-hidden"
            className="customAlert"

         >
            <div className="px-6 pt-6">
               <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
                  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <rect x="1.01331" y="0.5" width="47" height="47" rx="23.5" fill="#D2EBFF" />
                     <rect x="1.01331" y="0.5" width="47" height="47" rx="23.5" stroke="#ECFDF3" />
                     <path d="M26.5133 14.2695V18.4001C26.5133 18.9601 26.5133 19.2401 26.6223 19.4541C26.7182 19.6422 26.8712 19.7952 27.0593 19.8911C27.2732 20.0001 27.5533 20.0001 28.1133 20.0001H32.2438M22.3633 24.0022C22.5395 23.5014 22.8873 23.079 23.345 22.81C23.8028 22.5409 24.341 22.4426 24.8643 22.5324C25.3876 22.6221 25.8623 22.8942 26.2042 23.3004C26.5462 23.7066 26.7333 24.2207 26.7325 24.7517C26.7325 26.2506 24.4842 27 24.4842 27M24.5133 30H24.5233M26.5133 14H21.3133C19.6331 14 18.7931 14 18.1513 14.327C17.5868 14.6146 17.1279 15.0735 16.8403 15.638C16.5133 16.2798 16.5133 17.1198 16.5133 18.8V29.2C16.5133 30.8802 16.5133 31.7202 16.8403 32.362C17.1279 32.9265 17.5868 33.3854 18.1513 33.673C18.7931 34 19.6331 34 21.3133 34H27.7133C29.3935 34 30.2335 34 30.8753 33.673C31.4398 33.3854 31.8987 32.9265 32.1863 32.362C32.5133 31.7202 32.5133 30.8802 32.5133 29.2V20L26.5133 14Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

               </span>
               <p className="text-base text-gray-900 font-semibold text-center mt-4">
                  Are you sure you want to make these updates?
               </p>
               <p className="text-gray-600 text-center">
                  If additional checklists are assigned, Providers and Workers will need their documents reviewed again.
               </p>
            </div>
            <div className="mt-8 flex justify-between pb-6 px-6">
               <BtnLight onClick={onCloseConfirmDialog}>Close</BtnLight>
               <BtnPrimary
                  onClick={() => onConfirm()}
               >Confirm</BtnPrimary>
            </div>
         </Dialog>
         <div className="flex justify-between mt-3">
            <Pagination totalCount={countData} pageNumber={filterpage} displayPage={providerD?.length} UpdatePageLimit={setfilterlimit} UpdatePage={setfilterpage} />

            {countData > 0 && <ReactPaginate
               className="flex justify-content-end p-2 align-items-center pagination-wrap"
               previousLabel={"Prev"}
               nextLabel={"Next"}
               breakLabel={"..."}
               breakClassName={"break-me"}
               pageCount={totalPages}
               marginPagesDisplayed={2}
               pageRangeDisplayed={5}
               onPageChange={handlePageChange}
               containerClassName={"pagination"}
               activeClassName={"active"}
               previousLinkClassName={"prev-link"}
               nextLinkClassName={"page-link"}
               breakLinkClassName={"prev-link"}
               previousClassname={"px-4 rounded-md bg-blue-700 text-white"}
               nextClassname={"px-4 rounded-md bg-blue-700 text-white"}
               pageLinkClassName={"px-4 rounded-md"}
               activeLinkClassName={"w-9 h-9 bg-blue-700 text-white"}
               disabledClassName={"px-5 opacity-50 cursor-not-allowed"}
               renderOnZeroPageCount={null}
               forcePage={filterpage - 1}
            />}

         </div>
      </React.Fragment>
   )
}

export default DocumentList