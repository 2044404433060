import React, { useState, useEffect, useCallback } from "react";
import BtnLight from "../../../../../../common/element/Buttons/btnLight";
import SmallButton from "../../../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../../../common/element/Table";
import { useDispatch } from "react-redux";
import BtnPrimary from "../../../../../../common/element/Buttons/btnPrimary";
import Button from "../../../../../../common/element/Buttons";
import Switcher from "../../../../../../common/element/Switcher";
import Dialog from "../../../../../../common/element/Dialog";
import useUnauthorizedHandler from "../../../../../../common/snippet/useUnauthorizedHandler";
import { GetAdminPersonalNotifypermissions, UpdateAdminPersonalNotifypermissions } from "../../../../../../actions/Admin";

const Notification = () => {
    const dispatch = useDispatch();
    const handleUnauthorizedAccess = useUnauthorizedHandler();
    const { Tr, Th, Td, THead, TBody } = Table;
    const [dialogAddOpen, setDialogAddOpen] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [changedNotifications, setChangedNotifications] = useState([]); // Track changed notifications
    const loginData = JSON.parse(localStorage.getItem("LoginUserData"));

    let user_uuid;
    const role_name = "client";
    if (loginData != null) {
        // user_uuid = loginData?.userName?.user_uuid;
        user_uuid = "4be23a66-99c2-4f8c-a905-31376b624bae"
    }

    const onAddDialogClose = () => {
        setDialogAddOpen(false);
        setChangedNotifications([])
    };

    const OnGetAdminPersonalNotifypermissions = useCallback(async () => {
        try {
            const response = await dispatch(GetAdminPersonalNotifypermissions({ user_uuid: user_uuid }));

            if (response?.success) {
                setNotificationList(response?.data?.client);
                console.log(response?.data?.client);
            } else if (response?.status === 401) {
                handleUnauthorizedAccess();
            }
        } catch (error) {
            console.error('Error fetching global permissions:', error);
        }
    }, [dispatch]);

    useEffect(() => {
        OnGetAdminPersonalNotifypermissions();
    }, [OnGetAdminPersonalNotifypermissions]);

    const handleAllOnOff = (item) => {
        const allEnabled = notificationList?.every(notification => notification[item]);

        // Update notifications
        const updatedNotifications = notificationList?.map(notification => ({
            ...notification,
            [item]: !allEnabled,
        }));

        setNotificationList(updatedNotifications);

        // Track changed notifications for the save button (remove duplicates and add updated items)
        const updatedItems = updatedNotifications.filter((notification, index) =>
            notificationList[index][item] !== notification[item]
        );

        setChangedNotifications(prev => {
            const uniqueNotifications = prev.filter(
                prevItem => !updatedItems.some(
                    updatedItem => updatedItem?.notifications_global_settings_uuid === prevItem?.notifications_global_settings_uuid
                )
            );

            return [...uniqueNotifications, ...updatedItems];
        });

        console.log(updatedItems, "updated notifications");
    };


    const onSwitcherToggle = (val, notifications_global_settings_uuid, type) => {
        let updatedNotificationObject = null;

        // Update the notification that matches the UUID
        const updatedNotifications = notificationList?.map(notification => {
            if (notification.notifications_global_settings_uuid === notifications_global_settings_uuid) {
                updatedNotificationObject = {
                    ...notification,
                    [type]: !notification[type],
                };
                return updatedNotificationObject;
            }
            return notification;
        });

        // Update the notification list state
        setNotificationList(updatedNotifications);

        // Check if the object already exists in changedNotifications before adding it
        setChangedNotifications(prev => {
            const exists = prev.some(
                notification => notification.notifications_global_settings_uuid === notifications_global_settings_uuid
            );

            if (exists) {
                // If it exists, update the value in the previous list
                return prev.map(notification =>
                    notification.notifications_global_settings_uuid === notifications_global_settings_uuid
                        ? updatedNotificationObject
                        : notification
                );
            } else {
                // If it doesn't exist, add it to the list
                return [...prev, updatedNotificationObject];
            }
        });
    };


    const submitNotifications = () => {
        if (changedNotifications?.length > 0) {
            const payload = changedNotifications?.map(notification => ({
                notifications_global_settings_uuid: notification?.notifications_global_settings_uuid,
                user_uuid: user_uuid,
                notify_bell: notification?.notify_bell,
                notify_email: notification?.notify_email,
                notify_task: notification?.notify_task,
            }));

            // dispatch(UpdateAdminPersonalNotifypermissions(payload))
            //     .then((response) => {
            //         if (response?.success) {
            //             setChangedNotifications([])
            //             setDialogAddOpen(true);
            //         } else {
            //             console.error("Failed to update notification");
            //         }
            //     })
            //     .catch((error) => {
            //         console.error("Error while updating notification:", error);
            //     });
        }
    };

    return (
        <React.Fragment>
            <div className="flex justify-between mb-5 border-b border-gray-200 pb-5">
                <div>
                    <h5 className="text-base text-gray-900 font-semibold mb-1">Notifications</h5>
                    <p className="text-gray-600">Customize your notification preferences to stay informed and in control of your alerts and updates.</p>
                </div>
                <div className="flex justify-end items-center">
                    <BtnLight className="w-auto px-4">Cancel</BtnLight>
                    <SmallButton className="w-auto px-4 ml-3" onClick={() => submitNotifications()}>Save</SmallButton>
                </div>
            </div>

            <Table className="notification-table">
                <THead>
                    <Tr>
                        <Th>Notify me when...</Th>
                        <Th>
                            Task <br />
                            <Button
                                className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700"
                                onClick={() => notificationList.length > 0 && handleAllOnOff('notify_task')}
                            >
                                {notificationList?.every(notification => notification?.notify_task)
                                    ? "Turn off all tasks"
                                    : "Turn on all tasks"}
                            </Button>
                        </Th>
                        <Th>
                            Bell <br />
                            <Button
                                className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700"
                                onClick={() =>  notificationList.length > 0 && handleAllOnOff('notify_bell')}
                            >
                                {notificationList?.every(notification => notification?.notify_bell)
                                    ? "Turn off all bells"
                                    : "Turn on all bells"}
                            </Button>
                        </Th>
                        <Th>
                            Email <br />
                            <Button
                                className="text-blue-700 text-xs font-semibold hover:text-blue-900 focus:text-blue-700"
                                onClick={() => notificationList.length > 0 && handleAllOnOff('notify_email')}
                            >
                                {notificationList?.every(notification => notification?.notify_email)
                                    ? "Turn off all emails"
                                    : "Turn on all emails"}
                            </Button>
                        </Th>
                    </Tr>
                </THead>
                <TBody>
                    {notificationList?.map((notification, idx) => (
                        <Tr key={idx} >
                            <Td>
                                <span className="block font-semibold text-gray-700">
                                    {notification?.permission_name}
                                </span>
                                <span className="text-gray-600 text-xs">
                                    {notification?.message_template || "Description not available"}
                                </span>
                            </Td>
                            <Td>
                                <Switcher
                                    checked={notification?.notify_task}
                                    onChange={(val) => onSwitcherToggle(val, notification?.notifications_global_settings_uuid, 'notify_task')}
                                />
                                <span className="text-xs text-gray-700 font-medium ml-2">Task</span>
                            </Td>

                            <Td>
                                <Switcher
                                    checked={notification?.notify_bell}
                                    onChange={(val) => onSwitcherToggle(val, notification?.notifications_global_settings_uuid, 'notify_bell')}
                                />
                                <span className="text-xs text-gray-700 font-medium ml-2">Bell</span>
                            </Td>
                            <Td>
                                <Switcher
                                    checked={notification?.notify_email}
                                    onChange={(val) => onSwitcherToggle(val, notification?.notifications_global_settings_uuid, 'notify_email')}
                                />
                                <span className="text-xs text-gray-700 font-medium ml-2">Email</span>
                            </Td>
                        </Tr>
                    ))}
                </TBody>
            </Table>

            <Dialog
                isOpen={dialogAddOpen}
                onClose={onAddDialogClose}
                onRequestClose={onAddDialogClose}
                bodyOpenClassName="overflow-hidden"
                className="customAlert"
            >
                <div className="px-6 pt-6">
                    <span className="w-12 h-12 rounded-full m-auto bg-success-100 flex items-center justify-center">
                        <svg
                            width="20"
                            height="20"
                            className="text-success-600 m-auto"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10 0C4.485 0 0 4.485 0 10C0 15.515 4.485 20 10 20C15.515 20 20 15.515 20 10C20 4.485 15.515 0 10 0ZM8.5 13.793L5.354 10.646C5.159 10.451 4.842 10.451 4.646 10.646C4.451 10.841 4.451 11.159 4.646 11.354L8.146 14.854C8.342 15.049 8.659 15.049 8.854 14.854L15.854 7.854C16.049 7.659 16.049 7.342 15.854 7.146C15.659 6.951 15.342 6.951 15.146 7.146L8.5 13.793Z"
                            />
                        </svg>
                    </span>
                    <h2 className="mt-5 text-2xl leading-8 font-semibold text-gray-900 text-center">
                        Personal notifications updated successfully
                    </h2>
                    <div className="mt-6 flex justify-center">
                        <BtnPrimary className="w-full" onClick={onAddDialogClose}>Close</BtnPrimary>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default Notification;
