import { useContext, useState, useEffect } from 'react'
import BtnPrimary from '../../../common/element/Buttons/btnPrimary'
import Card from '../../../common/element/Card'
import Heading from '../../../common/element/Heading'
import Circle from '../../../common/element/Iconcircle'
import { Link } from 'react-router-dom'
import checkImg from '../../../assets/icons-svg/check.svg'
import arrowLeftImg from '../../../assets/icons-svg/arrow-left.svg';

function PasswordReset() {

return (
<div style={{ backgroundColor: `#eff8ff` }} className="flex justify-center min-h-screen">
<div className="min-h-screen justify-center flex  py-12 px-6">
   <div className="flex-col items-center justify-center flex">
   <Card className="w-500 rounded-xl   block-shadow w-100 mt-2 px-10 py-8">
      <Circle className="bg-success-100 border-success-50 mb-6">
         <img src={checkImg} alt="conserve" />
         </Circle>
      <Heading className="text-center mb-3">Password reset</Heading>
      <p className="text-sm text-gray-600 text-center mb-6">Your password has been successfully reset.</p>
         <div className="w-full">
            <form>
               <div className="flex items-center justify-between w-100">
                  <BtnPrimary><Link to="/login">Continue to log in</Link></BtnPrimary>
               </div>
            </form>
         </div>
      </Card>
      <div className=" mt-8 px-10 w-full text-center">
         
      <Link to="/login" className='text-xs text-gray-600 font-semibold hover:text-gray-900 focus:text-gray-600 flex justify-center items-center'> <img src={arrowLeftImg} className='w-5 mr-2' alt="" /> Back to log in</Link>
      </div>
   </div>
</div>
</div>
)
}
export default PasswordReset