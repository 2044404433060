// import React from "react";
// const Button = (props) =>{
//     const classes = " " + props.className;
   
//     return(
//         <button className={classes} type={props.type || "button"}
//                 onClick={props.onClick}>
//             {props.children}
//         </button>
//     )
// }
// export default Button

import React from "react";

const Button = (props) => {
    const { className, type, onClick, disabled, children } = props;
    const classes = " " + className;
   
    return (
        <button 
            className={classes} 
            type={type || "button"} 
            onClick={onClick} 
            disabled={disabled}
        >
            {children}
        </button>
    );
}

export default Button;
