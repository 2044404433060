import React, { useEffect, useState } from "react";
import Label from "../../../../../../../../common/element/Label";
import ClientInput from "../../../../../../../../common/element/Input/clientInput";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateInductionStatusSite } from "../../../../../../../../redux/classes/Admin-Client/updatInductionStatusSite";
import { DeletedocumentDataSite } from "../../../../../../../../redux/classes/Admin-Client/deleteDocumentSite";
import { closeDocumentfuncSite } from "../../../../../../../../redux/classes/Admin-Client/closeDocumentSite";
import { UpdateSiteInductionModuleData } from "../../../../../../../../redux/classes/Admin-Client/SiteInductionModuleSlice";
import { Tooltip } from "@material-ui/core";

import { DeleteCompanyIndModuleFileById} from "../../../../../../../../actions/Admin-Client/AllCompanyInduction";
const URLink = (props) =>{
    const { SiteInductionModuleData } = useSelector((state) => state);
    const [Pdfdata,Setpdfdata]=useState(SiteInductionModuleData[props.indexData])
    const dispatch=useDispatch()

    const inputchangehandler = (e) => {
        dispatch(UpdateInductionStatusSite(true))
        if(Pdfdata.content_info !== e){
            if(e.target.name=="urlLInkTitle"){
                Setpdfdata({ 
                    ...Pdfdata, 
                    content_info_type: "url",
                    content_link_title: e.target.value
                })
            }
            else{
                Setpdfdata({ 
                    ...Pdfdata, 
                    content_info_type: "url",
                    content_info: e.target.value
                })
            }
        }
    };
    useEffect(() => {
    dispatch(
        UpdateSiteInductionModuleData({
            indexData:props.indexData,
            moduleData:Pdfdata
        }))
    }, [Pdfdata]);  
    const handleDeletePDF=()=>{
        dispatch(closeDocumentfuncSite(true));
      }
    return(
        <React.Fragment>
                <div className="flex gap-3 relative">
                <Tooltip placement={"right-start"} title={"Remove module content"}>
          <button
            onClick={() => handleDeletePDF()}
            className="modue_creation_close_icon absolute right-0 bottom-full"
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m18 6.5-12 12m0-12 12 12"
                stroke="#667085"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </Tooltip>
                <div className="flex flex-col w-56">
                <Label className="mb-2">Link title</Label>
                        <ClientInput 
                        name="urlLInkTitle"  
                        value={Pdfdata?.content_link_title} 
                        inputchangehandler={inputchangehandler} 
                        className="h-9"
                        />
                       </div>
                       <div className="flex flex-col flex-1">
                       <Label className="mb-2">Link</Label>
                        <div className="input-wrapper ">
                            <div className="input-suffix-start right-3  w-6">
                            <Link to={`${Pdfdata.content_info}`}  target="_blank">
                                    <svg width="17" height="16" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.167 4.5v-3m0 0h-3m3 0-4 4m-1.5-3h-1.1c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656c-.163.32-.163.74-.163 1.581v3.2c0 .84 0 1.26.163 1.581a1.5 1.5 0 0 0 .656.655c.32.164.74.164 1.58.164h3.2c.84 0 1.26 0 1.582-.164a1.5 1.5 0 0 0 .655-.655c.164-.32.164-.74.164-1.581V7" stroke="#667085" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </Link>
                                
                            </div>
                            <ClientInput 
                            name="urlLInk" 
                            inputchangehandler={inputchangehandler} 
                            value={Pdfdata.content_info} 
                            placeholder="Enter link..." 
                            className="h-9 pl-9 w-full"
                            />
                        </div>
                       </div>
                </div>
        </React.Fragment>
    )
}
export default URLink