import React, { useState } from "react";
import Dialog from "../../../../../../../../../../common/element/Dialog/Dialog";
import BtnLight from "../../../../../../../../../../common/element/Buttons/btnLight";
import BtnPrimary from "../../../../../../../../../../common/element/Buttons/btnPrimary";
import Table from "../../../../../../../../../../common/element/Table";
const SiteList = () => {
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [inducteesDialogIsOpen, setInducteesIsOpen] = useState(false);
  const { Tr, Th, Td, THead, TBody } = Table;
  const openDialog = () => {
    setIsOpen(true);
  };
  const onDialogClose = () => {
    setIsOpen(false);
  };
  const inducteesOpenDialog = () => {
   setInducteesIsOpen(true);
 };
 const onInducteesDialogClose = () => {
   setInducteesIsOpen(false);
 };

  return (
    <React.Fragment>
      <div className="overflow-hidden border rounded-lg border-gray-200">
        <Table>
          <THead>
            <Tr>
              <Th className="w-1/4">
                <span className="flex items-center gap-1">
                Client
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-48">
                <span className="flex items-center gap-1">
                  Site
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.712 3.333v9.334m0 0L13.38 8m-4.667 4.667L4.045 8"
                      stroke="#475467"
                      strokeWidth="1.333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Th>
              <Th className="w-40">Sign-In</Th>

             
              <Th className="w-40">Sign-Out</Th>
            </Tr>
          </THead>
          <TBody>
            <Tr>
              <Td>Client name</Td>
              <Td>Site name</Td>
              <Td>
                <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span>
              </Td>
              <Td><span className="text-success-500">On Site</span></Td>
           
            </Tr>
            <Tr>
              <Td>Client name</Td>
              <Td>Site name</Td>
              <Td>
                <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span>
              </Td>
              <Td> <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span></Td>
           
            </Tr>
            <Tr>
              <Td>Client name</Td>
              <Td>Site name</Td>
              <Td>
                <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span>
              </Td>
              <Td> <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span></Td>
           
            </Tr>
            <Tr>
              <Td>Client name</Td>
              <Td>Site name</Td>
              <Td>
                <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span>
              </Td>
              <Td> <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span></Td>
           
            </Tr>
            <Tr>
              <Td>Client name</Td>
              <Td>Site name</Td>
              <Td>
                <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span>
              </Td>
              <Td> <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span></Td>
           
            </Tr>
            <Tr>
              <Td>Client name</Td>
              <Td>Site name</Td>
              <Td>
                <span className="text-gray-900 block w-full font-medium font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span>
              </Td>
              <Td> <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span></Td>
           
            </Tr>
            <Tr>
              <Td>Client name</Td>
              <Td>Site name</Td>
              <Td>
                <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span>
              </Td>
              <Td> <span className="text-gray-900 block w-full font-medium">DD/MM/YY</span>
                <span className="text-gray-600">00:00</span></Td>
           
            </Tr>
          </TBody>
        </Table>
      </div>

      <Dialog
        isOpen={dialogIsOpen}
        onClose={onDialogClose}
        onRequestClose={onDialogClose}
        bodyOpenClassName="overflow-hidden"
        className="customAlert"
      >
        <div className="px-6 pt-6">
          <span className="w-12 h-12 rounded-full m-auto bg-error-100 flex items-center justify-center">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 3.5h6m-12 3h18m-2 0-.701 10.52c-.105 1.578-.158 2.367-.499 2.965a3 3 0 0 1-1.298 1.215c-.62.3-1.41.3-2.993.3h-3.018c-1.582 0-2.373 0-2.993-.3A3 3 0 0 1 6.2 19.985c-.34-.598-.394-1.387-.499-2.966L5 6.5m5 4.5v5m4-5v5"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-base text-gray-900 font-semibold text-center mt-4">
            Are you sure you want to remove this Worker?
          </p>
          <p className=" text-gray-600 text-center mt-1">
            This action cannot be undone. The user will lose all access to the
            app.
          </p>
        </div>
        <div className="mt-8 flex justify-between pb-6 px-6 gap-2">
          <BtnLight onClick={onDialogClose}>Close</BtnLight>
          <BtnPrimary>Confirm</BtnPrimary>
        </div>
      </Dialog>
      <Dialog
         isOpen={inducteesDialogIsOpen}
         onClose={onInducteesDialogClose}
         onRequestClose={onInducteesDialogClose}
         bodyOpenClassName="overflow-hidden"
         >
         <div className="flex border-b mb-5 pb-5 px-6 pt-6">
            <span className="h-12 w-12 border rounded-lg border-gray-200 flex items-center justify-center shadow-sm mr-4">
               <img src="/assets/icons-svg/cons-worker.svg" alt="" />
            </span>
            <div>
               <h5 className="mb-1 text-gray-900 text-base font-semibold">
               [First name][Last name]
               </h5>
               <p className="text-gray-600 ">Worker Details</p>
            </div>
         </div>

         </Dialog>

    </React.Fragment>
  );
};
export default SiteList;
