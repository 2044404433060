
import * as actionType from "../constant/actionType";

const initial_state = {
    error: null,
    token: null,
    loader: false,
};

const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case actionType.SignIn_START:
            return {
                ...state,
                error: action.error,
                loader: action.loader,
            };
        case actionType.SignIn_SUCCESS:
            return {
                ...state,
                error: null,
                loader: false,
                token: action.token,
            };
        case actionType.SignIn_FAIL:
            return {
                ...state,
                loader: action.loader,
                error: action.error,
            };

            case actionType.SignInVerify_START:
                return {
                    ...state,
                    error: action.error,
                    loader: action.loader,
                };
            case actionType.SignInVerify_SUCCESS:
                return {
                    ...state,
                    error: null,
                    loader: false,
                    token: action.token,
                };
            case actionType.SignInVerify_FAIL:
                return {
                    ...state,
                    loader: action.loader,
                    error: action.error,
                };
    
                case actionType.email_START:
                    return {
                        ...state,
                        error: action.error,
                        loader: action.loader,
                    };
                case actionType.email_SUCCESS:
                    return {
                        ...state,
                        error: null,
                        loader: false,
                        token: action.token,
                    };
                case actionType.email_FAIL:
                    return {
                        ...state,
                        loader: action.loader,
                        error: action.error,
                    };
        

                    case actionType.ForgotPassword_START:
                        return {
                            ...state,
                            error: action.error,
                            loader: action.loader,
                        };
                    case actionType.ForgotPassword_SUCCESS:
                        return {
                            ...state,
                            error: null,
                            loader: false,
                            token: action.token,
                        };
                    case actionType.ForgotPassword_FAIL:
                        return {
                            ...state,
                            loader: action.loader,
                            error: action.error,
                        };
            

                        
                        case actionType.ResendSignInOtp_START:
                            return {
                                ...state,
                                error: action.error,
                                loader: action.loader,
                            };
                        case actionType.ResendSignInOtp_SUCCESS:
                            return {
                                ...state,
                                error: null,
                                loader: false,
                                token: action.token,
                            };
                        case actionType.ResendSignInOtp_FAIL:
                            return {
                                ...state,
                                loader: action.loader,
                                error: action.error,
                            };
                

                            
                            case actionType.ResetPassword_START:
                                return {
                                    ...state,
                                    error: action.error,
                                    loader: action.loader,
                                };
                            case actionType.ResetPassword_SUCCESS:
                                return {
                                    ...state,
                                    error: null,
                                    loader: false,
                                    token: action.token,
                                };
                            case actionType.ResetPassword_FAIL:
                                return {
                                    ...state,
                                    loader: action.loader,
                                    error: action.error,
                                };
                    
                    

        default:
            return state;
    }
};

export default reducer;





