// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const updateInductionSlice = createSlice({
  name:"updateInduction",
  initialState:false,
  reducers:{
    UpdateInductionStatus(state,action){
      return action.payload;
    },
    ResetInductionStatus(){
      return null
    }
  }
}) 
export default updateInductionSlice.reducer;
export const {UpdateInductionStatus,ResetInductionStatus}=updateInductionSlice.actions;